import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { AUTH_SLICE_NAME, AuthState } from './models';

export const authSliceSelector = (state: RootState): AuthState =>
  state[AUTH_SLICE_NAME];

export const selectAuthIsLoading = createSelector(
  authSliceSelector,
  (state: AuthState) => state.isLoading,
);

export const selectIsAuthorized = createSelector(
  authSliceSelector,
  (state: AuthState) => state.isAuthorized,
);

export const selectAuthCurrentAction = createSelector(
  authSliceSelector,
  (state: AuthState) => state.currentAction,
);

export const selectAuthSessionId = createSelector(
  authSliceSelector,
  (state: AuthState) => state.authSessionId,
);

export const selectAuthSetupMfaQrCode = createSelector(
  authSliceSelector,
  (state: AuthState) => state.setupMfaQrCode,
);
export const selectAuthSetupMfaSecretCode = createSelector(
  authSliceSelector,
  (state: AuthState) => state.setupMfaSecretCode,
);
