import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';

export const DeliveryWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
`;

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  display: flex;
  width: 8.6rem;
  height: 2rem !important;
  background: ${COLORS.WHITE};
  color: ${COLORS.CORN_FLOWER_BLUE};
  box-shadow: 0px 4px 20px -2px #0c1a4b1a !important;
  :hover {
    background: ${COLORS.WHITE}!important;
    color: ${COLORS.CORN_FLOWER_BLUE}!important;
  }
  svg {
    transform: scale(1.5);
    margin: 0.5rem 0rem 0rem 1.5rem;
  }
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  margin-bottom: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('16', '25', 'BOLD')};
    margin-bottom: 1rem;
  }
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  :last-child {
    flex: 0.1;
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  width: 100%;
  flex-direction: column;
  min-width: 0;

  :last-child {
    flex: 0.1;
    img {
      transform: scale(0.8);
    }
  }
`;

export const CardWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledInfoTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;
