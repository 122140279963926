import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledMoreButton, StyledParagraph } from './TextTruncateStyles';

interface ITextTruncate {
  rows?: number;
  expandable?: boolean;
  showMore?: string;
  showLess?: string;
  text: string;
  className?: string;
}

const TextTruncate = ({
  expandable,
  rows = 1,
  text,
  className,
}: ITextTruncate) => {
  const [ellipsis, setEllipsis] = useState(true);
  const { t } = useTranslation(['projects', 'common']);

  const handleExpand = useCallback(() => {
    setEllipsis(false);
  }, []);

  const handleClose = useCallback(() => {
    setEllipsis(true);
  }, []);

  return (
    <>
      <StyledParagraph
        ellipsis={ellipsis ? { rows } : false}
        className={className}
      >
        {text}
      </StyledParagraph>

      {expandable && ellipsis && (
        <StyledMoreButton onClick={handleExpand}>
          {t('common_show_more', { ns: 'common' })}
        </StyledMoreButton>
      )}

      {expandable && !ellipsis && (
        <StyledMoreButton onClick={handleClose}>
          {t('common_show_less', { ns: 'common' })}
        </StyledMoreButton>
      )}
    </>
  );
};

export default TextTruncate;
