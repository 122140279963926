import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { REQUESTS_LIST_SLICE_NAME } from './models';
import { RequestsAPI } from '@/api';
import { formatApiError } from '@/utils';
import {
  IRequestsListResponse,
  IGetRequestsListDTO,
  IOwnRequestsResponse,
  IOwnRequestsDTO,
  IRequestsCountDTO,
  IEntityRequestsDTO,
} from '@/models/requests.model';

export const getRequestsList = createAsyncThunk<
  IRequestsListResponse,
  IGetRequestsListDTO,
  { serializedErrorType: IApiError }
>(
  `${REQUESTS_LIST_SLICE_NAME}/getRequestsList`,
  async (data: IGetRequestsListDTO) => {
    const response = await RequestsAPI.getRequestsList({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getOwnRequests = createAsyncThunk<
  IOwnRequestsResponse,
  IOwnRequestsDTO,
  { serializedErrorType: IApiError }
>(
  `${REQUESTS_LIST_SLICE_NAME}/getOwnRequests`,
  async (data: IOwnRequestsDTO) => {
    const response = await RequestsAPI.getOwnRequest({
      ...data,
      limit: 2,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getEntityRequest = createAsyncThunk<
  IOwnRequestsResponse,
  IEntityRequestsDTO,
  { serializedErrorType: IApiError }
>(
  `${REQUESTS_LIST_SLICE_NAME}/getEntityRequest`,
  async (data: IEntityRequestsDTO) => {
    const response = await RequestsAPI.getEntityRequest({
      ...data,
      limit: 2,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getRequestCounter = createAsyncThunk<
  number,
  IRequestsCountDTO,
  { serializedErrorType: IApiError }
>(
  `${REQUESTS_LIST_SLICE_NAME}/getRequestsCount`,
  async (data: IRequestsCountDTO) => {
    const response = await RequestsAPI.getRequestCount({
      ...data,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);
