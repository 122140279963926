// Globals
import React from 'react';

// Styles
import { StyledChannelFrame } from './SocialChannelsInvestmentStyles';

// Modules
import { socialChannelsConfig } from '@/modules/KolsList';

// Models
import { SocialChannels } from '@/models/socialChannel.model';

type TChannelButtonProps = {
  name: SocialChannels;
  isDisabled?: boolean;
  isActive: boolean;
  remove: (id: SocialChannels) => void;
  add: (id: SocialChannels) => void;
};

const ChanelButton = ({
  name,
  remove,
  add,
  isActive,
  isDisabled = false,
}: TChannelButtonProps) => {
  const onRemove = () => remove(name);
  const onAdd = () => add(name);
  const handleClick = () => {
    if (isDisabled) return;
    isActive ? onRemove() : onAdd();
  };

  return (
    <StyledChannelFrame onClick={handleClick} $isActive={isActive}>
      {socialChannelsConfig[name]?.[isDisabled ? 'disabledIcon' : 'icon']}
    </StyledChannelFrame>
  );
};

export default ChanelButton;
