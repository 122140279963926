// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import FundingReportCard from '../FundingReportCard/FundingReportCard';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import {
  ReportsListWrapper,
  StyledTitles,
  StyledInfoTitles,
} from './FundingReportsListStyles';
import FundingReportsFilters from '../ReportsFilters/FundingReportsFilters';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { IFundingReport } from '@/models/reports.model';

// Hooks | Helpers
import { useInfiniteScroll } from '@/hooks';

interface IFundingReportsList {
  reports: IFundingReport[] | null;
  isLoading: boolean;
  hasMore: boolean;
  getNextReports: () => void;
}

const FundingReportsList = ({
  reports,
  getNextReports,
  isLoading,
  hasMore,
}: IFundingReportsList) => {
  const loaderRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(['reports']);

  const mappedRequestsList = useMemo(() => {
    return reports?.map((report) => {
      return <FundingReportCard report={report} key={report.id} isListView />;
    });
  }, [reports]);

  useInfiniteScroll({
    callback: getNextReports,
    ref: loaderRef,
    isLoading,
    hasMore,
  });

  return (
    <ReportsListWrapper>
      <FundingReportsFilters />
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('reports_list_project_title')}</StyledTitles>
          <StyledTitles>{t('reports_list_funding_title')}</StyledTitles>
          <StyledTitles>{t('reports_list_funding_target_title')}</StyledTitles>
          <StyledTitles>{t('reports_list_funding_raised_title')}</StyledTitles>
          <StyledTitles>{t('reports_list_network_title')}</StyledTitles>
          <StyledTitles></StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedRequestsList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </ReportsListWrapper>
  );
};

export default FundingReportsList;
