// Globals
import React from 'react';

// Components
import { ItemWrap, StyledText } from './ProjectItemStyles';

// Modules
import { getProjectsPhase } from '@/modules/Projects/helpers';

// Models
import { IProject } from '@/models/projects.model';

// Utils
import { numberWithCommas } from '@/utils';

interface IProjectItem {
  project: IProject;
}

const ProjectItem = ({ project }: IProjectItem) => {
  return (
    <ItemWrap>
      <StyledText>{project.title}</StyledText>
      <StyledText>
        {`$ ${numberWithCommas(
          project?.investedMoneyAmount ?? 0,
        )} / $ ${numberWithCommas(project?.investmentTarget ?? 0)}`}
      </StyledText>
      <StyledText>{getProjectsPhase(project?.phase)}</StyledText>
    </ItemWrap>
  );
};

export default ProjectItem;
