import { COLORS } from '@/theme';

export const chartColors = [
  COLORS.CERULEAN,
  COLORS.COQUELICOT,
  COLORS.BRIGHT_ORANGE,
  COLORS.AZUL,
];

export const defaultConfig = {
  columnWidthRatio: 0.5,
  autoFit: true,
  columnStyle: {
    radius: [25, 25, 0, 0],
  },
  yAxis: {
    grid: null,
  },
  xAxis: {
    label: {
      autoHide: false,
      style: {
        wordWrap: 'break-word',
      },
      autoRotate: true,
    },
  },
  limitInPlot: true,
  height: 250,
};
