import { UserRoles } from '@/models/admin.model';

export enum DateSortKeys {
  LATEST_TOP = 'latest_top',
  NEAREST_TOP = 'nearest_top',
}

export enum IReportsTabsNames {
  FUNDING_ROUND = 'funding_round',
  CAMPAIGN = 'campaign',
}

export const reportsTabsConfig = [
  {
    label: 'funding-round',
    key: IReportsTabsNames.FUNDING_ROUND,
    name: 'Funding Round',
  },
  {
    label: 'campaign',
    key: IReportsTabsNames.CAMPAIGN,
    name: 'Campaign',
  },
];

export const sortByDateOptions = [
  {
    label: 'Sorting by nearest date',
    value: DateSortKeys.NEAREST_TOP,
  },
  {
    label: 'Sorting by latest date',
    value: DateSortKeys.LATEST_TOP,
  },
];

export const sortByUserTypeOptions = [
  {
    label: 'KOL',
    value: UserRoles.KOL,
  },
  {
    label: 'Project',
    value: UserRoles.PROJECT,
  },
  {
    label: 'Partner',
    value: UserRoles.PARTNER,
  },
  {
    label: 'Admin',
    value: UserRoles.ADMIN,
  },
];
