export enum Statuses {
  PENDING = 'pending',
  VERIFIED = 'verified',
  IN_VERIFICATION = 'in_verification',
  UNVERIFIED = 'not_verified',
}

export enum InvestmentStatuses {
  PENDING = 'pending',
  IN_VERIFICATION = 'in_verification',
  IN_REVIEW = 'in_review',
  REJECTED = 'rejected',
  WAITING_FOR_CONTRACT = 'waiting_for_contract',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
  WAITING_FOR_PROJECT_SIGN = 'waiting_for_project_sign',
  ACCEPTED = 'accepted',
  EXPIRED = 'expired',
}

export enum KYCStatuses {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  CANCELED = 'CANCELED',
  FINAL_REJECTED = 'FINAL_REJECTED',
}

export enum AccountStatuses {
  VERIFIED = 'verified',
  IN_VERIFICATION = 'in_verification',
  PRE_VERIFIED = 'pre_verified',
  NOT_VERIFIED = 'not_verified',
  PAUSED = 'paused',
  PENDING = 'pending',
  CANCELED = 'canceled',
  DECLINED = 'declined',
  ARCHIVED = 'archived',
  BLOCKED = 'blocked',
}
