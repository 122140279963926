import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { Button } from '@/ui';

export const StyledResourceWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${COLORS.SOLITUDE_GRAY};

  &:last-child {
    border-bottom: 0;
  }
`;

export const StyledResource = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 100%;
  height: 2.25rem;
  align-items: center;
  ${pickThemeFontStyles('14', '16', 'SEMI_BOLD')};
  & .anticon {
    svg {
      ${pickThemeFontStyles('16', '16', 'SEMI_BOLD')};
    }
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    gap: 2rem;
  }
`;

export const StyledResourcesButton = styled(Button)`
  &&& {
    height: 2.25rem !important;
    max-width: 9rem;
    background: ${COLORS.WHITE};
    border: 1px solid ${COLORS.LIGHTEST_GRAY};
    color: ${COLORS.CORN_FLOWER_BLUE};
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
    & .anticon {
      display: flex;
      transform: scale(1.5);
      line-height: 2.25rem !important;
      svg {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
`;

export const StyledA = styled.a<{ $isBold?: boolean }>`
  color: ${COLORS.SOLITUDE_DARK};
  ${({ $isBold }) =>
    $isBold
      ? pickThemeFontStyles('14', '14', 'SEMI_BOLD')
      : pickThemeFontStyles('12', '20', 'SEMI_NORMAL')};
`;

export const StyledBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  color: ${COLORS.CORN_FLOWER_BLUE};
  gap: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    justify-content: normal;
  }
`;

export const StyledSubTitle = styled.div`
  ${pickThemeFontStyles('18', '22', 'BOLD')};
  align-self: flex-start;
  color: ${COLORS.BLACK};
  word-break: break-all;
`;
