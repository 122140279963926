// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledTitle,
  StyledBlockWrapper,
  StyledActionsWrap,
  StyledLabel,
  StyledDot,
  StyledBlockTitle,
  StyledActions,
} from './AdminPermissionsStyles';

// Models
import { IAdmin } from '@/models/adminsList.model';

interface IAdminPermissions {
  adminDetails: IAdmin;
}

const AdminPermissions = ({ adminDetails }: IAdminPermissions) => {
  const { t } = useTranslation(['adminsList']);
  const mappedAllowedActions = adminDetails?.allowedActions?.map((action) => (
    <StyledLabel key={action}>
      <StyledDot />
      {t(action)}
    </StyledLabel>
  ));

  return (
    <StyledBlockWrapper>
      <StyledBlockTitle $type="h2">
        {`${adminDetails?.firstName} ${adminDetails?.lastName}`}
      </StyledBlockTitle>
      <StyledBlockTitle $type="h2">
        <StyledLabel>
          <span>{t('admin_details_email')}</span>
          {adminDetails?.email}
        </StyledLabel>
      </StyledBlockTitle>
      <StyledActions>
        <StyledActionsWrap>
          <StyledTitle>{t('admin_details_actions_allowed')}</StyledTitle>
          {mappedAllowedActions}
        </StyledActionsWrap>
      </StyledActions>
    </StyledBlockWrapper>
  );
};

export default AdminPermissions;
