// Global
import React from 'react';

// Components
import { StyledPageWrapper } from '../../components';
import { Spinner } from '@/ui';

// Models
import { IRequestDetails } from '@/models/requests.model';
import { LogEntities } from '@/models/logs.model';

// Modules
import { LogContainer } from '@/modules/Logs';
import { CheckInvestmentRequestsDetails } from '@/modules/RequestDetails';

interface IVerificationRequest {
  request: IRequestDetails | null;
  isLoading: boolean;
}

const CheckInvestmentRequestContainer = ({
  request,
  isLoading,
}: IVerificationRequest) => {
  return (
    <StyledPageWrapper>
      <CheckInvestmentRequestsDetails request={request} isLoading={isLoading} />
      {isLoading ? (
        <Spinner />
      ) : (
        <LogContainer entity={LogEntities.REQUESTS} id={request?.id} />
      )}
    </StyledPageWrapper>
  );
};

export default CheckInvestmentRequestContainer;
