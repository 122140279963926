import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { MILESTONES_SLICE_NAME } from './models';
import { formatApiError } from '@/utils';
import {
  IMilestone,
  IMilestoneDTO,
  IRemoveMilestoneDTO,
} from '@/models/milestones.model';
import { MilestonesAPI } from '@/api';

export const addMilestone = createAsyncThunk<
  IMilestone,
  string,
  {
    serializedErrorType: IApiError;
  }
>(
  `${MILESTONES_SLICE_NAME}/addMilestone`,
  async (data) => {
    const response = await MilestonesAPI.addMilestone(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateMilestone = createAsyncThunk<
  IMilestone,
  IMilestoneDTO,
  {
    serializedErrorType: IApiError;
  }
>(
  `${MILESTONES_SLICE_NAME}/updateMilestone`,
  async (data) => {
    const response = await MilestonesAPI.updateMilestone(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const removeMilestone = createAsyncThunk<
  IMilestone,
  IRemoveMilestoneDTO,
  {
    serializedErrorType: IApiError;
  }
>(
  `${MILESTONES_SLICE_NAME}/removeMilestone`,
  async (data) => {
    const response = await MilestonesAPI.removeMilestone(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getProjectMilestones = createAsyncThunk<
  IMilestone[],
  string,
  {
    serializedErrorType: IApiError;
  }
>(
  `${MILESTONES_SLICE_NAME}/getProjectMilestones`,
  async (projectId) => {
    const response = await MilestonesAPI.getProjectMilestones(projectId);
    return response.data;
  },
  { serializeError: formatApiError },
);
