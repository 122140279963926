// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledButton } from './FundingRoundCardStyles';
import {
  PayoutCardWrapper,
  StyledInfoWrapper,
  StyledTitle,
  StyledText,
  StyledArrowRightIcon,
  StyledLink,
  StyledBadge,
} from '../Styled/index';
import CompleteCampaign from '../CompleteCampaign/CompleteCampaign';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Models
import { IFundingPayoutListItem } from '@/models/payout.model';
import { FundingRoundStatuses } from '@/models/fundingRound.model';

// Helpers
import { getDateString, numberWithCommas } from '@/utils';
import { ROUTES_PATHS } from '@/router';
import { PayoutTabKeys } from '../../constants';

interface IFundingRoundCard {
  data: IFundingPayoutListItem;
  completeFundingRound: (fundingRoundId: string, isRequest?: boolean) => void;
}

const FundingRoundCard = ({
  data,
  completeFundingRound,
}: IFundingRoundCard) => {
  const { t } = useTranslation(['payout', 'campaigns']);
  const isCompleted = data.status === FundingRoundStatuses.COMPLETED;
  const isRequestsExists =
    data?.openedPayoutRequestId && data?.openedCreditsAndEthereumRequestId;

  const handleCompleteFundingRound = () => {
    completeFundingRound(data.id, isCompleted);
  };

  return (
    <PayoutCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_round_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.title}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_round_date_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{getDateString(data?.endDate ?? '')}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_round_target_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.fundingTarget ?? 0)} $</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_round_budget_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.raisedAmount ?? 0)} $</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>
            {t('payout_funding_round_total_payout_title')}
          </StyledTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.totalPayout ?? 0)} $</StyledText>
      </StyledInfoWrapper>

      {data.openedPayoutRequestId || data.openedCreditsAndEthereumRequestId ? (
        <StyledInfoWrapper />
      ) : (
        <StyledInfoWrapper>
          {data?.status === FundingRoundStatuses.COMPLETED &&
          isRequestsExists ? (
            <StyledBadge>
              {t(`campaign_status_${data?.status}`, { ns: 'campaigns' })}
            </StyledBadge>
          ) : (
            <CompleteCampaign
              handleComplete={handleCompleteFundingRound}
              buttonTitle={
                isCompleted
                  ? 'campaign_campaign_payout_request_title'
                  : 'campaign_finish_title'
              }
              modalTitle={
                isCompleted
                  ? 'campaign_payout_request_title'
                  : 'campaign_complete_funding_round_title'
              }
            />
          )}
        </StyledInfoWrapper>
      )}

      <StyledInfoWrapper>
        <StyledLink
          to={`${ROUTES_PATHS.PAYOUT}/${
            PayoutTabKeys.FUNDING_ROUND
          }/${encodeURIComponent(data?.id ?? '')}`}
        >
          <StyledButton type="ghost">
            {t('payout_go_to_approval_button')}
            <StyledArrowRightIcon />
          </StyledButton>
        </StyledLink>
      </StyledInfoWrapper>
    </PayoutCardWrapper>
  );
};

export default FundingRoundCard;
