import { UserRoles } from '@/models/admin.model';
import { CampaignTabKeys } from '@/modules/Campaigns/constants';
import { ROUTES_PATHS } from '@/router';

const getUserLink = (role?: string, userId?: string) => {
  switch (role) {
    case UserRoles.KOL:
      return `${ROUTES_PATHS.KOL_LIST}/${encodeURIComponent(
        userId ?? '',
      )}/general-info/`;
    case UserRoles.PARTNER:
      return `${ROUTES_PATHS.HUNTERS_LIST}/${encodeURIComponent(
        userId ?? '',
      )}/general-info`;
    case UserRoles.PROJECT:
      return `${ROUTES_PATHS.PROJECTS_LIST}/${encodeURIComponent(
        userId ?? '',
      )}/company-info`;
    default:
      return `${ROUTES_PATHS.CAMPAIGNS}/${encodeURIComponent(userId ?? '')}/${
        CampaignTabKeys.CAMPAIGN
      }`;
  }
};

export default getUserLink;
