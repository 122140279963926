import styled from 'styled-components';
import { DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button, Text, Title } from '@/ui';

export const Wrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-top: 1.5rem;
  align-items: flex-start;
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 60%;
  }
`;

export const StyledTitle = styled(Title)`
  ${pickThemeFontStyles('24', '24', 'BOLD')};
`;

export const StyledText = styled(Text)`
  flex: 1;
`;

export const StyledButton = styled(Button)`
  display: flex;
  &&& {
    max-width: 6rem;
    height: 2rem;
    ${pickThemeFontStyles('12', '16', 'SEMI_BOLD')};
    align-items: center;
  }
`;
