export enum DataListsEndpoints {
  SETTINGS = '/admin/settings',
  CRYPTO_CURRENCIES = '/admin/crypto-currencies',
  PROFILES = '/admin/profiles',
  GET_VERIFIED_KOLS = '/admin/kols/verified-kyc',
}

export enum DataLists {
  INDUSTRY = 'INDUSTRY',
  COMMUNITY = 'COMMUNITY',
  CRYPTO_CURRENCIES = 'CRYPTO_CURRENCIES',
  AFFILIATE_COMMISSION = 'AFFILIATE_COMMISSION',
  COMPANY_TYPE = 'COMPANY_TYPE',
  FIRST_STAGE_DAYS = 'FIRST_STAGE_DAYS',
  FOLLOWERS_MULTIPLIER = 'FOLLOWERS_MULTIPLIER',
  INVESTMENT_TARGET_BIAS_PERCENT = 'INVESTMENT_TARGET_BIAS_PERCENT',
  SOCIAL_NETWORK_PRICE = 'SOCIAL_NETWORK_PRICE',
  WALLET_NETWORK = 'WALLET_NETWORK',
}

export interface ICryptoCurrencyListItem {
  address: string;
  addressAdditionalData: boolean;
  coinId: string;
  createdAt: string;
  decimals: number;
  image: {
    large: string;
    small: string;
    thumb: string;
  };
  image_bk: {
    large: string;
    small: string;
    thumb: string;
  };
  isAllowed: boolean;
  isIgnorePriceVerification: boolean;
  isPayInAllowed: boolean;
  isPopular: boolean;
  isStable: boolean;
  isSuspended: boolean;
  name: string;
  network: {
    chainId: string;
    name: string;
  };
  symbol: string;
  uniqueId: string;
}

export interface IBaseDataListItem {
  value: string;
  label: string;
}

export interface IUserOption {
  id: string;
  alias: string;
}

export interface ICryptoCurrency {
  name: string;
  symbol: string;
  image?: string;
}

export interface INetworkConfig {
  name: string;
  symbol: string;
  image?: string;
}

export interface ICountryItem {
  name: string;
  code: string;
}

export interface IAffiliateCommission {
  defaultCommission: number;
  kolInvitesKolCommission: number;
  kolInvitesPartnerCommission: number;
  partnerInvitesPartnerCommission: number;
  projectInvitesKolDiscount: number;
  projectInvitesPartnerDiscount: number;
}

export interface ISocialNetworkPrice {
  facebook: number;
  instagram: number;
  twitter: number;
  youtube: number;
}

export interface ICommunity {
  id: number;
  value: string;
  disabled: boolean;
}

export interface ISettingsResponse {
  AFFILIATE_COMMISSION: IAffiliateCommission;
  COMPANY_TYPE: [];
  FIRST_STAGE_DAYS: number;
  FOLLOWERS_MULTIPLIER: number;
  INDUSTRY: [];
  COMMUNITY: ICommunity[];
  SOCIAL_NETWORK_PRICE: ISocialNetworkPrice;
  WALLET_NETWORK: [];
}

export interface IDataListsMeta {
  listType: DataLists;
}

export interface IGeographies {
  id: number;
  value: string;
  disabled?: boolean;
}

export interface IAverageSocialDataItem {
  [key: string]: number;
}

export interface IAverageSocialData {
  POST_PRICES: IAverageSocialDataItem;
  IMPRESSION: IAverageSocialDataItem;
}
