import styled from 'styled-components';
import { COLORS, pickThemeFontStyles, SHADOWS } from '@/theme';
import { ArrowRightIcon } from '@/icons';
import { BlockLayout, Button, Select } from '@/ui';
import { Form, FormItem } from '@/components';

export const StyledButton = styled(Button)`
  display: flex;
  margin-top: 2rem;
  &&& {
    max-width: 6.5rem;
    height: 2rem;
    background: ${COLORS.SOLITUDE_GRAY};
    color: ${COLORS.DARK_BLUE};
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const StyledSubmitButton = styled(Button)`
  display: flex;
  margin-top: 2rem;
  height: 2rem;
  max-width: 10rem;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledPreview = styled.div`
  background: ${COLORS.LIGHTEST_GRAY};
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 2rem;
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
  margin-right: 0.5rem;
  transform: rotate(180deg);
`;

export const StyledStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const StyledStatus = styled.div`
  display: flex;
  gap: 0.625rem;
  width: 16rem;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  svg {
    width: 1rem;
    height: 1rem;
    path {
      fill: ${COLORS.BLACK};
    }
  }
  &&&.ant-select {
    &&& .ant-select-selector {
      background: ${COLORS.LIGHTEST_GRAY};
      min-height: 1.5rem;
      border: 0;
      &&& .ant-select-selection-placeholder {
        ${pickThemeFontStyles('14', '14', 'SEMI_NORMAL')};
        margin: auto;
      }
      &&& .ant-select-selection-item {
        width: 1rem;
        ${pickThemeFontStyles('14', '16', 'SEMI_NORMAL')};
        margin: auto;
      }
    }
    &&& .ant-select-selection-overflow {
      padding: 0.3rem 4rem 0.3rem 0;
        .ant-select-selection-overflow-item {
          padding-bottom: 0.5rem;
          align-items: center;
          width: 100%;
        }
      }
    }
  }
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0;
  width: 100%;
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  margin-top: 2rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  :last-child {
    align-items: center;
    flex: 0.4;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 3;
  gap: 0.5rem;
  margin-top: 2rem;
  align-items: flex-start;
  position: relative;
  background: ${COLORS.WHITE};
  box-shadow: ${SHADOWS.MEDIUM};
  border-radius: 1rem;
  padding: 2rem;
`;
