import { createContext, useContext } from 'react';
import { FundingSteps, TLinkedStep } from '../types';
import { fundingRoundSteps } from '../constants';

export const StepsContext = createContext<{
  currentStep: TLinkedStep;
  goNext: () => void;
  goPrev: () => void;
  setCurrenStep: (value: number) => void;
}>({
  currentStep: fundingRoundSteps[FundingSteps.ELEVATOR_PITCH],
  goNext: () => {},
  goPrev: () => {},
  setCurrenStep: () => {},
});

export const useStepsContext = () => useContext(StepsContext);
