// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DesktopOnly, MobileOnly } from '@/modules/Adaptive';
import { InvestmentStatuses } from '@/constants';
import {
  SuccessCircleIcon,
  PendingIcon,
  XMarkCircleIcon,
  SuccessMobileIcon,
  PendingMobileIcon,
  XMarkMobileCircleIcon,
} from '@/icons';

interface IProps {
  status?: string;
}

const StatusIcon = ({ status }: IProps) => {
  const { t } = useTranslation(['common']);

  switch (status) {
    case InvestmentStatuses.ACCEPTED:
      return (
        <>
          <DesktopOnly>
            <SuccessCircleIcon />
          </DesktopOnly>
          <MobileOnly>
            <SuccessMobileIcon />
          </MobileOnly>
        </>
      );
    case InvestmentStatuses.IN_VERIFICATION:
    case InvestmentStatuses.IN_REVIEW:
    case InvestmentStatuses.WAITING_FOR_CONTRACT:
    case InvestmentStatuses.WAITING_FOR_PAYMENT:
    case InvestmentStatuses.PENDING:
      return (
        <>
          <DesktopOnly>
            <>
              <PendingIcon />
              {t(`investment_status_${status}`)}
            </>
          </DesktopOnly>
          <MobileOnly>
            <PendingMobileIcon />
          </MobileOnly>
        </>
      );
    default:
      return (
        <>
          <DesktopOnly>
            <>
              <XMarkCircleIcon />
              {t(`investment_status_${status}`)}
            </>
          </DesktopOnly>
          <MobileOnly>
            <XMarkMobileCircleIcon />
          </MobileOnly>
        </>
      );
  }
};

export default StatusIcon;
