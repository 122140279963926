// Global
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component
import { PendingIcon, SuccessCircleIcon, XMarkCircleIcon } from '@/icons';
import { StyledStatusBlockWrap } from './GeneralInfoStyles';
import { Text } from '@/ui';

// Helpers
import { KYCStatuses } from '@/constants';

interface IKYCStatus {
  status?: KYCStatuses | null;
}

const KYCStatus = ({ status }: IKYCStatus) => {
  const { t } = useTranslation(['kolsList']);

  switch (status) {
    case KYCStatuses.VERIFIED:
      return (
        <StyledStatusBlockWrap>
          <Text>{t('kols_general_info_KYC_verified')}</Text>
          <SuccessCircleIcon />
        </StyledStatusBlockWrap>
      );
    case KYCStatuses.CANCELED:
      return (
        <StyledStatusBlockWrap>
          <Text>{t('kols_general_info_KYC_canceled')}</Text>
          <XMarkCircleIcon />
        </StyledStatusBlockWrap>
      );
    case KYCStatuses.FINAL_REJECTED:
      return (
        <StyledStatusBlockWrap>
          <Text>{t('kols_general_info_KYC_final_rejected')}</Text>
          <XMarkCircleIcon />
        </StyledStatusBlockWrap>
      );
    case KYCStatuses.PENDING:
    default:
      return (
        <StyledStatusBlockWrap>
          <Text>{t('kols_general_info_KYC_pending')}</Text>
          <PendingIcon />
        </StyledStatusBlockWrap>
      );
  }
};

export default KYCStatus;
