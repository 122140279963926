// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Image } from '@/ui';
import {
  StyledImageWrapper,
  StyledTitle,
  StyledWrapper,
  StyledLayout,
} from './DeploymentInProgressStyles';

const DeploymentInProgress = () => {
  const { t } = useTranslation(['common']);
  return (
    <StyledLayout>
      <StyledWrapper>
        <StyledImageWrapper>
          <Image src={'/icons/main-logo-orange.svg'} alt="error image" />
        </StyledImageWrapper>
        <StyledTitle>{t('error_app_deployment')}</StyledTitle>
      </StyledWrapper>
    </StyledLayout>
  );
};

export default DeploymentInProgress;
