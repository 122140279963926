// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  BlockWrapper,
  NavigationWrapper,
  StyledButton,
  StyledHint,
  StyledInputWrapper,
  StyledText,
  StyledTitle,
} from '../Styled';
import { Link, StyledInput } from '@/ui';
import { WarningIcon } from '@/icons';

// Models
import { ICampaign } from '@/models/requests.model';

// Utils
import { ROUTES_PATHS } from '@/router';
import { getDateString } from '@/utils';

interface ICampaignDetails {
  campaign?: ICampaign;
}

const CampaignDetails = ({ campaign }: ICampaignDetails) => {
  const { t } = useTranslation(['requestsList']);

  return (
    <>
      <NavigationWrapper>
        <StyledTitle $type="h2">
          {t('requests_profile_campaign_label')}
        </StyledTitle>
        <Link
          to={`${ROUTES_PATHS.CAMPAIGNS}/${encodeURIComponent(
            campaign?.id ?? '',
          )}/campaign`}
          target="_blank"
        >
          <StyledButton type="primary">
            {t('verification_request_go_to_campaign')}
          </StyledButton>
        </Link>
      </NavigationWrapper>
      <StyledHint>
        <WarningIcon />
        {t('requests_profile_campaign_early_access_warn')}
      </StyledHint>
      <BlockWrapper>
        <StyledText>{t('verification_request_campaign_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={campaign?.title} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_campaign_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={campaign?.title} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_created_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput
            value={getDateString(campaign?.created ?? '')}
            disabled
          />
        </StyledInputWrapper>
      </BlockWrapper>
    </>
  );
};

export default CampaignDetails;
