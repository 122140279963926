import {
  IFundingContract,
  IFundingRound,
  initialFundingRound,
  initialFundingContract,
} from '@/models/fundingRound.model';
import { normalizeFundingRoundData } from './normalizeFundingRoundData';

export const getFundingContractFieldsToCheck = (
  fundingContract: IFundingContract,
) => {
  return {
    ...initialFundingContract,
    additionalDefinitions: fundingContract?.additionalDefinitions,
    excludedJurisdictions: fundingContract?.excludedJurisdictions,
  };
};

export const getFundingRoundFieldsToCheck = (fundingRound: IFundingRound) => {
  return {
    ...initialFundingRound,
    ...normalizeFundingRoundData(fundingRound),
  };
};
