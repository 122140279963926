// Globals
import React, { useCallback } from 'react';
import debounce from 'lodash.debounce';

// Components
import { useTranslation } from 'react-i18next';
import {
  StyledSlider,
  ChannelTitle,
  BlockWrapper,
  SliderWrapper,
  TitleWrap,
  StyledIconWrapper,
  StyledTextArea,
  StyledSubTitle,
  DescriptionWrap,
  StyledRow,
  StyledErrorText,
  LockWrapper,
  StyledIconWrap,
  TagsWrap,
  StyledTag,
  StyledSliderTooltip,
} from './SocialChannelSliderStyles';
import { LockIcon, LockOpenedIcon } from '@/icons';
import { HorizontalDivider } from '../Styled';

// Modules
import { socialChannelsConfig, getPostMeasureUnit } from '@/modules/KolsList';

// Models
import { IMarketingChannel } from '@/models/campaigns.model';
import { IAverageSocialData } from '@/models/settings.model';

// Utils | Hooks
import { capitalizeString, numberWithCommas } from '@/utils';
import { useAppDispatch } from '@/hooks';
import {
  changeSocialChannelPercent,
  updateSocialChannel,
} from '../../feature/slice';
import { TextLength } from '@/constants';

type TSocialChannelSlider = {
  item: IMarketingChannel;
  averageSocialData?: IAverageSocialData | null;
  marketingBudget?: number;
  withLocks?: boolean;
  disabled?: boolean;
};

const SocialChannelSlider = ({
  item,
  disabled,
  withLocks,
  marketingBudget,
  averageSocialData,
}: TSocialChannelSlider) => {
  const { t } = useTranslation(['campaigns']);
  const dispatch = useAppDispatch();
  const { name, percent, isEditable, description } = item;
  const amountInCurrency = (Number(marketingBudget) * (percent ?? 0)) / 100;
  const pricePerPost = averageSocialData?.POST_PRICES[item.name as string];
  const unitsOfMeasurement = getPostMeasureUnit(item?.name, true);

  const onChange = useCallback(
    debounce((value: number) => {
      if (disabled) return;
      dispatch(
        changeSocialChannelPercent({
          ...item,
          percent: Math.floor(value),
        }),
      );
    }, 100),
    [dispatch, item, disabled],
  );

  const onDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      dispatch(
        updateSocialChannel({
          ...item,
          description: value,
        }),
      );
    },
    [dispatch, item],
  );

  const toggleLock = (isEditable: boolean) => {
    dispatch(updateSocialChannel({ ...item, isEditable: isEditable }));
  };

  return (
    <>
      <BlockWrapper>
        <StyledRow>
          <TitleWrap>
            <StyledIconWrapper>
              {name && socialChannelsConfig[name]?.icon}
            </StyledIconWrapper>
            <ChannelTitle>{t(name ?? '', { ns: 'common' })}</ChannelTitle>
          </TitleWrap>
        </StyledRow>
      </BlockWrapper>
      <StyledErrorText>
        {percent === 0 && t('campaign_channel_error_text')}
      </StyledErrorText>

      <SliderWrapper>
        <StyledSlider
          value={percent}
          onChange={onChange}
          step={1}
          max={100}
          tooltip={{
            formatter: () => (
              <StyledSliderTooltip>{percent}%</StyledSliderTooltip>
            ),
          }}
        />
        {withLocks && (
          <LockWrapper>
            <StyledIconWrap
              isActive={!isEditable}
              onClick={() => toggleLock(false)}
            >
              <LockIcon />
            </StyledIconWrap>
            <StyledIconWrap
              isActive={!!isEditable}
              onClick={() => toggleLock(true)}
            >
              <LockOpenedIcon />
            </StyledIconWrap>
          </LockWrapper>
        )}
        <TagsWrap>
          <StyledTag>{percent}%</StyledTag>
        </TagsWrap>
        <TagsWrap>
          <StyledTag>${numberWithCommas(amountInCurrency)}</StyledTag>
          <StyledTag>
            {Math.floor(amountInCurrency / (pricePerPost ?? 0))}{' '}
            {capitalizeString(unitsOfMeasurement)}*
          </StyledTag>
        </TagsWrap>
      </SliderWrapper>
      <DescriptionWrap>
        <StyledSubTitle>
          {t('campaigns_details_channel_description_title')}
        </StyledSubTitle>
        <StyledTextArea
          rows={3}
          defaultValue={description}
          onChange={onDescriptionChange}
          disabled={disabled}
          showCount
          maxLength={TextLength.medium}
        />
      </DescriptionWrap>
      <HorizontalDivider />
    </>
  );
};

export default SocialChannelSlider;
