import {
  FacebookSVGIcon,
  FacebookDisabledIcon,
  FacebookGhostIcon,
  FacebookGhostFilledIcon,
  XTwitterIcon,
  XTwitterDisabledIcon,
  YoutubeSVGIcon,
  YoutubeDisabledIcon,
  YoutubeGhostIcon,
  YoutubeGhostFilledIcon,
  InstagramSVGIcon,
  InstagramDisabledIcon,
  InstagramGhostIcon,
  InstagramGhostFilledIcon,
  RedditSVGIcon,
  TiktokSVGIcon,
  TelegramSVGIcon,
  LinkedinSVGIcon,
  RedditDisabledSVGIcon,
  TiktokDisabledSVGIcon,
  TelegramDisabledSVGIcon,
  LinkedinDisabledIcon,
} from '@/icons';
import { COLORS } from '@/theme';
import {
  StyledFacebookIcon,
  StyledTwitterIcon,
  StyledInstagramIcon,
  StyledYoutubeIcon,
} from './styles';
import { SocialChannels } from '@/models/socialChannel.model';

export type TSocialChannelConfigs = {
  [key: string]: TSocialChannelConfig;
};

export type TSocialChannelConfig = {
  icon: JSX.Element;
  disabledIcon: JSX.Element;
  ghostIcon: JSX.Element;
  ghostFilledIcon: JSX.Element;
  styledHoverIcon: JSX.Element;
  color: string;
  postTypeName: string;
};

export const socialChannelsList = [
  SocialChannels.YOUTUBE,
  SocialChannels.TWITTER,
  SocialChannels.INSTAGRAM,
  SocialChannels.FACEBOOK,
  SocialChannels.TELEGRAM,
  SocialChannels.LINKEDIN,
  SocialChannels.REDDIT,
  SocialChannels.TIKTOK,
];

export const socialChannelsConfig: TSocialChannelConfigs = {
  [SocialChannels.FACEBOOK]: {
    icon: <FacebookSVGIcon />,
    disabledIcon: <FacebookDisabledIcon />,
    ghostIcon: <FacebookGhostIcon />,
    ghostFilledIcon: <FacebookGhostFilledIcon />,
    styledHoverIcon: <StyledFacebookIcon />,
    color: COLORS.AZUL,
    postTypeName: 'post',
  },
  [SocialChannels.TWITTER]: {
    icon: <XTwitterIcon />,
    disabledIcon: <XTwitterDisabledIcon />,
    ghostIcon: <XTwitterDisabledIcon />,
    ghostFilledIcon: <XTwitterDisabledIcon />,
    styledHoverIcon: <StyledTwitterIcon />,
    color: COLORS.CERULEAN,
    postTypeName: 'tweet',
  },
  [SocialChannels.YOUTUBE]: {
    icon: <YoutubeSVGIcon />,
    disabledIcon: <YoutubeDisabledIcon />,
    ghostIcon: <YoutubeGhostIcon />,
    ghostFilledIcon: <YoutubeGhostFilledIcon />,
    styledHoverIcon: <StyledYoutubeIcon />,
    color: COLORS.COQUELICOT,
    postTypeName: 'video',
  },
  [SocialChannels.INSTAGRAM]: {
    icon: <InstagramSVGIcon />,
    disabledIcon: <InstagramDisabledIcon />,
    ghostIcon: <InstagramGhostIcon />,
    ghostFilledIcon: <InstagramGhostFilledIcon />,
    styledHoverIcon: <StyledInstagramIcon />,
    color: COLORS.BRIGHT_ORANGE,
    postTypeName: 'post',
  },
  [SocialChannels.REDDIT]: {
    icon: <RedditSVGIcon />,
    disabledIcon: <RedditDisabledSVGIcon />,
    ghostIcon: <RedditDisabledSVGIcon />,
    ghostFilledIcon: <RedditDisabledSVGIcon />,
    styledHoverIcon: <RedditSVGIcon />,
    color: COLORS.BRIGHT_ORANGE,
    postTypeName: 'post',
  },
  [SocialChannels.TIKTOK]: {
    icon: <TiktokSVGIcon />,
    disabledIcon: <TiktokDisabledSVGIcon />,
    ghostIcon: <TiktokDisabledSVGIcon />,
    ghostFilledIcon: <TiktokSVGIcon />,
    styledHoverIcon: <TiktokSVGIcon />,
    color: COLORS.BRIGHT_ORANGE,
    postTypeName: 'post',
  },
  [SocialChannels.TELEGRAM]: {
    icon: <TelegramSVGIcon />,
    disabledIcon: <TelegramDisabledSVGIcon />,
    ghostIcon: <TelegramSVGIcon />,
    ghostFilledIcon: <TelegramSVGIcon />,
    styledHoverIcon: <TelegramSVGIcon />,
    color: COLORS.ALICE_BLUE_LIGHT,
    postTypeName: 'post',
  },
  [SocialChannels.LINKEDIN]: {
    icon: <LinkedinSVGIcon />,
    disabledIcon: <LinkedinDisabledIcon />,
    ghostIcon: <LinkedinSVGIcon />,
    ghostFilledIcon: <LinkedinSVGIcon />,
    styledHoverIcon: <LinkedinSVGIcon />,
    color: COLORS.BRIGHT_ORANGE,
    postTypeName: 'post',
  },
};
