import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledActionButton, StyledWrapper } from './ActionButtonsStyles';
import RejectModal from './RejectModal';
import AcceptModal from './AcceptModal';
import BlockModal from './BlockModal';

// Models
import {
  IRequestDetails,
  RequestActionsStatuses,
  RequestsTypesKeys,
} from '@/models/requests.model';
import { UserRoles } from '@/models/admin.model';

// Hooks
import { useAppDispatch, useModal } from '@/hooks';
import {
  getRequestDetails,
  patchRequestStatus,
} from '../../feature/actionCreators';
import { getRoleFromId, getUserId } from '@/utils';
import { REQUESTS_WITHOUT_REJECT } from '../../constants';

interface IActionButtons {
  request: IRequestDetails | null;
  withBlocking?: boolean;
  isLoading?: boolean;
}

const ActionButtons = ({
  request,
  withBlocking,
  isLoading,
}: IActionButtons) => {
  const { t } = useTranslation(['requestsList']);
  const { createdById, id, status, actionType } = request ?? {};
  const dispatch = useAppDispatch();
  const isRejectHidden = REQUESTS_WITHOUT_REJECT.has(
    actionType as RequestsTypesKeys,
  );

  const { isOpened, openModal, closeModal } = useModal();
  const {
    isOpened: isApprovedModalOpen,
    openModal: openApprovedModal,
    closeModal: closeApprovedModal,
  } = useModal();
  const {
    isOpened: isCloseModalOpen,
    openModal: openCloseModal,
    closeModal: closeCloseModal,
  } = useModal();
  const {
    isOpened: isBlockModalOpen,
    openModal: openBlockModal,
    closeModal: closeBlockModal,
  } = useModal();

  const handleState = async () => {
    await dispatch(
      patchRequestStatus({
        id: getUserId(id ?? ''),
        status: RequestActionsStatuses.IN_PROGRESS,
      }),
    ).unwrap();
    dispatch(getRequestDetails(getUserId(id ?? '')));
  };

  // TODO think about refactoring
  switch (status) {
    case RequestActionsStatuses.NEW:
      return (
        <StyledActionButton type="primary" onClick={handleState}>
          {t('verification_request_take_in_progress')}
        </StyledActionButton>
      );
    case RequestActionsStatuses.IN_PROGRESS:
      return (
        <StyledWrapper>
          {actionType === RequestsTypesKeys.INVESTMENT_REJECTED ? (
            <>
              <StyledActionButton type="default" onClick={openCloseModal}>
                {t('requests_close_status')}
              </StyledActionButton>
              <AcceptModal
                isOpened={isCloseModalOpen}
                handleClose={closeCloseModal}
                id={id}
                isLoading={isLoading}
              />
            </>
          ) : (
            <>
              <StyledActionButton type="primary" onClick={openApprovedModal}>
                {t('requests_approve_status')}
              </StyledActionButton>
              {!isRejectHidden && (
                <StyledActionButton type="default" onClick={openModal}>
                  {t('requests_in_reject_status')}
                </StyledActionButton>
              )}
              {getRoleFromId(createdById ?? '') !== UserRoles.PROJECT &&
                withBlocking && (
                  <StyledActionButton
                    type="primary"
                    danger
                    ghost
                    onClick={openBlockModal}
                  >
                    {t('requests_in_block_status')}
                  </StyledActionButton>
                )}
              <RejectModal
                isOpened={isOpened}
                handleClose={closeModal}
                id={id}
              />
              <BlockModal
                isOpened={isBlockModalOpen}
                handleClose={closeBlockModal}
                id={id}
              />
              <AcceptModal
                isApprove
                isOpened={isApprovedModalOpen}
                handleClose={closeApprovedModal}
                createdById={createdById}
                isLoading={isLoading}
                id={id}
              />
            </>
          )}
        </StyledWrapper>
      );
    case RequestActionsStatuses.APPROVED:
    case RequestActionsStatuses.REJECTED:
      return (
        <StyledWrapper>
          <StyledActionButton type="default" onClick={openCloseModal}>
            {t('requests_close_status')}
          </StyledActionButton>
          <AcceptModal
            isOpened={isCloseModalOpen}
            handleClose={closeCloseModal}
            isLoading={isLoading}
            id={id}
          />
        </StyledWrapper>
      );
    case RequestActionsStatuses.CLOSED:
    default:
      return <></>;
  }
};

export default ActionButtons;
