import React, { ReactNode } from 'react';
import { Layout as AntdLayout } from 'antd';

interface ILayoutComponent {
  className?: string;
  children?: ReactNode;
}

const LayoutFooter = (props: ILayoutComponent) => (
  <AntdLayout.Footer {...props} />
);

export default LayoutFooter;
