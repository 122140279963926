import styled, { css } from 'styled-components';
import { Slider, Title, Image, BlockLayout, Button } from '@/ui';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';

const logoStyles = css`
  border-radius: 0.7rem;
  width: 3.2rem;
  height: 3.2rem;
`;

const imageStyles = css`
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  opacity: 0.7;
  max-width: 100%;
  width: 100%;
`;

export const StyledImageWrapper = styled.div`
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 100%;
  background-color: ${COLORS.BLACK};
  margin-bottom: 1.5rem;
  position: relative;
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
  position: absolute;
  border-radius: 0.8rem;
  width: 3.5rem;
  top: 8rem;
  left: 1rem;
  justify-content: center;
  padding: 0.1rem;
  background-color: ${COLORS.WHITE};
`;

export const StyledImage = styled(Image)<{ isImage?: boolean }>`
  aspect-ratio: 2;
  object-fit: cover;
  ${({ isImage }) => (isImage ? imageStyles : logoStyles)}
`;

export const StyledSLiderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  ${pickThemeFontStyles('14', '20', 'NORMAL')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const SliderWrapper = styled.div`
  position: relative;
  margin-bottom: 0.5rem;
  width: 100%;
`;

export const StyledSubTitle = styled.div`
  ${pickThemeFontStyles('16', '26', 'BOLD')};
`;

export const StyledSlider = styled(Slider)<{ budgedPercentage?: number }>`
  width: 100%;
  position: absolute;
  height: 0.625rem;
  padding: 0rem;
  margin: 0rem;

  &&&.ant-slider .ant-slider-track {
    border-radius: 1rem;
    height: 0.625rem;
    ${({ budgedPercentage }) => {
      switch (true) {
        case (budgedPercentage ?? 0) < 25:
          return `
          background: linear-gradient(
            89.76deg,
            ${COLORS.GREEN} 2.3%,
            ${COLORS.GREEN_MINT} 97.85%
          );
        `;
        case (budgedPercentage ?? 0) >= 25 && (budgedPercentage ?? 0) < 75:
          return `
          background: linear-gradient(
            89.76deg,
            ${COLORS.CHIFFON} 2.3%,
            ${COLORS.PARIS_DAISY} 97.85%
          );
        `;
        default:
          return `background: linear-gradient(
            89.76deg,
            ${COLORS.BRIGHT_ORANGE} 2.3%,
            ${COLORS.HIGHLIGHTER_ORANGE} 97.85%
          );`;
      }
    }}
  }

  &&&.ant-slider .ant-slider-rail {
    height: 0.625rem;
    border: 1px solid ${COLORS.SOLITUDE_GRAY};
    border-radius: 1rem;
    background-color: ${COLORS.WHITE};
  }

  &&&.ant-slider .ant-slider-handle::after {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    width: 24px;
  }

  &&&.ant-slider .ant-slider-handle {
    display: none;
  }
`;

export const StyledTitle = styled(Title)`
  ${pickThemeFontStyles('18', '22', 'BOLD')};
  align-self: flex-start;
`;

export const StyledLabels = styled.div<{ $isColored?: boolean }>`
  display: flex;
  height: 1rem;
  align-items: center;
  border-radius: 0.375rem;
  padding: 0.3rem;
  ${pickThemeFontStyles('10', '16', 'BOLD')};
  color: ${COLORS.SOLITUDE_DARK};
  background: ${({ $isColored }) =>
    $isColored ? `${COLORS.SOLITUDE}` : `${COLORS.SOLITUDE_GRAY}`};
`;

export const CardWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  gap: 0.5rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

export const StyledColumnCardTitle = styled.div`
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  margin-bottom: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('16', '25', 'BOLD')};
    margin-bottom: 1rem;
  }
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledButton = styled(Button)`
  height: 2rem !important;
`;
