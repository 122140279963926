export const imageToString = async (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const imageUrl = reader.result as string;
      resolve(imageUrl);
    };
    reader.onerror = () => reject();
  });

const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    // needed to avoid cross-origin issues on CodeSandbox
    // image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export const validateFileSize = (imageSize: number, maxSize: number): boolean =>
  imageSize < maxSize;

export const validateImageResolution = async (
  imageSrc: string,
  maxWidth: number,
  maxHeight: number,
): Promise<boolean> => {
  try {
    const image = await createImage(imageSrc);
    return image.width <= maxWidth && image.height <= maxHeight;
  } catch (err) {
    return false;
  }
};
