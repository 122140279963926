// Globals
import React, { forwardRef } from 'react';
import {
  defaultCountries,
  parseCountry,
  PhoneInputProps,
} from 'react-international-phone';

// Components
import { StyledPhoneInput } from './PhoneInputStyles';

// Helpers
import { restrictedCountries } from '../../constants/geography';

const defaultCountriesList = defaultCountries.filter((country) => {
  const { iso2 } = parseCountry(country);
  return !restrictedCountries.has(iso2.toUpperCase());
});

const PhoneInput = ({ countries, disabled, ...props }: PhoneInputProps) => {
  return (
    <StyledPhoneInput
      {...props}
      disabled={disabled}
      defaultCountry="de"
      countries={countries ?? defaultCountriesList}
    />
  );
};

export default forwardRef(PhoneInput);
