// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { TextAreaProps } from 'antd/es/input';
import { useTranslation } from 'react-i18next';
import { ReactQuillProps } from 'react-quill';
import { StyledLabel, StyledTextAreaWrap } from './TextEditorStyles';
import Switch from '../Switch/Switch';
import HTMLEditor from '../HTMLEditor/HTMLEditor';
import TextEditor from './TextEditor';
import { useBoolean } from '@/hooks';

export interface ITextAreaProps extends Omit<TextAreaProps, 'onChange'> {
  onChange?: (v: string) => void;
  value?: string;
}
export interface ITextAreaQuillProps extends ReactQuillProps {
  onChange?: (v: string) => void;
  value?: string;
  disabled: boolean;
}

const TextEditorWithHtml = ({
  value,
  disabled,
  onChange,
  ...props
}: ITextAreaProps | ITextAreaQuillProps): JSX.Element => {
  const { t } = useTranslation(['common']);
  const { value: isHTMLMode, toggle: toggleHTMLMode } = useBoolean();
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    setDescription(value ?? props?.defaultValue);
  }, [value, props]);

  const onTextChange = (html: string) => {
    setDescription(html);
    onChange && onChange(html);
  };

  return (
    <StyledTextAreaWrap>
      <StyledLabel>
        {t('switch_to_HTML')}
        <Switch onChange={toggleHTMLMode} />
      </StyledLabel>
      {isHTMLMode ? (
        <HTMLEditor
          onChange={onTextChange}
          value={description ?? ''}
          {...props}
          disabled={disabled}
        />
      ) : (
        <TextEditor
          {...props}
          value={description}
          onChange={onTextChange}
          readOnly={disabled}
          defaultValue={props?.defaultValue}
        />
      )}
    </StyledTextAreaWrap>
  );
};

export default TextEditorWithHtml;
