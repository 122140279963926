import styled from 'styled-components';
import {
  FacebookSVGIcon,
  YoutubeSVGIcon,
  XTwitterIcon,
  InstagramSVGIcon,
} from '@/icons';
import { COLORS } from '@/theme';

export const StyledYoutubeIcon = styled(YoutubeSVGIcon)`
  path {
    &:not(:last-child) {
      fill: ${COLORS.SOLITUDE_GRAY};
    }
  }
  :hover {
    path {
      &:not(:last-child) {
        fill: ${COLORS.COQUELICOT};
      }
    }
  }
`;

export const StyledInstagramIcon = styled(InstagramSVGIcon)`
  path {
    fill: ${COLORS.SOLITUDE_GRAY};
  }
  :hover {
    path {
      fill: ${COLORS.PICH_FROLY};
    }
  }
`;

export const StyledTwitterIcon = styled(XTwitterIcon)`
  path {
    fill: ${COLORS.SOLITUDE_GRAY};
  }
  :hover {
    path {
      fill: ${COLORS.CERULEAN};
    }
  }
`;

export const StyledFacebookIcon = styled(FacebookSVGIcon)`
  path {
    &:not(:last-child) {
      fill: ${COLORS.SOLITUDE_GRAY};
    }
  }
  :hover {
    path {
      &:not(:last-child) {
        fill: ${COLORS.BLUE_PRIMARY};
      }
    }
  }
`;
