// Globals
import React from 'react';

// Components
import {
  StyledResourcesWrapper,
  StyledDocumentWrapper,
  StyledDownloadButton,
  StyledImage,
  StyledIconWrapper,
  StyledName,
} from './CampaignResourcesStyles';
import { CrossInCircleIcon } from '@/icons';
import { Tooltip } from '@/ui';

// Models
import { IResources } from '@/models/campaigns.model';

// Helpers | Utils
import { removeCampaignResource } from '../../feature/slice';
import { getFileTypeByName } from '@/utils';
import { useAppDispatch } from '@/hooks';
import { defineFileType } from '../../helpers';
import { GenericFileTypes, fileTypesConfig } from '../../constants';

interface IResource {
  resource: IResources;
  disabled: boolean;
}

const Resource = ({ resource, disabled }: IResource) => {
  const dispatch = useAppDispatch();
  const fileType = defineFileType(getFileTypeByName(resource.fileName));

  const removeResource = () => {
    dispatch(removeCampaignResource(resource));
  };

  return (
    <StyledResourcesWrapper>
      {!disabled && (
        <StyledIconWrapper onClick={removeResource}>
          <CrossInCircleIcon />
        </StyledIconWrapper>
      )}
      {fileType === GenericFileTypes.IMAGE ? (
        <StyledImage src={resource?.link} alt="image" />
      ) : (
        <StyledDocumentWrapper>
          <StyledDownloadButton href={resource?.link} target="blanc">
            {fileTypesConfig[fileType]?.['icon']}
          </StyledDownloadButton>
        </StyledDocumentWrapper>
      )}
      <Tooltip
        getPopupContainer={(triggerNode) => triggerNode}
        title={resource?.fileName}
        placement={'top'}
      >
        <StyledName>{resource?.fileName}</StyledName>
      </Tooltip>
    </StyledResourcesWrapper>
  );
};

export default Resource;
