import { IGetListParamsBase } from './api.model';
import {
  CampaignStatuses,
  IDocuments,
  ISocialChannels,
} from './campaigns.model';
import { FundingRoundStatuses } from './fundingRound.model';
import { RequestStatuses, RequestsTypesKeys } from './requests.model';
import { CryptoCurrenciesEnum, WalletNetworksEnum } from '@/models/web3.model';

export enum PayoutEndpoints {
  GET_PAYOUT_LIST = '/admin/payouts/campaigns',
  GET_FUNDING_PAYOUT_LIST = '/admin/payouts/funding-rounds',
  GET_FUNDING_REFUND_DETAILS = '/admin/refunds/funding-rounds',
  GET_FUNDING_REFUND_LIST = '/admin/refunds/funding-rounds',
  POST_PAYOUT_TRANSACTIONS = '/admin/payouts/transactions/participation-kol',
  POST_PAYOUT_FUNDING_TRANSACTIONS = '/admin/payouts/transactions/funding-rounds',
  POST_REFUND_FUNDING_TRANSACTIONS = '/admin/refunds/transactions/funding-rounds',
}

export enum PayoutStatuses {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
  REJECTED = 'rejected',
}

export enum RevenueTransferTypes {
  REVENUE = 'REVENUE',
  RECONCILIATION = 'RECONCILIATION',
}

export interface RevenueTransferItem {
  transferType: RevenueTransferTypes;
  currency: CryptoCurrenciesEnum;
  network: WalletNetworksEnum;
  walletAddress?: string;
  transferAmount: number;
  safeTransaction?: {
    id: string;
    sk: string;
    status: PayoutStatuses;
  };
}

export interface ICampaignResults {
  totalBookedPosts?: number;
  totalDeliveredPosts?: number;
  totalPayout?: number;
}

export interface IPayoutList {
  id: string;
  sk: string;
  projectId?: string;
  projectTitle?: string;
  title?: string;
  isPayoutsDone?: boolean;
  status?: CampaignStatuses;
  marketingEndDate?: string;
  campaignResults?: ICampaignResults;
  openedRequestId?: string;
}

export interface IFundingPayoutListItem {
  id: string;
  sk: string;
  projectId?: string;
  projectTitle?: string;
  endDate?: string;
  title?: string;
  fundingTarget?: number;
  raisedAmount?: number;
  totalPayout?: number;
  payoutRequest?: IPayoutRequest;
  status?: FundingRoundStatuses;
  openedPayoutRequestId?: string;
  openedCreditsAndEthereumRequestId?: string;
}

export interface IPayoutRequest {
  status: FundingRoundStatuses;
}

export interface IPayoutDetails {
  targetId: string;
  id: string;
  sk: string;
  projectId?: string;
  participantId?: string;
  campaignId?: string;
  alias?: string;
  totalPayoutAmount?: number;
  postContestRequestId?: string;
  campaignMarketingEndDate?: string;
  projectTitle?: string;
  campaignTitle?: string;
  payoutCurrency?: CryptoCurrenciesEnum;
  payoutNetwork?: WalletNetworksEnum;
  campaignStatus?: CampaignStatuses;
  payoutTransactionStatus?: PayoutStatuses;
  participantDocuments: IDocuments[];
  projectDocuments: IDocuments[];
}

export interface IRefundTransaction {
  id: string;
  sk: string;
  status?: PayoutStatuses;
}

export interface IRequest {
  id: string;
  status: RequestStatuses;
  actionType: RequestsTypesKeys;
}

export interface IBalanceAndTransfers {
  fundingTarget?: number;
  raisedBudgetTotal?: number;
  raisedBudgetPolygon?: number;
  raisedBudgetEthereum?: number;
  creditUsedTotal?: number;
  creditUsedPolygon?: number;
  creditUsedEthereum?: number;
  seedHunterCommissionPercent?: number;
  seedHunterCommissionAmountTotal?: number;
  seedHunterCommissionAmountPolygon?: number;
  seedHunterCommissionAmountEthereum?: number;
  initialBalancePolygon?: number;
  initialBalanceEthereum?: number;
  finalBalancePolygon?: number;
  finalBalanceEthereum?: number;
  projectTransferPolygon?: number;
  projectTransferEthereum?: number;
}

export interface FundingRoundPayoutItemsResponse {
  payouts?: IFundingPayoutItem[];
  revenueTransfers?: RevenueTransferItem[];
}

export interface IFundingPayoutItem {
  id: string;
  sk: string;
  projectId?: string;
  projectTitle?: string;
  projectSocialChannel?: ISocialChannels[];

  fundingRoundId: string;
  fundingRoundTitle?: string;
  fundingRoundEndDate?: string;
  fundingRoundStatus?: FundingRoundStatuses;

  currency: CryptoCurrenciesEnum;
  network: WalletNetworksEnum;
  walletAddress?: string;

  transferAmount?: number;

  safeTransaction?: {
    id?: string;
    sk?: string;
    status?: PayoutStatuses;
  };

  payoutRequest?: IRequest;
  creditsAndEthereumRequest?: IRequest;
}

// ============= DTO =============

export interface IPayoutTransactionDTO {
  payoutId?: string;
  payoutSk?: string;
}

export interface IPayoutFundingRoundTransactionDTO {
  fundingRoundId: string;
  payoutId: string;
  payoutSk: string;
}

export interface IPayoutDetailsListDTO extends IGetListParamsBase {
  campaignId?: string;
  startSk?: string;
}

export interface IFundingRoundRequestDTO {
  fundingRoundId?: string;
}

// ============== Response ==============

export interface IPayoutDetailsListResponse {
  items: IPayoutDetails[];
  revenueTransfers: RevenueTransferItem[];
  hasMore: boolean;
}
