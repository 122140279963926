// Globals
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'antd/lib/form/Form';

// Components
import {
  StyledInfo,
  StyledDetails,
  StyledForm,
  StyledFormItem,
  StyledButton,
  StyledButtonWrapper,
  Wrapper,
} from './GeneralInfoEditBlockStyles';
import { StyledInput, StyledSelect } from '@/ui';
import { IFormInstance } from '@/components';

// Models
import { IKOL } from '@/models/kolsList.model';

// Modules
import { selectWalletNetwork } from '@/modules/DataLists';
import {
  ContactInfoBlock,
  useGetCurrency,
  FormOfAddressConfig,
  getCountryOptions,
} from '@/modules/SharedProfile';

// Hooks | Helpers
import { GeneralInfoFieldsNames } from '../../helpers';
import { useGetFormValidationRules } from '../../hooks';
import { AccountStatuses, countries } from '@/constants';
import { useAppState } from '@/hooks';
import { getSelectOptions } from '@/utils';

interface IGeneralInfoEdit {
  formInstance: IFormInstance;
  kolDetails: IKOL | null;
  handleSubmit: (values: IKOL) => void;
}

const GeneralInfoEditBlock = ({
  kolDetails,
  handleSubmit,
  formInstance,
}: IGeneralInfoEdit) => {
  const { t } = useTranslation(['kolsList']);
  const isDisabled = kolDetails?.status === AccountStatuses.BLOCKED;
  const validationRules = useGetFormValidationRules();
  const walletNetwork = useAppState(selectWalletNetwork);
  const initialWalletNetwork = useWatch(
    GeneralInfoFieldsNames.WALLET_NETWORK,
    formInstance,
  );
  const { cryptoCurrenciesList, handleChange } =
    useGetCurrency(initialWalletNetwork);

  const handleNetworkChange = (item: string | number | string[] | number[]) => {
    handleChange(item);
  };

  useEffect(() => {
    formInstance.setFieldsValue(kolDetails);
  }, [kolDetails]);

  return (
    <StyledForm
      onFinish={handleSubmit}
      form={formInstance}
      initialValues={kolDetails || undefined}
    >
      <Wrapper>
        <StyledInfo>
          <StyledDetails>
            <StyledFormItem
              name={GeneralInfoFieldsNames.FIRST_NAME}
              rules={validationRules[GeneralInfoFieldsNames.FIRST_NAME]}
              label={t('kols_general_info_first_name_title')}
            >
              <StyledInput
                defaultValue={kolDetails?.firstName}
                disabled={isDisabled}
              />
            </StyledFormItem>
          </StyledDetails>

          <StyledDetails>
            <StyledFormItem
              name={GeneralInfoFieldsNames.LAST_NAME}
              rules={validationRules[GeneralInfoFieldsNames.LAST_NAME]}
              label={t('kols_general_info_last_name_title')}
            >
              <StyledInput
                defaultValue={kolDetails?.lastName}
                disabled={isDisabled}
              />
            </StyledFormItem>
          </StyledDetails>

          <StyledDetails>
            <StyledFormItem
              name={GeneralInfoFieldsNames.FORM_OF_ADDRESS}
              label={t('kols_general_info_person_title')}
              rules={validationRules[GeneralInfoFieldsNames.FORM_OF_ADDRESS]}
            >
              <StyledSelect
                options={FormOfAddressConfig}
                disabled={isDisabled}
              />
            </StyledFormItem>
          </StyledDetails>

          <StyledDetails>
            <StyledFormItem
              name={GeneralInfoFieldsNames.ALIAS}
              rules={validationRules[GeneralInfoFieldsNames.ALIAS]}
              label={t('kols_general_info_alias_title')}
            >
              <StyledInput
                defaultValue={kolDetails?.alias}
                disabled={isDisabled}
              />
            </StyledFormItem>
          </StyledDetails>

          <StyledDetails>
            <StyledFormItem
              name={GeneralInfoFieldsNames.DOCUMENT_NUMBER}
              rules={validationRules[GeneralInfoFieldsNames.DOCUMENT_NUMBER]}
              label={t('kols_general_info_document_number')}
            >
              <StyledInput
                defaultValue={kolDetails?.KYC?.documentNumber}
                disabled={isDisabled}
              />
            </StyledFormItem>
          </StyledDetails>
          <StyledDetails>
            <StyledFormItem
              name={GeneralInfoFieldsNames.NATIONALITY}
              rules={validationRules[GeneralInfoFieldsNames.NATIONALITY]}
              label={t('kols_general_info_nationality')}
            >
              <StyledInput
                defaultValue={kolDetails?.KYC?.nationality}
                disabled={isDisabled}
              />
            </StyledFormItem>
          </StyledDetails>
          {/*TODO: Clarify about upload necessary*/}
          {/*  <StyledDetails>*/}
          {/*    <StyledFormItem*/}
          {/*      name={GeneralInfoFieldsNames.DOCUMENT_NUMBER_PROOF}*/}
          {/*      label={t('kols_general_info_ID_proof')}*/}
          {/*    >*/}
          {/*      <FileUpload*/}
          {/*        fileMaxSize={MAX_IMAGE_SIZE}*/}
          {/*        acceptance={DOCS_MIME_TYPES}*/}
          {/*        isLoading={fileLoading}*/}
          {/*      >*/}
          {/*        <StyledUploadFilePlaceholder>*/}
          {/*          <UploadIcon />*/}
          {/*          {t('file_upload_placeholder', { ns: 'common' })}*/}
          {/*        </StyledUploadFilePlaceholder>*/}
          {/*      </FileUpload>*/}
          {/*    </StyledFormItem>*/}
          {/*  </StyledDetails>*/}
        </StyledInfo>

        <StyledInfo>
          <StyledDetails>
            <StyledFormItem
              name={GeneralInfoFieldsNames.COUNTRY}
              label={t('kols_general_info_country_title')}
            >
              <StyledSelect
                options={getCountryOptions(countries)}
                defaultValue={kolDetails?.country}
                disabled={isDisabled}
              />
            </StyledFormItem>
            <StyledFormItem
              name={GeneralInfoFieldsNames.CITY}
              rules={validationRules[GeneralInfoFieldsNames.ADDRESS]}
              label={t('kols_general_info_city_title')}
            >
              <StyledInput
                defaultValue={kolDetails?.city}
                disabled={isDisabled}
              />
            </StyledFormItem>
          </StyledDetails>
        </StyledInfo>

        <StyledInfo>
          <StyledDetails>
            <StyledFormItem
              name={GeneralInfoFieldsNames.WALLET_ADDRESS}
              rules={validationRules[GeneralInfoFieldsNames.WALLET_ADDRESS]}
              label={t('kols_general_info_wallet_address')}
            >
              <StyledInput
                defaultValue={kolDetails?.walletAddress}
                disabled={isDisabled}
              />
            </StyledFormItem>
          </StyledDetails>
          <StyledDetails>
            <StyledFormItem
              name={GeneralInfoFieldsNames.WALLET_NETWORK}
              label={t('kols_general_info_wallet_network')}
              rules={validationRules[GeneralInfoFieldsNames.WALLET_NETWORK]}
            >
              <StyledSelect
                options={getSelectOptions(walletNetwork ?? [])}
                disabled={isDisabled}
                onChange={handleNetworkChange}
              />
            </StyledFormItem>
          </StyledDetails>
          <StyledDetails>
            <StyledFormItem
              name={GeneralInfoFieldsNames.WALLET_CURRENCY}
              label={t('kols_general_info_wallet_currency')}
              rules={validationRules[GeneralInfoFieldsNames.WALLET_CURRENCY]}
            >
              <StyledSelect
                options={cryptoCurrenciesList ?? []}
                disabled={isDisabled}
              />
            </StyledFormItem>
          </StyledDetails>
        </StyledInfo>
      </Wrapper>
      <StyledInfo>
        <ContactInfoBlock formInstance={formInstance} />
      </StyledInfo>
      <StyledButtonWrapper>
        <StyledButton type="primary" htmlType="submit">
          {t('edit_save')}
        </StyledButton>
      </StyledButtonWrapper>
    </StyledForm>
  );
};

export default GeneralInfoEditBlock;
