// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  Wrapper,
  BlockWrapper,
  StyledText,
  StyledInputWrapper,
  NavigationWrapper,
  StyledButton,
  StyledTitle,
  StyledTextArea,
  SectionWrapper,
  StyledHeaderWrapper,
} from './CheckInvestmentRequestsDetailsStyles';
import { Link, StyledInput } from '@/ui';
import ActionButtons from '../ActionButtons/ActionButtons';
import RequestStatusBadges from '../RequestStatusBadges/RequestStatusBadges';
import SocialChannelsInvestment from './SocialChannelsInvestments';

// Models
import { IRequestDetails, RequestsTypesKeys } from '@/models/requests.model';

// Helpers | Hooks
import { getDateString, getUserLink } from '@/utils';

interface ICheckInvestmentRequestsDetails {
  request: IRequestDetails | null;
  isLoading?: boolean;
}

const CheckInvestmentRequestsDetails = ({
  request,
  isLoading,
}: ICheckInvestmentRequestsDetails) => {
  const { t } = useTranslation(['requestsList']);
  const { firstName, lastName, created, email, mainContact, role, id, title } =
    request?.project ?? {};
  const {
    firstName: kolFirstName,
    lastName: kolLastName,
    email: kolEmail,
    mainContact: kolMainContact,
    role: kolRole,
    id: kolId,
  } = request?.kol ?? {};
  const { campaignId, campaignTitle, totalParticipationAmount } =
    request?.participation ?? {};
  const isCampaignParticipation =
    request?.actionType === RequestsTypesKeys.CAMPAIGN_PARTICIPATION_REJECT;

  const goToKol = getUserLink(kolRole ?? '', kolId ?? '');

  const goToProject = getUserLink(role ?? '', id ?? '');

  const goToCampaign = getUserLink('', campaignId ?? '');
  const actionTypeLabel = t(`requests_${request?.actionType}_label`);

  return (
    <Wrapper>
      <StyledHeaderWrapper>
        <StyledTitle>{t('request_title')}</StyledTitle>
        <RequestStatusBadges status={request?.status} />
      </StyledHeaderWrapper>
      <StyledText>{`${t('requests_type')} ${actionTypeLabel}`}</StyledText>
      <SectionWrapper>
        <NavigationWrapper>
          <StyledTitle $type="h2">{title}</StyledTitle>
          <Link to={goToProject ?? ''} target="_blank">
            <StyledButton type="primary">
              {t('check_investment_go_to_profile')}
            </StyledButton>
          </Link>
        </NavigationWrapper>
        <BlockWrapper>
          <StyledText>{t('check_investment_name_title')}</StyledText>
          <StyledInputWrapper>
            <StyledInput value={firstName} disabled />
          </StyledInputWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <StyledText>{t('check_investment_last_name_title')}</StyledText>
          <StyledInputWrapper>
            <StyledInput value={lastName} disabled />
          </StyledInputWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <StyledText>{t('check_investment_registration_title')}</StyledText>
          <StyledInputWrapper>
            <StyledInput value={getDateString(created ?? '')} disabled />
          </StyledInputWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <StyledText>{t('check_investment_email_title')}</StyledText>
          <StyledInputWrapper>
            <StyledInput value={email} disabled />
          </StyledInputWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <StyledText>{t('check_investment_contact_source')}</StyledText>
          <StyledInputWrapper>
            <StyledInput value={mainContact?.source} disabled />
          </StyledInputWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <StyledText>{t('check_investment_contact')}</StyledText>
          <StyledInputWrapper>
            <StyledInput value={mainContact?.contact} disabled />
          </StyledInputWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <StyledText>{t('check_investment_decline_reason')}</StyledText>
          <StyledInputWrapper>
            <StyledTextArea value={request?.message} disabled rows={3} />
          </StyledInputWrapper>
        </BlockWrapper>
      </SectionWrapper>
      <StyledTitle>{t('check_investment_info')}</StyledTitle>

      {isCampaignParticipation && (
        <SectionWrapper>
          <BlockWrapper>
            <StyledText>{t('check_investment_campaign_id')}</StyledText>
            <StyledInputWrapper>
              <StyledText>{campaignId}</StyledText>
              <Link to={goToCampaign ?? ''} target="_blank">
                <StyledButton type="primary">
                  {t('check_investment_go_to_profile')}
                </StyledButton>
              </Link>
            </StyledInputWrapper>
          </BlockWrapper>
          <BlockWrapper>
            <StyledText>{t('check_investment_title')}</StyledText>
            <StyledInputWrapper>
              <StyledInput value={campaignTitle} disabled />
            </StyledInputWrapper>
          </BlockWrapper>
          <BlockWrapper>
            <StyledText>
              {t('check_investment_total_participation_amount')}
            </StyledText>
            <StyledInputWrapper>
              <StyledInput value={totalParticipationAmount} disabled />
            </StyledInputWrapper>
          </BlockWrapper>
        </SectionWrapper>
      )}

      <SectionWrapper>
        <BlockWrapper>
          <StyledText>{t('check_investment_kol_id')}</StyledText>
          <StyledInputWrapper>
            <StyledText>{kolId}</StyledText>
            <Link to={goToKol ?? ''} target="_blank">
              <StyledButton type="primary">
                {t('check_investment_go_to_profile')}
              </StyledButton>
            </Link>
          </StyledInputWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <StyledText>{t('check_investment_name_title')}</StyledText>
          <StyledInputWrapper>
            <StyledInput value={kolFirstName} disabled />
          </StyledInputWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <StyledText>{t('check_investment_last_name_title')}</StyledText>
          <StyledInputWrapper>
            <StyledInput value={kolLastName} disabled />
          </StyledInputWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <StyledText>{t('check_investment_email_title')}</StyledText>
          <StyledInputWrapper>
            <StyledInput value={kolEmail} disabled />
          </StyledInputWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <StyledText>{t('check_investment_contact_source')}</StyledText>
          <StyledInputWrapper>
            <StyledInput value={kolMainContact?.source} disabled />
          </StyledInputWrapper>
        </BlockWrapper>
        <BlockWrapper>
          <StyledText>{t('check_investment_contact')}</StyledText>
          <StyledInputWrapper>
            <StyledInput value={kolMainContact?.contact} disabled />
          </StyledInputWrapper>
        </BlockWrapper>
      </SectionWrapper>

      {request?.socialChannelInvestment && (
        <SectionWrapper>
          <StyledTitle>
            {t('check_investment_marketing_investment')}
          </StyledTitle>
          <SocialChannelsInvestment
            channelsInvestment={request?.socialChannelInvestment}
          />
        </SectionWrapper>
      )}

      <ActionButtons request={request} isLoading={isLoading} />
    </Wrapper>
  );
};

export default CheckInvestmentRequestsDetails;
