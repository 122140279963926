// Globals
import React, { ReactNode, useCallback } from 'react';
import type { UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';

// Components
import { StyledDragger } from './PureImageUploaderStyle';
import { showErrorToast } from '@/components';

// Helpers
import {
  validateImageResolution,
  validateFileSize,
} from '../../helpers/imageUtils';

interface IPureImageUploader extends Omit<UploadProps, 'onChange'> {
  acceptance?: string;
  imageMaxSize: number;
  imageMaxWidth?: number;
  maxCount?: number;
  imageMaxHeight?: number;
  showUploadList?: boolean;
  onChange?: (file: File | Blob, imageUrl: string) => void;
  handleShowError?: (hasError: boolean) => void;
  children: ReactNode;
}

const PureImageUploader = ({
  onChange,
  acceptance,
  imageMaxSize,
  imageMaxWidth,
  imageMaxHeight,
  maxCount = 1,
  showUploadList = true,
  handleShowError,
  children,
  ...props
}: IPureImageUploader) => {
  const { t } = useTranslation(['common']);

  const handleSelectFile = useCallback(
    async (image: Blob) => {
      handleShowError?.(false);
      const sizeIsValid = validateFileSize(image.size, imageMaxSize);

      if (!sizeIsValid) {
        showErrorToast({
          message: t('error_file_size', { limit: imageMaxSize / 1024 / 1024 }),
        });
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = async () => {
          const imageUrl = reader.result as string;

          if (imageMaxWidth && imageMaxHeight) {
            const isResolutionValid = await validateImageResolution(
              imageUrl,
              imageMaxWidth,
              imageMaxHeight,
            );

            if (!isResolutionValid) {
              handleShowError?.(true);
            }
          }

          onChange?.(image, imageUrl);
        };
      }

      return false;
    },
    [handleShowError, imageMaxHeight, imageMaxSize, imageMaxWidth, onChange, t],
  );

  return (
    <StyledDragger
      beforeUpload={handleSelectFile}
      maxCount={maxCount}
      multiple={maxCount > 1}
      showUploadList={showUploadList}
      accept={acceptance}
      {...props}
    >
      {children}
    </StyledDragger>
  );
};

export default PureImageUploader;
