import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { AUTH_SLICE_NAME } from './models';

import { AuthAPI } from '@/api';
import { appCookiesStorage, formatApiError } from '@/utils';
import {
  ISignInAuthorizedResponse,
  ISignInDTO,
  ISignInResponse,
  ISignInVerifyDTO,
} from '@/models/auth.model';
import { REFRESH_TOKEN_STORAGE_KEY } from '@/constants';

export const signIn = createAsyncThunk<
  ISignInResponse,
  ISignInDTO,
  { serializedErrorType: IApiError }
>(
  `${AUTH_SLICE_NAME}/signIn`,
  async (data: ISignInDTO) => {
    const response = await AuthAPI.signIn(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const signInVerify = createAsyncThunk<
  ISignInResponse & ISignInAuthorizedResponse,
  ISignInVerifyDTO,
  { serializedErrorType: IApiError }
>(
  `${AUTH_SLICE_NAME}/signInVerify`,
  async (data: ISignInVerifyDTO) => {
    const response = await AuthAPI.signInVerify(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const logOut = createAsyncThunk<
  unknown,
  undefined,
  { serializedErrorType: IApiError }
>(
  `${AUTH_SLICE_NAME}/logOut`,
  async () => {
    const refreshToken = appCookiesStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);

    if (!refreshToken) {
      throw new Error('Refresh token is not found');
    }

    const response = await AuthAPI.logOut({ refreshToken });
    return response.data;
  },
  { serializeError: formatApiError },
);
