// Globals
import React from 'react';
import { useWatch } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledDetailsWrapper,
  StyledInfo,
  StyledFormItem,
  StyledInfoWrapper,
  StyledSelect,
} from './ProjectInformationDetailsStyles';
import { StyledInput } from '@/ui';
import { IFormInstance } from '@/components';

// Models
import { IProject } from '@/models/projects.model';

// Modules
import { selectCommunity, selectGeography } from '@/modules/DataLists';

// Hooks | Helpers
import { ProjectFieldsNames } from '../../types';
import { useGetFormValidationRules } from '../../hooks';
import { useAppState } from '@/hooks';
import { getCommunityOptions } from '@/utils';
import { isProjectDisabled } from '../../helpers';

interface IProjectInformationDetails {
  projectDetails: IProject | null;
  isEditActive: boolean;
  formInstance: IFormInstance;
}

const ProjectInformationDetails = ({
  projectDetails,
  isEditActive,
  formInstance,
}: IProjectInformationDetails) => {
  const { t } = useTranslation(['projectsList']);
  const mainCommunity = useWatch(
    ProjectFieldsNames.MAIN_COMMUNITY,
    formInstance,
  );
  const mainGeography = useWatch(
    ProjectFieldsNames.PRIMARY_GEOGRAPHY,
    formInstance,
  );
  const secondaryCommunity = useWatch(
    ProjectFieldsNames.SECONDARY_COMMUNITY,
    formInstance,
  );
  const secondaryGeography = useWatch(
    ProjectFieldsNames.SECONDARY_GEOGRAPHY,
    formInstance,
  );
  const validationRules = useGetFormValidationRules();
  const community = useAppState(selectCommunity);
  const geography = useAppState(selectGeography);

  const isFieldsDisabled =
    !isEditActive || isProjectDisabled(projectDetails?.status);

  return (
    <StyledDetailsWrapper>
      <StyledInfoWrapper>
        <StyledInfo>
          <StyledFormItem
            name={ProjectFieldsNames.WEBSITE}
            label={t('projects_information_website_title')}
            rules={validationRules[ProjectFieldsNames.WEBSITE]}
          >
            <StyledInput disabled={isFieldsDisabled} />
          </StyledFormItem>
        </StyledInfo>
        <StyledInfo>
          <StyledFormItem
            name={ProjectFieldsNames.MAIN_COMMUNITY}
            label={t('milestones_primary_community_label')}
            rules={validationRules[ProjectFieldsNames.MAIN_COMMUNITY]}
          >
            <StyledSelect
              options={getCommunityOptions(community ?? [], secondaryCommunity)}
              placeholder={t('milestones_primary_community_placeholder')}
              disabled={isFieldsDisabled}
            />
          </StyledFormItem>

          <StyledFormItem
            name={ProjectFieldsNames.SECONDARY_COMMUNITY}
            label={t('milestones_secondary_community_label')}
            rules={validationRules[ProjectFieldsNames.SECONDARY_COMMUNITY]}
          >
            <StyledSelect
              options={getCommunityOptions(community ?? [], mainCommunity)}
              placeholder={t('milestones_second_community_placeholder')}
              disabled={isFieldsDisabled}
            />
          </StyledFormItem>
        </StyledInfo>
        <StyledInfo>
          <StyledFormItem
            name={ProjectFieldsNames.PRIMARY_GEOGRAPHY}
            label={t('milestones_primary_geography_label')}
            rules={validationRules[ProjectFieldsNames.PRIMARY_GEOGRAPHY]}
          >
            <StyledSelect
              options={getCommunityOptions(geography ?? [], secondaryGeography)}
              placeholder={t('milestones_primary_geography_placeholder')}
              disabled={isFieldsDisabled}
              showSearch
            />
          </StyledFormItem>
          <StyledFormItem
            name={ProjectFieldsNames.SECONDARY_GEOGRAPHY}
            label={t('milestones_secondary_geography_label')}
            rules={validationRules[ProjectFieldsNames.SECONDARY_GEOGRAPHY]}
          >
            <StyledSelect
              options={getCommunityOptions(geography ?? [], mainGeography)}
              placeholder={t('milestones_secondary_geography_placeholder')}
              disabled={isFieldsDisabled}
              showSearch
            />
          </StyledFormItem>
        </StyledInfo>
      </StyledInfoWrapper>
    </StyledDetailsWrapper>
  );
};

export default ProjectInformationDetails;
