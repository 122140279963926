// Globals
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import {
  StyledButton,
  StyledArrowRightIcon,
  StyledStatusWrapper,
  StyledPreview,
} from './DocumentDetailsContainerStyles';
import { Title, StyledPageWrapper, Spinner, StyledSpinnerWrap } from '@/ui';

// Modules
import { getClickWrapTemplate } from '@/modules/Documents';

// Models
import { DocumentTypes } from '@/models/documents.model';

// Hooks | Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import { ROUTES_PATHS } from '@/router';
import { documentsSliceSelector } from '../../feature/selectors';

const DocumentDetailsContainer = () => {
  const { t } = useTranslation(['documents']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: type } = useParams();
  const { clickWrapTemplateDetails, isLoading } = useAppState(
    documentsSliceSelector,
  );
  const content = clickWrapTemplateDetails?.htmlContent
    ? String(clickWrapTemplateDetails?.htmlContent)
    : '';

  const navigateToDocumentsList = () => {
    navigate(ROUTES_PATHS.DOCUMENTS);
  };

  useEffect(() => {
    dispatch(getClickWrapTemplate(type as DocumentTypes));
  }, []);

  if (isLoading) {
    return (
      <StyledSpinnerWrap>
        <Spinner />
      </StyledSpinnerWrap>
    );
  }

  return (
    <StyledPageWrapper>
      <StyledStatusWrapper>
        <Title $type="h3">
          {t(clickWrapTemplateDetails?.templateType ?? '')}
        </Title>
      </StyledStatusWrapper>
      <StyledButton type="ghost" onClick={navigateToDocumentsList}>
        <StyledArrowRightIcon />
        {t('documents_go_back')}
      </StyledButton>
      <StyledPreview dangerouslySetInnerHTML={{ __html: content }} />
    </StyledPageWrapper>
  );
};

export default DocumentDetailsContainer;
