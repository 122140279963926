import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppState } from '@/hooks';
import { AdminActionsEnum } from '@/models/adminsList.model';
import {
  selectAdminPermissions,
  selectPermissionIsLoading,
  usePermissions,
} from '@/modules/Permissions';
import { ROUTES_PATHS } from '@/router';
import { showErrorToast } from '@/components';

const usePermissionsRedirect = (action: AdminActionsEnum) => {
  const [isAllowed] = usePermissions(action);
  const { t } = useTranslation('common');
  const adminPermissions = useAppState(selectAdminPermissions);
  const loading = useAppState(selectPermissionIsLoading);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAllowed && !loading && adminPermissions !== null) {
      showErrorToast({
        message: t('error_permissions_not_allowed_view'),
      });

      navigate(ROUTES_PATHS.HOME);
    }
  }, [loading]);
};

export default usePermissionsRedirect;
