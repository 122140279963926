import { FilmIcon, TextFileIcon } from '@/icons';

export type TFileTypesConfigs = {
  [key: string]: TFileTypesConfig;
};

export type TFileTypesConfig = {
  icon: JSX.Element;
};

export enum GenericFileTypes {
  VIDEO = 'video',
  FILE = 'file',
  IMAGE = 'image',
}

export const fileTypesConfig: TFileTypesConfigs = {
  [GenericFileTypes.VIDEO]: {
    icon: <FilmIcon />,
  },
  [GenericFileTypes.FILE]: {
    icon: <TextFileIcon />,
  },
};
