// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import FundingReportCard from '../FundingReportCard/FundingReportCard';
import {
  ReportOverviewWrapper,
  StyledTitles,
  StyledInfoTitles,
  BlockTitle,
} from './FundingRoundOverviewStyles';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { TFundingRoundsReportGeneralDetailData } from '@/models/reports.model';

interface IFundingReportsList {
  report: TFundingRoundsReportGeneralDetailData;
}

const FundingRoundOverview = ({ report }: IFundingReportsList) => {
  const { t } = useTranslation(['reports']);

  return (
    <ReportOverviewWrapper>
      <BlockTitle>{t('reports_funding_overview')}</BlockTitle>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('reports_list_project_title')}</StyledTitles>
          <StyledTitles>{t('reports_list_funding_title')}</StyledTitles>
          <StyledTitles>{t('reports_list_funding_target_title')}</StyledTitles>
          <StyledTitles>{t('reports_list_funding_raised_title')}</StyledTitles>
          <StyledTitles>{t('reports_list_network_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      <FundingReportCard report={report} key={report.id} />
    </ReportOverviewWrapper>
  );
};

export default FundingRoundOverview;
