// Global
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component
import {
  StyledInfoWrapper,
  CardWrapper,
  StyledText,
  StyledTitle,
} from '../Styles/Styles';
import { Tooltip } from '@/ui';
import { ContactBar } from '@/components';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Models
import { CampaignParticipantsReportItem } from '@/models/reports.model';

// Helpers
import { numberWithCommas } from '@/utils';

interface ICampaignParticipationCard {
  participation: CampaignParticipantsReportItem;
}

const CampaignParticipationCard = ({
  participation,
}: ICampaignParticipationCard) => {
  const { t } = useTranslation(['reports']);

  return (
    <CardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('report_details_campaign_kol')}</StyledTitle>
        </MobileOnly>
        <StyledText>{`${participation?.participantFirstName} ${participation?.participantLastName}`}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('report_details_participation_contact')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          <ContactBar
            data={{
              mainContact: participation.participantMainContact,
              email: participation.participantEmail,
            }}
          />
        </StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('report_details_campaign_end_date')}</StyledTitle>
        </MobileOnly>
        <StyledText>{participation?.participationEndDate}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('report_details_campaign_payout')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          $ {numberWithCommas(participation?.totalPayoutAmount ?? 0)}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('report_details_campaign_deliveries')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {participation?.deliveredPosts}/{participation?.bookedPosts}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('report_details_participant_wallet')}</StyledTitle>
        </MobileOnly>
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode}
          title={participation?.walletAddress}
          placement={'top'}
        >
          <StyledText>{participation?.walletAddress}</StyledText>
        </Tooltip>
      </StyledInfoWrapper>
    </CardWrapper>
  );
};

export default CampaignParticipationCard;
