import { ISocialChannelsDTO } from '@/models/socialChannel.model';

class SocialChannelsDTO implements ISocialChannelsDTO {
  followersCount?: number;
  profileLikes?: number;
  profilePosts?: number;
  profileComments?: number;
  sharesCount?: number;
  profileViews?: number;
  impressionsCount?: number;
  influenceScore?: number;
  followerQualityScore?: number;
  coordinatedFollowers?: number;
  gradeScore?: string;
  profileTitle?: string;
  socialChannelName?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(values: any) {
    this.followersCount = values.followersCount || values.subscriberCount;
    this.profileLikes = values.likesCount || values.likeCount;
    this.profilePosts = values.postsCount || values.videoCount;
    this.profileComments = values.commentsCount || values.commentCount;
    this.sharesCount = values.sharesCount;
    this.profileViews = values.viewCount;
    this.impressionsCount = values.impressionsCount;
    this.influenceScore = values.influenceScore;
    this.followerQualityScore = values.followerQualityScore;
    this.coordinatedFollowers = values.coordinatedFollowers;
    this.gradeScore = values.gradeScore;
    this.profileTitle = values.socialNetworkProfile || values.title;
    this.socialChannelName = values.socialChannelName;
  }
}

export default SocialChannelsDTO;
