import {
  PayloadAction,
  createSlice,
  isRejected,
  isPending,
} from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { AUTH_SLICE_NAME, AuthState, initialState } from './models';
import {
  AUTHORIZATION_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  ResponseStatusCodes,
} from '@/constants';
import { appCookiesStorage, showApiErrors } from '@/utils';
import { logOut, signIn, signInVerify } from './actionCreators';

export const slice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState,
  reducers: {
    setIsAuthorized(state, action: PayloadAction<boolean>) {
      state.isAuthorized = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    resetAuthState() {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logOut.fulfilled, () => {
        appCookiesStorage.removeAll();
        localStorage.clear();
        return { ...initialState, isAuthorized: false, isLoading: false };
      })
      .addCase(logOut.rejected, () => {
        appCookiesStorage.removeAll();
        localStorage.clear();
        return { ...initialState, isAuthorized: false, isLoading: false };
      })
      .addCase(signIn.fulfilled, (state: AuthState, action) => {
        state.isLoading = false;
        state.currentAction = action.payload.action;
        state.authSessionId = action.payload.session;
        state.setupMfaQrCode = action.payload.qrCode || null;
        state.setupMfaSecretCode = action.payload.secretCode || null;
      })
      .addCase(signInVerify.fulfilled, (state: AuthState, action) => {
        state.isLoading = false;
        const { payload } = action;

        if (payload.qrCode) {
          state.currentAction = payload.action;
          state.authSessionId = payload.session;
          state.setupMfaQrCode = payload.qrCode;
          state.setupMfaSecretCode = payload.secretCode || null;
        }

        if (payload.idToken) {
          state.isAuthorized = true;
          appCookiesStorage.setItem(
            AUTHORIZATION_TOKEN_STORAGE_KEY,
            payload.idToken,
          );
          appCookiesStorage.setItem(
            REFRESH_TOKEN_STORAGE_KEY,
            payload.refreshToken,
          );
        }
      })
      .addMatcher(isPending(signIn, signInVerify), (state: AuthState) => {
        state.isLoading = true;
      })
      .addMatcher(
        isRejected(signIn, signInVerify),
        (state: AuthState, action) => {
          const { error } = action;
          state.isLoading = false;
          showApiErrors(error);
        },
      )
      .addMatcher(isRejected(), (state: AuthState, action) => {
        const { error } = action;
        if (
          (error as IApiError)?.status === ResponseStatusCodes.NOT_AUTHORIZED
        ) {
          state.isAuthorized = false;
          appCookiesStorage.removeItem(AUTHORIZATION_TOKEN_STORAGE_KEY);
          appCookiesStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
        }
      });
  },
});

export const { setIsAuthorized, setIsLoading, resetAuthState } = slice.actions;

export default slice.reducer;
