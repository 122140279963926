// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledTitle, StyledBlockWrapper } from '../Styles';
import { SettingsWrapper } from './TopBarStyles';
import { FormItemGroup, NestedFormItem } from '@/components';
import { Checkbox, TextArea } from '@/ui';

// Hooks
import { useGetFormValidationRules } from '../../hooks';
import { TopBarsSettingsFieldsNames } from '../../helpers';

interface INFTTopBar {
  isEditActive: boolean;
}

const NFTTopBar = ({ isEditActive }: INFTTopBar) => {
  const { t } = useTranslation(['settings']);
  const validationRules = useGetFormValidationRules();

  return (
    <StyledBlockWrapper>
      <StyledTitle>{t('settings_nft_top_bar_title')}</StyledTitle>
      <FormItemGroup prefix={TopBarsSettingsFieldsNames.NFT}>
        <SettingsWrapper>
          <NestedFormItem
            name={TopBarsSettingsFieldsNames.TEXT}
            rules={validationRules[TopBarsSettingsFieldsNames.TEXT]}
            label={t('settings_top_bar_text_title')}
          >
            <TextArea disabled={!isEditActive} />
          </NestedFormItem>
          <NestedFormItem
            name={TopBarsSettingsFieldsNames.ACTIVE}
            label={t('settings_top_bar_active_title')}
            valuePropName="checked"
          >
            <Checkbox disabled={!isEditActive} />
          </NestedFormItem>
        </SettingsWrapper>
      </FormItemGroup>
    </StyledBlockWrapper>
  );
};

export default NFTTopBar;
