import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Layout } from '@/ui';

export const StyledLayout = styled(Layout)`
  &.ant-layout {
    min-height: 100vh;
    background: ${COLORS.WHITE};
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &.ant-layout {
      background: url('/images/registration.png') no-repeat center center fixed;
      background-size: cover;
    }
  }
`;

export const StyledWrapper = styled(BlockLayout)`
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1rem;
  max-width: 40rem;
  margin: 0 auto;
  background: ${COLORS.LIGHTEST_GRAY};

  @media screen and ${DEVICES.LAPTOP_S} {
    padding: 1rem;
    margin-top: 10rem;
  }
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  margin-bottom: 2rem;

  & .ant-image-img {
    object-fit: contain;
    height: 14.75rem;
    width: 14.75rem;
  }
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('18', '24', 'SEMI_BOLD')};
  margin-bottom: 0.5rem;
  padding: 2rem;
`;
