import { IParticipationItems } from '@/models/campaigns.model';
import {
  marketingDeliveryOptions,
  currentWeekOption,
  CampaignActiveStatuses,
} from '../constants';

export const buildMarketingDeliveryFilters = (
  campaignParticipants: IParticipationItems[],
  status: CampaignActiveStatuses,
) => {
  const isMarketingDelivery =
    status === CampaignActiveStatuses.MARKETING_DELIVERY;
  const weeks: number[] = [];

  campaignParticipants.forEach((participant) => {
    participant.contentDistributionPlan.forEach((plan) => {
      if (!weeks.includes(plan.weekNumber)) {
        weeks.push(plan.weekNumber);
      }
    });
  });

  const mappedWeeks = weeks.map((number) => ({
    value: `${number}`,
    label: `CW${number}`,
  }));

  return [
    ...marketingDeliveryOptions,
    ...(isMarketingDelivery ? currentWeekOption : []),
    ...mappedWeeks,
  ];
};
