import {
  GeneralStatisticTypes,
  RequestsStatisticTypes,
} from '@/modules/Dashboard/constants';

export enum DashboardEndpoints {
  GET_RECENT_INVESTMENT_PROJECTS = '/admin/statistics/recent-investments-projects',
  GET_STATISTICS_GENERAL = '/admin/statistics/general',
  GET_STATISTICS_REQUESTS = '/admin/statistics/requests',
  GET_STATISTICS_INVESTMENTS = '/admin/statistics/investments-amount',
  GET_STATISTICS_USERS = '/admin/statistics/users',
  GET_STATISTICS_YEAR = '/admin/statistics/year',
}

export interface IEntityGeneralStatistic {
  totalCount: number;
  increaseStatistics: number;
}

export interface IUsersStatistics {
  kolsCreated: number;
  kolsVerified: number;
  partnersVerified: number;
  projectsCreated: number;
  projectsVerified: number;
}

export interface IUsersStatisticsItem {
  total: IUsersStatistics;
  current: IUsersStatistics;
  date: string;
}

export type IGeneralStatistics = {
  [key in GeneralStatisticTypes]: IEntityGeneralStatistic;
};

export type IRequestsStatistics = {
  [key in RequestsStatisticTypes]: number;
};

export type IInvestmentsStatistics = {
  totalInvestmentsInMarketing: number;
  totalInvestmentsInMoney: number;
};
