// Globals
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledTextArea,
  StyledWrapper,
  StyledButton,
  StyledTextAreaWrapper,
} from './PostsContestRequestDetailsStyles';
import { Modal, ModalSize, showErrorToast } from '@/components';
import { Title, Text } from '@/ui';

// Models
import { PostsStatus } from '@/models/requests.model';

// Helpers
import { getUserId } from '@/utils';
import { useAppDispatch } from '@/hooks';
import {
  getRequestDetails,
  patchPostsContestStatus,
} from '../../feature/actionCreators';

interface IRejectUrlModal {
  isOpened: boolean;
  handleClose: () => void;
  id?: string;
  postId?: string;
}

const RejectUrlModal = ({
  isOpened,
  handleClose,
  id,
  postId,
}: IRejectUrlModal) => {
  const { t } = useTranslation(['requestsList']);
  const [description, setDescription] = useState('');
  const [validation, setValidation] = useState(false);
  const dispatch = useAppDispatch();

  const onDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDescription(event.target.value);
    event.target.value !== '' && setValidation(false);
  };

  const handleReject = useCallback(async () => {
    try {
      await dispatch(
        patchPostsContestStatus({
          id: getUserId(id ?? ''),
          urlId: postId ?? '',
          status: PostsStatus.REJECTED,
          reason: description,
        }),
      ).unwrap();
      handleClose();
    } catch (e: unknown) {
      showErrorToast({ message: (e as Error).message });
    }
    dispatch(getRequestDetails(getUserId(id ?? '')));
  }, [postId, id, description]);

  return (
    <Modal visible={isOpened} onCancel={handleClose} size={ModalSize.LARGE}>
      <Title $type="h3">{t('requests_modal_reject_title')}</Title>
      <StyledTextAreaWrapper>
        {validation && <Text>{t('requests_modal_reject_text')}</Text>}
        <StyledTextArea
          $valid={validation}
          rows={4}
          value={description}
          onChange={onDescriptionChange}
        />
        <StyledWrapper>
          <StyledButton
            type="primary"
            onClick={handleReject}
            disabled={!description}
          >
            {t('requests_in_reject_status')}
          </StyledButton>
        </StyledWrapper>
      </StyledTextAreaWrapper>
    </Modal>
  );
};

export default RejectUrlModal;
