// Globals
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

// Store
import { useAppDispatch, useAppState } from '@/hooks';
import { projectsSliceSelector } from '../../feature/selectors';
import { getProjectsList } from '../../feature/actionCreators';
import { resetProjectsList, setProjectsFilters } from '../../feature/slice';

// Components
import { ExportIcon } from '@/icons';
import {
  StyledBox,
  StyledButton,
  StyledTitle,
  StyledCheckBox,
} from './ProjectsListContainerStyles';
import { StyledPageWrapper } from '@/ui';
import { ProjectsList } from '../../components';

// Modules
import { SearchInfoInput } from '@/modules/SharedProfile';
import {
  exportProjectsList,
  selectDataExportsIsLoading,
} from '@/modules/DataExports';

const ProjectsListContainer = () => {
  const { t } = useTranslation(['projectsList']);
  const dispatch = useAppDispatch();
  const exportLoading = useAppState(selectDataExportsIsLoading);
  const { projectsData, isLoading, projectsFilters } = useAppState(
    projectsSliceSelector,
  );

  const getNextProjects = useCallback(
    debounce(() => {
      if (isLoading || !projectsData.hasMore) return;
      const lastItem = projectsData?.items?.at(-1);
      dispatch(
        getProjectsList({
          startId: lastItem?.id,
          ...(projectsFilters ? projectsFilters : {}),
        }),
      );
    }, 1000),
    [isLoading, projectsData.hasMore, dispatch, projectsFilters],
  );

  const handleProjectsSearch = async (value: string) => {
    dispatch(
      setProjectsFilters({
        search: value,
      }),
    );
  };

  const exportProjects = async () => {
    dispatch(exportProjectsList({ ...projectsFilters }));
  };

  const handleKYBFilter = (e: CheckboxChangeEvent) => {
    dispatch(
      setProjectsFilters({
        verifiedBySynaps: e.target.checked,
      }),
    );
  };

  useEffect(
    () => (): void => {
      dispatch(resetProjectsList());
    },
    [dispatch],
  );

  return (
    <StyledPageWrapper>
      <StyledTitle $type="h3">{t('projects_list_title')}</StyledTitle>
      <StyledBox>
        <SearchInfoInput handleSearch={handleProjectsSearch} />
        {t('show_verified_synaps_title', { ns: 'common' })}
        <StyledCheckBox onChange={handleKYBFilter} />
        <StyledButton
          type="primary"
          onClick={exportProjects}
          loading={exportLoading}
        >
          {t('file_export', { ns: 'common' })}
          <ExportIcon />
        </StyledButton>
      </StyledBox>
      <ProjectsList
        projects={projectsData}
        isLoading={isLoading}
        getNextProjects={getNextProjects}
      />
    </StyledPageWrapper>
  );
};

export default ProjectsListContainer;
