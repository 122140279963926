// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  Wrapper,
  BlockWrapper,
  StyledText,
  StyledInputWrapper,
  NavigationWrapper,
  StyledButton,
  StyledTitle,
  StyledHeaderWrapper,
  StyledLinksWrap,
} from '../Styled';
import { Link, StyledInput } from '@/ui';
import ActionButtons from '../ActionButtons/ActionButtons';
import RequestStatusBadges from '../RequestStatusBadges/RequestStatusBadges';

// Models
import {
  IPayout,
  IRequestDetails,
  RequestsTypesKeys,
} from '@/models/requests.model';

// Helpers | Hooks
import { getDateString, numberWithCommas } from '@/utils';
import { ROUTES_PATHS } from '@/router';
import { payoutDataNormalizer } from '../../helpers';
import { PayoutTabKeys } from '@/modules/Payout/constants';

interface IPayoutRequestDetails {
  request: IRequestDetails | null;
  isLoading?: boolean;
  isEditable?: boolean;
}

const PayoutRequestDetails = ({
  request,
  isLoading,
}: IPayoutRequestDetails) => {
  const { t } = useTranslation(['requestsList']);
  const { firstName, lastName } = request?.requester ?? {};
  const isFundingRoundPayout =
    request?.actionType === RequestsTypesKeys.FUNDING_ROUND_PAYOUT;

  const { budget, endDate, projectName, totalPayout, title } =
    payoutDataNormalizer(request?.data as IPayout);

  const actionTypeLabel = t(`requests_${request?.actionType}_label`);

  return (
    <Wrapper>
      <StyledHeaderWrapper>
        <StyledTitle>{t('request_title')}</StyledTitle>
        <RequestStatusBadges status={request?.status} />
      </StyledHeaderWrapper>
      <StyledText>{`${t('requests_type')} ${actionTypeLabel}`}</StyledText>
      <NavigationWrapper>
        <StyledTitle $type="h2">
          {t('requests_profile_admin_label')}
        </StyledTitle>
        <StyledLinksWrap>
          {isFundingRoundPayout && (
            <Link
              to={`${ROUTES_PATHS.REPORTS}/${encodeURIComponent(
                request?.targetId ?? '',
              )}`}
              target="_blank"
            >
              <StyledButton type="default">
                {t('verification_request_go_to_report')}
              </StyledButton>
            </Link>
          )}
          <Link
            to={`${ROUTES_PATHS.PAYOUT}/${
              isFundingRoundPayout
                ? PayoutTabKeys.FUNDING_ROUND
                : PayoutTabKeys.CAMPAIGN
            }/${encodeURIComponent(request?.targetId ?? '')}`}
            target="_blank"
          >
            <StyledButton type="primary">
              {t('verification_request_go_to_payout')}
            </StyledButton>
          </Link>
        </StyledLinksWrap>
      </NavigationWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_name_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={firstName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_last_name_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={lastName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t(`${request?.actionType}_request_title`)}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={title} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_project_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={projectName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t(`${request?.actionType}_end_date`)}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={getDateString(endDate ?? '')} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t(`${request?.actionType}_budget`)}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={numberWithCommas(budget ?? 0)} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_payout_amount')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={numberWithCommas(totalPayout ?? 0)} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <ActionButtons request={request} isLoading={isLoading} />
    </Wrapper>
  );
};

export default PayoutRequestDetails;
