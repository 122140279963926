export enum IKOLsDetailsTabsNames {
  GENERAL_INFO = 'General Info',
  INFLUENCER_DATA = 'Influencer Data',
  AFFILIATE = 'Affiliate Campaign',
  AFFILIATE_FUNDING = 'Affiliate Funding',
  LEGAL_AGREEMENTS = 'Legal Agreements',
  SEED_HUNTER_CREDITS = 'SeedHunter Credits',
}

export enum GeneralInfoFieldsNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  FORM_OF_ADDRESS = 'formAddress',
  ADDRESS = 'address',
  COUNTRY = 'country',
  CITY = 'city',
  PHONE = 'phone',
  EMAIL = 'email',
  ALIAS = 'alias',
  DOCUMENT_ID_PROOF = 'documentIdProof',
  NATIONALITY = 'nationality',
  DOCUMENT_NUMBER = 'documentNumber',
  WALLET_ADDRESS = 'walletAddress',
  WALLET_CURRENCY = 'walletCurrency',
  WALLET_NETWORK = 'walletNetwork',
}

export enum InfluencerDataFieldsNames {
  MAIN_COMMUNITY = 'mainCommunity',
  SECONDARY_COMMUNITY = 'secondaryCommunity',
  PRIMARY_GEOGRAPHY = 'mainGeography',
  SECONDARY_GEOGRAPHY = 'secondaryGeography',
}
