import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { Button } from '@/ui';
import { Modal } from '@/components';
import { ArrowRightIcon } from '@/icons';

export const StyledModal = styled(Modal)`
  width: 100%;
`;

export const StyledWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  justify-content: space-between;
`;

export const StyledPreview = styled.div`
  background: ${COLORS.LIGHTEST_GRAY};
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 2rem;
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
  margin-right: 0.5rem;
  transform: rotate(180deg);
`;

export const StyledStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const StyledActionButton = styled(Button)`
  &&& {
    max-width: 8rem;
    height: 3rem;
    ${pickThemeFontStyles('14', '18', 'SEMI_BOLD')};
    align-items: center;
  }
`;
