import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataListsAPI } from '@/api';
import { formatApiError } from '@/utils';
import { IApiError } from '@/models/apiError.model';
import {
  DataLists,
  ICryptoCurrency,
  IDataListsMeta,
  IGeographies,
  ISettingsResponse,
  IUserOption,
} from '@/models/dataLists.model';
import {
  DATA_LISTS_SLICE_NAME,
  IVerifyCryptoWalletDTO,
  IVerifyCryptoWalletResponse,
} from './models';

const cryptoCurrenciesMeta = { listType: DataLists.CRYPTO_CURRENCIES };

export const fetchSettings = createAsyncThunk<
  ISettingsResponse,
  void,
  { serializedErrorType: IApiError }
>(
  `${DATA_LISTS_SLICE_NAME}/fetchSettings`,
  async (data) => {
    const response = await DataListsAPI.fetchSettings(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const fetchGeographies = createAsyncThunk<
  IGeographies[],
  void,
  { serializedErrorType: IApiError }
>(
  `${DATA_LISTS_SLICE_NAME}/fetchGeographies`,
  async (_, thunkAPI) => {
    try {
      const response = await DataListsAPI.fetchGeographies();
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      throw thunkAPI.rejectWithValue(formatApiError(error));
    }
  },
  {
    serializeError: formatApiError,
  },
);

export const fetchCryptoCurrencies = createAsyncThunk<
  ICryptoCurrency[],
  void,
  {
    serializedErrorType: IApiError;
    pendingMeta: IDataListsMeta;
    fulfilledMeta: IDataListsMeta;
    rejectedMeta: IDataListsMeta;
  }
>(
  `${DATA_LISTS_SLICE_NAME}/fetchCryptoCurrencies`,
  async (_, thunkAPI) => {
    try {
      const response = await DataListsAPI.fetchCryptoCurrencies();
      return thunkAPI.fulfillWithValue(response.data, cryptoCurrenciesMeta);
    } catch (error) {
      throw thunkAPI.rejectWithValue(
        formatApiError(error),
        cryptoCurrenciesMeta,
      );
    }
  },
  {
    serializeError: formatApiError,
    getPendingMeta: () => cryptoCurrenciesMeta,
  },
);

export const verifyCryptoWalletAddress = createAsyncThunk<
  IVerifyCryptoWalletResponse,
  IVerifyCryptoWalletDTO
>(
  `${DATA_LISTS_SLICE_NAME}/verifyCryptoWalletAddress`,

  async (data) => {
    const response = await DataListsAPI.verifyCryptoWalletAddress(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getVerifiedKols = createAsyncThunk<IUserOption[], void>(
  `${DATA_LISTS_SLICE_NAME}/getVerifiedKols`,

  async () => {
    const response = await DataListsAPI.getVerifiedKols();
    return response.data;
  },
  { serializeError: formatApiError },
);
