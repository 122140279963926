import styled from 'styled-components';
import { COLORS, pickThemeFontSize, pickThemeFontStyles } from '@/theme';
import { LayoutSidebar, Menu } from '@/ui';

export const StyledLogoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 2.4rem;
  margin: 1.3rem;
`;

export const StyledImg = styled.img`
  margin: auto;
`;

export const StyledColapsedLogoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 2.438rem;
  margin: 1.3rem;
`;

export const StyledDesktopSider = styled(LayoutSidebar)`
  & .ant-layout-sider-children {
    position: sticky;
    top: 0;
    height: 100vh;
    background: ${COLORS.DARK_BLUE};
  }
`;

export const StyledDesktopSideBarMenu = styled(Menu)<{ $isColapsed: boolean }>`
  &.ant-menu.ant-menu-vertical {
    ${({ $isColapsed }) => {
      return $isColapsed ? 'margin: 0 0.563rem;' : 'margin: 0 1.188rem;';
    }}
    background: ${COLORS.DARK_BLUE};
    border: none;

    &&& li {
      background-color: inherit;
    }

    // TODO Temporary solution / Rewrite if we going to use more navigation tabs
    li:nth-last-child(1) {
      position: absolute;
      bottom: 1rem;
      ${({ $isColapsed }) => {
        return $isColapsed
          ? `
        margin-left: 0.75rem;
        padding: 1.125rem;`
          : 'width: 80%';
      }};
      & .anticon {
        font-size: ${pickThemeFontSize('14')};
      }
    }

    li:nth-last-child(2) {
      position: absolute;
      bottom: 3.7rem;
      left: 0.625rem;
    }

    & .ant-menu-item-divider {
      ${({ $isColapsed }) => {
        return $isColapsed ? 'width: 70%;' : 'width: 90%;';
      }}
      border: none;
      border-top: 1px solid ${COLORS.GRAYISH_BLUE};
    }

    // Rewrite active link style because of issue with insensitivity to redirects
    &&& .ant-menu-item {
      &:has(.activeLink) {
        path {
          fill: ${COLORS.WHITE};
        }
        background: linear-gradient(
          89.76deg,
          ${COLORS.BRIGHT_ORANGE} 2.3%,
          ${COLORS.HIGHLIGHTER_ORANGE} 97.85%
        ) !important;
      }
    }

    & .ant-menu-item {
      ${pickThemeFontStyles('14', '24', 'SEMI_BOLD')};
      display: flex;
      align-items: center;
      color: ${COLORS.WHITE};
      ${({ $isColapsed }) => {
        return $isColapsed ? 'width: 3.375rem;' : '';
      }}

      & .ant-menu-item-icon {
        justify-content: center;
        font-size: ${pickThemeFontSize('16')};
        min-width: 1rem;
      }
    }
    & .ant-menu-item.ant-menu-item-selected {
      background: inherit;
    }

    & .ant-menu-item.ant-menu-item-active {
      path {
        fill: ${COLORS.WHITE};
      }
      color: ${COLORS.WHITE};
      background: linear-gradient(
        89.76deg,
        ${COLORS.BRIGHT_ORANGE} 2.3%,
        ${COLORS.HIGHLIGHTER_ORANGE} 97.85%
      );
    }
  }
`;
