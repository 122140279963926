import { ReactNode } from 'react';
import {
  DashboardIcon,
  FileIcon,
  ListIcon,
  LogoutIcon,
  MyAccountIcon,
  SpeakerIcon,
  TabOptionsIcon,
  WalletIcon,
} from '@/icons';
import { ROUTES_PATHS } from '@/router';

export enum MenuItemsKeys {
  DASHBOARD = 'dashboard',
  KOLS = 'kol-list',
  PROJECTS = 'projects-list',
  CAMPAIGNS = 'campaigns',
  HUNTERS = 'hunters-list',
  ADMINS = 'admins',
  FUNDING_ROUND = 'funding-rounds',
  REQUESTS = 'requests',
  SETTINGS = 'settings',
  LOG_OUT = 'log-out',
  OPTIONS = 'options',
  PAYOUT = 'payout',
  DOCUMENTS = 'documents',
  REPORTS = 'reports',
}

export interface IMenuItemChildren {
  key: MenuItemsKeys;
  label: JSX.Element | string;
  icon: JSX.Element;
}

export interface ISideBarConfig {
  titleKey: string;
  icon?: ReactNode;
  isMobileShown?: boolean;
  key: MenuItemsKeys;
  route?: string;
  children?: IMenuItemChildren[];
}

export const sideBarItemsOrder = new Map([
  [MenuItemsKeys.DASHBOARD, 0],
  [MenuItemsKeys.KOLS, 1],
  [MenuItemsKeys.PROJECTS, 2],
  [MenuItemsKeys.CAMPAIGNS, 3],
  [MenuItemsKeys.FUNDING_ROUND, 4],
  [MenuItemsKeys.HUNTERS, 5],
  [MenuItemsKeys.ADMINS, 6],
  [MenuItemsKeys.REQUESTS, 7],
  [MenuItemsKeys.PAYOUT, 8],
  [MenuItemsKeys.DOCUMENTS, 9],
  [MenuItemsKeys.SETTINGS, 11],
]);

export const menuItems: ISideBarConfig[] = [
  {
    titleKey: 'sidebar_dashboard',
    key: MenuItemsKeys.DASHBOARD,
    route: ROUTES_PATHS.DASHBOARD,
    isMobileShown: true,
    icon: <DashboardIcon />,
  },
  {
    titleKey: 'sidebar_kols',
    key: MenuItemsKeys.KOLS,
    route: ROUTES_PATHS.KOL_LIST,
    isMobileShown: true,
    icon: <ListIcon />,
  },
  {
    titleKey: 'sidebar_hunters',
    key: MenuItemsKeys.HUNTERS,
    route: ROUTES_PATHS.HUNTERS_LIST,
    icon: <ListIcon />,
  },
  {
    titleKey: 'sidebar_projects',
    key: MenuItemsKeys.PROJECTS,
    route: ROUTES_PATHS.PROJECTS_LIST,
    isMobileShown: true,
    icon: <ListIcon />,
  },
  {
    titleKey: 'sidebar_campaigns',
    key: MenuItemsKeys.CAMPAIGNS,
    route: ROUTES_PATHS.CAMPAIGNS,
    icon: <ListIcon />,
  },
  {
    titleKey: 'sidebar_funding_rounds',
    key: MenuItemsKeys.FUNDING_ROUND,
    route: ROUTES_PATHS.FUNDING_ROUNDS,
    icon: <ListIcon />,
  },
  {
    titleKey: 'sidebar_requests',
    key: MenuItemsKeys.REQUESTS,
    route: ROUTES_PATHS.REQUESTS,
    icon: <ListIcon />,
  },
  {
    titleKey: 'sidebar_settings',
    key: MenuItemsKeys.SETTINGS,
    route: ROUTES_PATHS.SETTINGS,
    icon: <MyAccountIcon />,
  },
  {
    titleKey: 'sidebar_admins',
    key: MenuItemsKeys.ADMINS,
    route: ROUTES_PATHS.ADMINS_LIST,
    icon: <MyAccountIcon />,
  },
  {
    titleKey: 'sidebar_payout',
    key: MenuItemsKeys.PAYOUT,
    route: ROUTES_PATHS.PAYOUT,
    icon: <WalletIcon />,
  },
  {
    titleKey: 'sidebar_documents',
    key: MenuItemsKeys.DOCUMENTS,
    route: ROUTES_PATHS.DOCUMENTS,
    icon: <FileIcon />,
  },
  {
    titleKey: 'sidebar_reports',
    key: MenuItemsKeys.REPORTS,
    route: ROUTES_PATHS.REPORTS,
    icon: <SpeakerIcon />,
  },
  {
    titleKey: 'sidebar_options',
    key: MenuItemsKeys.OPTIONS,
    isMobileShown: true,
    icon: <TabOptionsIcon />,
  },
  {
    titleKey: 'sidebar_log_out',
    icon: <LogoutIcon />,
    key: MenuItemsKeys.LOG_OUT,
  },
];

export const dividerItem = {
  type: 'divider',
};
