import styled, { css } from 'styled-components';
import { Input, Input as AntInput, InputNumber } from 'antd';
import {
  COLORS,
  DEVICES,
  pickThemeFontSize,
  pickThemeFontStyles,
} from '@/theme';

export const inputStyles = css`
  &.ant-input {
    min-width: 100%;
    background: ${COLORS.LIGHTEST_GRAY};
    ${pickThemeFontStyles('14', '32', 'SEMI_NORMAL')};
  }

  &.ant-input-affix-wrapper {
    overflow: hidden;
    padding: 0;

    & .ant-input {
      padding: 0.313rem 0.688rem;
      ${pickThemeFontStyles('14', '32', 'SEMI_NORMAL')};
      width: 100%;
    }

    & .ant-input-suffix {
      font-size: ${pickThemeFontSize('20')};
      margin: 0;
      position: absolute;
      right: 1rem;
      bottom: 0.6rem;
    }

    & .ant-input-prefix {
      font-size: ${pickThemeFontSize('20')};
      margin: 0;
    }
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &.ant-input {
      ${pickThemeFontStyles('15', '46', 'SEMI_NORMAL')};
    }

    &.ant-input-affix-wrapper {
      & .ant-input-suffix {
        position: absolute;
        right: 1rem;
        bottom: 1.1rem;
      }
      & .ant-input {
        ${pickThemeFontStyles('15', '46', 'SEMI_NORMAL')};
      }
    }
  }
`;

export const StyledInput = styled(AntInput)`
  ${inputStyles};
`;

export const StyledInputNumber = styled(InputNumber)`
  &.ant-input-number {
    width: 100%;
    background: ${COLORS.LIGHTEST_GRAY};
    ${pickThemeFontStyles('14', '20')};

    & .ant-input-number-input-wrap {
      & input {
        //padding: 1.625rem 1rem 0.5rem;
        //height: auto;
        color: ${COLORS.DARKEST_GRAY};
        ${pickThemeFontStyles('16', '20', 'SEMI_BOLD')};
      }
    }
  }
`;

export const StyledPasswordInput = styled(AntInput.Password)`
  ${inputStyles};
`;

export const StyledTextArea = styled(Input.TextArea)`
  ${inputStyles}
  &&&.ant-input {
    background: ${COLORS.LIGHTEST_GRAY};
  }
`;
