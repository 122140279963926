import { IMarketingChannel } from '@/models/campaigns.model';
import { socialChannelsConfig } from '@/modules/KolsList/constants/socialChannels';

export const getChannelsConfig = (data?: IMarketingChannel[]) => {
  return data?.map((channel) => {
    return {
      label: socialChannelsConfig[channel.name].icon,
      key: channel.name,
    };
  });
};
