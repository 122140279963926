// Globals
import React, { useCallback, useEffect, useState } from 'react';

// Components
import { ParticipationList } from '../../components';
import ManualConfirmPaymentModalContainer from '../ManualConfirmPaymentModalContainer/ManualConfirmPaymentModalContainer';

// Modules
import { getFundingRoundParticipations } from '@/modules/FundingRounds/feature/actionCreators';
import { fundingRoundSliceSelector } from '@/modules/FundingRounds';

// Models
import {
  IFundingRound,
  IFundingRoundParticipation,
} from '@/models/fundingRound.model';

// Helpers
import { getUserId } from '@/utils';
import { useAppDispatch, useAppState, useModal } from '@/hooks';

type TParticipationListContainer = {
  id?: string;
};

const ParticipationListContainer = ({ id }: TParticipationListContainer) => {
  const dispatch = useAppDispatch();
  const { fundingRoundParticipations, fundingRound, isLoading } = useAppState(
    fundingRoundSliceSelector,
  );

  const [transactionToConfirm, setTransactionToConfirm] =
    useState<IFundingRoundParticipation['transaction']>();

  const {
    isOpened: isConfirmModalOpen,
    openModal: openConfirmModal,
    closeModal: closeConfirmModal,
  } = useModal();

  const handleConfirmPayment = useCallback(
    (participation: IFundingRoundParticipation) => {
      setTransactionToConfirm(participation.transaction);
      openConfirmModal();
    },
    [],
  );

  useEffect(() => {
    dispatch(getFundingRoundParticipations(getUserId(id ?? '')));
  }, [id]);

  if (!id) return null;

  return (
    <>
      <ParticipationList
        fundingRound={fundingRound as IFundingRound}
        participations={fundingRoundParticipations}
        isLoading={isLoading}
        handleConfirmPayment={handleConfirmPayment}
      />

      <ManualConfirmPaymentModalContainer
        isOpened={isConfirmModalOpen}
        handleClose={closeConfirmModal}
        transactionToConfirm={transactionToConfirm}
        fundingRoundId={id}
      />
    </>
  );
};

export default ParticipationListContainer;
