// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  Wrapper,
  StyledInfoTitles,
  StyledTitles,
  StyledLogSection,
  StyledButton,
  StyledButtonWrap,
} from './LogSectionStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import LogCard from '../LogCard/LogCard';

// Models
import { ILogs, LogEntities } from '@/models/logs.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

interface ILogSection {
  entity?: LogEntities;
  getNextLogs: () => void;
  isLoading: boolean;
  logs: {
    items: ILogs[] | null;
    hasMore: boolean;
  };
}

const LogSection = ({ logs, getNextLogs, isLoading, entity }: ILogSection) => {
  const { t } = useTranslation(['logs']);

  const mappedLogs = useMemo(() => {
    return logs?.items?.map((log) => {
      return <LogCard log={log} key={JSON.stringify(log)} entity={entity} />;
    });
  }, [logs]);

  if (logs?.items?.length === 0) return null;

  return (
    <Wrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('logs_date_title')}</StyledTitles>
          <StyledTitles>{t('logs_admin_email_title')}</StyledTitles>
          <StyledTitles>{t('logs_action_title')}</StyledTitles>
          {entity && entity !== LogEntities.REQUESTS && (
            <StyledTitles>{t('logs_status_title')}</StyledTitles>
          )}
          {entity && <StyledTitles>{t('logs_reason_title')}</StyledTitles>}
        </StyledInfoTitles>
      </DesktopOnly>
      <StyledLogSection>{mappedLogs}</StyledLogSection>
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      {logs?.hasMore && (
        <StyledButtonWrap>
          <StyledButton type="ghost" onClick={getNextLogs}>
            {t('logs_get_next_logs')}
          </StyledButton>
        </StyledButtonWrap>
      )}
    </Wrapper>
  );
};

export default LogSection;
