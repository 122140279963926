import React, { useEffect, useState } from 'react';
import { getDetails } from 'use-places-autocomplete';

interface IAddress {
  placeId: string;
}

const Address = ({ placeId }: IAddress) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [addressData, setAddressData] = useState<any>();
  const getPlaceData = async (placeId: string) => {
    if (placeId) {
      try {
        const data = await getDetails({ placeId });
        if (typeof data !== 'string') {
          setAddressData(data);
        }
      } catch (e) {
        console.warn(e);
      }
    }
  };
  useEffect(() => {
    getPlaceData(placeId);
  }, [placeId]);

  return <>{addressData?.formatted_address}</>;
};

export default Address;
