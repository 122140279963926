import styled from 'styled-components';
import { Typography } from 'antd';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { ChevronIcon } from '@/icons';

const { Paragraph } = Typography;

export const StyledParagraph = styled(Paragraph)`
  &.ant-typography {
    margin: 0;
  }
`;

export const StyledMoreButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: ${COLORS.BLUE_PRIMARY};

  ${pickThemeFontStyles('14', '20', 'NORMAL')};

  & .anticon {
    display: none;
  }
`;

export const StyledUpIcon = styled(ChevronIcon)`
  transform: rotate(180deg);
`;
