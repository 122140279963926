import { TFunction } from 'react-i18next';
import { VerificationLevel } from '@/models/projects.model';

export const getVerificationLevelOptions = (t: TFunction) => [
  {
    value: VerificationLevel.BySeedhunter,
    label: t(VerificationLevel.BySeedhunter),
  },
  {
    value: VerificationLevel.RegularListing,
    label: t(VerificationLevel.RegularListing),
  },
];
