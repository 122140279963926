import { IKOL } from '@/models/kolsList.model';
import { SocialChannels } from '@/models/socialChannel.model';

const getChannelLink = (kol: IKOL, channel: SocialChannels) => {
  const twitterLink =
    kol?.socialChannel?.find((item) => item.name === channel)?.link ?? '';
  const twitterHandle = twitterLink.split('/').slice(3).join('/');
  return { twitterLink, twitterHandle };
};

export default getChannelLink;
