export enum SocialChannelNetwork {
  GET_SOCIAL_CHANNELS_NETWORK = '/admin/social-channel',
}

export interface ISocialChannelsDTO {
  followersCount?: number;
  profileLikes?: number;
  profilePosts?: number;
  profileComments?: number;
  sharesCount?: number;
  profileViews?: number;
  impressionsCount?: number;
  influenceScore?: number;
  followerQualityScore?: number;
  coordinatedFollowers?: number;
  gradeScore?: string;
  profileTitle?: string;
  socialChannelName?: string;
}

export enum SocialChannels {
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  YOUTUBE = 'youtube',
  TELEGRAM = 'telegram',
  LINKEDIN = 'linkedin',
  REDDIT = 'reddit',
  TIKTOK = 'tiktok',
}
