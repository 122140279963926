// Globals
import React, { useMemo } from 'react';
import { Pie } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledAmount,
  StyledChartWrapper,
  StyledLegendRow,
  StyledLegendWrapper,
  StyledTitle,
  StyledWrapper,
} from './DonutChartStyles';

// Helpers
import { chartColors, defaultConfig, getStatisticsElement } from './configs';

// Types
import type { TChartDataItem } from '@/types';

interface IDonutChart {
  data?: TChartDataItem[];
  colors?: string[];
  title: string;
  isPureChart?: boolean;
  isMobile?: boolean;
}

const DonutChart = ({
  data = [],
  title,
  isPureChart,
  isMobile,
  colors,
}: IDonutChart) => {
  const { t } = useTranslation(['common']);
  const statistic = useMemo(
    () => getStatisticsElement(title, isMobile),
    [title, isMobile],
  );

  const donut = (
    <Pie
      {...defaultConfig}
      color={colors ?? chartColors}
      statistic={statistic}
      data={data}
    />
  );

  if (isPureChart) {
    return donut;
  }

  return (
    <StyledWrapper>
      <StyledChartWrapper>{donut}</StyledChartWrapper>
      <StyledLegendWrapper>
        {data?.map((item, idx) => (
          <StyledLegendRow key={item.id}>
            <StyledTitle $color={colors?.[idx] ?? chartColors[idx]}>
              {t(item.label)}
            </StyledTitle>
            {item?.amount && <StyledAmount>{item?.amount}</StyledAmount>}
          </StyledLegendRow>
        ))}
      </StyledLegendWrapper>
    </StyledWrapper>
  );
};

export default DonutChart;
