import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button, DatePicker, Text, TextArea, Title } from '@/ui';
import { PostsStatus } from '@/models/requests.model';

export const Wrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-top: 1.5rem;
  align-items: flex-start;
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const BlockWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 60%;
    align-items: center;
    flex-direction: row;
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.3rem;
  flex-direction: row;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 60%;
  }
`;

export const StyledButton = styled(Button)`
  &&& {
    width: 100%;
    height: 2.5rem;
    ${pickThemeFontStyles('14', '18', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const StyledCloseButton = styled(Button)`
  &&& {
    width: 8rem;
    height: 2.5rem;
    ${pickThemeFontStyles('14', '18', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    align-items: center;
  }
`;

export const StyledText = styled(Text)`
  flex: 1;
`;

export const StyledTitle = styled(Title)`
  ${pickThemeFontStyles('24', '24', 'BOLD')};
`;

export const StyledInputWrapper = styled.div`
  flex: 3;
  width: 100%;
`;

export const StyledTextArea = styled(TextArea)<{ $valid?: boolean }>`
  width: 100%;
  margin-top: 0.5rem;
  &&&.ant-input {
    border: 1.5px solid
      ${({ $valid }) => ($valid ? COLORS.RED : COLORS.LIGHTEST_GRAY)};
    ${pickThemeFontStyles('16', '26', 'NORMAL')};
  }
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  gap: 0.5rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  :first-child {
    flex: 0.2;
  }

  :last-child {
    flex: 0.6;
    align-items: center;
  }
`;

export const CardWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.5rem;
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  min-width: 0;

  :first-child {
    flex: 0.2;
  }

  :last-child {
    flex: 0.6;
    align-items: center;
  }
`;

export const StyledPostText = styled(Text)`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media screen and ${DEVICES.LAPTOP_S} {
    max-width: 90%;
  }
`;

export const StyledPostTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  justify-content: space-between;
`;

export const StyledTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;

export const StyledBadge = styled.div<{ $status?: string | null }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  color: ${COLORS.CORN_FLOWER_BLUE};
  ${pickThemeFontStyles('12', '16', 'BOLD')};
  background: ${({ $status }) =>
    $status === PostsStatus.APPROVED
      ? COLORS.GREEN_BUBBLES
      : COLORS.MISTY_ROSE};
`;

export const StyledDatePicker = styled(DatePicker)`
  background: ${COLORS.LIGHTEST_GRAY};
  height: 2.875rem;
  border: 0;
  ${pickThemeFontStyles('15', '15', 'SEMI_NORMAL')};
`;
