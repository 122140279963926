// Globals
import React, { useState, MouseEvent } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import {
  StyledLogoWrapper,
  StyledDesktopSideBarMenu,
  StyledDesktopSider,
  StyledColapsedLogoWrapper,
  StyledImg,
} from './DesktopSideBarStyles';

// Hooks
import { useSidebarMenu } from '../../hooks';

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { items, handleMenuClick } = useSidebarMenu();
  const location = useLocation();

  const onCollapse = () => {
    setCollapsed((value) => !value);
  };

  const onPreventClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <StyledDesktopSider
      width={'15rem'}
      collapsed={collapsed}
      onClick={onCollapse}
    >
      {collapsed ? (
        <StyledColapsedLogoWrapper>
          <StyledImg src="/icons/main-logo-short.svg" alt="logo" />
        </StyledColapsedLogoWrapper>
      ) : (
        <StyledLogoWrapper>
          <StyledImg src="/icons/main-logo-full.svg" alt="logo" />
        </StyledLogoWrapper>
      )}
      <div onClick={onPreventClick}>
        <StyledDesktopSideBarMenu
          items={items}
          defaultSelectedKeys={[location.pathname.split('/')[1]]}
          $isColapsed={collapsed}
          onClick={handleMenuClick}
        />
      </div>
    </StyledDesktopSider>
  );
};

export default SideBar;
