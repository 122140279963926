import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { DASHBOARD_SLICE_NAME } from './models';
import { formatApiError } from '@/utils';
import { DashboardAPI } from '@/api';
import {
  IGeneralStatistics,
  IInvestmentsStatistics,
  IRequestsStatistics,
  IUsersStatisticsItem,
} from '@/models/dashboard.model';

export const getStatisticsGeneral = createAsyncThunk<
  IGeneralStatistics,
  void,
  { serializedErrorType: IApiError }
>(
  `${DASHBOARD_SLICE_NAME}/getStatisticsGeneral`,
  async () => {
    const response = await DashboardAPI.getStatisticsGeneral();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getStatisticsRequests = createAsyncThunk<
  IRequestsStatistics,
  void,
  { serializedErrorType: IApiError }
>(
  `${DASHBOARD_SLICE_NAME}/getStatisticsRequests`,
  async () => {
    const response = await DashboardAPI.getStatisticsRequests();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getStatisticsInvestments = createAsyncThunk<
  IInvestmentsStatistics,
  void,
  { serializedErrorType: IApiError }
>(
  `${DASHBOARD_SLICE_NAME}/getStatisticsInvestments`,
  async () => {
    const response = await DashboardAPI.getStatisticsInvestments();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getStatisticsUsers = createAsyncThunk<
  IUsersStatisticsItem[],
  void,
  { serializedErrorType: IApiError }
>(
  `${DASHBOARD_SLICE_NAME}/getStatisticsUsers`,
  async () => {
    const response = await DashboardAPI.getStatisticsUsers();
    return response.data;
  },
  { serializeError: formatApiError },
);
