import {
  CampaignParticipantStatuses,
  IParticipationItems,
} from '@/models/campaigns.model';

export const getFilteredParticipantsByStatus = (
  participants: IParticipationItems[],
  status?: CampaignParticipantStatuses,
) => {
  return participants?.filter((item) => item?.status === status);
};
