// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledHeaderBlockWrapper,
  StyledHeaderBlock,
  StyledHeaderText,
  StyledBadge,
} from './MarketingDeliveryInfoStyles';

// Models
import { IDeliveryStatistics } from '@/models/marketingDelivery.model';

// Helpers
import { getDateString } from '@/utils';
import { DATE_FORMATS } from '@/constants';

interface IMarketingDeliveryInfos {
  deliveryStatistics: IDeliveryStatistics | null;
}

const MarketingDeliveryHeader = ({
  deliveryStatistics,
}: IMarketingDeliveryInfos) => {
  const { t } = useTranslation(['projectsList']);

  return (
    <StyledHeaderBlockWrapper>
      <StyledHeaderBlock>
        <StyledHeaderText>{t('proof_of_delivery_period')}</StyledHeaderText>
        <StyledHeaderText>
          {`${getDateString(
            deliveryStatistics?.marketingStartDate ?? '',
            DATE_FORMATS.DAY_MONTH_WITH_DOTS,
          )} - ${getDateString(
            deliveryStatistics?.marketingEndDate ?? '',
            DATE_FORMATS.DATE_WITH_DOTS,
          )}`}
        </StyledHeaderText>
      </StyledHeaderBlock>

      <StyledHeaderBlock>
        <StyledHeaderText>
          {t('proof_of_delivery_delivered_planned_posts')}
        </StyledHeaderText>
        <StyledHeaderText>
          {`${deliveryStatistics?.deliveredPosts}/${deliveryStatistics?.plannedPosts}`}
        </StyledHeaderText>
      </StyledHeaderBlock>

      <StyledHeaderBlock>
        <StyledHeaderText>
          {t('proof_of_delivery_campaign_budget')}
        </StyledHeaderText>
        <StyledHeaderText>
          ${deliveryStatistics?.campaignBudget}
        </StyledHeaderText>
      </StyledHeaderBlock>

      <StyledHeaderBlock>
        <StyledHeaderText>{t('proof_of_delivery_status')}</StyledHeaderText>
        <StyledBadge $status={deliveryStatistics?.campaignStatus}>
          {t(`campaign_status_${deliveryStatistics?.campaignStatus}`, {
            ns: 'campaigns',
          })}
        </StyledBadge>
      </StyledHeaderBlock>
    </StyledHeaderBlockWrapper>
  );
};

export default MarketingDeliveryHeader;
