import React from 'react';

export const MyFormItemContext = React.createContext<(string | number)[]>([]);

export function toArr(
  str: string | number | (string | number)[],
): (string | number)[] {
  return Array.isArray(str) ? str : [str];
}

interface FormItemGroupProps {
  prefix: string | number | (string | number)[];
  children: React.ReactNode;
}

const FormItemGroup = ({ prefix, children }: FormItemGroupProps) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix],
  );

  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};

export default FormItemGroup;
