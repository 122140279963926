import { countries } from '../constants/geography';

export const numberWithCommas = (value: number, fraction = 2): string => {
  if (value === undefined || value === null) return '';

  const fixedValue = value.toFixed(fraction);

  const parts = fixedValue.split('.');
  return (
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
    (Number(parts[1]) ? '.' + parts[1] : '')
  );
};

export const getPercent = (value: number, total: number) => {
  const res = Number(((value / total) * 100).toFixed());
  return Number.isNaN(res) ? 0 : res;
};

export const calcPercent = (value: number, percent: number) => {
  const res = Number((value * percent) / 100);
  return Number.isNaN(res) ? 0 : res;
};

export const subtractCommission = (budget: number, commissionPercent = 0) => {
  const commission = Number(calcPercent(budget, commissionPercent));
  const res = budget - commission;
  return Number.isNaN(res) ? 0 : res;
};

export const getValueByPercent = (total: number, percent: number) => {
  const res = Number(((total * percent) / 100).toFixed());
  return Number.isNaN(res) ? 0 : res;
};

export const toNumber = (number: number | string) => {
  if (typeof number === 'number' && !isNaN(number)) return number;
  const num = Number(number);
  return isNaN(num) ? 0 : num;
};

export const getFileName = (fullName: string) => {
  const parts = fullName?.split('/');
  return parts[parts.length - 1];
};

export const capitalizeString = (row: string | undefined = '') => {
  return row?.charAt(0).toUpperCase() + row.slice(1).toLowerCase();
};

export const getUserId = (id: string) => {
  return id.substring(id.indexOf('#') + 1);
};

export const getRoleFromId = (id: string) => {
  return id.split('#')[0].toLowerCase();
};

export const getRoundPercentDown = (value: number, total: number) => {
  const res = Math.floor((value / total) * 100);
  return Number.isNaN(res) ? 0 : res;
};

export const numberFormatter = (value: number | string | undefined): string => {
  if (value === undefined || value === null) {
    return '';
  }

  const num = typeof value === 'number' ? value : parseFloat(value);
  if (isNaN(num)) {
    return '';
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberParser = (value: string | undefined) => {
  if (typeof value === 'number') return value;
  return parseFloat(value?.replace(/\$\s?|(,*)/g, '') ?? '');
};

export const getCountryLabel = (code: string) => {
  const country = countries.find((country) => country.code === code);
  if (!country) return 'Unknown';
  return country.name;
};

export const abbreviateNumber = (value: number): string => {
  const abbreviations = ['', 'K', 'M', 'B', 'T'];
  let newValue = value;
  let suffixIndex = 0;

  while (newValue >= 1000) {
    newValue /= 1000;
    suffixIndex += 1;
  }

  return `${newValue}${abbreviations[suffixIndex]}`;
};

export const getEntityByFullId = (id: string) => {
  const [entity] = id.split('#');

  return entity;
};
