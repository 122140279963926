import {
  createSlice,
  isPending,
  isRejected,
  isFulfilled,
} from '@reduxjs/toolkit';
import { SETTINGS_SLICE_NAME, SettingsState, initialState } from './models';
import {
  getAverageSocialData,
  getNFTCreditsBucketHistory,
  getNFTCreditsFundingBucket,
  getSettings,
  getTopBarsSettings,
  postAverageSocialData,
  getSocialsThresholdsSettings,
  postSocialsThresholdsSettings,
  postNFTCreditsFundingBucket,
  postSettings,
  postTopBarsSettings,
} from './actionCreators';

export const slice = createSlice({
  name: SETTINGS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getNFTCreditsBucketHistory.fulfilled,
        (state: SettingsState, action) => {
          state.nftCreditsBucketHistory = {
            items: action.meta.arg.startSk
              ? [
                  ...(state.nftCreditsBucketHistory?.items ?? []),
                  ...action.payload.items,
                ]
              : [...action.payload.items],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addMatcher(
        isFulfilled(
          getSocialsThresholdsSettings,
          postSocialsThresholdsSettings,
        ),
        (state: SettingsState, action) => {
          state.automaticApprovalData = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isFulfilled(getSettings, postSettings),
        (state: SettingsState, action) => {
          state.settingsData = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isFulfilled(getTopBarsSettings, postTopBarsSettings),
        (state: SettingsState, action) => {
          state.topBarsSettings = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isFulfilled(getNFTCreditsFundingBucket, postNFTCreditsFundingBucket),
        (state: SettingsState, action) => {
          state.nftCreditsBucketData = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isFulfilled(getAverageSocialData, postAverageSocialData),
        (state: SettingsState, action) => {
          state.averageSocialData = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isPending(
          getSettings,
          getTopBarsSettings,
          postSettings,
          getAverageSocialData,
          postAverageSocialData,
          postNFTCreditsFundingBucket,
          getAverageSocialData,
          getNFTCreditsBucketHistory,
        ),
        (state: SettingsState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(
          getSettings,
          getTopBarsSettings,
          postSettings,
          getAverageSocialData,
          postAverageSocialData,
          postNFTCreditsFundingBucket,
          getAverageSocialData,
          getNFTCreditsBucketHistory,
        ),
        (state: SettingsState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
        },
      );
  },
});

export default slice.reducer;
