import { isContainsDomain } from './domainValidation';

export const getNormalizedLink = (url: string) => {
  if (!url?.startsWith('https://')) {
    return 'https://' + url;
  }
  return url;
};

export const getNormalizedSocialLink = (url: string, domain: string) => {
  if (isContainsDomain(url, domain) && !url?.startsWith('https://')) {
    return 'https://' + url;
  }
  return url;
};
