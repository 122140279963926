import styled, { css } from 'styled-components';
import { COLORS, DEVICES, pickThemeFontSize } from '@/theme';

export const StyledSectionTab = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin: 0 0 0 0.5rem;
`;

export const StyledTab = styled.div<{ $isActive?: boolean }>`
  height: 2rem;
  display: flex;
  color: ${COLORS.GRAY};
  cursor: pointer;
  padding: 0rem;

  :hover {
    color: ${COLORS.BLACK};
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    padding: 1rem;
    font-size: ${pickThemeFontSize('16')};
    height: 3.875rem;

    &&& span {
      margin-right: 0 !important;
    }
  }

  svg {
    path {
      fill: ${COLORS.ORANGE_PRIMARY};
    }
  }

  &:hover {
    border-bottom: 2px solid ${COLORS.ORANGE_PRIMARY};
    border-image: linear-gradient(89.76deg, #fdae33 2.3%, #f49535 97.85%);
    border-image-slice: 1;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      border-bottom: 2px solid ${COLORS.ORANGE_PRIMARY};
      border-image: linear-gradient(89.76deg, #fdae33 2.3%, #f49535 97.85%);
      border-image-slice: 1;
    `};
`;

export const StyledIconWrapper = styled.div`
  padding-right: 0;

  @media screen and ${DEVICES.LAPTOP_S} {
    padding-right: 0.5rem;
  }
`;

export const StyledStatusesWrapper = styled.div`
  display: flex;
  & .anticon {
    font-size: ${pickThemeFontSize('48')};
    transform: scale(0.4);
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    & .anticon {
      transform: scale(0.5);
    }
  }
`;
