import styled from 'styled-components';
import { Checkbox, Title } from '@/ui';
import { DEVICES, pickThemeFontStyles } from '@/theme';

export const StyledTitle = styled(Title)`
  margin: 0 0 2rem 0;
`;

export const StyledCheckBox = styled(Checkbox)`
  margin-bottom: 0.2rem;
`;

export const StyledFilters = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  ${pickThemeFontStyles('14', '14', 'SEMI_BOLD')};

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    align-items: center;
  }
`;
