// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledInfoWrapper,
  StyledRow,
  StyledText,
  StyledInfo,
  StyledTitles,
  StyledInfoTitles,
} from './SocialChannelDataInfoStyles';
import { StyledInput } from '@/ui';

// Models
import {
  ISocialChannelsDTO,
  SocialChannels,
} from '@/models/socialChannel.model';

interface ISocialChannelInfo {
  data: ISocialChannelsDTO;
}

const SocialChannelInfo = ({ data }: ISocialChannelInfo) => {
  const { t } = useTranslation(['kolsList']);

  return (
    <StyledInfoWrapper>
      <StyledRow>
        <StyledText>{t('kol_social_data_social_title')}</StyledText>
        <StyledText>
          <StyledInput value={data.socialChannelName} disabled />
        </StyledText>
      </StyledRow>
      <StyledRow>
        <StyledText>{t('kol_social_data_profile_title')}</StyledText>
        <StyledText>
          <StyledInput value={data.profileTitle} disabled />
        </StyledText>
      </StyledRow>
      <StyledText>{t('kol_social_data_social_data_title')}</StyledText>
      <StyledInfo>
        <StyledInfoTitles>
          <StyledTitles>{t('kol_social_data_followers')}</StyledTitles>
          <StyledTitles>{t('kol_social_data_likes')}</StyledTitles>
          <StyledTitles>{t('kol_social_data_posts')}</StyledTitles>
          <StyledTitles>{t('kol_social_data_comments')}</StyledTitles>
          <StyledTitles>{t('kol_social_data_shares')}</StyledTitles>
          <StyledTitles>{t('kol_social_data_impressions')}</StyledTitles>
          <StyledTitles>{t('kol_social_data_views')}</StyledTitles>
        </StyledInfoTitles>
        <StyledInfoTitles>
          <StyledTitles>{data.followersCount ?? 0}</StyledTitles>
          <StyledTitles>{data.profileLikes ?? 0}</StyledTitles>
          <StyledTitles>{data.profilePosts ?? 0}</StyledTitles>
          <StyledTitles>{data.profileComments ?? 0}</StyledTitles>
          <StyledTitles>{data.sharesCount ?? 0}</StyledTitles>
          <StyledTitles>{data.impressionsCount ?? 0}</StyledTitles>
          <StyledTitles>{data.profileViews ?? 0}</StyledTitles>
        </StyledInfoTitles>
      </StyledInfo>
      {data?.socialChannelName === SocialChannels.TWITTER && (
        <StyledInfo>
          <StyledInfoTitles>
            <StyledTitles>
              {t('kols_influencer_data_grade_score_title')}
            </StyledTitles>
            <StyledTitles>
              {t('kols_influencer_data_influencer_score_title')}
            </StyledTitles>
            <StyledTitles>{t('kol_social_data_follower_score')}</StyledTitles>
            <StyledTitles>
              {t('kol_social_data_coordinated_followers')}
            </StyledTitles>
          </StyledInfoTitles>
          <StyledInfoTitles>
            <StyledTitles>{data.gradeScore ?? '-'}</StyledTitles>
            <StyledTitles>{data.influenceScore ?? 0}</StyledTitles>
            <StyledTitles>{data.followerQualityScore ?? 0}</StyledTitles>
            <StyledTitles>{data.coordinatedFollowers ?? 0}</StyledTitles>
          </StyledInfoTitles>
        </StyledInfo>
      )}
    </StyledInfoWrapper>
  );
};

export default SocialChannelInfo;
