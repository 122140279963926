import styled from 'styled-components';
import { Button } from '@/ui';
import { COLORS, pickThemeFontStyles, DEVICES } from '@/theme';

export const StyledButton = styled(Button)`
  display: flex;
  margin-top: 1rem;
  &&& {
    width: 100%;
    height: 1.5rem;
    background: ${COLORS.SOLITUDE_GRAY};
    color: ${COLORS.DARK_BLUE};
    ${pickThemeFontStyles('10', '10', 'SEMI_BOLD')};
    align-items: center;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-top: 0rem;
    &&& {
      width: auto;
    }
  }
`;
