// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Spinner, StyledSpinnerWrap } from '@/ui';
import { StyledTitles } from './FundingRoundPayoutListStyles';
import { PayoutListWrapper, StyledInfoTitles } from '../Styled';
import FundingRoundCard from '../FundingRoundCard/FundingRoundCard';

// Modules
import { IFundingPayoutListItem } from '@/models/payout.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

interface IFundingRoundPayoutList {
  payoutList?: IFundingPayoutListItem[] | null;
  isLoading: boolean;
  completeFundingRound: (fundingRoundId: string, isRequest?: boolean) => void;
}

const FundingRoundPayoutList = ({
  payoutList,
  isLoading,
  completeFundingRound,
}: IFundingRoundPayoutList) => {
  const { t } = useTranslation(['payout']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedPayoutList = useMemo(() => {
    return payoutList?.map((data) => {
      return (
        <FundingRoundCard
          data={data}
          key={data?.id}
          completeFundingRound={completeFundingRound}
        />
      );
    });
  }, [payoutList]);

  return (
    <PayoutListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('payout_funding_round_title')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_date_title')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_target_title')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_budget_title')}</StyledTitles>
          <StyledTitles>
            {t('payout_funding_round_total_payout_title')}
          </StyledTitles>
          <StyledTitles>{t('payout_account_manager_title')}</StyledTitles>
          <StyledTitles>{t('payout_finance_manager_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedPayoutList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </PayoutListWrapper>
  );
};

export default FundingRoundPayoutList;
