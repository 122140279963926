// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledModal,
  StyledDiffInfo,
  StyledActionTitle,
} from './LogCardStyles';
import LogsDiffs from './LogsDiffs';
import { ModalSize } from '@/components';
import { Title } from '@/ui';

// Models
import { ILogs } from '@/models/logs.model';

interface ILogDifferenceModal {
  log: ILogs;
  isOpened: boolean;
  handleClose: () => void;
}

const LogDifferenceModal = ({
  log,
  isOpened,
  handleClose,
}: ILogDifferenceModal) => {
  const { t } = useTranslation(['logs']);

  return (
    <StyledModal
      visible={isOpened}
      onCancel={handleClose}
      size={ModalSize.HUGE}
    >
      <Title $type="h2">{t('logs_difference')}</Title>
      <StyledDiffInfo $isNewData>
        <StyledActionTitle>{t('logs_difference_new')}</StyledActionTitle>
        <LogsDiffs data={log?.difference?.newData} />
      </StyledDiffInfo>
      <StyledDiffInfo>
        <StyledActionTitle>{t('logs_difference_old')}</StyledActionTitle>
        <LogsDiffs data={log?.difference?.oldData} />
      </StyledDiffInfo>
    </StyledModal>
  );
};

export default LogDifferenceModal;
