import { DatePicker } from 'antd';
import styled, { css } from 'styled-components';
import { COLORS, pickThemeFontSize } from '@/theme';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledPrefix = styled.span`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: ${pickThemeFontSize('20')};
  color: ${COLORS.DARKEST_GRAY};
  margin: 0;
  padding: 0 0.625rem 0 1rem;
  z-index: 1;
`;

const arrowStyles = css`
  .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: none;
  }

  .ant-select-arrow {
    margin: 0;
    transform: translateY(-50%);
  }

  .ant-select-clear {
    width: 1.5rem;
    height: 1.5rem;
    font-size: ${pickThemeFontSize('16')};
    margin-top: -0.5rem;
    background: ${COLORS.LIGHTEST_GRAY};
  }
`;

const datePickerStyles = css`
  ${StyledPrefix} + &.ant-select {
    &-single:not(&-customize-input) .ant-select-selector,
    &-multiple:not(&-customize-input) .ant-select-selector {
      padding-left: 2.875rem;
    }

    &-multiple .ant-select-selection-placeholder,
    &-single .ant-select-selector .ant-select-selection-search {
      left: 2.875rem;
    }
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  ${arrowStyles};
  ${datePickerStyles};
`;
