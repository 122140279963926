import {
  LegalAgreementRoles,
  UserActionLogTypes,
} from '@/models/sharedProfile.model';
import { env } from 'src/config/env';

export const getTermsLink = (
  type: UserActionLogTypes,
  role?: LegalAgreementRoles,
) => {
  switch (type) {
    case UserActionLogTypes.AFFILIATE_JOINED:
      return env.TERMS_AND_CONDITIONS_AFFILIATE_URL;
    case UserActionLogTypes.SIGNED_UP:
      return role === LegalAgreementRoles?.KOL
        ? env.TERMS_AND_CONDITIONS_KOL_URL
        : env.TERMS_AND_CONDITIONS_PROJECT_URL;
  }
};
