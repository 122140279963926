import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { InfoBlueIcon } from '@/icons';

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('14', '24', 'BOLD')};
  color: ${COLORS.DEEP_PURPLE};
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const StyledInfoBlueIcon = styled(InfoBlueIcon)`
  margin-left: 0.5rem;
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('14', '24', 'NORMAL')};
  color: ${COLORS.DUSTY_LAVENDER};
  margin-top: 0.5rem;
  white-space: break-spaces;
`;
