import { IApiError } from '@/models/apiError.model';
import {
  IFundingContract,
  IFundingRound,
  IFundingRoundParticipation,
} from '@/models/fundingRound.model';

export const FUNDING_ROUND_SLICE_NAME = 'funding-round';

export interface FundingRoundState {
  fundingRoundsListData: {
    items: IFundingRound[] | null;
    hasMore: boolean;
  };
  fundingRound: IFundingRound | null;
  fundingRoundParticipations: IFundingRoundParticipation[] | null;
  isLoading: boolean;
  fundingContract: IFundingContract | null;
  error: IApiError | null;
}

export const initialState: FundingRoundState = {
  fundingRoundsListData: {
    items: [],
    hasMore: true,
  },
  fundingRound: null,
  fundingRoundParticipations: null,
  fundingContract: null,
  isLoading: false,
  error: null,
};
