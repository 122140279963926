import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../../store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppState: TypedUseSelectorHook<RootState> = useSelector;

export { default as useThemeToken } from './useThemeToken';
export { useAppStageRestriction } from './useAppStageRestriction';
export { useAppStage } from './useAppStage';
export { default as useInfiniteScroll } from './useInfiniteScroll';
export { default as useTabs } from './useTabs';
export { default as useScript } from './useScript';
export { default as useBoolean } from './useBoolean';
export { default as useHorizontalScrollDetector } from './scroll';
export { default as useModal } from './useModal';
export { default as usePrevious } from './usePrevious';
export { default as useDynamicList } from './useDynamicList';
export { default as useImperativeInterval } from './useImperativeInterval';
export { default as useCopyToClipboard } from './useCopyToClipboard';
export * from './formValidations';
