import styled from 'styled-components';
import { BlockLayout, Slider, Select } from '@/ui';
import { COLORS, DEVICES, SHADOWS, pickThemeFontStyles } from '@/theme';

export const StyledSelectWrapper = styled.div`
  display: flex;
  width: 9.5rem;
`;

export const StyledSelect = styled(Select)`
  & {
    width: 9rem !important;
    box-shadow: 0 0 4px rgba(151, 151, 151, 0.32);
    border-radius: 0.5rem;
    .ant-select-selection-item {
      display: flex;
      ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
      justify-content: center;
    }
  }
`;

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  min-height: 6rem;
  margin: 2rem 0rem;
  flex-direction: column;
  gap: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 12rem;
  }
`;

export const StyledTitle = styled.h2`
  ${pickThemeFontStyles('16', '25', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('14', '20', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledBlock = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &&& {
    padding: 1.5rem 1.5rem 0.8rem 1.5rem;
  }
`;

export const ProgressWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 3rem);
  margin: 0rem -1.5rem;
  padding: 1.5rem 1.5rem;
  background: linear-gradient(
    89.76deg,
    rgba(253, 174, 51, 0.3) 2.3%,
    rgba(244, 149, 53, 0.3) 97.85%
  );
  border-radius: 16px 16px 0 0;
`;

export const ChannelsSlidersWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem -0.3rem;
  width: calc(100% + 1rem);
`;

export const StyledMarketingBlock = styled(BlockLayout)`
  display: flex;
  align-items: center;
  min-width: 100%;
  height: 100%;
  margin-bottom: 1.5rem;
  gap: 2rem;
  flex-direction: row;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
`;

export const StyledChannelTitles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const StyledChannelsInfo = styled.div`
  display: flex;
  min-width: 80%;
  flex-direction: column;
  gap: 1.2rem;
  margin-top: 2.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    margin-top: 0rem;
    min-width: 100%;
  }
`;

export const StyledItem = styled.div<{
  $isCentered?: boolean;
  $isColumn?: boolean;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 0.5rem;
  justify-content: ${({ $isCentered }) => ($isCentered ? 'center' : 'normal')};
  flex-direction: ${({ $isColumn }) => ($isColumn ? 'column' : 'row')};
  ${pickThemeFontStyles('16', '25', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};

  :first-child {
    flex: 0.5;
  }
`;

export const StyledMarketingHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const StyledBadge = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  ${pickThemeFontStyles('10', '16', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  background: ${COLORS.LIGHT_BLUE};
`;

export const BlockWrapper = styled.div`
  width: 100%;
  min-height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIconWrapper = styled.div`
  width: 100%;
  transform: scale(0.5);
`;

export const ChannelTitle = styled.div`
  ${pickThemeFontStyles('14', '24', 'BOLD')}
  color: ${COLORS.GRAY};
  margin-left: -0.25rem;
  align-items: center;
`;

export const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  gap: 0.5rem;
  min-height: 4rem;
  align-items: center;
  border-bottom: 1px solid ${COLORS.SOLITUDE_GRAY};

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  @media screen and ${DEVICES.TABLET_S} {
    flex-direction: row;
    padding-bottom: 0;
  }
`;

export const StyledSlider = styled(Slider)`
  width: 100%;
  cursor: auto;

  &&&.ant-slider .ant-slider-track {
    background-color: ${COLORS.PURPLE_BLUE};
  }

  &&&.ant-slider .ant-slider-handle::after {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    width: 24px;
    height: 24px;
  }

  &&&.ant-slider .ant-slider-handle {
    display: none;
  }
`;

export const StyledHeaderBlock = styled.div`
  width: 100%;
  background: ${COLORS.WHITE};
  box-shadow: ${SHADOWS.MEDIUM};
  border-radius: 1rem;
  display: flex;
  min-height: 5rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
  justify-content: space-between;
  align-items: center;

  @media screen and ${DEVICES.LAPTOP_S} {
    padding: 1.5rem;
  }
`;

export const StyledHeaderBlocksWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-wrap: nowrap;
    gap: 1.5rem;
  }
`;

export const StyledHeaderLabel = styled.div`
  color: ${COLORS.LIQUORICE_BLACK};
  ${pickThemeFontStyles('14', '20', 'SEMI_BOLD')};
`;

export const DateWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const StyledTag = styled.div`
  text-align: center;
  min-width: 5.5rem;
  border-radius: 6rem;
  background: ${COLORS.SOLITUDE_GRAY};
  margin-top: 0.25rem;
  color: ${COLORS.SOLITUDE_DARK};
  white-space: pre;
  width: 100%;
  padding: 0.125rem 0.5rem;
  display: flex;
  justify-content: center;
  ${pickThemeFontStyles('12', '16', 'BOLD')}
`;

export const TagsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  @media screen and ${DEVICES.TABLET_S} {
    justify-content: space-between;
  }
`;
