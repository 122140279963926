import omit from 'lodash.omit';
import { ApiResponse, httpGet, httpPost } from './httpClient';
import {
  IGetKOLInvestmentsDTO,
  IGetKOLsListDTO,
  IKOL,
  IKOLAffiliateResponse,
  IKOLsDetailsDTO,
  IKOLsListResponse,
  KOLsListEndpoints,
  IUpdateKOLsDetailsDTO,
  IBlockKOLUser,
} from '@/models/kolsList.model';
import {
  IGetInvitedUsersDTO,
  IGetInvitedUsersResponse,
} from '@/models/sharedProfile.model';

class KOLsListAPI {
  static getKOLsList(data: IGetKOLsListDTO): ApiResponse<IKOLsListResponse> {
    return httpGet<IKOLsListResponse>(KOLsListEndpoints.GET_KOLS_LIST, {
      ...data,
    });
  }
  static getKOLDetails(id: string): ApiResponse<IKOL> {
    return httpGet<IKOL>(`${KOLsListEndpoints.GET_KOLS_LIST}/${encodeURI(id)}`);
  }

  static getKOLAffiliateData(
    data: IGetKOLInvestmentsDTO,
  ): ApiResponse<IKOLAffiliateResponse> {
    return httpGet<IKOLAffiliateResponse>(
      `${KOLsListEndpoints.GET_KOLS_LIST}/${encodeURI(
        data?.kolId ?? '',
      )}/marketing-affiliate`,
      { ...data },
    );
  }

  static updateKOLDetails(data: IUpdateKOLsDetailsDTO) {
    const { kol, id } = data;
    return httpPost<IKOLsDetailsDTO, IKOL>(
      `${KOLsListEndpoints.GET_KOLS_LIST}/${encodeURI(id)}`,
      kol,
    );
  }

  static blockKOLUser(data: IBlockKOLUser) {
    return httpPost<IBlockKOLUser, IKOL>(
      `${KOLsListEndpoints.GET_KOLS_LIST}/${encodeURI(data?.id ?? '')}/block`,
      omit(data, 'id'),
    );
  }

  static getKOLSocialChannels(id: string): ApiResponse<IKOL> {
    return httpGet<IKOL>(
      `${KOLsListEndpoints.GET_KOLS_LIST}/${encodeURI(id)}/social-channels`,
    );
  }

  static getInvitedUsers({
    userId,
    ...data
  }: IGetInvitedUsersDTO): ApiResponse<IGetInvitedUsersResponse> {
    return httpGet<IGetInvitedUsersResponse>(
      `${KOLsListEndpoints.GET_KOLS_LIST}/${encodeURI(userId)}/invited-users`,
      {
        ...data,
      },
    );
  }
}

export default KOLsListAPI;
