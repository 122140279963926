import { FormOfAddress } from '@/models/sharedProfile.model';

interface IRenderUsername {
  firstName?: string;
  lastName?: string;
  formAddress?: string;
}

export const renderUsername = ({
  firstName,
  lastName,
  formAddress,
}: IRenderUsername) => {
  const formOfAddress =
    formAddress === FormOfAddress.UNDEFINED || !formAddress
      ? ''
      : `${formAddress}.`;
  return `${formOfAddress} ${firstName} ${lastName}`;
};

export default renderUsername;
