import styled from 'styled-components';
import { Form, FormItem } from '@/components';
import { BlockLayout, StyledInput, Title } from '@/ui';
import { COLORS, pickThemeFontStyles } from '@/theme';

export const StyledBlockWrapper = styled(BlockLayout)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  padding: 3rem;
  margin-top: 2rem;

  &:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const StyledBlockTitle = styled(Title)`
  ${pickThemeFontStyles('20', '12', 'BOLD')};
`;

export const StyledActionsWrap = styled(BlockLayout)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 1rem;
  padding: 1rem;
`;

export const StyledActions = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 2rem;
  border-radius: 1rem;
  padding: 1rem;
`;

export const StyledForm = styled(Form)`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0.5rem;

  &&& .ant-select-show-arrow {
    margin-bottom: 0;
  }
`;

export const StyledLabel = styled(Title)`
  ${pickThemeFontStyles('16', '16', 'SEMI_NORMAL')};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledTitle = styled(Title)`
  ${pickThemeFontStyles('24', '28', 'BOLD')};
  display: flex;
  gap: 0.5rem;
`;

export const StyledDot = styled.div`
  background: ${COLORS.LIQUORICE_BLACK};
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
`;

export const Input = styled(StyledInput)`
  &&& .ant-input-prefix {
    ${pickThemeFontStyles('14', '14', 'SEMI_NORMAL')};
    margin-left: 1rem;
  }
  &&& .ant-input {
    background: ${COLORS.LIGHTEST_GRAY};
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const AffiliateInput = styled(StyledInput)`
  &&& .ant-input-suffix {
    ${pickThemeFontStyles('14', '14', 'SEMI_NORMAL')};
    align-items: center;
    position: static;
    margin-bottom: 0rem;
    width: auto;
  }
  &&& .ant-input {
    padding: 0.313rem 0rem 0.313rem 0.688rem;
    width: 2.5rem;
    background: ${COLORS.LIGHTEST_GRAY};
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
