import { useContext } from 'react';
import { AppStageContext } from '../context/AppStageContext';

export const useAppStage = () => {
  const { appStage, toggleAppStage, forceAppStage } =
    useContext(AppStageContext) ?? {};

  return {
    appStage,
    toggleAppStage,
    forceAppStage,
  };
};

export default useAppStage;
