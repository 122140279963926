import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { BlockLayout } from '@/ui';
import { GeneralStatisticTypes } from '@/modules/Dashboard/constants';

export const StyledCardWrapper = styled(BlockLayout)`
  width: 100%;
  padding: 1.5rem;
  gap: 1rem;
  display: flex;
`;

export const StyledValueBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledText = styled.div`
  color: ${COLORS.GRAY};
  ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
`;

export const StyledCount = styled.div`
  color: ${COLORS.SAMBUCUS};
  ${pickThemeFontStyles('20', '20', 'SEMI_BOLD')};
  margin-right: 0.5rem;
`;

export const StyledIncrease = styled.div`
  color: ${COLORS.LIGHT_GREEN};
  ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
`;

export const IconWrap = styled.div<{ $type: GeneralStatisticTypes }>`
  border-radius: 0.5rem;
  padding: 0.88rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ $type }) => {
    switch ($type) {
      case GeneralStatisticTypes.USERS:
        return COLORS.CHABLIS_PEACH;
      case GeneralStatisticTypes.PROJECTS:
        return COLORS.CYAN_LIGHT;
      case GeneralStatisticTypes.INVESTMENTS:
        return COLORS.LAVENDER_BLUSH;
    }
  }};

  svg {
    path {
      fill: ${({ $type }) => {
        switch ($type) {
          case GeneralStatisticTypes.USERS:
            return COLORS.BRIGHT_ORANGE;
          case GeneralStatisticTypes.PROJECTS:
            return COLORS.TURQUOISE_BLUE;
          case GeneralStatisticTypes.INVESTMENTS:
            return COLORS.PINK_SALMON;
        }
      }};
    }
  }
`;
