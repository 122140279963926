import styled from 'styled-components';
import { DEVICES } from '@/theme';

export const PayoutListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  :last-child {
    align-items: flex-start;
  }

  :nth-last-child(2) {
    align-items: flex-start;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    :last-child {
      align-items: center;
    }

    :nth-last-child(2) {
      align-items: center;
    }
  }
`;
