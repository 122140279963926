// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledRefundTitles,
  StyledInfoTitles,
  StyledDeliveryTitle,
  StyledHeader,
  InvitedUsersWrapper,
} from './InvitedUsersStyles';
import InvitedUserCard from './InvitedUserCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { IUser } from '@/models/admin.model';
import { useInfiniteScroll } from '@/hooks';

interface IInvitedUsers {
  invitedUsers: IUser[] | null;
  isLoading: boolean;
  hasMore: boolean;
  getNextInvitedUsers: () => void;
}

const InvitedUsers = ({
  invitedUsers,
  getNextInvitedUsers,
  isLoading,
  hasMore,
}: IInvitedUsers) => {
  const { t } = useTranslation(['affiliate']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedInvitedUsers = useMemo(() => {
    return invitedUsers?.map((data) => {
      return <InvitedUserCard data={data} key={data.id} />;
    });
  }, [invitedUsers]);

  useInfiniteScroll({
    callback: getNextInvitedUsers,
    ref: loaderRef,
    isLoading,
    hasMore,
  });

  if (!invitedUsers?.length) return null;

  return (
    <InvitedUsersWrapper>
      <StyledHeader>
        <StyledDeliveryTitle>
          {t('affiliate_invited_users')}
        </StyledDeliveryTitle>
      </StyledHeader>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledRefundTitles>
            {t('affiliate_invited_users_name')}
          </StyledRefundTitles>
          <StyledRefundTitles>
            {t('affiliate_invited_users_email')}
          </StyledRefundTitles>
          <StyledRefundTitles>
            {t('affiliate_invited_users_role')}
          </StyledRefundTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedInvitedUsers}
      <div ref={loaderRef} />
    </InvitedUsersWrapper>
  );
};

export default InvitedUsers;
