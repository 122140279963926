import { SocialChannels } from '@/models/socialChannel.model';

const FacebookDomains = [
  'accountkit.com',
  'acebook.com',
  'faacebook.com',
  'facebbook.com',
  'facebook-dns.com',
  'facebook-hardware.com',
  'facebook.co',
  'facebook.com',
  'facebook.com.au',
  'facebook.com.mx',
  'facebook.it',
  'facebook.net',
  'facebookcareers.com',
  'facebookenterprise.com',
  'facebookmail.com',
  'facebookrecruiting.com',
  'facebookstudy.com',
  'facebookvirtualassistant.com',
  'fb.audio',
  'fb.com',
  'fb.gg',
  'fb.me',
  'fb.watch',
  'fbcdn.net',
  'fbe2e.com',
  'fbinfra.net',
  'fbpigeon.com',
  'fbsbx.com',
  'fbthirdpartypixel.com',
  'fbwat.ch',
  'internet.org',
  'm-freeway.com',
  'parse.com',
  'wit.ai',
];

const TwitterDomains = [
  'ads-twitter.com',
  'periscope.tv',
  'pscp.tv',
  't.co',
  'x.com',
  'tweetdeck.com',
  'twimg.com',
  'twitpic.com',
  'twitter.co',
  'twitter.com',
  'twitterinc.com',
  'twitteroauth.com',
  'twitterstat.us',
  'twttr.com',
];

const InstagramDomains = ['cdninstagram.com', 'ig.me', 'instagram.com'];

const LinkedinDomains = [
  'linkedin.at',
  'linkedin.cn',
  'linkedin.com',
  'linkedinmobileapp.com',
  'linkedin.qtlcdn',
  'lnkd.in',
];

const YoutubeDomains = [
  'googlevideo.com',
  'gvt1.com',
  'video.google.com',
  'video.l.google.com',
  'youtu.be',
  'youtube-nocookie.com',
  'youtube-ui.l.google.com',
  'youtube.ae',
  'youtube.al',
  'youtube.am',
  'youtube.at',
  'youtube.az',
  'youtube.ba',
  'youtube.be',
  'youtube.bg',
  'youtube.bh',
  'youtube.bo',
  'youtube.by',
  'youtube.ca',
  'youtube.cat',
  'youtube.ch',
  'youtube.cl',
  'youtube.co',
  'youtube.co.ae',
  'youtube.co.at',
  'youtube.co.cr',
  'youtube.co.hu',
  'youtube.co.id',
  'youtube.co.il',
  'youtube.co.in',
  'youtube.co.jp',
  'youtube.co.ke',
  'youtube.co.kr',
  'youtube.co.ma',
  'youtube.co.nz',
  'youtube.co.th',
  'youtube.co.tz',
  'youtube.co.ug',
  'youtube.co.uk',
  'youtube.co.ve',
  'youtube.co.za',
  'youtube.co.zw',
  'youtube.com',
  'youtube.com.ar',
  'youtube.com.au',
  'youtube.com.az',
  'youtube.com.bd',
  'youtube.com.bh',
  'youtube.com.bo',
  'youtube.com.br',
  'youtube.com.by',
  'youtube.com.co',
  'youtube.com.do',
  'youtube.com.ec',
  'youtube.com.ee',
  'youtube.com.eg',
  'youtube.com.es',
  'youtube.com.gh',
  'youtube.com.gr',
  'youtube.com.gt',
  'youtube.com.hk',
  'youtube.com.hn',
  'youtube.com.hr',
  'youtube.com.jm',
  'youtube.com.jo',
  'youtube.com.kw',
  'youtube.com.lb',
  'youtube.com.lv',
  'youtube.com.ly',
  'youtube.com.mk',
  'youtube.com.mt',
  'youtube.com.mx',
  'youtube.com.my',
  'youtube.com.ng',
  'youtube.com.ni',
  'youtube.com.om',
  'youtube.com.pa',
  'youtube.com.pe',
  'youtube.com.ph',
  'youtube.com.pk',
  'youtube.com.pt',
  'youtube.com.py',
  'youtube.com.qa',
  'youtube.com.ro',
  'youtube.com.sa',
  'youtube.com.sg',
  'youtube.com.sv',
  'youtube.com.tn',
  'youtube.com.tr',
  'youtube.com.tw',
  'youtube.com.ua',
  'youtube.com.uy',
  'youtube.com.ve',
  'youtube.cr',
  'youtube.cz',
  'youtube.de',
  'youtube.dk',
  'youtube.ee',
  'youtube.es',
  'youtube.fi',
  'youtube.fr',
  'youtube.ge',
  'youtube.googleapis.com',
  'youtube.gr',
  'youtube.gt',
  'youtube.hk',
  'youtube.hr',
  'youtube.hu',
  'youtube.ie',
  'youtube.in',
  'youtube.iq',
  'youtube.is',
  'youtube.it',
  'youtube.jo',
  'youtube.jp',
  'youtube.kr',
  'youtube.kz',
  'youtube.la',
  'youtube.lk',
  'youtube.lt',
  'youtube.lu',
  'youtube.lv',
  'youtube.ly',
  'youtube.ma',
  'youtube.md',
  'youtube.me',
  'youtube.mk',
  'youtube.mn',
  'youtube.mx',
  'youtube.my',
  'youtube.ng',
  'youtube.ni',
  'youtube.nl',
  'youtube.no',
  'youtube.pa',
  'youtube.pe',
  'youtube.ph',
  'youtube.pk',
  'youtube.pl',
  'youtube.pr',
  'youtube.pt',
  'youtube.qa',
  'youtube.ro',
  'youtube.rs',
  'youtube.ru',
  'youtube.sa',
  'youtube.se',
  'youtube.sg',
  'youtube.si',
  'youtube.sk',
  'youtube.sn',
  'youtube.soy',
  'youtube.sv',
  'youtube.tn',
  'youtube.tv',
  'youtube.ua',
  'youtube.ug',
  'youtube.uy',
  'youtube.vn',
  'youtubeeducation.com',
  'youtubeembeddedplayer.googleapis.com',
  'youtubei.googleapis.com',
  'youtubekids.com',
  'yt-video-upload.l.google.com',
  'yt.be',
  'yt3.ggpht.com',
  'ytimg.com',
  'ytimg.l.google.com',
  'ytkids.app.goo.gl',
];

const TelegramDomains = ['telegram.me', 'telegram.org', 'telesco.pe', 't.me'];
const RedditDomains = [
  'redd.it',
  'redditblog.com',
  'reddit.com',
  'redditinc.com',
  'redditmail.com',
  'redditmedia.com',
  'redditspace.com',
  'redditstatic.com',
  'redditstatus.com',
];
const TikTokDomains = ['tiktok.com', 'tiktokd.org', 'tiktok.in'];

const extractDomain = (domains: string[]) => {
  return domains.flatMap((item) => [item, 'www.' + item]);
};

export const networksDomains = {
  [SocialChannels.FACEBOOK]: extractDomain(FacebookDomains),
  [SocialChannels.INSTAGRAM]: extractDomain(InstagramDomains),
  [SocialChannels.TWITTER]: extractDomain(TwitterDomains),
  [SocialChannels.LINKEDIN]: extractDomain(LinkedinDomains),
  [SocialChannels.YOUTUBE]: extractDomain(YoutubeDomains),
  [SocialChannels.TELEGRAM]: extractDomain(TelegramDomains),
  [SocialChannels.REDDIT]: extractDomain(RedditDomains),
  [SocialChannels.TIKTOK]: extractDomain(TikTokDomains),
};
