// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Modules
import { socialChannelsConfig } from '@/modules/KolsList';

// Models
import { SocialChannels } from '@/models/socialChannel.model';

// Components
import {
  StyledCell,
  StyledChannelValue,
  StyledRowBlock,
} from '../ContentDistribution/ContentDistributionStyles';

const HeaderRow = () => {
  const { t } = useTranslation(['campaigns']);

  return (
    <StyledRowBlock>
      <StyledCell />
      <StyledCell>
        {socialChannelsConfig[SocialChannels.YOUTUBE]?.icon}
        <StyledChannelValue>
          {t(SocialChannels.YOUTUBE, { ns: 'common' })}
        </StyledChannelValue>
      </StyledCell>
      <StyledCell>
        {socialChannelsConfig[SocialChannels.TWITTER]?.icon}
        <StyledChannelValue>
          {t(SocialChannels.TWITTER, { ns: 'common' })}
        </StyledChannelValue>
      </StyledCell>
      <StyledCell>
        {socialChannelsConfig[SocialChannels.INSTAGRAM]?.icon}
        <StyledChannelValue>
          {t(SocialChannels.INSTAGRAM, { ns: 'common' })}
        </StyledChannelValue>
      </StyledCell>
      <StyledCell>
        {socialChannelsConfig[SocialChannels.FACEBOOK]?.icon}
        <StyledChannelValue>
          {t(SocialChannels.FACEBOOK, { ns: 'common' })}
        </StyledChannelValue>
      </StyledCell>
      <StyledCell>
        <StyledChannelValue>
          {t('content_distribution_total')}
        </StyledChannelValue>
      </StyledCell>
    </StyledRowBlock>
  );
};

export default HeaderRow;
