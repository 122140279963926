// Globals
import React from 'react';
import { useParams } from 'react-router-dom';

// Components
import { StyledPageWrapper } from '@/ui';
import CampaignDetailsContainer from '../CampaignDetailsContainer/CampaignDetailsContainer';
import FundingRoundDetailsContainer from '../FundingRoundDetailsContainer/FundingRoundDetailsContainer';

// Helpers | Utils
import { PayoutTabKeys } from '../../constants';

const PayoutDetailsListContainer = () => {
  const { section } = useParams();

  return (
    <StyledPageWrapper>
      {section === PayoutTabKeys.CAMPAIGN ? (
        <CampaignDetailsContainer />
      ) : (
        <FundingRoundDetailsContainer />
      )}
    </StyledPageWrapper>
  );
};

export default PayoutDetailsListContainer;
