// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Store
import {
  selectAuthCurrentAction,
  selectAuthIsLoading,
  selectAuthSessionId,
  selectAuthSetupMfaQrCode,
  selectAuthSetupMfaSecretCode,
} from '../../feature/selectors';
import { signIn, signInVerify } from '../../feature/actionCreators';
import { resetAuthState } from '../../feature/slice';

// Hooks
import { useAppDispatch, useAppState } from '@/hooks';

// Components
import { showErrorToast, showSuccessToast } from '@/components';
import {
  StyledFormWrapper,
  StyledLayout,
  StyledPageWrapper,
} from './LoginContainerStyles';
import {
  LogInForm,
  NewPasswordForm,
  OtpCodeForm,
  SetupMfaQrCode,
} from '../../components';

// Helpers
import {
  AdminSignInActions,
  ISignInDTO,
  ISignInVerifyDTO,
} from '@/models/auth.model';
import {
  AuthFieldsNames,
  TCreateNewPasswordFormValues,
  TSignInFormValues,
  TSignInOtpCodeFormValues,
} from '../../helpers/types';
import { useAuthorizedRedirect } from '../../hooks';

const LogInContainer = () => {
  const { t } = useTranslation(['auth']);
  const dispatch = useAppDispatch();

  const isLoading = useAppState(selectAuthIsLoading);
  const authCurrentAction = useAppState(selectAuthCurrentAction);
  const authSessionId = useAppState(selectAuthSessionId);
  const authSetupMfaQrCode = useAppState(selectAuthSetupMfaQrCode);
  const authSetupMfaSecretCode = useAppState(selectAuthSetupMfaSecretCode);

  useAuthorizedRedirect();

  const handleSignIn = (values: TSignInFormValues) => {
    const dto: ISignInDTO = {
      email: values[AuthFieldsNames.EMAIL],
      password: values[AuthFieldsNames.PASSWORD],
    };

    dispatch(signIn(dto));
  };

  const handleSignInVerify = <T extends { [key in AuthFieldsNames]?: string }>(
    values: T,
  ) => {
    if (!authSessionId) {
      showErrorToast({ message: t('auth_session_id_error') });
      return;
    }

    const data = (values[AuthFieldsNames.NEW_PASSWORD] ||
      values[AuthFieldsNames.OTP_CODE]) as string;

    const dto: ISignInVerifyDTO = {
      data,
      session: authSessionId,
    };

    dispatch(signInVerify(dto));
  };

  const handleVerifyMfaSetup = async (values: TSignInOtpCodeFormValues) => {
    if (!authSessionId) {
      showErrorToast({ message: t('auth_session_id_error') });
      return;
    }

    const dto: ISignInVerifyDTO = {
      data: values[AuthFieldsNames.OTP_CODE],
      session: authSessionId,
    };

    try {
      await dispatch(signInVerify(dto)).unwrap();
      showSuccessToast({ message: t('auth_setup_mfa_successful') });
      dispatch(resetAuthState());
    } catch (err) {
      console.error(err);
    }
  };

  const getAuthForm = (authCurrentAction: AdminSignInActions) => {
    switch (authCurrentAction) {
      case AdminSignInActions.SIGN_IN:
        return <LogInForm handleSubmit={handleSignIn} isLoading={isLoading} />;
      case AdminSignInActions.CREATE_NEW_PASSWORD:
        return (
          <NewPasswordForm
            handleSubmit={handleSignInVerify<TCreateNewPasswordFormValues>}
            isLoading={isLoading}
          />
        );
      case AdminSignInActions.SETUP_MFA:
        return (
          <SetupMfaQrCode
            handleSubmit={handleVerifyMfaSetup}
            isLoading={isLoading}
            qrCode={authSetupMfaQrCode}
            secretCode={authSetupMfaSecretCode}
          />
        );
      case AdminSignInActions.CONFIRM_OTP:
        return (
          <OtpCodeForm
            handleSubmit={handleSignInVerify<TSignInOtpCodeFormValues>}
            isLoading={isLoading}
          />
        );
    }
  };

  return (
    <StyledLayout>
      <StyledPageWrapper>
        <StyledFormWrapper>{getAuthForm(authCurrentAction)}</StyledFormWrapper>
      </StyledPageWrapper>
    </StyledLayout>
  );
};

export default LogInContainer;
