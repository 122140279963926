// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  FilterWrapper,
  StyledChevronIcon,
  StyledSelect,
} from './CampaignReportsFiltersStyles';

// Hooks | Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import { setCampaignReportsFilters } from '../../feature/slice';
import { reportsSliceSelector } from '../../feature/selectors';
import { getFundingFilterOptions } from '../../helpers/getOptions';

const CampaignReportsFilters = () => {
  const { t } = useTranslation(['reports']);
  const dispatch = useAppDispatch();
  const { campaignReportsFilters, campaignFiltersOptions } =
    useAppState(reportsSliceSelector);

  const projectOptions = getFundingFilterOptions(
    campaignFiltersOptions?.projects ?? [],
  );
  const fundingRoundsOptions = getFundingFilterOptions(
    campaignFiltersOptions?.campaigns ?? [],
  );

  const handleProjectFilterChange = (value: unknown) => {
    dispatch(
      setCampaignReportsFilters({
        ...campaignReportsFilters,
        projectId: value,
      }),
    );
  };

  const handleCampaignFilterChange = (value: unknown) => {
    dispatch(
      setCampaignReportsFilters({
        ...campaignReportsFilters,
        campaignId: value,
      }),
    );
  };

  return (
    <FilterWrapper>
      <StyledSelect
        options={projectOptions}
        allowClear
        showSearch
        value={campaignReportsFilters?.projectId}
        onChange={handleProjectFilterChange}
        placeholder={t('reports_filters_project')}
        suffixIcon={<StyledChevronIcon />}
      />
      <StyledSelect
        options={fundingRoundsOptions}
        allowClear
        showSearch
        value={campaignReportsFilters?.campaignId}
        onChange={handleCampaignFilterChange}
        placeholder={t('reports_filters_campaign')}
        suffixIcon={<StyledChevronIcon />}
      />
    </FilterWrapper>
  );
};

export default CampaignReportsFilters;
