// Globals
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

// Components
import { Input } from '@/ui';
import { Form, FormItem } from '@/components';
import {
  StyledButton,
  StyledCopyButton,
  StyledDescription,
  StyledQRCode,
  StyledTitle,
} from '../Styled';

// Helpers
import { AuthFieldsNames, TSignInOtpCodeFormValues } from '../../helpers/types';
import { useGetFormValidationRules } from '../../hooks';
import { normalizeNumber } from '@/utils';
import { useCopyToClipboard } from '@/hooks';

interface ISetupMfaQrCode {
  handleSubmit: (values: TSignInOtpCodeFormValues) => void;
  qrCode: string | null;
  secretCode: string | null;
  isLoading: boolean;
}

const SetupMfaQrCode = ({
  handleSubmit,
  qrCode,
  secretCode,
  isLoading,
}: ISetupMfaQrCode) => {
  const { t } = useTranslation(['auth', 'common']);

  const copyToClipboard = useCopyToClipboard();

  const validationRules = useGetFormValidationRules();
  const placeholder = t('common_placeholder_input', { ns: 'common' });

  const handleCopyCode = () => {
    copyToClipboard(secretCode ?? '');
  };

  return (
    <>
      <StyledTitle>{t('auth_setup_mfa_title')}</StyledTitle>

      <StyledDescription>
        <Trans
          t={t}
          ns={'auth'}
          i18nKey="auth_setup_mfa_instructions"
          components={{
            copy: <StyledCopyButton type={'link'} onClick={handleCopyCode} />,
          }}
        />
      </StyledDescription>

      {!!qrCode && <StyledQRCode src={qrCode} alt="mfa_qr_code" />}

      <StyledDescription>
        {t('auth_setup_mfa_code_instructions')}
      </StyledDescription>

      <Form onFinish={handleSubmit}>
        <FormItem
          name={AuthFieldsNames.OTP_CODE}
          rules={validationRules[AuthFieldsNames.OTP_CODE]}
          label={t('auth_otp_code_label')}
          normalize={normalizeNumber}
        >
          <Input placeholder={placeholder} />
        </FormItem>

        <StyledButton type="primary" htmlType="submit" loading={isLoading}>
          {t('submit', { ns: 'common' })}
        </StyledButton>
      </Form>
    </>
  );
};

export default SetupMfaQrCode;
