import styled from 'styled-components';
import { DEVICES, COLORS, pickThemeFontStyles } from '@/theme';
import { Button, Input, TextArea } from '@/ui';

export const FormFields = styled.div`
  display: flex;
  position: relative;
  gap: 0rem;
  margin-bottom: 0.5rem;
  padding-top: 1rem;
  flex-direction: column;
  border-top: 1px solid ${COLORS.SOLITUDE_GRAY};

  &:first-child {
    border-top: none;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    gap: 1.5rem;
  }
`;

export const StyledInput = styled(Input)`
  background: ${COLORS.LIGHTEST_GRAY};
  height: 2.875rem;
  border: 0;
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  border: 0;
  resize: none !important;
  line-height: 1.6rem !important;
  min-height: 9rem !important;

  &&& .ant-input {
    background: ${COLORS.LIGHTEST_GRAY};
  }

  &::placeholder {
    color: ${COLORS.DARK_GREY};
    padding-top: 0.4rem;
    font-family: 'Inter', sans-serif;
    ${pickThemeFontStyles('15', '15', 'SEMI_NORMAL')};
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledAddButton = styled(Button)`
  &&& {
    width: 100%;
    ${pickThemeFontStyles('12', '15', 'SEMI_NORMAL')};

    .anticon {
      font-size: 1rem;
      display: flex;
      align-items: center;
    }
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &&& {
      width: 9.5rem;
      ${pickThemeFontStyles('14', '15', 'SEMI_NORMAL')};
    }
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  @media screen and ${DEVICES.LAPTOP_S} {
    width: auto;
    height: auto;
  }
`;
