// Globals
import React from 'react';

// Components
import {
  TableRow,
  TableCell,
  NetworkTitle,
} from './BalanceSummaryReportStyles';
import { Tooltip } from '@/ui';

// Modules
import { networksConfig } from '@/modules/Web3';
import { renderCurrenciesValues } from '@/modules/Reports/helpers/dataRender';

// Models
import { TNetworkTransfersData } from '@/models/reports.model';
import { WalletNetworksEnum, CryptoCurrenciesEnum } from '@/models/web3.model';
import { capitalizeString } from '@/utils';

interface IBalanceReportRow {
  data: TNetworkTransfersData;
  network: WalletNetworksEnum;
  targetNetwork?: WalletNetworksEnum;
  walletCurrency?: CryptoCurrenciesEnum;
  walletAddress?: string;
  totalCommission?: number;
}
const BalanceSummaryReportRow = ({
  data,
  network,
  walletAddress,
}: IBalanceReportRow) => {
  return (
    <TableRow>
      <TableCell>
        <img src={networksConfig[network].image} alt="" />
        <NetworkTitle>{capitalizeString(network)}</NetworkTitle>
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode}
          title={walletAddress}
          placement={'top'}
        >
          <NetworkTitle>{walletAddress}</NetworkTitle>
        </Tooltip>
      </TableCell>

      <TableCell>
        {data.raisedBudget && renderCurrenciesValues(data.raisedBudget)}
      </TableCell>

      <TableCell>
        {data.seedHunterCommissionAmount &&
          renderCurrenciesValues(data.seedHunterCommissionAmount)}
      </TableCell>

      <TableCell>
        {data.projectTransfer && renderCurrenciesValues(data.projectTransfer)}
      </TableCell>
    </TableRow>
  );
};

export default BalanceSummaryReportRow;
