import styled from 'styled-components';
import { COLORS, pickThemeFontStyles, DEVICES } from '@/theme';
import { Link, Text } from '@/ui';
import { Modal } from '@/components';

export const StyledModal = styled(Modal)`
  &&& {
    .ant-modal-content {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const StyledDiffInfo = styled.div<{ $isNewData?: boolean }>`
  display: flex;
  margin-top: 0.5rem;
  padding: 0.5rem;
  flex-direction: column;
  gap: 0.5rem;
  background: ${({ $isNewData }) =>
    $isNewData ? `${COLORS.GREEN_BUBBLES}` : `${COLORS.MISTY_ROSE}`};
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${COLORS.LIGHT_GRAY};
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    height: 2rem;
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  :last-child {
    align-items: flex-start;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    :last-child {
      max-width: 15rem;
      align-items: center;
    }
  }
`;

export const StyledText = styled(Text)`
  max-width: 15rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledActionText = styled(Text)`
  max-width: 15rem;
  text-decoration: underline;
  :hover {
    cursor: pointer;
    color: ${COLORS.BLUE_PRIMARY};
  }
`;

export const StyledActionTitle = styled.div`
  ${pickThemeFontStyles('16', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledActionSubTitle = styled.div`
  ${pickThemeFontStyles('16', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledLink = styled(Link)`
  margin-left: 0.3rem;
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;
