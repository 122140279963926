import styled from 'styled-components';
import { BlockLayout } from '@/ui';

export const StyledWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  align-items: flex-start;
`;
