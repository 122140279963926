// Globals
import React, { useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

// Components
import { showErrorToast } from '@/components';
import { StyledTitle } from './FundingRoundListContainerStyles';
import { StyledPageWrapper } from '@/ui';
import { FundingRoundList } from '../../components';

// Modules
import { SearchInfoInput } from '@/modules/SharedProfile';

// Helpers
import { useAppState, useAppDispatch } from '@/hooks';
import { fundingRoundSliceSelector } from '../../feature/selectors';
import { getFundingRoundList } from '../../feature/actionCreators';
import { resetFundingRoundList } from '../../feature/slice';

const FundingRoundListContainer = () => {
  const { t } = useTranslation(['fundingRound']);
  const dispatch = useAppDispatch();
  const { fundingRoundsListData, isLoading } = useAppState(
    fundingRoundSliceSelector,
  );

  const getNextFundingRounds = useCallback(
    debounce(() => {
      if (isLoading || !fundingRoundsListData.hasMore) return;
      const lastItem = fundingRoundsListData?.items?.at(-1);
      dispatch(
        getFundingRoundList({
          startId: lastItem?.id,
        }),
      );
    }, 1000),
    [isLoading, fundingRoundsListData.hasMore, dispatch],
  );

  const handleFundingSearch = async (value: string) => {
    const fundingSearchedList = await dispatch(
      getFundingRoundList({
        search: value,
      }),
    ).unwrap();

    fundingSearchedList?.items?.length === 0 &&
      showErrorToast({
        message: t('empty_data_massage_funding_rounds', { ns: 'profile' }),
      });
  };

  useEffect(
    () => (): void => {
      dispatch(resetFundingRoundList());
    },
    [dispatch],
  );

  return (
    <StyledPageWrapper>
      <StyledTitle $type="h3">{t('funding_rounds_title')}</StyledTitle>
      <SearchInfoInput handleSearch={handleFundingSearch} />
      <FundingRoundList
        data={fundingRoundsListData}
        getNextFundingRounds={getNextFundingRounds}
        isLoading={isLoading}
      />
    </StyledPageWrapper>
  );
};

export default FundingRoundListContainer;
