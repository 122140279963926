import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { PARTNERS_LIST_SLICE_NAME, PartnersListState } from './models';

export const partnersListSliceSelector = (
  state: RootState,
): PartnersListState => state[PARTNERS_LIST_SLICE_NAME];

export const selectPartnersListIsLoading = createSelector(
  partnersListSliceSelector,
  (state: PartnersListState) => state.isLoading,
);
