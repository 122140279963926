// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  Wrapper,
  NavigationWrapper,
  StyledButton,
  StyledTitle,
  StyledText,
  StyledHeaderWrapper,
} from './SubmitContentPlanRequestDetailsStyles';
import { Link } from '@/ui';
import ActionButtons from '../ActionButtons/ActionButtons';
import RequestStatusBadges from '../RequestStatusBadges/RequestStatusBadges';

// Models
import { IRequestDetails } from '@/models/requests.model';

// routes
import { ROUTES_PATHS } from '@/router';

interface ISubmitContentPlanRequestDetails {
  request: IRequestDetails | null;
  isLoading?: boolean;
}

const SubmitContentPlanRequestDetails = ({
  request,
  isLoading,
}: ISubmitContentPlanRequestDetails) => {
  const { t } = useTranslation(['requestsList']);
  const actionTypeLabel = t(`requests_${request?.actionType}_label`);

  return (
    <Wrapper>
      <StyledHeaderWrapper>
        <StyledTitle>{t('request_title')}</StyledTitle>
        <RequestStatusBadges status={request?.status} />
      </StyledHeaderWrapper>
      <StyledText>{`${t('requests_type')} ${actionTypeLabel}`}</StyledText>
      <NavigationWrapper>
        <StyledTitle $type="h2">{request?.project?.title}</StyledTitle>
        <Link
          to={`${ROUTES_PATHS.PROJECTS_LIST}/${encodeURIComponent(
            request?.project?.id ?? '',
          )}/content-distributions}`}
          target="_blank"
        >
          <StyledButton type="primary">
            {t('verification_request_go_to_profile')}
          </StyledButton>
        </Link>
      </NavigationWrapper>
      <ActionButtons request={request} isLoading={isLoading} />
    </Wrapper>
  );
};

export default SubmitContentPlanRequestDetails;
