// Globals
import React from 'react';

// Components
import { StyledRow, StyledFooterBlock } from './CampaignCardStyles';
import { StyledLabels } from '../Styled/Styled';

// Models
import { ICampaign } from '@/models/campaigns.model';

interface ICampaignFooter {
  campaign: ICampaign;
}

const CampaignFooter = ({ campaign }: ICampaignFooter) => {
  return (
    <StyledFooterBlock>
      <StyledRow $isCommunity>
        <StyledLabels>{campaign.projectMainCommunityTitle}</StyledLabels>
        <StyledLabels>{campaign.projectSecondaryCommunityTitle}</StyledLabels>
      </StyledRow>
      <StyledRow $isCommunity>
        <StyledLabels $isColored>
          {campaign.projectMainGeographyTitle}
        </StyledLabels>
        <StyledLabels $isColored>
          {campaign.projectSecondaryGeographyTitle}
        </StyledLabels>
      </StyledRow>
    </StyledFooterBlock>
  );
};

export default CampaignFooter;
