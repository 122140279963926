import { IApiError } from '@/models/apiError.model';
import { ILogs } from '@/models/logs.model';

export const LOGS_SLICE_NAME = 'logs';

export interface LogsState {
  logs: {
    items: ILogs[] | null;
    hasMore: boolean;
  };
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: LogsState = {
  logs: {
    items: [],
    hasMore: true,
  },
  isLoading: false,
  error: null,
};
