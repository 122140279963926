import { IApiError } from '@/models/apiError.model';
import {
  ILegalAgreementResponse,
  IWithdrawalHistory,
  ICreditHistory,
  IFundingRoundAffiliate,
} from '@/models/sharedProfile.model';

export const SHARED_PROFILE_SLICE_NAME = 'shared-profile';

export interface SharedProfileState {
  legalAgreements: ILegalAgreementResponse[] | null;
  withdrawalHistory: IWithdrawalHistory[] | null;
  creditHistory: {
    items: ICreditHistory[] | null;
    hasMore: boolean;
    totalBalance: number;
  };
  fundingRoundAffiliateList: {
    items: IFundingRoundAffiliate[] | null;
    hasMore: boolean;
  };
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: SharedProfileState = {
  legalAgreements: null,
  withdrawalHistory: null,
  creditHistory: {
    items: [],
    hasMore: true,
    totalBalance: 0,
  },
  fundingRoundAffiliateList: {
    items: [],
    hasMore: true,
  },
  isLoading: false,
  error: null,
};
