import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, InputNumber } from '@/ui';
import { Form, FormItem } from '@/components';

export const StyledInfoWrapper = styled(BlockLayout)<{ $isColumn?: boolean }>`
  display: flex;
  width: 100%;
  padding: 1.5rem;
  flex-direction: ${({ $isColumn }) => ($isColumn ? 'column' : 'row')};
  align-items: flex-start;
  gap: 0.5rem;
`;

export const StyledFrom = styled(Form)`
  display: flex;
  width: 100%;
  gap: 1.5rem;
  flex-direction: column;
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0.5rem;
  width: 100%;
  flex-direction: column;

  &&& .ant-form-item-row {
    align-items: flex-start;
    flex-direction: column;

    .ant-form-item-control {
      width: 100%;
    }
  }

  label {
    ${pickThemeFontStyles('14', '18', 'NORMAL')};
    color: ${COLORS.CORN_FLOWER_BLUE}!important;
  }
`;

export const StyledFields = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  &&& {
    &&& .ant-input-number-input-wrap,
    .ant-input-number-input {
      height: 2.875rem;
      color: ${COLORS.SOFT_GREY};
      ${pickThemeFontStyles('15', '32', 'SEMI_NORMAL')};
    }
  }
`;
