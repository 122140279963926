import { PieConfig } from '@ant-design/plots';
import { COLORS } from '@/theme';

export const chartColors = [
  COLORS.CERULEAN,
  COLORS.COQUELICOT,
  COLORS.BRIGHT_ORANGE,
  COLORS.AZUL,
];

export const defaultConfig: Omit<PieConfig, 'data'> = {
  angleField: 'value',
  colorField: 'label',
  radius: 1,
  tooltip: {
    formatter: (datum) => {
      return { name: datum.label, value: `${datum.value.toFixed(2) ?? ''}%` };
    },
  },
  state: {
    active: {
      style: {
        lineWidth: 2,
        stroke: COLORS.BLUE_PRIMARY,
      },
    },
  },
  padding: 2,
  legend: false,
  interactions: [
    {
      type: 'element-active',
    },
  ],
  height: 220,
};

export const getStatisticsElement = (
  content: string,
  isMobile?: boolean,
): PieConfig['statistic'] => ({
  title: false,
  content: {
    style: {
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: isMobile ? '12px' : '16px',
      fontWeight: 600,
    },
    content,
  },
});
