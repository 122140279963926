import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import {
  ADMINS_LIST_SLICE_NAME,
  initialState,
  AdminsListState,
} from './models';
import { getAdminDetails, getAdminsList, updateAdmin } from './actionCreators';
import { showApiErrors } from '@/utils';

export const slice = createSlice({
  name: ADMINS_LIST_SLICE_NAME,
  initialState,
  reducers: {
    resetAdminsListState() {
      return { ...initialState };
    },
    resetAdminsList(state) {
      state.adminsList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminsList.fulfilled, (state: AdminsListState, action) => {
        state.adminsList = action.payload;
        state.isLoading = false;
      })
      .addCase(getAdminDetails.fulfilled, (state: AdminsListState, action) => {
        state.adminDetails = action.payload.data;
        state.isLoading = false;
      })

      .addMatcher(
        isPending(getAdminDetails, getAdminsList, updateAdmin),
        (state: AdminsListState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(getAdminDetails, getAdminsList, updateAdmin),
        (state: AdminsListState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export const { resetAdminsListState, resetAdminsList } = slice.actions;
export default slice.reducer;
