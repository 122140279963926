import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';
import { CampaignParticipantStatuses } from '@/models/campaigns.model';

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
`;

export const StyledFileIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    transform: scale(0.4);
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  :last-child {
    flex: 0.7;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    :nth-last-child(2) {
      align-items: center;
    }
  }
`;

export const StyledButton = styled(Button)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2rem;
    background: ${COLORS.SOLITUDE_GRAY};
    color: ${COLORS.SOLITUDE_DARK};
    ${pickThemeFontStyles('10', '16', 'SEMI_BOLD')};
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &&& {
      width: 85%;
    }
  }
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  gap: 0.5rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  :last-child {
    align-items: center;
    flex: 0.7;
  }

  :nth-last-child(2) {
    align-items: center;
  }
`;

export const CardWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.5rem;
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('12', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledLink = styled.a<{ $isActive?: boolean }>`
  pointer-events: ${({ $isActive }) => ($isActive ? 'all' : 'none')};
`;

export const StyledLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  gap: 0.5rem;
  @media screen and ${DEVICES.LAPTOP_S} {
    justify-content: flex-start;
  }
  svg {
    transform: scale(0.6);
  }
  img {
    transform: scale(0.7);
  }
`;

export const StyledBadge = styled.div<{ $status?: string | null }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  color: ${COLORS.CORN_FLOWER_BLUE};
  ${pickThemeFontStyles('12', '16', 'BOLD')};
  ${({ $status }) => {
    switch ($status) {
      case CampaignParticipantStatuses.ACCEPTED:
        return `background: ${COLORS.GREEN_BUBBLES};
        color: ${COLORS.LIGHT_GREEN};`;
      case CampaignParticipantStatuses.PENDING:
      case CampaignParticipantStatuses.IN_VERIFICATION:
      case CampaignParticipantStatuses.IN_REVIEW:
      case CampaignParticipantStatuses.WAITING_FOR_PROJECT_SIGN:
        return `background: ${COLORS.CHABLIS_PEACH};
        color: ${COLORS.ATOMIC_TANGERINE};`;
      default:
        return `background: ${COLORS.MISTY_ROSE};
        color: ${COLORS.PICH_FROLY};`;
    }
  }}
`;

export const StyledA = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.SOLITUDE_DARK};
  width: 100%;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 85%;
  }
`;
