import React from 'react';
import { CheckboxChangeEvent, CheckboxGroupProps } from 'antd/es/checkbox';
import { StyledCheckbox } from './CheckboxStyles';

export type ICheckboxChangeEvent = CheckboxChangeEvent;
export interface ICheckboxProps extends CheckboxGroupProps {
  placeholder?: string;
}

const CheckboxGroup = ({
  placeholder,
  children,
  ...props
}: ICheckboxProps): JSX.Element => (
  <StyledCheckbox.Group {...props}>
    {placeholder ?? children}
  </StyledCheckbox.Group>
);

export default CheckboxGroup;
