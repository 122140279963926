// Globals
import React from 'react';

// Components
import { StyledLink } from './SocialChannelsStyles';

// Helpers
import { socialChannelsConfig } from '../../constants';

// Models
import { ISocialChannelKOL } from '@/models/kolsList.model';

interface ISocialChannels {
  channel: ISocialChannelKOL;
}

const SocialChannels = ({ channel }: ISocialChannels) => {
  return (
    <StyledLink key={channel?.name} to={channel.link ?? ''}>
      {socialChannelsConfig[channel.name ?? '']?.styledHoverIcon}
    </StyledLink>
  );
};

export default SocialChannels;
