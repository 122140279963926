import { useTranslation } from 'react-i18next';
import {
  useGetNumbersOnlyRule,
  useGetRequiredRule,
  useLengthValidationRule,
} from '@/hooks';
import { IFundingRoundFields } from '../types';
import { TextLength } from '@/constants';

const useGetFormValidationRules = () => {
  const { t } = useTranslation(['common']);

  const required = useGetRequiredRule({ t });
  const numbers = useGetNumbersOnlyRule({ t });
  const textTitleLength = useLengthValidationRule({
    t,
    max: TextLength.short,
  });
  const descriptionFieldLength = useLengthValidationRule({
    t,
    max: TextLength.large,
  });

  return {
    [IFundingRoundFields.TITLE]: [required, textTitleLength],
    [IFundingRoundFields.QUESTIONNAIRE_LINK]: [required, textTitleLength],
    [IFundingRoundFields.ELEVATOR_PITCH]: [required, descriptionFieldLength],
    [IFundingRoundFields.TGE_DATE]: [required],
    [IFundingRoundFields.START_DATE]: [required],
    [IFundingRoundFields.END_DATE]: [required],
    [IFundingRoundFields.WALLET_NETWORK]: [required],
    [IFundingRoundFields.WALLET_CURRENCY]: [required],
    [IFundingRoundFields.WALLET_ADDRESS]: [required],
    [IFundingRoundFields.TOKENOMICS]: [required],
    [IFundingRoundFields.PITCH_DECK]: [required],
    [IFundingRoundFields.BANNER_IMAGE]: [required],
    [IFundingRoundFields.TOKEN_IMAGE]: [required],
    [IFundingRoundFields.SLUG]: [required],
    [IFundingRoundFields.ROADMAP]: [required, descriptionFieldLength],
    [IFundingRoundFields.INVOLVED]: [required, descriptionFieldLength],
    [IFundingRoundFields.MARKETING_STRATEGY]: [
      required,
      descriptionFieldLength,
    ],
    [IFundingRoundFields.TOKEN_METRICS]: [required, descriptionFieldLength],
    [IFundingRoundFields.USPS]: [descriptionFieldLength],
    [IFundingRoundFields.TOKEN_NAME]: [required, textTitleLength],
    [IFundingRoundFields.PHASE]: [required],
    [IFundingRoundFields.TOKEN_SUPPLY]: [required, numbers],
    [IFundingRoundFields.TOTAL_TOKENS_AMOUNT_FOR_SELECTED_PHASE]: [
      required,
      numbers,
    ],
    [IFundingRoundFields.HARD_CAP]: [required, numbers],
    [IFundingRoundFields.FDV]: [numbers],
    [IFundingRoundFields.INITIAL_MARKETING_CAP]: [numbers],
    [IFundingRoundFields.TOKEN_AMOUNT_TO_BE_MANAGED]: [required, numbers],
    [IFundingRoundFields.PRICE_PER_TOKEN]: [required, numbers],
    [IFundingRoundFields.TOKEN_ALLOCATION_PERCENT]: [required, numbers],
    [IFundingRoundFields.TGE_UNLOCK]: [required, textTitleLength],
    [IFundingRoundFields.VESTING_PERIOD]: [required, textTitleLength],
    [IFundingRoundFields.TOKEN_SALE_SUMMARY]: [required, textTitleLength],
  };
};

export default useGetFormValidationRules;
