import { AdminActionsEnum } from '@/models/adminsList.model';
import { ROUTES_PATHS } from '../../../router';

export const RoutePermissions: Partial<
  Record<ROUTES_PATHS, AdminActionsEnum | AdminActionsEnum[]>
> = {
  [ROUTES_PATHS.ADMINS_LIST]: [
    AdminActionsEnum.FULL_ADMIN,
    AdminActionsEnum.READ_ADMINS,
  ],
  [ROUTES_PATHS.ADMIN_DETAILS]: AdminActionsEnum.FULL_ADMIN,
  [ROUTES_PATHS.KOL_LIST]: AdminActionsEnum.READ_KOL,
  [ROUTES_PATHS.KOL_DETAILS]: AdminActionsEnum.READ_KOL,
  [ROUTES_PATHS.PROJECT_DETAILS]: AdminActionsEnum.READ_PROJECT,
  [ROUTES_PATHS.PROJECTS_LIST]: AdminActionsEnum.READ_PROJECT,
  [ROUTES_PATHS.SETTINGS]: AdminActionsEnum.SETTINGS,
  [ROUTES_PATHS.CAMPAIGNS]: AdminActionsEnum.READ_CAMPAIGN,
  [ROUTES_PATHS.FUNDING_ROUNDS]: AdminActionsEnum.READ_FUNDING_ROUND,
  [ROUTES_PATHS.FUNDING_ROUND_DETAILS]: AdminActionsEnum.READ_FUNDING_ROUND,
  [ROUTES_PATHS.PAYOUT]: AdminActionsEnum.KOL_PAYOUT,
  [ROUTES_PATHS.CAMPAIGN_DETAILS]: AdminActionsEnum.READ_CAMPAIGN,
  [ROUTES_PATHS.HUNTERS_LIST]: AdminActionsEnum.READ_PARTNER,
  [ROUTES_PATHS.HUNTERS_DETAILS]: AdminActionsEnum.READ_PARTNER,
  [ROUTES_PATHS.DOCUMENTS]: [
    AdminActionsEnum.FULL_ADMIN,
    AdminActionsEnum.DOCUMENTS,
  ],
  [ROUTES_PATHS.AFFILIATE_SYSTEM]: AdminActionsEnum.READ_AFFILIATE,
  [ROUTES_PATHS.REQUESTS]: [
    AdminActionsEnum.READ_REFUND_REQUEST,
    AdminActionsEnum.READ_PROFILE_CHANGE_REQUEST,
  ],
  [ROUTES_PATHS.REQUESTS_DETAILS]: [
    AdminActionsEnum.READ_REFUND_REQUEST,
    AdminActionsEnum.READ_PROFILE_CHANGE_REQUEST,
  ],
};
