import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Select } from '@/ui';
import { FormItem } from '@/components';

export const StyledDetailsWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  flex-direction: column;
  flex: 3;
  gap: 1.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledInfo = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

export const StyledFormItem = styled(FormItem)<{ $hidden?: boolean }>`
  margin-bottom: 0.5rem;
  flex-direction: column;
  display: ${({ $hidden }) => ($hidden ? 'none' : 'flex')};

  &&& .ant-form-item-row {
    align-items: flex-start;
    flex-direction: column;

    .ant-form-item-control {
      width: 100%;
    }
  }

  label {
    ${pickThemeFontStyles('14', '18', 'NORMAL')};
    color: ${COLORS.CORN_FLOWER_BLUE}!important;
  }
`;

export const StyledSelect = styled(Select)`
  &.ant-select {
    &-single {
      & .ant-select-selector {
        background: ${COLORS.LIGHTEST_GRAY};
        height: 2.875rem;
        border: 0;
        & .ant-select-selection-placeholder {
          ${pickThemeFontStyles('14', '14', 'SEMI_NORMAL')};
          margin: auto;
        }
        & .ant-select-selection-item {
          width: 1rem;
          ${pickThemeFontStyles('14', '16', 'SEMI_NORMAL')};
          margin: auto;
        }
        & .ant-select-selection-search {
          input {
            height: 100%;
          }
        }
      }
    }
  }
`;
