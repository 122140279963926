// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { useSearchParams } from 'react-router-dom';
import {
  StyledTableTabs,
  StyledTittleWrapper,
} from './SettingsContainerStyles';
import { StyledPageWrapper, Title } from '@/ui';
import { EditButton } from '@/components';
import AverageSocialDataContainer from '../AverageSocialDataContainer/AverageSocialDataContainer';
import AutomaticApprovalContainer from '../AutomaticApprovalContainer/AutomaticApprovalContainer';
import TopBarsSettingsContainer from '../TopBarsSettingsContainer/TopBarsSettingsContainer';
import GeneralSettingsContainer from '../GeneralSettingsContainer/GeneralSettingsContainer';
import BucketContainer from '../BucketContainer/BucketContainer';

// Modules
import { LogContainer } from '@/modules/Logs';

// Hooks | Helpers
import { useBoolean, useTabs } from '@/hooks';
import { SettingsTabKeys, settingsTabsConfig } from '../../constants';

const SettingsContainer = () => {
  const { t } = useTranslation(['settings']);
  const { value: isEditActive, toggle: toggleIsEditState } = useBoolean();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const { activeTab, onChange } = useTabs(tab ?? SettingsTabKeys.SETTINGS);

  const getSettingsContent = () => {
    switch (activeTab) {
      case SettingsTabKeys.SETTINGS:
        return (
          <GeneralSettingsContainer
            isEditActive={isEditActive}
            toggleIsEditState={toggleIsEditState}
          />
        );
      case SettingsTabKeys.AVERAGE_SOCIAL_DATA:
        return (
          <AverageSocialDataContainer
            isEditActive={isEditActive}
            toggleIsEditState={toggleIsEditState}
          />
        );
      case SettingsTabKeys.AUTOMATIC_APPROVAL:
        return (
          <AutomaticApprovalContainer
            isEditActive={isEditActive}
            toggleIsEditState={toggleIsEditState}
          />
        );
      case SettingsTabKeys.BUCKET:
        return (
          <BucketContainer
            isEditActive={isEditActive}
            toggleIsEditState={toggleIsEditState}
          />
        );
      case SettingsTabKeys.TOP_BARS:
        return (
          <TopBarsSettingsContainer
            isEditActive={isEditActive}
            toggleIsEditState={toggleIsEditState}
          />
        );
    }
  };

  return (
    <StyledPageWrapper>
      <StyledTittleWrapper>
        <Title $type="h3">{t('settings_title')}</Title>
        <EditButton
          isEditActive={isEditActive}
          setIsEditActive={toggleIsEditState}
          position={'1rem'}
        />
      </StyledTittleWrapper>
      <StyledTableTabs
        currentTab={activeTab}
        config={settingsTabsConfig}
        onTabChange={onChange}
      />
      {getSettingsContent()}
      <LogContainer />
    </StyledPageWrapper>
  );
};

export default SettingsContainer;
