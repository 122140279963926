import styled from 'styled-components';
import { BlockLayout, Title } from '@/ui';
import { COLORS, pickThemeFontStyles } from '@/theme';

export const CampaignCardWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0rem;
  width: 100%;
`;

export const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0.5rem;
`;

export const CampaignInfoWrapper = styled(BlockLayout)`
  &&& {
    box-shadow: none;
  }
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: ${COLORS.WHITE_SMOKER};
`;

export const StyledSubTitle = styled(Title)`
  ${pickThemeFontStyles('14', '16', 'BOLD')};
  align-self: flex-start;
`;

export const StyledText = styled.div<{ $wide?: boolean }>`
  ${({ $wide }) =>
    $wide
      ? pickThemeFontStyles('14', '20', 'SEMI_SMALL')
      : pickThemeFontStyles('14', '20', 'SMALL')};
  align-self: flex-start;
  font-style: italic;
`;

export const StyledSliderText = styled.div`
  ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  ${pickThemeFontStyles('14', '20', 'NORMAL')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledRow = styled.div<{ $isCommunity?: boolean }>`
  display: flex;
  padding: ${({ $isCommunity }) => ($isCommunity ? '0rem' : '0 1rem')};
  ${pickThemeFontStyles('14', '20', 'NORMAL')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  justify-content: ${({ $isCommunity }) =>
    $isCommunity ? 'flex-start' : 'space-between'};
  gap: ${({ $isCommunity }) => ($isCommunity ? '0.5rem' : '0rem')};

  &:last-child {
    margin-bottom: 1rem;
  }
`;

export const DescriptionWrap = styled.div`
  &&& {
    ${pickThemeFontStyles('14', '20', 'NORMAL')};
    color: ${COLORS.CORN_FLOWER_BLUE};
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }
`;

export const StyledFooterBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;
  width: 100%;
  padding: 0 1rem;
  ${pickThemeFontStyles('10', '20', 'NORMAL')};
`;
