import { useTranslation } from 'react-i18next';
import { useGetRequiredRule, useLengthValidationRule } from '@/hooks';
import { CreateTemplateFieldsNames } from '../types';

const useGetFormValidationRules = () => {
  const { t } = useTranslation(['common']);

  const required = useGetRequiredRule({ t });
  const textFieldLength = useLengthValidationRule({ t, min: 3, max: 3000 });

  return {
    [CreateTemplateFieldsNames.TEMPLATE_TYPE]: [required],
    [CreateTemplateFieldsNames.HTML_CONTENT]: [required, textFieldLength],
  };
};

export default useGetFormValidationRules;
