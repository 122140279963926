import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { Text } from '@/ui';

export const StyledInfo = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
  gap: 0.625rem;
`;

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 20rem;
`;

export const StyledSubTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledText = styled(Text)`
  margin-bottom: 0.625rem;
  max-width: 20rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  :last-child {
    margin-bottom: 0;
  }
`;
