import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button, Image, Slider, Title } from '@/ui';
import { TextFileIcon } from '@/icons';

export const FundingDetailsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const StyledHeaderBlocks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.5rem;
  border-radius: 1rem;
  gap: 1.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledBanner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${COLORS.WHITE};
  margin-bottom: 0.5rem;
  border-radius: 1rem;
  padding: 0 1rem 1rem 1rem;
  gap: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 60%;
  }
`;

export const StyledImageWrapper = styled.div`
  border-radius: 1rem;
  width: 100%;
  background-color: ${COLORS.BLACK};
  margin-bottom: 1.5rem;
  position: relative;
`;

export const StyledImage = styled(Image)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
`;

export const StyledLogoImage = styled(Image)`
  aspect-ratio: 2;
  object-fit: cover;
  border-radius: 0.7rem;
  width: 6.25rem;
  height: 6.25rem;
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
  position: absolute;
  border-radius: 0.8rem;
  width: 6.5rem;
  bottom: -2rem;
  left: 1rem;
  justify-content: center;
  padding: 0.1rem;
  background-color: ${COLORS.WHITE};

  @media screen and ${DEVICES.LAPTOP_S} {
    left: 3rem;
  }
`;

export const SideBlocks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 40%;
  }
`;

export const StyledTitle = styled(Title)`
  ${pickThemeFontStyles('28', '38', 'BOLD')};
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('14', '26', 'NORMAL')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const DetailsWrapper = styled(BlockLayout)`
  display: flex;
  width: auto;
  background: ${COLORS.WHITE};
  margin-bottom: 0.5rem;
  border-radius: 1rem;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
  align-items: flex-start;
`;

export const SliderWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
`;

export const StyledSlider = styled(Slider)`
  width: 100%;
  position: absolute;
  height: 0.625rem;
  padding: 0rem;
  margin: 0rem;

  &&&.ant-slider .ant-slider-track {
    border-radius: 1rem;
    height: 0.625rem;
    background: linear-gradient(
      89.76deg,
      ${COLORS.BRIGHT_ORANGE} 2.3%,
      ${COLORS.HIGHLIGHTER_ORANGE} 97.85%
    );
  }

  &&&.ant-slider .ant-slider-rail {
    height: 0.625rem;
    border: 1px solid ${COLORS.SOLITUDE_GRAY};
    border-radius: 1rem;
    background-color: ${COLORS.WHITE};
  }

  &&&.ant-slider .ant-slider-handle::after {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    width: 24px;
  }

  &&&.ant-slider .ant-slider-handle {
    display: none;
  }
`;

export const StyledColumnBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${COLORS.SOLITUDE_GRAY};
  ${pickThemeFontStyles('14', '16', 'NORMAL')};
  color: ${COLORS.CORN_FLOWER_BLUE};

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
`;

export const StyledTermsText = styled.div`
  ${pickThemeFontStyles('18', '22', 'SEMI_NORMAL')};
`;

export const DetailsBlock = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-start;
  flex: 1;
  ${pickThemeFontStyles('10', '16', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
`;

export const StyledFileIcon = styled(TextFileIcon)`
  margin-right: 0.1rem;
  transform: scale(0.4);
`;

export const StyledA = styled.a`
  display: flex;
  min-width: 8rem;
  height: 2rem;
  align-items: center;
  border-radius: 0.375rem;
  padding: 0.3rem;
  background: ${COLORS.SOLITUDE};
  color: ${COLORS.SOLITUDE_DARK};
  ${pickThemeFontStyles('12', '16', 'BOLD')};
`;

export const StyledBlockTitle = styled.div`
  ${pickThemeFontStyles('18', '22', 'BOLD')};
`;

export const StyledSubTitle = styled.div`
  ${pickThemeFontStyles('14', '18', 'SEMI_NORMAL')};
`;

export const StyledValue = styled.div`
  ${pickThemeFontStyles('20', '28', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledValues = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  ${pickThemeFontStyles('14', '20', 'NORMAL')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledLabel = styled.div`
  &&& {
    ${pickThemeFontStyles('14', '20', 'SEMI_BOLD')};
    align-self: flex-start;
    letter-spacing: -0.005em;
    color: ${COLORS.SAMBUCUS};
  }
`;

export const StyledCapitalValue = styled.div<{ $wide?: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: right;
  ${({ $wide }) =>
    $wide
      ? pickThemeFontStyles('16', '24', 'BOLD')
      : pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
`;

export const StyledButton = styled(Button)<{ $isColored?: boolean }>`
  display: flex;
  gap: 0.5rem;
  &&& {
    max-width: 100%;
    height: 3rem;
    ${pickThemeFontStyles('16', '24', 'SEMI_BOLD')};
    align-items: center;
  }
`;
