import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { PARTNERS_LIST_SLICE_NAME } from './models';
import {
  IGetPartnersListDTO,
  IPartnersListResponse,
  IPartnerDetailsResponse,
  IGetPartnerInvestmentsDTO,
  IPartnerAffiliateResponse,
  IPartner,
  IUpdatePartnersDetailsDTO,
  IBlockPartnerUser,
} from '@/models/partnersList.model';
import { PartnersListAPI } from '@/api';
import { formatApiError } from '@/utils';
import {
  IGetInvitedUsersDTO,
  IGetInvitedUsersResponse,
} from '@/models/sharedProfile.model';

export const getPartnersList = createAsyncThunk<
  IPartnersListResponse,
  IGetPartnersListDTO,
  { serializedErrorType: IApiError }
>(
  `${PARTNERS_LIST_SLICE_NAME}/getPartnersList`,
  async (data: IGetPartnersListDTO) => {
    const response = await PartnersListAPI.getPartnersList({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getPartnerDetails = createAsyncThunk<
  IPartnerDetailsResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${PARTNERS_LIST_SLICE_NAME}/getPartnerDetails`,
  async (id: string) => {
    const response = await PartnersListAPI.getPartnerDetails(id);
    return response;
  },
  { serializeError: formatApiError },
);

export const getPartnerAffiliateData = createAsyncThunk<
  IPartnerAffiliateResponse,
  IGetPartnerInvestmentsDTO,
  { serializedErrorType: IApiError }
>(
  `${PARTNERS_LIST_SLICE_NAME}/getPartnerAffiliateData`,
  async (data) => {
    const response = await PartnersListAPI.getPartnerAffiliateData({
      ...data,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updatePartner = createAsyncThunk<
  IPartner,
  IUpdatePartnersDetailsDTO,
  { serializedErrorType: IApiError }
>(
  `${PARTNERS_LIST_SLICE_NAME}/updatePartnerDetails`,
  async (data) => {
    const response = await PartnersListAPI.updatePartnerDetails(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const blockPartner = createAsyncThunk<
  IPartner,
  IBlockPartnerUser,
  { serializedErrorType: IApiError }
>(
  `${PARTNERS_LIST_SLICE_NAME}/blockPartner`,
  async (data) => {
    const response = await PartnersListAPI.blockPartnerUser(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getPartnerInvitedUsers = createAsyncThunk<
  IGetInvitedUsersResponse,
  IGetInvitedUsersDTO,
  { serializedErrorType: IApiError }
>(
  `${PARTNERS_LIST_SLICE_NAME}/getPartnerInvitedUsers`,
  async (data) => {
    const response = await PartnersListAPI.getInvitedUsers({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);
