import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout } from '@/ui';

export const StyledWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
`;

export const CardWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  gap: 0.5rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  margin-bottom: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('16', '25', 'BOLD')};
    margin-bottom: 1rem;
  }
`;

export const StyledA = styled.a`
  display: flex;
  width: 100%;
  height: 1.5rem;
  border-radius: 1rem;
  background: ${COLORS.SOLITUDE_GRAY};
  color: ${COLORS.CORN_FLOWER_BLUE};
  ${pickThemeFontStyles('10', '10', 'SEMI_BOLD')};
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  :hover {
    color: ${COLORS.CORN_FLOWER_BLUE};
  }
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;
