import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { ReportsState, REPORTS_SLICE_NAME } from './models';

export const reportsSliceSelector = (state: RootState): ReportsState =>
  state[REPORTS_SLICE_NAME];

export const selectReportsIsLoading = createSelector(
  reportsSliceSelector,
  (state: ReportsState) => state.isLoading,
);

export const selectFundingFilterOptions = createSelector(
  reportsSliceSelector,
  (state: ReportsState) => state.fundingFiltersOptions,
);
