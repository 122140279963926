import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';

export const BlockTitle = styled.div`
  ${pickThemeFontStyles('18', '22', 'BOLD')};
  color: ${COLORS.LIQUORICE_BLACK};
`;

export const BlockWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 1.5rem;
`;

export const StyledButton = styled(Button)`
  &&& {
    width: 9.25rem;
    height: 2.25rem;
    border: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    cursor: pointer;
    color: ${COLORS.LIQUORICE_BLACK};
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
    border-radius: 6px;
    background: var(--input-default-background, #fff);
    box-shadow: 0px 0px 1px 0px rgba(50, 50, 71, 0.2),
      0px 1px 2px 0px rgba(50, 50, 71, 0.08);
  }
`;
