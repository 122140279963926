import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { CrossInCircleIcon } from '@/icons';
import { Image } from '@/ui';

export const StyledUploadImagePlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${pickThemeFontStyles('15', '15', 'SEMI_NORMAL')};
  color: ${COLORS.SOFT_GRAY};
  background: ${COLORS.WHITE};
  border: 1px dashed ${COLORS.SOFT_GRAY};
  border-radius: 0.25rem;
  width: 100%;
  height: 2.75rem;
  padding: 1rem;

  svg {
    margin-right: 0.75rem;
  }
`;

export const StyledImage = styled(Image)`
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
`;

export const ImageContainer = styled.div`
  width: 100%;
`;

export const StyledCrossInCircleIcon = styled(CrossInCircleIcon)`
  cursor: pointer;
  position: absolute;
  right: -12px;
  top: -12px;
  z-index: 10;
`;
