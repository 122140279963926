import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { Button, TextArea } from '@/ui';

export const StyledWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  justify-content: space-between;
`;

export const StyledActionButton = styled(Button)`
  &&& {
    max-width: 8rem;
    height: 3rem;
    ${pickThemeFontStyles('14', '18', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const StyledTextArea = styled(TextArea)<{ $valid?: boolean }>`
  width: 100%;
  margin-top: 0.5rem;
  &&&.ant-input {
    border: 1.5px solid
      ${({ $valid }) => ($valid ? COLORS.RED : COLORS.LIGHTEST_GRAY)};
    ${pickThemeFontStyles('16', '26', 'NORMAL')};
  }
`;

export const StyledTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;
