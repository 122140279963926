import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';
import { RoundArrowsIcon } from '@/icons';
import { FundingRoundParticipationTransactionStatus } from '@/models/fundingRound.model';

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;

  :first-child {
    align-items: flex-start;
  }
  :nth-last-child(2) {
    gap: 0.5rem;
  }
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;

  :first-child {
    align-items: flex-start;
  }
`;

export const CardWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.5rem;
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('12', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  max-width: 8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }

  :last-child {
    align-items: flex-start;
  }

  :nth-last-child(2) {
    align-items: flex-start;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    :last-child {
      align-items: center;
    }

    :nth-last-child(2) {
      align-items: center;
    }
  }
`;

export const StyledRoundArrowsIcon = styled(RoundArrowsIcon)`
  margin-left: 0.5rem;
`;

export const StyledBadge = styled.div<{ $status?: string | null }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  color: ${COLORS.CORN_FLOWER_BLUE};
  ${pickThemeFontStyles('12', '16', 'BOLD')};
  ${({ $status }) => {
    switch ($status) {
      case FundingRoundParticipationTransactionStatus.SUCCESS:
        return `background: ${COLORS.GREEN_BUBBLES};
        color: ${COLORS.LIGHT_GREEN};`;
      case FundingRoundParticipationTransactionStatus.PENDING:
        return `background: ${COLORS.CHABLIS_PEACH};
        color: ${COLORS.ATOMIC_TANGERINE};`;
      default:
        return `background: ${COLORS.MISTY_ROSE};
        color: ${COLORS.PICH_FROLY};`;
    }
  }}
`;

export const StyledFileIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    transform: scale(0.4);
  }
`;

export const StyledButton = styled(Button)<{ $isRequest?: boolean }>`
  display: flex;
  margin-top: 1rem;
  &&& {
    width: 100%;
    height: 1.5rem;
    background: ${COLORS.SOLITUDE_GRAY};
    ${pickThemeFontStyles('12', '16', 'SEMI_BOLD')};
    align-items: center;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-top: 0rem;
  }
`;

export const StyledTransferButton = styled(Button)`
  display: flex;
  margin-top: 1rem;
  &&& {
    width: 100%;
    height: 1.5rem;
    ${pickThemeFontStyles('12', '16', 'SEMI_BOLD')};
    align-items: center;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-top: 0rem;
  }
`;

export const StyledPopupWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  span {
    ${pickThemeFontStyles('14', '22', 'BOLD')};
  }
`;

export const StyledSubmitButton = styled(Button)`
  display: flex;
  margin-top: 1rem;
  &&& {
    width: 100%;
    ${pickThemeFontStyles('12', '16', 'SEMI_BOLD')};
    align-items: center;
  }
`;
