import styled, { css } from 'styled-components';
import { Slider, TextArea } from '@/ui';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';

const activeLockStyles = css`
  box-shadow: 0px 4px 20px -2px rgba(50, 50, 71, 0.08);
  filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.1));
  background: ${COLORS.WHITE};
`;

export const StyledIconWrap = styled.div<{ isActive: boolean }>`
  width: 30px;
  height: 26px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  border-radius: 0.5rem;
  background: ${COLORS.WHITE_SMOKE};
  ${({ isActive }) => isActive && activeLockStyles}
`;

export const StyledSliderTooltip = styled.div`
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledSlider = styled(Slider)`
  width: 100%;

  &&&.ant-slider .ant-slider-track {
    background-color: ${COLORS.PURPLE_BLUE};
  }

  &&&.ant-slider .ant-slider-handle::after {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    width: 24px;
    height: 24px;
  }

  &&&.ant-slider .ant-slider-handle {
    width: 24px;
    height: 24px;
    margin-top: -6px;
  }
`;

export const BlockWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  gap: 0.5rem;
  min-height: 4rem;
  align-items: center;

  @media screen and ${DEVICES.TABLET_S} {
    flex-direction: row;
  }
`;

export const StyledIconWrapper = styled.div`
  width: 100%;
  transform: scale(0.5);
`;

export const ChannelTitle = styled.div`
  ${pickThemeFontStyles('14', '24', 'BOLD')}
  color: ${COLORS.GRAY};
  margin-left: -0.25rem;
  align-items: center;
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  margin-bottom: 1rem;
  &&& .ant-input {
    background: ${COLORS.LIGHTEST_GRAY};
    ${pickThemeFontStyles('16', '26', 'NORMAL')};
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-bottom: 0;
  }
`;

export const DescriptionWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledSubTitle = styled.div`
  ${pickThemeFontStyles('12', '24', 'SEMI_NORMAL')};
`;

export const LockWrapper = styled.div`
  max-width: 4rem;
  margin-left: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.125rem;
  background: ${COLORS.WHITE_SMOKE};
  display: flex;
  justify-content: space-between;
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const StyledErrorText = styled.div`
  ${pickThemeFontStyles('12', '24', 'SEMI_NORMAL')};
  color: ${COLORS.RED};
  height: 0.5rem;
  margin-bottom: 0.5rem;

  @media screen and ${DEVICES.TABLET_S} {
    margin-bottom: 0rem;
  }
`;

export const StyledTag = styled.div`
  text-align: center;
  min-width: 5.5rem;
  border-radius: 6rem;
  background: ${COLORS.SOLITUDE_GRAY};
  margin-top: 0.25rem;
  color: ${COLORS.SOLITUDE_DARK};
  white-space: pre;
  width: 100%;
  padding: 0.125rem 0.5rem;
  display: flex;
  justify-content: center;
  ${pickThemeFontStyles('12', '16', 'BOLD')}
`;

export const TagsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  @media screen and ${DEVICES.TABLET_S} {
    justify-content: space-between;
  }
`;
