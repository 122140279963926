// Globals
import React from 'react';

// Components
import { StyledFrame } from './ChanelLinkStyles';
import { Link } from '@/ui';

// Modules
import { socialChannelsConfig } from '@/modules/KolsList/constants/socialChannels';

// Models
import { SocialChannels } from '@/models/socialChannel.model';

type TChannelButtonProps = {
  id: SocialChannels;
  link?: string;
  isDisabled?: boolean;
  isExternal?: boolean;
};

const ChanelLink = ({
  id,
  isDisabled = false,
  link,
  isExternal,
}: TChannelButtonProps) => {
  return (
    <>
      {isDisabled ? (
        <StyledFrame>{socialChannelsConfig[id]?.disabledIcon}</StyledFrame>
      ) : (
        <Link to={link ?? ''} target={isExternal ? '_blank' : '_self'}>
          <StyledFrame>{socialChannelsConfig[id]?.icon}</StyledFrame>
        </Link>
      )}
    </>
  );
};

export default ChanelLink;
