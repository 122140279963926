// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  DetailsWrapper,
  BlockWrapper,
  StyledLabel,
  OverviewCell,
  StyledValue,
  StyledHeaderWrap,
  StyledChannels,
  StyledChannelsWrapper,
} from './CampaignInformationStyles';
import CommunityInfo from './CommunityInfo';
import CampaignBudget from './CampaignBudget';

// Models
import { ICampaign } from '@/models/campaigns.model';

// Modules
import {
  socialChannelsConfig,
  socialChannelsList,
} from '@/modules/KolsList/constants/socialChannels';

// Helpers
import {
  getDateString,
  getRoundPercentDown,
  numberWithCommas,
  sortSocialChannels,
  subtractCommission,
} from '@/utils';
import { DATE_FORMATS } from '@/constants';

interface ICampaignInformation {
  data: ICampaign | null;
}

const CampaignInformation = ({ data }: ICampaignInformation) => {
  const { t } = useTranslation(['campaigns']);
  const budgetWithoutCommission = subtractCommission(
    data?.marketingBudget ?? 0,
    data?.commissionPercent,
  );
  const budgedPercentage = getRoundPercentDown(
    data?.usedBudget?.total ?? 0,
    budgetWithoutCommission ?? 0,
  );

  const mappedChannels = useMemo(() => {
    return sortSocialChannels(socialChannelsList).map((id) => {
      const isActive = !!data?.socialChannels?.some(
        (item) => item?.name === id,
      );

      return (
        isActive && (
          <StyledChannels key={id}>
            {socialChannelsConfig[id]?.['icon']}
          </StyledChannels>
        )
      );
    });
  }, [data]);

  return (
    <DetailsWrapper>
      <StyledHeaderWrap>
        <OverviewCell>
          <StyledLabel $bold>{t('campaign_campaign_budget')}</StyledLabel>
          <StyledValue>
            ${numberWithCommas(data?.marketingBudget ?? 0)}
          </StyledValue>
        </OverviewCell>
      </StyledHeaderWrap>

      <CampaignBudget budgedPercentage={budgedPercentage} />

      <BlockWrapper>
        <OverviewCell>
          <StyledLabel $bold>
            {t('campaign_details_marketing_channels')}
          </StyledLabel>
          <StyledChannelsWrapper>{mappedChannels}</StyledChannelsWrapper>
        </OverviewCell>
      </BlockWrapper>

      <BlockWrapper>
        <OverviewCell>
          <StyledLabel $bold>
            {t('campaign_details_marketing_period')}
          </StyledLabel>
          <StyledLabel>
            {`${getDateString(
              data?.marketingStartDate ?? '',
              DATE_FORMATS.DAY_MONTH_WITH_DOTS,
            )} - ${getDateString(
              data?.marketingEndDate ?? '',
              DATE_FORMATS.DATE_WITH_DOTS,
            )}`}
          </StyledLabel>
        </OverviewCell>
      </BlockWrapper>

      <CommunityInfo data={data} />
    </DetailsWrapper>
  );
};

export default CampaignInformation;
