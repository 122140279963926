import { ApiResponse, httpGet } from './httpClient';
import {
  DashboardEndpoints,
  IGeneralStatistics,
  IInvestmentsStatistics,
  IRequestsStatistics,
  IUsersStatisticsItem,
} from '@/models/dashboard.model';

class DashboardAPI {
  static getStatisticsGeneral(): ApiResponse<IGeneralStatistics> {
    return httpGet<IGeneralStatistics>(
      DashboardEndpoints.GET_STATISTICS_GENERAL,
    );
  }
  static getStatisticsRequests(): ApiResponse<IRequestsStatistics> {
    return httpGet<IRequestsStatistics>(
      DashboardEndpoints.GET_STATISTICS_REQUESTS,
    );
  }
  static getStatisticsInvestments(): ApiResponse<IInvestmentsStatistics> {
    return httpGet<IInvestmentsStatistics>(
      DashboardEndpoints.GET_STATISTICS_INVESTMENTS,
    );
  }
  static getStatisticsUsers(): ApiResponse<IUsersStatisticsItem[]> {
    return httpGet<IUsersStatisticsItem[]>(
      DashboardEndpoints.GET_STATISTICS_YEAR,
    );
  }
}

export default DashboardAPI;
