// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledResources, StyledA } from './FundingDetailsStyles';
import { TextFileIcon } from '@/icons';

// Models
import { IFundingRound } from '@/models/fundingRound.model';

interface IResources {
  fundingRound: IFundingRound;
}

const AdditionalResources = ({ fundingRound }: IResources) => {
  const { t } = useTranslation(['fundingRound']);

  const mappedResources = useMemo(() => {
    return fundingRound?.additionalResources?.map((item, idx) => {
      return (
        <StyledA key={idx} href={item?.link} target="_blank">
          <TextFileIcon />
          {item.fileName}
        </StyledA>
      );
    });
  }, [fundingRound]);

  return (
    <StyledResources>
      <StyledA href={fundingRound?.tokenomics} target="_blank">
        <TextFileIcon />
        {t('funding_overview_tokenomics')}
      </StyledA>
      <StyledA href={fundingRound?.pitchDeck} target="_blank">
        <TextFileIcon />
        {t('funding_overview_pitchdeck')}
      </StyledA>
      {fundingRound?.additionalResources && mappedResources}
    </StyledResources>
  );
};

export default AdditionalResources;
