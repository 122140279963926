import styled from 'styled-components';
import {
  COLORS,
  DEVICES,
  pickThemeFontSize,
  pickThemeFontStyles,
} from '@/theme';
import { BlockLayout, Button, Text, TextArea, Title } from '@/ui';

export const StyledPageWrapper = styled.div`
  background: ${COLORS.LIGHTEST_GRAY};
  width: 100%;
  background: ${COLORS.WHITE_SMOKE};
`;

export const Wrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-top: 1.5rem;
  align-items: flex-start;
`;
export const StyledHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const BlockWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 60%;
    align-items: center;
    flex-direction: row;
  }
`;

export const StyledLinksWrap = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 60%;
  }
`;

export const StyledText = styled(Text)`
  flex: 1;
`;

export const StyledTitle = styled(Title)`
  ${pickThemeFontStyles('24', '24', 'BOLD')};
`;

export const StyledHint = styled.div`
  ${pickThemeFontStyles('14', '14', 'SEMI_BOLD')};
  display: flex;
  align-items: center;
  width: 60%;

  svg {
    margin-right: 0.5rem;
  }
`;

export const StyledInputWrapper = styled.div`
  flex: 3;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  display: flex;
  &&& {
    max-width: 6rem;
    height: 2rem;
    ${pickThemeFontStyles('12', '16', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  &&&.ant-input {
    ${pickThemeFontStyles('16', '26', 'NORMAL')};
  }
`;

export const StyledDownloadButton = styled.a`
  & .anticon {
    margin: 3rem 0rem 0rem 3rem;
    font-size: ${pickThemeFontSize('40')};
    transform: scale(3);
  }
`;

export const StyledDocumentWrapper = styled.div`
  position: relative;
  width: 5rem;
  height: 4rem;
`;
