import { IApiError } from '@/models/apiError.model';
import {
  ICampaign,
  IMarketingChannel,
  IParticipationItems,
  ICampaignKols,
  IGetCampaignsListDTO,
} from '@/models/campaigns.model';

export const CAMPAIGNS_SLICE_NAME = 'campaigns';

export interface CampaignsState {
  campaignsListData: {
    items: ICampaign[] | null;
    hasMore: boolean;
  };
  campaignKols: ICampaignKols[] | null;
  participationsData: {
    items: IParticipationItems[] | null;
    hasMore: boolean;
  };
  campaignDetails: ICampaign | null;
  campaignFilters: IGetCampaignsListDTO;
  socialChannels: IMarketingChannel[];
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: CampaignsState = {
  campaignsListData: {
    items: [],
    hasMore: true,
  },
  participationsData: {
    items: [],
    hasMore: true,
  },
  campaignDetails: null,
  campaignFilters: {
    search: undefined,
    isInternal: undefined,
  },
  campaignKols: null,
  socialChannels: [],
  isLoading: false,
  error: null,
};
