import { ISocialChannelKOL } from '@/models/kolsList.model';
import { socialChannelsConfig } from '../constants';
import { getNormalizedSocialLink } from '@/modules/SharedProfile';
import { SocialChannels } from '@/models/socialChannel.model';

export const normalizedKOLChannels = (channels?: ISocialChannelKOL[]) => {
  const normalizedKOLChannels = channels?.map((item) => {
    return {
      link: getNormalizedSocialLink(item.link ?? '', item.name ?? ''),
      name: item.name,
      followers: item.followers,
      price: item.price,
      expectedPrice: Number(item.expectedPrice),
    };
  });
  return normalizedKOLChannels;
};

export const getPostMeasureUnit = (
  channel: SocialChannels,
  plural?: boolean,
) => {
  return `${socialChannelsConfig[channel]?.postTypeName}${plural ? 's' : ''}`;
};
