import styled from 'styled-components';
import { COLORS, DEVICES } from '@/theme';

export const StyledFormItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${COLORS.SOLITUDE_GRAY};

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;
