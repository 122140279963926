import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';

export const StyledWrapper = styled.div`
  display: flex;
  position: absolute;
  top: -1rem;
  right: 0rem;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2rem;
  background: ${COLORS.CHABLIS_PEACH};
  color: ${COLORS.ATOMIC_TANGERINE};

  @media screen and ${DEVICES.LAPTOP_S} {
    right: -1rem;
  }
`;

export const StyledCount = styled.div`
  ${pickThemeFontStyles('12', '14', 'SEMI_BOLD')};
`;
