// Globals
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Title, StyledPageWrapper } from '@/ui';
import { AdminsList } from '../../components';

// Helpers | Hooks
import { useAppDispatch, useAppState } from '@/hooks';
import { adminsListSliceSelector } from '../../feature/selectors';
import { getAdminsList } from '../../feature/actionCreators';
import { resetAdminsList } from '../../feature/slice';

const AdminsListContainer = () => {
  const { t } = useTranslation(['adminsList']);
  const dispatch = useAppDispatch();
  const { adminsList, isLoading } = useAppState(adminsListSliceSelector);

  useEffect(() => {
    dispatch(getAdminsList());

    return (): void => {
      dispatch(resetAdminsList());
    };
  }, []);

  return (
    <StyledPageWrapper>
      <Title $type="h3">{t('admins_list_title')}</Title>
      <AdminsList adminsList={adminsList} isLoading={isLoading} />
    </StyledPageWrapper>
  );
};

export default AdminsListContainer;
