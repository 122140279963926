// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledInfo,
  StyledSubTitle,
  StyledText,
  StyledDetails,
  StyledWrapper,
} from './ContactPersonDetailsBlockStyles';

// Models
import { IProject } from '@/models/projects.model';

// Utils
import { renderUsername } from '@/utils';

interface IContactPersonDetailsBlock {
  projectDetails?: IProject | null;
}

const ContactPersonDetailsBlock = ({
  projectDetails,
}: IContactPersonDetailsBlock) => {
  const { t } = useTranslation(['projectsList']);
  const { firstName, lastName, position } = projectDetails?.contactPerson ?? {};

  return (
    <StyledWrapper>
      <StyledInfo>
        <StyledDetails>
          <StyledSubTitle>
            {t('projects_general_info_name_title')}
          </StyledSubTitle>
          <StyledText>
            {renderUsername({
              firstName,
              lastName,
            })}
          </StyledText>
        </StyledDetails>
      </StyledInfo>
      <StyledInfo>
        <StyledDetails>
          <StyledSubTitle>{t('projects_general_info_position')}</StyledSubTitle>
          <StyledText>{position}</StyledText>
        </StyledDetails>
      </StyledInfo>
    </StyledWrapper>
  );
};

export default ContactPersonDetailsBlock;
