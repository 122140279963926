// Globals
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  FilterWrapper,
  StyledButton,
  FilterBlock,
  StyledText,
  StyledSelect,
  StyledContent,
  StyledChevronIcon,
} from './RequestsFilterStyles';
import { FilterIcon } from '@/icons';

// Models
import { RequestStatuses } from '@/models/requests.model';

// Hooks | Helpers
import { useAppDispatch, useBoolean } from '@/hooks';
import { getRequestTypeOptions } from '@/utils';
import { setRequestsFilters } from '../../feature/slice';

interface IRequestsFilter {
  status?: RequestStatuses;
}

const RequestsFilter = ({ status }: IRequestsFilter) => {
  const { t } = useTranslation(['requestsList']);
  const dispatch = useAppDispatch();
  const [selectValue, setSelectValue] = useState<
    string | number | string[] | number[]
  >([]);
  const { value: isActive, toggle: setIsActive, setFalse } = useBoolean();

  const handleReset = () => {
    setSelectValue([]);
  };

  useEffect(() => {
    setSelectValue([]);
  }, [status]);

  const handleActive = () => {
    dispatch(setRequestsFilters({ actionTypes: selectValue || null }));
    setFalse();
  };

  return (
    <FilterWrapper>
      <StyledButton type="primary" onClick={setIsActive}>
        <FilterIcon />
        <StyledChevronIcon />
      </StyledButton>
      {isActive && (
        <FilterBlock>
          <StyledContent>
            <StyledText>{t('requests_list_filters')}</StyledText>
          </StyledContent>
          <StyledContent>
            <StyledSelect
              mode="multiple"
              onChange={setSelectValue}
              value={selectValue}
              options={getRequestTypeOptions(t)}
              placeholder={t('requests_list_select_type')}
              showArrow={false}
            />
          </StyledContent>
          <StyledContent>
            <StyledButton type="default" onClick={handleReset}>
              {t('requests_list_reset')}
            </StyledButton>
            <StyledButton type="primary" onClick={handleActive}>
              {t('requests_list_actives')}
            </StyledButton>
          </StyledContent>
        </FilterBlock>
      )}
    </FilterWrapper>
  );
};

export default RequestsFilter;
