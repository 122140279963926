import { IApiError } from '@/models/apiError.model';
import { IProject } from '@/models/projects.model';
import {
  IGeneralStatistics,
  IRequestsStatistics,
  IUsersStatisticsItem,
} from '@/models/dashboard.model';

export const DASHBOARD_SLICE_NAME = 'dashboard';

export interface DashboardState {
  isLoading: boolean;
  error: IApiError | null;
  totalInvestmentsInMarketing: number | null;
  totalInvestmentsInMoney: number | null;
  generalStatistic: IGeneralStatistics | null;
  usersStatistics: IUsersStatisticsItem[] | null;
  requestsStatistic: IRequestsStatistics | null;
  projectsListData: {
    items: IProject[] | null;
    hasMore: boolean;
  };
}

export const initialState: DashboardState = {
  isLoading: false,
  error: null,
  generalStatistic: null,
  requestsStatistic: null,
  usersStatistics: null,
  totalInvestmentsInMarketing: null,
  totalInvestmentsInMoney: null,
  projectsListData: {
    items: null,
    hasMore: true,
  },
};

// ============= DTO =============

export interface IGetRecentInvestmentProjectsDTO {
  limit?: number;
  search?: string;
}

// ============== Response ==============

export interface IGetRecentInvestmentProjectsResponse {
  items: IProject[];
  hasMore: boolean;
}
