// Globals
import React from 'react';

// Components
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd';
import { FormItem, NestedFormItem } from '@/components';
import {
  StyledFormWrapper,
  StyledFieldsRow,
  StyledFieldsColumn,
} from './CompanyInfoStyles';

// Modules
import {
  DOCS_MIME_TYPES,
  FileUpload,
  MAX_IMAGE_SIZE,
  uploadFile,
} from '@/modules/Uploaders';
import { ProjectFieldsNames } from '@/modules/Projects/types';
import { useGetFormValidationRules } from '@/modules/Projects/hooks';

// Helpers
import { useAppDispatch, useBoolean } from '@/hooks';
import { getUserId } from '@/utils';

type TCompanyDocumentation = {
  fileLoading?: boolean;
  disabled?: boolean;
  projectId?: string;
  formInstance?: FormInstance;
};

const CompanyDocumentation = ({
  formInstance,
  disabled,
  projectId,
}: TCompanyDocumentation) => {
  const { t } = useTranslation(['projectsList']);
  const {
    value: fileLoading,
    setTrue: setLoading,
    setFalse: unsetLoading,
  } = useBoolean();
  const validationRules = useGetFormValidationRules();
  const dispatch = useAppDispatch();
  const handleChange = async (key: string, value: File | Blob | null) => {
    if (!value) {
      return formInstance?.setFieldValue(key, value);
    }
    setLoading();
    const cloudfrontUrl = await dispatch(
      uploadFile({
        type: 'file',
        value: value as File,
        targetId: getUserId(projectId ?? ''),
      }),
    ).unwrap();

    formInstance?.setFieldValue(key, cloudfrontUrl);
    unsetLoading();
  };

  return (
    <StyledFormWrapper>
      <StyledFieldsColumn>
        <StyledFieldsRow>
          <FormItem
            label={t(
              'projects_general_info_certificate_of_incorporation_label',
            )}
            name={ProjectFieldsNames.CERTIFICATE_OF_INCORPORATION}
            rules={
              validationRules[ProjectFieldsNames.CERTIFICATE_OF_INCORPORATION]
            }
          >
            <FileUpload
              fileMaxSize={MAX_IMAGE_SIZE}
              acceptance={DOCS_MIME_TYPES}
              isLoading={fileLoading}
              disabled={disabled}
              onChange={(v) =>
                handleChange(ProjectFieldsNames.CERTIFICATE_OF_INCORPORATION, v)
              }
            />
          </FormItem>
        </StyledFieldsRow>
        <StyledFieldsRow>
          <NestedFormItem
            label={t('projects_general_info_beneficial_ownership_label')}
            name={ProjectFieldsNames.BENEFICIAL_OWNERSHIP}
            rules={validationRules[ProjectFieldsNames.BENEFICIAL_OWNERSHIP]}
          >
            <FileUpload
              fileMaxSize={MAX_IMAGE_SIZE}
              acceptance={DOCS_MIME_TYPES}
              isLoading={fileLoading}
              disabled={disabled}
              onChange={(v) =>
                handleChange(ProjectFieldsNames.BENEFICIAL_OWNERSHIP, v)
              }
            />
          </NestedFormItem>
        </StyledFieldsRow>
      </StyledFieldsColumn>
    </StyledFormWrapper>
  );
};

export default CompanyDocumentation;
