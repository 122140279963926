import { DesktopOnly } from '@/modules/Adaptive';
import {
  GeneralInfoFieldsNames,
  IKOLsDetailsTabsNames,
} from '../helpers/types';
import {
  AffiliateIcon,
  DashboardIcon,
  FileIcon,
  MyAccountIcon,
  FlagIcon,
} from '@/icons';
import { AccountStatuses } from '@/constants';
import { AppStage } from '@/models/global.model';
import { KolStatuses } from '@/models/kolsList.model';

export enum CommissionPaymentStatusEnum {
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  UNPAID = 'unpaid',
  CANCELED = 'canceled',
}

export enum KOLDetailsTabKeys {
  GENERAL_INFO = 'general-info',
  INFLUENCER_DATA = 'influencer-data',
  PROJECT_PARTICIPATION = 'project-participation',
  AFFILIATE = 'affiliate',
  AFFILIATE_FUNDING = 'affiliate-funding',
  LEGAL_AGREEMENTS = 'legal-agreements',
  SEED_HUNTER_CREDITS = 'seed-hunter-credits',
}

export enum SocialChannelsErrors {
  MAINLINE_PROCESSING = 'Mainline calculates data',
  FACEBOOK_NOT_CONNECTED = 'You must connect your Facebook account first',
  MAINLINE_TWITTER_ERROR = 'MainlineTwitterApiProvider request to getAccountInformationByUsername ended up with error: "Request failed with status code 500"',
  FACEBOOK_ERROR = 'FacebookApiProvider request to getPageToken ended up with error: "Request failed with status code 400"',
  YOUTUBE_ERROR = 'YoutubeManagerService request to getStatisticsByChannelId ended up with error: BadRequestError: YoutubeDataApiProvider request to getVideosByFilters ended up with error: "Request contains an invalid argument."',
}

export const statusesConfigs = [
  {
    value: AccountStatuses.VERIFIED,
    label: 'Verified',
    statuses: [AccountStatuses.VERIFIED],
  },
  {
    value: AccountStatuses.BLOCKED,
    label: 'Blocked',
    statuses: [AccountStatuses.VERIFIED],
  },
];

export const projectParticipantsTabsConfig = [
  {
    label: 'general-info',
    key: KOLDetailsTabKeys.GENERAL_INFO,
    name: IKOLsDetailsTabsNames.GENERAL_INFO,
    stages: [AppStage.SEED_HUNTER, AppStage.KOL_PLATFORM],
    icon: (
      <DesktopOnly>
        <DashboardIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'influencer-data',
    key: KOLDetailsTabKeys.INFLUENCER_DATA,
    name: IKOLsDetailsTabsNames.INFLUENCER_DATA,
    stages: [AppStage.SEED_HUNTER, AppStage.KOL_PLATFORM],
    icon: (
      <DesktopOnly>
        <MyAccountIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'affiliate',
    key: KOLDetailsTabKeys.AFFILIATE,
    name: IKOLsDetailsTabsNames.AFFILIATE,
    stages: [AppStage.SEED_HUNTER, AppStage.KOL_PLATFORM],
    icon: (
      <DesktopOnly>
        <AffiliateIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'affiliate-funding',
    key: KOLDetailsTabKeys.AFFILIATE_FUNDING,
    name: IKOLsDetailsTabsNames.AFFILIATE_FUNDING,
    stages: [AppStage.SEED_HUNTER, AppStage.KOL_PLATFORM],
    icon: (
      <DesktopOnly>
        <AffiliateIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'seed-hunter-credits',
    key: KOLDetailsTabKeys.SEED_HUNTER_CREDITS,
    name: IKOLsDetailsTabsNames.SEED_HUNTER_CREDITS,
    stages: [AppStage.SEED_HUNTER, AppStage.KOL_PLATFORM],
    icon: (
      <DesktopOnly>
        <FlagIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'legal-agreements',
    key: KOLDetailsTabKeys.LEGAL_AGREEMENTS,
    name: IKOLsDetailsTabsNames.LEGAL_AGREEMENTS,
    stages: [AppStage.SEED_HUNTER, AppStage.KOL_PLATFORM],
    icon: (
      <DesktopOnly>
        <FileIcon />
      </DesktopOnly>
    ),
  },
];

export const sortByStatusOptions = [
  {
    label: 'Pending',
    value: KolStatuses.PENDING,
  },
  {
    label: 'Not Verified',
    value: KolStatuses.NOT_VERIFIED,
  },
  {
    label: 'In Verification',
    value: KolStatuses.IN_VERIFICATION,
  },
  {
    label: 'Verified',
    value: KolStatuses.VERIFIED,
  },
  {
    label: 'Blocked',
    value: KolStatuses.BLOCKED,
  },
];

export const fileFields = new Set([GeneralInfoFieldsNames.DOCUMENT_ID_PROOF]);
