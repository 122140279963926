// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledBlock,
  ProgressWrap,
  StyledTitle,
  StyledText,
  ChannelsSlidersWrap,
} from './CampaignResultsStyles';
import { Progress } from '@/ui';
import SocialChannelSlider from './SocialChannelSlider';

// Models
import { ICampaign } from '@/models/campaigns.model';
import { SocialChannels } from '@/models/socialChannel.model';

// Helpers
import {
  getPercent,
  numberWithCommas,
  sortBy,
  subtractCommission,
} from '@/utils';

interface ICampaignChannelsData {
  campaign?: ICampaign;
}

const CampaignChannelsData = ({ campaign }: ICampaignChannelsData) => {
  const { t } = useTranslation(['campaigns']);

  const campaignBudgetWithoutCommission = subtractCommission(
    campaign?.marketingBudget ?? 0,
    campaign?.commissionPercent,
  );

  const chanelSliders = useMemo(() => {
    return sortBy([...(campaign?.socialChannels ?? [])], 'name')?.map(
      (item) => {
        const usedBudget =
          campaign?.usedBudget?.socials?.[item.name as SocialChannels];
        return (
          <SocialChannelSlider
            item={item}
            key={item.name}
            usedBudget={usedBudget}
            campaign={campaign}
            campaignBudgetWithoutCommission={campaignBudgetWithoutCommission}
          />
        );
      },
    );
  }, [campaign]);

  return (
    <StyledBlock>
      <StyledTitle>{t('campaign_details_sign_up')}</StyledTitle>
      <ProgressWrap>
        <StyledText>
          {t('campaign_details_budget', {
            value: numberWithCommas(campaign?.usedBudget?.total ?? 0),
            secondValue: numberWithCommas(campaign?.marketingBudget ?? 0),
          })}
        </StyledText>
        <Progress
          percent={getPercent(
            campaign?.usedBudget?.total ?? 0,
            campaignBudgetWithoutCommission,
          )}
        />
      </ProgressWrap>
      <ChannelsSlidersWrap>{chanelSliders}</ChannelsSlidersWrap>
    </StyledBlock>
  );
};

export default CampaignChannelsData;
