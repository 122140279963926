import styled from 'styled-components';
import { FormItem } from '@/components';

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0.5rem;

  &&& .ant-select-show-arrow {
    margin-bottom: 0;
  }
`;
