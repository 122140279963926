import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { BlockLayout } from '@/ui';

export const CampaignListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  margin-top: 2rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  :last-child {
    align-items: center;
    flex: 0.4;
  }
`;
