import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';

export const DocumentsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  height: 2rem !important;
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  margin-top: 2rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;

  &:first-child {
    flex: 2;
    align-items: flex-start;
  }

  :nth-child(4) {
    align-items: center;
    flex: 0.4;
  }
`;
