import styled from 'styled-components';
import { Button, BlockLayout, Select, Text } from '@/ui';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { ChevronIcon } from '@/icons';

export const FilterWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const StyledButton = styled(Button)`
  display: flex;
  gap: 1rem;
  &&&.ant-btn {
    height: 2rem;
  }

  svg {
    path {
      fill: ${COLORS.WHITE};
    }
  }
`;

export const FilterBlock = styled(BlockLayout)`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 20rem;
  top: 2.5rem;
  right: 0rem;
  z-index: 99;
  border: solid 1px ${COLORS.WHISPER_GRAY};
  gap: 1rem;
`;

export const StyledText = styled(Text)`
  display: flex;
`;

export const StyledSelect = styled(Select)`
  svg {
    width: 1rem;
    height: 1rem;
    path {
      fill: ${COLORS.BLACK};
    }
  }
  &&&.ant-select {
    &&& .ant-select-selector {
      background: ${COLORS.LIGHTEST_GRAY};
      min-height: 1.5rem;
      border: 0;
      &&& .ant-select-selection-placeholder {
        ${pickThemeFontStyles('14', '14', 'SEMI_NORMAL')};
        margin: auto;
      }
      &&& .ant-select-selection-item {
        width: 1rem;
        ${pickThemeFontStyles('14', '16', 'SEMI_NORMAL')};
        margin: auto;
      }
    }
    &&& .ant-select-selection-overflow {
      padding: 0.3rem 4rem 0.3rem 0;
        .ant-select-selection-overflow-item {
          padding-bottom: 0.5rem;
          align-items: center;
          width: 100%;
        }
      }
    }
  }
`;

export const StyledChevronIcon = styled(ChevronIcon)`
  transform: rotate(90deg);
`;

export const StyledContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;
