import { IParticipationItems } from '@/models/campaigns.model';

export const getParticipatedChannelsList = (data: IParticipationItems[]) => {
  const channels: string[] = [];
  const uniqueNames = data.reduce((acc, item) => {
    item?.socialChannels?.forEach((channel: { name: string }) => {
      if (!acc.includes(channel.name)) {
        acc.push(channel.name);
      }
    });
    return acc;
  }, channels);

  return uniqueNames;
};
