import { useEffect } from 'react';
import { ICryptoCurrencyListItem } from '@/models/dataLists.model';
import { selectCryptoCurrencies } from '../feature/selectors';
import { fetchCryptoCurrencies } from '../feature/actionCreators';
import { DataListState } from '../feature/models';
import { useAppDispatch, useAppState } from '@/hooks';
import { selectIsAuthorized } from '@/modules/auth';

const useGetCryptoCurrencies = (): DataListState<ICryptoCurrencyListItem> => {
  const { list, isLoading, error } = useAppState(selectCryptoCurrencies);
  const isAuthorized = useAppState(selectIsAuthorized);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!list && !isLoading && !error && isAuthorized) {
      dispatch(fetchCryptoCurrencies());
    }
  }, [dispatch, list, isLoading, error, isAuthorized]);

  return { list: list ?? [], isLoading, error };
};

export default useGetCryptoCurrencies;
