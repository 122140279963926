import React from 'react';
import { NavLinkProps } from 'react-router-dom';
import { StyledNavLink } from './LinkStyles';

export type TLinkChildren = string | JSX.Element;

export interface ILinkProps extends NavLinkProps {
  children: TLinkChildren;
  active?: boolean;
}

const Link = ({ children, active, ...props }: ILinkProps): JSX.Element => (
  <StyledNavLink className={active ? 'activeLink' : ''} {...props}>
    {children}
  </StyledNavLink>
);

export default Link;
