// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledHeaderBlock,
  StyledHeaderBlocksWrap,
  StyledHeaderLabel,
  DateWrap,
} from './HeaderBlocksStyles';

interface IHeaderBlocks {
  marketingStartDate: string;
  marketingEndDate: string;
}

const HeaderBlocks = ({
  marketingStartDate,
  marketingEndDate,
}: IHeaderBlocks) => {
  const { t } = useTranslation(['campaigns']);

  return (
    <StyledHeaderBlocksWrap>
      <StyledHeaderBlock>
        <StyledHeaderLabel>{t('campaigns_marketing_start')}</StyledHeaderLabel>
        <DateWrap>
          <StyledHeaderLabel>{marketingStartDate}</StyledHeaderLabel>
        </DateWrap>
      </StyledHeaderBlock>
      <StyledHeaderBlock>
        <StyledHeaderLabel>{t('campaigns_marketing_end')}</StyledHeaderLabel>
        <DateWrap>
          <StyledHeaderLabel>{marketingEndDate}</StyledHeaderLabel>
        </DateWrap>
      </StyledHeaderBlock>
    </StyledHeaderBlocksWrap>
  );
};

export default HeaderBlocks;
