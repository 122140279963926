// Globals
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

// Components
import { Title, StyledPageWrapper } from '@/ui';
import { showErrorToast, Tabs } from '@/components';
import { RequestsList } from '../../components';

// Models
import { RequestStatuses } from '@/models/requests.model';

// Hooks | Helpers
import { useAppDispatch, useAppState, useTabs } from '@/hooks';
import { requestsTabsConfig } from '../../constants';
import { requestsListSliceSelector } from '../../feature/selectors';
import { getRequestsList } from '../../feature/actionCreators';
import { resetRequestListState } from '../../feature/slice';

const RequestsListContainer = () => {
  const { t } = useTranslation(['requestsList']);
  const { activeTab, onChange } = useTabs(RequestStatuses.NEW_REQUEST);
  const dispatch = useAppDispatch();

  const { isLoading, requestsListData, requestFilters } = useAppState(
    requestsListSliceSelector,
  );

  const getNextRequestsList = useCallback(
    debounce(async () => {
      if (isLoading || !requestsListData?.hasMore || !activeTab) return;
      const lastItem = requestsListData?.items?.at(-1);
      const requestsList = await dispatch(
        getRequestsList({
          startId: lastItem?.id,
          status: activeTab as RequestStatuses,
          ...(requestFilters ? requestFilters : {}),
        }),
      ).unwrap();

      requestsList?.items?.length === 0 &&
        showErrorToast({
          message: t('empty_data_massage_request', { ns: 'profile' }),
        });
    }, 1000),
    [
      isLoading,
      requestsListData,
      requestsListData?.hasMore,
      requestFilters,
      activeTab,
    ],
  );

  useEffect(() => {
    dispatch(resetRequestListState());
  }, [activeTab]);

  return (
    <StyledPageWrapper>
      <Title $type="h3">{t('requests_title')}</Title>
      <Tabs
        sections={requestsTabsConfig}
        activeTab={activeTab ?? ''}
        onChange={onChange}
      />
      <RequestsList
        requests={requestsListData}
        isLoading={isLoading}
        getNextRequests={getNextRequestsList}
        status={activeTab as RequestStatuses}
      />
    </StyledPageWrapper>
  );
};

export default RequestsListContainer;
