import styled, { css } from 'styled-components';
import { PhoneInput } from 'react-international-phone';
import { COLORS, pickThemeFontStyles } from '@/theme';

export const inputStyles = css`
  background: ${COLORS.LIGHTEST_GRAY};
  height: 2.875rem;
  border: 0;
  box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08),
    0px 0px 1px rgba(50, 50, 71, 0.2);
`;

export const StyledPhoneInput = styled(PhoneInput)`
  &&& {
    input,
    input:hover {
      border-radius: 0 0.5rem 0.5rem 0;
      ${pickThemeFontStyles('14', '32', 'SEMI_NORMAL')};
      width: 100%;
      ${inputStyles}
    }
    .react-international-phone-country-selector button {
      border-radius: 0.5rem 0 0 0.5rem;
      ${inputStyles}
    }
  }
`;
