import { RequestStatuses, RequestsTypesKeys } from '@/models/requests.model';
import { UserRoles } from '@/models/admin.model';
import { IRequestsTabsNames } from '../helpers';

export enum DateSortKeys {
  LATEST_TOP = 'latest_top',
  NEAREST_TOP = 'nearest_top',
}

export const requestsTabsConfig = [
  {
    label: 'new-request',
    key: RequestStatuses.NEW_REQUEST,
    name: IRequestsTabsNames.NEW_REQUEST,
  },
  {
    label: 'in-progress',
    key: RequestStatuses.IN_PROGRESS,
    name: IRequestsTabsNames.IN_PROGRESS,
  },
  {
    label: 'closed',
    key: RequestStatuses.CLOSED,
    name: IRequestsTabsNames.CLOSED,
  },
];

export const requestsTypesConfig = [
  {
    label: `requests_${RequestsTypesKeys.PROFILE_CHANGE}_label`,
    value: RequestsTypesKeys.PROFILE_CHANGE,
  },
  {
    label: `requests_${RequestsTypesKeys.PROFILE_VERIFICATION}_label`,
    value: RequestsTypesKeys.PROFILE_VERIFICATION,
  },
  {
    label: `requests_${RequestsTypesKeys.PROFILE_PRE_VERIFICATION}_label`,
    value: RequestsTypesKeys.PROFILE_PRE_VERIFICATION,
  },
  {
    label: `requests_${RequestsTypesKeys.TGE_DATE_CHANGE}_label`,
    value: RequestsTypesKeys.TGE_DATE_CHANGE,
  },
  {
    label: `requests_${RequestsTypesKeys.CONTACT_SUPPORT_TGE}_label`,
    value: RequestsTypesKeys.CONTACT_SUPPORT_TGE,
  },
  {
    label: `requests_${RequestsTypesKeys.CHECK_INVESTMENT}_label`,
    value: RequestsTypesKeys.CHECK_INVESTMENT,
  },
  {
    label: `requests_${RequestsTypesKeys.INVESTMENT_REJECTED}_label`,
    value: RequestsTypesKeys.INVESTMENT_REJECTED,
  },
  {
    label: `requests_${RequestsTypesKeys.SUBMIT_CONTENT_PLAN}_label`,
    value: RequestsTypesKeys.SUBMIT_CONTENT_PLAN,
  },
  {
    label: `requests_${RequestsTypesKeys.CAMPAIGN_VERIFICATION}_label`,
    value: RequestsTypesKeys.CAMPAIGN_VERIFICATION,
  },
  {
    label: `requests_${RequestsTypesKeys.CAMPAIGN_PARTICIPATION_REJECT}_label`,
    value: RequestsTypesKeys.CAMPAIGN_PARTICIPATION_REJECT,
  },
  {
    label: `requests_${RequestsTypesKeys.POSTS_CONTEST}_label`,
    value: RequestsTypesKeys.POSTS_CONTEST,
  },
  {
    label: `requests_${RequestsTypesKeys.REFUND}_label`,
    value: RequestsTypesKeys.REFUND,
  },
  {
    label: `requests_${RequestsTypesKeys.AFFILIATE_WITHDRAWAL}_label`,
    value: RequestsTypesKeys.AFFILIATE_WITHDRAWAL,
  },
  {
    label: `requests_${RequestsTypesKeys.FUNDING_ROUND_APPLICATION}_label`,
    value: RequestsTypesKeys.FUNDING_ROUND_APPLICATION,
  },
  {
    label: `requests_${RequestsTypesKeys.PAYOUT}_label`,
    value: RequestsTypesKeys.PAYOUT,
  },
  {
    label: `requests_${RequestsTypesKeys.FUNDING_ROUND_PAYOUT}_label`,
    value: RequestsTypesKeys.FUNDING_ROUND_PAYOUT,
  },
  {
    label: `requests_${RequestsTypesKeys.FUNDING_ROUND_REFUND}_label`,
    value: RequestsTypesKeys.FUNDING_ROUND_REFUND,
  },
  {
    label: `requests_${RequestsTypesKeys.FUNDING_ROUND_CREDITS_AND_ETHEREUM}_label`,
    value: RequestsTypesKeys.FUNDING_ROUND_CREDITS_AND_ETHEREUM,
  },
  {
    label: `requests_${RequestsTypesKeys.CAMPAIGN_ETHEREUM_SWAP}_label`,
    value: RequestsTypesKeys.CAMPAIGN_ETHEREUM_SWAP,
  },
];

export const sortByDateOptions = [
  {
    label: 'Sorting by nearest date',
    value: DateSortKeys.NEAREST_TOP,
  },
  {
    label: 'Sorting by latest date',
    value: DateSortKeys.LATEST_TOP,
  },
];

export const sortByUserTypeOptions = [
  {
    label: 'KOL',
    value: UserRoles.KOL,
  },
  {
    label: 'Project',
    value: UserRoles.PROJECT,
  },
  {
    label: 'Partner',
    value: UserRoles.PARTNER,
  },
  {
    label: 'Admin',
    value: UserRoles.ADMIN,
  },
];
