import { ApiResponse, httpPost } from './httpClient';
import {
  AuthEndpoints,
  ILogOutDTO,
  IRefreshTokenDTO,
  ISignInAuthorizedResponse,
  ISignInDTO,
  ISignInResponse,
  ISignInVerifyDTO,
} from '@/models/auth.model';

class AuthAPI {
  static signIn(data: ISignInDTO): ApiResponse<ISignInResponse> {
    return httpPost<ISignInDTO, ISignInResponse>(AuthEndpoints.SIGN_IN, data);
  }

  static signInVerify(
    data: ISignInVerifyDTO,
  ): ApiResponse<ISignInResponse & ISignInAuthorizedResponse> {
    return httpPost<
      ISignInVerifyDTO,
      ISignInResponse & ISignInAuthorizedResponse
    >(AuthEndpoints.SIGN_IN_VERIFY, data);
  }

  static refreshToken(
    data: IRefreshTokenDTO,
  ): ApiResponse<ISignInAuthorizedResponse> {
    return httpPost<IRefreshTokenDTO, ISignInAuthorizedResponse>(
      AuthEndpoints.REFRESH_TOKEN,
      data,
    );
  }

  static logOut(data: ILogOutDTO): ApiResponse<unknown> {
    return httpPost<ILogOutDTO, unknown>(AuthEndpoints.LOG_OUT, data);
  }
}

export default AuthAPI;
