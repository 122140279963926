import React, { ReactNode, useCallback } from 'react';
import type { CollapseProps } from 'antd';
import { ExpandIconPosition } from 'antd/es/collapse/Collapse';

// Components
import { StyledCollapse, StyledExpandIcon } from './CollapseStyles';

export interface ICollapse extends CollapseProps {
  children: ReactNode | ReactNode[];
  expandIconPosition?: ExpandIconPosition;
}

const Collapse = ({
  children,
  expandIconPosition = 'end',
  ...props
}: ICollapse) => {
  const renderExpandIcon: ICollapse['expandIcon'] = useCallback(
    ({ isActive }: { isActive?: boolean }) => {
      return <StyledExpandIcon $isActive={isActive} />;
    },
    [],
  );

  return (
    <StyledCollapse
      {...props}
      expandIconPosition={expandIconPosition}
      expandIcon={renderExpandIcon}
    >
      {children}
    </StyledCollapse>
  );
};

export default Collapse;
