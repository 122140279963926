// Global
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Component
import {
  StyledFileIconWrapper,
  StyledInfoWrapper,
  StyledButton,
  CardWrapper,
  StyledText,
  StyledTitle,
  StyledLink,
  StyledLinkWrapper,
  StyledBadge,
  StyledA,
} from './ParticipationStyles';
import { TextFileIcon } from '@/icons';

// Modules
import { MobileOnly } from '@/modules/Adaptive';
import { socialChannelsConfig, socialChannelsList } from '@/modules/KolsList';

// Models
import { IParticipationItems } from '@/models/campaigns.model';

// Helpers | Hooks
import { getDateString, numberWithCommas } from '@/utils';
import { DATE_FORMATS } from '@/constants';

interface IParticipantCard {
  participant: IParticipationItems;
}

const ParticipantCard = ({ participant }: IParticipantCard) => {
  const { t } = useTranslation(['campaigns']);

  const mappedSocialChannels = useMemo(() => {
    return socialChannelsList.map((channel) => {
      const foundedSocialMedia = participant?.socialChannels?.find((item) => {
        return item.name === channel;
      });
      const isActive = participant?.socialChannels?.some(
        (item) => item.name === channel,
      );

      return (
        <StyledLink
          $isActive={isActive}
          key={channel}
          href={foundedSocialMedia?.link}
          target="_blank"
        >
          {
            socialChannelsConfig[channel ?? '']?.[
              isActive ? 'icon' : 'disabledIcon'
            ]
          }
        </StyledLink>
      );
    });
  }, [participant]);

  return (
    <CardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>
            {t('campaign_participants_alias_community_title')}
          </StyledTitle>
        </MobileOnly>
        <StyledText>{participant?.alias}</StyledText>
        <StyledText>({participant?.mainCommunity?.value})</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaign_participants_date_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {getDateString(
            participant?.acceptedAt,
            DATE_FORMATS.DATE_WITH_TIME_UTC,
          )}
        </StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaign_participants_payout_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          ${numberWithCommas(participant?.totalParticipationAmount ?? 0)}
        </StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <StyledLinkWrapper>{mappedSocialChannels}</StyledLinkWrapper>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <StyledA
          href={participant?.participantDocuments[0].signedDocumentLink}
          target="_blank"
        >
          <StyledButton type="ghost">
            {t('campaign_participants_service_agreement_title')}
            <StyledFileIconWrapper>
              <TextFileIcon />
            </StyledFileIconWrapper>
          </StyledButton>
        </StyledA>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <StyledBadge $status={participant?.status}>
          {t(`investment_status_${participant?.status}`, { ns: 'common' })}
        </StyledBadge>
      </StyledInfoWrapper>
    </CardWrapper>
  );
};

export default ParticipantCard;
