import styled from 'styled-components';
import { DEVICES, pickThemeFontStyles } from '@/theme';

export const StyledChannelsDetails = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.2rem;
  margin-bottom: 1rem;
  ${pickThemeFontStyles('14', '24', 'SEMI_NORMAL')};

  & ul {
    margin: 0;
    padding: 0 0 0 1rem;
  }

  & h6 {
    margin: 0;
    ${pickThemeFontStyles('14', '24', 'BOLD')};
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 80%;
  }
`;
