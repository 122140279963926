import {
  UploadersEndpoints,
  IGetUploadUrlDTO,
  IGetUploadUrlResponse,
} from '@/models/uploaders.model';
import { ApiResponse, httpGet } from './httpClient';

class UploadersAPI {
  static getCampaignUploadUrl(
    data: IGetUploadUrlDTO,
  ): ApiResponse<IGetUploadUrlResponse> {
    const { fileType, targetId } = data ?? {};
    const requestData = Object.assign({
      type: fileType,
    });

    return httpGet<IGetUploadUrlResponse>(
      `${UploadersEndpoints.CAMPAIGNS}/${targetId}${UploadersEndpoints.PRESIGNED_URL}`,
      requestData,
    );
  }

  static getFundingRoundUploadUrl(
    data: IGetUploadUrlDTO,
  ): ApiResponse<IGetUploadUrlResponse> {
    const { fileType, targetId } = data ?? {};
    const requestData = Object.assign({ type: fileType });

    return httpGet<IGetUploadUrlResponse>(
      `${UploadersEndpoints.FUNDING_ROUND_UPLOAD_URL}/${targetId}${UploadersEndpoints.PRESIGNED_URL}`,
      requestData,
    );
  }

  static getUploadUrl(
    data: IGetUploadUrlDTO,
  ): ApiResponse<IGetUploadUrlResponse> {
    const { type, targetId } = data ?? {};

    return httpGet<IGetUploadUrlResponse>(
      `${UploadersEndpoints.PROJECTS}/${targetId}/presigned-url`,
      {
        type,
      },
    );
  }
}

export default UploadersAPI;
