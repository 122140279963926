import { IBaseItem } from '@/types';

interface IConfig<T> {
  value: T;
  label: string;
  statuses: T[];
}

export const getStatusesConfig = <T>(
  currentStatus: string,
  config: IConfig<T>[],
) => {
  const statusesConfig = config.reduce((acc, current) => {
    if (current.statuses.includes(currentStatus as T)) {
      acc.push({
        label: current.label,
        value: current.value as string,
      });
    }
    return acc;
  }, [] as IBaseItem[]);

  return statusesConfig;
};
