// Global
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledAffiliateWrapper,
  StyledInfoWrapper,
  StyledStatistics,
  StyledStatisticsWrapper,
  StyledTitle,
} from '../Styles/Styles';
import { Spinner, Text, StyledSpinnerWrap } from '@/ui';
import AffiliateHistoryCard from '../AffiliateCard/AffiliateHistoryCard';

// Models
import {
  IFundingRoundAffiliate,
  LegalAgreementRoles,
} from '@/models/sharedProfile.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Utils | Hooks
import { numberWithCommas } from '@/utils';
import { useInfiniteScroll } from '@/hooks';

interface IAffiliateFunding {
  affiliateData: {
    items: IFundingRoundAffiliate[] | null;
    hasMore: boolean;
  };
  isLoading: boolean;
  getNextAffiliateData: () => void;
  role: LegalAgreementRoles;
  overallBalance?: number;
}

const AffiliateFunding = ({
  affiliateData,
  isLoading,
  getNextAffiliateData,
  overallBalance,
  role,
}: IAffiliateFunding) => {
  const { t } = useTranslation(['kolsList']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedAffiliate = useMemo(() => {
    return affiliateData?.items?.map((affiliate) => {
      return (
        <AffiliateHistoryCard
          key={affiliate.sk}
          role={role}
          affiliate={affiliate}
        />
      );
    });
  }, [affiliateData]);

  useInfiniteScroll({
    callback: getNextAffiliateData,
    ref: loaderRef,
    isLoading,
    hasMore: affiliateData?.hasMore,
  });

  return (
    <StyledAffiliateWrapper>
      <StyledStatistics>
        <StyledStatisticsWrapper>
          <Text>{t('kols_affiliate_overall_balance')}</Text>
          <Text>{`$${numberWithCommas(overallBalance ?? 0)}`}</Text>
        </StyledStatisticsWrapper>
      </StyledStatistics>
      <DesktopOnly>
        <StyledInfoWrapper>
          <StyledTitle>{t('kols_affiliate_funding_round_title')}</StyledTitle>
          <StyledTitle>{t('kols_affiliate_invitee_alias_title')}</StyledTitle>
          <StyledTitle>{t('kols_affiliate_registered_as_title')}</StyledTitle>
          <StyledTitle>
            {`${t('kols_affiliate_budget')} / ${t(
              'kols_affiliate_participation_budget',
            )}`}
          </StyledTitle>
          <StyledTitle>{t('kols_affiliate_reward_title')}</StyledTitle>
        </StyledInfoWrapper>
      </DesktopOnly>
      {mappedAffiliate}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </StyledAffiliateWrapper>
  );
};

export default AffiliateFunding;
