// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  ReportOverviewWrapper,
  StyledTitles,
  StyledInfoTitles,
  BlockTitle,
} from './CampaignOverviewStyles';
import CampaignReportCard from '../CampaignReportCard/CampaignReportCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { ICampaign } from '@/models/campaigns.model';

interface ICampaignOverview {
  report: ICampaign;
}

const CampaignOverview = ({ report }: ICampaignOverview) => {
  const { t } = useTranslation(['reports']);

  return (
    <ReportOverviewWrapper>
      <BlockTitle>{t('report_details_campaign_overview')}</BlockTitle>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('reports_list_project_title')}</StyledTitles>
          <StyledTitles>{t('reports_list_campaign_title')}</StyledTitles>
          <StyledTitles>{t('reports_list_budget')}</StyledTitles>
          <StyledTitles>{t('reports_list_budget_booked')}</StyledTitles>
          <StyledTitles>{t('reports_list_total_payout')}</StyledTitles>
          <StyledTitles>{t('reports_list_deliveries')}</StyledTitles>
          <StyledTitles>{t('reports_list_network_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      <CampaignReportCard report={report} key={report.id} />
    </ReportOverviewWrapper>
  );
};

export default CampaignOverview;
