export enum PayoutTabKeys {
  CAMPAIGN = 'campaign',
  FUNDING_ROUND = 'funding_round',
  FUNDING_ROUND_REFUND = 'funding_round_refund',
}

export const payoutTabsConfig = [
  {
    label: 'payout_campaign',
    key: PayoutTabKeys.CAMPAIGN,
  },
  {
    label: 'payout_funding_round',
    key: PayoutTabKeys.FUNDING_ROUND,
  },
  {
    label: 'payout_funding_refund',
    key: PayoutTabKeys.FUNDING_ROUND_REFUND,
  },
];
