import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';

export const StyledCardWrapper = styled(BlockLayout)`
  width: 100%;
  padding: 1.5rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  div {
    width: 100%;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledText = styled.div`
  color: ${COLORS.GRAY};
  ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
`;

export const StyledCount = styled.div`
  color: ${COLORS.SAMBUCUS};
  ${pickThemeFontStyles('20', '20', 'SEMI_BOLD')};
  margin-right: 0.5rem;
`;

export const StyledButton = styled(Button)`
  &&& {
    width: 100%;
    font-weight: 600;
    padding: 1rem !important;
    background: ${COLORS.BLUE_PRIMARY};

    &&& svg {
      font-size: 1rem;
      margin-left: 0.75rem;
      path {
        fill: ${COLORS.WHITE};
      }
    }
  }
`;
