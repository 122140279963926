import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { DATA_EXPORTS_SLICE_NAME, DataExportsState } from './models';

export const dataExportsSliceSelector = (state: RootState): DataExportsState =>
  state[DATA_EXPORTS_SLICE_NAME];

export const selectDataExportsIsLoading = createSelector(
  dataExportsSliceSelector,
  (state: DataExportsState) => state.isLoading,
);
