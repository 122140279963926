// Global
import React from 'react';

// Components
import { Spinner } from '@/ui';
import {
  FundingRoundApplicationRequestDetails,
  StyledPageWrapper,
} from '../../components';

// Models
import { IRequestDetails } from '@/models/requests.model';
import { LogEntities } from '@/models/logs.model';

// Modules
import { LogContainer } from '@/modules/Logs';

interface IFundingRoundApplicationRequestContainer {
  request: IRequestDetails | null;
  isLoading: boolean;
}

const FundingRoundApplicationRequestContainer = ({
  request,
  isLoading,
}: IFundingRoundApplicationRequestContainer) => {
  return (
    <StyledPageWrapper>
      <FundingRoundApplicationRequestDetails
        request={request}
        isLoading={isLoading}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <LogContainer entity={LogEntities.REQUESTS} id={request?.id} />
      )}
    </StyledPageWrapper>
  );
};

export default FundingRoundApplicationRequestContainer;
