// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledWrapper, StyledActionButton } from './ActionButtonsStyles';
import { Modal, ModalSize, showErrorToast } from '@/components';
import { Title } from '@/ui';

// Modules
import { getKOLDetails } from '@/modules/KolsList';

// Models
import { RequestActionsStatuses } from '@/models/requests.model';
import { UserRoles } from '@/models/admin.model';

// Helpers | Hooks
import { useAppDispatch } from '@/hooks';
import {
  getRequestDetails,
  patchRequestStatus,
} from '../../feature/actionCreators';
import { getRoleFromId, getUserId } from '@/utils';

interface IAcceptModal {
  isOpened: boolean;
  handleClose: () => void;
  isApprove?: boolean;
  createdById?: string;
  id?: string;
  isLoading?: boolean;
}

const AcceptModal = ({
  isOpened,
  handleClose,
  createdById,
  isApprove,
  id,
  isLoading,
}: IAcceptModal) => {
  const { t } = useTranslation(['requestsList']);
  const dispatch = useAppDispatch();

  const handleSubmit = async () => {
    if (isLoading) return;

    // If its KOL checking for influenceDataReceivedAt
    if (getRoleFromId(createdById ?? '') === UserRoles.KOL) {
      const { data } = await dispatch(
        getKOLDetails(getUserId(createdById ?? '')),
      ).unwrap();
      if (!data?.influenceDataReceivedAt) {
        return showErrorToast({ message: t('requests_influencer_data_error') });
      }
    }

    await dispatch(
      patchRequestStatus({
        id: getUserId(id ?? ''),
        status: isApprove
          ? RequestActionsStatuses.APPROVED
          : RequestActionsStatuses.CLOSED,
      }),
    ).unwrap();
    dispatch(getRequestDetails(getUserId(id ?? '')));
    handleClose();
  };

  return (
    <Modal visible={isOpened} onCancel={handleClose} size={ModalSize.SMALL}>
      <Title $type="h2">
        {isApprove
          ? t('requests_modal_approve_title')
          : t('requests_modal_close_title')}
      </Title>
      <StyledWrapper>
        <StyledActionButton
          disabled={isLoading}
          type="primary"
          onClick={handleSubmit}
        >
          {isApprove
            ? t('requests_approve_status')
            : t('requests_close_status')}
        </StyledActionButton>
        <StyledActionButton type="default" onClick={handleClose}>
          {t('requests_cancel')}
        </StyledActionButton>
      </StyledWrapper>
    </Modal>
  );
};

export default AcceptModal;
