// Globals
import React, { useEffect, useMemo, useState } from 'react';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import { SelectProps } from 'antd';

// Components
import { StyledSelect } from './PlacesAutocompleteStyles';

const PlacesAutocomplete = ({ ...props }: SelectProps) => {
  return <AutocompleteInput {...props} />;
};

const AutocompleteInput = ({ onChange, value = '', ...props }: SelectProps) => {
  const [initialItem, setInitialItem] = useState<{
    value: string;
    label: string;
  }>({ label: '', value: '' });
  const {
    ready,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });
  const handleInput = (query: string) => {
    setValue(query);
  };

  const mappedOptions = useMemo(
    () => [
      ...data.map((place) => ({
        value: place.place_id,
        label: place.description,
      })),
      initialItem,
    ],
    [data, initialItem],
  );

  const getFormattedValue = async (placeId: string) => {
    if (!placeId) return;
    const details = await getDetails({ placeId });
    if (typeof details !== 'string') {
      setInitialItem({
        value: placeId,
        label: details.formatted_address || (details as string),
      });
    }
  };

  useEffect(() => {
    getFormattedValue(value);
  }, [value]);

  return (
    <>
      {ready && (
        <StyledSelect
          {...props}
          onChange={onChange}
          options={mappedOptions}
          onSearch={handleInput}
          value={value}
          showSearch
          onBlur={clearSuggestions}
        />
      )}
    </>
  );
};

export default PlacesAutocomplete;
