// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledTitle,
  StyledBlockWrapper,
  StyledFormItem,
  Input,
  SettingsWrapper,
} from '../Styles';

// Helpers | Hooks
import { SettingsFieldsNames } from '../../helpers';
import { useGetFormValidationRules } from '../../hooks';

interface IAffiliateSettings {
  isEditActive: boolean;
}

const AffiliateSettings = ({ isEditActive }: IAffiliateSettings) => {
  const { t } = useTranslation(['settings']);

  const validationRules = useGetFormValidationRules();

  return (
    <StyledBlockWrapper>
      <StyledTitle>{t('settings_affiliate_title')}</StyledTitle>
      <SettingsWrapper>
        <StyledFormItem
          name={SettingsFieldsNames.MARKETING_AFFILIATE_COMMISSION}
          rules={
            validationRules[SettingsFieldsNames.MARKETING_AFFILIATE_COMMISSION]
          }
          label={t('settings_marketing_affiliate_commission')}
        >
          <Input disabled={!isEditActive} type="number" />
        </StyledFormItem>
        <StyledFormItem
          name={SettingsFieldsNames.FUNDING_ROUND_AFFILIATE_COMMISSION}
          rules={
            validationRules[
              SettingsFieldsNames.FUNDING_ROUND_AFFILIATE_COMMISSION
            ]
          }
          label={t('settings_funding_round_affiliate_commission')}
        >
          <Input disabled={!isEditActive} type="number" />
        </StyledFormItem>
      </SettingsWrapper>
    </StyledBlockWrapper>
  );
};

export default AffiliateSettings;
