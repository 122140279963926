// Globals
import React, { useMemo } from 'react';

// Components
import {
  StyledMarketingBlock,
  StyledItem,
  StyledChannelTitles,
  StyledChannelsInfo,
} from './CampaignResultsStyles';
import SocialChannelsTitles from './SocialChannelsTitles';
import { SliderWrapper, StyledSlider, StyledRangePercent } from '../Styled';

// Modules
import { MobileOnly, DesktopOnly } from '@/modules/Adaptive';

// Models
import { IParticipationItems } from '@/models/campaigns.model';

// Helpers
import {
  getMarketingDeliveryWeek,
  getTotalMarketingDeliveredPosts,
} from '../../helpers';
import { getPercent } from '@/utils';

interface IMarketingDeliveryItem {
  item?: IParticipationItems;
  activeFilter: string;
  channelsList: string[];
}

const MarketingDeliveryItem = ({
  item,
  activeFilter,
  channelsList,
}: IMarketingDeliveryItem) => {
  const filteredWeek = getMarketingDeliveryWeek(
    activeFilter,
    item?.contentDistributionPlan,
  );

  const mappedSocialChannels = useMemo(() => {
    return channelsList.sort().map((channel) => {
      const foundedSocialMedia = item?.socialChannels?.find((item) => {
        return item.name === channel;
      });
      const totalDeliveredPosts = getTotalMarketingDeliveredPosts(
        item?.contentDistributionPlan,
        channel,
      );
      const currentChannel = filteredWeek?.channelsConfig[channel];

      const budgedPercentage = getPercent(
        totalDeliveredPosts ?? 0,
        foundedSocialMedia?.bookedPosts ?? 0,
      );

      const budgedWeeklyPercentage = getPercent(
        currentChannel?.deliveredPosts ?? 0,
        currentChannel?.posts ?? 0,
      );

      if (filteredWeek) {
        return (
          <StyledItem $isCentered $isColumn key={foundedSocialMedia?.name}>
            {currentChannel?.deliveredPosts ?? 0}/{currentChannel?.posts ?? 0}
            <SliderWrapper isSmaller>
              <StyledSlider value={budgedWeeklyPercentage} max={100} />
              <StyledRangePercent width={budgedWeeklyPercentage}>
                {budgedWeeklyPercentage + '%'}
              </StyledRangePercent>
            </SliderWrapper>
          </StyledItem>
        );
      }

      return (
        <StyledItem $isCentered $isColumn key={foundedSocialMedia?.name}>
          {totalDeliveredPosts}/{foundedSocialMedia?.bookedPosts ?? 0}
          <SliderWrapper isSmaller>
            <StyledSlider value={budgedPercentage} max={100} />
            <StyledRangePercent width={budgedPercentage}>
              {budgedPercentage + '%'}
            </StyledRangePercent>
          </SliderWrapper>
        </StyledItem>
      );
    });
  }, [item, filteredWeek, channelsList]);

  const mappedTitles = useMemo(() => {
    return channelsList.map((channel) => (
      <SocialChannelsTitles key={channel} channel={channel} />
    ));
  }, [channelsList]);

  return (
    <StyledMarketingBlock>
      <MobileOnly>
        <StyledChannelTitles>
          <StyledItem>{item?.firstName}</StyledItem>
          {mappedTitles}
        </StyledChannelTitles>
      </MobileOnly>
      <StyledChannelsInfo>
        <DesktopOnly>
          <StyledItem>{item?.firstName}</StyledItem>
        </DesktopOnly>
        {mappedSocialChannels}
      </StyledChannelsInfo>
    </StyledMarketingBlock>
  );
};

export default MarketingDeliveryItem;
