import React from 'react';
import { Line } from '@ant-design/plots';
import { defaultConfig } from './configs';
import { TChartDataItem } from '@/types';

interface ILineChart {
  data?: TChartDataItem[];
}

const LineChart = ({ data = [] }: ILineChart) => {
  return <Line {...defaultConfig} data={data} />;
};

export default LineChart;
