import styled from 'styled-components';
import { Steps } from 'antd';
import { COLORS, pickThemeFontStyles } from '@/theme';

export const StyledSteps = styled(Steps)`
  &&& .ant-steps-item-process .ant-steps-item-icon {
    background-color: ${COLORS.BRIGHT_ORANGE};
    border-color: ${COLORS.BRIGHT_ORANGE};

    span {
      color: ${COLORS.BLACK};
    }
  }
  &&& .ant-steps-item-finish .ant-steps-item-icon {
    background-color: ${COLORS.PALE_ORANGE};
    border-color: ${COLORS.PALE_ORANGE};

    svg {
      color: ${COLORS.BLACK};
    }
  }
  &&& .ant-steps-item-tail:: after {
    background-color: ${COLORS.GRAYISH_BLUE_LIGHT};
  }
  &&& .ant-steps-item-content {
    ${pickThemeFontStyles('14', '14', 'SEMI_BOLD')};
  }
`;
