// Globals
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

// Components
import { Title, StyledPageWrapper } from '@/ui';
import { showErrorToast, Tabs } from '@/components';
import { FundingReportsList, CampaignReportsList } from '../../components';

// Hooks | Helpers
import { useAppDispatch, useAppState, useTabs } from '@/hooks';
import { IReportsTabsNames, reportsTabsConfig } from '../../constants';
import { reportsSliceSelector } from '../../feature/selectors';
import {
  getCampaignReportOptions,
  getCampaignReportsList,
  getFundingReportsList,
  getFundingRoundReportOptions,
} from '../../feature/actionCreators';

const ReportsListContainer = () => {
  const { t } = useTranslation(['reports', 'profile']);
  const { activeTab, onChange } = useTabs(IReportsTabsNames.FUNDING_ROUND);
  const dispatch = useAppDispatch();
  const {
    isLoading,
    fundingReports,
    campaignReports,
    reportsFilters,
    campaignReportsFilters,
  } = useAppState(reportsSliceSelector);

  const getNextFundingReportsList = useCallback(
    debounce(async () => {
      if (isLoading || !fundingReports?.hasMore || !activeTab) return;
      const lastItem = fundingReports?.items?.at(-1);
      const requestsList = await dispatch(
        getFundingReportsList({
          startId: lastItem?.id,
          projectId: reportsFilters?.projectId,
          fundingRoundId: reportsFilters?.fundingRoundId,
        }),
      ).unwrap();

      requestsList?.items?.length === 0 &&
        showErrorToast({
          message: t('empty_data_massage_request', { ns: 'profile' }),
        });
    }, 1000),
    [
      isLoading,
      reportsFilters?.fundingRoundId,
      reportsFilters?.projectId,
      campaignReports,
    ],
  );

  const getNextCampaignReportsList = useCallback(
    debounce(async () => {
      if (isLoading || !campaignReports?.hasMore) return;
      const lastItem = campaignReports?.items?.at(-1);
      const requestsList = await dispatch(
        getCampaignReportsList({
          startId: lastItem?.id,
          projectId: campaignReportsFilters?.projectId,
          campaignId: campaignReportsFilters?.campaignId,
        }),
      ).unwrap();

      requestsList?.items?.length === 0 &&
        showErrorToast({
          message: t('empty_data_massage_request', { ns: 'profile' }),
        });
    }, 1000),
    [
      isLoading,
      campaignReports,
      campaignReportsFilters?.campaignId,
      campaignReportsFilters?.projectId,
    ],
  );

  useEffect(() => {
    getNextFundingReportsList();
  }, [reportsFilters?.fundingRoundId, reportsFilters?.projectId]);

  useEffect(() => {
    getNextCampaignReportsList();
  }, [campaignReportsFilters?.campaignId, campaignReportsFilters?.projectId]);

  useEffect(() => {
    dispatch(getFundingRoundReportOptions());
    dispatch(getCampaignReportOptions());
  }, []);

  return (
    <StyledPageWrapper>
      <Title $type="h3">{t('reports_title')}</Title>
      <Tabs
        sections={reportsTabsConfig}
        activeTab={activeTab ?? ''}
        onChange={onChange}
      />
      {activeTab === IReportsTabsNames.FUNDING_ROUND ? (
        <FundingReportsList
          reports={fundingReports.items}
          hasMore={fundingReports.hasMore}
          isLoading={isLoading}
          getNextReports={getNextFundingReportsList}
        />
      ) : (
        <CampaignReportsList
          reports={campaignReports.items}
          hasMore={campaignReports.hasMore}
          isLoading={isLoading}
          getNextReports={getNextCampaignReportsList}
        />
      )}
    </StyledPageWrapper>
  );
};

export default ReportsListContainer;
