import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledActionButton, StyledWrapper } from './ActionButtonsStyles';
import SendReplyModal from './SendReplyModal';
import AcceptModal from './AcceptModal';

// Models
import { RequestActionsStatuses } from '@/models/requests.model';

// Hooks | Utils
import { useAppDispatch, useModal } from '@/hooks';
import {
  getRequestDetails,
  patchRequestStatus,
} from '../../feature/actionCreators';
import { getUserId } from '@/utils';

interface IActionButtons {
  status?: string;
  id?: string;
}

const ContactSupportActionButtons = ({ status, id }: IActionButtons) => {
  const { t } = useTranslation(['requestsList']);
  const dispatch = useAppDispatch();

  const { isOpened, openModal, closeModal } = useModal();
  const {
    isOpened: isCloseModalOpen,
    openModal: openCloseModal,
    closeModal: closeCloseModal,
  } = useModal();

  const handleState = async () => {
    await dispatch(
      patchRequestStatus({
        id: getUserId(id ?? ''),
        status: RequestActionsStatuses.IN_PROGRESS,
      }),
    ).unwrap();
    dispatch(getRequestDetails(getUserId(id ?? '')));
  };

  switch (status) {
    case RequestActionsStatuses.NEW:
      return (
        <StyledActionButton type="primary" onClick={handleState}>
          {t('verification_request_take_in_progress')}
        </StyledActionButton>
      );
    case RequestActionsStatuses.IN_PROGRESS:
      return (
        <StyledWrapper>
          <StyledActionButton type="primary" onClick={openModal}>
            {t('requests_send_reply')}
          </StyledActionButton>
          <SendReplyModal
            isOpened={isOpened}
            handleClose={closeModal}
            id={id}
          />
        </StyledWrapper>
      );
    case RequestActionsStatuses.REPLY_SENT:
      return (
        <StyledWrapper>
          <StyledActionButton type="default" onClick={openCloseModal}>
            {t('requests_close_status')}
          </StyledActionButton>
          <AcceptModal
            isOpened={isCloseModalOpen}
            handleClose={closeCloseModal}
            isApprove
            id={id}
          />
        </StyledWrapper>
      );
    case RequestActionsStatuses.CLOSED:
    default:
      return <></>;
  }
};

export default ContactSupportActionButtons;
