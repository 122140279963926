import styled from 'styled-components';
import { BlockLayout, Text } from '@/ui';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';

export const StyledRequestWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3rem;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};

  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
    margin-bottom: 0.313rem;
    gap: 0rem;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

export const StyledText = styled(Text)`
  margin-right: 1rem;
`;

export const StyledDetails = styled(BlockLayout)`
  display: flex;
  width: 4rem;
  justify-content: center;
  height: 1.625rem;
  background: ${COLORS.SOLITUDE_GRAY};
  color: ${COLORS.SOLITUDE_DARK};
  ${pickThemeFontStyles('10', '10', 'SEMI_BOLD')};
  align-items: center;
  padding: 0rem;
`;
