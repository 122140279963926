// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { MassageWrap } from './EmptyDataMassageBlockStyles';

interface IEmptyDataMassageBlock {
  role: string;
  isLoading: boolean;
}

const EmptyDataMassageBlock = ({ role, isLoading }: IEmptyDataMassageBlock) => {
  const { t } = useTranslation(['profile']);

  if (isLoading) return null;

  return <MassageWrap>{t(`empty_data_massage_${role}`)}</MassageWrap>;
};

export default EmptyDataMassageBlock;
