// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  CardWrapper,
  StyledTitles,
  StyledColumnCardTitle,
  StyledText,
} from '../Styled/Styled';

// Models
import { ICreditHistory } from '@/models/sharedProfile.model';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Helpers
import { getDateString, numberWithCommas } from '@/utils';
import { DATE_FORMATS } from '@/constants';

interface ISeedHunterCreditsHistoryCard {
  data: ICreditHistory;
}

const SeedHunterCreditsHistoryCard = ({
  data,
}: ISeedHunterCreditsHistoryCard) => {
  const { t } = useTranslation(['profile']);
  const createdAt = getDateString(
    data?.created ?? '',
    DATE_FORMATS.DATE_WITH_DOTS,
  );

  return (
    <CardWrapper>
      <StyledTitles>
        <MobileOnly>
          <StyledColumnCardTitle>
            {t('credit_history_event_title')}
          </StyledColumnCardTitle>
        </MobileOnly>
        <StyledText>{data?.title}</StyledText>
      </StyledTitles>

      <StyledTitles>
        <MobileOnly>
          <StyledColumnCardTitle>
            {t('credit_history_date_title')}
          </StyledColumnCardTitle>
        </MobileOnly>
        <StyledText>{createdAt}</StyledText>
      </StyledTitles>

      <StyledTitles>
        <MobileOnly>
          <StyledColumnCardTitle>
            {t('credit_history_admin_email_title')}
          </StyledColumnCardTitle>
        </MobileOnly>
        <StyledText>{data?.adminEmail}</StyledText>
      </StyledTitles>

      <StyledTitles>
        <MobileOnly>
          <StyledColumnCardTitle>
            {t('credit_history_credit_amount_title')}
          </StyledColumnCardTitle>
        </MobileOnly>
        <StyledText>$ {numberWithCommas(data?.amount ?? 0)}</StyledText>
      </StyledTitles>
    </CardWrapper>
  );
};

export default SeedHunterCreditsHistoryCard;
