import styled, { css } from 'styled-components';
import { Input } from 'antd';
import { DEVICES, pickThemeFontSize, pickThemeFontStyles } from '@/theme';

export const inputStyles = css`
  &.ant-input {
    min-width: 100%;
    ${pickThemeFontStyles('14', '32', 'SEMI_NORMAL')};
  }

  &.ant-input-affix-wrapper {
    overflow: hidden;
    padding: 0;

    & .ant-input {
      padding: 0.313rem 0.688rem;
      ${pickThemeFontStyles('14', '32', 'SEMI_NORMAL')};
      width: 100%;
    }

    & .ant-input-suffix {
      font-size: ${pickThemeFontSize('20')};
      margin: 0;
      position: absolute;
      right: 1rem;
      bottom: 0.6rem;
    }

    & .ant-input-prefix {
      font-size: ${pickThemeFontSize('20')};
      margin: 0;
    }
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &.ant-input {
      ${pickThemeFontStyles('15', '46', 'SEMI_NORMAL')};
    }

    &.ant-input-affix-wrapper {
      & .ant-input-suffix {
        position: absolute;
        right: 1rem;
        bottom: 1.1rem;
      }
      & .ant-input {
        ${pickThemeFontStyles('15', '46', 'SEMI_NORMAL')};
      }
    }
  }
`;

export const StyledTextArea = styled(Input.TextArea)`
  ${inputStyles}
`;

export const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  ${pickThemeFontStyles('12', '14', 'NORMAL')};
  margin-bottom: 0.5rem;

  &&& button {
    margin-left: 0.25rem;
  }
`;

export const StyledTextAreaWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
