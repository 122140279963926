import {
  IFundingContractDTO,
  IFundingContractItem,
} from '@/models/fundingRound.model';
import {
  FundingContractFieldsNames,
  TFundingContractValues,
} from '@/modules/FundingRounds/types';

class FundingContractDTO implements IFundingContractDTO {
  additionalDefinitions?: string;
  excludedJurisdictions?: string;
  items?: IFundingContractItem[];

  constructor(values: TFundingContractValues) {
    this.additionalDefinitions =
      values[FundingContractFieldsNames.ADDITIONAL_DEFINITIONS];
    this.excludedJurisdictions =
      values[FundingContractFieldsNames.EXCLUDED_JURISDICTIONS];
    this.items = values[FundingContractFieldsNames.ITEMS]?.filter(
      (item) => item.description,
    );
  }
}

export default FundingContractDTO;
