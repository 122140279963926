import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, StyledPageWrapper } from '@/ui';

export const Wrapper = styled(StyledPageWrapper)`
  min-height: calc(100vh - 4.688rem);

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 100%;
  }
`;

export const StyledHeaderBlocksWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-wrap: nowrap;
    gap: 1.5rem;
  }
`;

export const DateWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const StyledHeaderLabel = styled.div`
  color: ${COLORS.LIQUORICE_BLACK};
  ${pickThemeFontStyles('14', '20', 'SEMI_BOLD')};
`;

export const StyledHeaderSubLabel = styled.div`
  color: ${COLORS.LIQUORICE_BLACK};
  ${pickThemeFontStyles('12', '20', 'NORMAL')};
`;

export const StyledHeaderBlock = styled(BlockLayout)`
  min-height: 5rem;
  margin-bottom: 1rem;
  justify-content: space-between;
  flex-direction: row;
`;
