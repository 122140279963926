import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';

export const StyledCompleteWrapper = styled(BlockLayout)`
  display: flex;
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const Text = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledButton = styled(Button)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 1.5rem;
    ${pickThemeFontStyles('10', '10', 'BOLD')};
    background: ${COLORS.SOLITUDE_GRAY};
    color: ${COLORS.DARK_BLUE};
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &&& {
      width: 8rem;
    }
  }
`;
