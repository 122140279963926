// Global
import React, { useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';

// Components
import { AffiliateFunding } from '../../components';

// Models
import { LegalAgreementRoles } from '@/models/sharedProfile.model';

// Modules
import {
  getFundingRoundAffiliateList,
  profileSliceSelector,
  resetAffiliateFindingList,
} from '@/modules/SharedProfile';

// Hooks
import { useAppDispatch, useAppState } from '@/hooks';

interface IAffiliateFundingContainer {
  role: LegalAgreementRoles;
  id: string;
  overallBalance?: number;
}

const AffiliateFundingContainer = ({
  role,
  id,
  overallBalance,
}: IAffiliateFundingContainer) => {
  const dispatch = useAppDispatch();
  const { isLoading, fundingRoundAffiliateList } =
    useAppState(profileSliceSelector);

  const getAffiliateFunding = useCallback(
    debounce(() => {
      if (isLoading || !fundingRoundAffiliateList.hasMore) return;
      const lastItem = fundingRoundAffiliateList?.items?.at(-1);
      dispatch(
        getFundingRoundAffiliateList({
          startSk: lastItem?.sk,
          role,
          id,
        }),
      );
    }, 1000),
    [isLoading, fundingRoundAffiliateList.hasMore, dispatch, role, id],
  );

  useEffect(
    () => (): void => {
      dispatch(resetAffiliateFindingList());
    },
    [dispatch],
  );

  return (
    <AffiliateFunding
      affiliateData={fundingRoundAffiliateList}
      getNextAffiliateData={getAffiliateFunding}
      isLoading={isLoading}
      overallBalance={overallBalance}
      role={role}
    />
  );
};

export default AffiliateFundingContainer;
