export enum UserRoles {
  ADMIN = 'admin',
  KOL = 'kol',
  PARTNER = 'partner',
  PROJECT = 'project',
}

export interface IUser {
  id: string;
  formAddress?: string;
  alias?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  zip_code?: string;
  land?: string;
  state?: string;
  phone?: number;
  email?: string;
  status?: string;
  role?: UserRoles;
}

export class UserData implements IUser {
  id;
  formAddress;
  alias;
  firstName;
  lastName;
  address;
  zip_code;
  land;
  state;
  phone;
  email;
  status?;
  role;

  constructor(userData: IUser) {
    this.id = userData.id;
    this.formAddress = userData.formAddress;
    this.alias = userData.alias;
    this.firstName = userData.firstName;
    this.lastName = userData.lastName;
    this.address = userData.address;
    this.zip_code = userData.zip_code;
    this.land = userData.land;
    this.state = userData.state;
    this.phone = userData.phone;
    this.email = userData.email;
    this.status = userData.status;
    this.role = userData.role;
  }

  get full_name() {
    return `${this.firstName ?? ''} ${this.lastName ?? ''}`;
  }

  get is_admin() {
    return this.role === UserRoles.ADMIN;
  }
}
