import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { formatApiError } from '@/utils';
import { UPLOADERS_SLICE_NAME } from './models';
import { UploadersAPI } from '@/api';
import uploadToCloudfront from '../utils/fileUpload';
import { IFileUploadDTO } from '@/models/uploaders.model';

export const uploadFile = createAsyncThunk<
  string,
  IFileUploadDTO,
  { serializedErrorType: IApiError }
>(
  `${UPLOADERS_SLICE_NAME}/uploadFile`,
  async (data) => {
    const response = await UploadersAPI.getUploadUrl({
      ...data,
    });

    await uploadToCloudfront(response.data, data.value);

    return response.data.cloudfrontUrl;
  },
  { serializeError: formatApiError },
);

export const uploadCampaignFile = createAsyncThunk<
  string,
  IFileUploadDTO,
  { serializedErrorType: IApiError }
>(
  `${UPLOADERS_SLICE_NAME}/uploadCampaignFile`,
  async (data) => {
    const response = await UploadersAPI.getCampaignUploadUrl({
      ...data,
    });

    await uploadToCloudfront(response.data, data.value);

    return response.data.cloudfrontUrl;
  },
  { serializeError: formatApiError },
);

export const uploadFundingRoundFile = createAsyncThunk<
  string,
  IFileUploadDTO,
  { serializedErrorType: IApiError }
>(
  `${UPLOADERS_SLICE_NAME}/uploadFundingRoundFile`,
  async (data) => {
    const response = await UploadersAPI.getFundingRoundUploadUrl({
      ...data,
    });

    await uploadToCloudfront(response.data, data.value);

    return response.data.cloudfrontUrl;
  },
  { serializeError: formatApiError },
);
