export enum GeneralStatisticTypes {
  USERS = 'users',
  PROJECTS = 'projects',
  INVESTMENTS = 'investments',
}

export enum RequestsStatisticTypes {
  FINANCE = 'finance',
  PROJECT_MANAGEMENT = 'project_management',
  CUSTOMER_SUPPORT = 'customer_support',
  KOL_MANAGER = 'kol_manager',
}

export enum UsersStatisticTypes {
  TOTAL = 'total',
  CURRENT = 'current',
}

export const usersChartTabsConfig = [
  {
    label: 'tabs_statistics_total',
    key: UsersStatisticTypes.TOTAL,
  },
  {
    label: 'tabs_statistics_current',
    key: UsersStatisticTypes.CURRENT,
  },
];
