// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Modules
import { getPostMeasureUnit } from '@/modules/KolsList/helpers/normalizedKOLChannels';

// Models
import { IAverageSocialDataItem } from '@/models/dataLists.model';
import { SocialChannels } from '@/models/socialChannel.model';

// Helpers
import {
  StyledCell,
  StyledChannelValue,
  StyledRowBlock,
} from '../ContentDistribution/ContentDistributionStyles';
import { ContentDistributionDataTypes } from '../../helpers/types';
import { abbreviateNumber, capitalizeString } from '@/utils';

interface ITableRow {
  type: ContentDistributionDataTypes;
  data: IAverageSocialDataItem;
  title: string;
}

const TableRow = ({ type, title, data }: ITableRow) => {
  const { t } = useTranslation(['campaigns']);
  const total = useMemo(
    () =>
      Object.values(data ?? {})?.reduce(
        (cur, acc) => Math.floor(acc) + Math.floor(cur),
        0,
      ),
    [data],
  );
  const isImpressionsItems = type === ContentDistributionDataTypes.IMPRESSIONS;

  const channelsRender = Object.entries(data ?? {}).map(([key, value]) => {
    const impressionFormatted = abbreviateNumber(value);
    const postsFormatted = `${Math.floor(value)} ${capitalizeString(
      getPostMeasureUnit(key as SocialChannels, value !== 1),
    )}`;

    return (
      <StyledCell key={key + type}>
        <StyledChannelValue>
          {isImpressionsItems ? impressionFormatted : postsFormatted}
        </StyledChannelValue>
      </StyledCell>
    );
  });

  return (
    <StyledRowBlock>
      <StyledCell>
        <StyledChannelValue>{t(title)}</StyledChannelValue>
      </StyledCell>
      {channelsRender}

      <StyledCell>
        <StyledChannelValue>
          {isImpressionsItems
            ? abbreviateNumber(total)
            : t('campaigns_posts_and_videos', {
                amount: Math.floor(total ?? 0),
              })}
        </StyledChannelValue>
      </StyledCell>
    </StyledRowBlock>
  );
};

export default TableRow;
