// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledTitle,
  StyledBlockWrapper,
  AffiliateInput,
  SettingsWrapper,
} from '../Styles';
import { FormItem, FormList, IFormInstance } from '@/components';

// Helpers | Hooks
import { SettingsFieldsNames } from '../../helpers';

interface IDocumentsSettings {
  isEditActive: boolean;
  formInstance: IFormInstance;
}

const DocumentsSettings = ({
  isEditActive,
  formInstance,
}: IDocumentsSettings) => {
  const { t } = useTranslation(['settings']);

  return (
    <StyledBlockWrapper>
      <StyledTitle>{t('settings_documents_title')}</StyledTitle>
      <FormList name={SettingsFieldsNames.DOCUSIGN_DOCUMENTS_TEMPLATES}>
        {(fields) => {
          return (
            <SettingsWrapper>
              {fields.map((field) => {
                const item = formInstance.getFieldValue([
                  SettingsFieldsNames.DOCUSIGN_DOCUMENTS_TEMPLATES,
                  field.name,
                ]);

                return (
                  <FormItem
                    key={field.name}
                    name={[field.name, 'templateId']}
                    label={t(
                      `settings_${item.documentType}_${item.signingType}`,
                    )}
                  >
                    <AffiliateInput disabled={!isEditActive} />
                  </FormItem>
                );
              })}
            </SettingsWrapper>
          );
        }}
      </FormList>
    </StyledBlockWrapper>
  );
};

export default DocumentsSettings;
