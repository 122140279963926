// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledLabel, StyledBadge } from './RequestStatusBadgesStyles';

// Models
import { getRequestStatuses } from '../../helpers';

interface IProps {
  status?: string | null;
}

const RequestStatusBadges = ({ status }: IProps) => {
  const { t } = useTranslation(['requestsList']);
  return (
    <StyledBadge $status={status}>
      <StyledLabel>{getRequestStatuses(t, status)}</StyledLabel>
    </StyledBadge>
  );
};

export default RequestStatusBadges;
