import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { SETTINGS_SLICE_NAME, SettingsState } from './models';

export const settingsSliceSelector = (state: RootState): SettingsState =>
  state[SETTINGS_SLICE_NAME];

export const selectSettingsIsLoading = createSelector(
  settingsSliceSelector,
  (state: SettingsState) => state.isLoading,
);

export const selectSettingsData = createSelector(
  settingsSliceSelector,
  (state: SettingsState) => state.settingsData,
);

export const selectAverageSocialData = createSelector(
  settingsSliceSelector,
  (state: SettingsState) => state.averageSocialData,
);
