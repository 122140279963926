// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledWrapper,
  StyledTitle,
  StyledInfoTitles,
  StyledTitles,
} from './LegalAgreementsStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import LegalAgreementsCard from './LegalAgreementsCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import {
  ILegalAgreementResponse,
  LegalAgreementRoles,
} from '@/models/sharedProfile.model';

interface ILegalAgreements {
  data: ILegalAgreementResponse[];
  role: LegalAgreementRoles;
  isLoading: boolean;
}

const LegalAgreements = ({ isLoading, data, role }: ILegalAgreements) => {
  const { t } = useTranslation(['profile']);

  const mappedAgreementsList = useMemo(() => {
    return data?.map((data) => {
      return <LegalAgreementsCard data={data} key={data.created} role={role} />;
    });
  }, [data]);

  return (
    <StyledWrapper>
      <StyledTitle>{t('legal_agreement_general_title')}</StyledTitle>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('legal_agreement_document')}</StyledTitles>
          <StyledTitles>{t('legal_agreement_acceptance_date')}</StyledTitles>
          <StyledTitles>{t('legal_agreement_ip_address')}</StyledTitles>
          <StyledTitles>{t('legal_agreement_link')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedAgreementsList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
    </StyledWrapper>
  );
};

export default LegalAgreements;
