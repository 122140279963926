// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  KOLsListWrapper,
  StyledInfoTitles,
  StyledTitles,
} from './PartnersListStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import PartnerCard from '../PartnerCard/PartnerCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { IPartner } from '@/models/partnersList.model';

// Hooks
import { useInfiniteScroll } from '@/hooks';

type TPartnersListProps = {
  partners: {
    items: IPartner[] | null;
    hasMore: boolean;
  };
  getNextProjects: () => void;
  isLoading: boolean;
};

const PartnersList = ({
  partners,
  getNextProjects,
  isLoading,
}: TPartnersListProps) => {
  const { t } = useTranslation(['partnersList']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedPartnerList = useMemo(() => {
    return partners?.items?.map((partnerItem) => {
      return <PartnerCard partner={partnerItem} key={partnerItem.id} />;
    });
  }, [partners?.items]);

  useInfiniteScroll({
    callback: getNextProjects,
    ref: loaderRef,
    isLoading,
    hasMore: partners?.hasMore,
  });

  return (
    <KOLsListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('hunters_list_name_title')}</StyledTitles>
          <StyledTitles>{t('hunters_list_contact_title')}</StyledTitles>
          <StyledTitles>{t('hunters_list_status_title')}</StyledTitles>
          <StyledTitles>
            {t('hunters_list_social_kyc_status_title')}
          </StyledTitles>
          <StyledTitles>{t('hunters_list_details_status_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedPartnerList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </KOLsListWrapper>
  );
};

export default PartnersList;
