// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledProjectInfoWrapper,
  StyledInfo,
  StyledDetails,
  StyledFormItem,
  EditWrap,
} from './ProjectMainInformationStyles';
import ProjectUploads from '../ProjectUploads/ProjectUploads';
import { StyledInput } from '@/ui';
import { EditButton, IFormInstance } from '@/components';

// Modules
import { SocialChannelsSelectBlock } from '@/modules/SharedProfile';

// Models
import { IProject } from '@/models/projects.model';
import { UserRoles } from '@/models/admin.model';

// Helpers | Hooks
import { ProjectFieldsNames } from '../../types';
import { useGetFormValidationRules } from '../../hooks';
import { isProjectDisabled } from '../../helpers';

interface IProjectMainInformation {
  projectDetails: IProject;
  isEditActive: boolean;
  toggleIsEditActive: () => void;
  formInstance: IFormInstance;
}

const ProjectMainInformation = ({
  projectDetails,
  isEditActive,
  toggleIsEditActive,
  formInstance,
}: IProjectMainInformation) => {
  const { t } = useTranslation(['projectsList']);
  const validationRules = useGetFormValidationRules();
  const isFieldsDisabled =
    !isEditActive || isProjectDisabled(projectDetails?.status);

  return (
    <StyledProjectInfoWrapper>
      <StyledInfo>
        <StyledDetails>
          <StyledFormItem
            name={ProjectFieldsNames.TITLE}
            label={t('projects_information_title')}
            rules={validationRules[ProjectFieldsNames.TITLE]}
          >
            <StyledInput disabled={isFieldsDisabled} />
          </StyledFormItem>
        </StyledDetails>

        <StyledDetails>
          <StyledFormItem
            name={ProjectFieldsNames.SUB_TITLE}
            label={t('projects_information_sub_title')}
            rules={validationRules[ProjectFieldsNames.SUB_TITLE]}
          >
            <StyledInput
              disabled={isFieldsDisabled}
              defaultValue={projectDetails.subtitle}
            />
          </StyledFormItem>
        </StyledDetails>
      </StyledInfo>

      <StyledInfo>
        <ProjectUploads
          projectDetails={projectDetails}
          isEditActive={isEditActive}
          isLoading={false}
          initialValues={projectDetails}
          formInstance={formInstance}
        />
        <SocialChannelsSelectBlock
          formInstance={formInstance}
          data={projectDetails}
          role={projectDetails?.role as UserRoles}
          disabled={isFieldsDisabled}
          isEditActive={isEditActive}
        />
      </StyledInfo>
      <EditWrap>
        <EditButton
          isEditActive={isEditActive}
          setIsEditActive={toggleIsEditActive}
        />
      </EditWrap>
    </StyledProjectInfoWrapper>
  );
};

export default ProjectMainInformation;
