import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  min-height: 6rem;
  margin-bottom: 1rem;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 12rem;
  }
`;

export const StyledContentWrapper = styled.div<{ $wide?: boolean }>`
  width: 100%;
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  overflow-x: auto;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-wrap: nowrap;
  }
`;

export const StyledContentplanWrapper = styled(BlockLayout)<{
  $wide?: boolean;
}>`
  width: 100%;
  min-width: 70rem;
  align-items: center;
  min-height: 6rem;
  max-width: 100%;
  padding: 0.75rem;
  flex-direction: column;
  position: relative;

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 12rem;
    padding: 1.5rem;
    max-width: 100%;
  }
`;

export const StyledNote = styled.div`
  ${pickThemeFontStyles('12', '15', 'SEMI_NORMAL')};
  color: ${COLORS.GREY};
  margin-top: 1.5rem;
`;

export const StyledChannelValue = styled.div`
  ${pickThemeFontStyles('14', '24', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  margin-left: 0.35rem;
`;

export const StyledCell = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  &&&:first-child {
    min-width: 14rem;
    justify-content: flex-start;
  }
`;

export const StyledRowBlock = styled(BlockLayout)`
  min-height: 5rem;
  margin-bottom: 1rem;
  flex-direction: row;
`;

export const StyledButton = styled(Button)`
  height: 2.25rem !important;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin: 1rem 0rem 5rem 0rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    justify-content: flex-end;
  }
`;
