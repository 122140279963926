import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout } from '@/ui';
import { FormItem } from '@/components';

export const StyledProjectInfoWrapper = styled(BlockLayout)`
  display: flex;
  align-items: flex-start;
  position: relative;
  gap: 1.5rem;
  padding: 1rem;
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0.5rem;
  label {
    ${pickThemeFontStyles('14', '18', 'NORMAL')};
    color: ${COLORS.CORN_FLOWER_BLUE}!important;
  }
`;

export const StyledInfo = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
  gap: 0.5rem;
`;

export const EditWrap = styled.div`
  &&& {
    position: absolute;
    right: 1rem !important;
    top: 1rem !important;
  }
`;

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('12', '22', 'NORMAL')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '18', 'NORMAL')};
  }
`;

export const StyledA = styled.a`
  color: ${COLORS.SOLITUDE_DARK};
  ${pickThemeFontStyles('10', '10', 'SEMI_BOLD')};
`;
