// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledInfoWrapper,
  StyledInfoTitle,
  CardWrapper,
  StyledText,
  StyledA,
  StyledLinkWrapper,
  StyledIconWrapper,
  IconWrap,
} from './MarketingDeliveryInfoStyles';
import { CloseIcon, EditIcon } from '@/icons';
import { ConfirmationModal } from '@/components';

// Models
import { IMarketingDeliveryInfo } from '@/models/marketingDelivery.model';

// Modules
import { MobileOnly } from '@/modules/Adaptive';
import { socialChannelsConfig } from '@/modules/KolsList';

// Helpers
import { getDateString, numberWithCommas } from '@/utils';
import { DATE_FORMATS } from '@/constants';
import { useAppDispatch, useModal } from '@/hooks';
import { setCurrentPostData } from '../../feature/slice';

interface IMarketingDeliveryInfoCard {
  data: IMarketingDeliveryInfo;
  handleDeleteCampaignPost: (proofId: string, sk: string) => void;
  openUpdateModal: () => void;
}

const MarketingDeliveryInfoCard = ({
  data,
  handleDeleteCampaignPost,
  openUpdateModal,
}: IMarketingDeliveryInfoCard) => {
  const { t } = useTranslation(['projectsList']);
  const dispatch = useAppDispatch();
  const {
    isOpened: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const handleRemove = () => {
    handleDeleteCampaignPost(data?.id ?? '', data?.sk ?? '');
    closeDeleteModal();
  };

  const onEdit = async () => {
    await dispatch(setCurrentPostData(data));
    openUpdateModal();
  };

  return (
    <CardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>{t('proof_of_delivery_admin')}</StyledInfoTitle>
        </MobileOnly>
        <StyledText>{data?.adminEmail ?? '-'}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>{t('proof_of_delivery_kol')}</StyledInfoTitle>
        </MobileOnly>
        <StyledText>{data?.alias}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>{t('proof_of_delivery_cw')}</StyledInfoTitle>
        </MobileOnly>
        <StyledText>{data?.weekNumber}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>{t('proof_of_delivery_date')}</StyledInfoTitle>
        </MobileOnly>
        <StyledText>
          {getDateString(
            data?.dateOfPublish ?? '',
            DATE_FORMATS.DATE_WITH_DOTS,
          )}
        </StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>{t('proof_of_delivery_link')}</StyledInfoTitle>
        </MobileOnly>
        <StyledLinkWrapper>
          <StyledIconWrapper>
            {data?.socialChannel &&
              socialChannelsConfig[data?.socialChannel]?.icon}
          </StyledIconWrapper>
          <StyledA href={data?.link} target="_blank">
            {data?.link}
          </StyledA>
        </StyledLinkWrapper>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>{t('proof_of_delivery_price')}</StyledInfoTitle>
        </MobileOnly>
        <StyledText>${numberWithCommas(data?.pricePerPost ?? 0)}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>{t('proof_of_delivery_engagement')}</StyledInfoTitle>
        </MobileOnly>
        <StyledText>{data?.postResults?.engagementRate}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>{t('proof_of_delivery_reach')}</StyledInfoTitle>
        </MobileOnly>
        <StyledText>{data?.postResults?.richRate}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>{t('proof_of_delivery_actions')}</StyledInfoTitle>
        </MobileOnly>
        {data?.adminEmail && (
          <IconWrap>
            <CloseIcon onClick={openDeleteModal} />
            <EditIcon onClick={onEdit} />
          </IconWrap>
        )}
      </StyledInfoWrapper>

      <ConfirmationModal
        isOpened={isDeleteModalOpen}
        title={t('proof_of_delivery_delete_post')}
        onClose={closeDeleteModal}
        onConfirm={handleRemove}
      />
    </CardWrapper>
  );
};

export default MarketingDeliveryInfoCard;
