import { RoutePermissions } from '../constants';
import { AdminActionsEnum } from '@/models/adminsList.model';
import { ROUTES_PATHS } from '@/router';

const checkRoutePermission = (
  route: ROUTES_PATHS,
  adminPermissions: AdminActionsEnum[],
) => {
  const routePermissions = RoutePermissions[route] ?? [];
  const isSuperAdmin = adminPermissions?.includes(AdminActionsEnum.FULL_ADMIN);
  const permissionsToCheck = Array.isArray(routePermissions)
    ? routePermissions
    : [routePermissions];

  if (isSuperAdmin) return true;

  return permissionsToCheck.some((permission) =>
    adminPermissions.includes(permission),
  );
};

export default checkRoutePermission;
