import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { REPORTS_SLICE_NAME } from './models';
import { ReportsAPI } from '@/api';
import { formatApiError } from '@/utils';
import {
  FundingRoundParticipantsReportResponse,
  ICampaignReportsFilterOptionsResponse,
  ReportAffiliateResponse,
  ICampaignReportsListResponse,
  IFundingReportsListResponse,
  IFundingRoundFilterOptionsResponse,
  IGetCampaignReportsListDTO,
  IGetFundingReportsListDTO,
  TFundingRoundsReportGeneralDetailData,
  CampaignParticipantsReportResponse,
} from '@/models/reports.model';
import { ICampaign } from '@/models/campaigns.model';

export const getFundingReportsList = createAsyncThunk<
  IFundingReportsListResponse,
  IGetFundingReportsListDTO,
  { serializedErrorType: IApiError }
>(
  `${REPORTS_SLICE_NAME}/getFundingReportsList`,
  async (data: IGetFundingReportsListDTO) => {
    const response = await ReportsAPI.getFundingReportsList({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getCampaignReportsList = createAsyncThunk<
  ICampaignReportsListResponse,
  IGetCampaignReportsListDTO,
  { serializedErrorType: IApiError }
>(
  `${REPORTS_SLICE_NAME}/getCampaignReportsList`,
  async (data: IGetCampaignReportsListDTO) => {
    const response = await ReportsAPI.getCampaignReportsList({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getFundingRoundReportDetails = createAsyncThunk<
  TFundingRoundsReportGeneralDetailData,
  string,
  { serializedErrorType: IApiError }
>(
  `${REPORTS_SLICE_NAME}/getFundingRoundReportDetails`,
  async (id: string) => {
    const response = await ReportsAPI.getFundingRoundReportDetails(id);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getCampaignReportDetails = createAsyncThunk<
  ICampaign,
  string,
  { serializedErrorType: IApiError }
>(
  `${REPORTS_SLICE_NAME}/getCampaignReportDetails`,
  async (id: string) => {
    const response = await ReportsAPI.getCampaignReportDetails(id);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getFundingRoundReportParticipants = createAsyncThunk<
  FundingRoundParticipantsReportResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${REPORTS_SLICE_NAME}/getFundingRoundReportParticipants`,
  async (id: string) => {
    const response = await ReportsAPI.getFundingRoundReportParticipants(id);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getCampaignReportParticipants = createAsyncThunk<
  CampaignParticipantsReportResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${REPORTS_SLICE_NAME}/getCampaignReportParticipants`,
  async (id: string) => {
    const response = await ReportsAPI.getCampaignReportParticipants(id);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getFundingRoundReportAffiliate = createAsyncThunk<
  ReportAffiliateResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${REPORTS_SLICE_NAME}/getFundingRoundReportAffiliate`,
  async (id: string) => {
    const response = await ReportsAPI.getFundingRoundReportAffiliate(id);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getCampaignAffiliate = createAsyncThunk<
  ReportAffiliateResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${REPORTS_SLICE_NAME}/getCampaignAffiliate`,
  async (id: string) => {
    const response = await ReportsAPI.getCampaignAffiliate(id);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getFundingRoundReportOptions = createAsyncThunk<
  IFundingRoundFilterOptionsResponse,
  void,
  { serializedErrorType: IApiError }
>(
  `${REPORTS_SLICE_NAME}/getFundingRoundReportOptions`,
  async () => {
    const response = await ReportsAPI.getFundingRoundReportOptions();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getCampaignReportOptions = createAsyncThunk<
  ICampaignReportsFilterOptionsResponse,
  void,
  { serializedErrorType: IApiError }
>(
  `${REPORTS_SLICE_NAME}/getCampaignReportOptions`,
  async () => {
    const response = await ReportsAPI.getCampaignReportOptions();
    return response.data;
  },
  { serializeError: formatApiError },
);
