import { useAppState } from '@/hooks';
import {
  AdminActionsEnum,
  readRequestActions,
} from '@/models/adminsList.model';
import { selectAdminPermissions } from '../feature/selectors';

const usePermissions = (action: AdminActionsEnum | AdminActionsEnum[]) => {
  const permissions = useAppState(selectAdminPermissions); // to permissions module
  const isSuperAdmin = permissions?.includes(AdminActionsEnum.FULL_ADMIN);
  const isRequestsViewer = permissions?.includes(
    AdminActionsEnum.READ_ALL_REQUESTS,
  );
  const actionsArray = Array.isArray(action) ? action : [action];
  const allowedState = [];

  for (const item of actionsArray) {
    const isAllowed =
      isSuperAdmin ||
      (isRequestsViewer && readRequestActions.has(item)) ||
      permissions?.includes(item);

    allowedState.push(isAllowed);
  }

  return allowedState;
};

export default usePermissions;
