// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  Wrapper,
  BlockWrapper,
  StyledText,
  StyledInputWrapper,
  NavigationWrapper,
  StyledButton,
  StyledTitle,
  StyledHeaderWrapper,
} from '../Styled';
import { Link, StyledInput } from '@/ui';
import ActionButtons from '../ActionButtons/ActionButtons';
import RequestStatusBadges from '../RequestStatusBadges/RequestStatusBadges';

// Models
import { IPayout, IRequestDetails } from '@/models/requests.model';

// Helpers | Hooks
import { getDateString, numberWithCommas } from '@/utils';
import { ROUTES_PATHS } from '@/router';

interface IFundingRoundRequestDetails {
  request: IRequestDetails | null;
  isLoading?: boolean;
  isEditable?: boolean;
}

const FundingRoundRefundRequestDetails = ({
  request,
  isLoading,
}: IFundingRoundRequestDetails) => {
  const { t } = useTranslation(['requestsList']);
  const { firstName, lastName } = request?.requester ?? {};

  const {
    fundingRoundTitle,
    projectName,
    fundingRoundEndDate,
    totalPayoutAmount,
    raisedBudget,
    totalSeedHunterCommission,
    creditsUsed,
  } = (request?.data as IPayout) ?? {};

  const actionTypeLabel = t(`requests_${request?.actionType}_label`);

  return (
    <Wrapper>
      <StyledHeaderWrapper>
        <StyledTitle>{t('request_title')}</StyledTitle>
        <RequestStatusBadges status={request?.status} />
      </StyledHeaderWrapper>
      <StyledText>{`${t('requests_type')} ${actionTypeLabel}`}</StyledText>
      <NavigationWrapper>
        <StyledTitle $type="h2">
          {t('requests_profile_admin_label')}
        </StyledTitle>
        <Link
          to={`${ROUTES_PATHS.FUNDING_ROUNDS}/${encodeURIComponent(
            request?.targetId ?? '',
          )}`}
          target="_blank"
        >
          <StyledButton type="primary">
            {t('verification_request_go_to_profile')}
          </StyledButton>
        </Link>
      </NavigationWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_name_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={firstName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_last_name_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={lastName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('funding_round_payout_request_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={fundingRoundTitle} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_project_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={projectName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('funding_round_payout_end_date')}</StyledText>
        <StyledInputWrapper>
          <StyledInput
            value={getDateString(fundingRoundEndDate ?? '')}
            disabled
          />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('funding_round_payout_budget')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={numberWithCommas(raisedBudget ?? 0)} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_payout_amount')}</StyledText>
        <StyledInputWrapper>
          <StyledInput
            value={numberWithCommas(totalPayoutAmount ?? 0)}
            disabled
          />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('funding_round_used_credits')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={numberWithCommas(creditsUsed ?? 0)} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('funding_round_payout_commission')}</StyledText>
        <StyledInputWrapper>
          <StyledInput
            value={numberWithCommas(totalSeedHunterCommission ?? 0)}
            disabled
          />
        </StyledInputWrapper>
      </BlockWrapper>
      <ActionButtons request={request} isLoading={isLoading} />
    </Wrapper>
  );
};

export default FundingRoundRefundRequestDetails;
