import styled from 'styled-components';
import { BlockLayout, Text } from '@/ui';
import { COLORS, pickThemeFontStyles, DEVICES } from '@/theme';

export const KOLCardWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.5rem;
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  width: 100%;
  flex-direction: column;
  min-width: 0;

  :last-child {
    align-items: center;
    flex: 0.4;
  }
`;

export const StyledText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  flex: 1;
  width: 100%;
  gap: 0.3rem;
  @media screen and ${DEVICES.LAPTOP_S} {
    justify-content: flex-start;
  }
  svg {
    transform: scale(0.9);
  }
`;
