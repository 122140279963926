import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { FUNDING_ROUND_SLICE_NAME } from './models';

import {
  IGetFundingRoundListDTO,
  IFundingRoundListResponse,
  IFundingRound,
  IUpdateFundingDetailsDTO,
  IFundingContractDTO,
  IFundingContract,
  IFundingRoundParticipation,
  IManualConfirmFundingRoundPaymentDTO,
  IFundingRoundSlugDTO,
} from '@/models/fundingRound.model';
import { IGetDocumentPreviewDataResponse } from '@/models/documents.model';
import { FundingRoundAPI } from '@/api';
import { formatApiError } from '@/utils';
import { DOCUMENTS_SLICE_NAME } from '@/modules/Documents';

export const getFundingRoundList = createAsyncThunk<
  IFundingRoundListResponse,
  IGetFundingRoundListDTO,
  { serializedErrorType: IApiError }
>(
  `${FUNDING_ROUND_SLICE_NAME}/getFundingRoundList`,
  async (data: IGetFundingRoundListDTO) => {
    const response = await FundingRoundAPI.getFundingRoundList({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getFundingDetails = createAsyncThunk<
  IFundingRound,
  string,
  { serializedErrorType: IApiError }
>(
  `${FUNDING_ROUND_SLICE_NAME}/getFundingDetails`,
  async (id: string) => {
    const response = await FundingRoundAPI.getFundingDetails(id);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getFundingRoundParticipations = createAsyncThunk<
  IFundingRoundParticipation[],
  string,
  { serializedErrorType: IApiError }
>(
  `${FUNDING_ROUND_SLICE_NAME}/getFundingRoundParticipations`,
  async (id: string) => {
    const response = await FundingRoundAPI.getFundingRoundParticipations(id);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateFundingDetails = createAsyncThunk<
  IFundingRound,
  IUpdateFundingDetailsDTO,
  { serializedErrorType: IApiError }
>(
  `${FUNDING_ROUND_SLICE_NAME}/updateFundingDetails`,
  async (data) => {
    const response = await FundingRoundAPI.updateFundingDetails(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateFundingSlug = createAsyncThunk<
  IFundingRound,
  IFundingRoundSlugDTO,
  { serializedErrorType: IApiError }
>(
  `${FUNDING_ROUND_SLICE_NAME}/updateFundingSlug`,
  async (data) => {
    const response = await FundingRoundAPI.updateFundingSlug(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateFundingContract = createAsyncThunk<
  IFundingContractDTO,
  IFundingContractDTO,
  {
    serializedErrorType: IApiError;
  }
>(
  `${DOCUMENTS_SLICE_NAME}/updateFundingContract`,
  async (data) => {
    const response = await FundingRoundAPI.updateFundingContract(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getFundingContract = createAsyncThunk<
  IFundingContractDTO,
  IFundingContract,
  {
    serializedErrorType: IApiError;
  }
>(
  `${DOCUMENTS_SLICE_NAME}/getFundingContract`,
  async (data) => {
    const response = await FundingRoundAPI.getFundingContract(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getFundingContractPreview = createAsyncThunk<
  IGetDocumentPreviewDataResponse,
  IFundingContract,
  {
    serializedErrorType: IApiError;
  }
>(
  `${DOCUMENTS_SLICE_NAME}/getFundingContractPreview`,
  async (data) => {
    const response = await FundingRoundAPI.getFundingContractPreview(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const manualConfirmFundingRoundPayment = createAsyncThunk<
  IFundingRoundParticipation,
  IManualConfirmFundingRoundPaymentDTO,
  { serializedErrorType: IApiError }
>(
  `${FUNDING_ROUND_SLICE_NAME}/manualConfirmFundingRoundPayment`,
  async (data) => {
    const response = await FundingRoundAPI.manualConfirmFundingRoundPayment(
      data,
    );
    return response.data;
  },
  { serializeError: formatApiError },
);
