import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { AccountStatuses } from '@/constants';

export const StyledBadge = styled.div<{ $status?: string | null }>`
  display: flex;
  height: 1.5rem;
  align-items: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  ${({ $status }) => {
    switch ($status) {
      case AccountStatuses.VERIFIED:
        return `background: ${COLORS.GREEN_BUBBLES};
          color: ${COLORS.GREEN_MINT};`;
      case AccountStatuses.IN_VERIFICATION:
      case AccountStatuses.PENDING:
        return `background: ${COLORS.CHABLIS_PEACH};
          color: ${COLORS.ATOMIC_TANGERINE};`;
      case AccountStatuses.PAUSED:
        return `background: ${COLORS.CHIFFON};
          color: ${COLORS.NEON_CARROT};`;
      default:
        return `background: ${COLORS.MISTY_ROSE};
          color: ${COLORS.PICH_FROLY};`;
    }
  }}
`;

export const StyledLabel = styled.div`
  ${pickThemeFontStyles('10', '16', 'BOLD')};
`;
