// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  BlockWrapper,
  TitleWrap,
  StyledIconWrapper,
  ChannelTitle,
  SliderWrapper,
  StyledSlider,
  TagsWrap,
  StyledTag,
} from './CampaignResultsStyles';

// Modules
import { socialChannelsConfig } from '@/modules/KolsList';

// Models
import { ICampaign, ICampaignSocialChannel } from '@/models/campaigns.model';

// Utils | Hooks
import { getPercent, numberWithCommas } from '@/utils';

type TSocialChannelSlider = {
  campaign?: ICampaign;
  usedBudget?: number;
  campaignBudgetWithoutCommission?: number;
  item: ICampaignSocialChannel;
};

const SocialChannelSlider = ({
  item,
  usedBudget,
  campaignBudgetWithoutCommission,
  campaign,
}: TSocialChannelSlider) => {
  const { t } = useTranslation(['campaigns']);
  const { name, percent } = item;
  const total = (campaign?.marketingBudget ?? 0) * (percent / 100);
  const totalWithoutCommission =
    (campaignBudgetWithoutCommission ?? 0) * (percent / 100);
  const usedBudgetPercent = getPercent(
    usedBudget ?? 0,
    totalWithoutCommission ?? 0,
  );

  const channelBudgetRow = `${t(
    'campaign_details_channel_budget',
  )} $${numberWithCommas(total ?? 0)}`;

  const participationsRow = `${t('campaign_details_participations')} ${
    campaign?.totalKolParticipations?.socials[name] ?? 0
  } KOL's`;

  const bookedPercentageRow = `${t(
    'campaign_details_booked',
  )} ${usedBudgetPercent}%`;

  return (
    <>
      <BlockWrapper>
        <TitleWrap>
          <StyledIconWrapper>
            {name && socialChannelsConfig[name]?.icon}
          </StyledIconWrapper>
          <ChannelTitle>{t(name ?? '', { ns: 'common' })}</ChannelTitle>
        </TitleWrap>
      </BlockWrapper>

      <SliderWrapper>
        <StyledSlider value={usedBudgetPercent} step={1} max={100} />
        <TagsWrap>
          <StyledTag>{bookedPercentageRow}</StyledTag>
        </TagsWrap>
        <TagsWrap>
          <StyledTag>{channelBudgetRow}</StyledTag>
          <StyledTag>{participationsRow}</StyledTag>
        </TagsWrap>
      </SliderWrapper>
    </>
  );
};

export default SocialChannelSlider;
