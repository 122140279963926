import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { DocumentsAPI } from '@/api';
import { formatApiError } from '@/utils';
import {
  DocumentTypes,
  IClickWrapTemplateData,
  ICreateClickWrapTemplateDTO,
} from '@/models/documents.model';
import { DOCUMENTS_SLICE_NAME } from './models';

export const getClickWrapTemplates = createAsyncThunk<
  IClickWrapTemplateData[],
  void,
  { serializedErrorType: IApiError }
>(
  `${DOCUMENTS_SLICE_NAME}/getClickWrapTemplates`,
  async () => {
    const response = await DocumentsAPI.getClickWrapTemplates();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getClickWrapTemplate = createAsyncThunk<
  IClickWrapTemplateData,
  DocumentTypes,
  { serializedErrorType: IApiError }
>(
  `${DOCUMENTS_SLICE_NAME}/getClickWrapTemplate`,
  async (type) => {
    const response = await DocumentsAPI.getClickWrapTemplate(type);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const createClickWrapTemplate = createAsyncThunk<
  IClickWrapTemplateData,
  ICreateClickWrapTemplateDTO,
  { serializedErrorType: IApiError }
>(
  `${DOCUMENTS_SLICE_NAME}/createClickWrapTemplate`,
  async (data) => {
    const response = await DocumentsAPI.createClickWrapTemplate(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
