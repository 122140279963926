import styled from 'styled-components';
import { FormItem, Form } from '@/components';
import { BlockLayout, Button, StyledInput, Title } from '@/ui';
import { COLORS, pickThemeFontStyles } from '@/theme';

export const StyledForm = styled(Form)`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  height: 2rem !important;
`;

export const StyledBlockWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0.5rem 1rem;

  &:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const SettingsWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  width: 100%;

  &&& .ant-form-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0.5rem;

  &&& .ant-select-show-arrow {
    margin-bottom: 0;
  }
`;

export const StyledTitle = styled(Title)`
  ${pickThemeFontStyles('24', '28', 'NORMAL')};
`;

export const Input = styled(StyledInput)`
  &&& .ant-input-prefix {
    ${pickThemeFontStyles('14', '14', 'SEMI_NORMAL')};
    margin-left: 1rem;
  }
  &&& .ant-input {
    background: ${COLORS.LIGHTEST_GRAY};
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const AffiliateInput = styled(StyledInput)`
  &&& .ant-input-suffix {
    ${pickThemeFontStyles('14', '14', 'SEMI_NORMAL')};
    align-items: center;
    position: static;
    margin-bottom: 0rem;
    width: auto;
  }
  &&& .ant-input {
    padding: 0.313rem 0rem 0.313rem 0.688rem;
    width: 2.5rem;
    background: ${COLORS.LIGHTEST_GRAY};
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
