// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  CardWrapper,
  StyledBadge,
  StyledButton,
  StyledInfoWrapper,
  StyledPostText,
  StyledPostTitle,
  StyledButtonWrapper,
} from './PostsContestRequestDetailsStyles';
import { MobileOnly } from '@/modules/Adaptive';
import { Link } from '@/ui';
import RejectUrlModal from './RejectUrlModal';
import ApproveUrlModal from './ApproveUrlModal';

// Models
import {
  PostsContestUrl,
  RequestActionsStatuses,
} from '@/models/requests.model';

// Hooks
import { useModal } from '@/hooks';

interface ITablePostsCard {
  post: PostsContestUrl;
  numbering: number;
  id?: string;
  status?: RequestActionsStatuses;
}

const TablePostsCard = ({ post, numbering, id, status }: ITablePostsCard) => {
  const { t } = useTranslation(['requestsList']);
  const {
    isOpened: isApproveModalOpen,
    openModal: openApproveModal,
    closeModal: closeApproveModal,
  } = useModal();
  const { isOpened, openModal, closeModal } = useModal();
  const isInProgress = status === RequestActionsStatuses?.IN_PROGRESS;

  return (
    <CardWrapper>
      <StyledInfoWrapper>
        <StyledPostText>{numbering}</StyledPostText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledPostTitle>{t('posts_contest_link')}</StyledPostTitle>
        </MobileOnly>
        <StyledPostText>
          <Link to={post?.url ?? ''} target="_blank">
            {post?.url ?? ''}
          </Link>
        </StyledPostText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledPostTitle>{t('posts_contest_status')}</StyledPostTitle>
        </MobileOnly>
        {post?.status ? (
          <StyledBadge $status={post.status}>
            {t(`posts_contest_${post.status}_status`)}
          </StyledBadge>
        ) : (
          <StyledButtonWrapper>
            <StyledButton
              type="default"
              onClick={openApproveModal}
              disabled={!isInProgress}
            >
              {t('posts_contest_approve')}
            </StyledButton>
            <StyledButton
              type="default"
              onClick={openModal}
              disabled={!isInProgress}
            >
              {t('posts_contest_reject')}
            </StyledButton>
          </StyledButtonWrapper>
        )}
      </StyledInfoWrapper>

      <RejectUrlModal
        isOpened={isOpened}
        handleClose={closeModal}
        id={id}
        postId={post?.id}
      />

      <ApproveUrlModal
        isOpened={isApproveModalOpen}
        handleClose={closeApproveModal}
        id={id}
        postId={post?.id}
      />
    </CardWrapper>
  );
};

export default TablePostsCard;
