import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppStage } from '@/models/global.model';
import { useAppStage } from '../hooks';

export const useAppStageRestriction = (
  allowedStage: AppStage[] = [AppStage.SEED_HUNTER],
  redirect?: string,
) => {
  const navigate = useNavigate();
  const { appStage } = useAppStage();

  useEffect(() => {
    const allowed = allowedStage.includes(appStage as AppStage);
    if (!allowed) navigate(redirect ?? '/');
  }, [allowedStage, appStage, redirect, navigate]);
};
