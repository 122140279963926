import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Modal, ModalSize } from '@/components';
import { Title, Text } from '@/ui';
import {
  StyledTextArea,
  StyledTextAreaWrapper,
  StyledButton,
} from './UserForceBlockSwitchStyles';

interface IChangeStatusModal {
  isOpened: boolean;
  handleClose: () => void;
  handleConfirm: (description: string) => Promise<void>;
}

const ChangeStatusModal = ({
  isOpened,
  handleClose,
  handleConfirm,
}: IChangeStatusModal) => {
  const { t } = useTranslation(['common']);
  const [description, setDescription] = useState('');
  const [isValid, setIsValid] = useState(true);

  const onDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDescription(event.target.value);
    event.target.value !== '' && setIsValid(true);
  };

  const confirm = () => {
    if (!description.length) {
      setIsValid(false);
      return;
    }
    handleConfirm(description);
  };

  return (
    <Modal visible={isOpened} onCancel={handleClose} size={ModalSize.LARGE}>
      <Title $type="h2">{t('block_user_confirm')}</Title>
      <StyledTextAreaWrapper>
        {!isValid && <Text>{t('block_user_error_reason')}</Text>}
        <StyledTextArea
          $valid={isValid}
          rows={4}
          value={description}
          onChange={onDescriptionChange}
        />
        <StyledButton type="primary" onClick={confirm}>
          {t('approve')}
        </StyledButton>
      </StyledTextAreaWrapper>
    </Modal>
  );
};

export default ChangeStatusModal;
