// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledParticipationWrapper,
  StyledInfoWrapper,
  StyledTitle,
} from './ProjectParticipationStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import ProjectParticipationCard from '../ProjectParticipationCard/ProjectParticipationCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { IKOLInvestment } from '@/models/kolsList.model';

// Helpers
import { useInfiniteScroll } from '@/hooks';

interface IProjectParticipation {
  investmentsData: {
    items: IKOLInvestment[] | null;
    hasMore: boolean;
  };
  isLoading: boolean;
  getNextInvestments: () => void;
}

const ProjectParticipation = ({
  investmentsData,
  isLoading,
  getNextInvestments,
}: IProjectParticipation) => {
  const { t } = useTranslation(['kolsList']);
  const loaderRef = useRef<HTMLDivElement>(null);
  const mappedProjectParticipation = useMemo(() => {
    return investmentsData?.items?.map((participation, i) => {
      const numbering = i + 1;
      return (
        <ProjectParticipationCard
          key={participation.id}
          participation={participation}
          numbering={numbering}
        />
      );
    });
  }, [investmentsData?.items]);

  useInfiniteScroll({
    callback: getNextInvestments,
    ref: loaderRef,
    isLoading,
    hasMore: investmentsData?.hasMore,
  });

  return (
    <StyledParticipationWrapper>
      <DesktopOnly>
        <StyledInfoWrapper>
          <StyledTitle>#</StyledTitle>
          <StyledTitle>
            {t('kols_project_participation_project_title')}
          </StyledTitle>
          <StyledTitle>
            {t('kols_project_participation_date_title')}
          </StyledTitle>
          <StyledTitle>
            {t('kols_project_participation_money_title')}
          </StyledTitle>
          <StyledTitle>
            {t('kols_project_participation_marketing_title')}
          </StyledTitle>
          <StyledTitle>{t('kols_list_social_status_title')}</StyledTitle>
        </StyledInfoWrapper>
      </DesktopOnly>
      {mappedProjectParticipation}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </StyledParticipationWrapper>
  );
};

export default ProjectParticipation;
