// Globals
import React, { useMemo } from 'react';

// Components
import { StyledRadio, StyledRadioButton, Tabs } from './RadioTabsStyles';

// Models
import { TTabItem } from '@/types';

type TRadioTabs = {
  config: TTabItem[];
};

const RadioTabs = ({ config, ...props }: TRadioTabs) => {
  const buttons = useMemo(() => {
    return config.map((item) => (
      <StyledRadioButton value={item.value} key={item.value}>
        {item.label}
      </StyledRadioButton>
    ));
  }, [config]);

  return (
    <Tabs>
      <StyledRadio {...props} defaultValue={config[0].value}>
        {buttons}
      </StyledRadio>
    </Tabs>
  );
};

export default RadioTabs;
