import { useTranslation } from 'react-i18next';
import {
  useGetRequiredRule,
  useLengthValidationRule,
  useGetNumbersOnlyRule,
  useGetNoSpaceRule,
  useHashTegRule,
} from '@/hooks';
import { ICampaignFields } from '../types';
import { TextLength } from '@/constants';

const useGetFormValidationRules = () => {
  const { t } = useTranslation(['common']);

  const required = useGetRequiredRule({ t });
  const textTitleLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.titleMedium,
  });
  const textFieldLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.short,
  });
  const descriptionFieldLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.long,
  });
  const goalDescriptionFieldLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.medium,
  });
  const hashtagFieldLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.veryShort,
  });
  const numbers = useGetNumbersOnlyRule({ t });
  const noSpace = useGetNoSpaceRule({ t });
  const hashTeg = useHashTegRule({ t });

  return {
    [ICampaignFields.TITLE]: [required, textTitleLength],
    [ICampaignFields.CAMPAIGN_DESCRIPTION]: [required, descriptionFieldLength],
    [ICampaignFields.HASHTAG]: [required, hashtagFieldLength, noSpace, hashTeg],
    [ICampaignFields.CAMPAIGN_GOAL]: [required],
    [ICampaignFields.GOAL_URL]: [required],
    [ICampaignFields.GOAL_CHANNELS]: [required],
    [ICampaignFields.GOAL_DESCRIPTION]: [required, goalDescriptionFieldLength],
    [ICampaignFields.USP]: [required, descriptionFieldLength],
    [ICampaignFields.BUDGET]: [required, numbers],
    [ICampaignFields.MARKETING_STRATEGY]: [required, textFieldLength],
    [ICampaignFields.MARKETING_START_DATE]: [required],
    [ICampaignFields.MARKETING_END_DATE]: [required],
    [ICampaignFields.CAMPAIGN_BANNER]: [required],
    [ICampaignFields.PUBLIC_DATE]: [required],
    [ICampaignFields.KOLS]: [required],
  };
};

export default useGetFormValidationRules;
