const decompose = (pool: number, itemsCount: number) => {
  const decomposed = [];
  const perItem = Math.floor(pool / itemsCount);
  const rest = pool - perItem * itemsCount;
  let restCopy = rest;
  for (let i = 0; i < itemsCount; i++) {
    decomposed.push(perItem);
  }

  if (rest) {
    for (let i = 0; i < rest; i++) {
      if (!restCopy) {
        decomposed[i] = 0;
      }

      decomposed[i] = decomposed[i] + 1;
      restCopy--;
    }
  }

  return decomposed;
};

export default decompose;
