import { ApiResponse, httpGet, httpPost } from './httpClient';
import {
  IGetRequestsListDTO,
  IRequestsListResponse,
  IPatchRequestStatusDTO,
  IRequestDetails,
  RequestsEndpoints,
  IRequestsCountDTO,
  IOwnRequestsDTO,
  IOwnRequestsResponse,
  IPatchPostsContestStatusDTO,
  IEntityRequestsDTO,
} from '@/models/requests.model';

class RequestsAPI {
  static getRequestsList(
    data: IGetRequestsListDTO,
  ): ApiResponse<IRequestsListResponse> {
    return httpGet<IRequestsListResponse>(RequestsEndpoints.GET_REQUESTS, {
      ...data,
    });
  }

  static getRequestDetails(id: string): ApiResponse<IRequestDetails> {
    return httpGet<IRequestDetails>(
      `${RequestsEndpoints.GET_REQUESTS}/${encodeURI(id)}`,
    );
  }

  static getRequestCount(data: IRequestsCountDTO): ApiResponse<number> {
    const { id, role } = data;
    return httpGet<number>(
      `${RequestsEndpoints.GET_OWN_REQUESTS}/${role}s/${encodeURI(
        id,
      )}/requests-count`,
    );
  }

  static getOwnRequest(
    data: IOwnRequestsDTO,
  ): ApiResponse<IOwnRequestsResponse> {
    const { id, role, limit } = data;
    return httpGet<IOwnRequestsResponse>(
      `${RequestsEndpoints.GET_OWN_REQUESTS}/${role}s/${encodeURI(
        id,
      )}/requests`,
      {
        limit,
      },
    );
  }

  static getEntityRequest(
    data: IEntityRequestsDTO,
  ): ApiResponse<IOwnRequestsResponse> {
    const { targetId, entity, limit } = data;
    return httpGet<IOwnRequestsResponse>(
      `${RequestsEndpoints.GET_OWN_REQUESTS}/requests/${entity}/${encodeURI(
        targetId,
      )}`,
      {
        limit,
      },
    );
  }

  static patchRequestStatus(data: IPatchRequestStatusDTO) {
    const { message, status, id } = data;
    return httpPost(
      `${RequestsEndpoints.GET_REQUESTS}/${encodeURI(id)}/status`,
      {
        message,
        status,
      },
    );
  }

  static patchPostsContestStatus(data: IPatchPostsContestStatusDTO) {
    const { status, urlId, id } = data;
    return httpPost(
      `${RequestsEndpoints.GET_REQUESTS}/${encodeURI(id)}/verify-posts-contest`,
      {
        publishDate: data?.publishDate,
        reason: data?.reason,
        urlId,
        status,
      },
    );
  }
}

export default RequestsAPI;
