// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledRefundInfoWrapper,
  StyledTitle,
  StyledText,
  CardWrapper,
} from './InvitedUsersStyles';

// Models
import { IUser, UserRoles } from '@/models/admin.model';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Helpers
import { getUserRoleLabel } from '@/utils';

interface IInvitedUserCard {
  data: IUser;
}

const InvitedUserCard = ({ data }: IInvitedUserCard) => {
  const { t } = useTranslation(['affiliate']);
  return (
    <CardWrapper>
      <StyledRefundInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('affiliate_invited_users_name')}</StyledTitle>
        </MobileOnly>
        <StyledText>{`${data.firstName} ${data.lastName}`}</StyledText>
      </StyledRefundInfoWrapper>

      <StyledRefundInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('affiliate_invited_users_email')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data.email}</StyledText>
      </StyledRefundInfoWrapper>

      <StyledRefundInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('affiliate_invited_users_role')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {getUserRoleLabel(data.role?.toLowerCase() as UserRoles)}
        </StyledText>
      </StyledRefundInfoWrapper>
    </CardWrapper>
  );
};

export default InvitedUserCard;
