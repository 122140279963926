// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledTitle,
  StyledBlockWrapper,
  StyledFormItem,
  Input,
  SettingsWrapper,
} from '../Styles';

// Helpers | Hooks
import { SettingsFieldsNames } from '../../helpers';
import { useGetFormValidationRules } from '../../hooks';
import { FormItemGroup, NestedFormItem } from '@/components';

interface IPriceSettings {
  isEditActive: boolean;
}

const PriceSettings = ({ isEditActive }: IPriceSettings) => {
  const { t } = useTranslation(['settings']);

  const validationRules = useGetFormValidationRules();

  return (
    <StyledBlockWrapper>
      <StyledTitle>{t('settings_price_title')}</StyledTitle>

      <FormItemGroup prefix={'socialNetwork'}>
        <SettingsWrapper>
          <StyledFormItem
            name={SettingsFieldsNames.FOLLOWERS_MULTIPLIER}
            rules={validationRules[SettingsFieldsNames.FOLLOWERS_MULTIPLIER]}
            label={t('settings_followers')}
          >
            <Input disabled={!isEditActive} type="number" />
          </StyledFormItem>
          <NestedFormItem
            name={SettingsFieldsNames.INSTAGRAM_PRICE}
            rules={validationRules[SettingsFieldsNames.INSTAGRAM_PRICE]}
            label={t('settings_instagram')}
          >
            <Input disabled={!isEditActive} prefix={'$'} type="number" />
          </NestedFormItem>
          <NestedFormItem
            name={SettingsFieldsNames.YOUTUBE_PRICE}
            rules={validationRules[SettingsFieldsNames.YOUTUBE_PRICE]}
            label={t('settings_youtube')}
          >
            <Input disabled={!isEditActive} prefix={'$'} type="number" />
          </NestedFormItem>
          <NestedFormItem
            name={SettingsFieldsNames.FACEBOOK_PRICE}
            rules={validationRules[SettingsFieldsNames.FACEBOOK_PRICE]}
            label={t('settings_facebook')}
          >
            <Input disabled={!isEditActive} prefix={'$'} type="number" />
          </NestedFormItem>
          <NestedFormItem
            name={SettingsFieldsNames.TWITTER_PRICE}
            rules={validationRules[SettingsFieldsNames.TWITTER_PRICE]}
            label={t('settings_twitter')}
          >
            <Input disabled={!isEditActive} prefix={'$'} type="number" />
          </NestedFormItem>
        </SettingsWrapper>
      </FormItemGroup>
    </StyledBlockWrapper>
  );
};

export default PriceSettings;
