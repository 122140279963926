// Globals
import React from 'react';
import { Column } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledChartWrapper,
  StyledLegendRow,
  StyledLegendWrapper,
  StyledTitle,
  StyledWrapper,
} from './ColumnChartStyles';

// Helpers
import { chartColors, defaultConfig } from './configs';

// Types
import type { TChartDataItem } from '@/types';

interface IColumnChart {
  data?: TChartDataItem[];
  colors?: string[];
  isPureChart?: boolean;
  isMobile?: boolean;
  legend?: boolean;
}

const ColumnChart = ({
  data = [],
  isPureChart,
  legend,
  colors,
}: IColumnChart) => {
  const { t } = useTranslation(['common']);

  const donut = (
    <Column
      xField={'label'}
      yField={'value'}
      {...defaultConfig}
      color={'l(180) 0:rgba(253, 174, 51, 1) 1:rgba(244, 149, 53, 1)'}
      data={data}
    />
  );

  if (isPureChart) {
    return donut;
  }

  return (
    <StyledWrapper>
      <StyledChartWrapper>{donut}</StyledChartWrapper>

      {legend && (
        <StyledLegendWrapper>
          {data?.map((item, idx) => (
            <StyledLegendRow key={item.id}>
              <StyledTitle $color={colors?.[idx] ?? chartColors[idx]}>
                {t(item.label)}
              </StyledTitle>
            </StyledLegendRow>
          ))}
        </StyledLegendWrapper>
      )}
    </StyledWrapper>
  );
};

export default ColumnChart;
