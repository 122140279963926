// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Spinner, StyledSpinnerWrap } from '@/ui';
import {
  StyledButtonWrapper,
  StyledForm,
  StyledButton,
  Input,
  SettingsWrapper,
  StyledBlockWrapper,
  StyledFormItem,
  StyledTitle,
} from '../Styles';
import { IFormInstance } from '@/components';
import NftCreditsBucketHistoryContainer from '../../containers/NftCreditsBucketHistoryContainer/NftCreditsBucketHistoryContainer';

// Modules
import { useGetFormValidationRules } from '@/modules/Settings/hooks';

// Models
import { INFTCreditsBucketData } from '@/models/settings.model';

// Helpers
import { SettingsFieldsNames } from '../../helpers/types';

interface IAverageSocialDataProps {
  formInstance: IFormInstance;
  handleSubmit: (values: INFTCreditsBucketData) => void;
  initialValues: INFTCreditsBucketData | null;
  isEditActive: boolean;
}

const Bucket = ({
  formInstance,
  handleSubmit,
  initialValues,
  isEditActive,
}: IAverageSocialDataProps) => {
  const { t } = useTranslation(['settings']);
  const validationRules = useGetFormValidationRules();

  if (!initialValues)
    return (
      <StyledSpinnerWrap>
        <Spinner />
      </StyledSpinnerWrap>
    );

  return (
    <>
      <StyledForm
        onFinish={handleSubmit}
        form={formInstance}
        initialValues={initialValues}
      >
        <StyledBlockWrapper>
          <StyledTitle>{t('settings_bucket_title')}</StyledTitle>
          <SettingsWrapper>
            <StyledFormItem
              name={SettingsFieldsNames.FUNDING_BUCKET}
              rules={validationRules[SettingsFieldsNames.FUNDING_BUCKET]}
              label={t('settings_bucket_funding')}
            >
              <Input disabled={!isEditActive} type="number" />
            </StyledFormItem>
          </SettingsWrapper>
        </StyledBlockWrapper>

        {isEditActive && (
          <StyledButtonWrapper>
            <StyledButton type="primary" htmlType="submit">
              {t('settings_information_save')}
            </StyledButton>
          </StyledButtonWrapper>
        )}
      </StyledForm>
      <NftCreditsBucketHistoryContainer />
    </>
  );
};

export default Bucket;
