// Global
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component
import {
  DocumentCardWrapper,
  StyledInfoWrapper,
  StyledText,
  StyledTitle,
  StyledButton,
} from './DocumentCardStyles';
import { Link } from '@/ui';
import { ArrowRightIcon } from '@/icons';
import PreviewModal from '../../components/PreviewModal/PreviewModal';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Models
import { IClickWrapTemplateData } from '@/models/documents.model';

// Helpers
import { getDateString } from '@/utils';
import { DATE_FORMATS } from '@/constants';
import { ROUTES_PATHS } from '@/router';
import { useModal } from '@/hooks';

interface IDocumentCard {
  data: IClickWrapTemplateData;
}

const DocumentCard = ({ data }: IDocumentCard) => {
  const { t } = useTranslation(['documents']);
  const { isOpened, openModal, closeModal } = useModal();

  return (
    <>
      <DocumentCardWrapper>
        <StyledInfoWrapper>
          <MobileOnly>
            <StyledTitle>{t('documents_list_document_type')}</StyledTitle>
          </MobileOnly>
          <StyledText>{t(data?.templateType)}</StyledText>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <MobileOnly>
            <StyledTitle>{t('documents_list_date')}</StyledTitle>
          </MobileOnly>
          <StyledText>
            {getDateString(data?.created ?? '', DATE_FORMATS.DATE_WITH_DOTS)}
          </StyledText>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <MobileOnly>
            <StyledTitle>{t('documents_list_version')}</StyledTitle>
          </MobileOnly>
          <StyledText>{data?.version}</StyledText>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <MobileOnly>
            <StyledTitle>{t('documents_list_preview')}</StyledTitle>
          </MobileOnly>
          <StyledButton type="primary" onClick={openModal}>
            {t('documents_list_preview')}
          </StyledButton>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <StyledText>
            <Link to={`${ROUTES_PATHS.DOCUMENTS}/${data.templateType}`}>
              <ArrowRightIcon />
            </Link>
          </StyledText>
        </StyledInfoWrapper>
      </DocumentCardWrapper>
      <PreviewModal
        isOpened={isOpened}
        clickWrapTemplateDetails={data}
        onClose={closeModal}
      />
    </>
  );
};

export default DocumentCard;
