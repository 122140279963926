import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonEN from './en/common.json';
import authEN from './en/auth.json';
import kolsListEN from './en/kolsList.json';
import partnersListEN from './en/partnersList.json';
import projectsEN from './en/projectsList.json';
import profileEN from './en/profile.json';
import fundingTargetEN from './en/fundingTarget.json';
import contentPlanListEN from './en/contentPlan.json';
import requestsListEN from './en/requestsList.json';
import settingsEN from './en/settings.json';
import dashboardEN from './en/dashboard.json';
import campaignsEN from './en/campaigns.json';
import payoutEN from './en/payout.json';
import logsEN from './en/logs.json';
import documentsEN from './en/documents.json';
import affiliateEn from './en/affiliate.json';
import adminsListEn from './en/adminsList.json';
import reportsEn from './en/reports.json';
import fundingRoundEn from './en/fundingRound.json';

const resources = {
  en: {
    common: commonEN,
    auth: authEN,
    kolsList: kolsListEN,
    partnersList: partnersListEN,
    projectsList: projectsEN,
    fundingTarget: fundingTargetEN,
    contentPlan: contentPlanListEN,
    requestsList: requestsListEN,
    settings: settingsEN,
    dashboard: dashboardEN,
    campaigns: campaignsEN,
    payout: payoutEN,
    logs: logsEN,
    profile: profileEN,
    documents: documentsEN,
    affiliate: affiliateEn,
    adminsList: adminsListEn,
    fundingRound: fundingRoundEn,
    reports: reportsEn,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  defaultNS: 'common',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
