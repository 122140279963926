import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';

export const StyledWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
  gap: 1rem;
`;

export const StyledText = styled.div`
  min-width: 12rem;
  ${pickThemeFontStyles('12', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledInputWrap = styled.div`
  width: 100%;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: normal;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 2rem;
  &&& {
    height: 2.25rem;
    background: ${COLORS.BRIGHT_ORANGE};
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledInfo = styled(BlockLayout)`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  flex-direction: row;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: column;
  }
`;

export const StyledTitles = styled.div`
  width: 100%;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledInfoTitles = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  :last-child {
    align-items: center;
    flex: 0.8;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    :last-child {
      align-items: center;
      flex: 1;
    }
  }
`;
