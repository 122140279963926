import { IApiError } from '@/models/apiError.model';
import { IAdmin } from '@/models/adminsList.model';

export const ADMINS_LIST_SLICE_NAME = 'admins-list';

export interface AdminsListState {
  adminDetails: IAdmin | null;
  adminsList: IAdmin[] | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: AdminsListState = {
  adminDetails: null,
  adminsList: [],
  isLoading: false,
  error: null,
};
