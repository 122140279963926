import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { BlockLayout } from '@/ui';

export const StyledCardWrapper = styled(BlockLayout)`
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledText = styled.div`
  color: ${COLORS.GRAY};
  ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
  margin-bottom: 0.25rem;
`;

export const StyledCount = styled.div`
  color: ${COLORS.SAMBUCUS};
  ${pickThemeFontStyles('20', '20', 'BOLD')};
  margin: 0 0.5rem 1rem 0;
`;
