// Globals
import React, { useMemo } from 'react';

// Components
import ProfileRequestCard from '../ProfileRequestCard/ProfileRequestCard';
import { Spinner } from '@/ui';

// Hooks | Helpers
import { useAppState } from '@/hooks';
import { requestsListSliceSelector } from '../../feature/selectors';

const ProfileRequestList = () => {
  const { ownRequestsListData, isLoading } = useAppState(
    requestsListSliceSelector,
  );

  const mappedRequestsList = useMemo(() => {
    return ownRequestsListData?.items?.map((requestItem) => {
      return <ProfileRequestCard request={requestItem} key={requestItem.id} />;
    });
  }, [ownRequestsListData?.items]);

  return (
    <>
      {mappedRequestsList}
      {isLoading && <Spinner />}
    </>
  );
};

export default ProfileRequestList;
