// Global
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component
import { Tooltip } from '@/ui';
import { ContactBar } from '@/components';
import RefundTransactionActions from './RefundTransactionActions';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Models
import { FundingRoundParticipantsReportItem } from '@/models/reports.model';

// Helpers
import { getDateString, numberWithCommas } from '@/utils';
import { DATE_FORMATS } from '@/constants';
import {
  StyledInfoWrapper,
  CardWrapper,
  StyledText,
  StyledTitle,
} from './FundingRefundDetailsCardStyles';

interface IFundingRoundDetailsCard {
  data: FundingRoundParticipantsReportItem;
}

const FundingRefundDetailsCard = ({ data }: IFundingRoundDetailsCard) => {
  const { t } = useTranslation(['payout']);
  const refundAmount =
    (data?.totalParticipationAmount ?? 0) - (data?.amountFromBonusCredit ?? 0);
  const date = getDateString(
    data?.acceptedAt ?? '',
    DATE_FORMATS.DATE_WITH_DOTS,
  );

  return (
    <CardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>
            {t('payout_refund_participation_participant')}
          </StyledTitle>
        </MobileOnly>
        <StyledText>{`${data?.participantFirstName} ${data?.participantLastName}`}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_refund_participation_date')}</StyledTitle>
        </MobileOnly>
        <StyledText>{date}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_refund_participation_contact')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          <ContactBar
            data={{
              mainContact: data.participantMainContact,
              email: data.participantEmail,
            }}
          />
        </StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_refund_participation_amount')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {numberWithCommas(data?.totalParticipationAmount ?? 0)} $
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>
            {t('payout_refund_participation_credits_used')}
          </StyledTitle>
        </MobileOnly>
        <StyledText>
          {numberWithCommas(data?.amountFromBonusCredit ?? 0)} $
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_refund_amount_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(refundAmount)} $</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_refund_participant_tokens')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {numberWithCommas(
            (data?.totalParticipationAmount ?? 0) / (data?.pricePerToken ?? 0),
          )}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_refund_participant_wallet')}</StyledTitle>
        </MobileOnly>
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode}
          title={data?.walletAddressForReceivingTokens}
          placement={'top'}
        >
          <StyledText>{data?.walletAddressForReceivingTokens}</StyledText>
        </Tooltip>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_refund_finance_manager')}</StyledTitle>
        </MobileOnly>
        <RefundTransactionActions data={data} />
      </StyledInfoWrapper>
    </CardWrapper>
  );
};

export default FundingRefundDetailsCard;
