export enum CryptoCurrenciesEnum {
  USDC = 'USDC',
  USDT = 'USDT',
  SEPOLIA = 'SEPOLIA',
}

export enum WalletNetworksEnum {
  POLYGON = 'POLYGON',
  SEPOLIA = 'SEPOLIA',
  ETHEREUM = 'ETHEREUM',
}
