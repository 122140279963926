// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  ReportCardWrapper,
  StyledInfoWrapper,
  StyledTitle,
  StyledText,
  StyledCurrency,
} from './FundingReportCardStyles';
import { ArrowRightIcon } from '@/icons';
import { Link } from '@/ui';

// Modules
import { currenciesConfig, networksConfig } from '@/modules/Web3';
import { MobileOnly } from '@/modules/Adaptive';
import { fundingTargetCalculation } from '@/modules/FundingRounds/helpers';

// Models
import { TFundingRoundsReportGeneralDetailData } from '@/models/reports.model';
import { WalletNetworksEnum } from '@/models/web3.model';

// Utils | Helpers
import { findByName, numberWithCommas } from '@/utils';
import { ROUTES_PATHS } from '@/router';
import { IReportsTabsNames } from '../../constants';

interface IFundingReportCard {
  report: TFundingRoundsReportGeneralDetailData;
  isListView?: boolean;
}

const FundingReportCard = ({ report, isListView }: IFundingReportCard) => {
  const { t } = useTranslation(['reports']);
  const fundingTarget = fundingTargetCalculation(report);

  return (
    <ReportCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('reports_list_project_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{report.projectTitle}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('reports_list_funding_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{report.title}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('reports_list_funding_target_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          $ {numberWithCommas(Number(fundingTarget) ?? 0)}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('reports_list_funding_raised_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(report.raisedAmount ?? 0)} $</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('reports_list_network_title')}</StyledTitle>
        </MobileOnly>

        <StyledCurrency>
          <img
            src={
              findByName(currenciesConfig, report?.walletCurrency ?? '')
                ?.image ?? ''
            }
            width={24}
            height={24}
          />
          <img
            src={
              networksConfig[report?.walletNetwork as WalletNetworksEnum]
                ?.image || ''
            }
            width={24}
            height={24}
          />
        </StyledCurrency>
      </StyledInfoWrapper>
      {isListView && (
        <StyledInfoWrapper>
          <StyledText>
            <Link
              to={`${ROUTES_PATHS.REPORTS}/${
                IReportsTabsNames.FUNDING_ROUND
              }/${encodeURIComponent(report.id ?? '')}`}
            >
              <ArrowRightIcon />
            </Link>
          </StyledText>
        </StyledInfoWrapper>
      )}
    </ReportCardWrapper>
  );
};

export default FundingReportCard;
