import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { BlockLayout } from '@/ui';
import { FormItem } from '@/components';

export const StyledDescriptionWrapper = styled(BlockLayout)`
  display: flex;
  align-items: flex-start;
  position: relative;
  gap: 1.5rem;
  padding: 1rem;
`;

export const StyledInfo = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0.5rem;
  label {
    ${pickThemeFontStyles('14', '18', 'NORMAL')};
    color: ${COLORS.CORN_FLOWER_BLUE}!important;
  }
`;
