// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Spinner, StyledSpinnerWrap } from '@/ui';
import {
  StyledBlockWrapper,
  StyledFormItem,
  Input,
  StyledForm,
  StyledButtonWrapper,
  StyledButton,
  SettingsWrapper,
} from '../Styles';
import { IFormInstance } from '@/components';

// Modules
import { useGetFormValidationRules } from '@/modules/Settings/hooks';

// Models
import { AutomaticApprovalDTO } from '@/models/settings.model';

// Helpers
import { SettingsFieldsNames } from '../../helpers/types';

interface IAutomaticApprovalSettings {
  formInstance: IFormInstance;
  handleSubmit: (values: AutomaticApprovalDTO) => void;
  initialValues: AutomaticApprovalDTO | null;
  isEditActive: boolean;
}

const AutomaticApprovalSettings = ({
  formInstance,
  handleSubmit,
  initialValues,
  isEditActive,
}: IAutomaticApprovalSettings) => {
  const { t } = useTranslation(['settings']);
  const validationRules = useGetFormValidationRules();

  if (!initialValues)
    return (
      <StyledSpinnerWrap>
        <Spinner />
      </StyledSpinnerWrap>
    );

  return (
    <StyledForm
      onFinish={handleSubmit}
      form={formInstance}
      initialValues={initialValues}
    >
      <StyledBlockWrapper>
        <SettingsWrapper>
          <StyledFormItem
            name={SettingsFieldsNames.MIN_FOLLOWERS}
            rules={validationRules[SettingsFieldsNames.MIN_FOLLOWERS]}
            label={t('settings_number_of_followers')}
          >
            <Input disabled={!isEditActive} type="number" />
          </StyledFormItem>
          <StyledFormItem
            name={SettingsFieldsNames.MIN_ENGAGEMENT_RATE}
            rules={validationRules[SettingsFieldsNames.MIN_ENGAGEMENT_RATE]}
            label={t('settings_profile_engagement')}
          >
            <Input disabled={!isEditActive} type="number" />
          </StyledFormItem>
          <StyledFormItem
            name={SettingsFieldsNames.MAX_MONTH_REPORT}
            rules={validationRules[SettingsFieldsNames.MAX_MONTH_REPORT]}
            label={t('settings_profile_reach_rate')}
          >
            <Input disabled={!isEditActive} type="number" />
          </StyledFormItem>
          <StyledFormItem
            name={SettingsFieldsNames.MAX_COORDINATED_FOLLOWERS}
            rules={
              validationRules[SettingsFieldsNames.MAX_COORDINATED_FOLLOWERS]
            }
            label={t('settings_profile_coordinated_followers')}
          >
            <Input disabled={!isEditActive} type="number" />
          </StyledFormItem>
        </SettingsWrapper>
      </StyledBlockWrapper>

      {isEditActive && (
        <StyledButtonWrapper>
          <StyledButton type="primary" htmlType="submit">
            {t('settings_information_save')}
          </StyledButton>
        </StyledButtonWrapper>
      )}
    </StyledForm>
  );
};

export default AutomaticApprovalSettings;
