import styled from 'styled-components';
import { Image } from 'antd';
import {
  COLORS,
  DEVICES,
  pickThemeFontSize,
  pickThemeFontStyles,
} from '@/theme';
import { BlockLayout } from '@/ui';
import { FormItem } from '@/components';

export const StyledFormItem = styled(FormItem)`
  width: 100%;
  margin-bottom: 0.5rem;
  flex-direction: column;

  &&& .ant-form-item-row {
    align-items: flex-start;
    flex-direction: column;

    .ant-form-item-control {
      width: 100%;
    }
  }

  label {
    ${pickThemeFontStyles('14', '18', 'NORMAL')};
    color: ${COLORS.CORN_FLOWER_BLUE}!important;
  }
`;

export const StyledFormWrapper = styled(BlockLayout)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 8rem;
  }
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledUploadFilePlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${pickThemeFontStyles('15', '15', 'SEMI_NORMAL')};
  color: ${COLORS.SOFT_GRAY};
  background: ${COLORS.WHITE};
  border: 1px dashed ${COLORS.SOFT_GRAY};
  border-radius: 0.25rem;
  width: 100%;
  height: 3.75rem;
  padding: 1rem;

  svg {
    margin-right: 0.75rem;
  }
`;

export const ResourcesWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  width: 100%;
  gap: 1.5rem;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;

  @media screen and ${DEVICES.MOBILE_XL} {
    flex-direction: row;
  }
`;

export const StyledDocumentWrapper = styled.div`
  position: relative;
  width: 6rem;
  height: 10rem;
`;

export const StyledIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0rem;
  top: 0rem;
  z-index: 10;
`;

export const StyledDownloadButton = styled.a`
  svg {
    margin: 70% 0 0 40%;
    font-size: ${pickThemeFontSize('40')};
    transform: scale(1.5);
  }
`;

export const StyledResourcesWrapper = styled.div`
  max-width: 10rem;
  position: relative;
`;

export const StyledImage = styled(Image)`
  &&& {
    width: 10rem;
    height: 10rem;
    position: relative !important;
    object-fit: cover;
  }
`;

export const StyledName = styled.div`
  text-overflow: ellipsis;
  max-width: 8rem;
  overflow: hidden;
  white-space: nowrap;
  ${pickThemeFontStyles('14', '22', 'NORMAL')};
  color: ${COLORS.GREY};
  margin: 1rem 0 0 0;
`;
