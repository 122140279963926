// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledFormWrapper,
  FormTextWrapper,
  StyledTitle,
  FormFieldsWrapper,
  StyledText,
  StyledSubTitle,
  StyledRow,
} from './ContractInfoStyles';

// Models
import { IKOL } from '@/models/kolsList.model';
import { IPartner } from '@/models/partnersList.model';

interface IContractInfo {
  userData?: IKOL | IPartner | null;
}

const ContractInfo = ({ userData }: IContractInfo) => {
  const { t } = useTranslation(['profile']);
  if (!userData) return null;

  return (
    <StyledFormWrapper>
      <FormTextWrapper>
        <StyledTitle>{t('profile_contract_information')}</StyledTitle>
      </FormTextWrapper>
      <FormFieldsWrapper>
        {userData?.KYC?.fullName && (
          <StyledRow>
            <StyledSubTitle>
              {t('profile_contract_fullName_label')}
            </StyledSubTitle>
            <StyledText>{userData?.KYC?.fullName}</StyledText>
          </StyledRow>
        )}
        {userData?.KYC?.title && (
          <StyledRow>
            <StyledSubTitle>{t('profile_contract_title_label')}</StyledSubTitle>
            <StyledText>{userData?.KYC?.title}</StyledText>
          </StyledRow>
        )}
        {userData?.KYC?.documentNumber && (
          <StyledRow>
            <StyledSubTitle>
              {t('profile_contract_documentNumber_label')}
            </StyledSubTitle>
            <StyledText>{userData?.KYC?.documentNumber}</StyledText>
          </StyledRow>
        )}
        {userData?.KYC?.nationality && (
          <StyledRow>
            <StyledSubTitle>
              {t('profile_contract_documentNumber_label')}
            </StyledSubTitle>
            <StyledText>{userData?.KYC?.nationality}</StyledText>
          </StyledRow>
        )}

        <StyledRow>
          <StyledSubTitle>{t('profile_contract_email_label')}</StyledSubTitle>
          <StyledText>{userData?.email}</StyledText>
        </StyledRow>

        <StyledRow>
          <StyledSubTitle>{t('profile_contract_country_label')}</StyledSubTitle>
          <StyledText>{userData?.KYC?.address?.country}</StyledText>
        </StyledRow>

        <StyledRow>
          <StyledSubTitle>{t('profile_contract_city_label')}</StyledSubTitle>
          <StyledText>{userData?.KYC?.address?.city}</StyledText>
        </StyledRow>

        <StyledRow>
          <StyledSubTitle>{t('profile_contract_address_label')}</StyledSubTitle>
          <StyledText>{userData?.KYC?.address?.address}</StyledText>
        </StyledRow>

        <StyledRow>
          <StyledSubTitle>
            {t('profile_contract_registration_number')}
          </StyledSubTitle>
          <StyledText>{userData?.KYC?.registrationNumber}</StyledText>
        </StyledRow>
      </FormFieldsWrapper>
    </StyledFormWrapper>
  );
};

export default ContractInfo;
