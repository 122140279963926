import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout } from '@/ui';

export const StyledFormWrapper = styled(BlockLayout)`
  align-items: flex-start;
  gap: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 8rem;
  }
`;

export const ChannelsSlidersWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ChannelsWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  div {
    margin-right: 1rem;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  ${pickThemeFontStyles('14', '22', 'NORMAL')};
  color: ${COLORS.GREY};
`;

export const StyledBadge = styled.div`
  display: flex;
  max-width: 10rem;
  border-radius: 1rem;
  padding: 0.5rem;
  ${pickThemeFontStyles('10', '16', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  background: ${COLORS.SOLITUDE_GRAY};
`;

export const StyledInfoWrapper = styled(BlockLayout)`
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

export const StyledChannelFrame = styled.div<{ $isActive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid
    ${({ $isActive }) => ($isActive ? COLORS.GREEN_MINT : COLORS.LIGHTEST_GRAY)};
  background: ${({ $isActive }) =>
    $isActive ? COLORS.GREEN_LIGHT : COLORS.WHITE_SMOKE};
`;
