import { IContentDistributionPlan } from '@/models/campaigns.model';

export const getTotalMarketingDeliveredPosts = (
  contentDistribution?: IContentDistributionPlan[],
  channel?: string,
) => {
  return contentDistribution?.reduce(
    (acc, item) =>
      acc + (item?.channelsConfig[channel ?? '']?.deliveredPosts ?? 0),
    0,
  );
};
