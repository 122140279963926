export const MAX_IMAGE_SIZE = 10 * 1024 * 1024;
export const MAX_FILE_SIZE = 10 * 1024 * 1024;
export const MAX_RESOURCE_SIZE = 25 * 1024 * 1024;

export const IMAGE_FORMATS = ['png', 'jpeg', 'jpg'];
export const VIDEO_FORMATS = ['mp4', 'x-msvideo', 'x-flv', 'avi'];
export const ICON_FORMATS = ['png', 'jpeg', 'svg'];
export const FILE_FORMATS = [
  'pdf',
  'vnd.openxmlformats-officedocument.presentationml.presentation',
  'vnd.ms-powerpoint',
];

export const IMAGE_MIME_TYPES = IMAGE_FORMATS.map(
  (format) => `image/${format}`,
).join(',');

export const DOCS_MIME_TYPES = FILE_FORMATS.map(
  (format) => `application/${format}`,
).join(',');

export const ICON_MIME_TYPES = ['image/svg+xml', 'image/png'].join(',');
export const RESOURCES_MIME_TYPES = [
  'application/pdf',
  'application/pptx',
  'application/doc',
  'application/docx',
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'video/mp4',
  'video/avi',
  'video/x-msvideo',
  'video/x-flv',
].join(',');
