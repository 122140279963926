// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'antd/lib/form/Form';

// Components
import {
  FormItem,
  FormItemGroup,
  IFormInstance,
  NestedFormItem,
  PhoneInput,
  RadioTabs,
} from '@/components';
import {
  FormFields,
  FormFieldsWrapper,
  FormTextWrapper,
  StyledTitle,
  StyledFormWrapper,
} from './ContactInfoStyles';
import { StyledInput } from '@/ui';

// Models
import { ContactSourceEnum } from '@/models/sharedProfile.model';

// Modules
import { ContactInfoFieldNames } from '@/modules/SharedProfile';

// Hooks | Helpers
import useGetFormValidationRules from '../../hooks/useGetFormValidationRules';
import { getContactTabsConfig } from './phoneTabsConfig';

interface IContactInfoBlock {
  formInstance: IFormInstance;
}

const ContactInfoBlock = ({ formInstance }: IContactInfoBlock) => {
  const { t } = useTranslation(['profile', 'common']);
  const validationRules = useGetFormValidationRules();
  const sourceValue = useWatch(
    [ContactInfoFieldNames.MAIN_CONTACT, ContactInfoFieldNames.SOURCE],
    formInstance,
  );
  const isPhoneField =
    sourceValue && sourceValue !== ContactSourceEnum.TELEGRAM;
  const contactLabel =
    !isPhoneField || !sourceValue
      ? 'profile_contact_block_telegram_label'
      : 'profile_contact_block_phone_label';

  return (
    <StyledFormWrapper>
      <FormTextWrapper>
        <StyledTitle>{t('profile_contact_block_title')}</StyledTitle>
      </FormTextWrapper>
      <FormFieldsWrapper>
        <FormItemGroup prefix={[ContactInfoFieldNames.MAIN_CONTACT]}>
          <FormFieldsWrapper>
            <FormFields>
              <NestedFormItem
                name={ContactInfoFieldNames.SOURCE}
                initialValue={ContactSourceEnum.TELEGRAM}
                rules={validationRules[ContactInfoFieldNames.SOURCE]}
              >
                <RadioTabs config={getContactTabsConfig(t)} />
              </NestedFormItem>
            </FormFields>
            <FormFields>
              <NestedFormItem
                name={ContactInfoFieldNames.CONTACT}
                rules={validationRules[ContactInfoFieldNames.CONTACT]}
                label={t(contactLabel)}
              >
                {isPhoneField ? (
                  <PhoneInput disableDialCodePrefill />
                ) : (
                  <StyledInput placeholder={t(contactLabel)} />
                )}
              </NestedFormItem>
            </FormFields>
          </FormFieldsWrapper>
        </FormItemGroup>

        <FormItem
          name={ContactInfoFieldNames.EMAIL}
          rules={validationRules[ContactInfoFieldNames.EMAIL]}
          label={t('profile_email_label')}
        >
          <StyledInput
            placeholder={t('profile_email_label')}
            type="email"
            disabled
          />
        </FormItem>
      </FormFieldsWrapper>
    </StyledFormWrapper>
  );
};

export default ContactInfoBlock;
