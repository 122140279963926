import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { DatePicker } from '@/ui';

export const StyledFormItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${COLORS.SOLITUDE_GRAY};

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const StyledDatePicker = styled(DatePicker)`
  background: ${COLORS.LIGHTEST_GRAY};
  height: 2.875rem;
  border: 0;
  ${pickThemeFontStyles('15', '15', 'SEMI_NORMAL')};
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledSharingUrlWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  ${pickThemeFontStyles('18', '18', 'SEMI_NORMAL')};

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;
