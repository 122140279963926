// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledFileIcon,
  DetailsBlock,
  StyledChannelsWrapper,
  StyledA,
  StyledChannels,
} from './ProjectDetailsTabStyles';

// Models
import { ICampaign } from '@/models/campaigns.model';

// Helpers
import { getChannelIcon } from '../../helpers';

interface ISocialsBlock {
  data: ICampaign | null;
}

const SocialsBlock = ({ data }: ISocialsBlock) => {
  const { t } = useTranslation(['campaigns']);

  const mappedChannels = useMemo(() => {
    return data?.projectSocialChannel?.map((item) => (
      <StyledChannelsWrapper key={item.name}>
        <a href={item.link} target="_blank" rel="noreferrer">
          {getChannelIcon(item.name)}
        </a>
      </StyledChannelsWrapper>
    ));
  }, [data]);

  return (
    <DetailsBlock $isRow>
      <StyledA href={data?.projectWebsite} target="_blank">
        {t('campaign_details_website')}
      </StyledA>
      {data?.projectWhitepaper && (
        <StyledA href={data.projectWhitepaper} target="_blank">
          <StyledFileIcon />
          {t('campaign_details_whitepaper')}
        </StyledA>
      )}
      {!!mappedChannels && <StyledChannels>{mappedChannels}</StyledChannels>}
    </DetailsBlock>
  );
};

export default SocialsBlock;
