import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';

export const DetailsWrapper = styled.div`
  display: flex;
  width: auto;
  background: ${COLORS.WHITE};
  margin-bottom: 0.5rem;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 1rem;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  margin-bottom: 1.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    padding: 1.688rem 1.5rem 2.125rem 1.5rem;
  }
`;

export const StyledSubTitle = styled.div`
  ${pickThemeFontStyles('14', '20', 'SEMI_BOLD')};
  color: ${COLORS.GREY};
`;

export const StyledValue = styled.div`
  &&& {
    text-align: start;
    ${pickThemeFontStyles('20', '28', 'BOLD')};
    color: ${COLORS.GREY};
  }
`;

export const StyledLabel = styled.div<{ $bold?: boolean }>`
  ${({ $bold }) =>
    pickThemeFontStyles('14', '20', $bold ? 'SEMI_BOLD' : 'SEMI_NORMAL')}
  color: ${COLORS.CORN_FLOWER_BLUE}
`;

export const DetailsBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;
  flex: 1;
  margin-top: 0.25rem;
  flex-wrap: wrap;
`;

export const OverviewCell = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledHeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid ${COLORS.SOLITUDE_GRAY};
  padding-top: 1rem;
`;

export const StyledChannels = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  cursor: auto;
  transform: scale(0.6);
`;

export const StyledChannelsWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
`;
