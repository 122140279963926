/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICommunity, IGeographies } from '@/models/dataLists.model';
import { IProfileCommunity } from '@/models/kolsList.model';

export const getSelectOptions = (arr: any[]) => {
  const options = arr.map((item: any) => {
    if (typeof item === 'string') {
      return {
        label: item,
        value: item,
      };
    } else {
      return {
        label: item.value,
        value: item.value,
        disabled: item.disabled,
      };
    }
  });
  return options.sort?.((a, b) => a.label.localeCompare(b.label));
};

export const getCommunityOptions = (
  arr: ICommunity[] | IGeographies[],
  current: unknown,
) => {
  const options = arr
    .map((item: ICommunity | IGeographies) => ({
      label: item.value,
      value: item.value,
      id: item.id,
      disabled:
        ((current as IProfileCommunity | IGeographies)?.value || current) ===
        item.value,
    }))
    .sort?.((a, b) => a.label.localeCompare(b.label));
  return options;
};
