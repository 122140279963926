import dayjs from 'dayjs';
import { isDateInRange } from '@/utils';
import { IContentDistributionPlan } from '@/models/campaigns.model';

export const getMarketingDeliveryWeek = (
  filterBy: string,
  plan?: IContentDistributionPlan[],
) => {
  const chosenWeekNumber =
    filterBy === 'Total' ? undefined : parseInt(filterBy);
  return plan?.find((week) => {
    if (filterBy === 'Current Week') {
      return isDateInRange(
        dayjs(),
        dayjs(week?.startDate),
        dayjs(week?.endDate),
      );
    }
    return week.weekNumber === chosenWeekNumber;
  });
};
