import styled from 'styled-components';
import { BlockLayout } from '@/ui';
import { COLORS, pickThemeFontStyles, DEVICES } from '@/theme';

export const ReportOverviewWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 1.5rem;
`;

export const BlockTitle = styled.div`
  ${pickThemeFontStyles('18', '22', 'BOLD')};
  color: ${COLORS.LIQUORICE_BLACK};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;

  :first-child {
    align-items: flex-start;
  }

  :nth-last-child(2) {
    align-items: flex-start;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    :last-child {
      align-items: center;
    }

    :nth-last-child(2) {
      align-items: center;
    }
  }
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;
