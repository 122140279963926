// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledParticipationWrapper,
  StyledInfo,
  StyledInvestmentWrap,
  StyledTitle,
} from './ProjectParticipationCardStyles';
import { StatusIconBlock } from '@/components';
import { Text } from '@/ui';

// Modules
import { MobileOnly, DesktopOnly } from '@/modules/Adaptive';

// Utils
import { getDateString, numberWithCommas } from '@/utils';

// Models
import { IKOLInvestment } from '@/models/kolsList.model';

interface IProjectParticipation {
  numbering: number;
  participation: IKOLInvestment;
}

const ProjectParticipationCard = ({
  participation,
  numbering,
}: IProjectParticipation) => {
  const { t } = useTranslation(['kolsList']);

  return (
    <StyledParticipationWrapper>
      <MobileOnly>
        <StyledTitle>
          {t(`investment_status_${participation.investmentStatus}`, {
            ns: 'common',
          })}
        </StyledTitle>
      </MobileOnly>
      <StyledInfo>
        <DesktopOnly>
          <Text>{numbering}</Text>
        </DesktopOnly>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>
            {t('kols_project_participation_project_title')}
          </StyledTitle>
        </MobileOnly>
        <Text>{participation.title}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>
            {t('kols_project_participation_date_title')}
          </StyledTitle>
        </MobileOnly>
        <Text>{getDateString(participation.modified)}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>
            {t('kols_project_participation_money_title')}
          </StyledTitle>
        </MobileOnly>
        <StyledInvestmentWrap>
          <Text>{`$${numberWithCommas(participation.investedAmount)}`}</Text>
          <Text>
            {`${numberWithCommas(participation.investedTokensForMoney)}`}
          </Text>
        </StyledInvestmentWrap>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>
            {t('kols_project_participation_marketing_title')}
          </StyledTitle>
        </MobileOnly>
        <StyledInvestmentWrap>
          <Text>
            {`$${numberWithCommas(participation.investedForMarketing)}`}
          </Text>
          <Text>
            {`${numberWithCommas(
              participation.investedTokensAmount -
                participation.investedTokensForMoney,
            )}`}
          </Text>
        </StyledInvestmentWrap>
      </StyledInfo>
      <StyledInfo>
        <StatusIconBlock status={participation.investmentStatus} />
      </StyledInfo>
    </StyledParticipationWrapper>
  );
};

export default ProjectParticipationCard;
