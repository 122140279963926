// Globals
import React from 'react';
import { StepsProps } from 'antd';

// Components
import { StyledSteps } from './StepsStyles';

const StepsProgress = ({ ...props }: StepsProps) => {
  return <StyledSteps {...props} />;
};

export default StepsProgress;
