import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { ADMINS_LIST_SLICE_NAME, AdminsListState } from './models';

export const adminsListSliceSelector = (state: RootState): AdminsListState =>
  state[ADMINS_LIST_SLICE_NAME];

export const selectAdminsListIsLoading = createSelector(
  adminsListSliceSelector,
  (state: AdminsListState) => state.isLoading,
);
