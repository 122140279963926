// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  ProjectCardWrapper,
  StyledInfoWrapper,
  StyledTitle,
  StyledText,
  StyledLinkWrapper,
} from './ProjectCardStyles';
import { ContactBar, CountBadge } from '@/components';
import { Link } from '@/ui';
import { ArrowRightIcon } from '@/icons';

// Models
import { IProject } from '@/models/projects.model';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Helpers
import { ProjectTabKeys } from '../../constants';
import { ROUTES_PATHS } from '@/router';

interface IProjectCard {
  project: IProject;
}

const ProjectCard = ({ project }: IProjectCard) => {
  const { t } = useTranslation(['projectsList']);

  return (
    <ProjectCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('projects_list_project_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{project?.title}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('projects_list_contact_title')}</StyledTitle>
        </MobileOnly>
        <StyledLinkWrapper>
          <ContactBar data={project} />
        </StyledLinkWrapper>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('projects_list_contact_person_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{`${project?.firstName} ${project?.lastName}`}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('projects_list_community_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{project?.mainCommunity?.value}</StyledText>
        <StyledText>{project?.secondaryCommunity?.value}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('projects_list_geography_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{project?.mainGeography?.value}</StyledText>
        <StyledText>{project?.secondaryGeography?.value}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('projects_list_status_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{project?.status}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('projects_list_kyb_status_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{project?.KYB?.state ?? '-'}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('projects_list_company_name_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{project?.companyInfo?.companyName}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <StyledText>
          <Link
            to={`${ROUTES_PATHS.PROJECTS_LIST}/${encodeURIComponent(
              project?.id ?? '',
            )}/${ProjectTabKeys.COMPANY_INFO}`}
          >
            <ArrowRightIcon />
          </Link>
          <CountBadge count={project?.unreadRequestsCount ?? 0} />
        </StyledText>
      </StyledInfoWrapper>
    </ProjectCardWrapper>
  );
};

export default ProjectCard;
