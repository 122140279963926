import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Link } from '@/ui';

export const StyledSocialChannelWrapper = styled(BlockLayout)<{
  $warning: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.5rem;
  padding: 1rem;
  border: ${({ $warning }) => $warning && `1px solid ${COLORS.PINK_SALMON}`};
`;

export const StyledInfo = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  :first-child {
    justify-content: flex-start;
    svg {
      width: 1.5rem;
      transform: scale(0.7);
    }
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'BOLD')};
  }
`;

export const StyledNumbers = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'BOLD')};
  }
`;

export const StyledLastUpdated = styled(StyledNumbers)<{ $old?: boolean }>`
  color: ${({ $old }) => ($old ? COLORS.RED : COLORS.CORN_FLOWER_BLUE)};
`;

export const StyledChannelWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;
