import { IApiError } from '@/models/apiError.model';

export const DATA_EXPORTS_SLICE_NAME = 'dataExports';

export interface DataExportsState {
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: DataExportsState = {
  isLoading: false,
  error: null,
};
