// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  RequestsListWrapper,
  StyledTitles,
  StyledInfoTitles,
  StyledFilterWrapper,
  StyledBox,
  StyledSelect,
} from './RequestsListStyles';
import { DesktopOnly } from '@/modules/Adaptive';
import { SearchInput } from '@/components';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import RequestCard from '../RequestCard/RequestCard';
import RequestsFilter from '../RequestsFilter/RequestsFilter';

// Models
import { IRequest, RequestStatuses } from '@/models/requests.model';

// Helpers
import { useAppDispatch, useInfiniteScroll } from '@/hooks';
import { setRequestsFilters } from '../../feature/slice';
import {
  DateSortKeys,
  sortByDateOptions,
  sortByUserTypeOptions,
} from '../../constants';

interface INewRequests {
  requests: {
    items: IRequest[] | null;
    hasMore: boolean;
  };
  isLoading: boolean;
  getNextRequests: () => void;
  status?: RequestStatuses;
}

const RequestsList = ({
  requests,
  isLoading,
  getNextRequests,
  status,
}: INewRequests) => {
  const { t } = useTranslation(['requestsList']);
  const dispatch = useAppDispatch();
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedNewRequestsList = useMemo(() => {
    return requests?.items?.map((requestsItem, i) => {
      const numbering = i + 1;
      return (
        <RequestCard
          request={requestsItem}
          key={requestsItem.id}
          numbering={numbering}
          status={status}
        />
      );
    });
  }, [requests]);

  useInfiniteScroll({
    callback: getNextRequests,
    ref: loaderRef,
    isLoading,
    hasMore: requests?.hasMore,
  });

  const handleSearch = (value: string) => {
    dispatch(
      setRequestsFilters({
        search: value ? value : undefined,
        status: status,
      }),
    );
  };

  const handleDateFilter = (v: unknown) => {
    dispatch(
      setRequestsFilters({
        sortByDate: v === DateSortKeys.LATEST_TOP ? true : undefined,
      }),
    );
  };

  const handleUserTypeFilter = (v: unknown) => {
    dispatch(
      setRequestsFilters({
        userType: v,
      }),
    );
  };

  return (
    <RequestsListWrapper>
      <StyledFilterWrapper>
        <SearchInput handleChange={handleSearch} resetValue={status} />
        <StyledBox>
          <StyledSelect
            options={sortByUserTypeOptions}
            onChange={handleUserTypeFilter}
            placeholder={t('requests_list_filters_filter_by_user_type')}
            allowClear
          />
          <StyledSelect
            options={sortByDateOptions}
            onChange={handleDateFilter}
            placeholder={t('requests_list_filters_sort_by_date')}
            allowClear
          />
          <RequestsFilter status={status} />
        </StyledBox>
      </StyledFilterWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('requests_list_id_title')}</StyledTitles>
          <StyledTitles>{t('requests_list_date_title')}</StyledTitles>
          <StyledTitles>{t('requests_list_name_title')}</StyledTitles>
          <StyledTitles>{t('requests_list_user_type')}</StyledTitles>
          <StyledTitles>{t('requests_list_responsible')}</StyledTitles>
          {status === RequestStatuses.IN_PROGRESS && (
            <StyledTitles>{t('requests_list_status')}</StyledTitles>
          )}
          <StyledTitles>{t('requests_list_type_title')}</StyledTitles>
          <StyledTitles>{t('requests_list_action_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedNewRequestsList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </RequestsListWrapper>
  );
};

export default RequestsList;
