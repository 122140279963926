import {
  ICreateClickWrapTemplateDTO,
  ICreateClickWrapTemplateValues,
} from '@/models/documents.model';

class CreateClickWrapTemplateDTO implements ICreateClickWrapTemplateDTO {
  htmlContent;
  templateType;

  constructor(values: ICreateClickWrapTemplateValues) {
    this.htmlContent = values.htmlContent;
    this.templateType = values.templateType;
  }
}

export default CreateClickWrapTemplateDTO;
