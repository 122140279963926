// Global
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component
import { PendingIcon, SuccessCircleIcon, XMarkCircleIcon } from '@/icons';
import {
  StyledText,
  StyledStatusBlockWrap,
} from './CompanyInfoDetailsBlockStyles';

// Helpers
import { KYCStatuses } from '@/constants';

interface IKYCStatus {
  status?: KYCStatuses | null;
}

const KYBStatus = ({ status }: IKYCStatus) => {
  const { t } = useTranslation(['kolsList']);

  switch (status) {
    case KYCStatuses.VERIFIED:
      return (
        <StyledStatusBlockWrap>
          <StyledText>{t('kols_general_info_KYC_verified')}</StyledText>
          <SuccessCircleIcon />
        </StyledStatusBlockWrap>
      );
    case KYCStatuses.CANCELED:
      return (
        <StyledStatusBlockWrap>
          <StyledText>{t('kols_general_info_KYC_canceled')}</StyledText>
          <XMarkCircleIcon />
        </StyledStatusBlockWrap>
      );
    case KYCStatuses.FINAL_REJECTED:
      return (
        <StyledStatusBlockWrap>
          <StyledText>{t('kols_general_info_KYC_final_rejected')}</StyledText>
          <XMarkCircleIcon />
        </StyledStatusBlockWrap>
      );
    case KYCStatuses.PENDING:
    default:
      return (
        <StyledStatusBlockWrap>
          <StyledText>{t('kols_general_info_KYC_pending')}</StyledText>
          <PendingIcon />
        </StyledStatusBlockWrap>
      );
  }
};

export default KYBStatus;
