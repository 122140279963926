import styled from 'styled-components';
import {
  COLORS,
  DEVICES,
  pickThemeFontSize,
  pickThemeFontStyles,
} from '@/theme';

export const StyledFormItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${COLORS.SOLITUDE_GRAY};

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
`;

export const StyledResources = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  flex-direction: column;
`;

export const StyledUploadFilePlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${pickThemeFontStyles('15', '15', 'SEMI_NORMAL')};
  color: ${COLORS.SOFT_GRAY};
  background: ${COLORS.WHITE};
  border: 1px dashed ${COLORS.SOFT_GRAY};
  border-radius: 0.25rem;
  width: 100%;
  height: 2.75rem;
  padding: 1rem;

  svg {
    margin-right: 0.75rem;
  }
`;

export const ResourcesContainer = styled.div`
  max-width: 10rem;
  position: relative;
`;

export const StyledIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0rem;
  top: 0rem;
  z-index: 10;
`;

export const StyledDownloadButton = styled.a`
  svg {
    margin: 20% 0 0 35%;
    font-size: ${pickThemeFontSize('40')};
    transform: scale(1);
  }
`;

export const StyledName = styled.div`
  text-overflow: ellipsis;
  max-width: 8rem;
  overflow: hidden;
  white-space: nowrap;
  ${pickThemeFontStyles('14', '22', 'NORMAL')};
  color: ${COLORS.GREY};
  margin: 1rem 0 0 0;
`;

export const StyledDocumentWrapper = styled.div`
  position: relative;
  width: 6rem;
  height: 6rem;
`;

export const ResourcesWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1.5rem;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;

  @media screen and ${DEVICES.MOBILE_XL} {
    flex-direction: row;
  }
`;
