import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout } from '@/ui';
import { WithdrawalHistoryStatuses } from '@/models/sharedProfile.model';

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  :last-child {
    align-items: center;
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  width: 100%;
  flex-direction: column;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  :last-child {
    align-items: center;
  }
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const CardWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const DeliveryWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
`;

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
`;

export const StyledDeliveryTitle = styled.div`
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  margin-bottom: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('16', '25', 'BOLD')};
    margin-bottom: 1rem;
  }
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  gap: 0.5rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledBadge = styled.div<{ $status?: WithdrawalHistoryStatuses }>`
  display: flex;
  width: 70%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  ${pickThemeFontStyles('14', '16', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  ${({ $status }) => {
    switch ($status) {
      case WithdrawalHistoryStatuses.PENDING:
        return `background: ${COLORS.CHABLIS_PEACH};`;
      case WithdrawalHistoryStatuses.APPROVED:
        return `background: ${COLORS.GREEN_BUBBLES};`;
      default:
        return `background: ${COLORS.MISTY_ROSE};`;
    }
  }}
`;
