import { ProjectsPhase } from '@/models/projects.model';

export const getProjectsPhase = (phase?: ProjectsPhase) => {
  switch (phase) {
    case ProjectsPhase.INVESTMENT_STARTED:
      return 'Investment Started';
    case ProjectsPhase.INVESTMENT_CLOSED:
      return 'Investment Closed';
    case ProjectsPhase.FINALIZED:
      return 'Finalized';
    default:
      return '';
  }
};

export const getProjectsPhaseKey = (phase?: string) => {
  switch (phase) {
    case 'Investment Started':
      return ProjectsPhase.INVESTMENT_STARTED;
    case 'Investment Closed':
      return ProjectsPhase.INVESTMENT_CLOSED;
    case 'Finalized':
      return ProjectsPhase.FINALIZED;
    default:
      return '';
  }
};
