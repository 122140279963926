/* eslint @typescript-eslint/no-explicit-any: 0 */
// Globals
import React, { LegacyRef, useCallback, useRef } from 'react';
import ReactQuill, { ReactQuillProps, UnprivilegedEditor } from 'react-quill';
import { DeltaStatic, Sources } from 'quill';
import 'react-quill/dist/quill.snow.css';

// Helpers
import { formats, imageWithVideoModules } from './config';

interface ITextEditorWithMedia extends ReactQuillProps {
  handleFileUpload: (value: File) => Promise<any>;
}

const TextEditorWithMedia = ({
  onChange,
  handleFileUpload,
  ...props
}: ITextEditorWithMedia) => {
  const reactQuillRef: LegacyRef<ReactQuill> | undefined = useRef(null);

  const handleChange = (
    value: string,
    delta: DeltaStatic,
    source: Sources,
    editor: UnprivilegedEditor,
  ) => {
    if (source === 'user') {
      onChange && onChange(value, delta, source, editor);
    }
  };

  const imageHandler = useCallback(async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      if (!input.files) return;
      const file = input.files[0];

      const handledValue = await handleFileUpload(file);

      if (!reactQuillRef?.current) return;
      const quillEditor = reactQuillRef.current.getEditor();
      const range = quillEditor.getSelection(true).index;
      quillEditor.insertEmbed(range, 'image', handledValue);
    };
  }, []);

  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: imageWithVideoModules,
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    [imageHandler],
  );

  return (
    <ReactQuill
      {...props}
      theme="snow"
      ref={reactQuillRef}
      onChange={handleChange}
      onBlur={() => {}}
      modules={modules}
      formats={formats}
    />
  );
};

export default TextEditorWithMedia;
