// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  DetailsWrapper,
  StyledInfoBlock,
  StyledBlockTitle,
  StyledTitle,
  StyledText,
  StyledDangerousHtml,
} from './FundingDetailsStyles';
import AdditionalResources from './AdditionalResources';
import { Table } from '@/components';

// Models
import { IFundingRound } from '@/models/fundingRound.model';

// Helpers
import { getTokenomicsTableData, getSeedHunterTableData } from '../../helpers';
import { columnsConfig } from '../../constants';

interface IFundingDetails {
  fundingRound: IFundingRound;
}

const FundingDetails = ({ fundingRound }: IFundingDetails) => {
  const { t } = useTranslation(['fundingRound']);

  return (
    <DetailsWrapper>
      <StyledInfoBlock>
        <StyledBlockTitle>
          <StyledTitle $isColored>
            {t('funding_answers_elevatorPitch_title')}
          </StyledTitle>
          <StyledTitle>{t('funding_overview_about_project')}</StyledTitle>
        </StyledBlockTitle>
        <StyledDangerousHtml
          dangerouslySetInnerHTML={{
            __html: fundingRound?.description
              ? String(fundingRound?.description)
              : '',
          }}
        />
      </StyledInfoBlock>

      {fundingRound?.usps && (
        <StyledInfoBlock>
          <StyledBlockTitle>
            <StyledTitle $isColored>
              {t('funding_details_presentation_usps')}
            </StyledTitle>
            <StyledTitle>
              {t('funding_details_presentation_usps_description')}
            </StyledTitle>
          </StyledBlockTitle>
          <StyledDangerousHtml
            dangerouslySetInnerHTML={{
              __html: fundingRound?.usps ? String(fundingRound?.usps) : '',
            }}
          />
        </StyledInfoBlock>
      )}

      <StyledInfoBlock>
        <StyledBlockTitle>
          <StyledText $isBold>{t('funding_overview_tokenomics')}</StyledText>
          <StyledText $isBold>
            {t('funding_overview_tokenomics_description')}
          </StyledText>
        </StyledBlockTitle>
        <StyledText>{t('funding_overview_total_rise')}</StyledText>
        <Table
          columns={columnsConfig}
          dataSource={getTokenomicsTableData(fundingRound, t)}
          showHeader={false}
        />
        <StyledText>{t('funding_overview_seedhunter_funding')}</StyledText>
        <Table
          columns={columnsConfig}
          dataSource={getSeedHunterTableData(fundingRound, t)}
          showHeader={false}
        />
        <StyledDangerousHtml
          dangerouslySetInnerHTML={{
            __html: fundingRound?.tokenMetrics
              ? String(fundingRound?.tokenMetrics)
              : '',
          }}
        />
      </StyledInfoBlock>

      <StyledInfoBlock>
        <StyledBlockTitle>
          <StyledTitle $isColored>
            {t('funding_answers_marketingStrategy_title')}
          </StyledTitle>
          <StyledTitle>{t('funding_overview_product_known')}</StyledTitle>
        </StyledBlockTitle>
        <StyledDangerousHtml
          dangerouslySetInnerHTML={{
            __html: fundingRound?.marketingStrategy
              ? String(fundingRound?.marketingStrategy)
              : '',
          }}
        />
      </StyledInfoBlock>

      <StyledInfoBlock>
        <StyledBlockTitle>
          <StyledTitle $isColored>
            {t('funding_details_teams_title')}
          </StyledTitle>
          <StyledTitle>
            {t('funding_details_presentation_who_involved')}
          </StyledTitle>
        </StyledBlockTitle>
        <StyledDangerousHtml
          dangerouslySetInnerHTML={{
            __html: fundingRound?.involved
              ? String(fundingRound?.involved)
              : '',
          }}
        />
      </StyledInfoBlock>

      <StyledInfoBlock>
        <StyledBlockTitle>
          <StyledTitle $isColored>
            {t('funding_details_presentation_roadmap')}
          </StyledTitle>
          <StyledTitle>{t('funding_details_roadmap_description')}</StyledTitle>
        </StyledBlockTitle>
        <StyledDangerousHtml
          dangerouslySetInnerHTML={{
            __html: fundingRound?.roadMap ? String(fundingRound?.roadMap) : '',
          }}
        />
      </StyledInfoBlock>

      <StyledInfoBlock>
        <StyledBlockTitle>
          <StyledTitle $isColored>
            {t('funding_answers_additionalDocumentation_title')}
          </StyledTitle>
          <StyledTitle>
            {t('funding_answers_documents_description')}
          </StyledTitle>
        </StyledBlockTitle>
        <AdditionalResources fundingRound={fundingRound} />
      </StyledInfoBlock>
    </DetailsWrapper>
  );
};

export default FundingDetails;
