// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  ListWrapper,
  StyledTitles,
  StyledInfoTitles,
} from './ParticipationStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import ParticipantCard from './ParticipantCard';

// Models
import { IParticipationItems } from '@/models/campaigns.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Hooks
import { useInfiniteScroll } from '@/hooks';

interface ICampaignParticipantsList {
  isLoading: boolean;
  participants: {
    items: IParticipationItems[] | null;
    hasMore: boolean;
  };
  getNextParticipant: () => void;
}

const Participation = ({
  isLoading,
  participants,
  getNextParticipant,
}: ICampaignParticipantsList) => {
  const { t } = useTranslation(['campaigns']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedParticipantsList = useMemo(() => {
    return participants?.items?.map((participant) => {
      return <ParticipantCard participant={participant} key={participant.id} />;
    });
  }, [participants]);

  useInfiniteScroll({
    callback: getNextParticipant,
    ref: loaderRef,
    isLoading,
    hasMore: !!participants?.hasMore,
  });

  return (
    <ListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>
            {t('campaign_participants_alias_community_title')}
          </StyledTitles>
          <StyledTitles>{t('campaign_participants_date_title')}</StyledTitles>
          <StyledTitles>{t('campaign_participants_payout_title')}</StyledTitles>
          <StyledTitles>
            {t('campaign_participants_social_channels_title')}
          </StyledTitles>
          <StyledTitles>
            {t('campaign_participants_document_title')}
          </StyledTitles>
          <StyledTitles>{t('campaigns_status_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedParticipantsList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </ListWrapper>
  );
};

export default Participation;
