import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { REQUESTS_DETAILS_SLICE_NAME } from './models';
import { RequestsAPI } from '@/api';
import { formatApiError } from '@/utils';
import {
  IPatchPostsContestStatusDTO,
  IPatchRequestStatusDTO,
  IRequestDetailsResponse,
} from '@/models/requests.model';
import { TFormatResponse } from 'src/api/httpClient';

export const getRequestDetails = createAsyncThunk<
  IRequestDetailsResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${REQUESTS_DETAILS_SLICE_NAME}/getRequestDetails`,
  async (id: string) => {
    const response = await RequestsAPI.getRequestDetails(id);
    return response;
  },
  { serializeError: formatApiError },
);

export const patchRequestStatus = createAsyncThunk<
  Promise<TFormatResponse>,
  IPatchRequestStatusDTO,
  { serializedErrorType: IApiError }
>(
  `${REQUESTS_DETAILS_SLICE_NAME}/patchRequestStatus`,
  async (data) => {
    const response = await RequestsAPI.patchRequestStatus(data);
    return response;
  },
  { serializeError: formatApiError },
);

export const patchPostsContestStatus = createAsyncThunk<
  Promise<TFormatResponse>,
  IPatchPostsContestStatusDTO,
  { serializedErrorType: IApiError }
>(
  `${REQUESTS_DETAILS_SLICE_NAME}/patchPostsContestStatus`,
  async (data) => {
    const response = await RequestsAPI.patchPostsContestStatus(data);
    return response;
  },
  { serializeError: formatApiError },
);
