import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { Input, Select } from '@/ui';

export const StyledInput = styled(Input)`
  &&& {
    background: ${COLORS.WHITE};
    max-width: 11rem;
    height: 2.25rem;
    border: 0;
    box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08),
      0px 0px 1px rgba(50, 50, 71, 0.2);
    border-radius: 6px;

    & .ant-input-prefix {
      color: ${COLORS.TUNDORA};
    }

    & .ant-input-clear-icon {
      color: ${COLORS.TUNDORA};
    }

    &:hover {
      background: ${COLORS.WHITE};
      box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08),
        0px 0px 1px rgba(50, 50, 71, 0.2);
    }

    & .ant-input-prefix svg {
      margin: 0.2rem 0 0 1rem !important;
    }

    &&&.ant-input-affix-wrapper .ant-input-suffix {
      position: relative;
      top: 0;
      bottom: 0;
    }

    input {
      padding: 0.625rem;
      &::placeholder {
        ${pickThemeFontStyles('13', '13', 'SEMI_NORMAL')};
        color: ${COLORS.TUNDORA};
      }
    }

    &&& .ant-input-affix-wrapper .ant-input-suffix {
      position: relative;
      top: 0;
      bottom: 0;
    }
  }
`;

export const StyledSelect = styled(Select)`
  &.ant-select {
    &&& .ant-select-arrow {
      display: none;
    }

    &-single {
      & .ant-select-selector {
        background: ${COLORS.LIGHTEST_GRAY};
        height: 2.875rem;

        & .ant-select-selection-placeholder {
          ${pickThemeFontStyles('14', '14', 'SEMI_NORMAL')};
          margin: auto;
        }

        & .ant-select-selection-item {
          width: 1rem;
          ${pickThemeFontStyles('14', '16', 'SEMI_NORMAL')};
          margin: auto;
        }
      }
    }

    &&& input {
      height: 100%;
    }
  }
`;
