// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { PasswordInput } from '@/ui';
import { Form, FormItem } from '@/components';
import { StyledButton, StyledTitle } from '../Styled';

// Helpers
import {
  AuthFieldsNames,
  TCreateNewPasswordFormValues,
} from '../../helpers/types';
import { useGetFormValidationRules } from '../../hooks';

interface INewPasswordForm {
  handleSubmit: (values: TCreateNewPasswordFormValues) => void;
  isLoading: boolean;
}

const NewPasswordForm = ({ handleSubmit, isLoading }: INewPasswordForm) => {
  const { t } = useTranslation(['auth', 'common']);

  const validationRules = useGetFormValidationRules();

  const placeholder = t('common_placeholder_input', { ns: 'common' });

  return (
    <>
      <StyledTitle>{t('auth_create_new_password_title')}</StyledTitle>

      <Form onFinish={handleSubmit}>
        <FormItem
          name={AuthFieldsNames.NEW_PASSWORD}
          rules={validationRules[AuthFieldsNames.NEW_PASSWORD]}
          label={t('auth_password_label')}
        >
          <PasswordInput placeholder={placeholder} />
        </FormItem>

        <FormItem
          name={AuthFieldsNames.CONFIRM_PASSWORD}
          dependencies={[AuthFieldsNames.NEW_PASSWORD]}
          label={t('auth_confirm_password')}
          rules={[
            ...validationRules[AuthFieldsNames.NEW_PASSWORD],
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  getFieldValue(AuthFieldsNames.NEW_PASSWORD) === value
                ) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  t('validation_message_confirm_password', { ns: 'common' }),
                );
              },
            }),
          ]}
        >
          <PasswordInput placeholder={placeholder} />
        </FormItem>

        <StyledButton type="primary" htmlType="submit" loading={isLoading}>
          {t('submit', { ns: 'common' })}
        </StyledButton>
      </Form>
    </>
  );
};

export default NewPasswordForm;
