import omit from 'lodash.omit';
import { ApiResponse, httpGet, httpPost } from './httpClient';
import {
  ProjectsEndpoints,
  IGetProjectsListDTO,
  IProjectsListResponse,
  IProject,
  IUpdateProjectDetailsDTO,
  IGetContractInfoDTO,
  IContractInfoResponse,
  IContractInfoPreviewResponse,
  IGetProjectAffiliateDTO,
  IGetProjectAffiliateResponse,
  ICreateProjectWalletDTO,
  ICreateProjectWalletResponse,
  IContractInfoDTO,
  IGetKolsRankingDataDTO,
} from '@/models/projects.model';
import {
  IGetInvitedUsersDTO,
  IGetInvitedUsersResponse,
  IGetKolsRankingResponse,
} from '@/models/sharedProfile.model';

class ProjectsAPI {
  static getProjectsList(
    data: IGetProjectsListDTO,
  ): ApiResponse<IProjectsListResponse> {
    return httpGet<IProjectsListResponse>(ProjectsEndpoints.GET_PROJECTS, {
      ...data,
    });
  }

  static getProjectDetails(id: string): ApiResponse<IProject> {
    return httpGet<IProject>(
      `${ProjectsEndpoints.GET_PROJECTS}/${encodeURI(id)}`,
    );
  }

  static updateProjectDetails(data: IUpdateProjectDetailsDTO) {
    return httpPost<IUpdateProjectDetailsDTO, IProject>(
      `${ProjectsEndpoints.GET_PROJECTS}/${encodeURI(data.id ?? '')}`,
      omit(data, 'id'),
    );
  }

  static startFundingRound(id: string) {
    return httpPost<string, IProject>(
      `${ProjectsEndpoints.GET_PROJECTS}/${encodeURI(id ?? '')}/funding-rounds`,
    );
  }

  static createProjectWallet(data: ICreateProjectWalletDTO) {
    return httpPost<ICreateProjectWalletDTO, ICreateProjectWalletResponse>(
      `${ProjectsEndpoints.GET_PROJECTS}/${encodeURI(
        data.id ?? '',
      )}/seed-hunter-wallet`,
    );
  }

  static getContractInfo(
    data: IGetContractInfoDTO,
  ): ApiResponse<IContractInfoResponse> {
    return httpGet<IContractInfoResponse>(
      `${ProjectsEndpoints.GET_PROJECTS}/${encodeURI(
        data?.projectId ?? '',
      )}/contract-information/${data?.documentType}`,
      omit(data, 'id'),
    );
  }

  static updateProjectContract(data: IContractInfoDTO) {
    return httpPost<IContractInfoDTO, IContractInfoResponse>(
      `${ProjectsEndpoints.GET_PROJECTS}/${data.id}/contract-information/${data.documentType}`,
      omit(data, ['documentType', 'id']),
    );
  }

  static getContractPreviewUrl(
    data: IGetContractInfoDTO,
  ): ApiResponse<IContractInfoPreviewResponse> {
    return httpGet<IContractInfoPreviewResponse>(
      `${ProjectsEndpoints.GET_PROJECTS}/${encodeURI(
        data?.projectId ?? '',
      )}/contract-information/${data?.documentType}/${
        data?.signingType
      }/preview`,
      omit(data, 'id'),
    );
  }

  static getProjectAffiliateData(
    data: IGetProjectAffiliateDTO,
  ): ApiResponse<IGetProjectAffiliateResponse> {
    return httpGet<IGetProjectAffiliateResponse>(
      `${ProjectsEndpoints.GET_PROJECTS}/${encodeURI(
        data?.projectId ?? '',
      )}/marketing-affiliate`,
      { ...data },
    );
  }

  static getKolsRankingData({
    projectId,
    ...data
  }: IGetKolsRankingDataDTO): ApiResponse<IGetKolsRankingResponse> {
    return httpGet<IGetKolsRankingResponse>(
      `${ProjectsEndpoints.GET_PROJECTS}/${encodeURI(
        projectId ?? '',
      )}/kols-ranking`,
      { ...data },
    );
  }

  static simulateKolsRanking({
    projectId,
  }: IGetKolsRankingDataDTO): ApiResponse<IGetKolsRankingResponse> {
    return httpPost<void, IGetKolsRankingResponse>(
      `${ProjectsEndpoints.GET_PROJECTS}/${encodeURI(
        projectId ?? '',
      )}/kols-ranking`,
    );
  }

  static getInvitedUsers({
    userId,
    ...data
  }: IGetInvitedUsersDTO): ApiResponse<IGetInvitedUsersResponse> {
    return httpGet<IGetInvitedUsersResponse>(
      `${ProjectsEndpoints.GET_PROJECTS}/${encodeURI(userId)}/invited-users`,
      { ...data },
    );
  }
}

export default ProjectsAPI;
