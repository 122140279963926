import styled, { css } from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { Button, Select, TextArea } from '@/ui';
import { AccountStatuses } from '@/constants';

const selectStyles = css<{ $status?: string | null }>`
  ${({ $status }) => {
    switch ($status) {
      case AccountStatuses.VERIFIED:
        return `background: ${COLORS.GREEN_BUBBLES};
          color: ${COLORS.GREEN_MINT};
          & .ant-select-selection-placeholder {
            color: ${COLORS.GREEN_MINT};
          }`;
      case AccountStatuses.IN_VERIFICATION:
      case AccountStatuses.PRE_VERIFIED:
      case AccountStatuses.PENDING:
        return `background: ${COLORS.CHABLIS_PEACH};
          color: ${COLORS.ATOMIC_TANGERINE};
          & .ant-select-selection-placeholder {
            color: ${COLORS.ATOMIC_TANGERINE};
          }`;
      case AccountStatuses.PAUSED:
      case AccountStatuses.NOT_VERIFIED:
        return `background: ${COLORS.CHIFFON};
          color: ${COLORS.NEON_CARROT};
          & .ant-select-selection-placeholder {
            color: ${COLORS.NEON_CARROT};
          }`;
      default:
        return `background: ${COLORS.MISTY_ROSE};
          background: ${COLORS.MISTY_ROSE};
          & .ant-select-selection-placeholder {
            color: ${COLORS.PICH_FROLY};
          }`;
    }
  }}
`;

export const StyledSelect = styled(Select)<{ $status?: string | null }>`
  &.ant-select {
    &.ant-select-disabled {
      border-radius: 0.5rem;
      ${selectStyles}
    }
    &-single {
      & .ant-select-selector {
        ${selectStyles};
        height: 2.5rem;
        border: 0;
        min-width: 10rem;

        & .ant-select-selection-search-input {
          height: 2rem;
        }
        & .ant-select-selection-placeholder {
          ${pickThemeFontStyles('14', '14', 'SEMI_NORMAL')};
          margin: auto;
        }
        & .ant-select-selection-item {
          width: 1rem;
          ${pickThemeFontStyles('14', '16', 'SEMI_NORMAL')};
          margin: auto;
        }
      }
    }
  }
`;

export const StyledTextArea = styled(TextArea)<{ $valid?: boolean }>`
  width: 100%;
  margin-top: 0.5rem;
  &&&.ant-input {
    border: 1.5px solid
      ${({ $valid }) => ($valid ? COLORS.LIGHTEST_GRAY : COLORS.RED)};
    ${pickThemeFontStyles('16', '26', 'NORMAL')};
  }
`;

export const StyledTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;

export const StyledButton = styled(Button)`
  &&& {
    margin-top: 1rem;
    max-width: 10rem;
    height: 3rem;
    ${pickThemeFontStyles('14', '18', 'SEMI_BOLD')};
    align-items: center;
  }
`;
