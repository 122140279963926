// Globals
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  ChannelsSlidersWrap,
  ChannelsWrap,
  StyledRow,
  StyledBadge,
  StyledInfoWrapper,
  StyledFormWrapper,
} from './SocialChannelsInvestmentStyles';
import { FormTextWrapper, StyledDescription, StyledTitle } from '../Styled';
import SocialChannelSlider from '../SocialChannelSlider/SocialChannelSlider';
import ChanelButton from './ChanelButton';

// Modules
import { socialChannelsList } from '@/modules/KolsList';

// Models
import { IAverageSocialData } from '@/models/settings.model';
import { SocialChannels } from '@/models/socialChannel.model';

// Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import { sortBy, sortSocialChannels } from '@/utils';
import { campaignsSliceSelector } from '../../feature/selectors';
import { showErrorToast } from '@/components';
import {
  addSocialChannel,
  changeChannelsBudget,
  removeSocialChannel,
} from '../../feature/slice';
import { getDefaultSocialChannel } from '../../helpers/socialChannels';

interface ISocialChannelsInvestment {
  disabled?: boolean;
  averageSocialData: IAverageSocialData | null;
  id?: string;
}

const SocialChannelsInvestment = ({
  disabled,
  averageSocialData,
}: ISocialChannelsInvestment) => {
  const { t } = useTranslation(['campaigns']);
  const dispatch = useAppDispatch();
  const willMountRef = useRef<boolean>(false);
  const { campaignDetails, socialChannels } = useAppState(
    campaignsSliceSelector,
  );

  const removeChannel = useCallback(
    (name: SocialChannels) => {
      if (disabled) return;

      if (!(socialChannels.length - 1)) {
        showErrorToast({
          message: t('campaign_error_channels_not_distributed'),
        });
      }
      dispatch(removeSocialChannel(name));
    },
    [socialChannels, disabled, dispatch, t],
  );

  const addChannel = useCallback(
    (name: SocialChannels) => {
      if (disabled) return;
      const plan = {
        ...getDefaultSocialChannel(name),
        percent: socialChannels?.length ? 0 : 100,
      };
      dispatch(addSocialChannel(plan));
    },
    [disabled, socialChannels?.length, dispatch],
  );

  // Lists render:
  const mappedChannels = useMemo(() => {
    return sortSocialChannels(socialChannelsList).map((id) => {
      const isActive = !!socialChannels?.some((item) => item.name === id);

      return (
        <ChanelButton
          name={id}
          key={id}
          remove={removeChannel}
          add={addChannel}
          isActive={isActive}
          isDisabled={false}
        />
      );
    });
  }, [addChannel, removeChannel, socialChannels]);

  const chanelSliders = useMemo(() => {
    return sortBy([...(socialChannels ?? [])], 'name')?.map((item) => (
      <SocialChannelSlider
        item={item}
        key={item.name}
        marketingBudget={campaignDetails?.marketingBudget}
        withLocks={socialChannels.length > 2}
        disabled={disabled}
        averageSocialData={averageSocialData}
      />
    ));
  }, [socialChannels, campaignDetails?.marketingBudget, disabled]);

  useEffect(() => {
    if (willMountRef.current) {
      dispatch(changeChannelsBudget());
      willMountRef.current = false;
    }
  }, [campaignDetails?.marketingBudget]);

  const postsTotal = useMemo(() => {
    return socialChannels?.reduce((acc, current) => {
      const amountInCurrency =
        (Number(campaignDetails?.marketingBudget) * (current?.percent ?? 0)) /
        100;
      const posts =
        amountInCurrency / (averageSocialData?.POST_PRICES[current.name] ?? 0);
      return acc + posts;
    }, 0);
  }, [campaignDetails?.marketingBudget, socialChannels, averageSocialData]);

  return (
    <>
      <StyledFormWrapper>
        <FormTextWrapper>
          <StyledTitle>
            {t('campaigns_marketing_preferences_title')}
          </StyledTitle>
          <StyledDescription>
            {t('campaigns_marketing_preferences_description')}
          </StyledDescription>
        </FormTextWrapper>
        <ChannelsWrap>{mappedChannels}</ChannelsWrap>
      </StyledFormWrapper>
      <StyledInfoWrapper>
        <FormTextWrapper>
          <StyledTitle>{t('campaigns_distribution_title')}</StyledTitle>
          <StyledRow>
            {t('campaigns_total_estimated_title')}
            <StyledBadge>
              {t('campaigns_posts_and_videos', {
                amount: Math.floor(postsTotal ?? 0),
              })}
            </StyledBadge>
          </StyledRow>
          <StyledDescription>
            {t('campaigns_distribution_description')}
          </StyledDescription>
        </FormTextWrapper>
        <ChannelsSlidersWrap>{chanelSliders}</ChannelsSlidersWrap>
      </StyledInfoWrapper>
    </>
  );
};

export default SocialChannelsInvestment;
