import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {
  REQUESTS_DETAILS_SLICE_NAME,
  RequestsDetailsState,
  initialState,
} from './models';
import {
  getRequestDetails,
  patchRequestStatus,
  patchPostsContestStatus,
} from './actionCreators';
import { showApiErrors } from '@/utils';

export const slice = createSlice({
  name: REQUESTS_DETAILS_SLICE_NAME,
  initialState,
  reducers: {
    resetRequestData(state) {
      state.currentRequest = initialState.currentRequest;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getRequestDetails.fulfilled,
        (state: RequestsDetailsState, action) => {
          state.currentRequest = action.payload.data;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isFulfilled(patchRequestStatus, patchPostsContestStatus),
        (state: RequestsDetailsState) => {
          state.isLoading = false;
        },
      )
      .addMatcher(
        isPending(
          getRequestDetails,
          patchRequestStatus,
          patchPostsContestStatus,
        ),
        (state: RequestsDetailsState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(
          getRequestDetails,
          patchRequestStatus,
          patchPostsContestStatus,
        ),
        (state: RequestsDetailsState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export const { resetRequestData } = slice.actions;

export default slice.reducer;
