// Globals
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

// Components
import { Form, IFormInstance, Modal, ModalSize } from '@/components';
import {
  StyledButton,
  StyledFieldWrap,
  StyledFormItem,
  StyledInfoWrap,
  StyledSelect,
} from './MarketingDeliveryInfoStyles';
import { Info, StyledDatePicker, StyledInput } from '@/ui';

// Models
import {
  IMarketingDeliveryInfo,
  IUpdateCampaignPostDTO,
} from '@/models/marketingDelivery.model';
import { IProject } from '@/models/projects.model';

// Hooks
import { AddCampaignPostsFieldsNames } from '../../types';
import { useGetCampaignPostValidationRules } from '../../hooks';
import { getSocialChannelOptions } from '../../helpers';

interface IAddCampaignPostModal {
  isOpened: boolean;
  handleClose: () => void;
  formInstance: IFormInstance;
  currentPost: IMarketingDeliveryInfo;
  projectData: IProject;
  handleSubmit: (values: IUpdateCampaignPostDTO) => void;
  optionCampaignKols?: {
    value: string;
    label: string;
  }[];
}

const AddCampaignPostModal = ({
  isOpened,
  handleClose,
  formInstance,
  handleSubmit,
  currentPost,
  optionCampaignKols,
  projectData,
}: IAddCampaignPostModal) => {
  const { t } = useTranslation(['projectsList']);
  const validationRules = useGetCampaignPostValidationRules();

  useEffect(() => {
    if (currentPost) {
      formInstance.setFieldsValue({
        ...currentPost,
        date: dayjs(currentPost?.dateOfPublish),
      });
    }
  }, [currentPost]);

  return (
    <Modal
      visible={isOpened}
      onCancel={handleClose}
      size={ModalSize.MEDIUM}
      destroyOnClose
    >
      <Form
        form={formInstance}
        initialValues={currentPost}
        onFinish={handleSubmit}
      >
        {!currentPost.link && (
          <StyledFormItem
            name={AddCampaignPostsFieldsNames.KOL_ID}
            rules={validationRules[AddCampaignPostsFieldsNames.KOL_ID]}
            label={t('proof_of_delivery_kols')}
          >
            <StyledSelect
              $isWide
              options={optionCampaignKols ?? []}
              placeholder={t('proof_of_delivery_kols')}
            />
          </StyledFormItem>
        )}
        <StyledFormItem
          name={AddCampaignPostsFieldsNames.DATE}
          rules={validationRules[AddCampaignPostsFieldsNames.DATE]}
          label={t('proof_of_delivery_date')}
        >
          <StyledDatePicker allowClear={false} />
        </StyledFormItem>

        <StyledFieldWrap>
          <StyledInfoWrap>
            <Info text={t('proof_of_delivery_social_channel_info_note')} />
          </StyledInfoWrap>
          <StyledFormItem
            name={AddCampaignPostsFieldsNames.SOCIAL_CHANNEL}
            rules={validationRules[AddCampaignPostsFieldsNames.SOCIAL_CHANNEL]}
            label={t('projects_influencer_data_social_channel_title')}
          >
            <StyledSelect
              $isWide
              options={getSocialChannelOptions(projectData) ?? []}
              placeholder={t('projects_influencer_data_social_channel_title')}
            />
          </StyledFormItem>
        </StyledFieldWrap>

        <StyledFormItem
          name={AddCampaignPostsFieldsNames.CHANNEL_LINK}
          rules={validationRules[AddCampaignPostsFieldsNames.CHANNEL_LINK]}
          label={t('proof_of_delivery_link')}
        >
          <StyledInput placeholder={t('proof_of_delivery_link')} />
        </StyledFormItem>
        <StyledButton type="primary" htmlType="submit">
          {t('proof_of_delivery_save_button')}
        </StyledButton>
      </Form>
    </Modal>
  );
};

export default AddCampaignPostModal;
