import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { OrangeCornerBadgeIcon } from '@/icons';

export const StyledFRBadge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const StyledFRBadgeText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0.8rem;
  right: 0.6rem;
  align-items: flex-end;
`;

export const StyledText = styled.div`
  color: ${COLORS.WHITE};
  ${pickThemeFontStyles('16', '22', 'BOLD')};
`;

export const StyledOrangeCornerBadgeIcon = styled(OrangeCornerBadgeIcon)`
  path {
    fill: ${COLORS.ORANGE_SUPERNOVA};
  }
`;
