import { IApiError } from '@/models/apiError.model';
import { AdminSignInActions } from '@/models/auth.model';
import { appCookiesStorage } from '@/utils';
import { AUTHORIZATION_TOKEN_STORAGE_KEY } from '@/constants';

export const AUTH_SLICE_NAME = 'auth';

export interface AuthState {
  isAuthorized: boolean;
  isLoading: boolean;
  currentAction: AdminSignInActions;
  authSessionId: string | null;
  setupMfaQrCode: string | null;
  setupMfaSecretCode: string | null;
  error: IApiError | null;
}

export const initialState: AuthState = {
  isAuthorized: !!appCookiesStorage.getItem(AUTHORIZATION_TOKEN_STORAGE_KEY),
  currentAction: AdminSignInActions.SIGN_IN,
  authSessionId: null,
  setupMfaQrCode: null,
  setupMfaSecretCode: null,
  isLoading: false,
  error: null,
};
