import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';

export const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 6rem repeat(5, 1fr) 6.75rem;
  border-radius: 1rem;
  width: 100%;
  min-width: 65rem;
  border: 1px solid ${COLORS.ALICE_BLUE_LIGHT};
`;

export const TableHeader = styled.div`
  display: contents;

  & > div:first-child {
    border-top-left-radius: 1rem;
  }

  & > div:last-child {
    border-top-right-radius: 1rem;
  }
`;

export const TableRow = styled.div`
  display: contents;

  &:last-child > div:first-child {
    border-bottom-left-radius: 1rem;
  }

  &:last-child > div:last-child {
    border-bottom-right-radius: 1rem;
  }
`;

export const TableCell = styled.div`
  border: 1px solid ${COLORS.ALICE_BLUE_LIGHT};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  height: 6.5rem;
  background-color: ${COLORS.WHITE};
  flex: 1;
  min-width: 9rem;

  img {
    width: 2rem;
    height: 2rem;
  }

  &:nth-child(7) {
    background-color: ${COLORS.SHADOW_MOUNTAIN};
    color: white;
    flex: 0.5;
    min-width: 5rem;
  }
  &:nth-child(1) {
    flex: 0.5;
    min-width: 5rem;
  }
`;

export const TableHeaderCell = styled(TableCell)`
  height: 1.5rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
`;

export const NetworkTitle = styled.div`
  color: ${COLORS.CORN_FLOWER_BLUE};
  margin-top: 0.5rem;
  max-width: 4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${pickThemeFontStyles('12', '20', 'NORMAL')};
`;
