// Global
import React from 'react';

// Components
import { Spinner } from '@/ui';
import {
  CampaignEthereumSwapDetails,
  StyledPageWrapper,
} from '../../components';

// Models
import { IRequestDetails } from '@/models/requests.model';
import { LogEntities } from '@/models/logs.model';

// Modules
import { LogContainer } from '@/modules/Logs';

interface ICampaignEthereumSwapContainer {
  request: IRequestDetails | null;
  isLoading: boolean;
}

const CampaignEthereumSwapContainer = ({
  request,
  isLoading,
}: ICampaignEthereumSwapContainer) => {
  return (
    <StyledPageWrapper>
      <CampaignEthereumSwapDetails request={request} isLoading={isLoading} />
      {isLoading ? (
        <Spinner />
      ) : (
        <LogContainer entity={LogEntities.REQUESTS} id={request?.id} />
      )}
    </StyledPageWrapper>
  );
};

export default CampaignEthereumSwapContainer;
