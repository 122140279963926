import dayjs from 'dayjs';
import {
  ICampaignDTO,
  IMarketingChannel,
  IResources,
} from '@/models/campaigns.model';
import { SocialChannels } from '@/models/socialChannel.model';
import {
  ICampaign,
  ICampaignFields,
  IMainCampaignGoal,
} from '../helpers/types';
import { CAMPAIGN_GOALS_WITH_SOCIALS } from '../constants/common';

class CampaignDTO implements ICampaignDTO {
  marketingStartDate?: string;
  marketingEndDate?: string;
  title?: string;
  campaignDescription?: string;
  campaignGoal?: string;
  campaignBanner?: string;
  goalDescription?: string;
  hashTag?: string;
  usp?: string;
  goalUrl?: string;
  isInternal?: boolean;
  earlyAccess?: boolean;
  publicDate?: string;
  kols?: string[];
  goalSocialChannels?: SocialChannels[];
  socialChannels?: IMarketingChannel[];
  resources?: IResources[];

  constructor(
    values: ICampaign,
    socialChannels: IMarketingChannel[],
    resources?: IResources[],
  ) {
    this.title = values[ICampaignFields.TITLE];
    this.isInternal = values[ICampaignFields.IS_INTERNAL];
    this.earlyAccess = values[ICampaignFields.IS_EARLY_ACCESS];
    this.kols = values[ICampaignFields.KOLS];
    this.publicDate = dayjs(values[ICampaignFields.PUBLIC_DATE])
      .tz('Europe/Berlin')
      .toString();
    this.campaignDescription = values[ICampaignFields.CAMPAIGN_DESCRIPTION];
    this.hashTag = values[ICampaignFields.HASHTAG];
    this.campaignGoal = values[ICampaignFields.CAMPAIGN_GOAL];
    this.goalDescription = values[ICampaignFields.GOAL_DESCRIPTION];
    this.usp = values[ICampaignFields.USP];
    this.marketingStartDate = values[ICampaignFields.MARKETING_START_DATE];
    this.marketingEndDate = values[ICampaignFields.MARKETING_END_DATE];
    this.campaignBanner = values[ICampaignFields.CAMPAIGN_BANNER];
    this.socialChannels = socialChannels?.map(
      ({ name, percent, description }) => ({
        name,
        percent,
        description,
      }),
    );

    if (resources) {
      this.resources = resources;
    }

    if (
      CAMPAIGN_GOALS_WITH_SOCIALS.has(this.campaignGoal as IMainCampaignGoal)
    ) {
      this.goalSocialChannels = values[ICampaignFields.GOAL_CHANNELS];
    } else {
      this.goalUrl = values[ICampaignFields.GOAL_URL];
    }
  }
}

export default CampaignDTO;
