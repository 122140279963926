// Globals
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';

// Components
import { Title } from '@/ui';
import {
  BlockTitle,
  BlockWrapper,
  StyledButton,
} from './CampaignReportDetailsContainerStyles';
import { ExportIcon } from '@/icons';
import CampaignOverview from '../../components/CampaignOverview/CampaignOverview';
import {
  AffiliateList,
  CampaignParticipationsList,
  CommissionReport,
} from '../../components';

// Modules
import {
  exportCampaignReport,
  selectDataExportsIsLoading,
} from '@/modules/DataExports';

// Hooks | Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import { getSumBy, getUserId } from '@/utils';
import { reportsSliceSelector } from '../../feature/selectors';
import {
  getCampaignAffiliate,
  getCampaignReportDetails,
  getCampaignReportParticipants,
} from '../../feature/actionCreators';

const CampaignReportDetailsContainer = () => {
  const { t } = useTranslation(['reports']);
  const dispatch = useAppDispatch();
  const exportLoading = useAppState(selectDataExportsIsLoading);
  const {
    isLoading,
    campaignDetails,
    reportAffiliateList,
    campaignReportParticipants,
  } = useAppState(reportsSliceSelector);
  const { id } = useParams();

  const exportReport = async () => {
    if (id) {
      await dispatch(exportCampaignReport(getUserId(id))).unwrap();
    }
  };

  const affiliateReward = getSumBy(
    reportAffiliateList ?? [],
    'affiliateCommission',
  );
  const commissionBalance =
    (campaignDetails?.totalSeedHunterCommission ?? 0) - affiliateReward;

  useEffect(() => {
    if (id) {
      dispatch(getCampaignReportDetails(getUserId(id))).unwrap();
      dispatch(getCampaignReportParticipants(id));
      dispatch(getCampaignAffiliate(id));
    }
  }, [id]);

  if (isLoading) return <Skeleton />;

  return (
    <>
      <Title $type="h3">{t('reports_title')}</Title>
      <StyledButton
        type="primary"
        onClick={exportReport}
        loading={exportLoading}
      >
        {t('file_export_pdf', { ns: 'common' })}
        <ExportIcon />
      </StyledButton>

      {campaignDetails && <CampaignOverview report={campaignDetails} />}

      <BlockWrapper>
        <BlockTitle>{t('reports_filters_participants')}</BlockTitle>
        <CampaignParticipationsList
          participations={campaignReportParticipants}
          isLoading={isLoading}
        />
      </BlockWrapper>

      <BlockWrapper>
        <BlockTitle>{t('reports_funding_commission')}</BlockTitle>
        <CommissionReport
          commissionTotal={campaignDetails?.totalSeedHunterCommission ?? 0}
          affiliateReward={affiliateReward}
          commissionBalance={commissionBalance}
        />
      </BlockWrapper>

      <BlockWrapper>
        <BlockTitle>{t('reports_funding_affiliate')}</BlockTitle>
        <AffiliateList
          affiliateItems={reportAffiliateList}
          isLoading={isLoading}
        />
      </BlockWrapper>
    </>
  );
};

export default CampaignReportDetailsContainer;
