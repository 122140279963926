import { Dayjs } from 'dayjs';
import { ILogs } from './logs.model';
import { IGetListParamsBase, RouteEntitiesEnum } from './api.model';
import { IMainContact } from '@/models/sharedProfile.model';
import { ISocialChannelInvestment } from '@/models/kolsList.model';
import { UserRoles } from './admin.model';

export enum RequestsEndpoints {
  GET_REQUESTS = '/admin/requests',
  GET_OWN_REQUESTS = '/admin',
}

export enum RequestersRoles {
  KOL = 'kol',
  PARTNER = 'partner',
  PROJECT = 'project',
}

export enum RequestStatuses {
  NEW_REQUEST = 'new',
  IN_PROGRESS = 'in_progress',
  CLOSED = 'closed',
}

export enum PostsStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum RequestActionsStatuses {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CLOSED = 'closed',
  REPLY_SENT = 'reply_sent',
  BLOCKED = 'blocked',
}

export enum RequestsTypesKeys {
  PROFILE_CHANGE = 'profile_change_request',
  PROFILE_VERIFICATION = 'profile_verification',
  CAMPAIGN_VERIFICATION = 'campaign_verification',
  CAMPAIGN_PARTICIPATION_REJECT = 'campaign_participation_reject',
  PROFILE_PRE_VERIFICATION = 'profile_pre_verification',
  TGE_DATE_CHANGE = 'change_tge_date',
  CONTACT_SUPPORT_TGE = 'contact_support_about_tge',
  CHECK_INVESTMENT = 'investment_verification',
  INVESTMENT_REJECTED = 'investment_rejected',
  SUBMIT_CONTENT_PLAN = 'content_distribution_plan_verification',
  POSTS_CONTEST = 'posts_contest',
  AFFILIATE_WITHDRAWAL = 'affiliate_withdrawal',
  FUNDING_ROUND_APPLICATION = 'funding_round_application',
  FUNDING_ROUND_PAYOUT = 'funding_round_payout',
  FUNDING_ROUND_CREDITS_AND_ETHEREUM = 'funding_round_credits_and_ethereum',
  FUNDING_ROUND_REFUND = 'funding_round_refund',
  CAMPAIGN_ETHEREUM_SWAP = 'campaign_ethereum_swap_request',
  PAYOUT = 'payout',
  REFUND = 'refund',
}

export interface IRequest {
  id: string;
  createdById: string;
  status: string;
  actionType?: string;
  created: string;
  role: string;
  requesterFullName?: string;
}

export interface IRequester {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  documentIdProof?: string;
  created: string;
  email: string;
  certificateOfIncorporation?: string;
  beneficialOwnershipDeclaration?: string;
  companyInfo?: {
    dateOfIncorporation?: string;
  };
  mainContact?: IMainContact;
  address: string;
  title?: string;
  whitepaper?: string;
}

export interface IFundingRound {
  id?: string;
  projectTitle?: string;
  projectSubTitle?: string;
  projectDescription?: string;
  projectImage?: string;
}

export interface ICampaign {
  id: string;
  title: string;
  created: string;
}

export interface IParticipation {
  firstName: string;
  lastName: string;
  participantId: string;
  campaignId: string;
  campaignTitle: string;
  totalParticipationAmount: number;
}

export interface PostsContestUrl {
  id?: string;
  publishDate?: string;
  reason?: string;
  url?: string;
  status?: PostsStatus;
}

export interface IFundingRoundDocumentation {
  type: string;
}

export interface IPosts {
  socialChannel?: string;
  weekNumber?: number;
  urls?: PostsContestUrl[];
}

export interface IFundingRoundApplication {
  elevatorPitch?: string;
  tgeDate?: string;
  tokenomics?: string;
  tokenomicsMetricsReview?: string;
  fundingStage?: string;
  roadmapOutline?: string;
  marketingStrategy?: string;
  marketAnalysis?: string;
  competitiveLandscape?: string;
  teamsAndPartners?: string;
  additionalDocumentation?: IFundingRoundDocumentation[];
}

export interface IPayout {
  campaignBudget?: number;
  campaignEndDate?: string;
  campaignName?: string;
  projectName?: string;
  totalPayout?: number;
  campaignId?: string;
  fundingRoundEndDate?: string;
  fundingRoundTitle?: string;
  fundingTarget?: string;
  raisedBudget?: number;
  totalPayoutAmount?: number;
  creditUsedTotal?: number;
  raisedBudgetTotal?: number;
  seedHunterCommissionAmountTotal?: number;
  totalSeedHunterCommission?: number;
  creditsUsed?: number;
}

export interface ICampaignEthereumSwap {
  campaignTitle?: string;
  campaignId?: string;
  campaignTotalBudget?: string;
  transferDate?: string;
  campaignStartDate?: string;
  campaignEndDate?: string;
  currency?: string;
  hash?: string;
  walletNetwork?: string;
}

export interface IRequestDetails {
  id: string;
  createdById: string;
  requester?: IRequester;
  fundingRound?: IFundingRound;
  project?: IProject;
  kol?: IKol;
  participation?: IParticipation;
  campaign?: ICampaign;
  socialChannelInvestment?: ISocialChannelInvestment[];
  comment?: string;
  subject?: string;
  description?: string;
  status: RequestActionsStatuses;
  actionType: RequestsTypesKeys;
  created: string;
  investedAmount?: number;
  investedTokensForMoney?: number;
  data?: IPosts | IFundingRoundApplication | IPayout | ICampaignEthereumSwap;
  role: string;
  message: string;
  logs: ILogs[];
  campaignTitle?: string;
  campaignId?: string;
  totalRefundAmount?: number;
  walletAddress?: string;
  totalAffiliateWithdrawalAmount?: number;
  targetId?: string;
  targetSk?: string;
}

export interface IOwnRequest {
  id: string;
  createdById: string;
  status: RequestActionsStatuses;
  actionType: RequestsTypesKeys;
  created: string;
  role: RequestersRoles;
  requesterFullName: string;
}

export interface IKol {
  id: string;
  firstName?: string;
  lastName?: string;
  created?: string;
  email?: string;
  mainContact?: IMainContact;
  role?: string;
  alias?: string;
}

export interface IProject {
  id: string;
  title: string;
  firstName?: string;
  lastName?: string;
  created?: string;
  email?: string;
  mainContact?: IMainContact;
  estimatedTGE?: string;
  marketingStartDate?: string;
  marketingEndDate?: string;
  newEstimatedTGE?: string;
  newMarketingStartDate?: string;
  newMarketingEndDate?: string;
  oldEstimatedTGE?: string;
  certificateOfIncorporation: string;
  beneficialOwnershipDeclaration: string;
  oldMarketingStartDate?: string;
  oldMarketingEndDate?: string;
  role?: string;
}

// ============= DTO =============

export interface IGetRequestsListDTO extends IGetListParamsBase {
  search?: string;
  status?: RequestStatuses;
  actionTypes?: RequestsTypesKeys[];
  userType?: UserRoles;
  sortByDate?: boolean;
}

export interface IPatchRequestStatusDTO {
  id: string;
  status: RequestActionsStatuses;
  message?: string;
}

export interface IPatchPostsContestStatusDTO {
  id: string;
  urlId: string;
  status: PostsStatus;
  publishDate?: Dayjs | null;
  reason?: string;
}

export interface IOwnRequestsDTO extends IGetListParamsBase {
  role: RequestersRoles;
  id: string;
}

export interface IRequestsCountDTO {
  role: RequestersRoles;
  id: string;
}

export interface IEntityRequestsDTO extends IGetListParamsBase {
  entity: RouteEntitiesEnum;
  targetId: string;
}

// ============== Response ==============

export interface IRequestsListResponse {
  items: IRequest[];
  hasMore: boolean;
}

export interface IOwnRequestsResponse {
  items: IOwnRequest[];
  hasMore: boolean;
}

export interface IRequestDetailsResponse {
  data: IRequestDetails;
}
