import { TFunction } from 'react-i18next';
import { TelegramSVGIcon, PhoneIcon, WhatsAppIcon } from '@/icons';
import { StyledContactTabLabel } from './ContactInfoStyles';
import { ContactSourceEnum } from '@/models/sharedProfile.model';

export const getContactTabsConfig = (t: TFunction) => [
  {
    value: ContactSourceEnum.TELEGRAM,
    label: (
      <StyledContactTabLabel>
        <TelegramSVGIcon />
        <span>{t('profile_contact_block_telegram')}</span>
      </StyledContactTabLabel>
    ),
  },
  {
    value: ContactSourceEnum.WHATSAPP,
    label: (
      <StyledContactTabLabel>
        <WhatsAppIcon />
        <span>{t('profile_contact_block_whatsapp')}</span>
      </StyledContactTabLabel>
    ),
  },
  {
    value: ContactSourceEnum.PHONE,
    label: (
      <StyledContactTabLabel>
        <PhoneIcon />
        <span>{t('profile_contact_block_phone')}</span>
      </StyledContactTabLabel>
    ),
  },
];
