import React from 'react';

// Components
import { StyledFooter, StyledMobileSideBarMenu } from './MobileSideBarStyles';

// Hooks
import { useSidebarMenu } from '../../hooks';

const MobileSideBar = (): JSX.Element => {
  const { items, handleMenuClick } = useSidebarMenu();

  return (
    <StyledFooter>
      <StyledMobileSideBarMenu
        items={items}
        onClick={handleMenuClick}
        triggerSubMenuAction={'click'}
      />
    </StyledFooter>
  );
};

export default MobileSideBar;
