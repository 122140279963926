// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Spinner, StyledSpinnerWrap } from '@/ui';
import { StyledTitles } from './FundingRoundRefundListStyles';
import { PayoutListWrapper, StyledInfoTitles } from '../Styled';
import FundingRoundRefundCard from '../FundingRoundRefundCard/FundingRoundRefundCard';

// Modules
import { IFundingPayoutListItem } from '@/models/payout.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

interface IFundingRoundRefundList {
  refundList?: IFundingPayoutListItem[] | null;
  isLoading: boolean;
}

const FundingRoundRefundList = ({
  refundList,
  isLoading,
}: IFundingRoundRefundList) => {
  const { t } = useTranslation(['payout']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedRefundList = useMemo(() => {
    return refundList?.map((data) => {
      return <FundingRoundRefundCard data={data} key={data?.id} />;
    });
  }, [refundList]);

  return (
    <PayoutListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>
            {t('payout_funding_refund_funding_round')}
          </StyledTitles>
          <StyledTitles>{t('payout_funding_refund_end_date')}</StyledTitles>
          <StyledTitles>
            {t('payout_funding_refund_funding_target')}
          </StyledTitles>
          <StyledTitles>{t('payout_funding_refund_raised')}</StyledTitles>
          <StyledTitles>{t('payout_funding_refund_total_payout')}</StyledTitles>
          <StyledTitles>{t('payout_account_manager_title')}</StyledTitles>
          <StyledTitles>{t('payout_finance_manager_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedRefundList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </PayoutListWrapper>
  );
};

export default FundingRoundRefundList;
