import { SocialChannelsErrors } from '../constants/common';

export const getSocialChannelError = (error?: string) => {
  switch (error) {
    case SocialChannelsErrors.MAINLINE_PROCESSING:
      return 'Mainline calculates data, press get influencer data after several minutes';
    case SocialChannelsErrors.MAINLINE_TWITTER_ERROR:
      return 'Not existing social channel or mainline calculation failure';
    case SocialChannelsErrors.FACEBOOK_NOT_CONNECTED:
      return 'You must connect your Facebook account first';
    case SocialChannelsErrors.FACEBOOK_ERROR:
    case SocialChannelsErrors.YOUTUBE_ERROR:
      return 'Not existing social channel';
    default:
      return 'Something went wrong';
  }
};
