import { ICampaignSocialChannel } from '@/models/campaigns.model';
import { IAverageSocialDataItem } from '@/models/dataLists.model';

export const getAveragePostsAmount = (
  SocialChannels: ICampaignSocialChannel[],
  averagePriceConfig: IAverageSocialDataItem,
  totalBudget: number,
) => {
  return Object.entries(averagePriceConfig ?? {}).reduce(
    (acc, [key, averagePrice]) => {
      const item = SocialChannels.find((channel) => channel.name === key);

      if (item) {
        const channelBudget = (totalBudget * item.percent) / 100;
        acc[key] = Math.floor(channelBudget) / Math.floor(averagePrice);
      } else {
        acc[key] = 0;
      }

      return acc;
    },
    {} as Record<string, number>,
  );
};
