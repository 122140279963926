import {
  PayloadAction,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import { CAMPAIGNS_SLICE_NAME, CampaignsState, initialState } from './models';
import {
  getCampaignsList,
  getCampaignDetails,
  updateCampaignStatus,
  updateCampaign,
  getCampaignParticipations,
  getCampaignKols,
} from './actionCreators';
import { decompose, showApiErrors, sortByLabel } from '@/utils';
import { ICampaign, IMarketingChannel } from '@/models/campaigns.model';
import { decomposeAndUpdateSocialChannels } from '../helpers';
import { showErrorToast } from '@/components';
import { maxResourcesCount } from '../constants/common';
import { SocialChannels } from '@/models/socialChannel.model';

export const slice = createSlice({
  name: CAMPAIGNS_SLICE_NAME,
  initialState,
  reducers: {
    setCampaign(state: CampaignsState, action) {
      state.campaignDetails = { ...state.campaignDetails, ...action.payload };
    },
    setCampaignResources(state: CampaignsState, action) {
      const updatedResources = [...action.payload.resources];
      state.campaignDetails = {
        ...(state.campaignDetails || null),
        resources:
          (state.campaignDetails?.resources?.length ?? 0) < maxResourcesCount
            ? [...(state.campaignDetails?.resources ?? []), ...updatedResources]
            : [...(state.campaignDetails?.resources ?? [])],
      } as ICampaign;
    },
    removeCampaignResource(state: CampaignsState, action) {
      const updatedResources =
        (state.campaignDetails?.resources ?? [])?.filter(
          (item) => item.link !== action.payload.link,
        ) || null;
      state.campaignDetails = {
        ...(state.campaignDetails || null),
        resources: updatedResources,
      } as ICampaign;
    },
    addSocialChannel(state, action: PayloadAction<IMarketingChannel>) {
      const newItem = action.payload;
      state.socialChannels = [...(state.socialChannels ?? []), newItem];
    },
    removeSocialChannel(state, action: PayloadAction<SocialChannels>) {
      const restChannels = sortByLabel([
        ...state.socialChannels.filter((item) => item.name !== action.payload),
      ]);
      const removedItem = state.socialChannels.find(
        (item) => item.name === action.payload,
      );

      if (!removedItem?.percent) {
        state.socialChannels = restChannels;
        return;
      }

      const updatedRestChannels = decomposeAndUpdateSocialChannels(
        removedItem?.percent,
        restChannels,
      ) as IMarketingChannel[];

      // Set editable if length less 2
      if (updatedRestChannels?.length <= 2) {
        state.socialChannels = sortByLabel(updatedRestChannels).map((item) => ({
          ...item,
          isEditable: true,
        }));
      } else {
        state.socialChannels = updatedRestChannels;
      }
    },
    updateSocialChannel(state, action: PayloadAction<IMarketingChannel>) {
      state.socialChannels = sortByLabel([
        ...state.socialChannels.map((item) =>
          item.name === action.payload.name ? action.payload : item,
        ),
      ]);
    },
    changeSocialChannelPercent(
      state,
      action: PayloadAction<IMarketingChannel>,
    ) {
      const { percent, name } = action.payload;
      const current = state.socialChannels.find((item) => item.name === name);
      if (!current?.isEditable) return;

      const diff = (current.percent ?? 0) - Number(percent);
      const other = state.socialChannels.filter(
        (plan) =>
          plan.name !== name &&
          (plan.isEditable || state.socialChannels?.length <= 2),
      );
      const notEditableOther = state.socialChannels.filter(
        (plan) => plan.name !== name && !plan.isEditable,
      );

      const availableTokens = other.reduce(
        (acc, item) => acc + Number(item.percent),
        0,
      );

      if (
        (Number(percent) > Number(current.percent) &&
          Math.abs(diff) > availableTokens) ||
        state.socialChannels.length === 1
      ) {
        showErrorToast({ message: 'Invalid value' });
        return;
      }

      const updatedOther = decomposeAndUpdateSocialChannels(diff, other);

      if (updatedOther === undefined) {
        showErrorToast({ message: 'Invalid value' });
        return;
      }

      state.socialChannels = sortByLabel([
        ...updatedOther,
        ...notEditableOther,
        action.payload,
      ]);
    },
    changeChannelsBudget(state) {
      const channelsCount = Number(state.socialChannels?.length);

      if (channelsCount) {
        const decomposedPlans = decompose(100, channelsCount);
        const updatedPlans = state.socialChannels?.map((channel, idx) => ({
          ...channel,
          percent: decomposedPlans[idx],
        }));
        state.socialChannels = updatedPlans ?? state.socialChannels;
      }
    },
    setCampaignFilters(state: CampaignsState, action) {
      state.campaignFilters = { ...state.campaignFilters, ...action.payload };
      state.campaignsListData = initialState.campaignsListData;
    },
    resetCampaignsState() {
      return { ...initialState };
    },
    resetCampaignsList(state) {
      state.campaignsListData = initialState.campaignsListData;
      state.campaignFilters = initialState.campaignFilters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignsList.fulfilled, (state: CampaignsState, action) => {
        state.campaignsListData = {
          items: action.meta.arg.startId
            ? [
                ...(state.campaignsListData?.items ?? []),
                ...action.payload.items,
              ]
            : [...action.payload.items],
          hasMore: action.payload.hasMore,
        };
        state.isLoading = false;
      })
      .addCase(
        getCampaignParticipations.fulfilled,
        (state: CampaignsState, action) => {
          state.participationsData = {
            items: action.meta.arg.startId
              ? [
                  ...(state.participationsData?.items ?? []),
                  ...action.payload.items,
                ]
              : [...action.payload.items],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addCase(
        getCampaignDetails.fulfilled,
        (state: CampaignsState, action) => {
          state.campaignDetails = action.payload.data;
          state.socialChannels = action.payload.data.socialChannels?.map(
            (socialChannel) => ({ ...socialChannel, isEditable: true }),
          );
          state.isLoading = false;
        },
      )
      .addCase(getCampaignKols.fulfilled, (state: CampaignsState, action) => {
        state.campaignKols = action.payload;
        state.isLoading = false;
      })
      .addMatcher(
        isFulfilled(updateCampaign, updateCampaignStatus),
        (state: CampaignsState, action) => {
          state.campaignDetails = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isPending(
          getCampaignsList,
          getCampaignDetails,
          updateCampaign,
          getCampaignParticipations,
          getCampaignKols,
        ),
        (state: CampaignsState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(
          getCampaignsList,
          getCampaignDetails,
          updateCampaign,
          getCampaignParticipations,
          getCampaignKols,
        ),
        (state: CampaignsState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export const {
  resetCampaignsState,
  resetCampaignsList,
  setCampaign,
  removeCampaignResource,
  removeSocialChannel,
  addSocialChannel,
  updateSocialChannel,
  changeSocialChannelPercent,
  changeChannelsBudget,
  setCampaignResources,
  setCampaignFilters,
} = slice.actions;
export default slice.reducer;
