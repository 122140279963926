import styled from 'styled-components';
import { RevenueTransferTypes } from '@/models/payout.model';
import { COLORS } from '@/theme';

export const StyledTransferType = styled.div<{
  $transferType?: RevenueTransferTypes;
}>`
  & div {
    ${({ $transferType }) => {
      switch ($transferType) {
        case RevenueTransferTypes.REVENUE:
          return `color: ${COLORS.GREEN};`;
        case RevenueTransferTypes.RECONCILIATION:
          return `color: ${COLORS.BRIGHT_ORANGE};`;
        default:
          return '';
      }
    }}
  }
`;
