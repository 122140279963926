// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledLabel,
  ChannelInvestmentWrapper,
  ChannelsInvestmentWrapper,
} from './CheckInvestmentRequestsDetailsStyles';
import ChannelInvestmentItem from '../CheckInvestmentRequestsDetails/ChannelInvestmentItem';
import { DesktopOnly } from '@/modules/Adaptive';
import { ISocialChannelInvestment } from '@/models/kolsList.model';

interface ISocialChannelInvestmentItem {
  channelsInvestment: ISocialChannelInvestment[];
}

const SocialChannelsInvestment = ({
  channelsInvestment,
}: ISocialChannelInvestmentItem) => {
  const { t } = useTranslation(['requestsList']);
  const mappedInvestments = useMemo(() => {
    return channelsInvestment.map((item) => (
      <ChannelInvestmentItem investmentData={item} key={item.name} />
    ));
  }, [channelsInvestment]);

  return (
    <ChannelsInvestmentWrapper>
      <DesktopOnly>
        <ChannelInvestmentWrapper>
          <StyledLabel>{t('check_investment_channel_name')}</StyledLabel>
          <StyledLabel>
            {t('check_investment_channel_invested_currency')}
          </StyledLabel>
          <StyledLabel>
            {t('check_investment_channel_invested_tokens')}
          </StyledLabel>
          <StyledLabel>
            {t('check_investment_channel_invested_post_price')}
          </StyledLabel>
          <StyledLabel>
            {t('check_investment_channel_invested_posts_amount')}
          </StyledLabel>
        </ChannelInvestmentWrapper>
      </DesktopOnly>
      {mappedInvestments}
    </ChannelsInvestmentWrapper>
  );
};

export default SocialChannelsInvestment;
