// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledDescriptionWrapper,
  StyledInfo,
  StyledFormItem,
} from './ProjectInformationDescriptionStyles';
import { TextEditorWithHtml } from '@/ui';

// Models
import { IProject } from '@/models/projects.model';

// Hooks | Helpers
import { ProjectFieldsNames } from '../../types';
import { useGetFormValidationRules } from '../../hooks';
import { isProjectDisabled } from '../../helpers';

interface IProjectInformationDescription {
  projectDetails: IProject;
  isEditActive: boolean;
}

const ProjectInformationDescription = ({
  projectDetails,
  isEditActive,
}: IProjectInformationDescription) => {
  const { t } = useTranslation(['projectsList']);
  const isFieldsDisabled =
    !isEditActive || isProjectDisabled(projectDetails?.status);
  const validationRules = useGetFormValidationRules();

  return (
    <StyledDescriptionWrapper>
      <StyledInfo>
        <StyledFormItem
          name={ProjectFieldsNames.DESCRIPTION}
          rules={validationRules[ProjectFieldsNames.DESCRIPTION]}
          label={t('projects_information_description_title')}
        >
          <TextEditorWithHtml
            disabled={isFieldsDisabled}
            rows={5}
            defaultValue={projectDetails.description}
          />
        </StyledFormItem>
      </StyledInfo>
    </StyledDescriptionWrapper>
  );
};

export default ProjectInformationDescription;
