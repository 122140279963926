import { IApiError } from '@/models/apiError.model';
import { IMilestone } from '@/models/milestones.model';

export const MILESTONES_SLICE_NAME = 'milestones';

export interface MilestonesState {
  milestones: IMilestone[] | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: MilestonesState = {
  milestones: null,
  isLoading: false,
  error: null,
};
