import styled from 'styled-components';
import { COLORS } from '@/theme';

export const IconWrap = styled.div<{ $position?: string }>`
  position: absolute;
  width: 1rem;
  ${({ $position }) => {
    switch ($position) {
      case '1rem':
        return `
          right: 1rem;
          top: 1rem;`;
      default:
        return `
          right: 0;
          top: 0;`;
    }
  }}
  cursor: pointer;
  svg {
    path {
      fill: ${COLORS.BLACK};
    }
  }
`;
