// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Title, StyledPageWrapper } from '@/ui';
import { GoBackButton } from '@/components';
import { SocialChannelDataInfo } from '../../components';

const SocialChannelDataContainer = () => {
  const { t } = useTranslation(['kolsList']);
  return (
    <StyledPageWrapper>
      <Title $type="h3">{t('kol_social_data_title')}</Title>
      <GoBackButton>{t('kols_list_details_go_back')}</GoBackButton>
      <SocialChannelDataInfo />
    </StyledPageWrapper>
  );
};

export default SocialChannelDataContainer;
