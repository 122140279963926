import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import {
  IAdminDetailsResponse,
  IAdmin,
  IUpdateAdminsDetailsDTO,
} from '@/models/adminsList.model';
import { AdminsListAPI } from '@/api';
import { formatApiError } from '@/utils';
import { ADMINS_LIST_SLICE_NAME } from './models';

export const getAdminsList = createAsyncThunk<
  IAdmin[],
  void,
  { serializedErrorType: IApiError }
>(
  `${ADMINS_LIST_SLICE_NAME}/getAdminsList`,
  async () => {
    const response = await AdminsListAPI.getAdminsList();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getAdminDetails = createAsyncThunk<
  IAdminDetailsResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${ADMINS_LIST_SLICE_NAME}/getAdminDetails`,
  async (id: string) => {
    const response = await AdminsListAPI.getAdminDetails(id);
    return response;
  },
  { serializeError: formatApiError },
);

export const updateAdmin = createAsyncThunk<
  IAdmin,
  IUpdateAdminsDetailsDTO,
  { serializedErrorType: IApiError }
>(
  `${ADMINS_LIST_SLICE_NAME}/updateAdminDetails`,
  async (data) => {
    const response = await AdminsListAPI.updateAdminDetails(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
