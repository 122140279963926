// Globals
import React from 'react';

// Components
import { TableRow, TableCell, NetworkTitle } from './BalanceReportStyles';
import { Tooltip } from '@/ui';

// Modules
import { networksConfig } from '@/modules/Web3';

// Models
import { TNetworkTransfersData } from '@/models/reports.model';
import { WalletNetworksEnum } from '@/models/web3.model';

// Utils | Helpers
import { renderCurrenciesValues } from '../../helpers/dataRender';
import { capitalizeString } from '@/utils';

interface IBalanceReportRow {
  data: TNetworkTransfersData;
  network: WalletNetworksEnum;
  walletAddress?: string;
}
const BalanceReportRow = ({
  data,
  network,
  walletAddress,
}: IBalanceReportRow) => {
  return (
    <TableRow>
      <TableCell>
        <img src={networksConfig[network].image} alt="" />
        <NetworkTitle>{capitalizeString(network)}</NetworkTitle>
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode}
          title={walletAddress}
          placement={'top'}
        >
          <NetworkTitle>{walletAddress}</NetworkTitle>
        </Tooltip>
      </TableCell>

      <TableCell>
        {data.raisedBudget && renderCurrenciesValues(data.raisedBudget)}
      </TableCell>

      <TableCell>
        {data.creditUsed && renderCurrenciesValues(data.creditUsed)}
      </TableCell>

      <TableCell>
        {data.initialBalance && renderCurrenciesValues(data.initialBalance)}{' '}
      </TableCell>

      <TableCell>
        {data.seedHunterRevenueTransfer &&
          renderCurrenciesValues(data.seedHunterRevenueTransfer)}
      </TableCell>

      <TableCell>
        {data.seedHunterReconciliationTransfer &&
          renderCurrenciesValues(data.seedHunterReconciliationTransfer)}
      </TableCell>

      <TableCell>
        {data.projectTransfer && renderCurrenciesValues(data.projectTransfer)}
      </TableCell>
    </TableRow>
  );
};

export default BalanceReportRow;
