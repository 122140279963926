// Globals
import React from 'react';

// Components
import { StyledWrapper } from './CampaignResultsStyles';
import HeaderBlocks from './HeaderBlock';
import CampaignChannelsData from './CampaignChannelsData';
import MarketingDelivery from './MarketingDelivery';

// Models
import { ICampaign, IParticipationItems } from '@/models/campaigns.model';

interface IActiveCampaignDetails {
  campaign: ICampaign;
  isLoading: boolean;
  participants: {
    items: IParticipationItems[] | null;
    hasMore: boolean;
  };
  getNextParticipant: () => void;
}

const CampaignResults = ({
  campaign,
  getNextParticipant,
  isLoading,
  participants,
}: IActiveCampaignDetails) => {
  return (
    <StyledWrapper>
      <HeaderBlocks campaign={campaign} />
      <CampaignChannelsData campaign={campaign} />
      <MarketingDelivery
        campaign={campaign}
        getNextParticipants={getNextParticipant}
        isLoading={isLoading}
        hasMore={participants?.hasMore}
        campaignParticipants={participants.items}
      />
    </StyledWrapper>
  );
};

export default CampaignResults;
