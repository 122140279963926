// Globals
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

// Components
import {
  Wrapper,
  BlockWrapper,
  StyledText,
  StyledInputWrapper,
  NavigationWrapper,
  StyledButton,
  StyledTitle,
  StyledTextArea,
  StyledDatePicker,
  StyledHeaderWrapper,
} from './ContactSupportTgeRequestDetailsStyles';
import { Link, StyledSelect } from '@/ui';
import ContactSupportActionButtons from '../ActionButtons/ContactSupportActionButtons';
import RequestStatusBadges from '../RequestStatusBadges/RequestStatusBadges';

// Modules
import {
  getWeeksAfterOptions,
  getWeeksBeforeOptions,
} from '@/modules/SharedProfile';

// Models
import { IRequestDetails } from '@/models/requests.model';
import { UserRoles } from '@/models/admin.model';

// Helpers | Hooks
import { diffWeeksAfter, diffWeeksBefore, getUserLink } from '@/utils';

interface IContactSupportTgeRequestDetails {
  request: IRequestDetails | null;
}

const ContactSupportTgeRequestDetails = ({
  request,
}: IContactSupportTgeRequestDetails) => {
  const { t } = useTranslation(['requestsList']);
  const [marketingStart, setMarketingStart] = useState<number | null>(null);
  const [marketingEnd, setMarketingEnd] = useState<number | null>(null);
  const { title, estimatedTGE, marketingStartDate, marketingEndDate } =
    request?.project ?? {};

  const weeksBeforeOptions = getWeeksBeforeOptions(t);
  const weeksAfterOptions = getWeeksAfterOptions(t);
  const actionTypeLabel = t(`requests_${request?.actionType}_label`);

  useEffect(() => {
    const oldDiffBefore = diffWeeksBefore(estimatedTGE, marketingStartDate);
    const oldDiffAfter = diffWeeksAfter(estimatedTGE, marketingEndDate);

    setMarketingStart(oldDiffBefore >= 0 ? oldDiffBefore : null);
    setMarketingEnd(oldDiffAfter <= 0 ? oldDiffAfter * -1 : null);
  }, [request]);

  return (
    <Wrapper>
      <StyledHeaderWrapper>
        <StyledTitle>{t('request_title')}</StyledTitle>
        <RequestStatusBadges status={request?.status} />
      </StyledHeaderWrapper>
      <StyledText>{`${t('requests_type')} ${actionTypeLabel}`}</StyledText>
      <NavigationWrapper>
        <StyledTitle $type="h2">{title}</StyledTitle>
        <Link
          to={getUserLink(UserRoles.PROJECT, request?.createdById) ?? ''}
          target="_blank"
        >
          <StyledButton type="primary">
            {t('contact_support_tge_go_to_profile')}
          </StyledButton>
        </Link>
      </NavigationWrapper>

      <BlockWrapper>
        <StyledText>{t('contact_support_tge_subject')}</StyledText>
        <StyledInputWrapper>
          <StyledTextArea rows={5} disabled value={request?.subject} />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('contact_support_tge_description')}</StyledText>
        <StyledInputWrapper>
          <StyledTextArea rows={5} disabled value={request?.description} />
        </StyledInputWrapper>
      </BlockWrapper>

      <BlockWrapper>
        <StyledText>{t('contact_support_tge_planned_date')}</StyledText>
        <StyledInputWrapper>
          <StyledDatePicker
            disabled
            value={estimatedTGE ? dayjs(estimatedTGE) : null}
          />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('contact_support_tge_marketing_start')}</StyledText>
        <StyledInputWrapper>
          <StyledSelect
            disabled
            options={weeksBeforeOptions}
            value={marketingStart ?? '-'}
          />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('contact_support_tge_marketing_end')}</StyledText>
        <StyledInputWrapper>
          <StyledSelect
            disabled
            options={weeksAfterOptions}
            value={marketingEnd ?? '-'}
          />
        </StyledInputWrapper>
      </BlockWrapper>
      <ContactSupportActionButtons status={request?.status} id={request?.id} />
    </Wrapper>
  );
};

export default ContactSupportTgeRequestDetails;
