import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { RequestActionsStatuses } from '@/models/requests.model';

export const StyledBadge = styled.div<{ $status?: string | null }>`
  display: flex;
  width: 6rem;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  ${({ $status }) => {
    switch ($status) {
      case RequestActionsStatuses.NEW:
        return `background: ${COLORS.PATTERNS_BLUE};
          color: ${COLORS.BLUE_PRIMARY};`;
      case RequestActionsStatuses.APPROVED:
        return `background: ${COLORS.GREEN_BUBBLES};
          color: ${COLORS.GREEN_MINT};`;
      case RequestActionsStatuses.IN_PROGRESS:
        return `background: ${COLORS.CHABLIS_PEACH};
          color: ${COLORS.ATOMIC_TANGERINE};`;
      case RequestActionsStatuses.REPLY_SENT:
        return `background: ${COLORS.CHIFFON};
          color: ${COLORS.NEON_CARROT};`;
      case RequestActionsStatuses.REJECTED:
        return `background: ${COLORS.MISTY_ROSE};
          color: ${COLORS.PICH_FROLY};`;
      default:
        return `background: ${COLORS.LIGHTEST_GRAY};
          color: ${COLORS.CORN_FLOWER_BLUE};`;
    }
  }}
`;

export const StyledLabel = styled.div`
  ${pickThemeFontStyles('12', '18', 'BOLD')};
`;
