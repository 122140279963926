import { createSelector } from '@reduxjs/toolkit';
import { DataLists } from '@/models/dataLists.model';
import { DataListsState, DATA_LISTS_SLICE_NAME } from './models';
import { RootState } from '../../../store';

const dataListsSelector = (state: RootState): DataListsState =>
  state[DATA_LISTS_SLICE_NAME];

export const selectCompanyType = createSelector(
  dataListsSelector,
  (state: DataListsState) => state[DataLists.COMPANY_TYPE],
);

export const selectGeography = createSelector(
  dataListsSelector,
  (state: DataListsState) => state.geographies,
);

export const selectWalletNetwork = createSelector(
  dataListsSelector,
  (state: DataListsState) => state[DataLists.WALLET_NETWORK],
);

export const selectCommunity = createSelector(
  dataListsSelector,
  (state: DataListsState) => state[DataLists.COMMUNITY],
);

export const selectCryptoCurrencies = createSelector(
  dataListsSelector,
  (state: DataListsState) => state[DataLists.CRYPTO_CURRENCIES],
);

export const selectFirstStageDays = createSelector(
  dataListsSelector,
  (state: DataListsState) => state.FIRST_STAGE_DAYS,
);

export const selectUsersOptions = createSelector(
  dataListsSelector,
  (state: DataListsState) => state.usersOptions,
);
