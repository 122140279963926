import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { Form, FormItem } from '@/components';
import { BlockLayout, Button, Checkbox, TextArea } from '@/ui';

export const StyledFrom = styled(Form)`
  display: flex;
  width: 100%;
  gap: 1.5rem;
  flex-direction: column;
`;

export const StyledFormItem = styled(FormItem)`
  width: 100%;
  margin-bottom: 0.5rem;
  flex-direction: column;

  &&& .ant-form-item-row {
    align-items: flex-start;
    flex-direction: column;

    .ant-form-item-control {
      width: 100%;
    }
  }

  label {
    ${pickThemeFontStyles('14', '18', 'NORMAL')};
    color: ${COLORS.CORN_FLOWER_BLUE}!important;
  }
`;

export const StyledInfoWrapper = styled(BlockLayout)<{ $isColumn?: boolean }>`
  display: flex;
  width: 100%;
  padding: 1.5rem;
  flex-direction: ${({ $isColumn }) => ($isColumn ? 'column' : 'row')};
  align-items: flex-start;
  gap: 0.5rem;
`;

export const StyledRequestWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: auto;
  }
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  margin-bottom: 1rem;
  &&& .ant-input {
    background: ${COLORS.LIGHTEST_GRAY};
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-bottom: 0;
  }
`;

export const StyledChannelsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -4.1rem;
  z-index: 1;
  padding: 0.3rem;
`;

export const StyledCheckbox = styled(Checkbox)`
  position: relative;
  z-index: 99;
  & .ant-checkbox-inner {
    width: 35px !important;
    height: 35px !important;
    background: ${COLORS.WHITE_SMOKE};
    border: 1px solid ${COLORS.LIGHTEST_GRAY};
    :hover {
      background-color: ${COLORS.GREEN_LIGHT};
      border: 1px solid ${COLORS.GREEN_MINT};
    }
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background: ${COLORS.GREEN_LIGHT};
    border: 1px solid ${COLORS.GREEN_MINT};
    :hover {
      background-color: ${COLORS.GREEN_LIGHT};
      border: 1px solid ${COLORS.GREEN_MINT};
    }
    &::after {
      display: none;
    }
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  &&& {
    max-width: 100%;
    height: 2rem;
    background: ${COLORS.SOLITUDE_GRAY};
    color: ${COLORS.DARK_BLUE};
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
    align-items: center;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &&& {
      max-width: 9.5rem;
    }
  }
`;

export const Text = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;
