import dayjs from 'dayjs';
import { IFundingRound } from '@/models/requests.model';
import { IFundingContract } from '@/models/fundingRound.model';

export const checkIsAllFilled = (
  values: IFundingRound | IFundingContract,
  keysToCheck?: string[],
): boolean => {
  return Object.entries(values).every(([key, val]) => {
    if (typeof val === 'object' && val !== null && !dayjs.isDayjs(val)) {
      return checkIsAllFilled(val, keysToCheck);
    }

    if (keysToCheck) {
      return keysToCheck.includes(key) ? !!val : true;
    }

    if (val === 0) {
      return !val;
    }
    if (val === undefined || val === null) {
      return val;
    }
    return val;
  });
};
