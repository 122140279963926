import { TFunction } from 'react-i18next';
import { Link } from '@/ui';
import { ListIcon, LogoutIcon, MyAccountIcon, WalletIcon } from '@/icons';
import { ROUTES_PATHS } from '@/router';
import { ISideBarConfig, MenuItemsKeys } from '../constants/sidebarConfigs';
import { sortSideBarItems } from './sortSideBarItems';

interface ISideBarMenuBuilder {
  t: TFunction;
  sideBarConfig: ISideBarConfig[];
  isMobile: boolean;
}

export const getMobileChildOptions = (t: TFunction) => [
  {
    key: MenuItemsKeys.CAMPAIGNS,
    label: <Link to={ROUTES_PATHS.CAMPAIGNS}>{t('sidebar_campaigns')}</Link>,
    icon: <ListIcon />,
  },
  {
    key: MenuItemsKeys.FUNDING_ROUND,
    label: (
      <Link to={ROUTES_PATHS.FUNDING_ROUNDS}>
        {t('sidebar_funding_rounds')}
      </Link>
    ),
    icon: <ListIcon />,
  },
  {
    key: MenuItemsKeys.HUNTERS,
    label: <Link to={ROUTES_PATHS.HUNTERS_LIST}>{t('sidebar_hunters')}</Link>,
    icon: <ListIcon />,
  },
  {
    key: MenuItemsKeys.REQUESTS,
    label: <Link to={ROUTES_PATHS.REQUESTS}>{t('sidebar_requests')}</Link>,
    icon: <ListIcon />,
  },
  {
    key: MenuItemsKeys.PAYOUT,
    label: <Link to={ROUTES_PATHS.PAYOUT}>{t('sidebar_payout')}</Link>,
    icon: <WalletIcon />,
  },
  {
    key: MenuItemsKeys.SETTINGS,
    label: <Link to={ROUTES_PATHS.SETTINGS}>{t('sidebar_settings')}</Link>,
    icon: <MyAccountIcon />,
  },
  {
    key: MenuItemsKeys.LOG_OUT,
    label: 'Log out',
    icon: <LogoutIcon />,
  },
];

export const sideBarMenuBuilder = ({
  t,
  sideBarConfig,
  isMobile,
}: ISideBarMenuBuilder) => {
  return sortSideBarItems(sideBarConfig).map((item) => {
    return {
      icon: item.icon,
      key: item.key,
      children:
        isMobile &&
        item.key === MenuItemsKeys.OPTIONS &&
        getMobileChildOptions(t),
      label: item.route ? (
        <Link
          to={item.route}
          active={location.pathname?.split('/')[1] === item.key}
        >
          {t(item?.titleKey)}
        </Link>
      ) : (
        <>{t(item.titleKey)}</>
      ),
    };
  });
};
