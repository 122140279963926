import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { KOLS_LIST_SLICE_NAME } from './models';
import {
  IBlockKOLUser,
  IGetKOLInvestmentsDTO,
  IGetKOLsListDTO,
  IKOL,
  IKOLAffiliateResponse,
  IKOLDetailsResponse,
  IKOLsListResponse,
  IUpdateKOLsDetailsDTO,
} from '@/models/kolsList.model';
import { KOLsListAPI } from '@/api';
import { formatApiError } from '@/utils';
import {
  IGetInvitedUsersDTO,
  IGetInvitedUsersResponse,
} from '@/models/sharedProfile.model';

export const getKOLsList = createAsyncThunk<
  IKOLsListResponse,
  IGetKOLsListDTO,
  { serializedErrorType: IApiError }
>(
  `${KOLS_LIST_SLICE_NAME}/getKOLsList`,
  async (data: IGetKOLsListDTO) => {
    const response = await KOLsListAPI.getKOLsList({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getKOLDetails = createAsyncThunk<
  IKOLDetailsResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${KOLS_LIST_SLICE_NAME}/getKOLDetails`,
  async (id: string) => {
    const response = await KOLsListAPI.getKOLDetails(id);
    return response;
  },
  { serializeError: formatApiError },
);

export const getKOLAffiliateData = createAsyncThunk<
  IKOLAffiliateResponse,
  IGetKOLInvestmentsDTO,
  { serializedErrorType: IApiError }
>(
  `${KOLS_LIST_SLICE_NAME}/getKOLAffiliateData`,
  async (data) => {
    const response = await KOLsListAPI.getKOLAffiliateData({
      ...data,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateKOL = createAsyncThunk<
  IKOL,
  IUpdateKOLsDetailsDTO,
  { serializedErrorType: IApiError }
>(
  `${KOLS_LIST_SLICE_NAME}/updateKOLDetails`,
  async (data) => {
    const response = await KOLsListAPI.updateKOLDetails(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getKOLSocialChannels = createAsyncThunk<
  IKOLDetailsResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${KOLS_LIST_SLICE_NAME}/getKOLSocialChannels`,
  async (id: string) => {
    const response = await KOLsListAPI.getKOLSocialChannels(id);
    return response;
  },
  { serializeError: formatApiError },
);

export const blockKOL = createAsyncThunk<
  IKOL,
  IBlockKOLUser,
  { serializedErrorType: IApiError }
>(
  `${KOLS_LIST_SLICE_NAME}/blockKOLUser`,
  async (data) => {
    const response = await KOLsListAPI.blockKOLUser(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getInvitedUsers = createAsyncThunk<
  IGetInvitedUsersResponse,
  IGetInvitedUsersDTO,
  { serializedErrorType: IApiError }
>(
  `${KOLS_LIST_SLICE_NAME}/getInvitedUsers`,
  async (data: IGetInvitedUsersDTO) => {
    const response = await KOLsListAPI.getInvitedUsers({ ...data, limit: 10 });
    return response.data;
  },
  { serializeError: formatApiError },
);
