import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { Text } from '@/ui';

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 3;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledInfo = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
  gap: 0.625rem;
`;

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const StyledSubTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledText = styled(Text)`
  :last-child {
    margin-bottom: 0;
  }
`;
