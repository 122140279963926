import { IBaseDataListItem } from '@/models/dataLists.model';

export const getOptionsWithDisabled = (
  arr: IBaseDataListItem[] = [],
  disabledOptions: IBaseDataListItem[] = [],
) => {
  const options = arr
    .map((item) => {
      const disabled = disabledOptions?.some?.(
        (option) => option.value === item.value,
      );

      return {
        ...item,
        disabled,
      };
    })
    .sort?.((a, b) => a.label.localeCompare(b.label));
  return options;
};
