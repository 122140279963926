// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { useNavigate } from 'react-router-dom';
import {
  DocumentsListWrapper,
  StyledInfoTitles,
  StyledTitles,
  StyledButtonWrapper,
  StyledButton,
} from './DocumentsListStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import DocumentCard from '../DocumentCard/DocumentCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { IClickWrapTemplateData } from '@/models/documents.model';

// Hooks
import { ROUTES_PATHS } from '@/router';

interface IDocumentsList {
  documents: IClickWrapTemplateData[] | null;
  isLoading: boolean;
}

const DocumentsList = ({ documents, isLoading }: IDocumentsList) => {
  const { t } = useTranslation(['documents']);
  const navigate = useNavigate();
  const goToDocumentCreate = () => navigate(ROUTES_PATHS.DOCUMENT_CREATE);

  const documentsList = useMemo(() => {
    return documents?.map((doc) => {
      return <DocumentCard data={doc} key={doc.templateType} />;
    });
  }, [documents]);

  return (
    <DocumentsListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('documents_list_document_type')}</StyledTitles>
          <StyledTitles>{t('documents_list_date')}</StyledTitles>
          <StyledTitles>{t('documents_list_version')}</StyledTitles>
          <StyledTitles>{t('documents_list_preview')}</StyledTitles>
          <StyledTitles></StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {documentsList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <StyledButtonWrapper>
        <StyledButton type="primary" onClick={goToDocumentCreate}>
          {t('documents_create')}
        </StyledButton>
      </StyledButtonWrapper>
    </DocumentsListWrapper>
  );
};

export default DocumentsList;
