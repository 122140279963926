import { ICampaignKols } from '@/models/campaigns.model';

const getCampaignKolsOptions = (campaignKols: ICampaignKols[]) => {
  return (
    campaignKols &&
    campaignKols.map((data) => ({
      value: data.id,
      label: data.name,
    }))
  );
};

export default getCampaignKolsOptions;
