import { combineReducers } from '@reduxjs/toolkit';
import { authReducer, AUTH_SLICE_NAME } from '@/modules/auth';
import { adaptiveReducer, ADAPTIVE_SLICE_NAME } from '@/modules/Adaptive';
import { kolsListReducer, KOLS_LIST_SLICE_NAME } from '@/modules/KolsList';
import { dataListsReducer, DATA_LISTS_SLICE_NAME } from '@/modules/DataLists';
import { dashboardReducer, DASHBOARD_SLICE_NAME } from '@/modules/Dashboard';
import { logsReducer, LOGS_SLICE_NAME } from '@/modules/Logs';
import { milestonesReducer, MILESTONES_SLICE_NAME } from '@/modules/Milestones';
import { uploadersReducer, UPLOADERS_SLICE_NAME } from '@/modules/Uploaders';
import { reportsReducer, REPORTS_SLICE_NAME } from '@/modules/Reports';
import {
  adminsListReducer,
  ADMINS_LIST_SLICE_NAME,
} from '@/modules/AdminsList';
import { projectsReducer, PROJECTS_SLICE_NAME } from '@/modules/Projects';
import {
  partnersListReducer,
  PARTNERS_LIST_SLICE_NAME,
} from '@/modules/PartnersList';
import {
  REQUESTS_LIST_SLICE_NAME,
  requestsListReducer,
} from '@/modules/Requests';
import {
  REQUESTS_DETAILS_SLICE_NAME,
  requestsDetailsReducer,
} from '@/modules/RequestDetails';
import { DOCUMENTS_SLICE_NAME, documentsReducer } from '@/modules/Documents';
import { SETTINGS_SLICE_NAME, settingsReducer } from '@/modules/Settings';
import { CAMPAIGNS_SLICE_NAME, campaignsReducer } from '@/modules/Campaigns';
import { PAYOUT_SLICE_NAME, payoutReducer } from '@/modules/Payout';
import {
  FUNDING_ROUND_SLICE_NAME,
  fundingRoundReducer,
} from '@/modules/FundingRounds';
import {
  SHARED_PROFILE_SLICE_NAME,
  sharedProfileReducer,
} from '@/modules/SharedProfile';
import {
  DATA_EXPORTS_SLICE_NAME,
  dataExportsReducer,
} from '@/modules/DataExports';
import {
  PERMISSION_SLICE_NAME,
  permissionReducer,
} from '@/modules/Permissions';
import {
  MARKETING_DELIVERY_SLICE_NAME,
  marketingDeliveryReducer,
} from '@/modules/MarketingDelivery';

export const rootReducer = combineReducers({
  [AUTH_SLICE_NAME]: authReducer,
  [ADAPTIVE_SLICE_NAME]: adaptiveReducer,
  [KOLS_LIST_SLICE_NAME]: kolsListReducer,
  [PARTNERS_LIST_SLICE_NAME]: partnersListReducer,
  [DATA_LISTS_SLICE_NAME]: dataListsReducer,
  [REQUESTS_LIST_SLICE_NAME]: requestsListReducer,
  [REQUESTS_DETAILS_SLICE_NAME]: requestsDetailsReducer,
  [SETTINGS_SLICE_NAME]: settingsReducer,
  [DASHBOARD_SLICE_NAME]: dashboardReducer,
  [LOGS_SLICE_NAME]: logsReducer,
  [MILESTONES_SLICE_NAME]: milestonesReducer,
  [PROJECTS_SLICE_NAME]: projectsReducer,
  [CAMPAIGNS_SLICE_NAME]: campaignsReducer,
  [PAYOUT_SLICE_NAME]: payoutReducer,
  [DOCUMENTS_SLICE_NAME]: documentsReducer,
  [SHARED_PROFILE_SLICE_NAME]: sharedProfileReducer,
  [ADMINS_LIST_SLICE_NAME]: adminsListReducer,
  [DATA_EXPORTS_SLICE_NAME]: dataExportsReducer,
  [FUNDING_ROUND_SLICE_NAME]: fundingRoundReducer,
  [PERMISSION_SLICE_NAME]: permissionReducer,
  [UPLOADERS_SLICE_NAME]: uploadersReducer,
  [REPORTS_SLICE_NAME]: reportsReducer,
  [MARKETING_DELIVERY_SLICE_NAME]: marketingDeliveryReducer,
});
