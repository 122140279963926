// Globals
import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

// Components
import { SearchIcon, CloseIcon } from '@/icons';
import { StyledInput } from './SearchInputStyles';
import { Spinner } from '@/ui';

interface ISearchInput {
  isLoading?: boolean;
  allowClear?: boolean;
  handleChange: (value: string) => void;
  placeholder?: string;
  resetValue?: string;
}

const SearchInput = ({
  isLoading,
  handleChange,
  allowClear,
  placeholder = 'Search ...',
  resetValue,
}: ISearchInput) => {
  const { t } = useTranslation(['common']);

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue('');
  }, [resetValue]);

  const debouncedHandleChange = useMemo(
    () =>
      debounce((query: string) => {
        handleChange(query ?? '');
      }, 500),
    [handleChange],
  );

  const onChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const { value } = evt.target;
      setValue(value);
      debouncedHandleChange(value);
    },
    [debouncedHandleChange],
  );

  return (
    <StyledInput
      value={value}
      onChange={onChange}
      bordered={false}
      prefix={<SearchIcon />}
      suffix={isLoading && <Spinner />}
      allowClear={allowClear && { clearIcon: <CloseIcon /> }}
      placeholder={t(placeholder)}
    />
  );
};

export default forwardRef(SearchInput);
