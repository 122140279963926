import dayjs from 'dayjs';
import differenceWith from 'lodash.differencewith';
import isEqual from 'lodash.isequal';
import { ILogs } from '@/models/logs.model';
import { DATE_FORMATS } from '@/constants';

export const logsDataNormalizer = (logs: ILogs) => {
  const postDiff = differenceWith(
    logs?.difference?.newData?.data?.urls,
    logs?.difference?.oldData?.data?.urls ?? [],
    isEqual,
  );
  const publishDate =
    postDiff?.[0]?.publishDate &&
    dayjs(postDiff?.[0]?.publishDate).format(DATE_FORMATS.DATE_WITH_DOTS);

  return {
    status: postDiff?.[0]?.status ?? logs?.difference.newData?.status,
    reason: postDiff?.[0]?.reason ?? publishDate ?? logs?.reason,
    url: postDiff?.[0]?.url ?? '',
    created: logs?.created,
    adminEmail: logs?.adminEmail,
  };
};
