// Globals
import { useState } from 'react';

// Modules
import { selectCryptoCurrencies } from '@/modules/DataLists';

// Models
import { IBaseDataListItem } from '@/models/dataLists.model';
import { WalletNetworksEnum } from '@/models/web3.model';

// Hooks
import { useAppState } from '@/hooks';

const useGetCurrency = (initialWalletNetwork: string) => {
  const cryptoCurrencies = useAppState(selectCryptoCurrencies);
  const [walletNetwork, setWalletNetwork] = useState<
    string | number | string[] | number[]
  >('');

  const isTestnet =
    initialWalletNetwork?.toUpperCase() === WalletNetworksEnum.SEPOLIA ||
    (walletNetwork as string)?.toUpperCase() === WalletNetworksEnum.SEPOLIA;

  const cryptoCurrenciesList = cryptoCurrencies.list?.reduce((acc, list) => {
    if (
      list.network.name ==
      ((walletNetwork as string) || initialWalletNetwork)?.toLowerCase()
    ) {
      acc.push({
        label: list.name,
        value: list.symbol,
      });
    }
    return acc;
  }, [] as IBaseDataListItem[]);

  const handleChange = (item: string | number | string[] | number[]) => {
    setWalletNetwork(item);
  };

  if (isTestnet) {
    cryptoCurrenciesList?.push({
      label: 'SEPOLIA',
      value: 'SEPOLIA',
    });
  }

  return { cryptoCurrenciesList, handleChange };
};

export default useGetCurrency;
