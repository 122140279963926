// Globals
import React from 'react';

// Components
import {
  StyledImage,
  StyledLogoWrapper,
  StyledImageWrapper,
} from '../Styled/Styled';

interface ICardImage {
  campaignBanner?: string;
  projectImage?: string;
}

const CardImage = ({ campaignBanner, projectImage }: ICardImage) => {
  return (
    <>
      <StyledImageWrapper>
        <StyledImage
          src={campaignBanner ?? '/images/stub_image.png'}
          alt="campaign"
          width={340}
          height={160}
          preview={false}
          isImage
        />
      </StyledImageWrapper>
      <StyledLogoWrapper>
        <StyledImage
          src={projectImage ?? '/images/stub_image.png'}
          alt="campaign"
          width={50}
          height={50}
          preview={false}
        />
      </StyledLogoWrapper>
    </>
  );
};

export default CardImage;
