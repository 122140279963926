// Globals
import React from 'react';

// Components
import {
  StyledFileIconWrapper,
  StyledStatusesWrapper,
} from './StatusIconBlockStyles';
import { TextFileIcon } from '@/icons';
import StatusIcon from '../StatusIcon/StatusIcon';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Helpers
import { InvestmentStatuses } from '@/constants';

interface IStatusIconBlock {
  contractLink?: string;
  status?: string;
}

const StatusIconBlock = ({ status, contractLink }: IStatusIconBlock) => {
  return (
    <StyledStatusesWrapper>
      {status === InvestmentStatuses.ACCEPTED && contractLink && (
        <DesktopOnly>
          <StyledFileIconWrapper>
            <TextFileIcon />
          </StyledFileIconWrapper>
        </DesktopOnly>
      )}
      <StatusIcon status={status} />
    </StyledStatusesWrapper>
  );
};

export default StatusIconBlock;
