import { Dayjs } from 'dayjs';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import 'antd/lib/date-picker/style/index';

import { StyledDatePicker } from './DatePickerStyles';

type IDatePicker = PickerProps<Dayjs> & { showTime?: boolean };

const DatePicker = ({ value, ...props }: IDatePicker) => (
  <StyledDatePicker
    {...props}
    value={value}
    placeholder=""
    onFocus={(e) => !props.showTime && e.target.blur()}
  />
);

export default DatePicker;
