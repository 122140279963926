import styled from 'styled-components';
import { Form } from '@/components';
import { Button } from '@/ui';
import { DEVICES } from '@/theme';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0.6rem;
`;

export const FormWrapper = styled.div`
  width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  height: 2rem !important;
`;
