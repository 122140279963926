// Globals
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { ConfirmationModal, FormItem, FormList } from '@/components';
import {
  StyledAddButton,
  StyledWrapper,
  StyledTextArea,
  StyledInput,
  StyledIconWrapper,
  FormFields,
  StyledRow,
} from './ContractInformationItemsStyles';
import { Tooltip } from '@/ui';
import { DeleteCircleIcon } from '@/icons';

// Hooks | Helpers
import { ContractInfoFieldsNames } from '../../types';
import { useGetContractFormValidationRules } from '../../hooks';
import { TextLength } from '@/constants';
import { useModal } from '@/hooks';

interface IContractInformationItems {
  disabled?: boolean;
}

const ContractInformationItems = ({ disabled }: IContractInformationItems) => {
  const { t } = useTranslation(['projectsList']);
  const validationRules = useGetContractFormValidationRules();
  const [fieldName, setFieldName] = useState<number>(0);
  const { isOpened, openModal, closeModal } = useModal();

  const handleOpenModal = (key: number) => {
    setFieldName(key);
    openModal();
  };

  return (
    <FormList name="items">
      {(fields, { add, remove }) => {
        return (
          <StyledWrapper>
            {fields.map((field) => {
              return (
                <FormFields key={field.key}>
                  <FormItem
                    name={[field.name, ContractInfoFieldsNames.TITLE]}
                    label={t('contract_information_title')}
                    rules={validationRules[ContractInfoFieldsNames.TITLE]}
                  >
                    <StyledInput />
                  </FormItem>
                  <FormItem
                    name={[field.name, ContractInfoFieldsNames.DESCRIPTION]}
                    label={t('contract_information_description_title')}
                    rules={validationRules[ContractInfoFieldsNames.DESCRIPTION]}
                  >
                    <StyledTextArea
                      rows={5}
                      showCount
                      maxLength={TextLength.semiLarge}
                    />
                  </FormItem>
                  {!disabled && fields.length > 1 ? (
                    <StyledIconWrapper
                      onClick={() => handleOpenModal(field.name)}
                    >
                      <DeleteCircleIcon />
                    </StyledIconWrapper>
                  ) : null}
                </FormFields>
              );
            })}
            <ConfirmationModal
              isOpened={isOpened}
              title={t('contract_information_remove_item_text')}
              onClose={closeModal}
              onConfirm={() => {
                remove(fieldName);
                closeModal();
              }}
            />
            <StyledRow>
              <Tooltip
                getPopupContainer={(triggerNode) => triggerNode}
                title={
                  (fields?.length ?? 0) >= 20
                    ? t('contract_information_limit_message')
                    : undefined
                }
                placement={'topLeft'}
              >
                <StyledAddButton
                  onClick={() => add({})}
                  type="primary"
                  disabled={disabled ?? (fields?.length ?? 0) >= 20}
                >
                  {t('contract_information_add_contract_item')}
                </StyledAddButton>
              </Tooltip>
            </StyledRow>
          </StyledWrapper>
        );
      }}
    </FormList>
  );
};

export default ContractInformationItems;
