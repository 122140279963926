// Globals
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

// Components
import { showErrorToast } from '@/components';
import { NFTFundingBucketHistory } from '../../components';

// Hooks | Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import { settingsSliceSelector } from '../../feature/selectors';
import { getNFTCreditsBucketHistory } from '../../feature/actionCreators';

const NftCreditsBucketHistoryContainer = () => {
  const { t } = useTranslation(['settings', 'profile']);
  const dispatch = useAppDispatch();

  const { isLoading, nftCreditsBucketHistory } = useAppState(
    settingsSliceSelector,
  );

  const getNextHistoryItems = useCallback(
    debounce(async () => {
      if (isLoading || !nftCreditsBucketHistory?.hasMore) return;
      const lastItem = nftCreditsBucketHistory?.items?.at(-1);
      const requestsList = await dispatch(
        getNFTCreditsBucketHistory({
          startSk: lastItem?.sk,
        }),
      ).unwrap();

      requestsList?.items?.length === 0 &&
        showErrorToast({
          message: t('empty_data_massage_request', { ns: 'profile' }),
        });
    }, 1000),
    [isLoading],
  );

  return (
    <NFTFundingBucketHistory
      data={nftCreditsBucketHistory}
      isLoading={isLoading}
      getNextItems={getNextHistoryItems}
    />
  );
};

export default NftCreditsBucketHistoryContainer;
