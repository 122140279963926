import { IMainContact } from '@/models/sharedProfile.model';
import { IGetListParamsBase } from '@/models/api.model';
import { CryptoCurrenciesEnum } from '@/models/web3.model';

export enum AdminsListEndpoints {
  GET_ADMINS_LIST = '/admin/admins',
  GET_ADMIN_PERMISSIONS = '/admin/permissions',
}

export enum AdminActionsEnum {
  FULL_ADMIN = 'FULL_ADMIN',
  READ_ALL_REQUESTS = 'READ_ALL_REQUESTS',
  SETTINGS = 'SETTINGS',
  KOL_PAYOUT = 'KOL_PAYOUT',
  DOCUMENTS = 'DOCUMENTS',
  READ_PROJECT = 'READ_PROJECT',
  WRITE_PROJECT = 'WRITE_PROJECT',
  READ_CAMPAIGN = 'READ_CAMPAIGN',
  WRITE_CAMPAIGN = 'WRITE_CAMPAIGN',
  READ_KOL = 'READ_KOL',
  WRITE_KOL = 'WRITE_KOL',
  READ_AFFILIATE = 'READ_AFFILIATE',
  WRITE_AFFILIATE = 'WRITE_AFFILIATE',
  READ_PARTNER = 'READ_PARTNER',
  WRITE_PARTNER = 'WRITE_PARTNER',
  READ_REFUND_REQUEST = 'READ_REFUND_REQUEST',
  WRITE_REFUND_REQUEST = 'WRITE_REFUND_REQUEST',
  READ_PROFILE_CHANGE_REQUEST = 'READ_PROFILE_CHANGE_REQUEST',
  WRITE_PROFILE_CHANGE_REQUEST = 'WRITE_PROFILE_CHANGE_REQUEST',
  READ_ADMINS = 'READ_ADMINS',
  READ_FUNDING_ROUND = 'READ_FUNDING_ROUND',
  WRITE_FUNDING_ROUND = 'WRITE_FUNDING_ROUND',
}

export const readActions = new Set([
  AdminActionsEnum.READ_PROJECT,
  AdminActionsEnum.READ_CAMPAIGN,
  AdminActionsEnum.READ_KOL,
  AdminActionsEnum.READ_AFFILIATE,
  AdminActionsEnum.READ_PARTNER,
  AdminActionsEnum.READ_REFUND_REQUEST,
  AdminActionsEnum.READ_PROFILE_CHANGE_REQUEST,
]);

export const readRequestActions = new Set([
  AdminActionsEnum.READ_PROFILE_CHANGE_REQUEST,
  AdminActionsEnum.READ_REFUND_REQUEST,
]);

export interface IAdmin {
  adminId: string;
  roleId: string;
  firstName: string;
  lastName: string;
  email: string;
  allowedActions: AdminActionsEnum[];
  disallowedActions?: AdminActionsEnum[];
}

// ============= DTO =============

export interface IAdminsDetailsDTO {
  formatAddress?: string;
  address?: string;
  mainContact?: IMainContact;
  walletAddress?: string;
  walletCurrency?: CryptoCurrenciesEnum;
}

export interface IGetAdminsListDTO {
  search?: string;
}

export interface IGetAdminInvestmentsDTO extends IGetListParamsBase {
  AdminId?: string;
}

export interface IUpdateAdminsDetailsDTO {
  Admin: IAdminsDetailsDTO;
  id: string;
}

export interface IBlockAdminUser {
  id?: string;
  reason?: string;
}

// ============== Response ==============

export interface IAdminDetailsResponse {
  data: IAdmin;
}
