import { useIsMobile } from '../../hooks';

interface IMobileOnly {
  children: JSX.Element;
}
const MobileOnly = ({ children }: IMobileOnly): JSX.Element | null => {
  const { isMobile } = useIsMobile();
  return isMobile ? children : null;
};

export default MobileOnly;
