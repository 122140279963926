// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledTitles,
  DeliveryWrapper,
  StyledInfoTitles,
  StyledDeliveryTitle,
  StyledHeader,
} from './AffiliateWithdrawalHistoryStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import WithdrawalHistoryCard from './WithdrawalHistoryCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { IWithdrawalHistory } from '@/models/sharedProfile.model';

interface IAffiliateWithdrawalHistory {
  withdrawalHistory: IWithdrawalHistory[] | null;
  isLoading: boolean;
}

const AffiliateWithdrawalHistory = ({
  withdrawalHistory,
  isLoading,
}: IAffiliateWithdrawalHistory) => {
  const { t } = useTranslation(['profile']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedWithdrawalHistoryList = useMemo(() => {
    return withdrawalHistory?.map((data) => {
      return <WithdrawalHistoryCard data={data} key={data.id} />;
    });
  }, [withdrawalHistory]);

  if (withdrawalHistory?.length === 0) return null;

  return (
    <DeliveryWrapper>
      <StyledHeader>
        <StyledDeliveryTitle>
          {t('affiliate_withdrawal_history')}
        </StyledDeliveryTitle>
      </StyledHeader>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('affiliate_withdrawal_history_date')}</StyledTitles>
          <StyledTitles>{t('affiliate_withdrawal_history_title')}</StyledTitles>
          <StyledTitles>
            {t('affiliate_withdrawal_history_reward')}
          </StyledTitles>
          <StyledTitles>
            {t('affiliate_withdrawal_history_wallet')}
          </StyledTitles>
          <StyledTitles>
            {t('affiliate_withdrawal_history_status')}
          </StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedWithdrawalHistoryList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </DeliveryWrapper>
  );
};

export default AffiliateWithdrawalHistory;
