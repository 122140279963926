// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  NewRequestCardWrapper,
  StyledInfoWrapper,
  StyledTitle,
  StyledText,
} from './RequestCardStyles';
import { MobileOnly } from '@/modules/Adaptive';
import { ArrowRightIcon } from '@/icons';
import { Link } from '@/ui';

// Routes
import { ROUTES_PATHS } from '@/router';

// Models
import {
  IRequest,
  RequestStatuses,
  RequestsTypesKeys,
} from '@/models/requests.model';

// Modules
import { getRequestStatuses } from '@/modules/RequestDetails/helpers';

// Utils | Helpers
import { getDateString, getRoleFromId } from '@/utils';
import { getResponsibleLabel } from '../../helpers/getResponsibleLabel';
import { DATE_FORMATS } from '@/constants';

interface INewRequestCard {
  request: IRequest;
  numbering: number;
  status?: RequestStatuses;
}

const RequestCard = ({ request, numbering, status }: INewRequestCard) => {
  const { t } = useTranslation(['requestsList']);
  const actionType =
    request.actionType === RequestsTypesKeys.PROFILE_PRE_VERIFICATION
      ? RequestsTypesKeys.PROFILE_VERIFICATION
      : request.actionType;
  return (
    <NewRequestCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('requests_list_id_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{numbering}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('requests_list_date_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {getDateString(request.created, DATE_FORMATS.DATE_WITH_TIME)}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('requests_list_name_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{request.requesterFullName}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('requests_list_user_type')}</StyledTitle>
        </MobileOnly>
        <StyledText>{getRoleFromId(request.createdById)}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('requests_list_responsible')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {getResponsibleLabel(t, request.actionType ?? '')}
        </StyledText>
      </StyledInfoWrapper>
      {status === RequestStatuses.IN_PROGRESS && (
        <StyledInfoWrapper>
          <MobileOnly>
            <StyledTitle>{t('requests_list_status')}</StyledTitle>
          </MobileOnly>
          <StyledText>{getRequestStatuses(t, request?.status)}</StyledText>
        </StyledInfoWrapper>
      )}
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('requests_list_type_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{t(`requests_${request?.actionType}_label`)}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <StyledText>
          <Link
            to={`${ROUTES_PATHS.REQUESTS}/${actionType}/${encodeURIComponent(
              request.id ?? '',
            )}`}
          >
            <ArrowRightIcon />
          </Link>
        </StyledText>
      </StyledInfoWrapper>
    </NewRequestCardWrapper>
  );
};

export default RequestCard;
