export enum AuthEndpoints {
  SIGN_IN = '/auth/admin/sign-in',
  SIGN_IN_VERIFY = '/auth/admin/sign-in/verify',
  REFRESH_TOKEN = '/auth/admin/sign-in/refresh',
  LOG_OUT = '/auth/admin/log-out',
}

export enum AdminSignInActions {
  SIGN_IN = 'SIGN_IN',
  CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD',
  SETUP_MFA = 'SETUP_MFA',
  CONFIRM_OTP = 'CONFIRM_OTP',
}

// ============== DTO ==============

export interface ISignInDTO {
  email: string;
  password: string;
}

export interface ISignInVerifyDTO {
  data: string;
  session: string;
}

export interface IRefreshTokenDTO {
  refreshToken: string;
}

export interface ILogOutDTO {
  refreshToken: string;
}

// ============== Response ==============

export interface ISignInResponse {
  action: AdminSignInActions;
  qrCode?: string;
  secretCode?: string;
  session: string;
}

export interface ISignInAuthorizedResponse {
  idToken: string;
  refreshToken: string;
}
