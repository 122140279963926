import styled from 'styled-components';
import { BlockLayout, Button } from '@/ui';
import { Form, FormItem } from '@/components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { ChevronIcon } from '@/icons';

export const StyledWrapper = styled(BlockLayout)`
  display: flex;
  width: 100%;
  padding: 1.5rem;
  margin-top: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

export const StyledFrom = styled(Form)`
  display: flex;
  width: 100%;
  gap: 1.5rem;
  flex-direction: column;
`;

export const StyledFormItem = styled(FormItem)`
  width: 100%;
  margin-bottom: 0.5rem;
  flex-direction: column;

  &&& .ant-form-item-row {
    align-items: flex-start;
    flex-direction: column;

    .ant-form-item-control {
      width: 100%;
    }
  }

  label {
    ${pickThemeFontStyles('15', '18', 'NORMAL')};
    color: ${COLORS.CORN_FLOWER_BLUE}!important;
  }
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('12', '24', 'NORMAL')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledButtonWrapper = styled.div<{ $isFlexEnd?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${({ $isFlexEnd }) =>
    $isFlexEnd ? 'flex-end' : 'space-between'};
`;

export const StyledButton = styled(Button)<{ $isColored?: boolean }>`
  display: flex;
  margin-top: 2rem;
  gap: 0.5rem;
  &&& {
    max-width: 12rem;
    height: 2rem;
    background: ${({ $isColored }) =>
      $isColored ? COLORS.BLUE_PRIMARY : COLORS.SOLITUDE_GRAY};
    color: ${({ $isColored }) =>
      $isColored ? COLORS.WHITE : COLORS.DARK_BLUE};
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const StyledChevronIcon = styled(ChevronIcon)<{
  rotate: number;
  $isColored?: boolean;
}>`
  transform: rotate(${({ rotate }) => rotate}deg);

  path {
    fill: ${({ $isColored }) => ($isColored ? COLORS.BLACK : COLORS.WHITE)};
  }
`;
