// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  Wrapper,
  BlockWrapper,
  StyledText,
  StyledInputWrapper,
  NavigationWrapper,
  StyledButton,
  StyledTitle,
  StyledHeaderWrapper,
} from '../Styled';
import { Link, StyledInput } from '@/ui';
import ActionButtons from '../ActionButtons/ActionButtons';
import RequestStatusBadges from '../RequestStatusBadges/RequestStatusBadges';

// Models
import { IRequestDetails } from '@/models/requests.model';

// Helpers | Hooks
import { getDateString, getUserLink } from '@/utils';

interface IAffiliateWithdrawalRequestDetails {
  request: IRequestDetails | null;
  isLoading?: boolean;
}

const AffiliateWithdrawalRequestDetails = ({
  request,
  isLoading,
}: IAffiliateWithdrawalRequestDetails) => {
  const { t } = useTranslation(['requestsList']);
  const { firstName, lastName, email, mainContact, role, id, created } =
    request?.requester ?? {};

  const goToRequester = getUserLink(role ?? '', id ?? '');
  const actionTypeLabel = t(`requests_${request?.actionType}_label`);

  return (
    <Wrapper>
      <StyledHeaderWrapper>
        <StyledTitle>{t('request_title')}</StyledTitle>
        <RequestStatusBadges status={request?.status} />
      </StyledHeaderWrapper>
      <StyledText>{`${t('requests_type')} ${actionTypeLabel}`}</StyledText>
      <NavigationWrapper>
        <StyledTitle $type="h2">
          {t(`requests_profile_${role}_label`)}
        </StyledTitle>
        <Link to={goToRequester ?? ''} target="_blank">
          <StyledButton type="primary">
            {t('verification_request_go_to_profile')}
          </StyledButton>
        </Link>
      </NavigationWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_name_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={firstName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_last_name_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={lastName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_registration_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={getDateString(created ?? '')} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_email_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={email} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_contact_source')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={mainContact?.source} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_contact')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={mainContact?.contact} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('refund_request_wallet_address_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={request?.walletAddress} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('requests_affiliate_withdrawal_amount')}</StyledText>
        <StyledInputWrapper>
          <StyledInput
            value={request?.totalAffiliateWithdrawalAmount}
            disabled
          />
        </StyledInputWrapper>
      </BlockWrapper>
      <ActionButtons request={request} isLoading={isLoading} />
    </Wrapper>
  );
};

export default AffiliateWithdrawalRequestDetails;
