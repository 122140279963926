import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout } from '@/ui';

export const StyledBlock = styled(BlockLayout)<{
  $isRow?: boolean;
}>`
  &&& {
    display: flex;
    justify-content: space-between;
    flex-direction: ${({ $isRow }) => ($isRow ? 'row' : 'column')};
    align-items: flex-start;
    color: ${COLORS.CORN_FLOWER_BLUE};
    gap: 1rem;
    width: auto;

    @media screen and ${DEVICES.LAPTOP_S} {
      justify-content: normal;
    }
  }
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('14', '22', 'SEMI_NORMAL')};
  white-space: pre-line;
`;

export const StyledA = styled.a`
  color: ${COLORS.BLUE_PRIMARY};
  word-break: break-all;
  ${pickThemeFontStyles('14', '16', 'SEMI_BOLD')};
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

export const StyledChannelsWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
`;

export const StyledChannels = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  cursor: auto;
  border: 1px solid ${COLORS.GRAY};
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('18', '22', 'BOLD')};
  align-self: flex-start;
  color: ${COLORS.BLACK};
  word-break: break-all;
`;
