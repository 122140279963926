// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd';
import { useWatch } from 'antd/es/form/Form';

// Components
import { StyledBlockWrapper, StyledTitle } from '../Styles';
import { SettingsWrapper } from './TopBarStyles';
import { FormItemGroup, NestedFormItem } from '@/components';
import { Checkbox, TextArea } from '@/ui';

// Hooks
import { useGetFormValidationRules } from '../../hooks';
import { TopBarsSettingsFieldsNames } from '../../helpers';

interface ITopBar {
  isEditActive: boolean;
  formInstance: FormInstance;
  topBarName: TopBarsSettingsFieldsNames;
}

const TopBar = ({ isEditActive, topBarName, formInstance }: ITopBar) => {
  const { t } = useTranslation(['settings']);
  const validationRules = useGetFormValidationRules();
  const isActive = useWatch(
    [topBarName, TopBarsSettingsFieldsNames.ACTIVE],
    formInstance,
  );
  return (
    <StyledBlockWrapper>
      <StyledTitle>{t(`settings_${topBarName}_top_bar_title`)}</StyledTitle>
      <FormItemGroup prefix={topBarName}>
        <SettingsWrapper>
          <NestedFormItem
            name={TopBarsSettingsFieldsNames.TEXT}
            rules={validationRules[TopBarsSettingsFieldsNames.TEXT]}
            label={t('settings_top_bar_text_title')}
          >
            <TextArea disabled={!isEditActive} />
          </NestedFormItem>
          <NestedFormItem
            name={TopBarsSettingsFieldsNames.ACTIVE}
            label={t('settings_top_bar_active_title')}
            valuePropName="checked"
          >
            <Checkbox disabled={!isEditActive} />
          </NestedFormItem>
          <NestedFormItem
            name={TopBarsSettingsFieldsNames.PRIORITY}
            label={t('settings_top_bar_priority_title')}
            valuePropName="checked"
          >
            <Checkbox disabled={!isEditActive || !isActive} />
          </NestedFormItem>
        </SettingsWrapper>
      </FormItemGroup>
    </StyledBlockWrapper>
  );
};

export default TopBar;
