import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { PERMISSION_SLICE_NAME, PermissionsState } from './models';

export const adminsListSliceSelector = (state: RootState): PermissionsState =>
  state[PERMISSION_SLICE_NAME];

export const selectPermissionIsLoading = createSelector(
  adminsListSliceSelector,
  (state: PermissionsState) => state.isLoading,
);

export const selectAdminPermissions = createSelector(
  adminsListSliceSelector,
  (state: PermissionsState) => state.permissions,
);
