import styled from 'styled-components';

export const StyledInfo = styled.div`
  display: flex;
  width: 100%;
  flex: 3;
  flex-direction: column;
  position: relative;
  gap: 0.625rem;

  :nth-last-child(2) {
    flex: 0.1;
  }
`;
