import styled from 'styled-components';
import { Modal } from 'antd';
import { pickThemeFontStyles } from '@/theme';
import { Button } from '@/ui';

export const StyledModal = styled(Modal)``;

export const StyledWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  justify-content: space-between;
`;

export const StyledActionButton = styled(Button)`
  &&& {
    max-width: 8rem;
    height: 3rem;
    ${pickThemeFontStyles('14', '18', 'SEMI_BOLD')};
    align-items: center;
  }
`;
