export enum RouteEntitiesEnum {
  KOL = 'kols',
  PROJECT = 'projects',
  PARTNER = 'partners',
  REQUEST = 'requests',
  CAMPAIGN = 'campaigns',
}

export interface IGetListParamsBase {
  startId?: string;
  limit?: number;
}
