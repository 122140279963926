import { DesktopOnly } from '@/modules/Adaptive';
import { IPartnersDetailsTabsNames } from '../helpers/types';
import { DashboardIcon, FileIcon, AffiliateIcon, FlagIcon } from '@/icons';

export enum AccountStatuses {
  PENDING = 'pending',
  VERIFIED = 'verified',
  DECLINED = 'declined',
  IN_VERIFICATION = 'in_verification',
}

export enum PartnerDetailsTabKeys {
  GENERAL_INFO = 'general-info',
  LEGAL_AGREEMENTS = 'Legal Agreements',
  AFFILIATE = 'affiliate',
  AFFILIATE_FUNDING = 'affiliate-funding',
  SEED_HUNTER_CREDITS = 'seed-hunter-credits',
}

export const partnersTabsConfig = [
  {
    label: 'general-info',
    key: PartnerDetailsTabKeys.GENERAL_INFO,
    name: IPartnersDetailsTabsNames.GENERAL_INFO,
    icon: (
      <DesktopOnly>
        <DashboardIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'legal-agreements',
    key: PartnerDetailsTabKeys.LEGAL_AGREEMENTS,
    name: IPartnersDetailsTabsNames.LEGAL_AGREEMENTS,
    icon: (
      <DesktopOnly>
        <FileIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'affiliate',
    key: PartnerDetailsTabKeys.AFFILIATE,
    name: IPartnersDetailsTabsNames.AFFILIATE,
    icon: (
      <DesktopOnly>
        <AffiliateIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'affiliate-funding',
    key: PartnerDetailsTabKeys.AFFILIATE_FUNDING,
    name: IPartnersDetailsTabsNames.AFFILIATE_FUNDING,
    icon: (
      <DesktopOnly>
        <AffiliateIcon />
      </DesktopOnly>
    ),
  },
  {
    label: 'seed-hunter-credits',
    key: PartnerDetailsTabKeys.SEED_HUNTER_CREDITS,
    name: IPartnersDetailsTabsNames.SEED_HUNTER_CREDITS,
    icon: (
      <DesktopOnly>
        <FlagIcon />
      </DesktopOnly>
    ),
  },
];
