import styled from 'styled-components';
import { DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';

export const PayoutListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
`;

export const StyledButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledLinkButton = styled(Button)`
  display: flex;
  &&& {
    max-width: 6rem;
    height: 2rem;
    ${pickThemeFontStyles('12', '16', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;

  :first-child {
    align-items: flex-start;
  }

  :nth-last-child(2) {
    align-items: flex-start;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    :last-child {
      align-items: center;
    }

    :nth-last-child(2) {
      align-items: center;
    }
  }
`;

export const StyledHeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledHeaderBlock = styled(BlockLayout)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
