import styled from 'styled-components';
import { BlockLayout } from '@/ui';
import { DEVICES, pickThemeFontStyles } from '@/theme';

export const StyledContentWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column-reverse;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const StyledStatus = styled.div`
  display: flex;
  gap: 0.625rem;
  width: 16rem;
  align-items: center;
`;

export const StyledFields = styled.div`
  width: 100%;
  flex: 3;
`;

export const HeaderWrapper = styled(BlockLayout)`
  display: flex;
  padding: 1rem;
  gap: 0.5rem;
  margin: 1.5rem 0;
  ${pickThemeFontStyles('18', '22', 'BOLD')};
  text-align: center;
`;

export const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    transform: scale(1.2);
  }
`;
