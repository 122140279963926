// Globals
import React, { useMemo } from 'react';

// Components
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  StyledCardWrapper,
  StyledCount,
  StyledButton,
} from './RequestsBlockStyles';
import { ColumnChart } from '@/components';

// Models
import { IRequestsStatistics } from '@/models/dashboard.model';

// Utils
import { RequestsStatisticTypes } from '@/modules/Dashboard/constants/common';
import { ArrowRightIcon } from '@/icons';
import { ROUTES_PATHS } from '@/router';

interface IRequestsBlock {
  data: IRequestsStatistics | null;
}

const RequestsBlock = ({ data }: IRequestsBlock) => {
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();
  const mappedData = useMemo(() => {
    return Object.keys(data ?? {}).map((key) => ({
      label: t(`dashboard_${key}`),
      id: key,
      value: data?.[key as RequestsStatisticTypes],
    }));
  }, [data]);
  const goToRequests = () => navigate(ROUTES_PATHS.REQUESTS);

  return (
    <StyledCardWrapper>
      <StyledCount>{t('dashboard_requests')}</StyledCount>
      <ColumnChart data={mappedData} />
      <StyledButton type="primary" onClick={goToRequests}>
        {t('dashboard_go_to_requests')}
        <ArrowRightIcon rotate={-90} />
      </StyledButton>
    </StyledCardWrapper>
  );
};

export default RequestsBlock;
