import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { LOGS_SLICE_NAME } from './models';
import { formatApiError } from '@/utils';
import {
  ILogsListResponse,
  ILogsDTO,
  ISettingsLogsDTO,
} from '@/models/logs.model';
import LogsApi from 'src/api/LogsApi';

export const getLogs = createAsyncThunk<
  ILogsListResponse,
  ILogsDTO,
  { serializedErrorType: IApiError }
>(
  `${LOGS_SLICE_NAME}/getLogsList`,
  async (data: ILogsDTO) => {
    const response = await LogsApi.getLogs({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getSettingsLogs = createAsyncThunk<
  ILogsListResponse,
  ISettingsLogsDTO,
  { serializedErrorType: IApiError }
>(
  `${LOGS_SLICE_NAME}/getSettingsLogs`,
  async (data: ILogsDTO) => {
    const response = await LogsApi.getSettingsLogs({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);
