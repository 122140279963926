// Globals
import React from 'react';

// Components
import {
  StyledSectionTab,
  StyledTab,
  StyledIconWrapper,
} from './SectionTabStyles';
import { Text } from '@/ui';
import { ISectionConfig } from '../Tabs/Tabs';
import CountBadge from '../CountBadge/CountBadge';

interface IProps {
  section: ISectionConfig;
  isActive: boolean;
  onClick: (key: string) => void;
  count?: number;
  withCount?: boolean;
}

const SectionTab = ({
  section,
  isActive,
  onClick,
  count,
  withCount,
}: IProps) => {
  const handleClick = () => onClick(section.key);

  return (
    <StyledTab $isActive={isActive} onClick={handleClick}>
      <StyledSectionTab>
        {withCount && !!count && <CountBadge count={count ?? 0} />}
        <StyledIconWrapper>{section.icon}</StyledIconWrapper>
        <Text>{section.name}</Text>
      </StyledSectionTab>
    </StyledTab>
  );
};

export default SectionTab;
