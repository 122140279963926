// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledInput } from '@/ui';
import { Form, IFormInstance, Modal, ModalSize } from '@/components';
import { StyledFormItem } from './AddCreditModalStyles';
import { StyledButton } from '../Styled/Styled';

// Models
import { ICreditHistory } from '@/models/sharedProfile.model';

// Helpers
import { CreditFieldsNames } from '../../helpers';
import { useGetCreditFormValidationRules } from '../../hooks';

interface IAddCreditModal {
  isOpened: boolean;
  handleClose: () => void;
  formInstance: IFormInstance;
  handleSubmit: (values: ICreditHistory) => void;
}

const AddCreditModal = ({
  isOpened,
  handleClose,
  formInstance,
  handleSubmit,
}: IAddCreditModal) => {
  const { t } = useTranslation(['profile']);
  const validationRules = useGetCreditFormValidationRules();

  return (
    <Modal visible={isOpened} onCancel={handleClose} size={ModalSize.MEDIUM}>
      <Form form={formInstance} onFinish={handleSubmit}>
        <StyledFormItem
          name={CreditFieldsNames.TITLE}
          rules={validationRules[CreditFieldsNames.TITLE]}
          label={t('credit_history_event_name')}
        >
          <StyledInput placeholder={t('credit_history_event_name')} />
        </StyledFormItem>
        <StyledFormItem
          name={CreditFieldsNames.AMOUNT}
          rules={validationRules[CreditFieldsNames.AMOUNT]}
          label={t('credit_history_credit_amount')}
        >
          <StyledInput
            type="number"
            placeholder={t('credit_history_credit_amount')}
          />
        </StyledFormItem>
        <StyledButton type="primary" htmlType="submit">
          {t('credit_history_confirm_button')}
        </StyledButton>
      </Form>
    </Modal>
  );
};

export default AddCreditModal;
