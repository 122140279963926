import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  width: 100%;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  margin-top: 1rem;
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledLogSection = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-top: 0.5rem;
  }
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  :last-child {
    max-width: 15rem;
    align-items: center;
  }
`;

export const StyledButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  display: flex;
  &&& {
    max-width: 100%;
    height: 2rem;
    margin-top: 1rem;
    background: ${COLORS.SOLITUDE_GRAY};
    color: ${COLORS.DARK_BLUE};
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
    align-items: center;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &&& {
      max-width: 9.5rem;
    }
  }
`;
