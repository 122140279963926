import { IApiError } from '@/models/apiError.model';
import {
  IGetRequestsListDTO,
  IOwnRequest,
  IRequest,
} from '@/models/requests.model';

export const REQUESTS_LIST_SLICE_NAME = 'requests-list';

export interface RequestsListState {
  requestsDetails: IRequest | null;
  requestsListData: {
    items: IRequest[] | null;
    hasMore: boolean;
  };
  ownRequestsListData: {
    items: IOwnRequest[] | null;
    hasMore: boolean;
  };
  currentRequestCount: number;
  requestFilters: IGetRequestsListDTO;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: RequestsListState = {
  requestsDetails: null,
  requestsListData: {
    items: [],
    hasMore: true,
  },
  ownRequestsListData: {
    items: [],
    hasMore: true,
  },
  requestFilters: {
    search: undefined,
    actionTypes: undefined,
    userType: undefined,
    sortByDate: undefined,
  },
  currentRequestCount: 0,
  isLoading: false,
  error: null,
};
