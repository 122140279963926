// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Spinner, StyledSpinnerWrap } from '@/ui';
import {
  StyledForm,
  StyledButtonWrapper,
  StyledButton,
  Input,
  SettingsWrapper,
  StyledBlockWrapper,
  StyledTitle,
} from '../Styles';
import { FormItemGroup, IFormInstance, NestedFormItem } from '@/components';

// Modules
import { useGetFormValidationRules } from '@/modules/Settings/hooks';

// Models
import {
  AverageSocialDataTypes,
  IAverageSocialData,
} from '@/models/settings.model';

// Helpers
import { SettingsFieldsNames } from '../../helpers/types';

interface IAverageSocialDataProps {
  formInstance: IFormInstance;
  handleSubmit: (values: IAverageSocialData) => void;
  initialValues: IAverageSocialData | null;
  isEditActive: boolean;
}

const AverageSocialData = ({
  formInstance,
  handleSubmit,
  initialValues,
  isEditActive,
}: IAverageSocialDataProps) => {
  const { t } = useTranslation(['settings']);
  const validationRules = useGetFormValidationRules();

  if (!initialValues)
    return (
      <StyledSpinnerWrap>
        <Spinner />
      </StyledSpinnerWrap>
    );

  return (
    <>
      <StyledForm
        onFinish={handleSubmit}
        form={formInstance}
        initialValues={initialValues}
      >
        <StyledBlockWrapper>
          <StyledTitle>{t('settings_average_social_data_posts')}</StyledTitle>
          <FormItemGroup prefix={AverageSocialDataTypes.POST_PRICES}>
            <SettingsWrapper>
              <NestedFormItem
                name={SettingsFieldsNames.AVERAGE_DATA_TWITTER}
                rules={
                  validationRules[SettingsFieldsNames.AVERAGE_DATA_TWITTER]
                }
                label={t('settings_twitter_label')}
              >
                <Input disabled={!isEditActive} type="number" />
              </NestedFormItem>
              <NestedFormItem
                name={SettingsFieldsNames.AVERAGE_DATA_INSTAGRAM}
                rules={
                  validationRules[SettingsFieldsNames.AVERAGE_DATA_INSTAGRAM]
                }
                label={t('settings_instagram_label')}
              >
                <Input disabled={!isEditActive} type="number" />
              </NestedFormItem>
              <NestedFormItem
                name={SettingsFieldsNames.AVERAGE_DATA_YOUTUBE}
                rules={
                  validationRules[SettingsFieldsNames.AVERAGE_DATA_YOUTUBE]
                }
                label={t('settings_youtube_label')}
              >
                <Input disabled={!isEditActive} type="number" />
              </NestedFormItem>
              <NestedFormItem
                name={SettingsFieldsNames.AVERAGE_DATA_FACEBOOK}
                rules={
                  validationRules[SettingsFieldsNames.AVERAGE_DATA_FACEBOOK]
                }
                label={t('settings_facebook_label')}
              >
                <Input disabled={!isEditActive} type="number" />
              </NestedFormItem>
            </SettingsWrapper>
          </FormItemGroup>
        </StyledBlockWrapper>
        <StyledBlockWrapper>
          <FormItemGroup prefix={AverageSocialDataTypes.IMPRESSION}>
            <StyledTitle>
              {t('settings_average_social_data_impression')}
            </StyledTitle>
            <SettingsWrapper>
              <NestedFormItem
                name={SettingsFieldsNames.AVERAGE_DATA_TWITTER}
                rules={
                  validationRules[SettingsFieldsNames.AVERAGE_DATA_TWITTER]
                }
                label={t('settings_twitter_label')}
              >
                <Input disabled={!isEditActive} type="number" />
              </NestedFormItem>
              <NestedFormItem
                name={SettingsFieldsNames.AVERAGE_DATA_INSTAGRAM}
                rules={
                  validationRules[SettingsFieldsNames.AVERAGE_DATA_INSTAGRAM]
                }
                label={t('settings_instagram_label')}
              >
                <Input disabled={!isEditActive} type="number" />
              </NestedFormItem>
              <NestedFormItem
                name={SettingsFieldsNames.AVERAGE_DATA_YOUTUBE}
                rules={
                  validationRules[SettingsFieldsNames.AVERAGE_DATA_YOUTUBE]
                }
                label={t('settings_youtube_label')}
              >
                <Input disabled={!isEditActive} type="number" />
              </NestedFormItem>
              <NestedFormItem
                name={SettingsFieldsNames.AVERAGE_DATA_FACEBOOK}
                rules={
                  validationRules[SettingsFieldsNames.AVERAGE_DATA_FACEBOOK]
                }
                label={t('settings_facebook_label')}
              >
                <Input disabled={!isEditActive} type="number" />
              </NestedFormItem>
            </SettingsWrapper>
          </FormItemGroup>
        </StyledBlockWrapper>

        {isEditActive && (
          <StyledButtonWrapper>
            <StyledButton type="primary" htmlType="submit">
              {t('settings_information_save')}
            </StyledButton>
          </StyledButtonWrapper>
        )}
      </StyledForm>
    </>
  );
};

export default AverageSocialData;
