import {
  IAverageSocialData,
  IGetNFTCreditsBucketHistoryDTO,
  INFTCreditsBucketData,
  INftCreditsBucketHistoryResponse,
  ISettingsDTO,
  AutomaticApprovalDTO,
  ITopBarSettingsDTO,
  SettingsEndpoints,
} from '@/models/settings.model';
import { ApiResponse, httpGet, httpPost } from './httpClient';

class SettingsAPI {
  static getSettings(params: void): ApiResponse<ISettingsDTO> {
    return httpGet<ISettingsDTO>(SettingsEndpoints.GET_SETTINGS, { params });
  }

  static getTopBarsSettings(params: void): ApiResponse<ITopBarSettingsDTO> {
    return httpGet<ITopBarSettingsDTO>(
      SettingsEndpoints.GET_TOP_BARS_SETTINGS,
      { params },
    );
  }

  static getSocialsThresholdsSettings(
    params: void,
  ): ApiResponse<AutomaticApprovalDTO> {
    return httpGet<AutomaticApprovalDTO>(
      SettingsEndpoints.AUTOMATIC_APPROVAL_SOCIALS_THRESHOLDS,
      { params },
    );
  }

  static postSettings(data: ISettingsDTO): ApiResponse<ISettingsDTO> {
    return httpPost(SettingsEndpoints.GET_SETTINGS, data);
  }

  static postSocialsThresholdsSettings(
    data: AutomaticApprovalDTO,
  ): ApiResponse<AutomaticApprovalDTO> {
    return httpPost(
      SettingsEndpoints.AUTOMATIC_APPROVAL_SOCIALS_THRESHOLDS,
      data,
    );
  }

  static postTopBarsSettings(
    data: ITopBarSettingsDTO,
  ): ApiResponse<ITopBarSettingsDTO> {
    return httpPost(SettingsEndpoints.GET_TOP_BARS_SETTINGS, data);
  }

  static getAverageSocialData(params: void): ApiResponse<IAverageSocialData> {
    return httpGet<IAverageSocialData>(
      SettingsEndpoints.GET_AVERAGE_SOCIAL_DATA,
      { params },
    );
  }

  static postAverageSocialData(
    data: IAverageSocialData,
  ): ApiResponse<IAverageSocialData> {
    return httpPost(SettingsEndpoints.GET_AVERAGE_SOCIAL_DATA, data);
  }

  static getNFTCreditsFundingBucket(
    params: void,
  ): ApiResponse<INFTCreditsBucketData> {
    return httpGet<INFTCreditsBucketData>(
      SettingsEndpoints.NFT_CREDITS_BUCKET,
      {
        params,
      },
    );
  }

  static getNFTFundingBucketHistory(
    params: IGetNFTCreditsBucketHistoryDTO,
  ): ApiResponse<INftCreditsBucketHistoryResponse> {
    return httpGet<INftCreditsBucketHistoryResponse>(
      `${SettingsEndpoints.NFT_CREDITS_BUCKET}/history`,
      {
        ...params,
      },
    );
  }

  static postNFTCreditsFundingBucket(
    data: INFTCreditsBucketData,
  ): ApiResponse<INFTCreditsBucketData> {
    return httpPost(SettingsEndpoints.NFT_CREDITS_BUCKET, data);
  }
}

export default SettingsAPI;
