import { TFunction } from 'react-i18next';
import { RequestActionsStatuses } from '@/models/requests.model';

export const getRequestStatuses = (t: TFunction, phase?: string | null) => {
  switch (phase) {
    case RequestActionsStatuses.NEW:
      return t('requests_new_status', { ns: 'requestsList' });
    case RequestActionsStatuses.APPROVED:
      return t('requests_approved_status', { ns: 'requestsList' });
    case RequestActionsStatuses.IN_PROGRESS:
      return t('requests_in_progress_status', { ns: 'requestsList' });
    case RequestActionsStatuses.REPLY_SENT:
      return t('requests_in_reply_sent_status', { ns: 'requestsList' });
    case RequestActionsStatuses.REJECTED:
      return t('requests_in_rejected_status', { ns: 'requestsList' });
    case RequestActionsStatuses.BLOCKED:
      return t('requests_in_rejected_status', { ns: 'requestsList' });
    default:
      return t('requests_closed_status', { ns: 'requestsList' });
  }
};
