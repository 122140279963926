import React, { useEffect, useState } from 'react';
import { TextAreaProps } from 'antd/es/input';
import { StyledTextArea, StyledTitle, StyledPreview } from './HTMLEditroStyles';

type IHTMLEditor = TextAreaProps & { withPreview?: boolean };

const HTMLEditor = ({
  value,
  onChange,
  withPreview = true,
  ...props
}: IHTMLEditor) => {
  const [content, setContent] = useState<string>('');

  const handleContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setContent(event.target.value);
    onChange && onChange(event);
  };

  useEffect(() => {
    setContent(String(value ?? ''));
  }, [value]);

  return (
    <div>
      <StyledTextArea
        value={content}
        onChange={handleContentChange}
        {...props}
      />
      {withPreview && (
        <>
          <StyledTitle>Preview:</StyledTitle>
          <StyledPreview
            dangerouslySetInnerHTML={{ __html: content ? String(content) : '' }}
          />
        </>
      )}
    </div>
  );
};

export default HTMLEditor;
