import { useTranslation } from 'react-i18next';
import { useGetRequiredRule } from '@/hooks';
import { ContactInfoFieldNames } from '../helpers';

const useGetFormValidationRules = () => {
  const { t } = useTranslation(['common']);
  const required = useGetRequiredRule({ t });

  return {
    [ContactInfoFieldNames.SOURCE]: [required],
    [ContactInfoFieldNames.CONTACT]: [required],
    [ContactInfoFieldNames.EMAIL]: [required],
  };
};

export default useGetFormValidationRules;
