// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { ListWrapper, StyledInfoTitles, StyledTitles } from '../Styles/Styles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import CampaignParticipationCard from './CampaignParticipationCard';

// Models
import { CampaignParticipantsReportItem } from '@/models/reports.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

interface ICampaignParticipationsList {
  isLoading: boolean;
  participations: CampaignParticipantsReportItem[] | null;
}

const CampaignParticipationsList = ({
  isLoading,
  participations,
}: ICampaignParticipationsList) => {
  const { t } = useTranslation(['reports']);

  const mappedParticipationList = useMemo(() => {
    return participations?.map((participation) => {
      return (
        <CampaignParticipationCard
          participation={participation}
          key={participation.participationId}
        />
      );
    });
  }, [participations]);

  return (
    <ListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('report_details_campaign_kol')}</StyledTitles>
          <StyledTitles>
            {t('report_details_participation_contact')}
          </StyledTitles>
          <StyledTitles>{t('report_details_campaign_end_date')}</StyledTitles>
          <StyledTitles>{t('report_details_campaign_payout')}</StyledTitles>
          <StyledTitles>{t('report_details_campaign_deliveries')}</StyledTitles>
          <StyledTitles>{t('report_details_participant_wallet')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedParticipationList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
    </ListWrapper>
  );
};

export default CampaignParticipationsList;
