// GLobals
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

// Components
import { showErrorToast, showSuccessToast, useForm } from '@/components';
import {
  StyledWrap,
  StyledHeader,
  StyledCreditsAvailableBlock,
} from './SeedHunterCreditContainerStyles';
import { Text } from '@/ui';
import {
  AddCreditModal,
  SeedHunterCreditsHistory,
  StyledButton,
} from '../../components';

// Models
import {
  ICreditHistory,
  SeedHunterCreditRoles,
} from '@/models/sharedProfile.model';
import { AdminActionsEnum } from '@/models/adminsList.model';

// Modules
import usePermissions from '@/modules/Permissions/hooks/usePermissions';

// Helpers | Hooks
import { checkFieldValidation, numberWithCommas } from '@/utils';
import { useAppDispatch, useAppState, useModal } from '@/hooks';
import { profileSliceSelector } from '../../feature/selectors';
import {
  getSeedHunterCreditList,
  postCreditBonus,
} from '../../feature/actionCreators';
import { resetSeedHunterCreditsList } from '../../feature/slice';

interface ISeedHunterCreditContainer {
  role: SeedHunterCreditRoles;
  id: string;
}

const SeedHunterCreditContainer = ({
  role,
  id,
}: ISeedHunterCreditContainer) => {
  const { t } = useTranslation(['profile']);
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const [permissionToAddCredit] = usePermissions(AdminActionsEnum.FULL_ADMIN);
  const { isLoading, creditHistory } = useAppState(profileSliceSelector);
  const { isOpened, openModal, closeModal } = useModal();

  const getNextSeedHunterCredits = useCallback(
    debounce(() => {
      if (isLoading || !creditHistory.hasMore || !permissionToAddCredit) return;
      const lastItem = creditHistory?.items?.at(-1);
      dispatch(
        getSeedHunterCreditList({
          startId: lastItem?.userId,
          role,
          id,
        }),
      );
    }, 1000),
    [
      isLoading,
      creditHistory.hasMore,
      dispatch,
      role,
      id,
      permissionToAddCredit,
    ],
  );

  const handleSubmit = useCallback(
    async (values: ICreditHistory) => {
      await checkFieldValidation(values, form);
      try {
        await dispatch(
          postCreditBonus({
            ...values,
            role,
            id,
          }),
        );

        dispatch(
          getSeedHunterCreditList({
            role,
            id,
          }),
        );
        showSuccessToast({
          message: t('credit_history_success_toast'),
        });
        closeModal();
      } catch (e: unknown) {
        showErrorToast({
          message: t('credit_history_error_toast'),
        });
      }
    },
    [role, id],
  );

  useEffect(
    () => (): void => {
      dispatch(resetSeedHunterCreditsList());
    },
    [dispatch],
  );

  return (
    <StyledWrap>
      <StyledHeader>
        <StyledCreditsAvailableBlock>
          <Text>{t('credit_history_credit_available_title')}</Text>
          <Text>{`$${numberWithCommas(
            creditHistory?.totalBalance ?? 0,
          )}`}</Text>
        </StyledCreditsAvailableBlock>
        <StyledButton
          type="primary"
          disabled={!permissionToAddCredit}
          onClick={openModal}
        >
          {t('credit_history_add_credit')}
        </StyledButton>
      </StyledHeader>
      <SeedHunterCreditsHistory
        getNextSeedHunterCredits={getNextSeedHunterCredits}
        data={creditHistory}
        isLoading={isLoading}
      />

      <AddCreditModal
        formInstance={form}
        isOpened={isOpened}
        handleClose={closeModal}
        handleSubmit={handleSubmit}
      />
    </StyledWrap>
  );
};

export default SeedHunterCreditContainer;
