import React, { useCallback } from 'react';
import debounce from 'lodash.debounce';
import { useParams } from 'react-router-dom';

// Components
import KOLsRanking from '../../components/KOLsRanking/KOLsRanking';

// Store
import { projectsSliceSelector } from '../../feature/selectors';
import {
  getKolsRankingData,
  simulateKolsRanking,
} from '../../feature/actionCreators';

// Modules
import {
  exportKOLsRankingList,
  selectDataExportsIsLoading,
} from '@/modules/DataExports';

// Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import { getUserId } from '@/utils';

const KOLsRankingContainer = () => {
  const dispatch = useAppDispatch();
  const { kolsRankingData, isLoading } = useAppState(projectsSliceSelector);
  const { id } = useParams();
  const exportLoading = useAppState(selectDataExportsIsLoading);

  const getNextKolsRanking = useCallback(
    debounce(() => {
      if (isLoading || !kolsRankingData?.hasMore) return;
      const lastItem = kolsRankingData?.items?.at(-1);

      dispatch(
        getKolsRankingData({
          startSk: lastItem?.sk,
          projectId: getUserId(id ?? ''),
        }),
      );
    }, 1000),
    [isLoading, kolsRankingData?.hasMore, id],
  );

  const exportKolsRankingList = async () => {
    dispatch(exportKOLsRankingList(getUserId(id ?? '')));
  };

  const refreshKolsRanking = useCallback(
    debounce(() => {
      if (isLoading) return;

      dispatch(
        getKolsRankingData({
          projectId: getUserId(id ?? ''),
        }),
      );
    }, 1000),
    [isLoading, kolsRankingData?.hasMore, id],
  );

  const simulateKOLsRankingCallback = useCallback(
    debounce(async () => {
      await dispatch(simulateKolsRanking(getUserId(id ?? ''))).unwrap();
      refreshKolsRanking();
    }, 1000),
    [isLoading, kolsRankingData?.hasMore, id],
  );

  return (
    <KOLsRanking
      getNextData={getNextKolsRanking}
      refreshKolsRanking={refreshKolsRanking}
      simulateKOLsRankingCallback={simulateKOLsRankingCallback}
      isLoading={isLoading}
      kolsRankingData={kolsRankingData}
      exportKolsRankingList={exportKolsRankingList}
      exportLoading={exportLoading}
    />
  );
};

export default KOLsRankingContainer;
