import styled, { css } from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { Button } from '@/ui';

export const StyledTabs = styled.div`
  display: flex;
  max-width: 16rem;
  background: ${COLORS.WHITE_SMOKE};
  border-radius: 0.5rem;
`;

const activeButton = css`
  box-shadow: 0rem 0.25rem 1.25rem -0.125rem rgba(50, 50, 71, 0.08);
  filter: drop-shadow(0rem 0rem 0.063rem rgba(12, 26, 75, 0.1));
  background: ${COLORS.WHITE};
`;

export const StyledSwitchButton = styled(Button)<{ $isActive?: boolean }>`
  width: auto;
  height: 2rem !important;
  ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
  color: ${COLORS.LIQUORICE_BLACK};
  background: ${COLORS.WHITE_SMOKE};
  border: none !important;
  padding: 0.3rem;
  border-bottom-left-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
  ${({ $isActive }) => $isActive && activeButton}
`;
