// Globals
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

// Store
import { useAppDispatch, useAppState } from '@/hooks';
import { getKOLsList } from '../../feature/actionCreators';
import { kolsListSliceSelector } from '../../feature/selectors';
import { resetKolList, setKolFilters } from '../../feature/slice';

// Components
import {
  StyledBox,
  StyledButton,
  StyledExportButton,
  StyledHeader,
  StyledSelect,
  StyledCheckBox,
  StyledText,
  StyledSelectWrap,
} from './KOLsListContainerStyles';
import { showErrorToast } from '@/components';
import { Title, StyledPageWrapper } from '@/ui';
import { ExportIcon } from '@/icons';
import { KOLsList } from '../../components';

// Models
import { KolStatuses } from '@/models/kolsList.model';

// Modules
import { SearchInfoInput } from '@/modules/SharedProfile';
import {
  exportKolsList,
  selectDataExportsIsLoading,
} from '@/modules/DataExports';

// Helpers | Hooks
import { ROUTES_PATHS } from '@/router';
import { sortByStatusOptions } from '../../constants';

const KOLsListContainer = () => {
  const { t } = useTranslation(['kolsList', 'common']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const exportLoading = useAppState(selectDataExportsIsLoading);
  const { kolsListData, isLoading, kolFilters } = useAppState(
    kolsListSliceSelector,
  );

  const goToSocialChannelData = () => navigate(ROUTES_PATHS.SOCIAL_CHANNEL);

  const getNextKOLsList = useCallback(
    debounce(async () => {
      if (isLoading || !kolsListData.hasMore) return;
      const lastItem = kolsListData?.items?.at(-1);
      const kolList = await dispatch(
        getKOLsList({
          startId: lastItem?.id,
          ...(kolFilters ? kolFilters : {}),
        }),
      ).unwrap();

      kolList?.items?.length === 0 &&
        showErrorToast({
          message: t('empty_data_massage_kol', { ns: 'profile' }),
        });
    }, 1000),
    [isLoading, kolsListData.hasMore, dispatch, kolFilters],
  );

  const handleKolsSearch = (value: string) => {
    dispatch(
      setKolFilters({
        search: value,
      }),
    );
  };

  const handleStatusFilter = (v: unknown) => {
    dispatch(
      setKolFilters({
        status: v as KolStatuses,
      }),
    );
  };

  const handleKYCFilter = (e: CheckboxChangeEvent) => {
    dispatch(
      setKolFilters({
        verifiedBySynaps: e.target.checked,
      }),
    );
  };

  useEffect(
    () => (): void => {
      dispatch(resetKolList());
    },
    [dispatch],
  );

  const exportKols = async () => {
    dispatch(exportKolsList({ ...kolFilters }));
  };

  return (
    <StyledPageWrapper>
      <Title $type="h3">{t('kols_list_title')}</Title>
      <StyledHeader>
        <StyledBox>
          <SearchInfoInput handleSearch={handleKolsSearch} />
          <StyledSelectWrap>
            <StyledSelect
              options={sortByStatusOptions}
              onChange={handleStatusFilter}
              placeholder={t('kols_list_status_search_title')}
              allowClear
            />
          </StyledSelectWrap>
          <StyledText>
            {t('show_verified_synaps_title', { ns: 'common' })}
          </StyledText>
          <StyledCheckBox onChange={handleKYCFilter} />
          <StyledExportButton
            type="primary"
            onClick={exportKols}
            loading={exportLoading}
          >
            {t('file_export', { ns: 'common' })}
            <ExportIcon />
          </StyledExportButton>
        </StyledBox>
        <StyledButton type="primary" onClick={goToSocialChannelData}>
          {t('kols_list_button_channel')}
        </StyledButton>
      </StyledHeader>
      <KOLsList
        kols={kolsListData}
        isLoading={isLoading}
        getNextProjects={getNextKOLsList}
      />
    </StyledPageWrapper>
  );
};

export default KOLsListContainer;
