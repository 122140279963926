// Globals
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Title, StyledPageWrapper } from '@/ui';
import { DocumentsList } from '../../components';

// Helpers | Hooks
import { useAppDispatch, useAppState } from '@/hooks';
import { documentsSliceSelector } from '../../feature/selectors';
import { getClickWrapTemplates } from '../../feature/actionCreators';
import { resetDocumentsState } from '../../feature/slice';

const DocumentsListContainer = () => {
  const { t } = useTranslation(['projectsList']);
  const dispatch = useAppDispatch();
  const { isLoading, clickWrapTemplates } = useAppState(documentsSliceSelector);

  useEffect(() => {
    dispatch(getClickWrapTemplates());

    return (): void => {
      dispatch(resetDocumentsState());
    };
  }, [dispatch]);

  return (
    <StyledPageWrapper>
      <Title $type="h3">{t('projects_list_title')}</Title>
      <DocumentsList documents={clickWrapTemplates} isLoading={isLoading} />
    </StyledPageWrapper>
  );
};

export default DocumentsListContainer;
