import styled, { css } from 'styled-components';
import {
  COLORS,
  DEVICES,
  pickThemeFontSize,
  pickThemeFontStyles,
  SHADOWS,
} from '@/theme';
import Input from '../Input/Input';
import Select from '../Select/Select';
import InputNumber from '../Input/InputNumber';
import DatePicker from '../DatePicker/DatePicker';

export const Title = styled.h1<{ $type?: string }>`
  margin: 0rem;
  color: ${COLORS.LIQUORICE_BLACK};
  ${({ $type }) => {
    switch ($type) {
      case 'h1':
        return pickThemeFontStyles('16', '20');
      case 'h2':
        return pickThemeFontStyles('16', '20', 'SEMI_BOLD');
      case 'h3':
        return pickThemeFontStyles('28', '36', 'BOLD');
      default:
        return pickThemeFontStyles('16', '20');
    }
  }}
`;

export const BlockLayout = styled.div`
  width: 100%;
  background: ${COLORS.WHITE};
  box-shadow: ${SHADOWS.MEDIUM};
  display: flex;
  align-items: center;
  border-radius: 1rem;
  padding: 1rem;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const scrollBar = css`
  scrollbar-color: ${COLORS.EGG_SOUR} ${COLORS.SNOW} !important;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 1rem;
  }

  ::-webkit-scrollbar-track {
    width: 1rem;
    background: ${COLORS.SNOW};
    box-shadow: inset 1px 0px 0px #e8e8e8, inset -1px 0px 0px #f0f0f0;
  }

  ::-webkit-scrollbar-thumb {
    max-height: 1rem;
    background: ${COLORS.EGG_SOUR};
    border: 5px solid transparent;
    border-radius: 1rem;
    background-clip: content-box;
  }
`;

export const StyledInput = styled(Input)`
  background: ${COLORS.LIGHTEST_GRAY};
  height: 2.875rem;
  border: 0;
`;

export const StyledDatePicker = styled(DatePicker)`
  background: ${COLORS.LIGHTEST_GRAY};
  height: 2.875rem;
  border: 0;
  ${pickThemeFontStyles('15', '15', 'SEMI_NORMAL')};
`;

export const StyledSelect = styled(Select)`
  &.ant-select {
    &-single {
      & .ant-select-selector {
        background: ${COLORS.LIGHTEST_GRAY};
        height: 2.875rem;
        border: 0;
        & .ant-select-selection-placeholder {
          ${pickThemeFontStyles('14', '14', 'SEMI_NORMAL')};
          margin: auto;
        }
        & .ant-select-selection-item {
          width: 1rem;
          ${pickThemeFontStyles('14', '16', 'SEMI_NORMAL')};
          margin: auto;
        }
        & .ant-select-selection-search {
          input {
            height: 100%;
          }
        }
      }
    }
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  &&& {
    background: ${COLORS.LIGHTEST_GRAY};
    height: 2.875rem;
    border: 0;

    &&& .ant-input-number-input-wrap,
    .ant-input-number-input {
      height: 2.875rem;
      ${pickThemeFontStyles('15', '32', 'SEMI_NORMAL')};
    }
  }
`;

export const Text = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledDownloadButton = styled.a`
  svg {
    margin: 70% 0 0 40%;
    font-size: ${pickThemeFontSize('40')};
    transform: scale(1.5);
  }
`;

export const StyledPageWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 3.688rem);
  padding: 1.5rem;
  background: ${COLORS.WHITE_SMOKE};

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 100vh;
  }
`;
export const StyledSpinnerWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1.5rem;
`;
