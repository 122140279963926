import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { ChevronIcon } from '@/icons';
import { Button } from '@/ui';

export const FormWrapper = styled.div`
  width: 100%;
  background: ${COLORS.WHITE};
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  min-height: 6rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 12rem;
    padding: 1.5rem;
    flex-direction: row;
  }
`;

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('12', '22', 'NORMAL')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '18', 'NORMAL')};
  }
`;

export const StyledSocialChannels = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const StyledChevronIcon = styled(ChevronIcon)`
  transform: rotate(270deg);
`;

export const FormTextWrapper = styled.div`
  width: 100%;
`;

export const FormFieldsWrapper = styled.div`
  width: 100%;
  & .ant-form-item {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    margin-bottom: 0rem;
  }
`;

export const FormFields = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
  flex-direction: column;
  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const FormFieldsDevider = styled.div`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  width: 10rem;
  margin-top: 2rem;
`;

export const StyledAddButton = styled(Button)`
  max-width: 11.2rem;
  background: ${COLORS.TURQUOISE_BLUE};
`;

export const StyledURIError = styled.div`
  color: ${COLORS.RED};
  ${pickThemeFontStyles('12', '14', 'SEMI_NORMAL')};
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  align-items: center;
  cursor: pointer;
  @media screen and ${DEVICES.LAPTOP_S} {
    width: auto;
    height: auto;
  }
`;
