import styled from 'styled-components';
import { Table } from 'antd';
import { COLORS, DEVICES } from '@/theme';

export const StyledTable = styled(Table)`
  width: 100%;
  overflow: auto;
  white-space: nowrap;

  &&& .ant-table-thead {
    .ant-table-cell {
      text-align: center;
      width: 30%;
      background: ${COLORS.WHITE};
    }
  }
  &&& .ant-table-row {
    .ant-table-cell {
      text-align: center;
      width: 30%;

      :last-child {
        width: 5rem;
      }
    }
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    white-space: wrap;
  }
`;
