import { AccountStatuses } from '@/constants';
import { IPosts } from './requests.model';

export enum LogsEndpoints {
  GET_LOGS = '/admin',
}

export enum LogEntities {
  KOL = 'kol',
  PARTNER = 'partner',
  PROJECT = 'project',
  REQUESTS = 'request',
  CAMPAIGN = 'campaign',
  FUNDING_ROUNDS = 'funding-round',
}

export enum RequestActionsStatuses {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  CLOSED = 'closed',
  REPLY_SENT = 'reply_sent',
  BLOCKED = 'blocked',
}

export interface ILogs {
  type: string;
  adminEmail?: string;
  reason: string;
  created: string;
  sk?: string;
  action: RequestActionsStatuses;
  difference: {
    oldData: ILogDifference;
    newData: ILogDifference;
  };
}

export interface ILogDifference {
  status?: RequestActionsStatuses | AccountStatuses;
  data?: IPosts;
}

// ============= DTO =============

export interface ILogsDTO {
  limit?: number;
  startSk?: string;
  entity?: LogEntities;
  id?: string;
}

export interface ISettingsLogsDTO {
  limit?: number;
  startSk?: string;
}

// ============== Response ==============

export interface ILogsListResponse {
  items: ILogs[];
  hasMore: boolean;
}
