export const omitProjectFields = [
  'KYB',
  'seedHunterWalletAddress',
  'seedHunterWalletNetwork',
  'seedHunterWalletCreationStatus',
  'totalAffiliateCommissionAmount',
  'affiliateCode',
  'linkClicks',
  'registrationsCompleted',
  'verifiedAt',
  'created',
  'modified',
  'role',
  'unreadRequestsCount',
  'seedHunterWalletBalance',
];
