// Modules
import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import { StyledLayout } from './MainLayoutStyles';
import MainLayoutContent from '../MainLayoutContent/MainLayoutContent';
import SideBar from '../DesktopSideBar/DesktopSideBar';
import MobileSideBar from '../MobileSideBar/MobileSideBar';

// Modules
import { DesktopOnly, MobileOnly } from '@/modules/Adaptive';

// Helpers
import { ROUTES_PATHS } from '../../../../router/config';

interface IMainLayout {
  children: ReactNode;
}

const MainLayout = ({ children }: IMainLayout): JSX.Element => {
  const location = useLocation();

  return (
    <StyledLayout>
      {location.pathname !== ROUTES_PATHS.SIGN_IN && (
        <DesktopOnly>
          <SideBar />
        </DesktopOnly>
      )}
      <MainLayoutContent>{children}</MainLayoutContent>
      {location.pathname !== ROUTES_PATHS.SIGN_IN && (
        <MobileOnly>
          <MobileSideBar />
        </MobileOnly>
      )}
    </StyledLayout>
  );
};

export default MainLayout;
