// Global
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component
import {
  StyledFileIconWrapper,
  StyledInfoWrapper,
  StyledButton,
  CardWrapper,
  StyledText,
  StyledTitle,
  StyledBadge,
  StyledA,
  StyledDateText,
  StyledDocWrapper,
  StyledDocNumber,
} from './ParticipationListStyles';
import { TextFileIcon } from '@/icons';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Models
import {
  FundingRoundParticipationTransactionStatus,
  IFundingRoundParticipation,
} from '@/models/fundingRound.model';

// Helpers
import {
  getDateString,
  getEntityByFullId,
  getUserRoleLabel,
  numberWithCommas,
} from '@/utils';
import { DATE_FORMATS } from '@/constants';
import { Tooltip } from '@/ui';

interface IParticipationCard {
  participation: IFundingRoundParticipation;
  handleConfirmPayment: (participation: IFundingRoundParticipation) => void;
}

const ParticipationCard = ({
  participation,
  handleConfirmPayment,
}: IParticipationCard) => {
  const { t } = useTranslation(['fundingRound']);

  const userRole = getEntityByFullId(participation?.participantId);
  const date = getDateString(
    participation?.acceptedAt ?? '',
    DATE_FORMATS.DATE_WITH_TIME_UTC,
  );

  const handleConfirm = () => {
    handleConfirmPayment(participation);
  };

  const mappedDocs = participation?.participantDocuments.map((doc) => (
    <StyledDocWrapper key={doc.id}>
      <StyledA href={doc.signedDocumentLink} target="_blank">
        <StyledButton type="ghost">
          {t(doc.type)}
          <StyledFileIconWrapper>
            <TextFileIcon />
          </StyledFileIconWrapper>
        </StyledButton>
      </StyledA>

      {doc.agreementNumber && (
        <StyledDocNumber>{`(№:${doc.agreementNumber})`}</StyledDocNumber>
      )}
    </StyledDocWrapper>
  ));

  return (
    <CardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('funding_participation_name_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{`${participation?.firstName} ${participation?.lastName}`}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('funding_participation_role_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{getUserRoleLabel(userRole.toLowerCase())}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('funding_participation_date_title')}</StyledTitle>
        </MobileOnly>
        <StyledDateText>{date}</StyledDateText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('funding_participation_wallet_title')}</StyledTitle>
        </MobileOnly>
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode}
          title={participation?.walletAddressForReceivingTokens}
          placement={'top'}
        >
          <StyledText>
            {participation?.walletAddressForReceivingTokens}
          </StyledText>
        </Tooltip>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>
            {t('funding_participation_participation_title')}
          </StyledTitle>
        </MobileOnly>
        <StyledText>
          $ {numberWithCommas(participation?.totalParticipationAmount ?? 0)}
        </StyledText>
        <StyledText>
          <img src={participation?.tokenImage} alt="tokenImage" />
          {numberWithCommas(
            (participation?.totalParticipationAmount ?? 0) /
              (participation?.pricePerToken ?? 0),
          )}
        </StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>
            {t('funding_participation_documents_title')}
          </StyledTitle>
        </MobileOnly>
        {mappedDocs}
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <StyledBadge $status={participation?.status}>
          {t(`investment_status_${participation?.status}`, { ns: 'common' })}
        </StyledBadge>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        {participation?.transaction?.status ===
          FundingRoundParticipationTransactionStatus.WAITING_FOR_APPROVAL && (
          <StyledButton type="ghost" onClick={handleConfirm}>
            {t('funding_participation_action_confirm_title')}
          </StyledButton>
        )}
      </StyledInfoWrapper>
    </CardWrapper>
  );
};

export default ParticipationCard;
