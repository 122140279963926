import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { DocumentsState, DOCUMENTS_SLICE_NAME } from './models';

export const documentsSliceSelector = (state: RootState): DocumentsState =>
  state[DOCUMENTS_SLICE_NAME];

export const selectDocumentsIsLoading = createSelector(
  documentsSliceSelector,
  (state: DocumentsState) => state.isLoading,
);
