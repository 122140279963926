// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledValue,
  ChannelInvestmentWrapper,
  StyledInvestmentTitle,
  StyledInfo,
} from './CheckInvestmentRequestsDetailsStyles';

// Models
import { ISocialChannelInvestment } from '@/models/kolsList.model';

// Helpers | Hooks
import { socialChannelsConfig } from '@/modules/KolsList';
import { numberWithCommas } from '@/utils';
import { MobileOnly } from '@/modules/Adaptive';

interface ISocialChannelInvestmentItem {
  investmentData: ISocialChannelInvestment;
}

const ChannelInvestmentItem = ({
  investmentData,
}: ISocialChannelInvestmentItem) => {
  const { t } = useTranslation(['requestsList']);
  return (
    <ChannelInvestmentWrapper>
      <StyledInfo>
        <MobileOnly>
          <StyledInvestmentTitle>
            {t('check_investment_channel_name')}
          </StyledInvestmentTitle>
        </MobileOnly>
        <StyledValue>
          {socialChannelsConfig[investmentData.name]?.icon}
          {investmentData.name}
        </StyledValue>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledInvestmentTitle>
            {t('check_investment_channel_invested_currency')}
          </StyledInvestmentTitle>
        </MobileOnly>
        <StyledValue>
          {`$${numberWithCommas(investmentData.investedInCurrency)}`}
        </StyledValue>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledInvestmentTitle>
            {t('check_investment_channel_invested_tokens')}
          </StyledInvestmentTitle>
        </MobileOnly>
        <StyledValue>
          {numberWithCommas(investmentData.investedTokens ?? 0)}
        </StyledValue>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledInvestmentTitle>
            {t('check_investment_channel_invested_post_price')}
          </StyledInvestmentTitle>
        </MobileOnly>
        <StyledValue>
          {`$${numberWithCommas(investmentData.price ?? 0)}`}
        </StyledValue>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledInvestmentTitle>
            {t('check_investment_channel_invested_posts_amount')}
          </StyledInvestmentTitle>
        </MobileOnly>
        <StyledValue>
          {numberWithCommas(investmentData.numberOfPosts ?? 0)}
        </StyledValue>
      </StyledInfo>
    </ChannelInvestmentWrapper>
  );
};

export default ChannelInvestmentItem;
