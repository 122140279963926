import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import {
  PERMISSION_SLICE_NAME,
  initialState,
  PermissionsState,
} from './models';
import { getAdminPermissions } from './actionCreators';
import { showApiErrors } from '@/utils';

export const slice = createSlice({
  name: PERMISSION_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getAdminPermissions.fulfilled,
        (state: PermissionsState, action) => {
          state.permissions = action.payload;
          state.isLoading = false;
        },
      )

      .addMatcher(isPending(getAdminPermissions), (state: PermissionsState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(
        isRejected(getAdminPermissions),
        (state: PermissionsState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export default slice.reducer;
