// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  KOLsListWrapper,
  StyledInfoTitles,
  StyledTitles,
} from './AdminsListStyles';
import AdminCard from '../AdminCard/AdminCard';
import { Spinner, StyledSpinnerWrap } from '@/ui';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { IAdmin } from '@/models/adminsList.model';

type TAdminsListProps = {
  adminsList: IAdmin[] | null;
  isLoading: boolean;
};

const AdminsList = ({ adminsList, isLoading }: TAdminsListProps) => {
  const { t } = useTranslation(['adminsList']);

  const mappedAdminList = useMemo(() => {
    return adminsList?.map((adminItem) => {
      return <AdminCard admin={adminItem} key={adminItem.adminId} />;
    });
  }, [adminsList]);

  return (
    <KOLsListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('admins_list_name_title')}</StyledTitles>
          <StyledTitles>{t('admins_list_email_title')}</StyledTitles>
          <StyledTitles>{t('admins_list_details_status_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedAdminList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
    </KOLsListWrapper>
  );
};

export default AdminsList;
