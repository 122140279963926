// Global
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component
import {
  KOLCardWrapper,
  StyledInfoWrapper,
  StyledText,
  StyledTitle,
} from './AdminCardStyles';
import { ArrowRightIcon } from '@/icons';
import { Link } from '@/ui';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Models
import { IAdmin } from '@/models/adminsList.model';

// Helpers
import { ROUTES_PATHS } from '@/router';
import { getUserId } from '@/utils';

interface IAdminCard {
  admin: IAdmin;
}

const AdminCard = ({ admin }: IAdminCard) => {
  const { t } = useTranslation(['adminsList', 'common']);

  return (
    <KOLCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('admins_list_name_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{`${admin?.firstName} ${admin?.lastName}`}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('admins_list_email_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{admin.email}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <StyledText>
          <Link
            to={`${ROUTES_PATHS.ADMINS_LIST}/${encodeURIComponent(
              getUserId(admin?.adminId ?? ''),
            )}`}
          >
            <ArrowRightIcon />
          </Link>
        </StyledText>
      </StyledInfoWrapper>
    </KOLCardWrapper>
  );
};

export default AdminCard;
