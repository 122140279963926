// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  PayoutCardWrapper,
  StyledInfoWrapper,
  StyledFileButton,
  StyledFileIconWrapper,
  StyledIconWrapper,
} from './PayoutDetailsCardStyles';
import { StyledText, StyledTitle } from '../Styled';
import { PendingIcon, SuccessCircleIcon, TextFileIcon } from '@/icons';
import PayoutActionsBadges from './PayoutActionsBadges';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Models
import { IPayoutDetails } from '@/models/payout.model';

// Helpers | Utils
import { getDateString, numberWithCommas } from '@/utils';
import { DATE_FORMATS } from '@/constants';

interface IPayoutDetailsCard {
  data: IPayoutDetails;
}

const PayoutDetailsCard = ({ data }: IPayoutDetailsCard) => {
  const { t } = useTranslation(['payout']);
  const marketingEndDate = getDateString(
    data?.campaignMarketingEndDate ?? '',
    DATE_FORMATS.DATE_WITH_DOTS,
  );

  return (
    <PayoutCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_kol_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.alias}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_campaign_date_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{marketingEndDate}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_project_name_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.projectTitle}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_campaign_name_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.campaignTitle}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_payout_token_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.payoutCurrency}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_network_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.payoutNetwork}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_payout_amount_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          ${numberWithCommas(data?.totalPayoutAmount ?? 0)}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_document_title')}</StyledTitle>
        </MobileOnly>
        <StyledFileButton type="ghost">
          {t('payout_service_agreement_title')}
          <StyledFileIconWrapper>
            <TextFileIcon />
          </StyledFileIconWrapper>
        </StyledFileButton>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_request_status_title')}</StyledTitle>
        </MobileOnly>
        <StyledIconWrapper>
          {data?.postContestRequestId ? <PendingIcon /> : <SuccessCircleIcon />}
        </StyledIconWrapper>
      </StyledInfoWrapper>
      <PayoutActionsBadges data={data} />
    </PayoutCardWrapper>
  );
};

export default PayoutDetailsCard;
