// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

// Components
import { StyledTabsWrap, StyledChannelsDetails } from './CampaignInfoTabStyles';
import { StyledTitle } from '../Styled/Styled';
import { TableTabs } from '@/components';
import SocialChannelsGuidelines from '../SocialChannelsGuidelines/SocialChannelsGuidelines';

// Models
import { SocialChannels } from '@/models/socialChannel.model';
import { ICampaign } from '@/models/campaigns.model';

// helpers
import { useTabs } from '@/hooks';
import { getChannelsConfig } from '../../helpers';

interface ISocialChannelsInfo {
  data: ICampaign | null;
}

interface ITabsConfig {
  label: string | JSX.Element;
  key: string;
}

const SocialChannelsInfo = ({ data }: ISocialChannelsInfo) => {
  const { t } = useTranslation(['campaigns']);
  const { activeTab, onChange } = useTabs(
    SocialChannels.TWITTER,
    undefined,
    true,
  );
  const isChannelDescription = data?.socialChannels?.some(
    (i) => i.description !== '',
  );

  const socialsCustomInstructionsMap = useMemo(() => {
    if (!data?.socialChannels) {
      return null;
    }

    const map = data?.socialChannels.reduce<{
      [key in SocialChannels]?: string;
    }>((acc, social) => {
      acc[social.name] = social.description;
      return acc;
    }, {});

    return isEmpty(map) ? null : map;
  }, [data?.socialChannels]);

  return (
    <StyledTabsWrap>
      <StyledTitle>{t('campaign_details_social_media_title')}</StyledTitle>
      <TableTabs
        onTabChange={onChange}
        config={getChannelsConfig(data?.socialChannels) as ITabsConfig[]}
        currentTab={activeTab}
      />
      <SocialChannelsGuidelines
        socialChannelName={activeTab as SocialChannels}
      />

      <StyledChannelsDetails>
        <h6>{t('campaign_details_custom_instructions_title')}:</h6>
        {isChannelDescription && socialsCustomInstructionsMap
          ? socialsCustomInstructionsMap[activeTab as SocialChannels]
          : t('campaign_details_no_custom_instructions_title')}
      </StyledChannelsDetails>
    </StyledTabsWrap>
  );
};

export default SocialChannelsInfo;
