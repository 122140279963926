import styled from 'styled-components';
import { Radio } from 'antd';
import { pickThemeFontStyles, COLORS, SHADOWS } from '@/theme';
import { RadioGroup } from '@/ui';

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  background: ${COLORS.WHITE_SMOKE};
  border-radius: 0.5rem;
  margin-top: 1rem;
`;

export const StyledRadio = styled(RadioGroup)`
  display: flex;
  width: 100%;
  padding: 0.25rem;
`;

export const StyledRadioButton = styled(Radio.Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
  color: ${COLORS.LIQUORICE_BLACK};
  background: ${COLORS.WHITE_SMOKE};
  border-radius: 0.5rem;
  border: none !important;
  &.ant-radio-button-wrapper-checked {
    background: ${COLORS.WHITE};
    color: ${COLORS.LIQUORICE_BLACK};
    box-shadow: ${SHADOWS.LIGHTER};
  }
  :hover {
    color: ${COLORS.LIQUORICE_BLACK};
  }
  ::before {
    display: none !important;
  }
`;
