import { IApiError } from '@/models/apiError.model';
import {
  IAverageSocialData,
  INFTCreditsBucketData,
  INftCreditsBucketHistoryResponse,
  ISettingsDTO,
  AutomaticApprovalDTO,
  ITopBarSettingsDTO,
} from '@/models/settings.model';

export const SETTINGS_SLICE_NAME = 'settings';

export interface SettingsState {
  settingsData: ISettingsDTO | null;
  topBarsSettings: ITopBarSettingsDTO | null;
  automaticApprovalData: AutomaticApprovalDTO | null;
  averageSocialData: IAverageSocialData | null;
  nftCreditsBucketHistory: INftCreditsBucketHistoryResponse;
  isLoading: boolean;
  nftCreditsBucketData: INFTCreditsBucketData | null;
  error: IApiError | null;
}

export const initialState: SettingsState = {
  settingsData: null,
  topBarsSettings: null,
  automaticApprovalData: null,
  averageSocialData: null,
  nftCreditsBucketHistory: {
    items: [],
    hasMore: true,
  },
  nftCreditsBucketData: null,
  isLoading: false,
  error: null,
};
