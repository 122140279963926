import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { httpGet } from '../../api/httpClient';
import { showErrorToast, showSuccessToast } from '@/components';
import { Button } from '@/ui';
import { COLORS, pickThemeFontStyles } from '@/theme';

enum CRON_TYPES {
  COMPLETE_CAMPAIGNS = 'COMPLETE_CAMPAIGNS',
  RUN_AUTO_CONTENT_CHECK = 'RUN_AUTO_CONTENT_CHECK',
  RUN_MAINLINE_REPORTS_CHECK = 'RUN_MAINLINE_REPORTS_CHECK',
}

const endpointsMap: Record<CRON_TYPES, string> = {
  [CRON_TYPES.COMPLETE_CAMPAIGNS]:
    '/admin/complete-campaigns-for-test-purposes',
  [CRON_TYPES.RUN_AUTO_CONTENT_CHECK]:
    '/admin/run-auto-content-check-for-test-purposes',
  [CRON_TYPES.RUN_MAINLINE_REPORTS_CHECK]:
    '/admin/run-mainline-reports-check-for-test-purposes',
};

const messages: string[] = [
  "Can't you read?",
  'I warn you!',
  "Don't you dare to do it again!",
  'Are you immortal?',
  "You're playing with fire!",
  "Don't say I didn't warn you!",
  'This is your final warning!',
];

const sendRequest = async (cron: CRON_TYPES) => {
  const endpoint = endpointsMap[cron];

  if (!endpoint) {
    throw new Error(`There is no endpoint for cron [${cron}]`);
  }

  return httpGet<unknown>(endpoint);
};

export const StyledContainer = styled.div`
  padding: 2.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledMessage = styled.div`
  color: ${COLORS.CORN_FLOWER_BLUE};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${pickThemeFontStyles('22', '32', 'SEMI_NORMAL')};

  & > div:first-child {
    margin-bottom: 0.5rem;
    color: ${COLORS.BLACK};
    ${pickThemeFontStyles('56', '56', 'SEMI_BOLD')};
  }
`;

export const StyledActionsContainer = styled.div`
  padding: 2.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledActionsBlock = styled.div`
  color: ${COLORS.BLACK};
  ${pickThemeFontStyles('20', '32', 'SEMI_NORMAL')};
  text-align: center;
`;

export const StyledActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding: 1.5rem;
  border-bottom: 1px solid ${COLORS.LIQUORICE_BLACK};

  & > button {
    width: 12.5rem;
  }
`;

export const StyledHint = styled.div`
  color: ${COLORS.CORN_FLOWER_BLUE};
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;
  ${pickThemeFontStyles('14', '16', 'SEMI_NORMAL')};
`;

export const StyledButton = styled(Button)`
  font-size: 3rem !important;
  padding: 3rem !important;
`;

// TODO: Delete this after Marketing affiliate tests
const CompleteCampaignsForTestPurposes = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [clickCounts, setClickCounts] = useState(0);

  const weekToCheck = useMemo(
    () => dayjs().startOf('isoWeek').subtract(1, 'week').isoWeek(),
    [],
  );

  const handleSendRequest = async (request: Promise<unknown>) => {
    setIsLoading(true);
    try {
      await request;
      showSuccessToast({ message: 'SUCCESS!!!' });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      showErrorToast({ message: err?.data?.message || 'Error!' });
    } finally {
      setIsLoading(false);
    }
  };

  const completeCampaigns = async () => {
    const request = sendRequest(CRON_TYPES.COMPLETE_CAMPAIGNS);
    await handleSendRequest(request);
  };

  const runAutoContentCheck = async () => {
    const request = sendRequest(CRON_TYPES.RUN_AUTO_CONTENT_CHECK);
    await handleSendRequest(request);
  };

  const runMainlineReportsCheck = async () => {
    const request = sendRequest(CRON_TYPES.RUN_MAINLINE_REPORTS_CHECK);
    await handleSendRequest(request);
  };

  const doNotPress = () => {
    const message = messages[clickCounts] || 'Ой фсьо!';
    showErrorToast({ message });
    setClickCounts((prev) => prev + 1);
  };

  return (
    <StyledContainer>
      <StyledMessage>
        <div>Alex!!!</div>
        <div>Keep calm and continue testing</div>
        <div>Do not forget to drink some beer after the demo!</div>
      </StyledMessage>

      <StyledActionsContainer>
        <StyledActionsBlock>
          <div>Manual run for Complete Campaigns Cron</div>
          <StyledActionsRow>
            <Button
              loading={isLoading}
              size={'large'}
              type={'primary'}
              onClick={completeCampaigns}
            >
              Complete Campaigns
            </Button>

            <StyledHint>
              <div>
                Remember - Campaign must be in status &quot;Active&quot; (with
                &quot;W&quot; in &quot;GSI4SK&quot;)!!!
              </div>
              <div>
                And &quot;marketingStartDate&quot; and
                &quot;marketingEndDate&quot; must be before today!!!
              </div>
            </StyledHint>
          </StyledActionsRow>
        </StyledActionsBlock>

        <StyledActionsBlock>
          <div>Manual run for Automatic Content Deliveries Cron</div>
          <StyledActionsRow>
            <Button
              loading={isLoading}
              size={'large'}
              type={'primary'}
              onClick={runAutoContentCheck}
            >
              Auto Content Check
            </Button>

            <StyledHint>
              <div>
                Week number for check: <strong>CW {weekToCheck}</strong>
              </div>
              <div>
                It checks all Active campaigns with started marketing period
              </div>
              <div>
                It checks Completed campaigns that were completed during the
                specified week.
              </div>
              <div>
                It checks all participation in status Accepted with booked posts
                in the specified week for respective campaigns.
              </div>
              <div>*For Twitter it only triggers MainLine report creation</div>
            </StyledHint>
          </StyledActionsRow>
        </StyledActionsBlock>

        <StyledActionsBlock>
          <div>Manual run for Mainline Reports Check Cron</div>
          <StyledActionsRow>
            <Button
              loading={isLoading}
              size={'large'}
              type={'primary'}
              onClick={runMainlineReportsCheck}
            >
              Mainline Reports Check
            </Button>

            <StyledHint>
              <div>
                You must run Automatic Content Deliveries Cron first to create
                reports
              </div>
              <div>It checks all Pending MainLine reports.</div>
              <div>
                It may take some time for MainLine to complete reports, so you
                need to wait for a while before you run this cron
              </div>
            </StyledHint>
          </StyledActionsRow>
        </StyledActionsBlock>
      </StyledActionsContainer>

      <StyledButton
        loading={isLoading}
        size={'large'}
        type={'primary'}
        onClick={doNotPress}
      >
        DO NOT PRESS THIS BUTTON!!!
      </StyledButton>
    </StyledContainer>
  );

  // return (
  //   <StyledContainer>
  //     <StyledTxt>
  //       <div>Alex!!!</div>
  //       <div>
  //         Remember - Campaign must be in status &quot;Active&quot; (with
  //         &quot;W&quot; in &quot;GSI4SK&quot;)!!!
  //       </div>
  //       <div>
  //         And &quot;marketingStartDate&quot; and &quot;marketingEndDate&quot;
  //         must be before today!!!
  //       </div>
  //     </StyledTxt>
  //     <StyledButton
  //       loading={isLoading}
  //       size={'large'}
  //       type={'primary'}
  //       onClick={completeCampaigns}
  //     >
  //       DO NOT PRESS THIS BUTTON!!!
  //     </StyledButton>
  //     <StyledHint>
  //       * This button do manual run for Complete Campaigns Cron
  //     </StyledHint>
  //   </StyledContainer>
  // );
};

export default CompleteCampaignsForTestPurposes;
