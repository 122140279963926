import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button, Text, TextArea, Title } from '@/ui';

export const Wrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.5rem;
  align-items: flex-start;

  > h1 {
    margin-bottom: 1rem;
  }
`;

export const ChannelsInvestmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  align-items: flex-start;
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.75rem;
`;

export const BlockWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 60%;
    align-items: center;
    flex-direction: row;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 60%;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
  gap: 1rem;
`;

export const StyledText = styled(Text)`
  flex: 1;
  width: 100%;
`;

export const StyledTitle = styled(Title)`
  ${pickThemeFontStyles('24', '24', 'BOLD')};
`;

export const StyledValue = styled.div`
  ${pickThemeFontStyles('16', '16', 'NORMAL')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  display: flex;
  justify-content: center;

  &:first-child {
    justify-content: flex-start;
  }

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
  }
`;

export const StyledLabel = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: center;

  &:first-child {
    justify-content: flex-start;
  }
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 3;
  width: 100%;
`;

export const ChannelInvestmentWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  position: relative;
  padding: 1rem;
  gap: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    gap: 0rem;
  }
`;

export const StyledInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  :first-child {
    justify-content: flex-start;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledInvestmentTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'BOLD')};
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  &&& {
    max-width: 6rem;
    height: 2rem;
    ${pickThemeFontStyles('12', '16', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  &&&.ant-input {
    ${pickThemeFontStyles('16', '26', 'NORMAL')};
  }
`;
