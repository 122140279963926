import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { REQUESTS_LIST_SLICE_NAME, RequestsListState } from './models';

export const requestsListSliceSelector = (
  state: RootState,
): RequestsListState => state[REQUESTS_LIST_SLICE_NAME];

export const selectRequestsListIsLoading = createSelector(
  requestsListSliceSelector,
  (state: RequestsListState) => state.isLoading,
);

export const selectCurrentRequestCount = createSelector(
  requestsListSliceSelector,
  (state: RequestsListState) => state.currentRequestCount,
);

export const selectOwnRequestsListData = createSelector(
  requestsListSliceSelector,
  (state: RequestsListState) => state.ownRequestsListData,
);
