import { IPayout } from '@/models/requests.model';

export const payoutDataNormalizer = (data: IPayout) => {
  return {
    ...data,
    endDate: data?.fundingRoundEndDate ?? data?.campaignEndDate,
    budget: data?.campaignBudget ?? data?.raisedBudget,
    totalPayout: data?.totalPayout ?? data?.totalPayoutAmount,
    title: data?.campaignName ?? data?.fundingRoundTitle,
  };
};
