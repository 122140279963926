// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  Wrapper,
  BlockWrapper,
  StyledText,
  StyledInputWrapper,
  StyledTitle,
  StyledTextArea,
  StyledHeaderWrapper,
  StyledInfoTitles,
  StyledTitles,
  CardsWrapper,
  StyledCloseButton,
  NavigationWrapper,
} from './PostsContestRequestDetailsStyles';
import { StyledInput, Link } from '@/ui';
import RequestStatusBadges from '../RequestStatusBadges/RequestStatusBadges';
import PostsContestActionButtons from '../ActionButtons/PostsContestActionButtons';
import TablePostsCard from './TablePostsCard';

// Models
import { IPosts, IRequestDetails } from '@/models/requests.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Hooks | Utils
import { getUserLink } from '@/utils';
import { ROUTES_PATHS } from '@/router';

interface IPostsContestRequestDetails {
  request: IRequestDetails | null;
}

const PostsContestRequestDetails = ({
  request,
}: IPostsContestRequestDetails) => {
  const { t } = useTranslation(['requestsList']);
  const { email, firstName, lastName, role, id } = request?.requester ?? {};
  const isCloseRequestActive = (request?.data as IPosts)?.urls?.every(
    (urlInfo) => urlInfo?.status !== undefined,
  );

  const goToRequester = getUserLink(role ?? '', id ?? '');
  const actionTypeLabel = t(`requests_${request?.actionType}_label`);

  const mappedPostsList = useMemo(() => {
    return (request?.data as IPosts)?.urls?.map((post, i) => {
      const numbering = i + 1;
      return (
        <TablePostsCard
          post={post}
          key={post.id}
          numbering={numbering}
          id={request?.id}
          status={request?.status}
        />
      );
    });
  }, [request]);

  return (
    <Wrapper>
      <StyledHeaderWrapper>
        <StyledTitle>{t('request_title')}</StyledTitle>
        <RequestStatusBadges status={request?.status} />
      </StyledHeaderWrapper>
      <StyledText>{`${t('requests_type')} ${actionTypeLabel}`}</StyledText>

      <NavigationWrapper>
        <StyledTitle $type="h2">
          {t(`requests_profile_${role}_label`)}
        </StyledTitle>
        <Link to={goToRequester ?? ''} target="_blank">
          <StyledCloseButton type="primary">
            {t('verification_request_go_to_profile')}
          </StyledCloseButton>
        </Link>
      </NavigationWrapper>

      <BlockWrapper>
        <StyledText>{t('posts_contest_first_name')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={firstName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>

      <BlockWrapper>
        <StyledText>{t('posts_contest_last_name')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={lastName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>

      <BlockWrapper>
        <StyledText>{t('posts_contest_email')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={email} disabled />
        </StyledInputWrapper>
      </BlockWrapper>

      <BlockWrapper>
        <StyledText>{t('posts_contest_reason')}</StyledText>
        <StyledInputWrapper>
          <StyledTextArea rows={5} disabled value={request?.message} />
        </StyledInputWrapper>
      </BlockWrapper>

      <NavigationWrapper>
        <StyledTitle $type="h2">{request?.campaignTitle}</StyledTitle>
        <Link
          to={`${ROUTES_PATHS.CAMPAIGNS}/${encodeURIComponent(
            request?.campaignId ?? '',
          )}/campaign`}
          target="_blank"
        >
          <StyledCloseButton type="primary">
            {t('verification_request_go_to_campaign')}
          </StyledCloseButton>
        </Link>
      </NavigationWrapper>

      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>№</StyledTitles>
          <StyledTitles>{t('posts_contest_link')}</StyledTitles>
          <StyledTitles>{t('posts_contest_status')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>

      <CardsWrapper>{mappedPostsList}</CardsWrapper>

      <PostsContestActionButtons
        status={request?.status}
        id={request?.id}
        isCloseRequestActive={isCloseRequestActive}
      />
    </Wrapper>
  );
};

export default PostsContestRequestDetails;
