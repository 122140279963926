// Globals
import React from 'react';
import { useParams } from 'react-router-dom';

// Components
import { PageWrapper } from './ReportDetailsContainerStyles';
import { IReportsTabsNames } from '../../constants';
import FundingRoundReportDetailsContainer from '../FundingRoundReportDetailsContainer/FundingRoundReportDetailsContainer';
import CampaignReportDetailsContainer from '../CampaignReportDetailsContainer/CampaignReportDetailsContainer';

const ReportDetailsContainer = () => {
  const { section } = useParams();

  return (
    <PageWrapper>
      {section === IReportsTabsNames.CAMPAIGN ? (
        <CampaignReportDetailsContainer />
      ) : (
        <FundingRoundReportDetailsContainer />
      )}
    </PageWrapper>
  );
};

export default ReportDetailsContainer;
