// Global
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledAffiliateWrapper,
  StyledInfoWrapper,
  StyledStatistics,
  StyledStatisticsWrapper,
  StyledTitle,
} from '../Styles/Styles';
import { Spinner, Text, StyledSpinnerWrap } from '@/ui';
import AffiliateCard from '../AffiliateCard/AffiliateCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Utils
import { numberWithCommas } from '@/utils';
import { MarketingAffiliateCommission } from '@/models/kolsList.model';
import { useInfiniteScroll } from '@/hooks';

interface IProjectParticipation {
  affiliateData: {
    items: MarketingAffiliateCommission[] | null;
    hasMore: boolean;
  };
  isLoading: boolean;
  getNextAffiliateData: () => void;
  overallBalance?: number;
}

const Affiliate = ({
  affiliateData,
  isLoading,
  getNextAffiliateData,
  overallBalance,
}: IProjectParticipation) => {
  const { t } = useTranslation(['kolsList']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedAffiliate = useMemo(() => {
    return affiliateData?.items?.map((affiliate) => {
      return <AffiliateCard key={affiliate.sk} affiliate={affiliate} />;
    });
  }, [affiliateData]);

  useInfiniteScroll({
    callback: getNextAffiliateData,
    ref: loaderRef,
    isLoading,
    hasMore: affiliateData?.hasMore,
  });

  return (
    <StyledAffiliateWrapper>
      <StyledStatistics>
        <StyledStatisticsWrapper>
          <Text>{t('kols_affiliate_overall_balance')}</Text>
          <Text>{`$${numberWithCommas(overallBalance ?? 0)}`}</Text>
        </StyledStatisticsWrapper>
      </StyledStatistics>
      <DesktopOnly>
        <StyledInfoWrapper>
          <StyledTitle>{t('kols_affiliate_invited_user_title')}</StyledTitle>
          <StyledTitle>
            {t('kols_affiliate_invited_user_role_title')}
          </StyledTitle>
          <StyledTitle>
            {t('kols_affiliate_campaign_invested_title')}
          </StyledTitle>
          <StyledTitle>
            {`${t('kols_affiliate_budget')} / ${t(
              'kols_affiliate_participation_budget',
            )}`}
          </StyledTitle>
          <StyledTitle>{t('kols_affiliate_bonus_title')}</StyledTitle>
        </StyledInfoWrapper>
      </DesktopOnly>
      {mappedAffiliate}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </StyledAffiliateWrapper>
  );
};

export default Affiliate;
