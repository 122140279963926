import { ISettingsDTO, ITopBarSettingsDTO } from '@/models/settings.model';
import { SettingsFieldsNames, TopBarsSettingsFieldsNames } from './types';
import { hideDocumentTypes } from './hideDocuments';

export const settingsDataToFormValues = (settings: ISettingsDTO | null) =>
  settings && {
    [SettingsFieldsNames.FOLLOWERS_MULTIPLIER]: settings?.FOLLOWERS_MULTIPLIER,
    [SettingsFieldsNames.INVESTMENT_TARGET_BIAS_PERCENT]:
      settings?.INVESTMENT_TARGET_BIAS_PERCENT,
    [SettingsFieldsNames.FIRST_STAGE_DAYS]: settings?.FIRST_STAGE_DAYS,
    [SettingsFieldsNames.SOCIAL_NETWORK_PRICE]: settings?.SOCIAL_NETWORK_PRICE,
    [SettingsFieldsNames.MARKETING_AFFILIATE_COMMISSION]:
      settings?.MARKETING_AFFILIATE_COMMISSION,
    [SettingsFieldsNames.HOURS_FOR_CONTRACT_SIGNING]:
      settings?.HOURS_FOR_CONTRACT_SIGNING,
    [SettingsFieldsNames.CAMPAIGN_SIGN_UP_PERIOD_OFFSET]:
      settings?.CAMPAIGN_SIGN_UP_PERIOD_OFFSET,
    [SettingsFieldsNames.DAYS_FOR_MONEY_TRANSFERRING]:
      settings?.DAYS_FOR_MONEY_TRANSFERRING,
    [SettingsFieldsNames.DAYS_FOR_MONEY_TRANSFERRING_BEFORE_MARKETING_START]:
      settings?.DAYS_FOR_MONEY_TRANSFERRING_BEFORE_MARKETING_START,
    [SettingsFieldsNames.DAYS_FOR_LABEL_NEW]: settings?.DAYS_FOR_LABEL_NEW,
    [SettingsFieldsNames.CAMPAIGN_COMMISSION]: settings?.CAMPAIGN_COMMISSION,
    [SettingsFieldsNames.DOCUSIGN_DOCUMENTS_TEMPLATES]: hideDocumentTypes(
      settings.DOCUSIGN_DOCUMENTS_TEMPLATES ?? [],
    ),
    [SettingsFieldsNames.PARTICIPATION]: settings?.PARTICIPATION,
    [SettingsFieldsNames.FUNDING_ROUND_AFFILIATE_COMMISSION]:
      settings?.FUNDING_ROUND_AFFILIATE_COMMISSION,
    [SettingsFieldsNames.FUNDING_ROUND_MAXIMUM_PARTICIPATION_FOR_NFT_HOLDERS]:
      settings?.FUNDING_ROUND_MAXIMUM_PARTICIPATION_FOR_NFT_HOLDERS,
  };

export const settingsTopBarsDataToFormValues = (
  settings: ITopBarSettingsDTO | null,
) =>
  settings && {
    [TopBarsSettingsFieldsNames.NFT]: settings?.NFT,
    [TopBarsSettingsFieldsNames.MARKETING]: settings?.MARKETING,
    [TopBarsSettingsFieldsNames.FUNDING_ROUND]: settings?.FUNDING_ROUND,
  };
