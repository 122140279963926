import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { MARKETING_DELIVERY_SLICE_NAME } from './models';
import { MarketingDeliveryAPI } from '@/api';
import { formatApiError } from '@/utils';
import {
  IGetMarketingDeliveryResponse,
  IMarketingDeliveryDetailsDTO,
  IGetMarketingDeliveryDetailsResponse,
  IUpdateCampaignPostDTO,
  IMarketingDeliveryInfo,
  IRemoveCampaignPostDTO,
  IDeliveryStatistics,
  IDeliveryStatisticsDTO,
} from '@/models/marketingDelivery.model';

export const getMarketingDeliveryData = createAsyncThunk<
  IGetMarketingDeliveryResponse,
  string,
  { serializedErrorType: IApiError }
>(
  `${MARKETING_DELIVERY_SLICE_NAME}/getMarketingDeliveryData`,
  async (id: string) => {
    const response = await MarketingDeliveryAPI.getMarketingDeliveryData(id);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getDeliveryStatistics = createAsyncThunk<
  IDeliveryStatistics,
  IDeliveryStatisticsDTO,
  {
    serializedErrorType: IApiError;
  }
>(
  `${MARKETING_DELIVERY_SLICE_NAME}/getDeliveryStatistics`,
  async (projectId: IDeliveryStatisticsDTO) => {
    const response = await MarketingDeliveryAPI.getDeliveryStatistics(
      projectId,
    );
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getProofOfDeliveryDataDetails = createAsyncThunk<
  IGetMarketingDeliveryDetailsResponse,
  IMarketingDeliveryDetailsDTO,
  { serializedErrorType: IApiError }
>(
  `${MARKETING_DELIVERY_SLICE_NAME}/getProofOfDeliveryDataDetails`,
  async (data: IMarketingDeliveryDetailsDTO) => {
    const response = await MarketingDeliveryAPI.getProofOfDeliveryDataDetails({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const addCampaignPost = createAsyncThunk<
  IMarketingDeliveryInfo,
  IUpdateCampaignPostDTO,
  {
    serializedErrorType: IApiError;
  }
>(
  `${MARKETING_DELIVERY_SLICE_NAME}/addCampaignPost`,
  async (data: IUpdateCampaignPostDTO) => {
    const response = await MarketingDeliveryAPI.addCampaignPost(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateCampaignPost = createAsyncThunk<
  IMarketingDeliveryInfo,
  IUpdateCampaignPostDTO,
  {
    serializedErrorType: IApiError;
  }
>(
  `${MARKETING_DELIVERY_SLICE_NAME}/updateCampaignPost`,
  async (data: IUpdateCampaignPostDTO) => {
    const response = await MarketingDeliveryAPI.updateCampaignPost(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const deleteCampaignPost = createAsyncThunk<
  boolean,
  IRemoveCampaignPostDTO,
  {
    serializedErrorType: IApiError;
  }
>(
  `${MARKETING_DELIVERY_SLICE_NAME}/deleteCampaignPost`,
  async (data) => {
    const response = await MarketingDeliveryAPI.deleteCampaignPost(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
