import { UserRoles } from '@/models/admin.model';

export const getUserRoleLabel = (type: string) => {
  switch (type) {
    case UserRoles.KOL:
      return 'Influencer';
    case UserRoles.PROJECT:
      return 'Project';
    case UserRoles.PARTNER:
      return 'Hunter';
    default:
      return 'Other';
  }
};
