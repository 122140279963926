import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { ArrowRightIcon } from '@/icons';
import { BlockLayout, Button } from '@/ui';

export const StyledButton = styled(Button)`
  display: flex;
  margin-top: 2rem;
  &&& {
    max-width: 6.5rem;
    height: 2rem;
    background: ${COLORS.SOLITUDE_GRAY};
    color: ${COLORS.DARK_BLUE};
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const StyledPreview = styled.div`
  background: ${COLORS.LIGHTEST_GRAY};
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 2rem;
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
  margin-right: 0.5rem;
  transform: rotate(180deg);
`;

export const StyledStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const StyledStatus = styled.div`
  display: flex;
  gap: 0.625rem;
  width: 16rem;
  align-items: center;
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  margin-top: 2rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  :last-child {
    align-items: center;
    flex: 0.4;
  }
`;
