import { IApiError } from '@/models/apiError.model';
import {
  IGetPartnersListDTO,
  IPartner,
  IPartnerAffiliateData,
} from '@/models/partnersList.model';
import { IUser } from '@/models/admin.model';

export const PARTNERS_LIST_SLICE_NAME = 'partners-list';

export interface PartnersListState {
  partnerDetails: IPartner | null;
  partnersListData: {
    items: IPartner[] | null;
    hasMore: boolean;
  };
  affiliateData: {
    items: IPartnerAffiliateData[] | null;
    hasMore: boolean;
  };
  invitedUsers: {
    items: IUser[] | null;
    hasMore: boolean;
  };
  partnersFilters: IGetPartnersListDTO | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: PartnersListState = {
  partnerDetails: null,
  partnersListData: {
    items: [],
    hasMore: true,
  },
  affiliateData: {
    items: [],
    hasMore: true,
  },
  invitedUsers: {
    items: [],
    hasMore: true,
  },
  partnersFilters: {
    search: undefined,
    verifiedBySynaps: undefined,
  },
  isLoading: false,
  error: null,
};
