// Globals
import React from 'react';

// Components
import { useTranslation } from 'react-i18next';
import {
  StyledCardWrapper,
  StyledText,
  StyledCount,
} from './ParticipationAmountBlockStyles';

// Utils
import { DonutChart } from '@/components';
import { numberWithCommas } from '@/utils';
import { COLORS } from '@/theme';

interface IParticipationAmountBlock {
  totalInvestmentsInMarketing: number | null;
  totalInvestmentsInMoney: number | null;
}

const ParticipationAmountBlock = ({
  totalInvestmentsInMarketing,
  totalInvestmentsInMoney,
}: IParticipationAmountBlock) => {
  const { t } = useTranslation(['dashboard']);
  const total =
    (totalInvestmentsInMoney ?? 0) + (totalInvestmentsInMarketing ?? 0);
  const data = [
    {
      label: t('dashboard_marketing'),
      value: ((totalInvestmentsInMarketing ?? 0) / total) * 100,
      amount: `$${numberWithCommas(totalInvestmentsInMarketing ?? 0)}`,
      id: '1',
    },
    {
      label: t('dashboard_money'),
      value: ((totalInvestmentsInMoney ?? 0) / total) * 100,
      amount: `$${numberWithCommas(totalInvestmentsInMoney ?? 0)}`,
      id: '2',
    },
  ];

  return (
    <StyledCardWrapper>
      <StyledText>{t('dashboard_participation_amount')}</StyledText>
      <StyledCount>{`$ ${numberWithCommas(total)}`}</StyledCount>
      <DonutChart
        title={''}
        data={data}
        colors={[COLORS.BRIGHT_ORANGE, COLORS.LIGHT_ORANGE]}
      />
    </StyledCardWrapper>
  );
};

export default ParticipationAmountBlock;
