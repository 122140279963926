import {
  sideBarItemsOrder,
  ISideBarConfig,
  MenuItemsKeys,
} from '../constants/sidebarConfigs';

export const sortSideBarItems = (array: ISideBarConfig[]) => {
  return [...array].sort((a, b) => {
    const aIndex =
      sideBarItemsOrder.get(a.key as MenuItemsKeys) ?? sideBarItemsOrder.size;
    const bIndex =
      sideBarItemsOrder.get(b.key as MenuItemsKeys) ?? sideBarItemsOrder.size;
    return aIndex - bIndex;
  });
};
