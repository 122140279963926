import styled from 'styled-components';
import { Button, BlockLayout, Select, Text } from '@/ui';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { ChevronIcon } from '@/icons';

export const FilterWrapper = styled.div`
  display: flex;
  width: fit-content;
  gap: 1rem;
  position: relative;
`;

export const StyledButton = styled(Button)`
  display: flex;
  gap: 1rem;
  &&&.ant-btn {
    height: 2rem;
  }

  svg {
    path {
      fill: ${COLORS.WHITE};
    }
  }
`;

export const FilterBlock = styled(BlockLayout)`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 20rem;
  top: 2.5rem;
  right: 0rem;
  z-index: 99;
  border: solid 1px ${COLORS.WHISPER_GRAY};
  gap: 1rem;
`;

export const StyledText = styled(Text)`
  display: flex;
`;

export const StyledSelect = styled(Select)`
  &&& {
    min-width: 10rem;

    svg {
      width: 0.65rem;
      height: 0.65rem;

      path {
        fill: ${COLORS.LIQUORICE_BLACK};
      }
    }

    &&& .ant-select-selection-placeholder {
      ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
      color: ${COLORS.LIQUORICE_BLACK} !important;

      margin: auto;
    }

    &&& .ant-select-clear {
      background: ${COLORS.SOLITUDE_GRAY};
      margin-top: -0.75rem;
      margin-right: -0.5rem;
    }
    &&& .ant-select-selector {
      background: ${COLORS.SOLITUDE_GRAY};
      min-height: 1.5rem;
      border: 0;

      &&& .ant-select-selection-item {
        width: 1rem;
        ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
        color: ${COLORS.LIQUORICE_BLACK};

        margin: auto;
      }
    }

    .ant-select-selection-overflow {
      padding: 0.3rem 4rem 0.3rem 0;

      .ant-select-selection-overflow-item {
        padding-bottom: 0.5rem;
        align-items: center;
        width: 100%;
      }
    }
  }
`;

export const StyledChevronIcon = styled(ChevronIcon)<{
  rotate: number;
}>`
  transform: rotate(90deg);

  path {
    fill: ${COLORS.BLACK};
  }
`;

export const StyledContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;
