// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  Wrapper,
  BlockWrapper,
  StyledText,
  StyledInputWrapper,
  NavigationWrapper,
  StyledButton,
  StyledTitle,
  StyledTextArea,
  StyledDocumentWrapper,
  StyledDownloadButton,
  StyledHeaderWrapper,
} from '../Styled';
import { Link, StyledInput } from '@/ui';
import ActionButtons from '../ActionButtons/ActionButtons';
import RequestStatusBadges from '../RequestStatusBadges/RequestStatusBadges';

// Models
import { IRequestDetails } from '@/models/requests.model';

// Helpers | Hooks
import { getDateString, getUserLink } from '@/utils';
import CampaignDetails from './CampaignDetails';
import { TextFileIcon } from '@/icons';

interface IProfileRequestsDetails {
  request: IRequestDetails | null;
  withVerification: boolean;
  isLoading?: boolean;
  isEditable?: boolean;
}

const ProfileRequestsDetails = ({
  request,
  withVerification,
  isLoading,
  isEditable,
}: IProfileRequestsDetails) => {
  const { t } = useTranslation(['requestsList']);
  const {
    firstName,
    lastName,
    created,
    email,
    mainContact,
    address,
    role,
    id,
  } = request?.requester ?? {};

  const goToRequester = getUserLink(role ?? '', id ?? '');
  const actionTypeLabel = t(`requests_${request?.actionType}_label`);

  return (
    <Wrapper>
      <StyledHeaderWrapper>
        <StyledTitle>{t('request_title')}</StyledTitle>
        <RequestStatusBadges status={request?.status} />
      </StyledHeaderWrapper>
      <StyledText>{`${t('requests_type')} ${t(actionTypeLabel)}`}</StyledText>
      {request?.campaign && <CampaignDetails campaign={request?.campaign} />}
      <NavigationWrapper>
        <StyledTitle $type="h2">
          {t(`requests_profile_${role}_label`)}
        </StyledTitle>
        <Link to={goToRequester ?? ''} target="_blank">
          <StyledButton type="primary">
            {t('verification_request_go_to_profile')}
          </StyledButton>
        </Link>
      </NavigationWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_name_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={firstName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_last_name_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={lastName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_registration_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={getDateString(created ?? '')} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_email_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={email} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_contact_source')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={mainContact?.source} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_contact')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={mainContact?.contact} disabled />
        </StyledInputWrapper>
      </BlockWrapper>

      {request?.requester?.certificateOfIncorporation && (
        <BlockWrapper>
          <StyledText>
            {t('requests_certificate_of_incorporation_label')}
          </StyledText>
          <StyledInputWrapper>
            <StyledDocumentWrapper>
              <StyledDownloadButton
                href={request?.requester?.certificateOfIncorporation}
                target={'_blank'}
              >
                <TextFileIcon />
              </StyledDownloadButton>
            </StyledDocumentWrapper>
          </StyledInputWrapper>
        </BlockWrapper>
      )}

      {request?.requester?.beneficialOwnershipDeclaration && (
        <BlockWrapper>
          <StyledText>{t('requests_beneficial_ownership_label')}</StyledText>
          <StyledInputWrapper>
            <StyledDocumentWrapper>
              <StyledDownloadButton
                href={request?.requester?.beneficialOwnershipDeclaration}
                target={'_blank'}
              >
                <TextFileIcon />
              </StyledDownloadButton>
            </StyledDocumentWrapper>
          </StyledInputWrapper>
        </BlockWrapper>
      )}

      {request?.requester?.documentIdProof && (
        <BlockWrapper>
          <StyledText>{t('check_investment_document_id_title')}</StyledText>
          <StyledInputWrapper>
            <StyledDocumentWrapper>
              <StyledDownloadButton
                href={request?.requester?.documentIdProof}
                target={'_blank'}
              >
                <TextFileIcon />
              </StyledDownloadButton>
            </StyledDocumentWrapper>
          </StyledInputWrapper>
        </BlockWrapper>
      )}

      {address && (
        <BlockWrapper>
          <StyledText>{t('verification_request_address_title')}</StyledText>
          <StyledInputWrapper>
            <StyledInput value={address} disabled />
          </StyledInputWrapper>
        </BlockWrapper>
      )}
      {!withVerification && (
        <BlockWrapper>
          <StyledText>
            {t('profile_change_request_description_title')}
          </StyledText>
          <StyledInputWrapper>
            <StyledTextArea value={request?.description} rows={5} disabled />
          </StyledInputWrapper>
        </BlockWrapper>
      )}
      {isEditable && <ActionButtons request={request} isLoading={isLoading} />}
    </Wrapper>
  );
};

export default ProfileRequestsDetails;
