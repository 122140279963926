export enum DataExportsEndpoints {
  ROOT = '/admin/exports',
  EXPORTS_PDF = '/admin/exports-pdf',
  PAYOUTS = '/payouts',
  FUNDING_ROUNDS_PAYOUTS = '/funding-round-payouts',
  FUNDING_ROUNDS_REFUNDS = '/funding-round-refunds',
  FUNDING_ROUND_REPORTS = '/reports/funding-rounds',
  CAMPAIGN_REPORTS = '/reports/campaigns',
  POSTS = '/admin/exports/posts',
  KOLS = '/kols',
  PROJECTS = '/projects',
  PROJECTS_EXPORT = '/admin/exports/projects',
}

// ============== Responses ==============

export interface IDataExportsResponse {
  url: string;
}

// ============== DTO ==============

export interface IGetEntityListExportDTO {
  search?: string;
  status?: string;
}

export interface IGetPostsExportDTO {
  campaignId?: string;
  kolId?: string;
}
