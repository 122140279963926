import styled from 'styled-components';
import { BlockLayout, Button, Text, Link } from '@/ui';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { ArrowRightIcon, ExportIcon } from '@/icons';
import { RequestStatuses } from '@/models/requests.model';

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  gap: 0.5rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const PayoutListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-bottom: 0;
  }
`;

export const StyledExportIcon = styled(ExportIcon)`
  transform: scale(1.5);
  margin: 0.5rem 0rem 0rem 1.5rem;
`;

export const StyledButton = styled(Button)`
  display: flex;
  width: 8.6rem;
  height: 2rem !important;
  background: ${COLORS.WHITE};
  color: ${COLORS.CORN_FLOWER_BLUE};
  box-shadow: 0px 4px 20px -2px #0c1a4b1a !important;
  :hover {
    background: ${COLORS.WHITE}!important;
    color: ${COLORS.CORN_FLOWER_BLUE}!important;
  }
`;

export const PayoutCardWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  width: 100%;
  flex-direction: column;
  min-width: 0;

  @media screen and ${DEVICES.LAPTOP_S} {
    :nth-last-child(1),
    :nth-last-child(2),
    :nth-last-child(3),
    :nth-last-child(4),
    :nth-last-child(5) {
      align-items: center;
    }
  }
`;

export const StyledText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledLink = styled(Link)`
  width: 100%;
  @media screen and ${DEVICES.LAPTOP_S} {
    width: auto;
  }
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
  margin-left: 0.5rem;
  transform: scale(0.8);
`;

export const StyledBadge = styled.div<{ $status?: RequestStatuses }>`
  display: flex;
  width: 100%;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  color: ${COLORS.CORN_FLOWER_BLUE};
  ${({ $status }) => {
    switch ($status) {
      case RequestStatuses.CLOSED:
        return `background: ${COLORS.GREEN_BUBBLES};
        color: ${COLORS.LIGHT_GREEN};`;
      case RequestStatuses.IN_PROGRESS:
        return `background: ${COLORS.CHABLIS_PEACH};
        color: ${COLORS.ATOMIC_TANGERINE};`;
      case RequestStatuses.NEW_REQUEST:
        return `background: ${COLORS.CHABLIS_PEACH};
        color: ${COLORS.ATOMIC_TANGERINE};`;
      default:
        return `background: ${COLORS.MISTY_ROSE};
        color: ${COLORS.PICH_FROLY};`;
    }
  }}
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
`;

export const StyledDividerBlock = styled.div`
  height: 2rem;
`;
