// Globals
import React from 'react';

// Components
import { IconWrap } from './EditButtonStyles';
import { CloseIcon, EditIcon } from '@/icons';

interface IEditButton {
  isEditActive: boolean;
  setIsEditActive: () => void;
  position?: string;
  isVisible?: boolean;
}

const EditButton = ({
  isEditActive,
  setIsEditActive,
  position,
  isVisible = true,
}: IEditButton) => {
  return (
    <>
      {isVisible && (
        <IconWrap onClick={setIsEditActive} $position={position}>
          {isEditActive ? <CloseIcon /> : <EditIcon />}
        </IconWrap>
      )}
    </>
  );
};

export default EditButton;
