// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  TableContainer,
  TableHeader,
  TableHeaderCell,
} from './BalanceReportStyles';
import BalanceReportRow from '../BalanceReport/BalanceReportRow';

// Models
import { TFundingRoundPayoutBalanceAndTransfers } from '@/models/reports.model';
import { WalletNetworksEnum } from '@/models/web3.model';

// Modules
import { SEEDHUNTER_ETH_WALLET } from '@/modules/Web3';

interface IReportDetails {
  report: TFundingRoundPayoutBalanceAndTransfers;
  walletAddress?: string;
}

const BalanceReport = ({ report, walletAddress }: IReportDetails) => {
  const { t } = useTranslation(['reports']);

  return (
    <TableContainer>
      <TableHeader>
        <TableHeaderCell>
          {t('reports_funding_balance_report_network')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('reports_funding_balance_report_raised')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('reports_funding_balance_report_credit_used')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('reports_funding_balance_report_initial_balance')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('reports_funding_balance_report_revenue_transfer')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('reports_funding_balance_report_reconciliation_transfer')}
        </TableHeaderCell>
        <TableHeaderCell>
          {t('reports_funding_balance_report_balance')}
        </TableHeaderCell>
      </TableHeader>

      {report.POLYGON && (
        <BalanceReportRow
          data={report.POLYGON}
          network={WalletNetworksEnum.POLYGON}
          walletAddress={walletAddress}
        />
      )}
      {report.SEPOLIA && (
        <BalanceReportRow
          data={report.SEPOLIA}
          network={WalletNetworksEnum.SEPOLIA}
          walletAddress={walletAddress}
        />
      )}
      {report.ETHEREUM && (
        <BalanceReportRow
          data={report.ETHEREUM}
          network={WalletNetworksEnum.ETHEREUM}
          walletAddress={SEEDHUNTER_ETH_WALLET}
        />
      )}
    </TableContainer>
  );
};

export default BalanceReport;
