// Globals
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

// Components
import {
  Wrapper,
  BlockWrapper,
  NavigationWrapper,
  StyledButton,
  StyledTitle,
  StyledText,
  StyledDatePicker,
  StyledDateWrapper,
  StyledHeaderWrapper,
  StyledInputWrapper,
  StyledTextArea,
} from './TgeDateChangeRequestDetailsStyles';
import { Link, StyledSelect } from '@/ui';
import ActionButtons from '../ActionButtons/ActionButtons';
import RequestStatusBadges from '../RequestStatusBadges/RequestStatusBadges';

// Models
import { IRequestDetails } from '@/models/requests.model';

// Modules
import {
  getWeeksBeforeOptions,
  getWeeksAfterOptions,
} from '@/modules/SharedProfile';

// routes
import { ROUTES_PATHS } from '@/router';

// Helpers | Hooks
import { diffWeeksBefore, diffWeeksAfter } from '@/utils';

interface ITgeRequest {
  request: IRequestDetails | null;
  isLoading?: boolean;
}

const TgeDateChangeRequestDetails = ({ request, isLoading }: ITgeRequest) => {
  const { t } = useTranslation(['requestsList']);
  const [marketingStart, setMarketingStart] = useState<number | null>(null);
  const [marketingEnd, setMarketingEnd] = useState<number | null>(null);
  const [newMarketingStart, setNewMarketingStart] = useState<number | null>(
    null,
  );
  const [newMarketingEnd, setNewMarketingEnd] = useState<number | null>(null);

  const {
    title,
    oldEstimatedTGE,
    oldMarketingEndDate,
    oldMarketingStartDate,
    newEstimatedTGE,
    newMarketingEndDate,
    newMarketingStartDate,
  } = request?.project ?? {};

  const weeksBeforeOptions = getWeeksBeforeOptions(t);
  const weeksAfterOptions = getWeeksAfterOptions(t);
  const actionTypeLabel = t(`requests_${request?.actionType}_label`);

  useEffect(() => {
    const oldDiffBefore = diffWeeksBefore(
      oldEstimatedTGE,
      oldMarketingStartDate,
    );
    const oldDiffAfter = diffWeeksAfter(oldEstimatedTGE, oldMarketingEndDate);
    const newDiffBefore = diffWeeksBefore(
      newEstimatedTGE,
      newMarketingStartDate,
    );
    const newDiffAfter = diffWeeksAfter(newEstimatedTGE, newMarketingEndDate);

    setMarketingStart(oldDiffBefore >= 0 ? oldDiffBefore : null);
    setMarketingEnd(oldDiffAfter <= 0 ? oldDiffAfter * -1 : null);
    setNewMarketingStart(newDiffBefore >= 0 ? newDiffBefore : null);
    setNewMarketingEnd(newDiffAfter <= 0 ? newDiffAfter * -1 : null);
  }, [request]);

  return (
    <Wrapper>
      <StyledHeaderWrapper>
        <StyledTitle>{t('request_title')}</StyledTitle>
        <RequestStatusBadges status={request?.status} />
      </StyledHeaderWrapper>
      <StyledText>{`${t('requests_type')} ${actionTypeLabel}`}</StyledText>
      <NavigationWrapper>
        <StyledTitle $type="h2">{title}</StyledTitle>
        <Link
          to={`${ROUTES_PATHS.PROJECTS_LIST}/${encodeURIComponent(
            request?.createdById ?? '',
          )}/project-information`}
          target="_blank"
        >
          <StyledButton type="primary">
            {t('verification_request_go_to_profile')}
          </StyledButton>
        </Link>
      </NavigationWrapper>
      <BlockWrapper>
        <StyledText>{t('tge_date_request_old_planned_tge')}</StyledText>
        <StyledDateWrapper>
          <StyledDatePicker
            disabled
            value={oldEstimatedTGE ? dayjs(oldEstimatedTGE) : null}
          />
        </StyledDateWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('tge_date_request_old_marketing_start')}</StyledText>
        <StyledDateWrapper>
          <StyledSelect
            disabled
            options={weeksBeforeOptions}
            value={marketingStart ?? null}
          />
        </StyledDateWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('tge_date_request_old_marketing_end')}</StyledText>
        <StyledDateWrapper>
          <StyledSelect
            disabled
            options={weeksAfterOptions}
            value={marketingEnd ?? null}
          />
        </StyledDateWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('tge_date_request_new_planned_tge')}</StyledText>
        <StyledDateWrapper>
          <StyledDatePicker
            disabled
            value={newEstimatedTGE ? dayjs(newEstimatedTGE) : null}
          />
        </StyledDateWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('tge_date_request_new_marketing_start')}</StyledText>
        <StyledDateWrapper>
          <StyledSelect
            disabled
            options={weeksBeforeOptions}
            value={newMarketingStart ?? null}
          />
        </StyledDateWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('tge_date_request_new_marketing_end')}</StyledText>
        <StyledDateWrapper>
          <StyledSelect
            disabled
            options={weeksAfterOptions}
            value={newMarketingEnd ?? null}
          />
        </StyledDateWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('profile_change_request_comment_title')}</StyledText>
        <StyledInputWrapper>
          <StyledTextArea value={request?.comment} rows={4} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <ActionButtons request={request} isLoading={isLoading} />
    </Wrapper>
  );
};

export default TgeDateChangeRequestDetails;
