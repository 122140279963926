import {
  FacebookSVGIcon,
  YoutubeSVGIcon,
  InstagramSVGIcon,
  TelegramSVGIcon,
  LinkedinSVGIcon,
  TwitterSVGIcon,
} from '@/icons';
import { SocialChannels } from '@/models/socialChannel.model';

export const getChannelIcon = (channel: SocialChannels) => {
  switch (channel) {
    case SocialChannels.FACEBOOK:
      return <FacebookSVGIcon />;
    case SocialChannels.INSTAGRAM:
      return <InstagramSVGIcon />;
    case SocialChannels.TWITTER:
      return <TwitterSVGIcon />;
    case SocialChannels.YOUTUBE:
      return <YoutubeSVGIcon />;
    case SocialChannels.LINKEDIN:
      return <LinkedinSVGIcon />;
    case SocialChannels.TELEGRAM:
      return <TelegramSVGIcon />;
  }
};
