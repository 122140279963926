// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledInfoWrapper,
  StyledInfoTitle,
  StyledText,
  CardWrapper,
} from './ProofOfDeliveryStyles';
import { Link } from '@/ui';
import { ArrowRightIcon } from '@/icons';

// Models
import { IMarketingDelivery } from '@/models/marketingDelivery.model';

// Modules
import { MobileOnly } from '@/modules/Adaptive';
import { ProjectTabKeys } from '@/modules/Projects/constants';

// Helpers
import { getUserId, numberWithCommas } from '@/utils';
import { ROUTES_PATHS } from '@/router';

interface IMarketingDeliveryCard {
  data: IMarketingDelivery;
  projectId?: string;
}

const MarketingDeliveryCard = ({ data, projectId }: IMarketingDeliveryCard) => {
  const { t } = useTranslation(['projectsList']);

  return (
    <CardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('proof_of_delivery_campaign_title')}
          </StyledInfoTitle>
        </MobileOnly>
        <StyledText>{data?.campaignTitle}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('proof_of_delivery_planned_posts')}
          </StyledInfoTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.plannedPosts) ?? 0}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('proof_of_delivery_delivered_posts')}
          </StyledInfoTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.deliveredPosts) ?? 0}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledInfoTitle>
            {t('proof_of_delivery_campaign_budget')}
          </StyledInfoTitle>
        </MobileOnly>
        <StyledText>${numberWithCommas(data?.campaignBudget) ?? 0}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <Link
          to={`${ROUTES_PATHS.PROJECTS_LIST}/${encodeURIComponent(
            projectId ?? '',
          )}/${ProjectTabKeys.PROOF_OF_DELIVERY}/${getUserId(
            data?.campaignId ?? '',
          )}`}
        >
          <ArrowRightIcon />
        </Link>
      </StyledInfoWrapper>
    </CardWrapper>
  );
};

export default MarketingDeliveryCard;
