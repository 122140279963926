import { env } from 'src/config/env';

export const { GOOGLE_MAPS_API_KEY } = env;

export const BASE_URL = env.BASE_API_URL;

export const GOOGLE_PLACES_API_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&language=en`;

export const API_ERRORS = {
  MAINLINE_PROCESSING: 'Mainline calculates data',
};
