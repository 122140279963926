import { Upload } from 'antd';
import styled from 'styled-components';
import { COLORS } from '@/theme';

export const StyledDragger = styled(Upload.Dragger)`
  &.ant-upload-wrapper {
    & .ant-upload-drag .ant-upload {
      padding: 0;
    }
    & .ant-upload.ant-upload-drag {
      background: ${COLORS.WHITE};
      border: none;
    }
  }
`;
