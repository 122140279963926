import { TFunction } from 'i18next';
import { DocumentTypes } from '@/models/documents.model';

export const getClickWrapTypeOptions = (t: TFunction) => {
  const requestsTypeConfig = Object.values(DocumentTypes).map(
    (v: DocumentTypes) => {
      return {
        label: t(v),
        value: v,
      };
    },
  );

  return requestsTypeConfig;
};
