// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import CompleteCampaign from '../CompleteCampaign/CompleteCampaign';
import { StyledButton } from './PayoutCardStyles';
import {
  PayoutCardWrapper,
  StyledInfoWrapper,
  StyledTitle,
  StyledText,
  StyledArrowRightIcon,
  StyledLink,
  StyledBadge,
} from '../Styled/index';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Models
import { IPayoutList } from '@/models/payout.model';
import { CampaignStatuses } from '@/models/campaigns.model';

// Helpers
import { getDateString, numberWithCommas } from '@/utils';
import { ROUTES_PATHS } from '@/router';
import { PayoutTabKeys } from '../../constants';

interface IPayoutCard {
  data: IPayoutList;
  completeCampaign: (campaignId: string, isRequest?: boolean) => void;
}

const PayoutCard = ({ data, completeCampaign }: IPayoutCard) => {
  const { t } = useTranslation(['payout', 'campaigns']);
  const isCompleted = data.status === CampaignStatuses.COMPLETED;

  const handleCompleteCampaign = () => {
    completeCampaign(data.id, isCompleted);
  };

  return (
    <PayoutCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_campaign_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.title}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_marketing_end_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{getDateString(data?.marketingEndDate ?? '')}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_project_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.projectTitle}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_planned_deliveries_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {numberWithCommas(data?.campaignResults?.totalBookedPosts ?? 0)}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_confirmed_deliveries_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {numberWithCommas(data?.campaignResults?.totalDeliveredPosts ?? 0)}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_total_payout_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          ${numberWithCommas(data?.campaignResults?.totalPayout ?? 0)}
        </StyledText>
      </StyledInfoWrapper>

      {data.openedRequestId ? (
        <StyledInfoWrapper />
      ) : (
        <StyledInfoWrapper>
          {data?.status === CampaignStatuses.COMPLETED &&
          data?.isPayoutsDone ? (
            <StyledBadge>
              {t(`campaign_status_${data?.status}`, { ns: 'campaigns' })}
            </StyledBadge>
          ) : (
            <CompleteCampaign
              handleComplete={handleCompleteCampaign}
              buttonTitle={
                isCompleted
                  ? 'campaign_campaign_payout_request_title'
                  : 'campaign_finish_title'
              }
              modalTitle={
                isCompleted
                  ? 'campaign_payout_request_title'
                  : 'campaign_complete_campaign_title'
              }
            />
          )}
        </StyledInfoWrapper>
      )}
      <StyledInfoWrapper>
        <StyledLink
          to={`${ROUTES_PATHS.PAYOUT}/${
            PayoutTabKeys.CAMPAIGN
          }/${encodeURIComponent(data?.id ?? '')}`}
        >
          <StyledButton type="ghost">
            {t('payout_go_to_approval_button')}
            <StyledArrowRightIcon />
          </StyledButton>
        </StyledLink>
      </StyledInfoWrapper>
    </PayoutCardWrapper>
  );
};

export default PayoutCard;
