import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { Skeleton } from 'antd';

// Store
import { showErrorToast, useForm } from '@/components';

// Components
import { MarketingContractInformation } from '../../components';

// Modules
import { TFundingContractValues } from '@/modules/FundingRounds/types';
import { PreviewModal } from '@/modules/Documents';

// Models
import {
  DocumentTypes,
  IGetDocumentPreviewDataResponse,
  SigningTypes,
} from '@/models/documents.model';

// Helpers
import { checkFieldValidation, getUserId } from '@/utils';
import { useAppDispatch, useAppState, useModal } from '@/hooks';
import { fundingRoundSliceSelector } from '../../feature/selectors';
import { FundingContractDTO } from '../../dtos';
import {
  getFundingContract,
  getFundingContractPreview,
  updateFundingContract,
} from '../../feature/actionCreators';
import { resetFundingRoundContract } from '../..';

interface IContractInfoContainer {
  isDisabled: boolean;
}
const ContractInfoContainer = ({ isDisabled }: IContractInfoContainer) => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const { fundingRound, fundingContract, isLoading } = useAppState(
    fundingRoundSliceSelector,
  );
  const { isOpened, openModal, closeModal } = useModal();

  const [currentContractData, setCurrentContractData] = useState<
    IGetDocumentPreviewDataResponse | undefined
  >();
  const fundingRoundId = getUserId(fundingRound?.id ?? '');

  const onContractUpdate = useCallback(
    async (
      values: TFundingContractValues,
      allValues: TFundingContractValues,
    ) => {
      const serviceAgreementInfDto = new FundingContractDTO(allValues);
      await checkFieldValidation(values, form);

      await dispatch(
        updateFundingContract({
          ...serviceAgreementInfDto,
          documentType: DocumentTypes.FUNDING_ROUND_GENERAL_TERMS,
          id: fundingRoundId,
        }),
      ).unwrap();
    },
    [dispatch, fundingRoundId],
  );

  const onContractPreview = useCallback(async (type: DocumentTypes) => {
    try {
      const data = await dispatch(
        getFundingContractPreview({
          documentType: type,
          signingType: SigningTypes.CLICK,
          id: fundingRoundId,
        }),
      ).unwrap();

      setCurrentContractData(data);
      openModal();
    } catch (err) {
      showErrorToast({ message: (err as Error).message });
    }
  }, []);

  const debouncedOnContractUpdate = useCallback(
    debounce(onContractUpdate, 1000),
    [fundingRoundId],
  );

  useEffect(() => {
    fundingRoundId &&
      dispatch(
        getFundingContract({
          id: fundingRoundId,
          documentType: DocumentTypes.FUNDING_ROUND_GENERAL_TERMS,
        }),
      );
  }, [fundingRoundId]);

  useEffect(() => {
    return (): void => {
      dispatch(resetFundingRoundContract());
    };
  }, [dispatch]);

  if (isLoading) return <Skeleton />;

  return (
    <>
      <MarketingContractInformation
        handleSubmit={debouncedOnContractUpdate}
        formInstance={form}
        contractInfo={fundingContract}
        disabled={isDisabled}
        onContractPreview={onContractPreview}
      />
      <PreviewModal
        onClose={closeModal}
        clickWrapTemplateDetails={currentContractData}
        isOpened={isOpened}
        loading={isLoading}
      />
    </>
  );
};

export default ContractInfoContainer;
