import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { REQUESTS_DETAILS_SLICE_NAME, RequestsDetailsState } from './models';

export const requestsDetailsSliceSelector = (
  state: RootState,
): RequestsDetailsState => state[REQUESTS_DETAILS_SLICE_NAME];

export const requestsDetailsIsLoading = createSelector(
  requestsDetailsSliceSelector,
  (state: RequestsDetailsState) => state.isLoading,
);
