import { IApiError } from '@/models/apiError.model';

export const UPLOADERS_SLICE_NAME = 'uploaders_slice_name';

export interface UploadersState {
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: UploadersState = {
  isLoading: false,
  error: null,
};
