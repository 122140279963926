// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  Wrapper,
  BlockWrapper,
  StyledText,
  StyledInputWrapper,
  NavigationWrapper,
  StyledButton,
  StyledTitle,
  StyledHeaderWrapper,
  StyledLinksWrap,
} from '../Styled';
import { Link, StyledInput } from '@/ui';
import ActionButtons from '../ActionButtons/ActionButtons';
import RequestStatusBadges from '../RequestStatusBadges/RequestStatusBadges';

// Models
import {
  ICampaignEthereumSwap,
  IRequestDetails,
} from '@/models/requests.model';

// Helpers | Hooks
import { getDateString, getUserLink } from '@/utils';
import { ROUTES_PATHS } from '@/router';

interface ICampaignEthereumSwapDetails {
  request: IRequestDetails | null;
  isLoading?: boolean;
  isEditable?: boolean;
}

const CampaignEthereumSwapDetails = ({
  request,
  isLoading,
}: ICampaignEthereumSwapDetails) => {
  const { t } = useTranslation(['requestsList']);
  const { firstName, lastName, role, id } = request?.requester ?? {};

  const {
    hash,
    currency,
    walletNetwork,
    campaignTitle,
    campaignEndDate,
    campaignStartDate,
    campaignTotalBudget,
    campaignId,
    transferDate,
  } = (request?.data as ICampaignEthereumSwap) ?? {};

  const actionTypeLabel = t(`requests_${request?.actionType}_label`);
  const goToRequester = getUserLink(role ?? '', id ?? '');

  return (
    <Wrapper>
      <StyledHeaderWrapper>
        <StyledTitle>{t('request_title')}</StyledTitle>
        <RequestStatusBadges status={request?.status} />
      </StyledHeaderWrapper>
      <StyledText>{`${t('requests_type')} ${actionTypeLabel}`}</StyledText>
      <StyledText>{t('campaign_ethereum_swap_hint')}</StyledText>
      <NavigationWrapper>
        <StyledTitle $type="h2">
          {t('requests_profile_admin_label')}
        </StyledTitle>
        <StyledLinksWrap>
          <Link
            to={`${ROUTES_PATHS.CAMPAIGNS}/${encodeURIComponent(
              campaignId ?? request?.targetId ?? '',
            )}/campaign`}
            target="_blank"
          >
            <StyledButton type="default">
              {t('verification_request_go_to_campaign')}
            </StyledButton>
          </Link>
          <Link to={goToRequester ?? ''} target="_blank">
            <StyledButton type="primary">
              {t('verification_request_go_to_profile')}
            </StyledButton>
          </Link>
        </StyledLinksWrap>
      </NavigationWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_name_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={firstName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_last_name_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={lastName} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('verification_request_campaign_title')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={campaignTitle} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('campaign_total_budget')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={campaignTotalBudget} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('campaign_start_date')}</StyledText>
        <StyledInputWrapper>
          <StyledInput
            value={getDateString(campaignStartDate ?? '')}
            disabled
          />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('campaign_end_date')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={getDateString(campaignEndDate ?? '')} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('campaign_transfer_date')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={getDateString(transferDate ?? '')} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('campaign_currency')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={currency} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('campaign_hash_code')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={hash} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <StyledText>{t('campaign_safe_wallet')}</StyledText>
        <StyledInputWrapper>
          <StyledInput value={walletNetwork} disabled />
        </StyledInputWrapper>
      </BlockWrapper>
      <ActionButtons request={request} isLoading={isLoading} />
    </Wrapper>
  );
};

export default CampaignEthereumSwapDetails;
