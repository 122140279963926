import { createContext, ReactNode, useMemo, useState } from 'react';
import { AppStage } from '@/models/global.model';
import { env } from '../../config/env';

interface IAppStageContext {
  appStage: AppStage | null;
  forceAppStage: (value: AppStage) => void;
  toggleAppStage: () => void;
}

export const AppStageContext = createContext<IAppStageContext | null>(null);

export const AppStageContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [appStage, setAppStage] = useState<AppStage | null>(
    env.APP_STAGE as AppStage,
  );

  const forceAppStage = (value: AppStage) => setAppStage(value);
  const toggleAppStage = () => {
    const value =
      appStage === AppStage.KOL_PLATFORM
        ? AppStage.SEED_HUNTER
        : AppStage.KOL_PLATFORM;
    setAppStage(value as AppStage);
  };

  const value = useMemo(
    () => ({
      appStage,
      forceAppStage,
      toggleAppStage,
    }),
    [appStage, forceAppStage, toggleAppStage],
  );

  return (
    <AppStageContext.Provider value={value}>
      {children}
    </AppStageContext.Provider>
  );
};
