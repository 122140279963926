import { useTranslation } from 'react-i18next';
import { showSuccessToast } from '@/components';

function useCopyToClipboard() {
  const { t } = useTranslation(['common']);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    showSuccessToast({ message: t('copy_to_clipboard') });
  };

  return copyToClipboard;
}

export default useCopyToClipboard;
