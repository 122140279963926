import { TFunction } from 'react-i18next';
import { UserRoles } from '@/models/admin.model';
import {
  kolSocialChannelOptions,
  projectSocialChannelOptions,
  socialChannelOptions,
} from '../constants/common';
import { ICountryItem } from '@/models/dataLists.model';
import { restrictedCountries } from '@/constants';

const getSocialChannelOptionsConfig = (role?: UserRoles) => {
  switch (role) {
    case UserRoles.KOL:
      return kolSocialChannelOptions;
    case UserRoles.PROJECT:
      return projectSocialChannelOptions;
    default:
      return socialChannelOptions;
  }
};

export const getSocialChannelOptions = (
  translate: TFunction,
  role?: UserRoles,
) => {
  const config = getSocialChannelOptionsConfig(role);
  return config.map((item) => ({
    ...item,
    label: translate(item.label, { ns: 'common' }),
  }));
};

export const getCountryOptions = (arr: ICountryItem[]) => {
  const options = arr
    ?.map((item) => {
      return {
        label: item.name,
        value: item.code,
        disabled: restrictedCountries.has(item.code),
      };
    })
    ?.sort?.((a, b) => a.label.localeCompare(b.label));
  return options;
};

export const getWeeksBeforeOptions = (t: TFunction) => [
  {
    value: 1,
    label: t('projects_information_week_before_TGE', { ns: 'projectsList' }),
  },
  {
    value: 2,
    label: t('projects_information_weeks_before_TGE', {
      count: 2,
      ns: 'projectsList',
    }),
  },
  {
    value: 3,
    label: t('projects_information_weeks_before_TGE', {
      count: 3,
      ns: 'projectsList',
    }),
  },
  {
    value: 4,
    label: t('projects_information_weeks_before_TGE', {
      count: 4,
      ns: 'projectsList',
    }),
  },
  {
    value: 5,
    label: t('projects_information_weeks_before_TGE', {
      count: 5,
      ns: 'projectsList',
    }),
  },
  {
    value: 6,
    label: t('projects_information_weeks_before_TGE', {
      count: 6,
      ns: 'projectsList',
    }),
  },
  {
    value: 7,
    label: t('projects_information_weeks_before_TGE', {
      count: 7,
      ns: 'projectsList',
    }),
  },
];

export const getWeeksAfterOptions = (t: TFunction) => [
  {
    value: 1,
    label: t('projects_information_week_after_TGE', { ns: 'projectsList' }),
  },
  {
    value: 2,
    label: t('projects_information_weeks_after_TGE', {
      count: 2,
      ns: 'projectsList',
    }),
  },
  {
    value: 3,
    label: t('projects_information_weeks_after_TGE', {
      count: 3,
      ns: 'projectsList',
    }),
  },
  {
    value: 4,
    label: t('projects_information_weeks_after_TGE', {
      count: 4,
      ns: 'projectsList',
    }),
  },
];
