import React from 'react';
import { ImageProps, Image as AntdImage } from 'antd';

export type IImage = ImageProps;

const Image = (props: IImage) => {
  return <AntdImage {...props} />;
};

export default Image;
