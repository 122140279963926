import { useState, useEffect } from 'react';

const defaultDelay = 1000 * 60 * 15;

type TCallback = () => void;

const useImperativeInterval = (
  callback: TCallback,
  delay: number = defaultDelay,
) => {
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    if (isRunning) {
      const intervalId = setInterval(callback, delay);

      return () => clearInterval(intervalId);
    }
  }, [isRunning, delay, callback]);

  const startInterval = () => {
    setIsRunning(true);
  };

  const stopInterval = () => {
    setIsRunning(false);
  };

  return { startInterval, stopInterval, isRunning };
};

export default useImperativeInterval;
