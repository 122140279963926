import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { Input } from '@/ui';

export const StyledLabel = styled.div`
  ${pickThemeFontStyles('12', '20', 'NORMAL')};
  color: ${COLORS.SAMBUCUS};
  margin-right: 1.25rem;
`;

export const StyledTableWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  background: ${COLORS.WHITE};
`;

export const StyledInput = styled(Input)`
  &&& {
    padding: 0;
    line-height: 1rem;
    border: 0;
    max-width: 2rem;
    width: 2rem;
    min-width: 2rem;
    border-radius: 0.25rem;
    margin: 0 auto;
    text-align: center;
  }
`;

export const StyledIconWrap = styled.div<{ $disabled: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'inherit' : 'pointer')};
`;
