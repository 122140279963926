import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { Text } from '@/ui';

export const FormWrapper = styled.div`
  width: 100%;
  background: ${COLORS.WHITE};
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  min-height: 6rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 12rem;
    padding: 1rem;
    flex-direction: row;
    margin-bottom: 0rem;
  }
`;

export const StyledFormWrapper = styled(FormWrapper)`
  align-items: flex-start;
  margin-top: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 8rem;
  }
`;

export const FormTextWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-bottom: 0;
  }
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('16', '16', 'SEMI_NORMAL')};
  color: ${COLORS.SAMBUCUS};
`;

export const FormFieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & .ant-form-item {
    margin-bottom: 0rem;
  }
`;

export const FormFields = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;

  :first-child {
    margin-top: 0;
  }

  div {
    flex: 1;
  }
  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    gap: 1.5rem;
  }
`;

export const StyledSubTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
  margin-right: 0.5rem;
`;

export const StyledStatusBlockWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  svg {
    width: 1rem;
    height: 1rem;
    margin-left: 1rem;
  }
`;

export const StyledText = styled(Text)`
  :last-child {
    margin-bottom: 0;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
`;
