import { useTranslation } from 'react-i18next';
import {
  useGetNumbersOnlyRule,
  useGetRequiredRule,
  useLengthValidationRule,
} from '@/hooks';
import { CreditFieldsNames } from '../helpers';

const useGetCreditFormValidationRules = () => {
  const { t } = useTranslation(['common']);
  const required = useGetRequiredRule({ t });
  const textFieldLength = useLengthValidationRule({ t, min: 3, max: 100 });
  const numberFieldLength = useLengthValidationRule({ t, min: 1, max: 5 });
  const numbersOnly = useGetNumbersOnlyRule({ t });

  return {
    [CreditFieldsNames.TITLE]: [required, textFieldLength],
    [CreditFieldsNames.AMOUNT]: [required, numbersOnly, numberFieldLength],
  };
};

export default useGetCreditFormValidationRules;
