import { useCallback } from 'react';
import { useAppDispatch, useAppState } from '@/hooks';
import { selectDataExportsIsLoading } from '../feature/selectors';
import { exportPostsByCampaignId } from '../feature/actionCreators';

export const useExportPosts = (campaignId: string, kolId?: string) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppState(selectDataExportsIsLoading);

  const handler = useCallback(() => {
    dispatch(
      exportPostsByCampaignId({
        campaignId,
        ...(kolId && { kolId }),
      }),
    );
  }, [campaignId, kolId]);

  return { isLoading, handler };
};
