// Globals
import React from 'react';

// Components
import { useTranslation } from 'react-i18next';
import {
  StyledInformationWrapper,
  StyledFrom,
  StyledButton,
  StyledButtonWrapper,
} from './ProjectInformationStyles';
import ProjectMainInformation from '../ProjectMainInformation/ProjectMainInformation';
import ProjectInformationDetails from '../ProjectInformationDetails/ProjectInformationDetails';
import ProjectInformationDescription from '../ProjectInformationDescription/ProjectInformationDescription';
import { IFormInstance } from '@/components';

// Models
import { IProject } from '@/models/projects.model';

interface IProjectInformation {
  projectDetails: IProject | null;
  formInstance: IFormInstance;
  permissionToEdit?: boolean;
  isEditActive: boolean;
  toggleIsEditActive: () => void;
  handleSubmit: (values: IProject) => void;
}

const ProjectInformation = ({
  projectDetails,
  formInstance,
  isEditActive,
  permissionToEdit,
  toggleIsEditActive,
  handleSubmit,
}: IProjectInformation) => {
  const { t } = useTranslation(['projectsList']);

  return projectDetails ? (
    <StyledInformationWrapper>
      <StyledFrom
        form={formInstance}
        initialValues={projectDetails}
        onFinish={handleSubmit}
        disabled={!permissionToEdit}
      >
        <ProjectMainInformation
          projectDetails={projectDetails}
          isEditActive={isEditActive}
          toggleIsEditActive={toggleIsEditActive}
          formInstance={formInstance}
        />
        <ProjectInformationDetails
          projectDetails={projectDetails}
          formInstance={formInstance}
          isEditActive={isEditActive}
        />
        <ProjectInformationDescription
          projectDetails={projectDetails}
          isEditActive={isEditActive}
        />
        {isEditActive && (
          <StyledButtonWrapper>
            <StyledButton type="primary" htmlType="submit">
              {t('projects_information_save')}
            </StyledButton>
          </StyledButtonWrapper>
        )}
      </StyledFrom>
    </StyledInformationWrapper>
  ) : null;
};

export default ProjectInformation;
