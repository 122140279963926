import React, { ForwardedRef, forwardRef, WheelEvent } from 'react';
import { InputProps, InputRef } from 'antd';
import { StyledInput } from './InputStyles';

export type IInputProps = InputProps;

const Input = (
  { prefix, value, ...props }: IInputProps,
  ref: ForwardedRef<InputRef>,
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onWheel = (e: WheelEvent) => e.target.blur();
  return (
    <StyledInput
      {...props}
      value={value}
      prefix={prefix}
      ref={ref}
      onWheel={onWheel}
    />
  );
};

export default forwardRef<InputRef, IInputProps>(Input);
