import styled from 'styled-components';
import { Checkbox } from '@/ui';
import { DEVICES } from '@/theme';

export const StyledBox = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  margin: 1rem 0;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    align-items: center;
    margin: 1rem 0 0 0;
  }
`;

export const StyledCheckBox = styled(Checkbox)`
  margin-bottom: 0.2rem;
`;
