import { TFunction } from 'react-i18next';
import { IMainCampaignGoal } from './types';
import { ICampaignTabsNames } from '@/modules/Campaigns/types';
import { CampaignTabKeys } from '@/modules/Campaigns/constants';

export const getCampaignGoalOptions = (t: TFunction) => [
  {
    value: IMainCampaignGoal.FOLLOWER,
    label: t(IMainCampaignGoal.FOLLOWER, { ns: 'campaigns' }),
  },
  {
    value: IMainCampaignGoal.ENGAGEMENT,
    label: t(IMainCampaignGoal.ENGAGEMENT, { ns: 'campaigns' }),
  },
  {
    value: IMainCampaignGoal.USER_SIGN_UPS,
    label: t(IMainCampaignGoal.USER_SIGN_UPS, { ns: 'campaigns' }),
  },
  {
    value: IMainCampaignGoal.PERSONALIZED,
    label: t(IMainCampaignGoal.PERSONALIZED, { ns: 'campaigns' }),
  },
];

export const getCampaignTabsConfig = (earlyAccess: boolean) => [
  {
    label: 'campaign',
    key: CampaignTabKeys.CAMPAIGN,
    name: ICampaignTabsNames.CAMPAIGN,
  },
  {
    label: 'resources',
    key: CampaignTabKeys.RESOURCES,
    name: ICampaignTabsNames.RESOURCES,
  },
  {
    label: 'marketing-delivery',
    key: CampaignTabKeys.MARKETING_DELIVERY,
    name: ICampaignTabsNames.MARKETING_DELIVERY,
  },
  ...(earlyAccess
    ? [
        {
          label: 'early-access',
          key: CampaignTabKeys.EARLY_ACCESS,
          name: ICampaignTabsNames.EARLY_ACCESS,
        },
      ]
    : []),
  // TODO Hided for now
  // {
  //   label: 'content-distribution',
  //   key: CampaignTabKeys.CONTENT_DISTRIBUTION,
  //   name: ICampaignTabsNames.CONTENT_DISTRIBUTION,
  // },
  {
    label: 'participation',
    key: CampaignTabKeys.PARTICIPATION,
    name: ICampaignTabsNames.PARTICIPATION,
  },
  {
    label: 'Results',
    key: CampaignTabKeys.RESULTS,
    name: ICampaignTabsNames.RESULTS,
  },
];
