export enum MilestonesEndpoints {
  FUNDING_ROUNDS = 'admin/funding-rounds',
}

export interface IMilestone {
  id?: string;
  projectId?: string;
  fundingRoundId?: string;
  title?: string;
  subGoals?: string;
  approvalRequirement?: string;
  isAchieved?: boolean;
  created?: string;
}

export interface IMilestones {
  timeframeToDeliverMilestones?: string;
  title?: IMilestone[];
}

// --------------DTO------------

export interface IMilestoneDTO {
  id?: string;
  milestoneId?: string;
  fundingRoundId?: string;
  title?: string;
  isAchieved?: boolean;
  approvalRequirement?: string;
  subGoals?: string;
}

export interface IRemoveMilestoneDTO {
  milestoneId?: string;
  id?: string;
}
