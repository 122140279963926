import { CampaignStatuses } from '@/models/campaigns.model';
import { IBaseItem } from '@/types';

interface IConfig {
  value: CampaignStatuses;
  label: string;
  statuses: CampaignStatuses[];
}

export const getCampaignStatusesConfig = (
  currentStatus: CampaignStatuses,
  config: IConfig[],
) => {
  const statusesConfig = config.reduce((acc, current) => {
    if (current.statuses.includes(currentStatus)) {
      acc.push({
        label: current.label,
        value: current.value,
      });
    }
    return acc;
  }, [] as IBaseItem[]);

  return statusesConfig;
};
