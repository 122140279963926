import dayjs from 'dayjs';
import { isDateInRange } from '@/utils';
import { CampaignActiveStatuses } from '../constants/common';

export const getActiveCampaignStatus = (startDate: string, endDate: string) => {
  switch (true) {
    case isDateInRange(dayjs(), dayjs(startDate), dayjs(endDate)):
      return CampaignActiveStatuses.MARKETING_DELIVERY;
    case dayjs().isBefore(dayjs(startDate)):
      return CampaignActiveStatuses.SIGN_UP;
    default:
      return CampaignActiveStatuses.COMPLETED;
  }
};
