import { useTranslation } from 'react-i18next';
import {
  useGetEmailRule,
  useGetOtpCodeRule,
  useGetPasswordRule,
  useGetRequiredRule,
} from '@/hooks';
import { AuthFieldsNames } from '../helpers/types';

const useGetFormValidationRules = () => {
  const { t } = useTranslation(['common']);

  const required = useGetRequiredRule({ t });
  const email = useGetEmailRule({ t });
  const password = useGetPasswordRule({ t });
  const otpCode = useGetOtpCodeRule({ t });

  return {
    [AuthFieldsNames.EMAIL]: [required, email],
    [AuthFieldsNames.PASSWORD]: [required, password],
    [AuthFieldsNames.NEW_PASSWORD]: [required, password],
    [AuthFieldsNames.OTP_CODE]: [required, otpCode],
  };
};

export default useGetFormValidationRules;
