// Globals
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';

// Components
import {
  StyledButton,
  StyledArrowRightIcon,
  StyledStatusWrapper,
  StyledForm,
  StyledSubmitButton,
  StyledButtonsWrapper,
  StyledFormItem,
  StyledSelect,
} from './DocumentCreateContainerStyles';
import {
  Title,
  StyledPageWrapper,
  Spinner,
  HTMLEditor,
  StyledSpinnerWrap,
} from '@/ui';
import { showErrorToast, showSuccessToast } from '@/components';
import PreviewModal from '../../components/PreviewModal/PreviewModal';

// Modules
import { createClickWrapTemplate } from '@/modules/Documents';
import { resetErrors } from '@/modules/KolsList';
import { CreateTemplateFieldsNames } from '@/modules/Documents/types';

// Models
import { ICreateClickWrapTemplateValues } from '@/models/documents.model';

// Hooks | Helpers
import { useAppDispatch, useAppState, useModal } from '@/hooks';
import { ROUTES_PATHS } from '@/router';
import { documentsSliceSelector } from '../../feature/selectors';
import { checkFieldValidation, isHTMLValid } from '@/utils';
import { getClickWrapTypeOptions } from '../../utils/options';
import CreateClickWrapTemplateDTO from '../../dtos/CreateClickWrapTemplateDTO';
import useGetFormValidationRules from '../../hooks/useGetFormValidationRules';

const DocumentDetailsContainer = () => {
  const { t } = useTranslation(['documents']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = useForm();
  const rules = useGetFormValidationRules();
  const { isLoading } = useAppState(documentsSliceSelector);
  const { isOpened, openModal, closeModal } = useModal();

  const navigateToDocumentsList = () => {
    navigate(ROUTES_PATHS.DOCUMENTS);
  };

  const handleSubmit = useCallback(
    async (values: ICreateClickWrapTemplateValues) => {
      await checkFieldValidation(values, form);

      if (!isHTMLValid(values.htmlContent)) {
        return showErrorToast({ message: 'documents_invalid_html' });
      }
      try {
        const dto = new CreateClickWrapTemplateDTO(values);
        dispatch(resetErrors());
        await dispatch(createClickWrapTemplate(dto));

        showSuccessToast({
          message: t('Success'),
        });
        navigateToDocumentsList();
      } catch (e) {
        showErrorToast({ message: (e as Error).message });
      }
    },
    [form, dispatch],
  );

  if (isLoading) {
    return (
      <StyledSpinnerWrap>
        <Spinner />
      </StyledSpinnerWrap>
    );
  }

  return (
    <>
      <StyledPageWrapper>
        <StyledStatusWrapper>
          <Title $type="h3">{t('documents_create')}</Title>
        </StyledStatusWrapper>
        <StyledButton type="ghost" onClick={navigateToDocumentsList}>
          <StyledArrowRightIcon />
          {t('documents_go_back')}
        </StyledButton>

        <StyledForm onFinish={handleSubmit} form={form}>
          <StyledFormItem
            name={CreateTemplateFieldsNames.TEMPLATE_TYPE}
            rules={rules[CreateTemplateFieldsNames.TEMPLATE_TYPE]}
            label={t('documents_list_document_type')}
          >
            <StyledSelect
              options={getClickWrapTypeOptions(t)}
              placeholder={t('documents_list_document_type')}
              showArrow={false}
            />
          </StyledFormItem>
          <StyledFormItem
            name={CreateTemplateFieldsNames.HTML_CONTENT}
            rules={[...rules[CreateTemplateFieldsNames.TEMPLATE_TYPE]]}
            label={t('documents_list_html_content')}
          >
            <HTMLEditor withPreview={false} />
          </StyledFormItem>
          <StyledButtonsWrapper>
            <StyledSubmitButton
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {t('documents_create')}
            </StyledSubmitButton>
            <StyledSubmitButton
              type="primary"
              onClick={openModal}
              loading={isLoading}
            >
              {t('documents_list_preview')}
            </StyledSubmitButton>
          </StyledButtonsWrapper>
        </StyledForm>
      </StyledPageWrapper>
      <PreviewModal
        isOpened={isOpened}
        clickWrapTemplateDetails={form.getFieldsValue()}
        onClose={closeModal}
      />
    </>
  );
};

export default DocumentDetailsContainer;
