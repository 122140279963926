// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  CampaignListWrapper,
  StyledInfoTitles,
  StyledTitles,
} from './CampaignsListStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import CampaignCard from '../CampaignCard/CampaignCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Helpers
import { ICampaign } from '@/models/campaigns.model';
import { useInfiniteScroll } from '@/hooks';

interface ICampaignList {
  campaigns: {
    items: ICampaign[] | null;
    hasMore: boolean;
  };
  getNextCampaigns: () => void;
  isLoading: boolean;
}

const CampaignsList = ({
  campaigns,
  getNextCampaigns,
  isLoading,
}: ICampaignList) => {
  const { t } = useTranslation(['campaigns']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedCampaignList = useMemo(() => {
    return campaigns?.items?.map((campaignItem) => {
      return <CampaignCard campaign={campaignItem} key={campaignItem?.id} />;
    });
  }, [campaigns?.items]);

  useInfiniteScroll({
    callback: getNextCampaigns,
    ref: loaderRef,
    isLoading,
    hasMore: campaigns?.hasMore,
  });

  return (
    <CampaignListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('campaigns_campaign_title')}</StyledTitles>
          <StyledTitles>{t('campaigns_project_title')}</StyledTitles>
          <StyledTitles>{t('campaigns_marketing_start_title')}</StyledTitles>
          <StyledTitles>{t('campaigns_marketing_end_title')}</StyledTitles>
          <StyledTitles>{t('campaigns_campaign_budget_title')}</StyledTitles>
          <StyledTitles>{t('campaigns_status_title')}</StyledTitles>
          <StyledTitles>{t('campaigns_details_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedCampaignList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </CampaignListWrapper>
  );
};

export default CampaignsList;
