import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { formatApiError } from '@/utils';
import {
  ILegalAgreementsDTO,
  ILegalAgreementResponse,
  IWithdrawalHistory,
  IWithdrawalHistoryDTO,
  ISeedHunterCreditsListResponse,
  ISeedHunterCreditsListDTO,
  ICreditBonusDTO,
  ICreditHistory,
  IFundingRoundAffiliateResponse,
  IFundingRoundAffiliateDTO,
  IUpdateUserVerificationDTO,
} from '@/models/sharedProfile.model';
import SharedProfileAPI from 'src/api/SharedProfileAPI';
import { SHARED_PROFILE_SLICE_NAME } from './models';

export const getLegalAgreements = createAsyncThunk<
  ILegalAgreementResponse[],
  ILegalAgreementsDTO,
  { serializedErrorType: IApiError }
>(
  `${SHARED_PROFILE_SLICE_NAME}/getLegalAgreements`,
  async (data) => {
    const response = await SharedProfileAPI.getLegalAgreements({
      ...data,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getWithdrawalHistory = createAsyncThunk<
  IWithdrawalHistory[],
  IWithdrawalHistoryDTO,
  { serializedErrorType: IApiError }
>(
  `${SHARED_PROFILE_SLICE_NAME}/getWithdrawalLogs`,
  async (data) => {
    const response = await SharedProfileAPI.getWithdrawalHistory({
      ...data,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getSeedHunterCreditList = createAsyncThunk<
  ISeedHunterCreditsListResponse,
  ISeedHunterCreditsListDTO,
  { serializedErrorType: IApiError }
>(
  `${SHARED_PROFILE_SLICE_NAME}/getSeedHunterCreditList`,
  async (data: ISeedHunterCreditsListDTO) => {
    const response = await SharedProfileAPI.getSeedHunterCreditsList({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const postCreditBonus = createAsyncThunk<
  ICreditHistory,
  ICreditBonusDTO,
  { serializedErrorType: IApiError }
>(
  `${SHARED_PROFILE_SLICE_NAME}/postCreditBonus`,
  async (data) => {
    const response = await SharedProfileAPI.postCreditBonus(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getFundingRoundAffiliateList = createAsyncThunk<
  IFundingRoundAffiliateResponse,
  IFundingRoundAffiliateDTO,
  { serializedErrorType: IApiError }
>(
  `${SHARED_PROFILE_SLICE_NAME}/getFundingRoundAffiliateList`,
  async (data: IFundingRoundAffiliateDTO) => {
    const response = await SharedProfileAPI.getFundingRoundAffiliateList({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const forceBlockUser = createAsyncThunk<
  { status: string },
  IUpdateUserVerificationDTO,
  { serializedErrorType: IApiError }
>(
  `${SHARED_PROFILE_SLICE_NAME}/forceBlockUser`,
  async (data: IUpdateUserVerificationDTO) => {
    const response = await SharedProfileAPI.forceBlockUser({
      ...data,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const forceUnblockUser = createAsyncThunk<
  { status: string },
  IUpdateUserVerificationDTO,
  { serializedErrorType: IApiError }
>(
  `${SHARED_PROFILE_SLICE_NAME}/forceUnblockUser`,
  async (data: IUpdateUserVerificationDTO) => {
    const response = await SharedProfileAPI.forceUnblockUser({
      ...data,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);
