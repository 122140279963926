import { VerificationTypes } from '@/models/sharedProfile.model';

export const omitProjectFields = [
  'investedTokensForMarketing',
  'investedTokensForMoney',
  'KYB',
  'created',
  'affiliateCode',
  'tokensForMarketingInTokens',
  'contentDistributionCreatedAt',
  'contentDistributionStatus',
  'tokensForMoneyInCurrency',
  'investedAmount',
  'role',
  'verifiedAt',
  'tokensForMoneyInTokens',
  'investedMoneyAmount',
  'investorsNumber',
  'modified',
  'tokensForMarketingInCurrency',
  'lastInvestmentDate',
  'investedTokensAmount',
];

export const BASE_SYNAPS_URL = 'https://manager.synaps.io/';

export const SYNAPS_APP_IDS = {
  [VerificationTypes.KYC]: '463835438',
  [VerificationTypes.KYC_DEV]: '425401725',
  [VerificationTypes.KYB]: '463834699',
  [VerificationTypes.KYB_DEV]: '425401758',
};
