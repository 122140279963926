import styled from 'styled-components';
import { DEVICES } from '@/theme';

export const TabsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  min-width: 100%;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom: 1.5rem;
  height: 3.3rem;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    height: 3.875rem;
  }
`;

export const SectionTabWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  gap: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    gap: 1.75rem;
  }
`;
