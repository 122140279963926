import styled from 'styled-components';
import { DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout } from '@/ui';

export const StyledWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
`;

export const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledCreditsAvailableBlock = styled(BlockLayout)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  max-width: 100%;
  ${pickThemeFontStyles('14', '20', 'BOLD')};

  @media screen and ${DEVICES.LAPTOP_S} {
    max-width: 30%;
  }
`;
