// Global
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component
import {
  StyledInfoWrapper,
  CardWrapper,
  StyledText,
  StyledTitle,
} from './AffiliateListStyles';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Models
import { IReportAffiliateItem } from '@/models/reports.model';

interface IAffiliateCard {
  affiliateData: IReportAffiliateItem;
}

const AffiliateCard = ({ affiliateData }: IAffiliateCard) => {
  const { t } = useTranslation(['reports']);

  return (
    <CardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('report_details_affiliate_invitee')}</StyledTitle>
        </MobileOnly>
        <StyledText>{`${affiliateData?.recipientUserName} (${affiliateData?.recipientUserName})`}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('report_details_affiliate_invited')}</StyledTitle>
        </MobileOnly>
        <StyledText>{`${affiliateData?.invitedUserName} (${affiliateData?.invitedUserRole})`}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('report_details_affiliate_rewards')}</StyledTitle>
        </MobileOnly>
        <StyledText>{`${affiliateData?.affiliateCommission}`}</StyledText>
      </StyledInfoWrapper>
    </CardWrapper>
  );
};

export default AffiliateCard;
