import { useTranslation } from 'react-i18next';
import { useGetRequiredRule, useLengthValidationRule } from '@/hooks';
import { TextLength } from '@/constants';
import { FundingContractFieldsNames } from '../types';

const useGetContractFormValidationRules = () => {
  const { t } = useTranslation(['common']);

  const required = useGetRequiredRule({ t });

  const textDescriptionLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.large,
  });

  const titleFieldLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.titleMedium,
  });

  return {
    [FundingContractFieldsNames.TITLE]: [required, titleFieldLength],
    [FundingContractFieldsNames.DESCRIPTION]: [required, textDescriptionLength],
    [FundingContractFieldsNames.ADDITIONAL_DEFINITIONS]: [
      required,
      textDescriptionLength,
    ],
    [FundingContractFieldsNames.EXCLUDED_JURISDICTIONS]: [
      required,
      textDescriptionLength,
    ],
  };
};

export default useGetContractFormValidationRules;
