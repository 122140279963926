import { createSlice } from '@reduxjs/toolkit';
import {
  fetchCryptoCurrencies,
  fetchSettings,
  fetchGeographies,
  getVerifiedKols,
} from './actionCreators';
import { getUserOptions } from '../helpers/getOptions';
import { DATA_LISTS_SLICE_NAME, DataListsState, initialState } from './models';
import { DataLists } from '@/models/dataLists.model';
import { showApiErrors, sortAlphabeticalBy } from '@/utils';

export const dataListsSlice = createSlice({
  name: DATA_LISTS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCryptoCurrencies.fulfilled,
        (state: DataListsState, action) => {
          state[DataLists.CRYPTO_CURRENCIES].isLoading = false;
          state[DataLists.CRYPTO_CURRENCIES].list = sortAlphabeticalBy(
            action.payload,
            'label',
          );
        },
      )
      .addCase(fetchSettings.fulfilled, (state: DataListsState, action) => {
        const { payload } = action;
        const entries = Object.entries(payload) as [
          keyof typeof DataLists,
          never,
        ][];
        entries.map(([key, value]) => {
          state[key] = value;
        });

        state.isLoading = false;
      })
      .addCase(getVerifiedKols.fulfilled, (state: DataListsState, action) => {
        const { payload } = action;

        state.usersOptions = getUserOptions(payload);
        state.isLoading = false;
      })
      .addCase(fetchGeographies.fulfilled, (state: DataListsState, action) => {
        state.isLoading = false;
        state.geographies = sortAlphabeticalBy(action.payload, 'label');
      })
      .addCase(fetchSettings.pending, (state: DataListsState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSettings.rejected, (state: DataListsState, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;

        showApiErrors(error);
      });
  },
});

export default dataListsSlice.reducer;
