// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  ListWrapper,
  StyledTitles,
  StyledInfoTitles,
} from './AffiliateListStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import AffiliateCard from './AffiliateCard';

// Models
import { IReportAffiliateItem } from '@/models/reports.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

interface IAffiliateList {
  isLoading: boolean;
  affiliateItems: IReportAffiliateItem[] | null;
}

const AffiliateList = ({ isLoading, affiliateItems }: IAffiliateList) => {
  const { t } = useTranslation(['reports']);
  const mappedAffiliateList = useMemo(() => {
    return affiliateItems?.map((affiliateData) => {
      return (
        <AffiliateCard
          affiliateData={affiliateData}
          key={affiliateData.participationId}
        />
      );
    });
  }, [affiliateItems]);

  return (
    <ListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('report_details_affiliate_invitee')}</StyledTitles>
          <StyledTitles>{t('report_details_affiliate_invited')}</StyledTitles>
          <StyledTitles>{t('report_details_affiliate_rewards')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedAffiliateList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
    </ListWrapper>
  );
};

export default AffiliateList;
