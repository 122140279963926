import React from 'react';
import { ProgressProps } from 'antd';
import {
  StyledProgress,
  Wrapper,
  PercentsWrap,
  StyledPercent,
} from './ProgressStyles';

const Progress = ({ percent, ...props }: ProgressProps): JSX.Element => (
  <Wrapper>
    <StyledProgress percent={percent} {...props} showInfo={false} />
    <PercentsWrap>
      <StyledPercent>0%</StyledPercent>
      <StyledPercent>100%</StyledPercent>
    </PercentsWrap>
  </Wrapper>
);

export default Progress;
