import { IApiError } from '@/models/apiError.model';
import { IRequestDetails } from '@/models/requests.model';

export const REQUESTS_DETAILS_SLICE_NAME = 'requests-details';

export interface RequestsDetailsState {
  currentRequest: IRequestDetails | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: RequestsDetailsState = {
  currentRequest: null,
  isLoading: false,
  error: null,
};
