import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { showApiErrors } from '@/utils';
import { initialState, DASHBOARD_SLICE_NAME, DashboardState } from './models';
import {
  getStatisticsGeneral,
  getStatisticsInvestments,
  getStatisticsRequests,
  getStatisticsUsers,
} from './actionCreators';

export const slice = createSlice({
  name: DASHBOARD_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getStatisticsGeneral.fulfilled,
        (state: DashboardState, action) => {
          state.generalStatistic = action?.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        getStatisticsRequests.fulfilled,
        (state: DashboardState, action) => {
          state.requestsStatistic = action?.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        getStatisticsInvestments.fulfilled,
        (state: DashboardState, action) => {
          state.totalInvestmentsInMarketing =
            action?.payload?.totalInvestmentsInMarketing;
          state.totalInvestmentsInMoney =
            action?.payload?.totalInvestmentsInMoney;
          state.isLoading = false;
        },
      )
      .addCase(
        getStatisticsUsers.fulfilled,
        (state: DashboardState, action) => {
          state.usersStatistics = action?.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(isPending(getStatisticsGeneral), (state: DashboardState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(
        isRejected(getStatisticsGeneral),
        (state: DashboardState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export default slice.reducer;
