import omit from 'lodash.omit';
import { ApiResponse, httpGet, httpPost } from './httpClient';
import {
  IGetPartnersListDTO,
  IPartner,
  IPartnersListResponse,
  PartnersListEndpoints,
  IGetPartnerInvestmentsDTO,
  IPartnerAffiliateResponse,
  IUpdatePartnersDetailsDTO,
  IPartnersDetailsDTO,
  IBlockPartnerUser,
} from '@/models/partnersList.model';
import {
  IGetInvitedUsersDTO,
  IGetInvitedUsersResponse,
} from '@/models/sharedProfile.model';

class PartnersListAPI {
  static getPartnersList(
    data: IGetPartnersListDTO,
  ): ApiResponse<IPartnersListResponse> {
    return httpGet<IPartnersListResponse>(
      PartnersListEndpoints.GET_PARTNERS_LIST,
      { ...data },
    );
  }
  static getPartnerDetails(id: string): ApiResponse<IPartner> {
    return httpGet<IPartner>(
      `${PartnersListEndpoints.GET_PARTNERS_LIST}/${encodeURI(id)}`,
    );
  }

  static getPartnerAffiliateData(
    data: IGetPartnerInvestmentsDTO,
  ): ApiResponse<IPartnerAffiliateResponse> {
    return httpGet<IPartnerAffiliateResponse>(
      `${PartnersListEndpoints.GET_PARTNERS_LIST}/${encodeURI(
        data?.partnerId ?? '',
      )}/marketing-affiliate`,
      { ...data },
    );
  }

  static updatePartnerDetails(data: IUpdatePartnersDetailsDTO) {
    const { partner, id } = data;
    return httpPost<IPartnersDetailsDTO, IPartner>(
      `${PartnersListEndpoints.GET_PARTNERS_LIST}/${encodeURI(id)}`,
      partner,
    );
  }

  static blockPartnerUser(data: IBlockPartnerUser) {
    return httpPost<IBlockPartnerUser, IPartner>(
      `${PartnersListEndpoints.GET_PARTNERS_LIST}/${encodeURI(
        data?.id ?? '',
      )}/block`,
      omit(data, 'id'),
    );
  }

  static getInvitedUsers({
    userId,
    ...data
  }: IGetInvitedUsersDTO): ApiResponse<IGetInvitedUsersResponse> {
    return httpGet<IGetInvitedUsersResponse>(
      `${PartnersListEndpoints.GET_PARTNERS_LIST}/${encodeURI(
        userId,
      )}/invited-users`,
      { ...data },
    );
  }
}

export default PartnersListAPI;
