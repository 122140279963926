import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';

export const StyledInfoWrapper = styled(BlockLayout)`
  display: flex;
  align-items: flex-start;
  position: relative;
  gap: 0.5rem;
  padding: 1rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
`;

export const StyledInfo = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
  gap: 0.625rem;

  :nth-last-child(2) {
    flex: 0.1;
  }
  :last-child {
    flex: 1.5;
    border-top: 1px solid ${COLORS.LIGHTEST_GRAY};
    padding: 1rem;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    :last-child {
      flex: 1.5;
      border-left: 1px solid ${COLORS.LIGHTEST_GRAY};
      border-top: 0px;
      padding: 1rem;
    }
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  &&& {
    max-width: 100%;
    height: 2rem;
    background: ${COLORS.SOLITUDE_GRAY};
    color: ${COLORS.DARK_BLUE};
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
    align-items: center;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &&& {
      max-width: 11rem;
    }
  }
`;

export const StyledButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100%;
`;

export const StyledBlockWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;

  & :last-child {
    border-bottom: 0;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    gap: 1.5rem;
  }
`;

export const StyledFormWrapper = styled.div`
  align-items: flex-start;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 8rem;
  }
`;

export const StyledFieldsRow = styled.div`
  &&& {
    display: flex;
    width: 100%;
    gap: 1rem;
    flex-direction: column;

    .ant-form-item-row {
      display: flex;
      flex-direction: column;
    }

    .ant-form-item {
      max-width: 13rem;
    }

    .ant-form-item-label {
      text-align: start;
    }
    &&& label {
      flex-grow: initial;
      height: initial;
      ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
      color: ${COLORS.GRAY};
      @media screen and ${DEVICES.LAPTOP_S} {
        ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
      }

      &:before {
        display: none;
      }
    }

    @media screen and ${DEVICES.LAPTOP_S} {
      gap: 1.5rem;
      flex-direction: row;
    }
  }
`;

export const StyledFieldsColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & .ant-form-item {
    margin-bottom: 0rem;
  }
`;
