import { Form } from 'antd';
import styled from 'styled-components';

export const StyledFormItem = styled(Form.Item)`
  & .ant-form-item-label {
    padding-bottom: 0.25rem;
    font-weight: 500;
  }
  .ant-form-item-control-input {
    min-height: auto;
  }

  &&& .ant-form-item-optional {
    display: none;
  }
`;
