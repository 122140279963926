// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledResourceWrapper,
  StyledResourcesButton,
  StyledResource,
  StyledA,
  StyledBlock,
  StyledSubTitle,
} from './CampaignResoursesStyles';

// Models
import { ICampaign } from '@/models/campaigns.model';

interface IResource {
  data: ICampaign | null;
}

const CampaignResources = ({ data }: IResource) => {
  const { t } = useTranslation(['campaigns']);

  const mappedResources = useMemo(() => {
    return data?.resources?.map((item) => (
      <StyledResourceWrapper key={item.fileName}>
        <StyledResource>{item.fileName}</StyledResource>
        <StyledA $isBold href={item?.link} target="_blank">
          <StyledResourcesButton type="primary">
            {t('campaign_details_download_button')}
          </StyledResourcesButton>
        </StyledA>
      </StyledResourceWrapper>
    ));
  }, [data]);

  return (
    <StyledBlock>
      <StyledSubTitle>{t('campaign_resources_title')}</StyledSubTitle>
      {data?.resources ? mappedResources : t('campaign_resources_text')}
    </StyledBlock>
  );
};

export default CampaignResources;
