import { useMemo } from 'react';
import { TFunction } from 'react-i18next';
import {
  emailRegex,
  nameRegex,
  phoneRegex,
  passwordRegex,
  zipCodeRegex,
  numbersOnlyRegex,
  hashTegRegex,
  noWhiteSpaceRegex,
  urlRegex,
  otpCodeRegex,
} from '@/constants';
import type { TFormValidatorRule } from '@/types';

const getRequiredRule = (t: TFunction) => ({
  required: true,
  message: t('validation_message_required', { ns: 'common' }),
});

const getTextdLengthValidationRule = (
  t: TFunction,
  max: number,
  min?: number,
) => {
  const messageKey = min
    ? 'validation_text_message_length'
    : 'validation_message_max_length';

  return {
    min,
    max,
    message: t(messageKey, { ns: 'common', min, max }),
  };
};

const getArrayLengthValidationRule = (
  t: TFunction,
  max: number,
  min?: number,
) => {
  const messageKey = min
    ? 'validation_message_array_length'
    : 'validation_message_array_max_length';

  return {
    min,
    max,
    type: 'array' as const,
    message: t(messageKey, { ns: 'common', min, max }),
  };
};

export const useGetRequiredRule = ({ t }: { t: TFunction }) =>
  useMemo(() => getRequiredRule(t), [t]);

export const useArrayLengthValidationRule = ({
  t,
  min,
  max,
}: {
  t: TFunction;
  max: number;
  min?: number;
}) => useMemo(() => getArrayLengthValidationRule(t, max, min), [t, max, min]);

export const useLengthValidationRule = ({
  t,
  min,
  max,
}: {
  t: TFunction;
  max: number;
  min?: number;
}) => useMemo(() => getTextdLengthValidationRule(t, max, min), [t, max, min]);

export const useGetEmailRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: emailRegex,
      message: t('validation_message_email', { ns: 'common' }),
    }),
    [t],
  );

export const useGetPasswordRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: passwordRegex,
      message: t('validation_password_message_length', { ns: 'common' }),
    }),
    [t],
  );

export const useGetFullNameRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: nameRegex,
      message: t('validation_message_full_name', { ns: 'common' }),
    }),
    [t],
  );

export const useGetZipCodeRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: zipCodeRegex,
      message: t('validation_message_zip_code', { ns: 'common' }),
    }),
    [t],
  );

export const useGetPhoneRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: phoneRegex,
      message: t('validation_message_phone', { ns: 'common' }),
    }),
    [t],
  );

export const useGetUrlRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: urlRegex,
      message: t('validation_message_url', { ns: 'common' }),
    }),
    [t],
  );

export const useGetNoSpaceRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: noWhiteSpaceRegex,
      message: t('validation_no_space', { ns: 'common' }),
    }),
    [t],
  );

export const useHashTegRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: hashTegRegex,
      message: t('validation_alphanumeric_underscore_only', { ns: 'common' }),
    }),
    [t],
  );

export const useGetNumbersOnlyRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: numbersOnlyRegex,
      message: t('validation_numbers_only', { ns: 'common' }),
    }),
    [t],
  );

export const useGetOtpCodeRule = ({ t }: { t: TFunction }) =>
  useMemo(
    () => ({
      pattern: otpCodeRegex,
      message: t('validation_otp_code', { ns: 'common' }),
    }),
    [t],
  );

export const useDependentRequiredFieldsValidator =
  <
    TFormFields extends string,
    TFormValues extends Partial<Record<TFormFields, unknown>>,
  >(
    t: TFunction,
    fieldName: TFormFields,
    dependencyFieldName: TFormFields,
  ): TFormValidatorRule =>
  ({ getFieldsValue }) => ({
    validator() {
      const values: TFormValues = getFieldsValue();
      const {
        [fieldName]: fieldNameValue,
        [dependencyFieldName]: dependencyFieldNameValue,
      } = values;

      if (!fieldNameValue && !dependencyFieldNameValue) {
        return Promise.resolve();
      }

      if (!fieldNameValue) {
        return Promise.reject(
          t('validation_message_required', { ns: 'common' }),
        );
      }

      return Promise.resolve();
    },
  });
