import { VIDEO_FORMATS, FILE_FORMATS } from '@/modules/Uploaders';
import { GenericFileTypes } from '../constants';

export const defineFileType = (type: string) => {
  switch (true) {
    case VIDEO_FORMATS.includes(type):
      return GenericFileTypes.VIDEO;
    case FILE_FORMATS.includes(type):
      return GenericFileTypes.FILE;
    default:
      return GenericFileTypes.IMAGE;
  }
};
