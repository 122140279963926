import { IApiError } from '@/models/apiError.model';
import { showErrorToast } from '@/components';
import { isSessionError } from './formatApiError';

const showApiErrors = ({ message, errors, status }: IApiError) => {
  console.warn('=====> message, errors', message, errors);
  if (
    isSessionError({
      response: {
        status,
      },
    })
  ) {
    return;
  }

  if (errors) {
    return Object.values(errors).map((value) =>
      showErrorToast({ message: value || message }),
    );
  }

  return showErrorToast({ message });
};

export default showApiErrors;
