// GLobals
import React, { useEffect } from 'react';

// Components
import { AffiliateWithdrawalHistory } from '../../components';

// Model
import { LegalAgreementRoles } from '@/models/sharedProfile.model';

// Hooks | Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import { getWithdrawalHistory } from '../../feature/actionCreators';
import { profileSliceSelector } from '../../feature/selectors';

interface IAffiliateWithdrawalHistoryContainer {
  role: LegalAgreementRoles;
  id: string;
}

const AffiliateWithdrawalHistoryContainer = ({
  role,
  id,
}: IAffiliateWithdrawalHistoryContainer) => {
  const dispatch = useAppDispatch();
  const { isLoading, withdrawalHistory } = useAppState(profileSliceSelector);

  useEffect(() => {
    dispatch(getWithdrawalHistory({ id: id, role: role }));
  }, []);

  return (
    <AffiliateWithdrawalHistory
      withdrawalHistory={withdrawalHistory}
      isLoading={isLoading}
    />
  );
};

export default AffiliateWithdrawalHistoryContainer;
