import styled from 'styled-components';
import { ArrowRightIcon } from '@/icons';
import { Button } from '@/ui';
import { COLORS } from '@/theme';

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
  margin-right: 0.5rem;
  transform: rotate(180deg);
`;

export const StyledButton = styled(Button)`
  &&& {
    min-width: 7rem;
    font-weight: 600;
    font-size: 0.75rem;
    height: 2.5rem;
    padding: 0.75rem;
    color: ${COLORS.CORN_FLOWER_BLUE};
    background: ${COLORS.SOLITUDE_GRAY};
  }
  margin-top: 2rem;
`;
