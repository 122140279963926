// Global
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledAffiliateWrapper,
  StyledInfo,
  StyledTitle,
} from './AffiliateCardStyles';
import { Text } from '@/ui';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Utils
import { numberWithCommas } from '@/utils';
import { MarketingAffiliateCommission } from '@/models/kolsList.model';

interface IAffiliate {
  affiliate: MarketingAffiliateCommission;
}

const AffiliateCard = ({ affiliate }: IAffiliate) => {
  const { t } = useTranslation(['common', 'kolsList']);

  return (
    <StyledAffiliateWrapper>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>{t('kols_affiliate_invited_user_title')}</StyledTitle>
        </MobileOnly>
        <Text>{affiliate.invitedUserName}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>
            {t('kols_affiliate_invited_user_role_title')}
          </StyledTitle>
        </MobileOnly>
        <Text>{t(affiliate.invitedUserRole?.toLowerCase() ?? '')}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>
            {t('kols_affiliate_campaign_invested_title')}
          </StyledTitle>
        </MobileOnly>
        <Text>{affiliate.campaignTitle}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>
            {`${t('kols_affiliate_budget')} / ${t(
              'kols_affiliate_participation_budget',
            )}`}
          </StyledTitle>
        </MobileOnly>
        <Text>{`$${numberWithCommas(
          affiliate.participationAmount ?? affiliate.campaignUsedBudget ?? 0,
        )}`}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>{t('kols_affiliate_bonus_title')}</StyledTitle>
        </MobileOnly>
        <Text>{`$${numberWithCommas(
          affiliate.affiliateCommission ?? 0,
        )}`}</Text>
      </StyledInfo>
    </StyledAffiliateWrapper>
  );
};

export default AffiliateCard;
