import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';

export const StyledBadgesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  flex-wrap: wrap;
`;

export const StyledBadges = styled.div`
  display: flex;
  height: 1.5rem;
  align-items: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  background: ${COLORS.SOLITUDE_GRAY};
`;

export const FormFields = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  width: 100%;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const ImageUploadsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;

  &&& .ant-image {
    img {
      max-height: 15rem;
    }
  }
`;

export const StyledUploadFilePlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${pickThemeFontStyles('15', '15', 'SEMI_NORMAL')};
  color: ${COLORS.SOFT_GRAY};
  background: ${COLORS.WHITE};
  border: 1px dashed ${COLORS.SOFT_GRAY};
  border-radius: 0.25rem;
  width: 100%;
  height: 2.75rem;
  padding: 1rem;

  svg {
    margin-right: 0.75rem;
  }
`;
