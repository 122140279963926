// Globals
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

// Components
import { useWatch } from 'antd/lib/form/Form';
import { Skeleton } from 'antd';
import {
  StyledFields,
  StyledFormItem,
  StyledFrom,
  StyledInfoWrapper,
  StyledInputNumber,
} from './CampaignMarketingDeliveryStyles';
import { StyledDatePicker } from '@/ui';
import { StyledPageWrapper } from '../Styled';
import { IFormInstance } from '@/components';
import SocialChannelsInvestment from '../SocialChannelsInvestment/SocialChannelsInvestment';

// Models
import { ICampaign } from '@/models/campaigns.model';
import { IAverageSocialData } from '@/models/settings.model';

// Helpers
import { ICampaignFields } from '../../types';
import { useGetFormValidationRules } from '../../hooks';

interface ICampaignMarketingDelivery {
  campaign: ICampaign;
  handleChange?: (values: ICampaign, allValues: ICampaign) => void;
  formInstance?: IFormInstance;
  disabled: boolean;
  averageSocialData: IAverageSocialData | null;
}

const CampaignMarketingDelivery = ({
  campaign,
  handleChange,
  formInstance,
  averageSocialData,
  disabled,
}: ICampaignMarketingDelivery) => {
  const { t } = useTranslation(['campaigns']);
  const validationRules = useGetFormValidationRules();

  const startDate = useWatch(
    ICampaignFields.MARKETING_START_DATE,
    formInstance,
  );

  if (!campaign || !Object.keys(campaign ?? {}).length) {
    return <Skeleton />;
  }

  return (
    <StyledPageWrapper>
      <StyledFrom
        form={formInstance}
        onValuesChange={handleChange}
        initialValues={campaign}
        disabled={disabled}
      >
        <StyledInfoWrapper $isColumn>
          <StyledFields>
            <StyledFormItem
              name={ICampaignFields.MARKETING_START_DATE}
              rules={validationRules[ICampaignFields.MARKETING_START_DATE]}
              label={t('campaigns_details_marketing_start')}
            >
              <StyledDatePicker
                allowClear={false}
                disabledDate={(current) => {
                  return current && current < dayjs(dayjs()).add(2, 'weeks');
                }}
              />
            </StyledFormItem>
            <StyledFormItem
              name={ICampaignFields.MARKETING_END_DATE}
              rules={validationRules[ICampaignFields.MARKETING_END_DATE]}
              label={t('campaigns_details_marketing_end')}
            >
              <StyledDatePicker
                allowClear={false}
                disabledDate={(current) => {
                  const start = startDate?.add(1, 'weeks');
                  const end = startDate?.add(3, 'month');
                  return (
                    (start as Dayjs) >= current || (end as Dayjs) <= current
                  );
                }}
              />
            </StyledFormItem>
          </StyledFields>
          <StyledFields>
            <StyledFormItem
              name={ICampaignFields.BUDGET}
              label={t('campaigns_details_total_title')}
            >
              <StyledInputNumber disabled />
            </StyledFormItem>
          </StyledFields>
        </StyledInfoWrapper>

        <SocialChannelsInvestment
          disabled={disabled}
          averageSocialData={averageSocialData}
        />
      </StyledFrom>
    </StyledPageWrapper>
  );
};

export default CampaignMarketingDelivery;
