import { TAmountByCurrency } from '@/models/reports.model';
import { CryptoCurrenciesEnum } from '@/models/web3.model';

export const renderCurrenciesValues = (data: TAmountByCurrency) => {
  return Object.entries(data).map(([key, value]) =>
    Object.values(CryptoCurrenciesEnum).includes(
      key as CryptoCurrenciesEnum,
    ) ? (
      <div key={key}>
        {key} {value}
      </div>
    ) : null,
  );
};
