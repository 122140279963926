import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { showErrorToast } from '@/components';
import { useAppState } from '@/hooks';
import { ROUTES_PATHS } from '@/router';
import {
  selectAdminPermissions,
  selectPermissionIsLoading,
} from '@/modules/Permissions';
import checkRoutePermission from '../helpers/checkRoutePermission';
import { RoutePermissions } from '../constants';

const useRoutePermissions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const adminPermissions = useAppState(selectAdminPermissions);
  const loading = useAppState(selectPermissionIsLoading);
  const routePermissions =
    RoutePermissions[location.pathname as ROUTES_PATHS] ?? [];

  useEffect(() => {
    const isAllowed = checkRoutePermission(
      location.pathname as ROUTES_PATHS,
      adminPermissions ?? [],
    );

    if (
      !isAllowed &&
      routePermissions.length &&
      !loading &&
      adminPermissions !== null
    ) {
      showErrorToast({
        message: t('error_permissions_not_allowed_view'),
      });

      navigate(ROUTES_PATHS.HOME);
    }
  }, [location, loading]);
};

export default useRoutePermissions;
