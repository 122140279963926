import { FormInstance } from 'antd';

export const checkFieldValidation = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  formInstance: FormInstance,
) => {
  const fieldKey = Object.keys(values)[0];
  const touched = formInstance.isFieldTouched(fieldKey);

  try {
    await formInstance.validateFields([fieldKey]);
  } catch (e) {
    const err = formInstance.getFieldError(fieldKey);
    if (Object.values(values).length && touched && err.length) {
      return formInstance.validateFields([fieldKey]);
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFormWithErrors = (fields: any[]) =>
  fields.some((value) => value.errors.length !== 0);

export const isHTMLValid = (html: string) => {
  const doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerHTML === html;
};
