import { IProject } from '@/models/projects.model';

const getSocialChannelOptions = (project: IProject) => {
  return project?.allowedSocialChannels?.map((data) => ({
    value: data,
    label: data,
  }));
};

export default getSocialChannelOptions;
