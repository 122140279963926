// Globals
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { UploadIcon } from '@/icons';
import {
  IMAGE_MIME_TYPES,
  MAX_IMAGE_SIZE,
  PureImageUploader,
} from '../../index';
import {
  StyledUploadImagePlaceholder,
  ImageContainer,
  StyledImage,
  StyledCrossInCircleIcon,
} from './ImageUploadStyles';
import { Spinner } from '@/ui';

interface IImageUpload {
  isLoading?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  onChange?: (value: File | Blob | null) => void;
  value?: string;
  placeholder?: string;
  acceptance?: string;
  imageMaxSize?: number;
}

const ImageUpload = ({
  value,
  onChange,
  acceptance = IMAGE_MIME_TYPES,
  imageMaxSize = MAX_IMAGE_SIZE,
  isLoading: loading,
  disabled,
}: IImageUpload) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(['common']);
  const handleRemove = () => !disabled && onChange && onChange(null);

  const handleChange = (file: File | Blob) => {
    onChange && onChange(file);
    setIsLoading(true);
  };

  useEffect(() => {
    !loading && setIsLoading(false);
  }, [loading]);

  if (isLoading) {
    return <Spinner />;
  }

  return value ? (
    <ImageContainer>
      <StyledCrossInCircleIcon onClick={handleRemove} />
      <StyledImage src={value} alt="image" />
    </ImageContainer>
  ) : (
    <PureImageUploader
      imageMaxSize={imageMaxSize}
      acceptance={acceptance}
      onChange={handleChange}
      disabled={disabled}
    >
      <StyledUploadImagePlaceholder>
        <UploadIcon />
        {t('file_upload_placeholder', { ns: 'common' })}
      </StyledUploadImagePlaceholder>
    </PureImageUploader>
  );
};

export default ImageUpload;
