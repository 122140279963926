import styled from 'styled-components';
import { BlockLayout } from '@/ui';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';

export const StyledWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  align-items: flex-start;
  margin: 1rem 0;
`;

export const CardWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  gap: 0.5rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  gap: 1rem;
  flex-direction: column;
`;

export const StyledColumnCardTitle = styled.div`
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  margin-bottom: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('16', '25', 'BOLD')};
    margin-bottom: 1rem;
  }
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;
