import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Select } from '@/ui';
import { Form, FormItem } from '@/components';

export const StyledInfoWrapper = styled(BlockLayout)<{ $isColumn?: boolean }>`
  display: flex;
  width: 100%;
  padding: 1.5rem;
  flex-direction: ${({ $isColumn }) => ($isColumn ? 'column' : 'row')};
  align-items: flex-start;
  gap: 0.5rem;
`;

export const StyledFrom = styled(Form)`
  display: flex;
  width: 100%;
  gap: 1.5rem;
  flex-direction: column;
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0.5rem;
  width: 100%;
  flex-direction: column;

  &&& .ant-form-item-row {
    align-items: flex-start;
    flex-direction: column;

    .ant-form-item-control {
      width: 100%;
    }
  }

  label {
    ${pickThemeFontStyles('14', '18', 'NORMAL')};
    color: ${COLORS.CORN_FLOWER_BLUE}!important;
  }
`;

export const StyledTag = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  background: ${COLORS.LIGHTEST_GRAY};
`;

export const StyledFields = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledSelect = styled(Select)`
  svg {
    width: 2rem;
    height: 1rem;
    path {
      fill: ${COLORS.BLACK};
    }
  }
  &&&.ant-select {
    &&& .ant-select-selector {
      background: ${COLORS.LIGHTEST_GRAY};
      min-height: 1.5rem;
      border: 0;
      &&& .ant-select-selection-placeholder {
        ${pickThemeFontStyles('14', '14', 'SEMI_NORMAL')};
        margin: auto;
      }
      &&& .ant-select-selection-item {
        width: 1rem;
        ${pickThemeFontStyles('14', '16', 'SEMI_NORMAL')};
        margin: auto;
      }
    }
    &&& .ant-select-selection-overflow {
      padding: 0.3rem 4rem 0.3rem 0;
      .ant-select-selection-overflow-item {
        padding-bottom: 0.5rem;
        align-items: center;
        width: 100%;
      }
    }
  }
}
`;
