import { useCallback, useEffect, useState } from 'react';

interface DynamicList {
  list: string[];
  addItem: (item: string) => void;
  removeItem: (index: number) => void;
  editItem: (index: number, newItem: string) => void;
}

const useDynamicList = (initialList: string[] = []): DynamicList => {
  const [list, setList] = useState<string[]>(initialList);

  const addItem = useCallback((item: string): void => {
    setList((prevList) => [...prevList, item]);
  }, []);

  const removeItem = useCallback(
    (index: number): void => {
      setList((prevList) => {
        return prevList.filter((_, i) => i !== index, []);
      });
    },
    [list],
  );

  const editItem = useCallback((index: number, newItem: string): void => {
    setList((prevList) =>
      prevList.map((item, i) => (i === index ? newItem : item)),
    );
  }, []);

  useEffect(
    () => () => {
      setList([]);
    },
    [],
  );

  return { list, addItem, removeItem, editItem };
};

export default useDynamicList;
