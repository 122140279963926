// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Input } from '@/ui';
import { Form, FormItem } from '@/components';
import { StyledButton, StyledTitle } from '../Styled';

// Helpers
import { AuthFieldsNames, TSignInOtpCodeFormValues } from '../../helpers/types';
import { useGetFormValidationRules } from '../../hooks';
import { normalizeNumber } from '@/utils';

interface IOtpCodeForm {
  handleSubmit: (values: TSignInOtpCodeFormValues) => void;
  isLoading: boolean;
}

const OtpCodeForm = ({ handleSubmit, isLoading }: IOtpCodeForm) => {
  const { t } = useTranslation(['auth', 'common']);

  const validationRules = useGetFormValidationRules();

  const placeholder = t('common_placeholder_input', { ns: 'common' });

  return (
    <>
      <StyledTitle>{t('auth_log_in_title')}</StyledTitle>

      <Form onFinish={handleSubmit}>
        <FormItem
          name={AuthFieldsNames.OTP_CODE}
          rules={validationRules[AuthFieldsNames.OTP_CODE]}
          label={t('auth_otp_code_label')}
          normalize={normalizeNumber}
        >
          <Input placeholder={placeholder} />
        </FormItem>

        <StyledButton type="primary" htmlType="submit" loading={isLoading}>
          {t('submit', { ns: 'common' })}
        </StyledButton>
      </Form>
    </>
  );
};

export default OtpCodeForm;
