// Global
import React, { useEffect } from 'react';

// Modules
import {
  Dashboard,
  dashboardSliceSelector,
  getStatisticsGeneral,
  getStatisticsRequests,
  getStatisticsUsers,
} from '@/modules/Dashboard';

// Components
import { StyledPageWrapper } from '@/ui';

// Hooks
import { useAppDispatch, useAppState } from '@/hooks';

const DashboardContainer = () => {
  const dispatch = useAppDispatch();
  const {
    projectsListData,
    generalStatistic,
    requestsStatistic,
    totalInvestmentsInMarketing,
    totalInvestmentsInMoney,
    usersStatistics,
  } = useAppState(dashboardSliceSelector);

  useEffect(() => {
    dispatch(getStatisticsGeneral());
    dispatch(getStatisticsRequests());
    dispatch(getStatisticsUsers());
  }, [dispatch]);

  return (
    <StyledPageWrapper>
      <Dashboard
        projectsList={projectsListData?.items}
        generalStatistic={generalStatistic}
        requestsStatistic={requestsStatistic}
        totalInvestmentsInMarketing={totalInvestmentsInMarketing}
        totalInvestmentsInMoney={totalInvestmentsInMoney}
        usersStatistics={usersStatistics}
      />
    </StyledPageWrapper>
  );
};

export default DashboardContainer;
