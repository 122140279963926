import styled from 'styled-components';
import { Button, Checkbox, Title } from '@/ui';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';

export const StyledTitle = styled(Title)`
  margin: 0 0 2rem 0;
`;

export const StyledButton = styled(Button)`
  &&& {
    width: 6.25rem;
    height: 2.25rem;
    border: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    cursor: pointer;
    color: ${COLORS.LIQUORICE_BLACK};
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
    border-radius: 6px;
    background: var(--input-default-background, #fff);
    box-shadow: 0px 0px 1px 0px rgba(50, 50, 71, 0.2),
      0px 1px 2px 0px rgba(50, 50, 71, 0.08);
  }
`;

export const StyledBox = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  margin-bottom: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
  }
`;

export const StyledCheckBox = styled(Checkbox)`
  margin-bottom: 0.2rem;
`;
