import { IFundingContract, IFundingRound } from '@/models/fundingRound.model';
import {
  getFundingContractFieldsToCheck,
  getFundingRoundFieldsToCheck,
} from './fundingRoundsFieldsToCheck';
import { checkIsAllFilled } from './checkIsAllFilled';
import {
  LEGAL_REQUIREMENTS_STEP_FIELDS,
  ELEVATOR_PITCH_STEP_FIELDS,
  PRESENTATION_STEP_FIELDS,
  TOKENOMICS_STEP_FIELDS,
} from '../constants';
import {
  FundingContractFieldsNames,
  FundingSteps,
  IFundingRoundFields,
} from '../types';
import { getUnfilledFieldKey } from '@/utils';

export const getUnfilledFundingStep = (
  fundingRound: IFundingRound,
  fundingContract: IFundingContract,
) => {
  const fieldsToCheck = getFundingRoundFieldsToCheck(fundingRound);

  const contractFieldsToCheck =
    getFundingContractFieldsToCheck(fundingContract);

  if (
    checkIsAllFilled(fieldsToCheck) &&
    checkIsAllFilled(contractFieldsToCheck)
  )
    return;

  const unfilledField = getUnfilledFieldKey(fieldsToCheck);
  const unfilledContractField = getUnfilledFieldKey(contractFieldsToCheck);

  const isContractFieldsUnFilled = LEGAL_REQUIREMENTS_STEP_FIELDS.has(
    unfilledContractField as FundingContractFieldsNames,
  );

  switch (true) {
    case ELEVATOR_PITCH_STEP_FIELDS.has(unfilledField as IFundingRoundFields):
      return FundingSteps.ELEVATOR_PITCH;
    case PRESENTATION_STEP_FIELDS.has(unfilledField as IFundingRoundFields):
      return FundingSteps.PRESENTATION;
    case TOKENOMICS_STEP_FIELDS.has(unfilledField as IFundingRoundFields):
      return FundingSteps.TOKENOMICS;
    case isContractFieldsUnFilled:
      return FundingSteps.LEGAL_REQUIREMENTS;
    default:
      return;
  }
};
