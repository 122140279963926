import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { AdminActionsEnum } from '@/models/adminsList.model';
import { AdminsListAPI } from '@/api';
import { formatApiError } from '@/utils';
import { PERMISSION_SLICE_NAME } from './models';

export const getAdminPermissions = createAsyncThunk<
  AdminActionsEnum[],
  void,
  { serializedErrorType: IApiError }
>(
  `${PERMISSION_SLICE_NAME}/getAdminPermissions`,
  async () => {
    const response = await AdminsListAPI.getAdminPermissions();
    return response.data;
  },
  { serializeError: formatApiError },
);
