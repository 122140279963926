// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  BlockWrapper,
  DetailsBlock,
  StyledSubTitle,
  OverviewCell,
} from './CampaignInformationStyles';
import { StyledLabels } from '../Styled/Styled';

// Models
import { ICampaign } from '@/models/campaigns.model';

interface ICommunityInfo {
  data: ICampaign | null;
}

const CommunityInfo = ({ data }: ICommunityInfo) => {
  const { t } = useTranslation(['campaigns']);

  return (
    <BlockWrapper>
      <OverviewCell>
        <StyledSubTitle>{t('campaign_details_communities')}</StyledSubTitle>
        <DetailsBlock>
          <StyledLabels>{data?.projectMainCommunityTitle}</StyledLabels>
          <StyledLabels>{data?.projectSecondaryCommunityTitle}</StyledLabels>
        </DetailsBlock>
      </OverviewCell>
      <OverviewCell>
        <StyledSubTitle>{t('campaign_details_geographies')}</StyledSubTitle>
        <DetailsBlock>
          <StyledLabels $isColored>
            {data?.projectMainGeographyTitle}
          </StyledLabels>
          <StyledLabels $isColored>
            {data?.projectSecondaryGeographyTitle}
          </StyledLabels>
        </DetailsBlock>
      </OverviewCell>
    </BlockWrapper>
  );
};

export default CommunityInfo;
