import { IMAGE_FORMATS, VIDEO_FORMATS } from '@/modules/Uploaders';

export const getFileType = (value: File) => {
  const type = value?.type.substring(value?.type.indexOf('/') + 1);
  const isImage = IMAGE_FORMATS.includes(type);
  const isVideo = VIDEO_FORMATS.includes(type);

  switch (true) {
    case isImage:
      return 'image';
    case isVideo:
      return 'video';
    default:
      return 'file';
  }
};

export const getFileTypeByName = (value: string) => {
  return value.substring(value.lastIndexOf('.') + 1);
};
