import { ApiResponse, httpGet, httpPost } from './httpClient';
import {
  IAdmin,
  AdminsListEndpoints,
  IUpdateAdminsDetailsDTO,
  IAdminsDetailsDTO,
  AdminActionsEnum,
} from '@/models/adminsList.model';

class AdminsListAPI {
  static getAdminsList(): ApiResponse<IAdmin[]> {
    return httpGet<IAdmin[]>(AdminsListEndpoints.GET_ADMINS_LIST);
  }

  static getAdminPermissions(): ApiResponse<AdminActionsEnum[]> {
    return httpGet<AdminActionsEnum[]>(
      AdminsListEndpoints.GET_ADMIN_PERMISSIONS,
    );
  }

  static getAdminDetails(id: string): ApiResponse<IAdmin> {
    return httpGet<IAdmin>(
      `${AdminsListEndpoints.GET_ADMINS_LIST}/${encodeURI(id)}`,
    );
  }

  static updateAdminDetails(data: IUpdateAdminsDetailsDTO) {
    const { Admin, id } = data;
    return httpPost<IAdminsDetailsDTO, IAdmin>(
      `${AdminsListEndpoints.GET_ADMINS_LIST}/${encodeURI(id)}`,
      Admin,
    );
  }
}

export default AdminsListAPI;
