// Globals
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledTextArea,
  StyledWrapper,
  StyledActionButton,
  StyledTextAreaWrapper,
} from './ActionButtonsStyles';
import { Modal, ModalSize } from '@/components';
import { Title, Text } from '@/ui';

// Models
import { RequestActionsStatuses } from '@/models/requests.model';

// Helpers | Hooks
import { useAppDispatch } from '@/hooks';
import {
  getRequestDetails,
  patchRequestStatus,
} from '../../feature/actionCreators';
import { getUserId } from '@/utils';

interface IBlockModal {
  isOpened: boolean;
  handleClose: () => void;
  id?: string;
}

const BlockModal = ({ isOpened, handleClose, id }: IBlockModal) => {
  const { t } = useTranslation(['requestsList']);
  const [description, setDescription] = useState('');
  const [isValid, setIsValid] = useState(false);
  const dispatch = useAppDispatch();

  const onDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDescription(event.target.value);
    event.target.value !== '' && setIsValid(false);
  };

  const handleBlockedState = async () => {
    description === ''
      ? setIsValid(true)
      : await dispatch(
          patchRequestStatus({
            id: getUserId(id ?? ''),
            status: RequestActionsStatuses.BLOCKED,
            message: description,
          }),
        ).unwrap();
    dispatch(getRequestDetails(getUserId(id ?? '')));
  };

  return (
    <Modal visible={isOpened} onCancel={handleClose} size={ModalSize.LARGE}>
      <Title $type="h3">{t('requests_modal_blocking_title')}</Title>
      <StyledTextAreaWrapper>
        {isValid && <Text>{t('requests_modal_block_text')}</Text>}
        <StyledTextArea
          $valid={isValid}
          rows={4}
          value={description}
          onChange={onDescriptionChange}
        />
        <StyledWrapper>
          <StyledActionButton type="primary" onClick={handleBlockedState}>
            {t('requests_in_block_status')}
          </StyledActionButton>
        </StyledWrapper>
      </StyledTextAreaWrapper>
    </Modal>
  );
};

export default BlockModal;
