export enum AuthFieldsNames {
  EMAIL = 'email',
  PASSWORD = 'password',
  NEW_PASSWORD = 'new_password',
  CONFIRM_PASSWORD = 'confirm_password',
  OTP_CODE = 'otp_code',
}

export type TSignInFormValues = {
  [AuthFieldsNames.EMAIL]: string;
  [AuthFieldsNames.PASSWORD]: string;
};

export type TCreateNewPasswordFormValues = {
  [AuthFieldsNames.NEW_PASSWORD]: string;
};

export type TSignInOtpCodeFormValues = {
  [AuthFieldsNames.OTP_CODE]: string;
};
