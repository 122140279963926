// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledBadge,
  StyledHeaderBlock,
  StyledHeaderBlocksWrap,
  StyledHeaderLabel,
  DateWrap,
} from './CampaignResultsStyles';

// Models
import { ICampaign } from '@/models/campaigns.model';

// Helpers
import { getDateString } from '@/utils';
import { DATE_FORMATS } from '@/constants';
import { getActiveCampaignStatus } from '../../helpers';

interface IHeaderBlocks {
  campaign: ICampaign;
}

const HeaderBlocks = ({ campaign }: IHeaderBlocks) => {
  const { t } = useTranslation(['campaigns']);
  const marketingStartDate = getDateString(
    campaign?.marketingStartDate ?? '',
    DATE_FORMATS.DAY_MONTH_WITH_DOTS,
  );
  const marketingEndDate = getDateString(
    campaign?.marketingEndDate ?? '',
    DATE_FORMATS.DATE_WITH_DOTS,
  );

  return (
    <StyledHeaderBlocksWrap>
      <StyledHeaderBlock>
        <StyledHeaderLabel>
          {t('campaigns_marketing_period_title')}
        </StyledHeaderLabel>
        <DateWrap>
          <StyledHeaderLabel>
            {`${marketingStartDate} - ${marketingEndDate}`}
          </StyledHeaderLabel>
        </DateWrap>
      </StyledHeaderBlock>
      <StyledHeaderBlock>
        <StyledHeaderLabel>{t('campaign_contracts_status')}:</StyledHeaderLabel>
        <DateWrap>
          <StyledBadge>
            {getActiveCampaignStatus(
              campaign?.marketingStartDate,
              campaign?.marketingEndDate,
            )}
          </StyledBadge>
        </DateWrap>
      </StyledHeaderBlock>
    </StyledHeaderBlocksWrap>
  );
};

export default HeaderBlocks;
