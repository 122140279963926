// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledRequestWrapper,
  StyledDetails,
  StyledText,
} from './ProfileRequestCardStyles';
import { Link } from '@/ui';

// Models
import { IRequest, RequestsTypesKeys } from '@/models/requests.model';

// Helpers
import { ROUTES_PATHS } from '@/router';

interface IProfileRequestCard {
  request: IRequest;
}

const ProfileRequestCard = ({ request }: IProfileRequestCard) => {
  const { t } = useTranslation(['requestsList']);
  const actionType =
    request.actionType === RequestsTypesKeys.PROFILE_PRE_VERIFICATION
      ? RequestsTypesKeys.PROFILE_VERIFICATION
      : request.actionType;

  return (
    <StyledRequestWrapper>
      <StyledText>{t(`requests_${request?.actionType}_label`)}</StyledText>
      <Link
        to={`${ROUTES_PATHS.REQUESTS}/${actionType}/${encodeURIComponent(
          request.id ?? '',
        )}`}
      >
        <StyledDetails>{t('requests_details')}</StyledDetails>
      </Link>
    </StyledRequestWrapper>
  );
};

export default ProfileRequestCard;
