// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledSwitchButton, StyledTabs } from './TableTabsStyles';

interface ITabsConfig {
  label: string | JSX.Element;
  key: string;
}

interface ISwitchTabs {
  currentTab?: string | null;
  onTabChange: (key: string) => void;
  config: ITabsConfig[];
}

const TableTabs = ({ currentTab, onTabChange, config }: ISwitchTabs) => {
  const { t } = useTranslation(['common']);

  const tabsItems = useMemo(
    () =>
      config.map((config) => {
        const handleClick = () => onTabChange(config.key);
        return (
          <StyledSwitchButton
            key={config.key}
            $isActive={currentTab === config.key}
            onClick={handleClick}
          >
            {typeof config.label === 'string' ? t(config.label) : config.label}
          </StyledSwitchButton>
        );
      }),
    [config, onTabChange],
  );

  return <StyledTabs>{tabsItems}</StyledTabs>;
};

export default TableTabs;
