// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledWrapper,
  StyledTitles,
  StyledColumnCardTitle,
  StyledInfoTitles,
} from './NFTFundingBucketHistoryStyles';
import { StyledSpinnerWrap, Spinner } from '@/ui';
import NFTFundingBucketHistoryCard from './NFTFundingBucketHistoryCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { INftCreditsBucketHistoryResponse } from '@/models/settings.model';

// Hooks
import { useInfiniteScroll } from '@/hooks';

interface ISeedHunterCreditsHistory {
  data: INftCreditsBucketHistoryResponse;
  getNextItems: () => void;
  isLoading: boolean;
}

const NFTFundingBucketHistory = ({
  isLoading,
  data,
  getNextItems,
}: ISeedHunterCreditsHistory) => {
  const { t } = useTranslation(['settings']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedCreditsList = useMemo(() => {
    return data?.items?.map((data) => {
      return <NFTFundingBucketHistoryCard data={data} key={data.id} />;
    });
  }, [data]);

  useInfiniteScroll({
    callback: getNextItems,
    ref: loaderRef,
    isLoading,
    hasMore: data?.hasMore,
  });

  return (
    <StyledWrapper>
      <StyledColumnCardTitle>
        {t('settings_bucket_history_block_title')}
      </StyledColumnCardTitle>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('settings_bucket_history_title')}</StyledTitles>
          <StyledTitles>{t('settings_bucket_history_date')}</StyledTitles>
          <StyledTitles>
            {t('settings_bucket_history_used_amount')}
          </StyledTitles>
          <StyledTitles>{t('settings_bucket_history_used_by')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedCreditsList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </StyledWrapper>
  );
};

export default NFTFundingBucketHistory;
