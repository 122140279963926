import {
  DataLists,
  IBaseDataListItem,
  ICryptoCurrencyListItem,
  IGeographies,
} from '@/models/dataLists.model';
import { IApiError } from '@/models/apiError.model';

export const DATA_LISTS_SLICE_NAME = 'data_lists';

export interface DataListState<T> {
  list: T[] | null;
  isLoading: boolean;
  error: IApiError | null;
}

export interface DataListsState {
  [DataLists.INDUSTRY]: null;
  [DataLists.COMMUNITY]: null;
  [DataLists.CRYPTO_CURRENCIES]: DataListState<ICryptoCurrencyListItem>;
  [DataLists.AFFILIATE_COMMISSION]: null;
  [DataLists.COMPANY_TYPE]: null;
  [DataLists.FIRST_STAGE_DAYS]: number;
  [DataLists.FOLLOWERS_MULTIPLIER]: number;
  [DataLists.INVESTMENT_TARGET_BIAS_PERCENT]: null;
  [DataLists.SOCIAL_NETWORK_PRICE]: null;
  [DataLists.WALLET_NETWORK]: null;
  geographies: IGeographies[] | null;
  usersOptions: IBaseDataListItem[] | null;
  isLoading: boolean;
  error: IApiError | null;
}

const initialSubState = {
  list: null,
  isLoading: false,
  error: null,
};

export const initialState: DataListsState = {
  [DataLists.INDUSTRY]: null,
  [DataLists.COMMUNITY]: null,
  [DataLists.CRYPTO_CURRENCIES]: initialSubState,
  [DataLists.AFFILIATE_COMMISSION]: null,
  [DataLists.COMPANY_TYPE]: null,
  [DataLists.FIRST_STAGE_DAYS]: 0,
  [DataLists.FOLLOWERS_MULTIPLIER]: 0,
  [DataLists.INVESTMENT_TARGET_BIAS_PERCENT]: null,
  [DataLists.SOCIAL_NETWORK_PRICE]: null,
  [DataLists.WALLET_NETWORK]: null,
  geographies: null,
  usersOptions: null,
  isLoading: false,
  error: null,
};

// ============== DTO ==============

export interface IVerifyCryptoWalletDTO {
  userId: string;
  currency: string;
  address: string;
  network: string;
}

// ============== Responses ==============

export interface IVerifyCryptoWalletResponse {
  isValid: boolean;
}
