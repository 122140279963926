// Globals
import React, { useEffect } from 'react';

// Components
import { StyledBlock } from './UserForceBlockSwitchStyles';
import ConfirmBlockModal from './ConfirmBlockModal';
import { Switch } from '@/ui';

// Models
import { UserRoles } from '@/models/admin.model';

// Modules
import { forceBlockUser, forceUnblockUser } from '@/modules/SharedProfile';

// Helpers
import { useAppDispatch, useBoolean, useModal } from '@/hooks';

interface IUserForceBlockSwitch {
  id: string;
  role: UserRoles;
  disabled: boolean;
  initialIsBlocked: boolean;
  onSuccess?: (status: string) => void;
}

const UserForceBlockSwitch = ({
  id,
  role,
  disabled,
  initialIsBlocked,
  onSuccess,
}: IUserForceBlockSwitch) => {
  const { value: isBlocked, setValue: setIsBlocked } =
    useBoolean(initialIsBlocked);
  const { value: prevIsBlocked, setValue: setPrevIsBlocked } = useBoolean();

  const { isOpened, openModal, closeModal } = useModal();
  const dispatch = useAppDispatch();

  const blockUser = async (reason: string) => {
    const action = prevIsBlocked ? forceBlockUser : forceUnblockUser;
    try {
      const user = await dispatch(action({ id, reason, role })).unwrap();
      setIsBlocked(prevIsBlocked);
      onSuccess?.(user?.status);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setIsBlocked(initialIsBlocked);
  }, [initialIsBlocked]);

  return (
    <StyledBlock>
      <Switch
        disabled={disabled}
        checked={isBlocked}
        onChange={(v: boolean) => {
          setPrevIsBlocked(v);
          openModal();
        }}
      />
      <ConfirmBlockModal
        isOpened={isOpened}
        handleClose={closeModal}
        handleConfirm={async (reason) => {
          await blockUser(reason);
          closeModal();
        }}
      />
    </StyledBlock>
  );
};

export default UserForceBlockSwitch;
