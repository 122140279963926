import React, { ReactNode } from 'react';
import { CollapsibleType } from 'antd/es/collapse/CollapsePanel';
import { StyledPanel } from './CollapseStyles';

interface PanelProps {
  header: React.ReactNode;
  key: string;
  showArrow?: boolean;
  collapsible?: CollapsibleType;
  children: ReactNode;
}

export const Panel: React.FC<PanelProps> = ({ children, ...props }) => {
  return <StyledPanel {...props}>{children}</StyledPanel>;
};

export default Panel;
