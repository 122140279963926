// Global
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledAffiliateWrapper,
  StyledInfo,
  StyledTitle,
} from './AffiliateCardStyles';
import { Text } from '@/ui';

// Models
import {
  IFundingRoundAffiliate,
  LegalAgreementRoles,
} from '@/models/sharedProfile.model';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Utils
import { numberWithCommas } from '@/utils';

interface IAffiliateHistoryCard {
  affiliate: IFundingRoundAffiliate;
  role: LegalAgreementRoles;
}

const AffiliateHistoryCard = ({ affiliate, role }: IAffiliateHistoryCard) => {
  const { t } = useTranslation(['common', 'kolsList']);
  const budget =
    role === LegalAgreementRoles.PROJECTS
      ? affiliate?.fundingRoundRaisedAmount
      : affiliate?.participationAmount;

  return (
    <StyledAffiliateWrapper>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>{t('kols_affiliate_funding_round_title')}</StyledTitle>
        </MobileOnly>
        <Text>{affiliate.fundingRoundTitle}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>{t('kols_affiliate_invitee_alias_title')}</StyledTitle>
        </MobileOnly>
        <Text>{affiliate.invitedUserName}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>{t('kols_affiliate_registered_as_title')}</StyledTitle>
        </MobileOnly>
        <Text>{t(affiliate.invitedUserRole?.toLowerCase() ?? '')}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>
            {`${t('kols_affiliate_budget')} / ${t(
              'kols_affiliate_participation_budget',
            )}`}
          </StyledTitle>
        </MobileOnly>
        <Text>{`$${numberWithCommas(budget ?? 0)}`}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>{t('kols_affiliate_reward_title')}</StyledTitle>
        </MobileOnly>
        <Text>{`$${numberWithCommas(
          affiliate.affiliateCommission ?? 0,
        )}`}</Text>
      </StyledInfo>
    </StyledAffiliateWrapper>
  );
};

export default AffiliateHistoryCard;
