import styled from 'styled-components';
import { DEVICES, pickThemeFontSize } from '@/theme';

export const StyledStatusesWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 0rem;
  top: 0rem;
  svg {
    font-size: ${pickThemeFontSize('48')};
    transform: scale(1);
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: column;
    align-items: center;
    position: relative;
    right: 0;
    margin-top: 0;
    svg {
      transform: scale(0.8);
    }
  }
`;

export const StyledStatusWrapper = styled.div`
  display: flex;
  width: 3rem;
  justify-content: center;
  position: absolute;
  right: -0.4rem;
  top: 0rem;
  & .anticon {
    font-size: ${pickThemeFontSize('48')};
    transform: scale(1);
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    position: relative;
    width: 100%;
    right: 0;
    margin-top: 0.5rem;
    & .anticon {
      transform: scale(0.8);
    }
  }
`;

export const StyledFileIconWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  svg {
    transform: scale(1);
  }
`;
