import { AppStage } from '@/models/global.model';

export const environments = {
  DEV: 'development',
  STAGE: 'stage',
  PROD: 'production',
};

export const env = {
  environment: process.env.REACT_APP_ENVIRONMENT ?? environments.DEV,
  BASE_API_URL: process.env.REACT_APP_API_URL ?? 'http://localhost:3000',
  BASE_URL: process.env.REACT_APP_FRONT_END_URL ?? '',
  IMAGES_HOST: process.env.REACT_APP_IMAGES_HOST ?? '',
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
  FUNDING_ROUND_GENERAL_TERMS_AND_CONDITIONS:
    process.env.REACT_APP_FUNDING_ROUND_GENERAL_TERMS_AND_CONDITIONS ?? '',
  FUNDING_ROUND_SALE_AND_PURCHASE_AGREEMENT:
    process.env.REACT_APP_FUNDING_ROUND_SALE_AND_PURCHASE_AGREEMENT ?? '',
  APP_STAGE: process.env.REACT_APP_APP_STAGE ?? AppStage.SEED_HUNTER,
  TERMS_AND_CONDITIONS_KOL_URL:
    process.env.REACT_APP_TERMS_AND_CONDITIONS_KOL_URL ??
    'https://www.seedhunter.com/terms-of-service-kol/',
  TERMS_AND_CONDITIONS_AFFILIATE_URL:
    process.env.REACT_APP_TERMS_AND_CONDITIONS_AFFILIATE_URL ??
    'https://www.seedhunter.com/affiliate-program-terms-and-conditions/',
  TERMS_AND_CONDITIONS_PROJECT_URL:
    process.env.REACT_APP_TERMS_AND_CONDITIONS_PROJECT_URL ??
    'https://www.seedhunter.com/terms-of-service-project/',
  SERVICE_AGREEMENT_DOCUMENT_TEMPLATE:
    process.env.REACT_APP_SERVICE_AGREEMENT_DOCUMENT_TEMPLATE ??
    'https://seedhunter-prod.s3.eu-central-1.amazonaws.com/document_templates/Services_Agreement_Template_V3.1.pdf',
  MAINTENANCE_MODE: process.env.REACT_APP_MAINTENANCE_MODE ?? false,
};

export const isDev = env.environment === environments.DEV;
