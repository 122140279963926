import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { BlockLayout } from '@/ui';

export const ItemWrap = styled(BlockLayout)`
  width: 100%;
  padding: 0.625rem 1rem;
  color: ${COLORS.CORN_FLOWER_BLUE};
  ${pickThemeFontStyles('14', '24', 'SEMI_BOLD')};
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const StyledText = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
