import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMATS } from '../constants/dates';

export const getDatesRangeString = (startDate: Dayjs, endDate: Dayjs) => {
  const firstDay = dayjs(startDate)?.get('date');
  const firstDayString = `${firstDay < 10 ? '0' + firstDay : firstDay}`;
  const lastDayString = dayjs(endDate)?.format(DATE_FORMATS.DEFAULT);

  return `${firstDayString} - ${lastDayString}`;
};

export const getDateString = (date: string, format = DATE_FORMATS.DEFAULT) => {
  return date ? dayjs(date).format(format) : '';
};

export const isDateInRange = (date: Dayjs, start: Dayjs, end: Dayjs) => {
  const isSameAsStart = date.isSame(start, 'day');
  const isSameAsEnd = date.isSame(end, 'day');
  const isBetween = date.isBetween(start, end);
  return isSameAsStart || isSameAsEnd || isBetween;
};
