import styled from 'styled-components';
import { COLORS, DEVICES } from '@/theme';

export const StyledPageWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 3.688rem);
  padding: 1.5rem;
  background: ${COLORS.WHITE_SMOKE};

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 100vh;
  }
`;
