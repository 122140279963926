import omit from 'lodash.omit';
import { ApiResponse, httpGet } from './httpClient';
import {
  LogsEndpoints,
  ILogsListResponse,
  ILogsDTO,
  ISettingsLogsDTO,
} from '@/models/logs.model';

class LogsApi {
  static getLogs(data: ILogsDTO): ApiResponse<ILogsListResponse> {
    const { entity, id } = data;
    return httpGet<ILogsListResponse>(
      `${LogsEndpoints.GET_LOGS}/${entity}s/${encodeURI(id ?? '')}/logs`,
      {
        ...omit(data, 'entity'),
      },
    );
  }

  static getSettingsLogs(
    data: ISettingsLogsDTO,
  ): ApiResponse<ILogsListResponse> {
    return httpGet<ILogsListResponse>(
      `${LogsEndpoints.GET_LOGS}/settings/logs`,
      {
        ...data,
      },
    );
  }
}

export default LogsApi;
