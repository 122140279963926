// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { UploadIcon } from '@/icons';
import { Form, IFormInstance } from '@/components';
import { FormTextWrapper, StyledTitle, StyledDescription } from '../Styled';
import {
  StyledUploadFilePlaceholder,
  StyledFormWrapper,
  StyledInfo,
  StyledFormItem,
  ResourcesWrapper,
} from './CampaignResourcesStyles';
import Resource from './Resource';

// Modules
import {
  FileUpload,
  ImageUpload,
  MAX_RESOURCE_SIZE,
  RESOURCES_MIME_TYPES,
} from '@/modules/Uploaders';
import { useGetFormValidationRules } from '@/modules/Campaigns/hooks';

// Models
import { ICampaign } from '@/models/campaigns.model';

// Helpers
import { maxResourcesCount } from '../../constants/common';
import { ICampaignFields } from '../../types';

interface ICampaignResources {
  campaign: ICampaign;
  handleChange?: (values: ICampaign) => void;
  formInstance?: IFormInstance;
  disabled: boolean;
  fileLoading?: boolean;
}

const CampaignResources = ({
  campaign,
  handleChange,
  formInstance,
  disabled,
  fileLoading,
}: ICampaignResources) => {
  const { t } = useTranslation(['campaigns']);
  const validationRules = useGetFormValidationRules();

  const mappedResources = useMemo(() => {
    return campaign?.resources?.map((item) => (
      <Resource resource={item} disabled={disabled} key={item.link} />
    ));
  }, [campaign]);

  return (
    <Form
      form={formInstance}
      onValuesChange={handleChange}
      initialValues={campaign}
      disabled={disabled || campaign?.resources?.length >= maxResourcesCount}
    >
      <StyledFormWrapper>
        <StyledInfo>
          <FormTextWrapper>
            <StyledTitle>{t('campaigns_resources_title')}</StyledTitle>
            <StyledDescription>
              {t('campaigns_resources_description')}
            </StyledDescription>
          </FormTextWrapper>
          <StyledFormItem
            name={ICampaignFields.RESOURCES}
            label={t('campaigns_resources_amount', {
              amount: campaign?.resources?.length ?? 0,
            })}
          >
            <FileUpload
              fileMaxSize={MAX_RESOURCE_SIZE}
              acceptance={RESOURCES_MIME_TYPES}
              maxCount={maxResourcesCount}
              withPreview={false}
              showList={false}
            >
              <StyledUploadFilePlaceholder>
                <UploadIcon />
                {t('file_upload_placeholder')}
              </StyledUploadFilePlaceholder>
            </FileUpload>
          </StyledFormItem>
        </StyledInfo>
        <ResourcesWrapper>{mappedResources}</ResourcesWrapper>
      </StyledFormWrapper>

      <StyledFormWrapper>
        <FormTextWrapper>
          <StyledTitle>{t('campaigns_banner_label')}</StyledTitle>
          <StyledDescription>{t('campaigns_banner_note')}</StyledDescription>
        </FormTextWrapper>
        <StyledFormItem
          name={ICampaignFields.CAMPAIGN_BANNER}
          label={t('campaigns_banner_title')}
          rules={validationRules[ICampaignFields.CAMPAIGN_BANNER]}
        >
          <ImageUpload isLoading={fileLoading} disabled={disabled} />
        </StyledFormItem>
      </StyledFormWrapper>
    </Form>
  );
};

export default CampaignResources;
