import { useEffect, useState } from 'react';

export const useHorizontalScrollDetector = <T extends HTMLElement>(
  ref: React.RefObject<T>,
): boolean => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [initialScrollLeft, setInitialScrollLeft] = useState<number>(0);

  useEffect(() => {
    if (ref.current) {
      const handleScroll = () => {
        const scrolledLeft = ref?.current?.scrollLeft;
        setIsScrolled(scrolledLeft !== initialScrollLeft);
      };
      setInitialScrollLeft(ref.current.scrollLeft);
      ref.current.addEventListener('scroll', handleScroll);
      return () => {
        ref?.current?.removeEventListener('scroll', handleScroll);
      };
    }
  }, [ref, initialScrollLeft]);

  return isScrolled;
};

export default useHorizontalScrollDetector;
