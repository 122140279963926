/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-ts-comment  */
import { useEffect } from 'react';

// Store
import { selectAuthIsLoading } from '../feature/selectors';
import { logOut } from '../feature/actionCreators';

// Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import { env } from '../../../config/env';

const useMaintenanceMode = () => {
  const isLoading = useAppState(selectAuthIsLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (env.MAINTENANCE_MODE) {
      dispatch(logOut());
    }
  }, [isLoading]);
};

export default useMaintenanceMode;
