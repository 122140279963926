import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout } from '@/ui';

export const StyledList = styled.div`
  display: grid;
  gap: 0.3rem;
  grid-template-rows: 1fr;
  width: 100%;
  grid-template-columns: 100%;

  @media screen and ${DEVICES.LAPTOP_S} {
    grid-template-columns: 50% 50%;
  }
`;

export const StyledBlockLayout = styled(BlockLayout)`
  flex-direction: column;
  padding: 1rem;
`;

export const StyledHeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625rem;
`;

export const StyledBlockTitle = styled.div`
  width: 100%;
  color: ${COLORS.LIQUORICE_BLACK};
  ${pickThemeFontStyles('16', '16', 'SEMI_BOLD')};
`;

export const StyledSearchWrap = styled.div`
  width: 100%;
  display: flex;
  max-width: 18rem;
  justify-content: space-between;
`;

export const StyledButton = styled.button`
  width: 6.25rem;
  height: 2.25rem;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${COLORS.LIQUORICE_BLACK};
  ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
  border-radius: 6px;
  background: var(--input-default-background, #fff);
  box-shadow: 0px 0px 1px 0px rgba(50, 50, 71, 0.2),
    0px 1px 2px 0px rgba(50, 50, 71, 0.08);
`;
