// Globals
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';

// Components
import { Settings } from '../../components';
import { showErrorToast, showSuccessToast } from '@/components';

// Modules
import { getSettingsLogs } from '@/modules/Logs';

// Hooks | Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import { getSettings, postSettings } from '../../feature/actionCreators';
import { settingsSliceSelector } from '../../feature/selectors';
import { checkFieldValidation } from '@/utils';
import { SettingsDTO } from '../../dtos';
import { settingsDataToFormValues, TSettingsValues } from '../../helpers';

interface IGeneralSettingsContainer {
  toggleIsEditState: () => void;
  isEditActive: boolean;
}

const GeneralSettingsContainer = ({
  toggleIsEditState,
  isEditActive,
}: IGeneralSettingsContainer) => {
  const { t } = useTranslation(['settings']);
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const { settingsData } = useAppState(settingsSliceSelector);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  const handleSubmit = useCallback(
    async (values: TSettingsValues) => {
      await checkFieldValidation(values, form);
      const settingsDTO = new SettingsDTO(values);

      try {
        await dispatch(postSettings(settingsDTO)).unwrap();
        toggleIsEditState();
        dispatch(getSettingsLogs({}));
        showSuccessToast({ message: t('settings_success_toast') });
      } catch (e: unknown) {
        showErrorToast({ message: t('settings_error_toast') });
      }
    },
    [form, dispatch],
  );

  return (
    <Settings
      handleSubmit={handleSubmit}
      formInstance={form}
      initialValues={settingsDataToFormValues(settingsData)}
      isEditActive={isEditActive}
    />
  );
};

export default GeneralSettingsContainer;
