import { SocialChannels } from '@/models/socialChannel.model';
import { networksDomains } from '../constants/domains';

const getDomainRegExp = /:\/\/(.[^/]+)/;
const checkStartsWith = /^(http|https):/;

const getDomain = (url: string) => {
  if (checkStartsWith.test(url)) {
    return url?.split(getDomainRegExp)[1];
  } else return url?.split('/')[0];
};

export const domainValidation = (value: string, url: string) => {
  switch (value) {
    case SocialChannels.FACEBOOK:
      return networksDomains[SocialChannels.FACEBOOK].some(
        (domain: string) => domain === getDomain(url),
      );
    case SocialChannels.TWITTER:
      return networksDomains[SocialChannels.TWITTER].some(
        (domain: string) => domain === getDomain(url),
      );
    case SocialChannels.INSTAGRAM:
      return networksDomains[SocialChannels.INSTAGRAM].some(
        (domain: string) => domain === getDomain(url),
      );
    case SocialChannels.YOUTUBE:
      return networksDomains[SocialChannels.YOUTUBE].some(
        (domain: string) => domain === getDomain(url),
      );
    case SocialChannels.LINKEDIN:
      return networksDomains[SocialChannels.LINKEDIN].some(
        (domain: string) => domain === getDomain(url),
      );
    default:
      return true;
  }
};

export const isContainsDomain = (url: string, network: string) => {
  return networksDomains[network as SocialChannels]?.some(
    (domain: string) => domain === getDomain(url),
  );
};
