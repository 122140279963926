// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  DeliveryWrapper,
  StyledInfoTitles,
  StyledTitle,
  StyledTitles,
  StyledHeader,
} from './ProofOfDeliveryStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import MarketingDeliveryCard from './MarketingDeliveryCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { IMarketingDelivery } from '@/models/marketingDelivery.model';

// Helpers
import { useInfiniteScroll } from '@/hooks';

interface IProofOfDelivery {
  marketingDelivery: {
    items: IMarketingDelivery[] | null;
    hasMore: boolean;
  };
  data?: IMarketingDelivery[];
  getNextData: () => void;
  isLoading: boolean;
  projectId?: string;
}

const ProofOfDelivery = ({
  marketingDelivery,
  getNextData,
  isLoading,
  projectId,
}: IProofOfDelivery) => {
  const { t } = useTranslation(['projectsList']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedDeliveryList = useMemo(() => {
    return marketingDelivery?.items?.map((data) => {
      return (
        <MarketingDeliveryCard
          data={data}
          key={data.campaignTitle}
          projectId={projectId}
        />
      );
    });
  }, [marketingDelivery]);

  useInfiniteScroll({
    callback: getNextData,
    ref: loaderRef,
    isLoading,
    hasMore: marketingDelivery?.hasMore,
  });

  if (!marketingDelivery) return null;

  return (
    <DeliveryWrapper>
      <StyledHeader>
        <StyledTitle>{t('proof_of_delivery_marketing_delivery')}</StyledTitle>
      </StyledHeader>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('proof_of_delivery_campaign_title')}</StyledTitles>
          <StyledTitles>{t('proof_of_delivery_planned_posts')}</StyledTitles>
          <StyledTitles>{t('proof_of_delivery_delivered_posts')}</StyledTitles>
          <StyledTitles>{t('proof_of_delivery_campaign_budget')}</StyledTitles>
          <StyledTitles />
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedDeliveryList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </DeliveryWrapper>
  );
};

export default ProofOfDelivery;
