// Global
import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

// Components
import {
  StyledSocialChannelWrapper,
  StyledInfo,
  StyledNumbers,
  StyledTitle,
  StyledChannelWrap,
  StyledLink,
  StyledLastUpdated,
} from './InfluencerSocialChannelStyles';
import { Text, Tooltip } from '@/ui';

// Helpers | Utils
import { socialChannelsConfig } from '../../constants';
import { getDateString, numberWithCommas } from '@/utils';

// Modules
import { MobileOnly } from '@/modules/Adaptive';
import { ISocialChannelKOL } from '@/models/kolsList.model';
import { COLORS } from '@/theme';

interface ISocialChannels {
  channel: ISocialChannelKOL;
  warning?: string;
}

const InfluencerSocialChannel = ({ channel, warning }: ISocialChannels) => {
  const { t } = useTranslation(['kolsList']);

  return (
    <Tooltip
      getPopupContainer={(triggerNode) => triggerNode}
      title={warning}
      placement={'top'}
      color={COLORS.PINK_SALMON}
    >
      <StyledSocialChannelWrapper $warning={!!warning}>
        <StyledInfo>
          <MobileOnly>
            <StyledTitle>
              {t('kols_influencer_data_social_channel_title')}
            </StyledTitle>
          </MobileOnly>
          <StyledChannelWrap>
            <StyledLink to={channel.link} target="_blank">
              <>
                {socialChannelsConfig[channel?.name]?.icon}
                <Text>{channel.name}</Text>
              </>
            </StyledLink>
          </StyledChannelWrap>
        </StyledInfo>
        <StyledInfo>
          <MobileOnly>
            <StyledTitle>{t('kols_influencer_data_last_updated')}</StyledTitle>
          </MobileOnly>
          <StyledLastUpdated
            $old={dayjs(channel?.lastUpdated).isBefore(
              dayjs().subtract(1, 'month'),
            )}
          >
            {getDateString(channel?.lastUpdated ?? '')}
          </StyledLastUpdated>
        </StyledInfo>
        <StyledInfo>
          <MobileOnly>
            <StyledTitle>
              {t('kols_influencer_data_followers_title')}
            </StyledTitle>
          </MobileOnly>
          <StyledNumbers>
            {`${numberWithCommas(channel?.followers ?? 0)}`}
          </StyledNumbers>
        </StyledInfo>
        <StyledInfo>
          <MobileOnly>
            <StyledTitle>
              {t('kols_influencer_data_engagement_title')}
            </StyledTitle>
          </MobileOnly>
          <StyledNumbers>
            {`${numberWithCommas(channel.engagementRate ?? 0)}`}
          </StyledNumbers>
        </StyledInfo>
        <StyledInfo>
          <MobileOnly>
            <StyledTitle>{t('kols_influencer_data_rich_title')}</StyledTitle>
          </MobileOnly>
          <StyledNumbers>
            {`${numberWithCommas(channel.richRate ?? 0)}`}
          </StyledNumbers>
        </StyledInfo>
        <StyledInfo>
          <MobileOnly>
            <StyledTitle>
              {t('kols_influencer_data_grade_score_title')}
            </StyledTitle>
          </MobileOnly>
          <Text>{channel?.gradeScore ?? ''}</Text>
        </StyledInfo>
        <StyledInfo>
          <MobileOnly>
            <StyledTitle>
              {t('kols_influencer_data_influencer_score_title')}
            </StyledTitle>
          </MobileOnly>
          <StyledNumbers>
            {`${numberWithCommas(channel.influenceScore ?? 0)}`}
          </StyledNumbers>
        </StyledInfo>
        <StyledInfo>
          <MobileOnly>
            <StyledTitle>
              {t('kols_influencer_data_followers_quality_title')}
            </StyledTitle>
          </MobileOnly>
          <StyledNumbers>
            {`${numberWithCommas(channel.followerQualityScore ?? 0)}`}
          </StyledNumbers>
        </StyledInfo>
      </StyledSocialChannelWrapper>
    </Tooltip>
  );
};

export default InfluencerSocialChannel;
