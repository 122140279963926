// Global
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledAffiliateWrapper,
  StyledInfo,
  StyledTitle,
} from './AffiliateCardStyles';
import { StatusIconBlock } from '@/components';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Utils
import { numberWithCommas, getDateString, capitalizeString } from '@/utils';
import { IPartnerAffiliateData } from '@/models/partnersList.model';
import { Text } from '@/ui';

interface IAffiliate {
  affiliate: IPartnerAffiliateData;
}

const AffiliateCard = ({ affiliate }: IAffiliate) => {
  const { t } = useTranslation(['partnersList']);

  return (
    <StyledAffiliateWrapper>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>{t('hunters_affiliate_invited_user_title')}</StyledTitle>
        </MobileOnly>
        <Text>{`${affiliate.firstName} ${affiliate.lastName}`}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>
            {t('hunters_affiliate_investment_date_title')}
          </StyledTitle>
        </MobileOnly>
        <Text>{getDateString(affiliate.modified)}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>
            {t('hunters_affiliate_invited_user_role_title')}
          </StyledTitle>
        </MobileOnly>
        <Text>{capitalizeString(affiliate.userRole)}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>
            {t('hunters_affiliate_project_invested_title')}
          </StyledTitle>
        </MobileOnly>
        <Text>{affiliate.title}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>
            {t('hunters_affiliate_money_investment_title')}
          </StyledTitle>
        </MobileOnly>
        <Text>{`$${numberWithCommas(affiliate.investedNetAmount)}`}</Text>
      </StyledInfo>
      <StyledInfo>
        <MobileOnly>
          <StyledTitle>{t('hunters_affiliate_bonus_title')}</StyledTitle>
        </MobileOnly>
        <Text>{`$${numberWithCommas(
          affiliate.affiliateCommissionAmount,
        )}`}</Text>
      </StyledInfo>
      <StyledInfo>
        <StatusIconBlock status={affiliate.investmentStatus} />
      </StyledInfo>
    </StyledAffiliateWrapper>
  );
};

export default AffiliateCard;
