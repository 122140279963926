import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {
  REQUESTS_LIST_SLICE_NAME,
  RequestsListState,
  initialState,
} from './models';
import {
  getOwnRequests,
  getEntityRequest,
  getRequestCounter,
  getRequestsList,
} from './actionCreators';
import { IOwnRequest, IRequest } from '@/models/requests.model';
import { showApiErrors } from '@/utils';

export const slice = createSlice({
  name: REQUESTS_LIST_SLICE_NAME,
  initialState,
  reducers: {
    resetRequestListState() {
      return { ...initialState };
    },
    setRequestsFilters(state: RequestsListState, action) {
      state.requestFilters = { ...state.requestFilters, ...action.payload };
      state.requestsListData = initialState.requestsListData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getRequestsList.fulfilled,
        (state: RequestsListState, action) => {
          state.requestsListData = {
            items: (action.meta.arg.startId
              ? [
                  ...(state.requestsListData?.items ?? []),
                  ...action.payload.items,
                ]
              : [...action.payload.items]) as IRequest[],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addCase(
        getRequestCounter.fulfilled,
        (state: RequestsListState, action) => {
          state.currentRequestCount = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isFulfilled(getOwnRequests, getEntityRequest),
        (state: RequestsListState, action) => {
          state.ownRequestsListData = {
            items: (action.meta.arg.startId
              ? [
                  ...(state.requestsListData?.items ?? []),
                  ...action.payload.items,
                ]
              : [...action.payload.items]) as IOwnRequest[],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addMatcher(
        isPending(getRequestsList, getOwnRequests, getRequestCounter),
        (state: RequestsListState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(getRequestsList, getOwnRequests, getRequestCounter),
        (state: RequestsListState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export const { resetRequestListState, setRequestsFilters } = slice.actions;
export default slice.reducer;
