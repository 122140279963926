// Globals
import React, { useState, ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { httpGet } from 'src/api/httpClient';

// Components
import {
  StyledWrapper,
  StyledButton,
  StyledButtonWrapper,
  StyledRow,
  StyledText,
  StyledInputWrap,
} from './SocialChannelDataInfoStyles';
import { showErrorToast, showSuccessToast } from '@/components';
import { StyledInput } from '@/ui';
import SocialChannelInfo from './SocialChannelInfo';

// Models
import {
  ISocialChannelsDTO,
  SocialChannelNetwork,
} from '@/models/socialChannel.model';

// Helpers
import { SocialChannelsDTO } from '../../dtos';

const SocialChannelDataInfo = () => {
  const { t } = useTranslation(['kolsList']);
  const [url, setUrl] = useState('');
  const [socialData, setSocialData] = useState<ISocialChannelsDTO>();

  const handleChange = (url: ChangeEvent<HTMLInputElement>) => {
    setUrl(url.target.value);
  };

  const handleSubmit = useCallback(async () => {
    setSocialData(undefined);
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data } = await httpGet<any>(
        `${SocialChannelNetwork.GET_SOCIAL_CHANNELS_NETWORK}`,
        { url },
      );
      const socialChannelDTO = new SocialChannelsDTO(data);

      setSocialData(socialChannelDTO);
      showSuccessToast({
        message: t('kol_social_data_success_toast'),
      });
    } catch (e) {
      showErrorToast({
        message: t('kol_social_data_error_toast'),
      });
      console.warn(e);
    }
  }, [url]);

  return (
    <StyledWrapper>
      <StyledRow>
        <StyledText>{t('kol_social_data_provide')}</StyledText>
        <StyledInputWrap>
          <StyledInput onChange={handleChange} value={url} />
        </StyledInputWrap>
      </StyledRow>
      {socialData && <SocialChannelInfo data={socialData} />}
      <StyledButtonWrapper>
        <StyledButton onClick={handleSubmit} type="primary">
          {t('kol_social_get_data')}
        </StyledButton>
      </StyledButtonWrapper>
    </StyledWrapper>
  );
};

export default SocialChannelDataInfo;
