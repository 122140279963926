// Globals
import React from 'react';

// Components
import { StyledWrapper, StyledCount } from './CountBadgeStyles';

interface IRequestsCount {
  count: number;
}

const CountBadge = ({ count }: IRequestsCount) => {
  if (count === 0) {
    return null;
  }
  return (
    <StyledWrapper>
      <StyledCount>{count}</StyledCount>
    </StyledWrapper>
  );
};

export default CountBadge;
