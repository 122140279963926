import { environments, env } from 'src/config/env';

export const getCurrentDomain = () => {
  switch (true) {
    case env.environment === environments.DEV:
      return 'https://dev.app.seedhunter.com/';
    case env.environment === environments.STAGE:
      return 'https://st.app.seedhunter.com/';
    case env.environment === environments.PROD:
      return 'https://app.seedhunter.com/';
    default:
      return '';
  }
};
