// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Title } from '@/ui';
import {
  StyledFormWrapper,
  StyledBlockWrapper,
  TitleBlock,
  StyledText,
  FieldsWrapper,
  StyledTitle,
  DetailsBlock,
  StyledTextArea,
  StyledA,
  StyledBadge,
  FormFieldsWrapper,
  StyledForm,
  FormFields,
} from './MarketingContractInformationStyles';
import { FormItem, IFormInstance } from '@/components';

// Helpers | Hooks
import { env } from 'src/config/env';
import { ContractInfoFieldsNames } from '../../types';
import { IContractInfoResponse } from '@/models/projects.model';
import ContractInformationItems from '../ContractInformationItems/ContractInformationItems';
import { useGetContractFormValidationRules } from '../../hooks';
import { TextLength } from '@/constants';

interface IMarketingContractInformation {
  contractInfo: IContractInfoResponse | null;
  handleSubmit: (
    values: IContractInfoResponse,
    allValues: IContractInfoResponse,
  ) => void;
  formInstance: IFormInstance;
  disabled: boolean;
}

const MarketingContractInformation = ({
  formInstance,
  contractInfo,
  handleSubmit,
  disabled,
}: IMarketingContractInformation) => {
  const { t } = useTranslation(['projectsList']);
  const validationRules = useGetContractFormValidationRules();

  if (!contractInfo) return null;

  return (
    <StyledFormWrapper>
      <StyledBlockWrapper>
        <DetailsBlock>
          <StyledTitle>
            {t('contract_information_service_agreement_details_title')}
          </StyledTitle>
          <StyledText>
            {t('contract_information_service_agreement_description')}
          </StyledText>
        </DetailsBlock>
        <DetailsBlock>
          <StyledA
            href={env.SERVICE_AGREEMENT_DOCUMENT_TEMPLATE}
            target="_blank"
          >
            <StyledBadge>
              {t('contract_information_view_service_agreement')}
            </StyledBadge>
          </StyledA>
        </DetailsBlock>
      </StyledBlockWrapper>
      <FormFieldsWrapper>
        <StyledForm
          form={formInstance}
          initialValues={contractInfo}
          onValuesChange={handleSubmit}
          disabled={disabled}
        >
          <FieldsWrapper>
            <TitleBlock>
              <Title $type="h2">
                {t('contract_information_responsibilities_title')}
              </Title>
            </TitleBlock>
            <FormFields>
              <FormItem
                name={ContractInfoFieldsNames.DESCRIPTION_PROJECT}
                rules={
                  validationRules[ContractInfoFieldsNames.DESCRIPTION_PROJECT]
                }
                label={t('contract_information_project_description_label')}
              >
                <StyledTextArea
                  rows={5}
                  placeholder={t(
                    'contract_information_project_description_label',
                  )}
                  showCount
                  maxLength={TextLength.semiLarge}
                />
              </FormItem>
              <FormItem
                name={ContractInfoFieldsNames.DESCRIPTION_KOL}
                rules={validationRules[ContractInfoFieldsNames.DESCRIPTION_KOL]}
                label={t('contract_information_kol_description_label')}
              >
                <StyledTextArea
                  rows={5}
                  placeholder={t('contract_information_kol_description_label')}
                  showCount
                  maxLength={TextLength.semiLarge}
                />
              </FormItem>
            </FormFields>
          </FieldsWrapper>
          <FieldsWrapper>
            <TitleBlock>
              <Title $type="h2">
                {t('contract_information_clauses_title')}
              </Title>
            </TitleBlock>

            <ContractInformationItems disabled={disabled} />
          </FieldsWrapper>
        </StyledForm>
      </FormFieldsWrapper>
    </StyledFormWrapper>
  );
};

export default MarketingContractInformation;
