import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  SliderWrapper,
  StyledSlider,
  StyledSLiderTitle,
} from '../Styled/Styled';
import { OverviewCell, StyledLabel } from './CampaignInformationStyles';

interface ICampaignBudget {
  budgedPercentage: number;
}

const CampaignBudget = ({ budgedPercentage }: ICampaignBudget) => {
  const { t } = useTranslation(['campaigns']);

  return (
    <OverviewCell>
      <StyledSLiderTitle>
        <StyledLabel $bold>{t('campaign_details_budget_booked')}</StyledLabel>
        <StyledLabel>{budgedPercentage + '%'}</StyledLabel>
      </StyledSLiderTitle>
      <SliderWrapper>
        <StyledSlider
          budgedPercentage={budgedPercentage}
          value={budgedPercentage}
          max={100}
        />
      </SliderWrapper>
    </OverviewCell>
  );
};

export default CampaignBudget;
