// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  ListWrapper,
  StyledTitles,
  StyledInfoTitles,
  StyledTotalRaisedWrap,
  StyledTotalRaised,
  StyledRaisedText,
  StyledTitle,
} from './ParticipationListStyles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import ParticipationCard from './ParticipationCard';

// Models
import {
  IFundingRound,
  IFundingRoundParticipation,
} from '@/models/fundingRound.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Helpers
import { getPercent, numberWithCommas } from '@/utils';
import { fundingTargetCalculation } from '../../helpers';

interface IParticipationList {
  isLoading: boolean;
  fundingRound: IFundingRound;
  participations: IFundingRoundParticipation[] | null;
  handleConfirmPayment: (participation: IFundingRoundParticipation) => void;
}

const ParticipationList = ({
  isLoading,
  fundingRound,
  participations,
  handleConfirmPayment,
}: IParticipationList) => {
  const { t } = useTranslation(['fundingRound']);
  const loaderRef = useRef<HTMLDivElement>(null);
  const fundingTarget = fundingTargetCalculation(
    fundingRound ?? ({} as IFundingRound),
  );
  const fundingReached = getPercent(
    fundingRound?.raisedAmount ?? 0,
    fundingTarget,
  );

  const mappedParticipationList = useMemo(() => {
    return participations?.map((participation) => {
      return (
        <ParticipationCard
          participation={participation}
          key={participation.sk}
          handleConfirmPayment={handleConfirmPayment}
        />
      );
    });
  }, [participations, handleConfirmPayment]);

  return (
    <ListWrapper>
      <StyledTotalRaisedWrap>
        <StyledTotalRaised>
          <StyledRaisedText>
            {t('funding_participation_total_funding_title')}
          </StyledRaisedText>
          <StyledTitle>
            $ {fundingRound?.raisedAmount ?? 0} (
            {numberWithCommas(fundingReached)}%)
          </StyledTitle>
        </StyledTotalRaised>
      </StyledTotalRaisedWrap>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('funding_participation_name_title')}</StyledTitles>
          <StyledTitles>{t('funding_participation_role_title')}</StyledTitles>
          <StyledTitles>{t('funding_participation_date_title')}</StyledTitles>
          <StyledTitles>{t('funding_participation_wallet_title')}</StyledTitles>
          <StyledTitles>
            {t('funding_participation_participation_title')}
          </StyledTitles>
          <StyledTitles>
            {t('funding_participation_documents_title')}
          </StyledTitles>
          <StyledTitles>{t('funding_participation_status_title')}</StyledTitles>
          <StyledTitles>{t('funding_participation_action_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedParticipationList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </ListWrapper>
  );
};

export default ParticipationList;
