export enum SettingsTabKeys {
  SETTINGS = 'settings',
  AVERAGE_SOCIAL_DATA = 'average_social_data',
  AUTOMATIC_APPROVAL = 'automatic_approval',
  BUCKET = 'bucket',
  TOP_BARS = 'top_bars',
}

export const settingsTabsConfig = [
  {
    label: 'tabs_settings',
    key: SettingsTabKeys.SETTINGS,
  },
  {
    label: 'tabs_average_social_data',
    key: SettingsTabKeys.AVERAGE_SOCIAL_DATA,
  },
  {
    label: 'tabs_automatic_approval',
    key: SettingsTabKeys.AUTOMATIC_APPROVAL,
  },
  {
    label: 'tabs_bucket',
    key: SettingsTabKeys.BUCKET,
  },
  {
    label: 'tabs_top_bars',
    key: SettingsTabKeys.TOP_BARS,
  },
];
