// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Spinner, StyledSpinnerWrap, Link } from '@/ui';
import {
  StyledTitles,
  PayoutListWrapper,
  StyledButtonWrap,
  StyledLinkButton,
} from './FundingRoundPayoutDetailsStyles';
import { StyledDividerBlock, StyledInfoTitles } from '../Styled';
import FundingRoundDetailsCard from '../FundingRoundDetailsCard/FundingRoundDetailsCard';

// Modules
import { IFundingPayoutItem, RevenueTransferItem } from '@/models/payout.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';
import { IReportsTabsNames } from '@/modules/Reports';

// Helpers
import { ROUTES_PATHS } from '@/router';
import RevenueTransferDetailsCard from '../FundingRoundDetailsCard/RevenueTransferDetailsCard';

interface IFundingRoundPayoutDetails {
  fundingPayoutItems?: IFundingPayoutItem[] | null;
  fundingRevenueTransfers?: RevenueTransferItem[] | null;
  isLoading: boolean;
}

const FundingRoundPayoutDetails = ({
  isLoading,
  fundingPayoutItems,
  fundingRevenueTransfers,
}: IFundingRoundPayoutDetails) => {
  const { t } = useTranslation(['payout']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedPayoutItems = useMemo(() => {
    return fundingPayoutItems?.map((payoutDetails) => (
      <FundingRoundDetailsCard data={payoutDetails} key={payoutDetails?.id} />
    ));
  }, [fundingPayoutItems]);

  const mappedRevenueTransferItems = useMemo(() => {
    return fundingRevenueTransfers?.map((revenueTransfer) => (
      <RevenueTransferDetailsCard
        data={revenueTransfer}
        key={`${revenueTransfer.transferType}_${revenueTransfer.network}_${revenueTransfer.currency}`}
      />
    ));
  }, [fundingPayoutItems]);

  return (
    <PayoutListWrapper>
      <StyledButtonWrap>
        <Link
          to={`${ROUTES_PATHS.REPORTS}/${
            IReportsTabsNames.FUNDING_ROUND
          }/${encodeURIComponent(
            fundingPayoutItems?.[0]?.fundingRoundId ?? '',
          )}`}
          target="_blank"
        >
          <StyledLinkButton type="primary">
            {t('payout_funding_go_to_report')}
          </StyledLinkButton>
        </Link>
      </StyledButtonWrap>

      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('revenue_transfer_type')}</StyledTitles>
          <StyledTitles>{t('revenue_transfer_amount_title')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_network')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_currency')}</StyledTitles>
          <StyledTitles>{t('revenue_transfer_status_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedRevenueTransferItems}
      <StyledDividerBlock />

      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('payout_project_title')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_contact_title')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_wallet_title')}</StyledTitles>
          <StyledTitles>
            {t('payout_funding_round_transfer_title')}
          </StyledTitles>
          <StyledTitles>{t('payout_funding_round_network')}</StyledTitles>
          <StyledTitles>{t('payout_funding_round_currency')}</StyledTitles>
          <StyledTitles>{t('payout_action_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedPayoutItems}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </PayoutListWrapper>
  );
};

export default FundingRoundPayoutDetails;
