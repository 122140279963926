// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Modules
import {
  ProjectsList,
  ParticipationAmountBlock,
  RequestsBlock,
  StatisticsCard,
  GeneralStatisticTypes,
  formatUsersStatistics,
  usersChartTabsConfig,
  UsersStatisticTypes,
} from '@/modules/Dashboard';

// Components
import {
  Wrapper,
  CardsWrap,
  MainContent,
  StyledColumn,
  StyledBlockLayout,
} from './DashboardStyles';
import { LineChart, TableTabs } from '@/components';

// Models
import {
  IGeneralStatistics,
  IRequestsStatistics,
  IUsersStatisticsItem,
} from '@/models/dashboard.model';
import { IProject } from '@/models/projects.model';
import { useTabs } from '@/hooks';

interface IDashboard {
  projectsList: IProject[] | null;
  generalStatistic: IGeneralStatistics | null;
  requestsStatistic: IRequestsStatistics | null;
  usersStatistics: IUsersStatisticsItem[] | null;
  totalInvestmentsInMarketing: number | null;
  totalInvestmentsInMoney: number | null;
}

const Dashboard = ({
  projectsList,
  generalStatistic,
  requestsStatistic,
  totalInvestmentsInMarketing,
  totalInvestmentsInMoney,
  usersStatistics,
}: IDashboard) => {
  const { t } = useTranslation(['dashboard']);
  const { activeTab, onChange } = useTabs(UsersStatisticTypes.TOTAL);

  const mappedStatisticCards = useMemo(() => {
    return Object.keys(generalStatistic ?? {})?.map((key) => (
      <StatisticsCard
        data={generalStatistic?.[key as GeneralStatisticTypes]}
        type={key as GeneralStatisticTypes}
        key={key}
      />
    ));
  }, [generalStatistic]);

  const usersStatisticsCurrent = useMemo(() => {
    return formatUsersStatistics(
      usersStatistics ?? [],
      UsersStatisticTypes.CURRENT,
      t,
    );
  }, [usersStatistics, t]);

  const usersStatisticsTotal = useMemo(() => {
    return formatUsersStatistics(
      usersStatistics ?? [],
      UsersStatisticTypes.TOTAL,
      t,
    );
  }, [usersStatistics, t]);

  return (
    <Wrapper>
      <MainContent>
        <StyledColumn $width={'70%'}>
          <CardsWrap>{mappedStatisticCards}</CardsWrap>
          <StyledBlockLayout>
            <TableTabs
              onTabChange={onChange}
              config={usersChartTabsConfig}
              currentTab={activeTab}
            />
            <LineChart
              data={
                activeTab === UsersStatisticTypes.TOTAL
                  ? usersStatisticsTotal
                  : usersStatisticsCurrent
              }
            />
          </StyledBlockLayout>
        </StyledColumn>
        <StyledColumn $width={'27.5%'}>
          <ParticipationAmountBlock
            totalInvestmentsInMarketing={totalInvestmentsInMarketing}
            totalInvestmentsInMoney={totalInvestmentsInMoney}
          />
          <RequestsBlock data={requestsStatistic} />
        </StyledColumn>
      </MainContent>
      <ProjectsList isLoading={false} projectsList={projectsList} />
    </Wrapper>
  );
};

export default Dashboard;
