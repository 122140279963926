import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { SHARED_PROFILE_SLICE_NAME, SharedProfileState } from './models';

export const profileSliceSelector = (state: RootState): SharedProfileState =>
  state[SHARED_PROFILE_SLICE_NAME];

export const selectProjectsIsLoading = createSelector(
  profileSliceSelector,
  (state: SharedProfileState) => state.isLoading,
);
