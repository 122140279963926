import { useTranslation } from 'react-i18next';
import { useGetRequiredRule, useLengthValidationRule } from '@/hooks';
import { TextLength } from '@/constants';
import { ContractInfoFieldsNames } from '../types';

const useGetContractFormValidationRules = () => {
  const { t } = useTranslation(['common']);

  const required = useGetRequiredRule({ t });
  const textFieldLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.short,
  });
  const textDescriptionLength = useLengthValidationRule({
    t,
    min: 3,
    max: TextLength.semiLarge,
  });

  return {
    [ContractInfoFieldsNames.TITLE]: [required, textFieldLength],
    [ContractInfoFieldsNames.DESCRIPTION]: [required, textDescriptionLength],
    [ContractInfoFieldsNames.DESCRIPTION_PROJECT]: [
      required,
      textDescriptionLength,
    ],
    [ContractInfoFieldsNames.DESCRIPTION_KOL]: [
      required,
      textDescriptionLength,
    ],
  };
};

export default useGetContractFormValidationRules;
