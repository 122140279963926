import React, { useCallback, useEffect } from 'react';

// Components
import { LogSection } from '../../components';

// Models
import { LogEntities } from '@/models/logs.model';

// Helpers | Hooks
import { useAppDispatch, useAppState } from '@/hooks';
import { logsSliceSelector } from '../../feature/selectors';
import { getLogs, getSettingsLogs } from '../../feature/actionCreators';
import { resetLogsList } from '../../feature/slice';
import { getUserId } from '@/utils';

interface ILogSection {
  entity?: LogEntities;
  id?: string;
}

const LogContainer = ({ entity, id }: ILogSection) => {
  const dispatch = useAppDispatch();
  const { logs, isLoading } = useAppState(logsSliceSelector);

  const getLogsList = useCallback(() => {
    const lastItem = logs?.items?.at(-1);

    if (!entity) {
      return dispatch(
        getSettingsLogs({
          startSk: lastItem?.sk,
        }),
      );
    }

    id &&
      dispatch(
        getLogs({
          startSk: lastItem?.sk,
          entity: entity,
          id: getUserId(id ?? ''),
        }),
      );
  }, [isLoading, logs?.items, logs?.hasMore, id, entity, dispatch]);

  useEffect(() => {
    getLogsList();
  }, [entity, id]);

  useEffect(
    () => (): void => {
      dispatch(resetLogsList());
    },
    [dispatch],
  );

  return (
    <LogSection
      getNextLogs={getLogsList}
      isLoading={isLoading}
      entity={entity}
      logs={logs}
    />
  );
};

export default LogContainer;
