import React from 'react';
import { FormItemProps } from 'antd';
import { MyFormItemContext, toArr } from '../FormItemGroup/FormItemGroup';
import { FormItem } from '..';

const NestedFormItem = ({ name, ...props }: FormItemProps) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;

  return <FormItem name={concatName} {...props} />;
};

export default NestedFormItem;
