import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';
import { FundingRoundParticipationStatus } from '@/models/fundingRound.model';

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const StyledFileIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    transform: scale(0.4);
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;

  :first-child {
    align-items: flex-start;
  }
`;

export const StyledButton = styled(Button)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: initial;
    width: 100%;
    height: 2.25rem;
    background: ${COLORS.SOLITUDE_GRAY};
    color: ${COLORS.SOLITUDE_DARK};
    ${pickThemeFontStyles('10', '16', 'SEMI_BOLD')};
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &&& {
      width: 85%;
    }
  }
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;

  :first-child {
    align-items: flex-start;
  }
`;

export const CardWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.5rem;

  :first-child {
    align-items: flex-start;
  }
`;

export const StyledText = styled.div`
  ${pickThemeFontStyles('12', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  max-width: 8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledBadge = styled.div<{ $status?: string | null }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  color: ${COLORS.CORN_FLOWER_BLUE};
  ${pickThemeFontStyles('12', '16', 'BOLD')};
  ${({ $status }) => {
    switch ($status) {
      case FundingRoundParticipationStatus.ACCEPTED:
        return `background: ${COLORS.GREEN_BUBBLES};
        color: ${COLORS.LIGHT_GREEN};`;
      case FundingRoundParticipationStatus.PENDING:
      case FundingRoundParticipationStatus.WAITING_FOR_PAYMENT:
      case FundingRoundParticipationStatus.WAITING_FOR_CONFIRMATION:
      case FundingRoundParticipationStatus.PAYMENT_IN_PROGRESS:
        return `background: ${COLORS.CHABLIS_PEACH};
        color: ${COLORS.ATOMIC_TANGERINE};`;
      default:
        return `background: ${COLORS.MISTY_ROSE};
        color: ${COLORS.PICH_FROLY};`;
    }
  }}
`;

export const StyledA = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.SOLITUDE_DARK};
  width: 100%;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 85%;
  }
`;
