import styled from 'styled-components';
import { COLORS, pickThemeFontSize, pickThemeFontStyles } from '@/theme';
import { CrossInCircleIcon } from '@/icons';

export const StyledUploadImagePlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${pickThemeFontStyles('15', '15', 'SEMI_NORMAL')};
  color: ${COLORS.SOFT_GRAY};
  background: ${COLORS.WHITE};
  border: 1px dashed ${COLORS.SOFT_GRAY};
  border-radius: 0.25rem;
  width: 100%;
  height: 3.75rem;
  padding: 1rem;

  svg {
    min-width: 1rem;
    margin-right: 0.75rem;
  }
`;

export const StyledDownloadButton = styled.a`
  & .anticon {
    margin: 3rem 0rem 0rem 3rem;
    font-size: ${pickThemeFontSize('40')};
    transform: scale(3);
  }
`;

export const ImageContainer = styled.div`
  width: 5rem;
`;

export const StyledDocumentWrapper = styled.div`
  position: relative;
  width: 5rem;
  height: 4rem;
`;

export const StyledCrossInCircleIcon = styled(CrossInCircleIcon)`
  &&& {
    cursor: pointer;
    position: absolute;
    right: 0rem;
    top: 0rem;
    z-index: 10;
  }
`;
