// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledTitles,
  StyledTitle,
  StyledText,
  StyledA,
  CardWrapper,
} from './LegalAgreementsStyles';
import { ArrowRightIcon } from '@/icons';

// Models
import {
  ILegalAgreementResponse,
  LegalAgreementRoles,
} from '@/models/sharedProfile.model';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Helpers
import { getDateString, getTermsLink } from '@/utils';
import { DATE_FORMATS } from '@/constants';

interface ILegalAgreementsCard {
  data: ILegalAgreementResponse;
  role: LegalAgreementRoles;
}

const LegalAgreementsCard = ({ data, role }: ILegalAgreementsCard) => {
  const { t } = useTranslation(['profile']);

  const documentName = () => {
    switch (role) {
      case LegalAgreementRoles.KOL:
        return `legal_agreement_${data?.type.toLowerCase()}_type`;
      case LegalAgreementRoles.PROJECTS:
        return `legal_agreement_${data?.type.toLowerCase()}_type_project`;
      default:
        return `legal_agreement_${data?.type.toLowerCase()}_type_hunter`;
    }
  };

  return (
    <CardWrapper>
      <StyledTitles>
        <MobileOnly>
          <StyledTitle>{t('legal_agreement_document')}</StyledTitle>
        </MobileOnly>
        <StyledText>{t(documentName())}</StyledText>
      </StyledTitles>

      <StyledTitles>
        <MobileOnly>
          <StyledTitle>{t('legal_agreement_acceptance_date')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {getDateString(data?.created ?? '', DATE_FORMATS.DATE_WITH_DOTS)}
        </StyledText>
      </StyledTitles>

      <StyledTitles>
        <MobileOnly>
          <StyledTitle>{t('legal_agreement_ip_address')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.ipAddress}</StyledText>
      </StyledTitles>

      <StyledTitles>
        <MobileOnly>
          <StyledTitle>{t('legal_agreement_link')}</StyledTitle>
        </MobileOnly>
        <StyledA href={getTermsLink(data.type, role)} target="_blank">
          {t('legal_agreement_preview')}
          <ArrowRightIcon />
        </StyledA>
      </StyledTitles>
    </CardWrapper>
  );
};

export default LegalAgreementsCard;
