import styled from 'styled-components';

export const StyledFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;

  svg {
    width: 1.5rem;
  }
`;
