import React, { useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';

// Store
import { useForm } from '@/components';

// Components
import { MarketingContractInformation } from '../../components';

// Models
import { IContractInfoResponse } from '@/models/projects.model';
import { DocumentTypes } from '@/models/documents.model';

// Helpers
import { checkFieldValidation, getUserId } from '@/utils';
import { useAppDispatch, useAppState } from '@/hooks';
import { AccountStatuses } from '@/constants';
import { projectsSliceSelector } from '../../feature/selectors';
import {
  getContractInfo,
  updateProjectContract,
} from '../../feature/actionCreators';
import { ServiceAgreementDTO } from '../../dtos';

const ContractInfoContainer = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const { serviceAgreementInfo, projectsDetails } = useAppState(
    projectsSliceSelector,
  );
  const projectId = getUserId(projectsDetails?.id ?? '');
  const isDisabled =
    projectsDetails?.status === AccountStatuses.CANCELED ||
    projectsDetails?.status === AccountStatuses.ARCHIVED;

  const onContractUpdate = useCallback(
    async (values: IContractInfoResponse, allValues: IContractInfoResponse) => {
      const serviceAgreementInfDto = new ServiceAgreementDTO(allValues);
      await checkFieldValidation(values, form);

      await dispatch(
        updateProjectContract({
          ...serviceAgreementInfDto,
          documentType: DocumentTypes.CAMPAIGN_SERVICE_AGREEMENT,
          id: projectId,
        }),
      ).unwrap();
    },
    [dispatch, projectId],
  );

  const debouncedOnContractUpdate = useCallback(
    debounce(onContractUpdate, 1000),
    [projectId],
  );

  useEffect(() => {
    projectId &&
      dispatch(
        getContractInfo({
          projectId,
          documentType: DocumentTypes.CAMPAIGN_SERVICE_AGREEMENT,
        }),
      );
  }, [projectId]);

  return (
    <MarketingContractInformation
      handleSubmit={debouncedOnContractUpdate}
      formInstance={form}
      contractInfo={serviceAgreementInfo}
      disabled={isDisabled}
    />
  );
};

export default ContractInfoContainer;
