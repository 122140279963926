import { IContractInfo, IContractInfoDTO } from '@/models/projects.model';
import { ContractInfoFieldsNames, TServiceAgreement } from '../types';

class ServiceAgreementDTO implements IContractInfoDTO {
  descriptionForProject?: string;
  descriptionForKol?: string;
  items?: IContractInfo[];

  constructor(values: TServiceAgreement) {
    this.descriptionForProject =
      values[ContractInfoFieldsNames.DESCRIPTION_PROJECT];
    this.descriptionForKol = values[ContractInfoFieldsNames.DESCRIPTION_KOL];
    this.items = values[ContractInfoFieldsNames.ITEMS];
  }
}

export default ServiceAgreementDTO;
