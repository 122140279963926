import { IMarketingChannel } from '@/models/campaigns.model';
import { SocialChannels } from '@/models/socialChannel.model';

export const getDefaultSocialChannel = (
  name: SocialChannels,
): IMarketingChannel => ({
  name,
  percent: 0,
  isEditable: true,
  description: '',
});

export const decomposeAndUpdateSocialChannels = (
  basePool: number,
  arr: IMarketingChannel[],
) => {
  if (basePool === 0 || !arr.length) {
    return;
  }

  const isIncremented = basePool > 0;
  const increment = isIncremented ? 1 : -1;

  let localPool = basePool;
  let res: IMarketingChannel[] = [];

  for (let i = 0; i < Math.abs(basePool); i++) {
    arr.sort((a, b) =>
      isIncremented
        ? Number(a.percent) - Number(b.percent)
        : Number(b.percent) - Number(a.percent),
    );

    for (const item of arr) {
      // If value can be added to item add and break from cycle :
      if (Number(item.percent) + increment >= 0) {
        item.percent = Number(item.percent) + increment;
        localPool = localPool - increment;
        break;
      }
    }

    res = arr;
  }

  // Return because of no ability to decompose rest of pool :
  if (localPool !== 0) {
    return undefined;
  }

  return res.sort((a, b) => Number(a.percent) - Number(b.percent));
};
