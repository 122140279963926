// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  PayoutCardWrapper,
  StyledInfoWrapper,
  StyledBadge,
} from './FundingRoundDetailsCardStyles';
import { StyledText, StyledTitle } from '../Styled';

// Models
import { RevenueTransferItem } from '@/models/payout.model';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Helpers
import { numberWithCommas } from '@/utils';
import { StyledTransferType } from './RevenueTransferDetailsCardStyles';

interface IRevenueTransferDetailsCard {
  data: RevenueTransferItem;
}

const RevenueTransferDetailsCard = ({ data }: IRevenueTransferDetailsCard) => {
  const { t } = useTranslation(['payout']);

  return (
    <PayoutCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('revenue_transfer_type')}</StyledTitle>
        </MobileOnly>
        <StyledTransferType $transferType={data?.transferType}>
          <StyledText>{data?.transferType}</StyledText>
        </StyledTransferType>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('revenue_transfer_amount_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>${numberWithCommas(data?.transferAmount ?? 0)}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_round_network')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.network}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_round_currency')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.currency}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <StyledBadge $status={data?.safeTransaction?.status}>
          <StyledText>
            {data?.safeTransaction?.status
              ? t(`revenue_transfer_status_${data?.safeTransaction?.status}`)
              : t('revenue_transfer_not_started')}
          </StyledText>
        </StyledBadge>
      </StyledInfoWrapper>
    </PayoutCardWrapper>
  );
};

export default RevenueTransferDetailsCard;
