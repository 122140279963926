import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES_PATHS } from 'src/router/config';
import { useAppState } from '@/hooks';
import { selectIsAuthorized } from '@/modules/auth';

const useAuthorizedRedirect = () => {
  const navigate = useNavigate();
  const isAuthorized = useAppState(selectIsAuthorized);

  useEffect(() => {
    if (isAuthorized) {
      navigate(ROUTES_PATHS.KOL_LIST);
    }
  }, [isAuthorized]);
};

export default useAuthorizedRedirect;
