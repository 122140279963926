import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { PAYOUT_SLICE_NAME, PayoutState, initialState } from './models';
import {
  getPayoutList,
  getPayoutDetailsList,
  payoutTransaction,
  payoutFundingRoundTransaction,
  completeCampaign,
  getFundingPayoutList,
  completeFundingRound,
  sendPayoutRequest,
  getFundingPayoutDetailsList,
  getFundingRefundList,
  getFundingRefundDetails,
  refundFundingRoundTransaction,
} from './actionCreators';
import {
  IFundingPayoutListItem,
  IPayoutDetails,
  IPayoutList,
} from '@/models/payout.model';
import { showApiErrors } from '@/utils';

export const slice = createSlice({
  name: PAYOUT_SLICE_NAME,
  initialState,
  reducers: {
    resetPayoutList(state) {
      state.payoutList = null;
      state.fundingPayoutList = null;
    },
    resetPayoutDetailsList(state) {
      state.payoutListDetailsData = {
        items: [],
        revenueTransfers: [],
        hasMore: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayoutList.fulfilled, (state: PayoutState, action) => {
        state.payoutList = action.payload;
        state.isLoading = false;
      })
      .addCase(getFundingPayoutList.fulfilled, (state: PayoutState, action) => {
        state.fundingPayoutList = action.payload;
        state.isLoading = false;
      })
      .addCase(getFundingRefundList.fulfilled, (state: PayoutState, action) => {
        state.fundingRefundList = action.payload;
        state.isLoading = false;
      })
      .addCase(
        getFundingPayoutDetailsList.fulfilled,
        (state: PayoutState, action) => {
          state.fundingPayoutDetailsData = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        getFundingRefundDetails.fulfilled,
        (state: PayoutState, action) => {
          state.fundingRefundDetailsData = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(getPayoutDetailsList.fulfilled, (state: PayoutState, action) => {
        state.payoutListDetailsData = {
          items: (action.meta.arg.startId
            ? [
                ...(state.payoutListDetailsData?.items ?? []),
                ...action.payload.items,
              ]
            : [...action.payload.items]) as IPayoutDetails[],
          revenueTransfers: action.payload.revenueTransfers,
          hasMore: action.payload.hasMore,
        };
        state.isLoading = false;
      })
      .addCase(
        payoutFundingRoundTransaction.fulfilled,
        (state: PayoutState, action) => {
          const updatedPayouts = (
            state.fundingPayoutDetailsData?.payouts || []
          ).map((item) =>
            item.id === action.payload.id && item.sk === action.payload.sk
              ? action.payload
              : item,
          );
          state.fundingPayoutDetailsData = {
            ...(state.fundingPayoutDetailsData || {}),
            payouts: updatedPayouts,
          };
          state.isLoading = false;
        },
      )
      .addCase(
        refundFundingRoundTransaction.fulfilled,
        (state: PayoutState, action) => {
          state.fundingRefundDetailsData =
            state.fundingRefundDetailsData?.map((item) =>
              item.participationId === action.payload.participationId
                ? action.payload
                : item,
            ) ?? [];
          state.isLoading = false;
        },
      )
      .addCase(payoutTransaction.fulfilled, (state: PayoutState, action) => {
        const updatedPayoutDetails = (
          state.payoutListDetailsData?.items ?? []
        ).map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });

        state.payoutListDetailsData = {
          items: updatedPayoutDetails,
          revenueTransfers: state.payoutListDetailsData.revenueTransfers,
          hasMore: state.payoutListDetailsData.hasMore,
        };

        state.isLoading = false;
      })
      .addCase(completeCampaign.fulfilled, (state: PayoutState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.payoutList = (state.payoutList ?? []).map((item) =>
          item.id === payload.id ? (payload as unknown as IPayoutList) : item,
        );
      })
      .addCase(completeFundingRound.fulfilled, (state: PayoutState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.fundingPayoutList = (state.fundingPayoutList ?? []).map((item) =>
          item.id === payload.id
            ? (payload as unknown as IFundingPayoutListItem)
            : item,
        );
      })
      .addCase(sendPayoutRequest.fulfilled, (state: PayoutState, action) => {
        state.isLoading = false;
        state.payoutList = (state.payoutList ?? []).map((item) =>
          item.id === action.payload.targetId
            ? // TODO: provide correct ID after BE update:
              { ...item, openedRequestId: 'requestID' }
            : item,
        );
      })
      .addMatcher(
        isPending(
          getPayoutList,
          getFundingPayoutList,
          getPayoutDetailsList,
          payoutTransaction,
          payoutFundingRoundTransaction,
          completeCampaign,
          completeFundingRound,
          getFundingPayoutDetailsList,
          getFundingRefundDetails,
          getFundingRefundList,
          refundFundingRoundTransaction,
        ),
        (state: PayoutState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(
          getPayoutList,
          getFundingPayoutList,
          getPayoutDetailsList,
          payoutTransaction,
          payoutFundingRoundTransaction,
          completeCampaign,
          completeFundingRound,
          getFundingPayoutDetailsList,
          getFundingRefundDetails,
          getFundingRefundList,
          refundFundingRoundTransaction,
        ),
        (state: PayoutState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export const { resetPayoutList, resetPayoutDetailsList } = slice.actions;
export default slice.reducer;
