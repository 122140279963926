import { createSlice } from '@reduxjs/toolkit';
import { initialState, MilestonesState, MILESTONES_SLICE_NAME } from './models';
import { getProjectMilestones } from './actionCreators';
import { showApiErrors } from '@/utils';

export const slice = createSlice({
  name: MILESTONES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getProjectMilestones.fulfilled,
        (state: MilestonesState, action) => {
          state.milestones = action.payload;
        },
      )
      .addCase(getProjectMilestones.pending, (state: MilestonesState) => {
        state.isLoading = true;
      })
      .addCase(
        getProjectMilestones.rejected,
        (state: MilestonesState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export default slice.reducer;
