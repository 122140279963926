// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledBlock,
  StyledText,
  StyledChannels,
  StyledInfo,
  StyledChannelsWrapper,
  StyledA,
  StyledTitle,
} from './CampaignInfoTabStyles';
import SocialsBlock from '../ProjectDetailsTab/SocialsBlock';

// Models
import { ICampaign } from '@/models/campaigns.model';

// Modules
import {
  socialChannelsConfig,
  socialChannelsList,
} from '@/modules/KolsList/constants/socialChannels';
import {
  CAMPAIGN_GOALS_WITH_SOCIALS,
  IMainCampaignGoal,
} from '@/modules/Campaigns/helpers/types';

// Helpers
import { normalizeExternalLink } from '@/utils';

interface ICampaignInfo {
  data: ICampaign | null;
}

const CampaignInfo = ({ data }: ICampaignInfo) => {
  const { t } = useTranslation(['campaigns']);

  const mappedChannels = useMemo(() => {
    return socialChannelsList.map((id) => {
      const isActive = !!data?.goalSocialChannels?.some((item) => item === id);

      return (
        isActive && (
          <StyledChannels key={id}>
            {socialChannelsConfig[id]?.['icon']}
          </StyledChannels>
        )
      );
    });
  }, [data]);

  const goalUrl = useMemo(
    () => data?.goalUrl && normalizeExternalLink(data.goalUrl),
    [data?.goalUrl],
  );

  return (
    <StyledBlock>
      <StyledTitle>{data?.title}</StyledTitle>
      <SocialsBlock data={data} />

      <StyledInfo>
        <StyledTitle>{t('campaign_description_title')}</StyledTitle>
        <StyledText>{data?.campaignDescription}</StyledText>
      </StyledInfo>
      <StyledInfo>
        <StyledTitle>{t('campaign_goal_title')}</StyledTitle>
        <StyledText>
          <b>{t(`campaign_${data?.campaignGoal}`)}: </b>
          {data?.goalDescription}
        </StyledText>
      </StyledInfo>

      {CAMPAIGN_GOALS_WITH_SOCIALS.has(
        data?.campaignGoal as IMainCampaignGoal,
      ) ? (
        <StyledChannelsWrapper>{mappedChannels}</StyledChannelsWrapper>
      ) : (
        <StyledInfo>
          <StyledText>
            <b>{t('campaign_url_title')}</b>
            <StyledA href={goalUrl} target="_blank">
              {goalUrl}
            </StyledA>
          </StyledText>
        </StyledInfo>
      )}

      <StyledInfo>
        <StyledTitle>{t('campaign_selling_title')}</StyledTitle>
        <StyledText
          dangerouslySetInnerHTML={{
            __html: data?.usp ? String(data?.usp) : '',
          }}
        />
      </StyledInfo>
    </StyledBlock>
  );
};

export default CampaignInfo;
