// Globals
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';

// Components
import { AutomaticApprovalSettings } from '../../components';
import { showErrorToast, showSuccessToast } from '@/components';

// Modules
import { getSettingsLogs } from '@/modules/Logs';

// Models
import { AutomaticApprovalDTO } from '@/models/settings.model';

// Hooks | Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import {
  getSocialsThresholdsSettings,
  postSocialsThresholdsSettings,
} from '../../feature/actionCreators';
import { settingsSliceSelector } from '../../feature/selectors';
import { checkFieldValidation } from '@/utils';

interface IAutomaticApprovalContainer {
  toggleIsEditState: () => void;
  isEditActive: boolean;
}

const AutomaticApprovalContainer = ({
  toggleIsEditState,
  isEditActive,
}: IAutomaticApprovalContainer) => {
  const { t } = useTranslation(['settings']);
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const { automaticApprovalData } = useAppState(settingsSliceSelector);

  useEffect(() => {
    dispatch(getSocialsThresholdsSettings());
  }, []);

  const handleSubmit = useCallback(
    async (values: AutomaticApprovalDTO) => {
      await checkFieldValidation(values, form);
      try {
        await dispatch(postSocialsThresholdsSettings({ ...values })).unwrap();
        toggleIsEditState();
        dispatch(getSettingsLogs({}));
        showSuccessToast({ message: t('settings_success_toast') });
      } catch (e: unknown) {
        showErrorToast({ message: t('settings_error_toast') });
      }
    },
    [form, dispatch],
  );

  return (
    <AutomaticApprovalSettings
      handleSubmit={handleSubmit}
      formInstance={form}
      initialValues={automaticApprovalData}
      isEditActive={isEditActive}
    />
  );
};

export default AutomaticApprovalContainer;
