import styled from 'styled-components';
import { DEVICES } from '@/theme';
import { BlockLayout } from '@/ui';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  min-height: 6rem;
  margin-bottom: 1rem;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 12rem;
  }
`;

export const StyledBlockLayout = styled(BlockLayout)`
  height: 100%;
  width: 100%;
  max-height: 42rem;
  flex-direction: column;

  > div {
    width: 100%;
  }
`;

export const CardsWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 1.88rem;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  gap: 1.88rem;
  margin-bottom: 1rem;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledColumn = styled.div<{ $width: string }>`
  display: flex;
  width: 100%;
  gap: 1.88rem;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: ${({ $width }) => $width};
  }
`;
