// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledHeaderBlocks,
  FundingDetailsWrapper,
  StyledBanner,
  SideBlocks,
  StyledImage,
  StyledImageWrapper,
  StyledLogoImage,
  StyledLogoWrapper,
  StyledTitle,
  StyledText,
  StyledButton,
} from './FundingOverviewStyles';
import SocialsBlock from './SocialsBlock';
import DealTerms from './DealTerms';
import FundingDetails from '../FundingDetails/FundingDetails';

// Models
import { IFundingRound } from '@/models/fundingRound.model';

// Modules
import { FundingRoundSavingBadge } from '@/modules/SharedFundingRound';

// Hooks
import { useCopyToClipboard } from '@/hooks';
import { getCurrentDomain, getRoundPercentDown } from '@/utils';

interface IFundingOverview {
  fundingRound: IFundingRound;
}

const FundingOverview = ({ fundingRound }: IFundingOverview) => {
  const { t } = useTranslation(['fundingRound']);
  const copyToClipboard = useCopyToClipboard();
  const fundingSavings =
    fundingRound?.publicPricePerToken &&
    getRoundPercentDown(
      fundingRound?.publicPricePerToken - (fundingRound?.pricePerToken ?? 0),
      fundingRound?.publicPricePerToken,
    );

  return (
    <FundingDetailsWrapper>
      <StyledHeaderBlocks>
        <StyledBanner>
          <StyledTitle $type="h3">{fundingRound?.projectTitle}</StyledTitle>
          <StyledText>{fundingRound?.projectSubTitle}</StyledText>
          <StyledImageWrapper>
            <StyledImage
              src={fundingRound?.fundingRoundBanner ?? '/images/stub_image.png'}
              alt="fundingRoundBanner"
              width={'100%'}
              height={261}
              preview={false}
            />
            {fundingSavings && (
              <FundingRoundSavingBadge fundingSavings={fundingSavings} />
            )}
            <StyledLogoWrapper>
              <StyledLogoImage
                src={fundingRound?.projectImage ?? '/images/stub_image.png'}
                alt="fundingRoundImage"
                width={100}
                height={100}
                preview={false}
              />
            </StyledLogoWrapper>
          </StyledImageWrapper>
          <SocialsBlock data={fundingRound} />
        </StyledBanner>
        <SideBlocks>
          <DealTerms fundingRound={fundingRound} />
          <StyledButton
            type="primary"
            onClick={() =>
              copyToClipboard(
                `${getCurrentDomain()}project/${fundingRound.slug}`,
              )
            }
          >
            {t('funding_overview_copy_link_button')}
          </StyledButton>
        </SideBlocks>
      </StyledHeaderBlocks>
      <FundingDetails fundingRound={fundingRound} />
    </FundingDetailsWrapper>
  );
};

export default FundingOverview;
