// Global
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component
import {
  KOLCardWrapper,
  StyledInfoWrapper,
  StyledText,
  StyledLinkWrapper,
  StyledTitle,
} from './KOLCardStyles';
import { ArrowRightIcon } from '@/icons';
import { Link } from '@/ui';
import { ContactBar, CountBadge } from '@/components';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Routes
import { ROUTES_PATHS } from '@/router';

// Models
import { IKOL } from '@/models/kolsList.model';
import { SocialChannels } from '@/models/socialChannel.model';

// Helpers
import { KOLDetailsTabKeys } from '../../constants';
import { getDateString, renderUsername } from '@/utils';
import { DATE_FORMATS } from '@/constants';
import { getChannelLink } from '../../helpers';

interface IKOLCard {
  kol: IKOL;
}

const KOLCard = ({ kol }: IKOLCard) => {
  const { t } = useTranslation(['kolsList']);
  const { firstName, lastName, formAddress } = kol ?? {};
  const { twitterLink, twitterHandle } = getChannelLink(
    kol,
    SocialChannels.TWITTER,
  );
  const registrationDate = getDateString(
    kol?.created ?? '',
    DATE_FORMATS.DATE_WITH_DOTS,
  );

  return (
    <KOLCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('kols_list_alias_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {kol?.alias ??
            renderUsername({
              firstName,
              lastName,
              formAddress,
            })}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('kols_list_registration_date_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{registrationDate}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('kols_list_contact_title')}</StyledTitle>
        </MobileOnly>
        <StyledLinkWrapper>
          <ContactBar data={kol} />
        </StyledLinkWrapper>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('kols_list_community_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{kol?.mainCommunity?.value}</StyledText>
        <StyledText>{kol?.secondaryCommunity?.value}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('kols_list_geography_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{kol?.mainGeography?.value}</StyledText>
        <StyledText>{kol?.secondaryGeography?.value}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('kols_list_social_channels_title')}</StyledTitle>
        </MobileOnly>
        <StyledLinkWrapper>
          <Link target="_blank" to={twitterLink}>
            {twitterHandle}
          </Link>
        </StyledLinkWrapper>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('kols_list_social_status_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{kol?.status}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('kols_list_social_kyc_status_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{kol?.KYC?.state ?? '-'}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <StyledText>
          <Link
            to={`${ROUTES_PATHS.KOL_LIST}/${encodeURIComponent(
              kol?.id ?? '',
            )}/${KOLDetailsTabKeys.GENERAL_INFO}`}
          >
            <ArrowRightIcon />
          </Link>
          <CountBadge count={kol.unreadRequestsCount ?? 0} />
        </StyledText>
      </StyledInfoWrapper>
    </KOLCardWrapper>
  );
};

export default KOLCard;
