// Globals
import React, { useCallback, useEffect } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

// Store
import { useAppDispatch, useAppState } from '@/hooks';
import { partnersListSliceSelector } from '../../feature/selectors';
import { getPartnersList } from '../../feature/actionCreators';
import { resetPartnersList, setPartnersFilters } from '../../feature/slice';

// Components
import { StyledBox, StyledCheckBox } from './PartnersListContainerStyles';
import { Title, StyledPageWrapper } from '@/ui';
import { PartnersList } from '../../components';

// Modules
import { SearchInfoInput } from '@/modules/SharedProfile';

const PartnersListContainer = () => {
  const { t } = useTranslation(['partnersList']);
  const dispatch = useAppDispatch();

  const { partnersListData, isLoading, partnersFilters } = useAppState(
    partnersListSliceSelector,
  );

  const getNextPartnersList = useCallback(
    debounce(() => {
      if (isLoading || !partnersListData?.hasMore) return;
      const lastItem = partnersListData?.items?.at(-1);
      dispatch(
        getPartnersList({
          startId: lastItem?.id,
          ...(partnersFilters ? partnersFilters : {}),
        }),
      );
    }, 1000),
    [isLoading, dispatch, partnersListData?.hasMore, partnersFilters],
  );

  const handlePartnersSearch = async (value: string) => {
    dispatch(
      setPartnersFilters({
        search: value,
      }),
    );
  };

  const handleKYBFilter = (e: CheckboxChangeEvent) => {
    dispatch(
      setPartnersFilters({
        verifiedBySynaps: e.target.checked,
      }),
    );
  };

  useEffect(
    () => (): void => {
      dispatch(resetPartnersList());
    },
    [dispatch],
  );

  return (
    <StyledPageWrapper>
      <Title $type="h3">{t('hunters_list_title')}</Title>
      <StyledBox>
        <SearchInfoInput handleSearch={handlePartnersSearch} />
        {t('show_verified_synaps_title', { ns: 'common' })}
        <StyledCheckBox onChange={handleKYBFilter} />
      </StyledBox>
      <PartnersList
        partners={partnersListData}
        isLoading={isLoading}
        getNextProjects={getNextPartnersList}
      />
    </StyledPageWrapper>
  );
};

export default PartnersListContainer;
