// Globals
import React, { useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledBlock,
  StyledMarketingBlock,
  StyledTitle,
  StyledItem,
  StyledMarketingHeader,
  StyledSelect,
  StyledSelectWrapper,
} from './CampaignResultsStyles';
import MarketingDeliveryItem from './MarketingDeliveryItem';
import SocialChannelsTitles from './SocialChannelsTitles';
import { Spinner, StyledSpinnerWrap } from '@/ui';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import {
  CampaignParticipantStatuses,
  ICampaign,
  IParticipationItems,
} from '@/models/campaigns.model';

// Helpers
import { useInfiniteScroll } from '@/hooks';
import {
  buildMarketingDeliveryFilters,
  getActiveCampaignStatus,
  getFilteredParticipantsByStatus,
  getParticipatedChannelsList,
} from '../../helpers';
import { CampaignActiveStatuses } from '../../constants';

interface IMarketingDelivery {
  campaign: ICampaign;
  campaignParticipants: IParticipationItems[] | null;
  getNextParticipants: () => void;
  isLoading: boolean;
  hasMore: boolean;
}

const MarketingDelivery = ({
  campaignParticipants,
  getNextParticipants,
  campaign,
  isLoading,
  hasMore,
}: IMarketingDelivery) => {
  const { t } = useTranslation(['campaigns']);
  const loaderRef = useRef<HTMLDivElement>(null);
  const campaignStatus = getActiveCampaignStatus(
    campaign?.marketingStartDate,
    campaign?.marketingEndDate,
  );
  const acceptedParticipants = getFilteredParticipantsByStatus(
    campaignParticipants as IParticipationItems[],
    CampaignParticipantStatuses.ACCEPTED,
  );
  const channelsList = getParticipatedChannelsList(acceptedParticipants);
  const marketingOptions = buildMarketingDeliveryFilters(
    acceptedParticipants,
    campaignStatus,
  );
  const [activeFilter, setActiveFilter] = useState<string>(
    marketingOptions[0].value,
  );

  useInfiniteScroll({
    callback: getNextParticipants,
    ref: loaderRef,
    isLoading,
    hasMore,
  });

  const handleMarketingDelivery = (v: unknown) => {
    setActiveFilter(v as string);
  };

  const mappedMarketingDelivery = useMemo(() => {
    return acceptedParticipants?.map((item) => (
      <MarketingDeliveryItem
        activeFilter={activeFilter}
        item={item}
        key={item.id}
        channelsList={channelsList}
      />
    ));
  }, [campaignParticipants, activeFilter, channelsList]);

  const mappedTitles = useMemo(() => {
    return channelsList.map((channel) => (
      <SocialChannelsTitles key={channel} channel={channel} />
    ));
  }, [channelsList]);

  if (campaignStatus === CampaignActiveStatuses.SIGN_UP) return null;

  return (
    <StyledBlock>
      <StyledMarketingHeader>
        <StyledTitle>{t('campaign_details_marketing_delivery')}</StyledTitle>
        <StyledSelectWrapper>
          <StyledSelect
            options={marketingOptions}
            onChange={handleMarketingDelivery}
            value={activeFilter}
          />
        </StyledSelectWrapper>
      </StyledMarketingHeader>
      <DesktopOnly>
        <StyledMarketingBlock>
          <StyledItem>{t('campaign_details_name')}</StyledItem>
          {mappedTitles}
        </StyledMarketingBlock>
      </DesktopOnly>
      {mappedMarketingDelivery}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </StyledBlock>
  );
};

export default MarketingDelivery;
