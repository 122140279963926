import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { KOLS_LIST_SLICE_NAME, KOLsListState } from './models';

export const kolsListSliceSelector = (state: RootState): KOLsListState =>
  state[KOLS_LIST_SLICE_NAME];

export const selectKolListIsLoading = createSelector(
  kolsListSliceSelector,
  (state: KOLsListState) => state.isLoading,
);

export const selectInfluencerDataIsLoading = createSelector(
  kolsListSliceSelector,
  (state: KOLsListState) => state.influencerDataIsLoading,
);

export const selectInfluencerDataError = createSelector(
  kolsListSliceSelector,
  (state: KOLsListState) => state.influencerDataError,
);

export const selectKOLDetails = createSelector(
  kolsListSliceSelector,
  (state: KOLsListState) => state.kolDetails,
);
