// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledTable } from './TableStyles';

export interface ITableData {
  key?: string;
  title?: string;
  items?: string;
  requirements?: string;
  icon?: JSX.Element;
}

interface IColumns {
  title: string;
  dataIndex: string;
}

interface ITable {
  columns: IColumns[];
  dataSource: ITableData[];
  showHeader?: boolean;
}

const Table = ({ columns, dataSource, showHeader = true }: ITable) => {
  const { t } = useTranslation(['fundingRound']);

  const translatedColumn = columns.map((item) => {
    return {
      title: t(item.title),
      dataIndex: t(item.dataIndex),
    };
  });

  return (
    <StyledTable
      columns={translatedColumn}
      dataSource={dataSource}
      pagination={false}
      showHeader={showHeader}
      bordered
    />
  );
};

export default Table;
