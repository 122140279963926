// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledWrapper } from './SeedHunterCreditsStyles';
import {
  StyledTitles,
  StyledColumnCardTitle,
  StyledInfoTitles,
} from '../Styled/Styled';
import { StyledSpinnerWrap, Spinner } from '@/ui';
import SeedHunterCreditsHistoryCard from './SeedHunterCreditsHistoryCard';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { ICreditHistory } from '@/models/sharedProfile.model';

// Hooks
import { useInfiniteScroll } from '@/hooks';

interface ISeedHunterCreditsHistory {
  data: {
    items: ICreditHistory[] | null;
    hasMore: boolean;
    totalBalance: number;
  };
  getNextSeedHunterCredits: () => void;
  isLoading: boolean;
}

const SeedHunterCreditsHistory = ({
  isLoading,
  data,
  getNextSeedHunterCredits,
}: ISeedHunterCreditsHistory) => {
  const { t } = useTranslation(['profile']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedCreditsList = useMemo(() => {
    return data?.items?.map((data) => {
      return <SeedHunterCreditsHistoryCard data={data} key={data.sk} />;
    });
  }, [data]);

  useInfiniteScroll({
    callback: getNextSeedHunterCredits,
    ref: loaderRef,
    isLoading,
    hasMore: data?.hasMore,
  });

  return (
    <StyledWrapper>
      <StyledColumnCardTitle>{t('credit_history')}</StyledColumnCardTitle>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('credit_history_event_title')}</StyledTitles>
          <StyledTitles>{t('credit_history_date_title')}</StyledTitles>
          <StyledTitles>{t('credit_history_admin_email_title')}</StyledTitles>
          <StyledTitles>{t('credit_history_credit_amount_title')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedCreditsList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </StyledWrapper>
  );
};

export default SeedHunterCreditsHistory;
