import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button, DatePicker, Text, TextArea, Title } from '@/ui';

export const Wrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-top: 1.5rem;
  align-items: flex-start;
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const BlockWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 60%;
    align-items: center;
    flex-direction: row;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 60%;
  }
`;

export const StyledText = styled(Text)`
  flex: 1;
`;

export const StyledTitle = styled(Title)`
  ${pickThemeFontStyles('24', '24', 'BOLD')};
`;

export const StyledInputWrapper = styled.div`
  flex: 3;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  display: flex;
  &&& {
    max-width: 6rem;
    height: 2rem;
    ${pickThemeFontStyles('12', '16', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  &&&.ant-input {
    ${pickThemeFontStyles('16', '26', 'NORMAL')};
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  background: ${COLORS.LIGHTEST_GRAY};
  height: 2.875rem;
  border: 0;
  ${pickThemeFontStyles('15', '15', 'SEMI_NORMAL')};
`;
