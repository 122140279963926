// Globals
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { ListWrapper, StyledTitles, StyledInfoTitles } from '../Styles/Styles';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import FundingParticipationCard from './FundingParticipationCard';

// Models
import { FundingRoundParticipantsReportItem } from '@/models/reports.model';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

interface IParticipationList {
  isLoading: boolean;
  participations: FundingRoundParticipantsReportItem[] | null;
}

const FundingParticipationsList = ({
  isLoading,
  participations,
}: IParticipationList) => {
  const { t } = useTranslation(['reports']);

  const mappedParticipationList = useMemo(() => {
    return participations?.map((participation) => {
      return (
        <FundingParticipationCard
          participation={participation}
          key={participation.participationId}
        />
      );
    });
  }, [participations]);

  return (
    <ListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>
            {t('report_details_participation_participant')}
          </StyledTitles>
          <StyledTitles>{t('report_details_participation_date')}</StyledTitles>
          <StyledTitles>
            {t('report_details_participation_contact')}
          </StyledTitles>
          <StyledTitles>
            {t('report_details_participation_amount')}
          </StyledTitles>
          <StyledTitles>
            {t('report_details_participation_credits_used')}
          </StyledTitles>
          <StyledTitles>{t('report_details_participant_tokens')}</StyledTitles>
          <StyledTitles>{t('report_details_participant_wallet')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedParticipationList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
    </ListWrapper>
  );
};

export default FundingParticipationsList;
