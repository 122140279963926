import styled from 'styled-components';
import { Form } from '@/components';
import { Button } from '@/ui';

export const StyledInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const StyledFrom = styled(Form)`
  display: flex;
  width: 100%;
  gap: 1.5rem;
  flex-direction: column;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  height: 2rem !important;
`;
