// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledInfoWrapper,
  Wrapper,
  StyledTitle,
  StyledText,
  StyledLink,
  StyledActionText,
} from './LogCardStyles';
import { Tooltip } from '@/ui';
import LogDifferenceModal from './LogDifferenceModal';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Models
import { ILogs, LogEntities } from '@/models/logs.model';

// Utils
import { getDateString, isObjEmpty } from '@/utils';
import { DATE_FORMATS } from '@/constants';
import { logsDataNormalizer } from '../../helpers';
import { useModal } from '@/hooks';

interface ILogCard {
  log: ILogs;
  entity?: LogEntities;
}

const LogCard = ({ log, entity }: ILogCard) => {
  const { t } = useTranslation(['logs']);
  const { isOpened, openModal, closeModal } = useModal();
  const { reason, status, created, adminEmail, url } = logsDataNormalizer(log);
  const isNotRequest = entity && entity !== LogEntities.REQUESTS;

  const actionLog = () => {
    switch (true) {
      case isObjEmpty(log?.difference?.newData) &&
        isObjEmpty(log?.difference?.oldData):
        return <StyledText>{t('logs_no_edited_fields')}</StyledText>;
      case !entity || isNotRequest:
        return (
          <StyledActionText onClick={openModal}>
            {t('logs_edited_fields')}
          </StyledActionText>
        );
      default:
        return (
          <StyledText>
            {t(`logs_status_${status}`)}
            {url && (
              <StyledLink to={url} target="_blank">
                {url}
              </StyledLink>
            )}
          </StyledText>
        );
    }
  };

  return (
    <Wrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('logs_date_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {getDateString(created, DATE_FORMATS.DATE_WITH_TIME)}
        </StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('logs_admin_email_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{adminEmail ?? '-'}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('logs_action_title')}</StyledTitle>
        </MobileOnly>
        {actionLog()}
      </StyledInfoWrapper>

      {isNotRequest && (
        <StyledInfoWrapper>
          <MobileOnly>
            <StyledTitle>{t('logs_status_title')}</StyledTitle>
          </MobileOnly>
          <StyledText>
            {status && t(`logs_status_${status}`)}
            {url && (
              <StyledLink to={url} target="_blank">
                {url}
              </StyledLink>
            )}
          </StyledText>
        </StyledInfoWrapper>
      )}

      {entity && (
        <StyledInfoWrapper>
          <MobileOnly>
            <StyledTitle>{t('logs_reason_title')}</StyledTitle>
          </MobileOnly>
          <Tooltip
            getPopupContainer={(triggerNode) => triggerNode}
            title={reason}
            placement={'top'}
          >
            <StyledText>{reason || '-'}</StyledText>
          </Tooltip>
        </StyledInfoWrapper>
      )}
      <LogDifferenceModal
        log={log}
        isOpened={isOpened}
        handleClose={closeModal}
      />
    </Wrapper>
  );
};

export default LogCard;
