// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledRow,
  StyledSubTitle,
  CampaignInfoWrapper,
  CampaignCardWrapper,
  DescriptionWrap,
  StyledCardWrapper,
  StyledText,
  StyledSliderText,
  StyledTextWrap,
} from './CampaignCardStyles';
import { SliderWrapper, StyledSlider } from '../Styled/Styled';
import { TextTruncate } from '@/ui';
import CardImage from '../CardImage/CardImage';
import CampaignFooter from './CampaignFooter';

// Models
import { ICampaign } from '@/models/campaigns.model';

// Helpers
import {
  getDateString,
  getRoundPercentDown,
  subtractCommission,
} from '@/utils';
import { DATE_FORMATS } from '@/constants';

interface ICampaignCard {
  campaign: ICampaign;
}

const CampaignCard = ({ campaign }: ICampaignCard) => {
  const { t } = useTranslation(['campaigns']);
  const normalizedStartDate = getDateString(
    campaign?.marketingStartDate,
    DATE_FORMATS.DAY_MONTH_WITH_DOTS,
  );
  const normalizedEndDate = getDateString(
    campaign?.marketingEndDate,
    DATE_FORMATS.DATE_WITH_DOTS,
  );
  const budgedPercentage = getRoundPercentDown(
    campaign?.usedBudget?.total ?? 0,
    subtractCommission(
      campaign?.marketingBudget,
      campaign?.commissionPercent,
    ) ?? 0,
  );
  const campaignGoalLabel = t(`campaign_${campaign.campaignGoal}`);

  return (
    <CampaignCardWrapper>
      <StyledCardWrapper>
        <CardImage
          campaignBanner={campaign?.campaignBanner}
          projectImage={campaign?.projectImage}
        />

        <StyledRow>
          <StyledSubTitle $type="h2">{campaign.projectTitle}</StyledSubTitle>
        </StyledRow>

        <StyledRow>
          <DescriptionWrap>
            <TextTruncate
              text={campaign?.projectSubTitle ?? ''}
              rows={3}
              className={'description'}
            />
          </DescriptionWrap>
        </StyledRow>

        <StyledRow>
          <StyledText>{`${campaign.title} / ${campaignGoalLabel}`}</StyledText>
        </StyledRow>

        <StyledRow>
          <CampaignInfoWrapper>
            <StyledTextWrap>
              <StyledSliderText>
                {t('campaign_details_budget_booked')}
              </StyledSliderText>
              <StyledSliderText>{budgedPercentage + '%'}</StyledSliderText>
            </StyledTextWrap>
            <SliderWrapper>
              <StyledSlider
                budgedPercentage={budgedPercentage}
                value={budgedPercentage}
                max={100}
              />
            </SliderWrapper>
          </CampaignInfoWrapper>
        </StyledRow>

        <StyledRow>
          <StyledTextWrap>
            <StyledText>{t('campaign_details_marketing_period')}</StyledText>
            <StyledText $wide>
              {`${normalizedStartDate} - ${normalizedEndDate}`}
            </StyledText>
          </StyledTextWrap>
        </StyledRow>

        <CampaignFooter campaign={campaign} />
      </StyledCardWrapper>
    </CampaignCardWrapper>
  );
};

export default CampaignCard;
