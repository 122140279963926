import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { CAMPAIGNS_SLICE_NAME, CampaignsState } from './models';

export const campaignsSliceSelector = (state: RootState): CampaignsState =>
  state[CAMPAIGNS_SLICE_NAME];

export const selectCampaignsIsLoading = createSelector(
  campaignsSliceSelector,
  (state: CampaignsState) => state.isLoading,
);

export const selectCampaignDetails = createSelector(
  campaignsSliceSelector,
  (state: CampaignsState) => state.campaignDetails,
);

export const selectCampaignKols = createSelector(
  campaignsSliceSelector,
  (state: CampaignsState) => state.campaignKols,
);
