// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledLabel, StyledBadge } from './StatusBadgeStyles';

// Helpers
import { getStatuses } from '@/types';

interface IProps {
  status?: string | null;
}

const StatusBadge = ({ status }: IProps) => {
  const { t } = useTranslation(['common']);
  return (
    <StyledBadge $status={status}>
      <StyledLabel>{getStatuses(t, status)}</StyledLabel>
    </StyledBadge>
  );
};

export default StatusBadge;
