import styled, { css } from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { TextFileIcon } from '@/icons';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const DetailsBlock = styled.div<{ $isRow?: boolean }>`
  display: flex;
  flex-direction: ${({ $isRow }) => ($isRow ? 'row' : 'column')};
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-start;
  flex: 1;
  ${pickThemeFontStyles('10', '16', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const DescriptionWrap = styled.div`
  &&& {
    ${pickThemeFontStyles('14', '22', 'SEMI_NORMAL')};
    color: ${COLORS.CORN_FLOWER_BLUE};
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 4rem;
    flex: 1;
  }
`;

export const iconStyles = css`
  &.anticon {
    svg {
      ${pickThemeFontStyles('16', '16', 'SEMI_BOLD')};
    }
  }
`;

export const StyledFileIcon = styled(TextFileIcon)`
  margin-right: 0.3rem;
  ${iconStyles};
`;

export const StyledChannelsWrapper = styled.div`
  transform: scale(0.6);
`;

export const StyledA = styled.a`
  display: flex;
  height: 1rem;
  align-items: center;
  border-radius: 0.375rem;
  padding: 0.3rem;
  background: ${COLORS.SOLITUDE};
  color: ${COLORS.SOLITUDE_DARK};
  ${pickThemeFontStyles('12', '16', 'BOLD')};
`;

export const StyledChannels = styled.div`
  display: flex;
  height: 1.5rem;
  align-items: center;
  padding: 0.5rem;
  color: ${COLORS.SOLITUDE_DARK};
`;
