import dayjs from 'dayjs';
import { IUpdateCampaignPostDTO } from '@/models/marketingDelivery.model';
import { AddCampaignPostsFieldsNames } from '../types';
import { SocialChannels } from '@/models/socialChannel.model';

class CampaignPostDTO implements IUpdateCampaignPostDTO {
  kolId?: string;
  date?: string;
  link?: string;
  calendarWeek?: number;
  socialChannel?: SocialChannels;

  constructor(values: IUpdateCampaignPostDTO) {
    this.kolId = values[AddCampaignPostsFieldsNames.KOL_ID];
    this.date = values[AddCampaignPostsFieldsNames.DATE];
    this.link = values[AddCampaignPostsFieldsNames.CHANNEL_LINK];
    this.calendarWeek = dayjs(
      values[AddCampaignPostsFieldsNames.DATE],
    ).isoWeek();
    this.socialChannel = values[AddCampaignPostsFieldsNames.SOCIAL_CHANNEL];
  }
}

export default CampaignPostDTO;
