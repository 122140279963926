import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { ArrowRightIcon } from '@/icons';
import { Button } from '@/ui';

export const StyledButton = styled(Button)`
  display: flex;
  margin-top: 2rem;
  &&& {
    max-width: 6.5rem;
    height: 2rem;
    background: ${COLORS.SOLITUDE_GRAY};
    color: ${COLORS.DARK_BLUE};
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
  margin-right: 0.5rem;
  transform: rotate(180deg);
`;

export const StyledStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledStatus = styled.div`
  display: flex;
  gap: 0.625rem;
  width: 23rem;
  align-items: center;

  h1 {
    width: 10rem;
  }
`;
