// Globals
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledSelect } from './StatusChangeBlockStyled';
import ChangeStatusModal from './ChangeStatusModal';

// Hooks | Helpers
import { IBaseItem, getStatuses } from '@/types';
import { useModal } from '@/hooks';
import { noop } from '@/utils';

interface IProps {
  status?: string;
  handleRequest?: (description: string, status?: string) => Promise<void>;
  statusesConfigs?: IBaseItem[];
  withStatus?: boolean;
  isEditable?: boolean;
}

const StatusChangeBlock = ({
  status,
  handleRequest = noop,
  statusesConfigs,
  withStatus,
  isEditable,
}: IProps) => {
  const { t } = useTranslation(['common']);
  const [selectedStatus, setSelectedStatus] = useState('');
  const { isOpened, openModal, closeModal } = useModal();

  const isDisabled = !statusesConfigs;

  const handleChange = (value: unknown) => {
    setSelectedStatus(value as string);
    openModal();
  };

  return (
    <>
      <StyledSelect
        disabled={isDisabled || !isEditable}
        $status={status}
        value={status}
        options={statusesConfigs ?? []}
        placeholder={getStatuses(t, status)}
        onChange={handleChange}
      />
      <ChangeStatusModal
        isOpened={isOpened}
        handleClose={closeModal}
        handleRequest={isDisabled ? noop : handleRequest}
        withStatus={!!withStatus}
        selectedStatus={selectedStatus}
      />
    </>
  );
};

export default StatusChangeBlock;
