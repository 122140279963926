import { TFunction } from 'i18next';
import { requestsTypesConfig } from '@/modules/Requests/constants/common';
import { sortAlphabeticalBy } from './arrayActions';

const getRequestTypeOptions = (t: TFunction) => {
  const requestsTypeConfig = sortAlphabeticalBy(
    requestsTypesConfig,
    'value',
  ).map((v) => {
    return {
      label: t(v.label),
      value: v.value,
    };
  });

  return requestsTypeConfig;
};

export default getRequestTypeOptions;
