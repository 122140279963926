import styled from 'styled-components';
import { COLORS } from '@/theme';
import {
  InternetGhostIcon,
  MessageGhostIcon,
  TelephoneGhostIcon,
} from '@/icons';
import { Link } from '@/ui';

export const ContactsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
`;

export const StyledMessageIcon = styled(MessageGhostIcon)`
  transform: scale(1) !important;
  cursor: pointer;
  :hover {
    path {
      fill: ${COLORS.CORN_FLOWER_BLUE};
    }
  }
`;

export const StyledTelephoneIcon = styled(TelephoneGhostIcon)`
  transform: scale(1) !important;
  cursor: pointer;
  :hover {
    path {
      fill: ${COLORS.CORN_FLOWER_BLUE};
    }
  }
`;

export const StyledInternetIcon = styled(InternetGhostIcon)`
  transform: scale(1) !important;
  cursor: pointer;
  :hover {
    path {
      fill: ${COLORS.CORN_FLOWER_BLUE};
    }
  }
`;

export const StyledLink = styled(Link)`
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
`;
