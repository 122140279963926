import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { MilestonesState, MILESTONES_SLICE_NAME } from './models';

export const milestonesSliceSelector = (state: RootState): MilestonesState =>
  state[MILESTONES_SLICE_NAME];

export const milestonesSelector = createSelector(
  milestonesSliceSelector,
  (state: MilestonesState) => state.milestones,
);

export const milestonesIsLoading = createSelector(
  milestonesSliceSelector,
  (state: MilestonesState) => state.isLoading,
);
