import { SocialChannels } from '@/models/socialChannel.model';

const buildAllowedSocialChannels = (
  channels: SocialChannels[],
  value: SocialChannels,
) => {
  if (!channels.includes(value)) {
    channels.push(value);
  }
  return channels;
};

export default buildAllowedSocialChannels;
