// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledInfoWrapper,
  StyledTitle,
  StyledText,
  CardWrapper,
  StyledBadge,
} from './AffiliateWithdrawalHistoryStyles';

// Models
import { IWithdrawalHistory } from '@/models/sharedProfile.model';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Helpers
import { getDateString, numberWithCommas } from '@/utils';
import { DATE_FORMATS } from '@/constants';

interface IWithdrawalHistoryCard {
  data: IWithdrawalHistory;
}

const WithdrawalHistoryCard = ({ data }: IWithdrawalHistoryCard) => {
  const { t } = useTranslation(['profile']);
  return (
    <CardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('affiliate_withdrawal_history_date')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {getDateString(data?.created ?? '', DATE_FORMATS.DATE_WITH_DOTS)}
        </StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('affiliate_withdrawal_history_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {data?.campaignTitle ?? data?.fundingRoundTitle}
        </StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('affiliate_withdrawal_history_reward')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          ${numberWithCommas(data?.totalAffiliateWithdrawalAmount)}
        </StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('affiliate_withdrawal_history_wallet')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.walletAddress}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('affiliate_withdrawal_history_status')}</StyledTitle>
        </MobileOnly>
        <StyledBadge $status={data?.status}>
          {t(`affiliate_withdrawal_history_status_${data?.status}`)}
        </StyledBadge>
      </StyledInfoWrapper>
    </CardWrapper>
  );
};

export default WithdrawalHistoryCard;
