import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout } from '@/ui';

export const StyledAffiliateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const StyledInfoWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
`;

export const StyledStatistics = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    gap: 2rem;
  }
`;

export const StyledStatisticsWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
  flex-direction: row;
  max-width: 100%;

  @media screen and ${DEVICES.LAPTOP_S} {
    max-width: 30%;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  ${pickThemeFontStyles('12', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'BOLD')};
  }

  :first-child {
    justify-content: flex-start;
  }
`;
