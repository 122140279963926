// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledInfo,
  StyledDetails,
  StyledForm,
  StyledFormItem,
  StyledButtonWrapper,
  StyledButton,
  Wrapper,
} from './GeneralInfoEditBlockStyles';
import { StyledInput, StyledSelect } from '@/ui';
import { IFormInstance } from '@/components';

// Models
import { IPartner } from '@/models/partnersList.model';

// Modules
import {
  ContactInfoBlock,
  FormOfAddressConfig,
  getCountryOptions,
} from '@/modules/SharedProfile';

// Helpers | Hooks
import { GeneralInfoFieldsNames } from '../../helpers';
import { useGetFormValidationRules } from '../../hooks';
import { AccountStatuses, countries } from '@/constants';

interface IGeneralInfoEditBlock {
  formInstance: IFormInstance;
  partnerDetails: IPartner | null;
  handleSubmit: (values: IPartner) => void;
}

const GeneralInfoEditBlock = ({
  partnerDetails,
  handleSubmit,
  formInstance,
}: IGeneralInfoEditBlock) => {
  const { t } = useTranslation(['partnersList']);
  const isDisabled = partnerDetails?.status === AccountStatuses.BLOCKED;
  const validationRules = useGetFormValidationRules();

  return (
    <StyledForm
      onFinish={handleSubmit}
      form={formInstance}
      initialValues={partnerDetails || undefined}
    >
      <Wrapper>
        <StyledInfo>
          <StyledDetails>
            <StyledFormItem
              name={GeneralInfoFieldsNames.FIRST_NAME}
              rules={validationRules[GeneralInfoFieldsNames.FIRST_NAME]}
              label={t('hunters_general_info_first_name_title')}
            >
              <StyledInput
                defaultValue={partnerDetails?.firstName}
                disabled={isDisabled}
              />
            </StyledFormItem>
          </StyledDetails>

          <StyledDetails>
            <StyledFormItem
              name={GeneralInfoFieldsNames.LAST_NAME}
              rules={validationRules[GeneralInfoFieldsNames.LAST_NAME]}
              label={t('hunters_general_info_last_name_title')}
            >
              <StyledInput
                defaultValue={partnerDetails?.lastName}
                disabled={isDisabled}
              />
            </StyledFormItem>
          </StyledDetails>
          <StyledDetails>
            <StyledFormItem
              name={GeneralInfoFieldsNames.FORM_OF_ADDRESS}
              label={t('hunters_general_info_person_title')}
              rules={validationRules[GeneralInfoFieldsNames.FORM_OF_ADDRESS]}
            >
              <StyledSelect
                options={FormOfAddressConfig}
                disabled={isDisabled}
              />
            </StyledFormItem>
          </StyledDetails>

          <StyledDetails>
            <StyledFormItem
              name={GeneralInfoFieldsNames.ADDRESS}
              rules={validationRules[GeneralInfoFieldsNames.ADDRESS]}
              label={t('hunters_general_info_address_title')}
            >
              <StyledInput
                defaultValue={partnerDetails?.address}
                disabled={isDisabled}
              />
            </StyledFormItem>
            <StyledFormItem
              name={GeneralInfoFieldsNames.COUNTRY}
              label={t('hunters_general_info_country_title')}
            >
              <StyledSelect
                options={getCountryOptions(countries)}
                defaultValue={partnerDetails?.country}
                disabled={isDisabled}
              />
            </StyledFormItem>
            <StyledFormItem
              name={GeneralInfoFieldsNames.CITY}
              rules={validationRules[GeneralInfoFieldsNames.ADDRESS]}
              label={t('hunters_general_info_city_title')}
            >
              <StyledInput
                defaultValue={partnerDetails?.city}
                disabled={isDisabled}
              />
            </StyledFormItem>
          </StyledDetails>
        </StyledInfo>
      </Wrapper>

      <ContactInfoBlock formInstance={formInstance} />

      <StyledButtonWrapper>
        <StyledButton type="primary" htmlType="submit">
          {t('edit_save')}
        </StyledButton>
      </StyledButtonWrapper>
    </StyledForm>
  );
};

export default GeneralInfoEditBlock;
