// Globals
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';

// Components
import {
  StyledWrapper,
  StyledButton,
  StyledTextAreaWrapper,
  StyledDatePicker,
} from './PostsContestRequestDetailsStyles';
import { Modal, ModalSize, showErrorToast } from '@/components';
import { Title } from '@/ui';

// Models
import { PostsStatus } from '@/models/requests.model';

// Helpers
import { getUserId } from '@/utils';
import { useAppDispatch } from '@/hooks';
import {
  getRequestDetails,
  patchPostsContestStatus,
} from '../../feature/actionCreators';

interface IApproveUrlModal {
  isOpened: boolean;
  handleClose: () => void;
  id?: string;
  postId?: string;
}

const ApproveUrlModal = ({
  isOpened,
  handleClose,
  postId,
  id,
}: IApproveUrlModal) => {
  const { t } = useTranslation(['requestsList']);
  const [date, setDate] = useState<Dayjs | null | undefined>(null);
  const dispatch = useAppDispatch();

  const handleDate = async (v: Dayjs | null) => {
    setDate(v);
  };

  const handleSubmit = async () => {
    try {
      await dispatch(
        patchPostsContestStatus({
          id: getUserId(id ?? ''),
          urlId: postId ?? '',
          status: PostsStatus.APPROVED,
          publishDate: date,
        }),
      ).unwrap();
      handleClose();
    } catch (e: unknown) {
      showErrorToast({ message: (e as Error).message });
    }
    dispatch(getRequestDetails(getUserId(id ?? '')));
  };

  return (
    <Modal visible={isOpened} onCancel={handleClose} size={ModalSize.LARGE}>
      <Title $type="h3">{t('requests_modal_approve_title')}</Title>
      <StyledTextAreaWrapper>
        <StyledDatePicker value={date} onChange={handleDate} />
        <StyledWrapper>
          <StyledButton type="primary" onClick={handleSubmit} disabled={!date}>
            {t('requests_approve_status')}
          </StyledButton>
        </StyledWrapper>
      </StyledTextAreaWrapper>
    </Modal>
  );
};

export default ApproveUrlModal;
