import { ICryptoCurrency, INetworkConfig } from '@/models/dataLists.model';
import { isDev } from '../../../config/env';
import { CryptoCurrenciesEnum, WalletNetworksEnum } from '@/models/web3.model';

export const networksConfig: Record<WalletNetworksEnum, INetworkConfig> = {
  [WalletNetworksEnum.POLYGON]: {
    name: 'Polygon',
    symbol: WalletNetworksEnum.POLYGON,
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Polygon_Blockchain_Matic_Logo.svg/504px-Polygon_Blockchain_Matic_Logo.svg.png',
  },
  [WalletNetworksEnum.SEPOLIA]: {
    name: 'Sepolia',
    symbol: WalletNetworksEnum.SEPOLIA,
    image: 'https://avatars.githubusercontent.com/u/107390249?v=4',
  },
  [WalletNetworksEnum.ETHEREUM]: {
    name: 'Ethereum',
    symbol: WalletNetworksEnum.ETHEREUM,
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  },
};

export const currenciesConfig: ICryptoCurrency[] = [
  {
    name: 'USDC',
    symbol: CryptoCurrenciesEnum.USDC,
    image: 'https://s2.coinmarketcap.com/static/img/coins/200x200/3408.png',
  },
  {
    name: 'USDT',
    symbol: CryptoCurrenciesEnum.USDT,
    image: 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png',
  },
  // {
  //   name: 'ETH',
  //   symbol: CryptoCurrenciesEnum.ETH,
  //   image: 'https://s2.coinmarketcap.com/static/img/coins/200x200/1027.png',
  // },
  ...(isDev
    ? [
        {
          name: 'Sepolia',
          symbol: CryptoCurrenciesEnum.SEPOLIA,
          image: 'https://avatars.githubusercontent.com/u/107390249?v=4',
        },
      ]
    : []),
];
