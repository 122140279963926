// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  Input,
  StyledTitle,
  StyledBlockWrapper,
  SettingsWrapper,
  StyledFormItem,
} from '../Styles';
import { FormItemGroup, NestedFormItem } from '@/components';

// Models
import { FundingRoundsDataTypes } from '@/models/settings.model';

// Helpers | Hooks
import { SettingsFieldsNames } from '../../helpers';
import { useGetFormValidationRules } from '../../hooks';

interface IFundingRoundSettings {
  isEditActive: boolean;
}

const FundingRoundSettings = ({ isEditActive }: IFundingRoundSettings) => {
  const { t } = useTranslation(['settings']);
  const validationRules = useGetFormValidationRules();

  return (
    <StyledBlockWrapper>
      <StyledTitle>{t('settings_funding_round_title')}</StyledTitle>
      <FormItemGroup prefix={FundingRoundsDataTypes.PARTICIPATION}>
        <SettingsWrapper>
          <NestedFormItem
            name={SettingsFieldsNames.PARTICIPATION_MAX}
            rules={validationRules[SettingsFieldsNames.PARTICIPATION_MAX]}
            label={t('settings_funding_participation_max')}
          >
            <Input disabled={!isEditActive} type="number" />
          </NestedFormItem>
          <NestedFormItem
            name={SettingsFieldsNames.PARTICIPATION_MIN}
            rules={validationRules[SettingsFieldsNames.PARTICIPATION_MIN]}
            label={t('settings_funding_participation_min')}
          >
            <Input disabled={!isEditActive} type="number" />
          </NestedFormItem>
        </SettingsWrapper>
      </FormItemGroup>
      <StyledFormItem
        name={
          SettingsFieldsNames.FUNDING_ROUND_MAXIMUM_PARTICIPATION_FOR_NFT_HOLDERS
        }
        rules={
          validationRules[
            SettingsFieldsNames
              .FUNDING_ROUND_MAXIMUM_PARTICIPATION_FOR_NFT_HOLDERS
          ]
        }
        label={t('settings_max_participation_nft_folders')}
      >
        <Input disabled={!isEditActive} type="number" />
      </StyledFormItem>
    </StyledBlockWrapper>
  );
};

export default FundingRoundSettings;
