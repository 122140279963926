import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledArrowRightIcon, StyledButton } from './GoBackButtonStyles';

export interface IGoBackButton {
  children: React.ReactNode;
}

const GoBackButton = ({ children }: IGoBackButton): JSX.Element => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <StyledButton type={'primary'} onClick={goBack}>
      <StyledArrowRightIcon />
      {children}
    </StyledButton>
  );
};

export default GoBackButton;
