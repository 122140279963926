import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';

export const StyledHeaderBlock = styled(BlockLayout)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  ${pickThemeFontStyles('14', '20', 'SEMI_BOLD')};
  color: ${COLORS.LIQUORICE_BLACK};
`;

export const StyledRefundTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  :last-child {
    align-items: center;
  }
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  width: 100%;
  flex-direction: column;
  min-width: 0;

  @media screen and ${DEVICES.LAPTOP_S} {
    :last-child {
      align-items: center;
    }
  }
`;

export const StyledRefundInfoWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  width: 100%;
  flex-direction: column;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  :last-child {
    align-items: center;
  }
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
`;

export const StyledText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const CardWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 4rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-bottom: 7rem;
  }
`;

export const StyledButton = styled(Button)`
  &&& {
    width: 8rem;
    height: 1.5rem;
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
  }
`;

export const InvitedUsersWrapper = styled(BlockLayout)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  margin: 1rem 0;
`;

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
`;

export const StyledDeliveryTitle = styled.div`
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  margin-bottom: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('16', '25', 'BOLD')};
    margin-bottom: 1rem;
  }
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  gap: 0.5rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
`;
