// Globals
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useWatch } from 'antd/es/form/Form';

// Components
import {
  ResourcesContainer,
  StyledDownloadButton,
  StyledIconWrapper,
  StyledUploadFilePlaceholder,
  StyledName,
  StyledDocumentWrapper,
  ResourcesWrapper,
} from './PresentationStyles';
import { CrossInCircleIcon, TextFileIcon, UploadIcon } from '@/icons';
import { IFormInstance } from '@/components';

// Modules
import {
  FileUpload,
  IFileUpload,
  MAX_RESOURCE_SIZE,
  RESOURCES_MIME_TYPES,
  uploadFundingRoundFile,
} from '@/modules/Uploaders';

// Models
import { IAdditionalResources } from '@/models/fundingRound.model';

// helpers
import { useAppDispatch } from '@/hooks';
import { getFileType, getUserId } from '@/utils';
import { IFundingRoundFields } from '../../types';
import { updateFundingDetails } from '../../feature/actionCreators';

interface IFileListUploader extends Omit<IFileUpload, 'children'> {
  formInstance: IFormInstance;
}

const FileListUploader = ({ formInstance }: IFileListUploader) => {
  const { t } = useTranslation(['fundingRound']);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const resourcesValue = useWatch(IFundingRoundFields.RESOURCES, formInstance);

  const handleChange = useCallback(
    async (value: File | Blob | null) => {
      const handledValue =
        value &&
        (await dispatch(
          uploadFundingRoundFile({
            fileType: getFileType(value as File),
            value: value as File,
            targetId: getUserId(id ?? ''),
          }),
        ).unwrap());
      const resources = [
        ...(formInstance.getFieldValue(IFundingRoundFields.RESOURCES) ?? []),
        {
          link: handledValue,
          fileName: (value as File).name,
        },
      ];

      formInstance.setFieldValue(IFundingRoundFields.RESOURCES, resources);
      dispatch(
        updateFundingDetails({
          additionalResources: resources,
          id: getUserId(id ?? ''),
        }),
      );
    },
    [formInstance],
  );

  const handleRemove = useCallback(
    async (link: string) => {
      const resources = [
        ...(
          formInstance.getFieldValue(IFundingRoundFields.RESOURCES) ?? []
        ).filter((item: IAdditionalResources) => item.link !== link),
      ];

      formInstance.setFieldValue(IFundingRoundFields.RESOURCES, resources);
      dispatch(
        updateFundingDetails({
          additionalResources: resources,
          id: getUserId(id ?? ''),
        }),
      );
    },
    [formInstance],
  );

  const mappedDocuments = useMemo(() => {
    return resourcesValue?.map((item: IAdditionalResources) => (
      <ResourcesContainer key={item?.link}>
        <StyledIconWrapper onClick={() => handleRemove(item?.link as string)}>
          <CrossInCircleIcon />
        </StyledIconWrapper>
        <StyledDocumentWrapper>
          <StyledDownloadButton href={item?.link} target="blanc">
            <TextFileIcon />
          </StyledDownloadButton>
        </StyledDocumentWrapper>
        <StyledName>{item?.fileName}</StyledName>
      </ResourcesContainer>
    ));
  }, [resourcesValue]);

  return (
    <>
      <FileUpload
        onChange={handleChange}
        fileMaxSize={MAX_RESOURCE_SIZE}
        acceptance={RESOURCES_MIME_TYPES}
        maxCount={10}
        withPreview={false}
        showList={false}
      >
        <StyledUploadFilePlaceholder>
          <UploadIcon />
          {t('file_upload_placeholder')}
        </StyledUploadFilePlaceholder>
      </FileUpload>
      <ResourcesWrapper>{mappedDocuments}</ResourcesWrapper>
    </>
  );
};

export default FileListUploader;
