import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiError } from '@/models/apiError.model';
import { SETTINGS_SLICE_NAME } from './models';
import { formatApiError } from '@/utils';
import { SettingsAPI } from '@/api';
import {
  IAverageSocialData,
  IGetNFTCreditsBucketHistoryDTO,
  INFTCreditsBucketData,
  INftCreditsBucketHistoryResponse,
  ISettingsDTO,
  AutomaticApprovalDTO,
  ITopBarSettingsDTO,
} from '@/models/settings.model';

export const getSettings = createAsyncThunk<
  ISettingsDTO,
  void,
  { serializedErrorType: IApiError }
>(
  `${SETTINGS_SLICE_NAME}/getSettings`,
  async (data) => {
    const response = await SettingsAPI.getSettings(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getTopBarsSettings = createAsyncThunk<
  ITopBarSettingsDTO,
  void,
  { serializedErrorType: IApiError }
>(
  `${SETTINGS_SLICE_NAME}/getTopBarsSettings`,
  async (data) => {
    const response = await SettingsAPI.getTopBarsSettings(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getSocialsThresholdsSettings = createAsyncThunk<
  AutomaticApprovalDTO,
  void,
  { serializedErrorType: IApiError }
>(
  `${SETTINGS_SLICE_NAME}/getSocialsThresholdsSettings`,
  async (data) => {
    const response = await SettingsAPI.getSocialsThresholdsSettings(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const postSettings = createAsyncThunk<
  ISettingsDTO,
  ISettingsDTO,
  { serializedErrorType: IApiError }
>(
  `${SETTINGS_SLICE_NAME}/postSettings`,
  async (data) => {
    const response = await SettingsAPI.postSettings(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const postTopBarsSettings = createAsyncThunk<
  ITopBarSettingsDTO,
  ITopBarSettingsDTO,
  { serializedErrorType: IApiError }
>(
  `${SETTINGS_SLICE_NAME}/postTopBarsSettings`,
  async (data) => {
    const response = await SettingsAPI.postTopBarsSettings(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const postSocialsThresholdsSettings = createAsyncThunk<
  AutomaticApprovalDTO,
  AutomaticApprovalDTO,
  { serializedErrorType: IApiError }
>(
  `${SETTINGS_SLICE_NAME}/postSocialsThresholdsSettings`,
  async (data) => {
    const response = await SettingsAPI.postSocialsThresholdsSettings(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getAverageSocialData = createAsyncThunk<
  IAverageSocialData,
  void,
  { serializedErrorType: IApiError }
>(
  `${SETTINGS_SLICE_NAME}/getAverageSocialData`,
  async () => {
    const response = await SettingsAPI.getAverageSocialData();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const postAverageSocialData = createAsyncThunk<
  IAverageSocialData,
  IAverageSocialData,
  { serializedErrorType: IApiError }
>(
  `${SETTINGS_SLICE_NAME}/postAverageSocialData`,
  async (data) => {
    const response = await SettingsAPI.postAverageSocialData(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getNFTCreditsFundingBucket = createAsyncThunk<
  INFTCreditsBucketData,
  void,
  { serializedErrorType: IApiError }
>(
  `${SETTINGS_SLICE_NAME}/getNFTCreditsFundingBucket`,
  async () => {
    const response = await SettingsAPI.getNFTCreditsFundingBucket();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getNFTCreditsBucketHistory = createAsyncThunk<
  INftCreditsBucketHistoryResponse,
  IGetNFTCreditsBucketHistoryDTO,
  { serializedErrorType: IApiError }
>(
  `${SETTINGS_SLICE_NAME}/getNFTCreditsBucketHistory`,
  async (data) => {
    const response = await SettingsAPI.getNFTFundingBucketHistory({
      ...data,
      limit: 10,
    });
    return response.data;
  },
  { serializeError: formatApiError },
);

export const postNFTCreditsFundingBucket = createAsyncThunk<
  INFTCreditsBucketData,
  INFTCreditsBucketData,
  { serializedErrorType: IApiError }
>(
  `${SETTINGS_SLICE_NAME}/postNFTCreditsFundingBucket`,
  async (data) => {
    const response = await SettingsAPI.postNFTCreditsFundingBucket(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
