import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { ArrowRightIcon } from '@/icons';
import { Button } from '@/ui';

export const StyledButton = styled(Button)`
  display: flex;
  margin-top: 2rem;
  &&& {
    max-width: 6.5rem;
    height: 2rem;
    background: ${COLORS.SOLITUDE_GRAY};
    color: ${COLORS.DARK_BLUE};
    ${pickThemeFontStyles('12', '12', 'SEMI_BOLD')};
    align-items: center;
  }
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
  margin-right: 0.5rem;
  transform: rotate(180deg);
`;
