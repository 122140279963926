import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { LOGS_SLICE_NAME, LogsState } from './models';

export const logsSliceSelector = (state: RootState): LogsState =>
  state[LOGS_SLICE_NAME];

export const logsIsLoading = createSelector(
  logsSliceSelector,
  (state: LogsState) => state.isLoading,
);
