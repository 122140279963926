import styled from 'styled-components';
import { COLORS, pickThemeFontSize } from '@/theme';
import { TextArea } from '@/ui';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledTitle = styled.div`
  font-size: ${pickThemeFontSize('16')};
  color: ${COLORS.DARKEST_GRAY};
  margin: 1rem 0 1rem 0;
`;

export const StyledPreview = styled.div`
  background: ${COLORS.LIGHTEST_GRAY};
  border-radius: 0.5rem;
  padding: 1rem;
`;

export const StyledTextArea = styled(TextArea)`
  width: 100%;
`;
