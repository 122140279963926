/* eslint-disable @typescript-eslint/no-explicit-any */

import { ICommunity, IGeographies } from '@/models/dataLists.model';

export const sortByLabel = (arr: any[]) => {
  return arr.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

export const sortBy = (arr: any[], key: string) => {
  return arr.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};

export const sortAlphabeticalBy = (arr: any[], key: string) => {
  return arr.sort((a, b) => a[key]?.localeCompare(b[key]));
};

export const getSumBy = (arr: any[], key: string) =>
  arr.reduce((acc, current) => acc + Number(current[key]), 0);

export const objectToArray = (obj: any) =>
  Object.keys(obj).map((key) => ({
    ...obj[key],
  }));

export const arrayToObjectBy = (arr: any[], key: string) =>
  arr.reduce((acc, current) => {
    return { ...acc, [current[key]]: { ...current } };
  }, {});

export const findById = (arr: any[], id: string) =>
  arr.find((item) => item.id === id);

export const isObjEmpty = (ogj: any) => {
  return Object.keys(ogj).length === 0;
};

export const findByName = (arr: any[], name: string) =>
  arr.find((item) => item.name.toLowerCase() === name.toLowerCase());

export const decomposeBy = (basePool: number, arr: any[], key: string) => {
  if (basePool === 0 || !arr.length) {
    return;
  }

  const isIncremented = basePool > 0;
  const increment = isIncremented ? 1 : -1;

  let localPool = basePool;
  let res = [];

  for (let i = 0; i < Math.abs(basePool); i++) {
    arr.sort((a, b) =>
      isIncremented
        ? Number(a[key]) - Number(b[key])
        : Number(b[key]) - Number(a[key]),
    );

    for (const item of arr) {
      // If value can be added to item add and break from cycle :
      if (Number(item[key]) + increment >= 0) {
        item[key] = Number(item[key]) + increment;
        localPool = localPool - increment;
        break;
      }
    }

    res = arr;
  }

  // Return because of no ability to decompose rest of pool :
  if (localPool !== 0) {
    return undefined;
  }

  return res;
};

export const getByValue = (
  arr: ICommunity[] | IGeographies[],
  currentValue: unknown,
) => {
  const value = arr.find((o) => o.value === currentValue);
  return value;
};
