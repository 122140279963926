// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledCardWrapper,
  StyledText,
  IconWrap,
  StyledValueBlock,
  StyledRow,
  StyledCount,
  StyledIncrease,
} from './StatisticsCardStyles';
import { DiagramIcon } from '@/icons';

// Models
import { IEntityGeneralStatistic } from '@/models/dashboard.model';

// Helpers
import { GeneralStatisticTypes } from '@/modules/Dashboard/constants/common';

interface IStatisticsCard {
  data?: IEntityGeneralStatistic;
  type: GeneralStatisticTypes;
}

const StatisticsCard = ({ data, type }: IStatisticsCard) => {
  const { t } = useTranslation(['dashboard']);

  return (
    <StyledCardWrapper>
      <IconWrap $type={type}>
        <DiagramIcon />
      </IconWrap>
      <StyledValueBlock>
        <StyledText>{t(`dashboard_${type}`)}</StyledText>
        <StyledRow>
          <StyledCount>{data?.totalCount}</StyledCount>
          <StyledIncrease>{`(+${data?.increaseStatistics}%)`}</StyledIncrease>
        </StyledRow>
      </StyledValueBlock>
    </StyledCardWrapper>
  );
};

export default StatisticsCard;
