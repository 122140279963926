import { ISocialChannel } from '@/models/projects.model';
import { getNormalizedSocialLink } from '@/modules/SharedProfile';

const normalizedSocialChannels = (channels?: ISocialChannel[]) => {
  const normalizedProjectChannels = channels?.map((item) => {
    return {
      link: getNormalizedSocialLink(item.link ?? '', item.name ?? ''),
      name: item.name,
    };
  });
  return normalizedProjectChannels;
};

export default normalizedSocialChannels;
