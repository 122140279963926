import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { PROJECTS_SLICE_NAME, ProjectsState } from './models';

export const projectsSliceSelector = (state: RootState): ProjectsState =>
  state[PROJECTS_SLICE_NAME];

export const selectProjectsIsLoading = createSelector(
  projectsSliceSelector,
  (state: ProjectsState) => state.isLoading,
);

export const selectProjectDetails = createSelector(
  projectsSliceSelector,
  (state: ProjectsState) => state.projectsDetails,
);
