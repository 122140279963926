// Globals
import React from 'react';

// Components
import { StyledTableWrap } from './ContentplanTableStyles';
import HeaderRow from './HeaderRow';
import TableRow from './TableRow';

// Models
import { IAverageSocialDataItem } from '@/models/dataLists.model';

// Helpers
import { ContentDistributionDataTypes } from '../../helpers/types';

interface IContentDistributionTable {
  totalAverageDelivery: IAverageSocialDataItem;
  weeklyAverageDelivery: IAverageSocialDataItem;
  totalImpressions: IAverageSocialDataItem;
}

const ContentDistributionTable = ({
  totalAverageDelivery,
  weeklyAverageDelivery,
  totalImpressions,
}: IContentDistributionTable) => {
  return (
    <StyledTableWrap>
      <HeaderRow />
      <TableRow
        type={ContentDistributionDataTypes.WEEKLY_DELIVERY}
        title={'campaign_delivery_weekly'}
        data={weeklyAverageDelivery}
      />
      <TableRow
        type={ContentDistributionDataTypes.TOTAL_DELIVERY}
        title={'campaign_delivery_total'}
        data={totalAverageDelivery}
      />
      <TableRow
        type={ContentDistributionDataTypes.IMPRESSIONS}
        title={'campaign_impression'}
        data={totalImpressions}
      />
    </StyledTableWrap>
  );
};

export default ContentDistributionTable;
