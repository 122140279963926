import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { pickThemeFontStyles } from '@/theme';

export const StyledNavLink = styled(NavLink)`
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  padding: 0;
  height: auto;

  &: disabled;
`;
