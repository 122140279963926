import { Progress } from 'antd';
import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';

const progressHeight = '0.75rem';

export const StyledProgress = styled(Progress)`
  &&& {
    height: ${progressHeight};

    & .ant-progress-bg {
      background: linear-gradient(
        89.76deg,
        ${COLORS.BRIGHT_ORANGE} 2.3%,
        ${COLORS.HIGHLIGHTER_ORANGE} 97.85%
      );
      height: ${progressHeight} !important;
    }

    & .ant-progress-inner {
      background: ${COLORS.ALICE_BLUE_LIGHT};
      height: ${progressHeight};
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const PercentsWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.625rem;
`;

export const StyledPercent = styled.div`
  &&& {
    ${pickThemeFontStyles('12', '20', 'NORMAL')}
    color: ${COLORS.DARK_BLUE}
  }
`;
