// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledWrapper,
  StyledBlock,
  StyledSubTitle,
  StyledInfo,
  StyledHashTag,
  StyledHeader,
  StyledUl,
} from './CampaignInfoTabStyles';
import SocialChannelsInfo from './SocialChannelsInfo';
import CampaignInfo from '../CampaignInfo/CampaignInfo';
import CampaignResources from '../CampaignResources/CampaignResources';

// Models
import { ICampaign } from '@/models/campaigns.model';

interface ICampaignInfoTab {
  data: ICampaign | null;
}

const CampaignInfoTab = ({ data }: ICampaignInfoTab) => {
  const { t } = useTranslation(['campaigns']);

  return (
    <StyledWrapper>
      <CampaignInfo data={data} />

      <StyledBlock>
        <StyledHeader>
          <StyledSubTitle>{t('campaign_briefing_title')}</StyledSubTitle>
        </StyledHeader>
        <StyledInfo>
          <StyledSubTitle>{t('campaign_actions_title')}</StyledSubTitle>
          <StyledUl>
            <li>
              <StyledHashTag>
                {data?.hashTag?.startsWith('#')
                  ? data?.hashTag
                  : `#${data?.hashTag}`}
              </StyledHashTag>{' '}
              - {t('campaign_hashtag_info')}
            </li>
            <li>{t('campaign_posts_info')}</li>
          </StyledUl>
        </StyledInfo>

        <SocialChannelsInfo data={data} />

        <CampaignResources data={data} />
      </StyledBlock>
    </StyledWrapper>
  );
};

export default CampaignInfoTab;
