// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledFRBadge,
  StyledFRBadgeText,
  StyledText,
  StyledOrangeCornerBadgeIcon,
} from './FundingRoundSavingBadgeStyles';

interface IFundingRoundSavingBadge {
  fundingSavings: number;
}

const FundingRoundSavingBadge = ({
  fundingSavings,
}: IFundingRoundSavingBadge) => {
  const { t } = useTranslation(['common']);
  return (
    <>
      <StyledFRBadge>
        <StyledOrangeCornerBadgeIcon />
      </StyledFRBadge>
      <StyledFRBadgeText>
        <StyledText>{fundingSavings}%</StyledText>
        <StyledText>{t('off', { ns: 'common' })}</StyledText>
      </StyledFRBadgeText>
    </>
  );
};

export default FundingRoundSavingBadge;
