export const AUTHORIZATION_TOKEN_STORAGE_KEY = 'auth_token';
export const CURRENT_USER_EMAIL_STORAGE_KEY = 'current_user';
export const KYC_SESSION_ID = 'kyc_session_id';
export const KYB_SESSION_ID = 'kyb_session_id';
export const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';
export const AUTHORIZATION_TOKEN_EXPIRES = 24;

export const RESPONSE_ERRORS = {
  NOT_CONFITMED_USER: 'User is not confirmed.',
};

export enum ResponseStatusCodes {
  SUCCESS = 200,
  ACCEPTED = 202,
  TEMPORARY_REDIRECT = 307,
  BAD_REQUEST = 400,
  NOT_AUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  SOMETHING_WRONG = 418,
  SERVER_ERROR = 500,
}

export const nameRegex = /^[a-z ,.'-]+$/i;
export const numbersOnlyRegex = /^[0-9.]+$/;
export const zipCodeRegex = /^\d{5}(?:-\d{4})?$/;
export const hashTegRegex = /^[a-zA-Z0-9_#]\w+$/;
export const noWhiteSpaceRegex = /^\S*$/;
export const phoneRegex = /(\d)+/;
export const urlRegex =
  /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g;
export const passwordRegex =
  /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\W)(?=.{8,}$).*$/gm;

export const emailRegex = /^[\w.-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]+)+$/;
export const otpCodeRegex = /^\d{6}$/;
export const usernameFromUrlRegex = /(twitter|x)\.com\/([a-zA-Z0-9_]+)/i;

export const TextLength = {
  titleShort: 45,
  titleMedium: 70,
  veryShort: 100,
  short: 255,
  medium: 500,
  long: 1000,
  semiLarge: 1500,
  large: 2500,
};
