// Global
import React from 'react';

// Components
import { ProfileRequestsDetails, StyledPageWrapper } from '../../components';
import { Spinner } from '@/ui';

// Models
import { IRequestDetails } from '@/models/requests.model';
import { LogEntities } from '@/models/logs.model';
import { AdminActionsEnum } from '@/models/adminsList.model';

// Modules
import { LogContainer } from '@/modules/Logs';
import { usePermissions, usePermissionsRedirect } from '@/modules/Permissions';

interface IProfileRequestContainer {
  request: IRequestDetails | null;
  withVerification?: boolean;
  isLoading: boolean;
}

const ProfileRequestContainer = ({
  request,
  withVerification = false,
  isLoading,
}: IProfileRequestContainer) => {
  const [permissionToEdit] = usePermissions(
    AdminActionsEnum.WRITE_PROFILE_CHANGE_REQUEST,
  );

  usePermissionsRedirect(AdminActionsEnum.WRITE_PROFILE_CHANGE_REQUEST);

  return (
    <StyledPageWrapper>
      <ProfileRequestsDetails
        request={request}
        withVerification={withVerification}
        isLoading={isLoading}
        isEditable={permissionToEdit}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <LogContainer entity={LogEntities.REQUESTS} id={request?.id} />
      )}
    </StyledPageWrapper>
  );
};

export default ProfileRequestContainer;
