// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Spinner, StyledSpinnerWrap } from '@/ui';
import { StyledButtonWrapper, StyledForm, StyledButton } from '../Styles';
import { IFormInstance } from '@/components';
import NFTTopBar from '../TopBar/NFTTopBar';
import TopBar from '../TopBar/TopBar';

// Helpers
import {
  TopBarsSettingsFieldsNames,
  TTopBarsSettingsValues,
} from '../../helpers/types';

interface ITopBarsSettings {
  formInstance: IFormInstance;
  handleSubmit: (values: TTopBarsSettingsValues) => void;
  onValuesChange: (values: TTopBarsSettingsValues) => void;
  initialValues: TTopBarsSettingsValues | null;
  isEditActive: boolean;
}

const TopBarsSettings = ({
  formInstance,
  handleSubmit,
  initialValues,
  onValuesChange,
  isEditActive,
}: ITopBarsSettings) => {
  const { t } = useTranslation(['settings']);

  if (!initialValues)
    return (
      <StyledSpinnerWrap>
        <Spinner />
      </StyledSpinnerWrap>
    );

  return (
    <StyledForm
      onFinish={handleSubmit}
      form={formInstance}
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <NFTTopBar isEditActive={isEditActive} />
      <TopBar
        isEditActive={isEditActive}
        formInstance={formInstance}
        topBarName={TopBarsSettingsFieldsNames.MARKETING}
      />
      <TopBar
        isEditActive={isEditActive}
        formInstance={formInstance}
        topBarName={TopBarsSettingsFieldsNames.FUNDING_ROUND}
      />
      {isEditActive && (
        <StyledButtonWrapper>
          <StyledButton type="primary" htmlType="submit">
            {t('settings_information_save')}
          </StyledButton>
        </StyledButtonWrapper>
      )}
    </StyledForm>
  );
};

export default TopBarsSettings;
