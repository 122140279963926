import styled from 'styled-components';
import { TableTabs } from '@/components';

export const StyledTittleWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  margin-bottom: 2rem;
`;

export const StyledTableTabs = styled(TableTabs)`
  &&& div {
    margin-left: 2rem;
  }
`;
