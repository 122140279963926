// Globals
import React from 'react';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

// Components
import { StyledInput, StyledSelect, Title } from '@/ui';
import { StyledFormItemWrapper, StyledRow } from './TokenomicsStyles';
import {
  StyledWrapper,
  StyledFrom,
  StyledFormItem,
  StyledText,
} from '../Styles/Styles';
import { IFormInstance } from '@/components';

// Models
import { IFundingRound } from '@/models/fundingRound.model';

// Helpers
import { IFundingRoundFields } from '../../types';
import { useGetFormValidationRules } from '../../hooks';
import { phaseOptions } from '../../constants';

interface ITokenomics {
  fundingRound: IFundingRound | null;
  formInstance: IFormInstance;
  handleChange?: (value: IFundingRound, values: IFundingRound) => void;
  isLoading?: boolean;
  disabled?: boolean;
}

const Tokenomics = ({
  formInstance,
  fundingRound,
  disabled,
  handleChange,
}: ITokenomics) => {
  const { t } = useTranslation(['fundingRound']);
  const validationRules = useGetFormValidationRules();

  if (!fundingRound) return <Skeleton />;

  return (
    <StyledWrapper>
      <StyledFrom
        form={formInstance}
        initialValues={fundingRound}
        onValuesChange={handleChange}
        disabled={disabled}
      >
        <StyledFormItemWrapper>
          <Title $type="h3">
            {t('funding_details_tokenimics_funding_phase')}
            <StyledText>
              {t('funding_details_tokenimics_phase_description')}
            </StyledText>
          </Title>
          <StyledFormItem
            name={IFundingRoundFields.TOKEN_NAME}
            rules={validationRules[IFundingRoundFields.TOKEN_NAME]}
            label={t('funding_details_tokenimics_token_name')}
          >
            <StyledInput
              placeholder={t(
                'funding_details_tokenimics_token_name_placeholder',
              )}
            />
          </StyledFormItem>

          <StyledFormItem
            name={IFundingRoundFields.PHASE}
            rules={validationRules[IFundingRoundFields.PHASE]}
            label={t('funding_details_tokenimics_phase')}
          >
            <StyledSelect
              placeholder={t('funding_details_tokenimics_phase_placeholder')}
              options={phaseOptions}
            />
          </StyledFormItem>

          <StyledFormItem
            name={IFundingRoundFields.TOKEN_SUPPLY}
            rules={validationRules[IFundingRoundFields.TOKEN_SUPPLY]}
            label={t('funding_details_tokenimics_supply')}
          >
            <StyledInput
              type="number"
              placeholder={t('funding_details_tokenimics_supply_placeholder')}
            />
          </StyledFormItem>

          <StyledFormItem
            name={IFundingRoundFields.TOTAL_TOKENS_AMOUNT_FOR_SELECTED_PHASE}
            rules={
              validationRules[
                IFundingRoundFields.TOTAL_TOKENS_AMOUNT_FOR_SELECTED_PHASE
              ]
            }
            label={t('funding_details_tokenimics_tokens_phase')}
          >
            <StyledInput
              type="number"
              placeholder={t(
                'funding_details_tokenimics_tokens_phase_placeholder',
              )}
            />
          </StyledFormItem>

          <StyledFormItem
            name={IFundingRoundFields.HARD_CAP}
            rules={validationRules[IFundingRoundFields.HARD_CAP]}
            label={t('funding_details_tokenimics_hard_cap')}
          >
            <StyledInput
              type="number"
              placeholder={t('funding_details_tokenimics_hard_cap_placeholder')}
            />
          </StyledFormItem>

          <StyledFormItem
            name={IFundingRoundFields.FDV}
            rules={validationRules[IFundingRoundFields.FDV]}
            label={t('funding_details_tokenimics_fdv')}
          >
            <StyledInput
              type="number"
              placeholder={t('funding_details_tokenimics_fdv')}
            />
          </StyledFormItem>

          <StyledFormItem
            name={IFundingRoundFields.INITIAL_MARKETING_CAP}
            rules={validationRules[IFundingRoundFields.INITIAL_MARKETING_CAP]}
            label={t('funding_details_tokenimics_initial_marketing_cap')}
          >
            <StyledInput
              type="number"
              placeholder={t(
                'funding_details_tokenimics_initial_marketing_cap_placeholder',
              )}
            />
          </StyledFormItem>

          <StyledFormItem
            name={IFundingRoundFields.TOKEN_AMOUNT_TO_BE_MANAGED}
            rules={
              validationRules[IFundingRoundFields.TOKEN_AMOUNT_TO_BE_MANAGED]
            }
            label={t('funding_details_tokenimics_funding_tokens_to_be_managed')}
          >
            <StyledInput
              type="number"
              placeholder={t(
                'funding_details_tokenimics_funding_tokens_to_be_managed_placeholder',
              )}
            />
          </StyledFormItem>

          <StyledFormItem
            name={IFundingRoundFields.PRICE_PER_TOKEN}
            rules={validationRules[IFundingRoundFields.PRICE_PER_TOKEN]}
            label={t('funding_details_tokenimics_token_price')}
          >
            <StyledInput
              type="number"
              placeholder={t(
                'funding_details_tokenimics_token_price_placeholder',
              )}
            />
          </StyledFormItem>

          <StyledFormItem
            name={IFundingRoundFields.PUBLIC_PRICE_PER_TOKEN}
            label={t('funding_details_tokenimics_public_token_price')}
          >
            <StyledInput
              type="number"
              placeholder={t(
                'funding_details_tokenimics_public_token_price_placeholder',
              )}
            />
          </StyledFormItem>

          <StyledFormItem
            name={IFundingRoundFields.TOKEN_ALLOCATION_PERCENT}
            rules={
              validationRules[IFundingRoundFields.TOKEN_ALLOCATION_PERCENT]
            }
            label={t('funding_details_tokenimics_token_allocation')}
          >
            <StyledInput
              type="number"
              placeholder={t(
                'funding_details_tokenimics_token_allocation_placeholder',
              )}
            />
          </StyledFormItem>
        </StyledFormItemWrapper>

        <StyledFormItemWrapper>
          <Title $type="h3">{t('funding_details_tokenimics_token_sale')}</Title>
          <StyledText>
            {t('funding_details_tokenimics_token_sale_description')}
          </StyledText>
          <StyledRow>
            <StyledFormItem
              name={IFundingRoundFields.TGE_UNLOCK}
              rules={validationRules[IFundingRoundFields.TGE_UNLOCK]}
              label={t('funding_details_tokenimics_tge_unlock')}
            >
              <StyledInput
                placeholder={t(
                  'funding_details_tokenimics_tge_unlock_placeholder',
                )}
              />
            </StyledFormItem>
            <StyledFormItem
              name={IFundingRoundFields.VESTING_PERIOD}
              rules={validationRules[IFundingRoundFields.VESTING_PERIOD]}
              label={t('funding_details_tokenimics_vesting_period')}
            >
              <StyledInput
                placeholder={t(
                  'funding_details_tokenimics_vesting_period_placeholder',
                )}
              />
            </StyledFormItem>
          </StyledRow>
          <StyledFormItem
            name={IFundingRoundFields.TOKEN_SALE_SUMMARY}
            rules={validationRules[IFundingRoundFields.TOKEN_SALE_SUMMARY]}
            label={t('funding_details_tokenimics_token_sale_summary')}
          >
            <StyledInput
              placeholder={t(
                'funding_details_tokenimics_token_sale_summary_placeholder',
              )}
            />
          </StyledFormItem>
        </StyledFormItemWrapper>
      </StyledFrom>
    </StyledWrapper>
  );
};

export default Tokenomics;
