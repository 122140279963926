// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Title } from '@/ui';
import {
  StyledFormWrapper,
  StyledBlockWrapper,
  TitleBlock,
  StyledText,
  FieldsWrapper,
  StyledTitle,
  DetailsBlock,
  StyledTextArea,
  StyledA,
  StyledBadge,
  FormFieldsWrapper,
  StyledForm,
  FormFields,
  DocumentLinksBlock,
} from './FundingContractInformationStyles';
import ContractInformationItems from '../ContractInformationItems/ContractInformationItems';
import { FormItem, IFormInstance } from '@/components';

// Models
import { IFundingContract } from '@/models/fundingRound.model';
import { DocumentTypes } from '@/models/documents.model';

// Helpers | Hooks
import { useGetContractFormValidationRules } from '../../hooks';
import { TextLength } from '@/constants';
import {
  FundingContractFieldsNames,
  TFundingContractValues,
} from '../../types';

interface IFundingContractInformation {
  contractInfo: IFundingContract | null;
  handleSubmit: (
    values: TFundingContractValues,
    allValues: TFundingContractValues,
  ) => void;
  onContractPreview: (type: DocumentTypes) => void;
  formInstance: IFormInstance;
  disabled: boolean;
}

const FundingContractInformation = ({
  formInstance,
  contractInfo,
  handleSubmit,
  onContractPreview,
  disabled,
}: IFundingContractInformation) => {
  const { t } = useTranslation(['fundingRound', 'projectsList']);
  const validationRules = useGetContractFormValidationRules();

  return (
    <StyledFormWrapper>
      <StyledBlockWrapper>
        <DetailsBlock>
          <StyledTitle>{t('funding_details_terms_and_conditions')}</StyledTitle>
          <StyledText>
            {t('funding_details_terms_and_conditions_description')}
          </StyledText>
        </DetailsBlock>
        <DocumentLinksBlock>
          <StyledA
            onClick={() =>
              onContractPreview(DocumentTypes.FUNDING_ROUND_GENERAL_TERMS)
            }
          >
            <StyledBadge>
              {t('funding_details_general_terms_title')}
            </StyledBadge>
          </StyledA>
          <StyledA
            onClick={() =>
              onContractPreview(
                DocumentTypes.FUNDING_ROUND_SALE_AND_PURCHASE_AGREEMENT,
              )
            }
          >
            <StyledBadge>
              {t('funding_details_sale_agreement_title')}
            </StyledBadge>
          </StyledA>
        </DocumentLinksBlock>
      </StyledBlockWrapper>
      <FormFieldsWrapper>
        <StyledForm
          form={formInstance}
          initialValues={contractInfo || {}}
          onValuesChange={handleSubmit}
          disabled={disabled}
        >
          <FieldsWrapper>
            <TitleBlock>
              <Title $type="h2">{t('funding_details_definitions_title')}</Title>
            </TitleBlock>
            <FormFields>
              <FormItem
                name={FundingContractFieldsNames.ADDITIONAL_DEFINITIONS}
                rules={
                  validationRules[
                    FundingContractFieldsNames.ADDITIONAL_DEFINITIONS
                  ]
                }
                label={t('funding_details_additional_definitions_title')}
              >
                <StyledTextArea
                  rows={5}
                  showCount
                  maxLength={TextLength.large}
                />
              </FormItem>
              <FormItem
                name={FundingContractFieldsNames.EXCLUDED_JURISDICTIONS}
                rules={
                  validationRules[
                    FundingContractFieldsNames.EXCLUDED_JURISDICTIONS
                  ]
                }
                label={t('funding_details_additional_jurisdictions_title')}
              >
                <StyledTextArea
                  rows={5}
                  showCount
                  maxLength={TextLength.large}
                />
              </FormItem>
            </FormFields>
          </FieldsWrapper>
          <FieldsWrapper>
            <TitleBlock>
              <Title $type="h2">
                {t('funding_details_additional_clauses_title')}
              </Title>
            </TitleBlock>

            <ContractInformationItems disabled={disabled} />
          </FieldsWrapper>
        </StyledForm>
      </FormFieldsWrapper>
    </StyledFormWrapper>
  );
};

export default FundingContractInformation;
