import { ICampaign } from '@/models/campaigns.model';
import { ApiResponse, httpGet } from './httpClient';
import {
  FundingRoundParticipantsReportResponse,
  ICampaignReportsFilterOptionsResponse,
  ReportAffiliateResponse,
  ICampaignReportsListResponse,
  IFundingReportsListResponse,
  IFundingRoundFilterOptionsResponse,
  IGetCampaignReportsListDTO,
  IGetFundingReportsListDTO,
  ReportsEndpoints,
  TFundingRoundsReportGeneralDetailData,
  CampaignParticipantsReportResponse,
} from '@/models/reports.model';
import { getUserId } from '@/utils';

class RequestsAPI {
  static getFundingReportsList(
    data: IGetFundingReportsListDTO,
  ): ApiResponse<IFundingReportsListResponse> {
    return httpGet<IFundingReportsListResponse>(
      ReportsEndpoints.GET_FUNDING_REPORTS_LIST,
      {
        ...data,
      },
    );
  }
  static getCampaignReportsList(
    data: IGetCampaignReportsListDTO,
  ): ApiResponse<ICampaignReportsListResponse> {
    return httpGet<ICampaignReportsListResponse>(
      ReportsEndpoints.GET_CAMPAIGN_REPORTS_LIST,
      {
        ...data,
      },
    );
  }

  static getFundingRoundReportDetails(
    id: string,
  ): ApiResponse<TFundingRoundsReportGeneralDetailData> {
    return httpGet<TFundingRoundsReportGeneralDetailData>(
      `${ReportsEndpoints.FUNDING_ROUND}/${getUserId(id)}/report/general`,
    );
  }

  static getCampaignReportDetails(id: string): ApiResponse<ICampaign> {
    return httpGet<ICampaign>(
      `${ReportsEndpoints.CAMPAIGN}/${getUserId(id)}/report/general`,
    );
  }

  static getFundingRoundReportParticipants(
    id: string,
  ): ApiResponse<FundingRoundParticipantsReportResponse> {
    return httpGet<FundingRoundParticipantsReportResponse>(
      `${ReportsEndpoints.FUNDING_ROUND}/${getUserId(id)}/report/participants`,
    );
  }

  static getCampaignReportParticipants(
    id: string,
  ): ApiResponse<CampaignParticipantsReportResponse> {
    return httpGet<CampaignParticipantsReportResponse>(
      `${ReportsEndpoints.CAMPAIGN}/${getUserId(id)}/report/participants`,
    );
  }

  static getFundingRoundReportAffiliate(
    id: string,
  ): ApiResponse<ReportAffiliateResponse> {
    return httpGet<ReportAffiliateResponse>(
      `${ReportsEndpoints.FUNDING_ROUND}/${getUserId(id)}/report/affiliate`,
    );
  }

  static getCampaignAffiliate(
    id: string,
  ): ApiResponse<ReportAffiliateResponse> {
    return httpGet<ReportAffiliateResponse>(
      `${ReportsEndpoints.CAMPAIGN}/${getUserId(id)}/report/affiliate`,
    );
  }

  static getFundingRoundReportOptions(): ApiResponse<IFundingRoundFilterOptionsResponse> {
    return httpGet<IFundingRoundFilterOptionsResponse>(
      ReportsEndpoints.FUNDING_ROUND_FILTER_OPTIONS,
    );
  }

  static getCampaignReportOptions(): ApiResponse<ICampaignReportsFilterOptionsResponse> {
    return httpGet<ICampaignReportsFilterOptionsResponse>(
      ReportsEndpoints.CAMPAIGNS_FILTER_OPTIONS,
    );
  }
}

export default RequestsAPI;
