// Globals
import React, { useCallback, useEffect } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

// Components
import { showErrorToast } from '@/components';
import {
  StyledFilters,
  StyledTitle,
  StyledCheckBox,
} from './CampaignsListContainerStyles';
import { StyledPageWrapper } from '@/ui';
import { CampaignsList } from '../../components';

// Modules
import { SearchInfoInput } from '@/modules/SharedProfile';
import { useAppDispatch, useAppState } from '@/hooks';

// Store
import { resetCampaignsList, setCampaignFilters } from '../../feature/slice';
import { campaignsSliceSelector } from '../../feature/selectors';
import { getCampaignsList } from '../../feature/actionCreators';

const CampaignsListContainer = () => {
  const { t } = useTranslation(['campaigns']);
  const dispatch = useAppDispatch();
  const { campaignsListData, campaignFilters, isLoading } = useAppState(
    campaignsSliceSelector,
  );

  const getNextCampaigns = useCallback(
    debounce(async () => {
      if (isLoading || !campaignsListData.hasMore) return;
      const lastItem = campaignsListData?.items?.at(-1);
      const campaignsSearchedList = await dispatch(
        getCampaignsList({
          startId: lastItem?.id,
          ...(campaignFilters ? campaignFilters : {}),
        }),
      ).unwrap();

      campaignsSearchedList?.items?.length === 0 &&
        showErrorToast({
          message: t('empty_data_massage_campaign', { ns: 'profile' }),
        });
    }, 1000),
    [isLoading, campaignsListData.hasMore, dispatch, campaignFilters],
  );

  const handleCampaignSearch = (value: string) => {
    dispatch(
      setCampaignFilters({
        search: value,
      }),
    );
  };

  const handleIsInternal = (e: CheckboxChangeEvent) => {
    dispatch(
      setCampaignFilters({
        isInternal: e.target.checked,
      }),
    );
  };

  useEffect(
    () => (): void => {
      dispatch(resetCampaignsList());
    },
    [dispatch],
  );

  return (
    <StyledPageWrapper>
      <StyledTitle $type="h3">{t('campaigns_list_title')}</StyledTitle>
      <StyledFilters>
        <SearchInfoInput handleSearch={handleCampaignSearch} />
        {t('campaigns_details_show_internals_title')}
        <StyledCheckBox onChange={handleIsInternal} />
      </StyledFilters>
      <CampaignsList
        isLoading={isLoading}
        campaigns={campaignsListData}
        getNextCampaigns={getNextCampaigns}
      />
    </StyledPageWrapper>
  );
};

export default CampaignsListContainer;
