// Globals
import React, { useEffect } from 'react';

// Components
import {
  InfoWrapper,
  CampaignDetailsWrapper,
  SideBlocks,
  StyledLogoWrapper,
  StyledLogoImage,
  StyledImage,
} from './CampaignDetailsStyles';
import { StyledImageWrapper } from '../Styled/Styled';
import { TableTabs } from '@/components';
import CampaignInfoTab from '../CampaignInfoTab/CampaignInfoTab';
import CampaignInformation from '../CampaignInformation/CampaignInformation';
import ProjectDetailsTab from '../ProjectDetailsTab/ProjectDetailsTab';

// Models
import { ICampaign } from '@/models/campaigns.model';

// Hooks
import { useTabs } from '@/hooks';

// Helpers
import { CampaignTabsNames, campaignDetailsTabsConfig } from '../../constants';

interface ICampaignDetails {
  campaign: ICampaign | null;
}

const CampaignDetails = ({ campaign }: ICampaignDetails) => {
  const { activeTab, onChange } = useTabs(
    CampaignTabsNames.CAMPAIGN_INFORMATION,
  );

  const getTabsContent = (activeTab?: string) => {
    switch (activeTab) {
      case CampaignTabsNames.PROJECT_DETAILS:
        return <ProjectDetailsTab data={campaign} />;
      case CampaignTabsNames.CAMPAIGN_INFORMATION:
        return <CampaignInfoTab data={campaign} />;
    }
  };

  useEffect(() => {
    onChange(CampaignTabsNames.CAMPAIGN_INFORMATION);
  }, []);

  return (
    <CampaignDetailsWrapper>
      <InfoWrapper>
        <StyledImageWrapper>
          <StyledImage
            src={campaign?.campaignBanner ?? '/images/stub_image.png'}
            alt="campaign"
            width={600}
            height={320}
            preview={false}
          />
          <StyledLogoWrapper>
            <StyledLogoImage
              src={campaign?.projectImage ?? '/images/stub_image.png'}
              alt="campaign"
              width={100}
              height={100}
              preview={false}
            />
          </StyledLogoWrapper>
        </StyledImageWrapper>
        <TableTabs
          onTabChange={onChange}
          config={campaignDetailsTabsConfig}
          currentTab={activeTab}
        />
        {getTabsContent(activeTab ?? '')}
      </InfoWrapper>
      <SideBlocks>
        <CampaignInformation data={campaign} />
      </SideBlocks>
    </CampaignDetailsWrapper>
  );
};

export default CampaignDetails;
