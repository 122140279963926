import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import {
  MARKETING_DELIVERY_SLICE_NAME,
  MarketingDeliveryState,
  initialState,
} from './models';
import {
  getDeliveryStatistics,
  getMarketingDeliveryData,
  getProofOfDeliveryDataDetails,
} from './actionCreators';
import { showApiErrors } from '@/utils';
import {
  IMarketingDelivery,
  IMarketingDeliveryInfo,
} from '@/models/marketingDelivery.model';

export const slice = createSlice({
  name: MARKETING_DELIVERY_SLICE_NAME,
  initialState,
  reducers: {
    setKolNameFilter(state, action) {
      state.kolIdFilter = action.payload;
      state.marketingDeliveryInfo = initialState.marketingDeliveryInfo;
    },
    setCurrentPostData(state, action) {
      state.currentPostData = action.payload;
    },
    resetCurrentPostData(state) {
      state.currentPostData = initialState.currentPostData;
    },
    resetMarketingList(state: MarketingDeliveryState) {
      state.marketingDelivery = initialState.marketingDelivery;
      state.marketingDeliveryInfo = initialState.marketingDeliveryInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getProofOfDeliveryDataDetails.fulfilled,
        (state: MarketingDeliveryState, action) => {
          state.marketingDeliveryInfo = {
            items: (action.meta.arg.startSk
              ? [
                  ...(state.marketingDeliveryInfo?.items ?? []),
                  ...action.payload.items,
                ]
              : [...action.payload.items]) as IMarketingDeliveryInfo[],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addCase(
        getMarketingDeliveryData.fulfilled,
        (state: MarketingDeliveryState, action) => {
          state.marketingDelivery = {
            items: [...action.payload.items] as IMarketingDelivery[],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addCase(
        getDeliveryStatistics.fulfilled,
        (state: MarketingDeliveryState, action) => {
          state.deliveryStatistics = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isPending(
          getMarketingDeliveryData,
          getProofOfDeliveryDataDetails,
          getDeliveryStatistics,
        ),
        (state: MarketingDeliveryState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(
          getMarketingDeliveryData,
          getProofOfDeliveryDataDetails,
          getDeliveryStatistics,
        ),
        (state: MarketingDeliveryState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export const {
  resetMarketingList,
  setKolNameFilter,
  setCurrentPostData,
  resetCurrentPostData,
} = slice.actions;
export default slice.reducer;
