import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { Text, BlockLayout, Button } from '@/ui';
import { SeedHunterWalletCreationStatus } from '@/models/projects.model';

export const StyledWalletWrapper = styled(BlockLayout)`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 6rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 12rem;
    padding: 1rem;
    margin-bottom: 0rem;
  }
`;

export const StyledInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FormTextWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-bottom: 0;
  }
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('16', '16', 'SEMI_NORMAL')};
  color: ${COLORS.SAMBUCUS};
`;

export const FormFieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;

  & .ant-form-item {
    margin-bottom: 0rem;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledSubTitle = styled.div`
  ${pickThemeFontStyles('12', '22', 'SEMI_BOLD')};
  color: ${COLORS.GRAY};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  }
  margin-right: 0.5rem;
`;

export const StyledText = styled(Text)`
  :last-child {
    margin-bottom: 0;
  }
`;

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 20rem;
`;

export const StyledStatusWrapper = styled.div<{ $status?: string | null }>`
  border-radius: 0.375rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  ${pickThemeFontStyles('14', '22', 'SEMI_BOLD')};
  ${({ $status }) => {
    switch ($status) {
      case SeedHunterWalletCreationStatus.SUCCESS:
        return `background: ${COLORS.GREEN_BUBBLES};
          color: ${COLORS.GREEN_MINT};
          & .ant-select-selection-placeholder {
            color: ${COLORS.GREEN_MINT};
          }`;
      case SeedHunterWalletCreationStatus.MOT_CREATED:
      case SeedHunterWalletCreationStatus.IN_PROGRESS:
        return `background: ${COLORS.CHABLIS_PEACH};
          color: ${COLORS.ATOMIC_TANGERINE};
          & .ant-select-selection-placeholder {
            color: ${COLORS.ATOMIC_TANGERINE};
          }`;
      default:
        return `background: ${COLORS.MISTY_ROSE};
          background: ${COLORS.MISTY_ROSE};
          & .ant-select-selection-placeholder {
            color: ${COLORS.PICH_FROLY};
          }`;
    }
  }}
`;

export const StyledButtonWrapper = styled.div`
  position: initial;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-top: 0rem;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const StyledButton = styled(Button)`
  height: 2rem !important;
`;
