import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Select } from '@/ui';

export const RequestsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledInfoTitles = styled(BlockLayout)`
  display: flex;
  gap: 0.5rem;
  ${pickThemeFontStyles('14', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
`;

export const StyledTitles = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;

  :first-child {
    flex: 0.2;
  }

  :last-child {
    align-items: center;
    flex: 0.7;
  }
`;

export const StyledFilterWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: space-between;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledBox = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: flex-end;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledSelect = styled(Select)`
  &.ant-select {
    &-single {
      & .ant-select-selector {
        background: ${COLORS.WHITE};
        height: 2.25rem;
        width: 11rem;
      }
    }
    .ant-select-clear {
      background: ${COLORS.WHITE};
    }
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &.ant-select {
      &-single {
        & .ant-select-selector {
          width: 12rem;
        }
      }
    }
  }
`;
