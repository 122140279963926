// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Spinner, StyledSpinnerWrap } from '@/ui';
import { StyledForm, StyledButtonWrapper, StyledButton } from '../Styles';
import GeneralSettings from '../GeneralSettings/GeneralSettings';
import AffiliateSettings from '../AffiliateSettings/AffiliateSettings';
import DocumentsSettings from '../DocumentsSettings/DocumentsSettings';
import FundingRoundSettings from '../FundingRoundSettings/FundingRoundSettings';
import { IFormInstance } from '@/components';

// Models
import { TSettingsValues } from '../../helpers/types';

interface ISetting {
  formInstance: IFormInstance;
  handleSubmit: (values: TSettingsValues) => void;
  initialValues: TSettingsValues | null;
  isEditActive: boolean;
}

const Settings = ({
  formInstance,
  handleSubmit,
  initialValues,
  isEditActive,
}: ISetting) => {
  const { t } = useTranslation(['settings']);

  if (!initialValues)
    return (
      <StyledSpinnerWrap>
        <Spinner />
      </StyledSpinnerWrap>
    );

  return (
    <StyledForm
      onFinish={handleSubmit}
      form={formInstance}
      initialValues={initialValues}
    >
      <GeneralSettings isEditActive={isEditActive} />
      <AffiliateSettings isEditActive={isEditActive} />
      <DocumentsSettings
        isEditActive={isEditActive}
        formInstance={formInstance}
      />
      <FundingRoundSettings isEditActive={isEditActive} />
      {isEditActive && (
        <StyledButtonWrapper>
          <StyledButton type="primary" htmlType="submit">
            {t('settings_information_save')}
          </StyledButton>
        </StyledButtonWrapper>
      )}
    </StyledForm>
  );
};

export default Settings;
