import { DocumentTypes, IDocumentTemplateData } from '@/models/documents.model';

const hidedDocuments = [
  DocumentTypes.SAFT,
  DocumentTypes.SERVICE_AGREEMENT,
  DocumentTypes.SUPPLEMENTARY_AGREEMENT,
];

export const hideDocumentTypes = (templates?: IDocumentTemplateData[]) => {
  return templates?.filter(
    (val) => !hidedDocuments.includes(val?.documentType),
  );
};
