import omit from 'lodash.omit';
import { ApiResponse, httpGet, httpPost } from './httpClient';

import {
  CampaignEndpoints,
  ICampaign,
  ICampaignDTO,
  ICampaignKols,
  ICampaignsListResponse,
  IGetCampaignsListDTO,
  IGetParticipationDTO,
  IParticipationResponse,
  IUpdateCampaignsStatusDTO,
} from '@/models/campaigns.model';

class CampaignsAPI {
  static getCampaignsList(
    data: IGetCampaignsListDTO,
  ): ApiResponse<ICampaignsListResponse> {
    return httpGet<ICampaignsListResponse>(CampaignEndpoints.GET_CAMPAIGNS, {
      ...data,
    });
  }

  static getCampaignDetails(id: string): ApiResponse<ICampaign> {
    return httpGet<ICampaign>(
      `${CampaignEndpoints.GET_CAMPAIGNS}/${encodeURI(id)}`,
    );
  }

  static getCampaignKols(id: string): ApiResponse<ICampaignKols[]> {
    return httpGet<ICampaignKols[]>(
      `${CampaignEndpoints.GET_CAMPAIGNS}/${encodeURI(id)}/kols`,
    );
  }

  static updateCampaignStatus(data: IUpdateCampaignsStatusDTO) {
    return httpPost<IUpdateCampaignsStatusDTO, ICampaign>(
      `${CampaignEndpoints.GET_CAMPAIGNS}/${encodeURI(data.id ?? '')}`,
      omit(data, 'id'),
    );
  }

  static campaignCompletion(campaignId: string): ApiResponse<ICampaign> {
    return httpPost(
      `${CampaignEndpoints.GET_CAMPAIGNS}/${encodeURI(
        campaignId ?? '',
      )}/complete`,
    );
  }

  static updateCampaign(data: ICampaignDTO) {
    return httpPost<ICampaignDTO, ICampaign>(
      `${CampaignEndpoints.GET_CAMPAIGNS}/${encodeURI(data.id ?? '')}`,
      omit(data, ['id', 'verifiedAt']),
    );
  }

  static getCampaignParticipations(
    data: IGetParticipationDTO,
  ): ApiResponse<IParticipationResponse> {
    const { id } = data;
    return httpGet<IParticipationResponse>(
      `${CampaignEndpoints.GET_CAMPAIGNS}/${encodeURI(
        id ?? '',
      )}/participations`,
      { ...data },
    );
  }
}

export default CampaignsAPI;
