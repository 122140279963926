import omit from 'lodash.omit';
import { ApiResponse, httpGet, httpPost, httpDelete } from './httpClient';
import {
  IMilestone,
  IMilestoneDTO,
  MilestonesEndpoints,
  IRemoveMilestoneDTO,
} from '@/models/milestones.model';

class MilestonesAPI {
  static addMilestone(id: string): ApiResponse<IMilestone> {
    return httpPost(`${MilestonesEndpoints.FUNDING_ROUNDS}/${id}/milestones`);
  }

  static updateMilestone(data: IMilestoneDTO): ApiResponse<IMilestone> {
    return httpPost(
      `${MilestonesEndpoints.FUNDING_ROUNDS}/${encodeURI(
        data.fundingRoundId ?? '',
      )}/milestones/${encodeURI(data?.id ?? '')}`,
      omit(data, ['id', 'fundingRoundId']),
    );
  }

  static removeMilestone(data: IRemoveMilestoneDTO) {
    return httpDelete(
      `${MilestonesEndpoints.FUNDING_ROUNDS}/${data.id}/milestones/${data.milestoneId}`,
    );
  }

  static getProjectMilestones(id: string): ApiResponse<IMilestone[]> {
    return httpGet<IMilestone[]>(
      `${MilestonesEndpoints.FUNDING_ROUNDS}/${id}/milestones`,
    );
  }
}

export default MilestonesAPI;
