// Globals
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/es/form/Form';

// Components
import { TopBarsSettings } from '../../components';
import { showErrorToast, showSuccessToast } from '@/components';

// Modules
import { getSettingsLogs } from '@/modules/Logs';

// Hooks | Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import {
  getTopBarsSettings,
  postTopBarsSettings,
} from '../../feature/actionCreators';
import { settingsSliceSelector } from '../../feature/selectors';
import { checkFieldValidation } from '@/utils';
import { TopBarSettingsDTO } from '../../dtos';
import {
  settingsTopBarsDataToFormValues,
  TopBarsSettingsFieldsNames,
  TTopBarsSettingsValues,
} from '../../helpers';

interface ITopBarsSettingsContainer {
  toggleIsEditState: () => void;
  isEditActive: boolean;
}

const TopBarsSettingsContainer = ({
  toggleIsEditState,
  isEditActive,
}: ITopBarsSettingsContainer) => {
  const { t } = useTranslation(['settings']);
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const { topBarsSettings } = useAppState(settingsSliceSelector);

  useEffect(() => {
    dispatch(getTopBarsSettings());
  }, []);

  const handleSubmitTopBarSettings = useCallback(
    async (values: TTopBarsSettingsValues) => {
      await checkFieldValidation(values, form);
      const topBarSettingsDTO = new TopBarSettingsDTO(values);
      try {
        await dispatch(postTopBarsSettings(topBarSettingsDTO)).unwrap();
        toggleIsEditState();
        dispatch(getSettingsLogs({}));
        showSuccessToast({ message: t('settings_success_toast') });
      } catch (e: unknown) {
        showErrorToast({ message: t('settings_error_toast') });
      }
    },
    [form, dispatch],
  );

  const onValuesChange = useCallback(
    async (values: TTopBarsSettingsValues) => {
      const marketingSettedInactive =
        values?.[TopBarsSettingsFieldsNames.MARKETING]?.[
          TopBarsSettingsFieldsNames.ACTIVE
        ] === false;
      const fundingSettedInactive =
        values?.[TopBarsSettingsFieldsNames.FUNDING_ROUND]?.[
          TopBarsSettingsFieldsNames.ACTIVE
        ] === false;

      if (fundingSettedInactive) {
        form.setFieldsValue({
          [TopBarsSettingsFieldsNames.FUNDING_ROUND]: {
            [TopBarsSettingsFieldsNames.PRIORITY]: false,
          },
        });
      }

      if (marketingSettedInactive) {
        form.setFieldsValue({
          [TopBarsSettingsFieldsNames.MARKETING]: {
            [TopBarsSettingsFieldsNames.PRIORITY]: false,
          },
        });
      }
    },
    [form, dispatch],
  );

  return (
    <TopBarsSettings
      handleSubmit={handleSubmitTopBarSettings}
      onValuesChange={onValuesChange}
      formInstance={form}
      initialValues={settingsTopBarsDataToFormValues(topBarsSettings)}
      isEditActive={isEditActive}
    />
  );
};

export default TopBarsSettingsContainer;
