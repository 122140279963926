export const highlightVariables = (value: string) => {
  let highlighted = value;
  highlighted = highlighted.replace(
    /{{/g,
    '<span style="background-color: yellow;">{{',
  );
  highlighted = highlighted.replace(/}}/g, '}}</span>');

  return highlighted;
};
