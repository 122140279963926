import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { IRoute, routesConfig } from './config';

type ValueOrArray<T> = T | ValueOrArray<T>[];

const mapRoute = (route: IRoute): ValueOrArray<JSX.Element> => {
  return <Route path={route.path} element={route.component} key={route.path} />;
};

const routes = routesConfig.map(mapRoute);

const AppRouter = (): JSX.Element => <Routes>{routes}</Routes>;

export default AppRouter;
export { ROUTES_PATHS } from './config';
