import { COLORS } from '@/theme';

export const chartColors = [
  COLORS.CERULEAN,
  COLORS.COQUELICOT,
  COLORS.BRIGHT_ORANGE,
  COLORS.AZUL,
];

export const defaultConfig = {
  xField: 'month',
  yField: 'value',
  seriesField: 'label',
  yAxis: {
    grid: null,
  },
  options: {
    maintainAspectRatio: false,
  },
  autoFit: true,
  smooth: true,
  animation: {
    appear: {
      animation: 'path-in',
      duration: 1000,
    },
  },
};
