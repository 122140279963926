// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledInfo,
  StyledSubTitle,
  StyledText,
  StyledDetails,
} from './WalletBlockStyles';
import { Tooltip } from '@/ui';

// Models
import { WalletNetworksEnum } from '@/models/web3.model';

// Modules
import { selectCryptoCurrencies } from '@/modules/DataLists';

// Utils
import { useAppState } from '@/hooks';

interface IGeneralInfo {
  walletCurrency?: string;
  walletAddress?: string;
  walletNetwork?: string;
}

const WalletBlock = ({
  walletCurrency,
  walletAddress,
  walletNetwork,
}: IGeneralInfo) => {
  const { t } = useTranslation(['kolsList']);
  const cryptoCurrencies = useAppState(selectCryptoCurrencies);
  const walletCurrencyName =
    walletCurrency === WalletNetworksEnum.SEPOLIA
      ? walletCurrency
      : cryptoCurrencies?.list?.find(
          (currency) => currency.symbol === walletCurrency,
        )?.name;

  return (
    <StyledInfo>
      {walletNetwork && (
        <StyledDetails>
          <StyledSubTitle>
            {t('kols_general_info_wallet_network')}
          </StyledSubTitle>
          <StyledText>{walletNetwork}</StyledText>
        </StyledDetails>
      )}
      {walletCurrency && (
        <StyledDetails>
          <StyledSubTitle>
            {t('kols_general_info_wallet_currency')}
          </StyledSubTitle>
          <StyledText>{walletCurrencyName}</StyledText>
        </StyledDetails>
      )}
      {walletAddress && (
        <StyledDetails>
          <StyledSubTitle>
            {t('kols_general_info_wallet_address')}
          </StyledSubTitle>
          <Tooltip
            getPopupContainer={(triggerNode) => triggerNode}
            title={walletAddress}
            placement={'top'}
          >
            <StyledText>{walletAddress}</StyledText>
          </Tooltip>
        </StyledDetails>
      )}
    </StyledInfo>
  );
};

export default WalletBlock;
