import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES_PATHS } from '@/router';

// Components
import {
  StyledButton,
  StyledArrowRightIcon,
  StyledStatusWrapper,
} from './AdminDetailsContainerStyles';
import { Title, StyledPageWrapper } from '@/ui';
import AdminPermissions from '../../components/AdminPermissions/AdminPermissions';

// Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import { adminsListSliceSelector } from '../../feature/selectors';
import { getAdminsList } from '../../feature/actionCreators';
import { getUserId } from '@/utils';

const AdminDetailsContainer = () => {
  const { t } = useTranslation(['adminsList']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { adminsList } = useAppState(adminsListSliceSelector);
  const adminDetails = useMemo(
    () => adminsList?.find((admin) => getUserId(admin.adminId) === id || ''),
    [adminsList, id],
  );

  const navigateToAdminsList = () => {
    navigate(ROUTES_PATHS.ADMINS_LIST);
  };

  useEffect(() => {
    if (id) {
      dispatch(getAdminsList());
    }
  }, [id]);

  if (!adminDetails?.adminId) return null;

  return (
    <StyledPageWrapper>
      <StyledStatusWrapper>
        <Title $type="h3">{t('admin_details_permissions')}</Title>
      </StyledStatusWrapper>
      <StyledButton type="ghost" onClick={navigateToAdminsList}>
        <StyledArrowRightIcon />
        {t('admins_list_details_go_back')}
      </StyledButton>
      <AdminPermissions adminDetails={adminDetails} />
    </StyledPageWrapper>
  );
};

export default AdminDetailsContainer;
