// Global
import React from 'react';

// Components
import {
  ContactsWrapper,
  StyledInternetIcon,
  StyledLink,
  StyledMessageIcon,
  StyledTelephoneIcon,
} from './ContactBarStyles';
import { TelegramSVGIcon, WhatsAppIcon } from '@/icons';

// Models
import { IPartner } from '@/models/partnersList.model';
import { IKOL } from '@/models/kolsList.model';
import { IProject } from '@/models/projects.model';
import { ContactSourceEnum, IMainContact } from '@/models/sharedProfile.model';

interface IContactBar {
  data?: IPartner | IKOL | IProject;
}

const ContactBar = ({ data }: IContactBar) => {
  const renderMainContact = (contact: IMainContact) => {
    switch (contact.source) {
      case ContactSourceEnum.PHONE:
        return (
          <StyledLink to={`tel:${data?.mainContact?.contact}`}>
            <StyledTelephoneIcon />
          </StyledLink>
        );
      case ContactSourceEnum.TELEGRAM:
        return (
          <StyledLink
            to={`https://t.me/${data?.mainContact?.contact}`}
            target={'_blank'}
          >
            <TelegramSVGIcon />
          </StyledLink>
        );
      case ContactSourceEnum.WHATSAPP:
        return (
          <StyledLink
            to={`https://wa.me/${data?.mainContact?.contact}`}
            target={'_blank'}
          >
            <WhatsAppIcon />
          </StyledLink>
        );
      default:
        return null;
    }
  };

  return (
    <ContactsWrapper>
      {!!data?.email && (
        <StyledLink to={`mailto:${data?.email}`}>
          <StyledMessageIcon />
        </StyledLink>
      )}
      {!!data?.mainContact && renderMainContact(data?.mainContact)}
      {!!data && 'website' in data && (
        <StyledLink to={data?.website as string}>
          <StyledInternetIcon />
        </StyledLink>
      )}
    </ContactsWrapper>
  );
};

export default ContactBar;
