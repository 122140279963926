import { TFunction } from 'react-i18next';
import { AccountStatuses } from '@/constants';

export const getStatuses = (t: TFunction, status?: string | null) => {
  switch (status) {
    case AccountStatuses.VERIFIED:
      return t('statuses_badge_verified');
    case AccountStatuses.IN_VERIFICATION:
      return t('statuses_badge_in_verification');
    case AccountStatuses.PENDING:
      return t('statuses_badge_pending');
    case AccountStatuses.PAUSED:
      return t('statuses_badge_paused');
    case AccountStatuses.NOT_VERIFIED:
      return t('statuses_badge_not_verified');
    case AccountStatuses.CANCELED:
      return t('statuses_badge_canceled');
    case AccountStatuses.ARCHIVED:
      return t('statuses_badge_archived');
    case AccountStatuses.PRE_VERIFIED:
      return t('statuses_badge_pre_verified');
    case AccountStatuses.BLOCKED:
      return t('statuses_badge_blocked');
    default:
      return t('statuses_badge_declined');
  }
};
