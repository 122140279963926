import styled from 'styled-components';
import { Form, FormItem, PlacesAutocomplete } from '@/components';
import { Button } from '@/ui';
import { COLORS, DEVICES } from '@/theme';

export const StyledInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1rem 0.6rem;
`;

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const StyledForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 3;
  gap: 0.5rem;
  position: relative;
  padding-bottom: 2rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    padding-bottom: 0.5rem;
  }
`;

export const StyledFormItem = styled(FormItem)`
  margin-bottom: 0.5rem;

  &&& .ant-select-show-arrow {
    margin-bottom: 0;
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  height: 2rem !important;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const StyledPlacesAutocomplete = styled(PlacesAutocomplete)`
  background: ${COLORS.LIGHTEST_GRAY};
  height: 2.875rem;
  border-radius: 6px;

  &.ant-select-single .ant-select-selector {
    height: 2.875rem;
    border: 0;
  }
`;
