import styled from 'styled-components';
import { COLORS, DEVICES } from '@/theme';
import { Image } from '@/ui';

export const CampaignDetailsWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${COLORS.WHITE};
  margin-bottom: 0.5rem;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 1rem;
  padding: 1rem;
  gap: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 60%;
    gap: 1.5rem;
  }
`;

export const StyledImage = styled(Image)`
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  object-fit: cover;
`;

export const StyledLogoImage = styled(Image)`
  aspect-ratio: 2;
  object-fit: cover;
  border-radius: 0.7rem;
  width: 6.25rem;
  height: 6.25rem;
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
  position: absolute;
  border-radius: 0.8rem;
  width: 6.5rem;
  bottom: -2rem;
  left: 1rem;
  justify-content: center;
  padding: 0.1rem;
  background-color: ${COLORS.WHITE};

  @media screen and ${DEVICES.LAPTOP_S} {
    left: 3rem;
  }
`;

export const SideBlocks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 40%;
  }
`;
