// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  ReportOverviewWrapper,
  StyledTitles,
  StyledInfoTitles,
  StyledInfoWrapper,
  StyledText,
  StyledTitle,
  CardWrapper,
} from './CommissionReportStyles';

// Modules
import { DesktopOnly, MobileOnly } from '@/modules/Adaptive';

// Models
import { numberWithCommas } from '@/utils';

interface ICommissionReport {
  commissionTotal: number;
  affiliateReward: number;
  commissionBalance: number;
}

const CommissionReport = ({
  commissionTotal,
  affiliateReward,
  commissionBalance,
}: ICommissionReport) => {
  const { t } = useTranslation(['reports']);

  return (
    <ReportOverviewWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('reports_funding_commission_total')}</StyledTitles>
          <StyledTitles>
            {t('reports_funding_commission_affiliate_reward')}
          </StyledTitles>
          <StyledTitles>{t('reports_funding_commission_balance')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      <CardWrapper>
        <StyledInfoWrapper>
          <MobileOnly>
            <StyledTitle>{t('reports_funding_commission_total')}</StyledTitle>
          </MobileOnly>
          <StyledText>$ {numberWithCommas(commissionTotal ?? 0)}</StyledText>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <MobileOnly>
            <StyledTitle>
              {t('reports_funding_commission_affiliate_reward')}
            </StyledTitle>
          </MobileOnly>
          <StyledText>$ {numberWithCommas(affiliateReward ?? 0)}</StyledText>
        </StyledInfoWrapper>
        <StyledInfoWrapper>
          <MobileOnly>
            <StyledTitle>{t('reports_funding_commission_balance')}</StyledTitle>
          </MobileOnly>
          <StyledText>$ {numberWithCommas(commissionBalance ?? 0)}</StyledText>
        </StyledInfoWrapper>
      </CardWrapper>
    </ReportOverviewWrapper>
  );
};

export default CommissionReport;
