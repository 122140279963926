import { IApiError } from '@/models/apiError.model';
import { AdminActionsEnum } from '@/models/adminsList.model';

export const PERMISSION_SLICE_NAME = 'permissions';

export interface PermissionsState {
  permissions: AdminActionsEnum[] | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: PermissionsState = {
  permissions: null,
  isLoading: false,
  error: null,
};
