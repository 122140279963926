// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  CardWrapper,
  StyledInfoWrapper,
  StyledTitle,
  StyledText,
} from './FundingRoundCardStyles';
import { Link } from '@/ui';
import { ArrowRightIcon } from '@/icons';
import { CountBadge } from '@/components';

// Models
import { IFundingRound } from '@/models/fundingRound.model';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Helpers
import { getDateString, getPercent, numberWithCommas } from '@/utils';
import { ROUTES_PATHS } from '@/router';
import { fundingTargetCalculation } from '../../helpers';

interface IFundingRoundCard {
  data: IFundingRound;
}

const FundingRoundCard = ({ data }: IFundingRoundCard) => {
  const { t } = useTranslation(['fundingRound']);
  const fundingTarget = fundingTargetCalculation(data ?? ({} as IFundingRound));
  const fundingReached = getPercent(data?.raisedAmount ?? 0, fundingTarget);

  return (
    <CardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('funding_list_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.title}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('funding_list_project_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.projectTitle}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('funding_list_tge_date')}</StyledTitle>
        </MobileOnly>
        <StyledText>{getDateString(data?.plannedTGEDate ?? '')}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('funding_list_funding_reached')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          $ {data?.raisedAmount ?? 0} ({numberWithCommas(fundingReached)}%)
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('funding_list_funding_target')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          <img src={data?.tokenImage} alt="tokenImage" />
          {numberWithCommas(fundingTarget)}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('funding_list_phase')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.phase}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('funding_list_status')}</StyledTitle>
        </MobileOnly>
        <StyledText>{t(`funding_list_${data?.status}_status`)}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <StyledText>
          <Link
            to={`${ROUTES_PATHS.FUNDING_ROUNDS}/${encodeURIComponent(
              data?.id ?? '',
            )}`}
          >
            <ArrowRightIcon />
          </Link>
          <CountBadge count={0} />
        </StyledText>
      </StyledInfoWrapper>
    </CardWrapper>
  );
};

export default FundingRoundCard;
