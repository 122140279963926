// Globals
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Spinner, StyledSpinnerWrap } from '@/ui';
import { StyledTitles, PayoutListWrapper } from './FundingRefundDetailsStyles';
import { StyledInfoTitles } from '../Styled';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { FundingRoundParticipantsReportResponse } from '@/models/reports.model';

// Helpers
import FundingRefundDetailsCard from '../FundingRefundDetailsCard/FundingRefundDetailsCard';

interface IFundingRoundPayoutDetails {
  fundingRefundDetailsData?: FundingRoundParticipantsReportResponse | null;
  isLoading: boolean;
}

const FundingRefundDetails = ({
  isLoading,
  fundingRefundDetailsData,
}: IFundingRoundPayoutDetails) => {
  const { t } = useTranslation(['payout']);
  const loaderRef = useRef<HTMLDivElement>(null);

  const mappedParticipationItems = useMemo(() => {
    return fundingRefundDetailsData?.map((participation) => {
      return (
        <FundingRefundDetailsCard
          data={participation}
          key={participation.participationSk}
        />
      );
    });
  }, [fundingRefundDetailsData, fundingRefundDetailsData]);

  return (
    <PayoutListWrapper>
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>
            {t('payout_refund_participation_participant')}
          </StyledTitles>
          <StyledTitles>{t('payout_refund_participation_date')}</StyledTitles>
          <StyledTitles>
            {t('payout_refund_participation_contact')}
          </StyledTitles>
          <StyledTitles>{t('payout_refund_participation_amount')}</StyledTitles>
          <StyledTitles>
            {t('payout_refund_participation_credits_used')}
          </StyledTitles>
          <StyledTitles>{t('payout_refund_amount_title')}</StyledTitles>
          <StyledTitles>{t('payout_refund_participant_tokens')}</StyledTitles>
          <StyledTitles>{t('payout_refund_participant_wallet')}</StyledTitles>
          <StyledTitles>{t('payout_refund_finance_manager')}</StyledTitles>
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedParticipationItems}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </PayoutListWrapper>
  );
};

export default FundingRefundDetails;
