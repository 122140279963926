const fileDownloadByLink = (link: string, fileName = '') => {
  const a = document.createElement('a');
  document.body.appendChild(a);

  a.href = link;
  a.target = '_blank';
  a.download = fileName || (link.split('/').pop() as string);
  a.click();
  document.body.removeChild(a);
};

export default fileDownloadByLink;
