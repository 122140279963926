import { createSlice } from '@reduxjs/toolkit';
import { ADAPTIVE_SLICE_NAME, initialState } from './models';

export const slice = createSlice({
  name: ADAPTIVE_SLICE_NAME,
  initialState,
  reducers: {
    setIsMobile(state, action) {
      state.isMobile = action.payload;
    },
  },
});

export const { setIsMobile } = slice.actions;

export default slice.reducer;
