import {
  createSlice,
  isPending,
  isRejected,
  isFulfilled,
} from '@reduxjs/toolkit';
import { omitBy } from 'lodash';
import { KOLS_LIST_SLICE_NAME, KOLsListState, initialState } from './models';
import {
  getKOLAffiliateData,
  getKOLDetails,
  getKOLsList,
  updateKOL,
  blockKOL,
  getKOLSocialChannels,
  getInvitedUsers,
} from './actionCreators';
import { showApiErrors } from '@/utils';
import { IKOL, MarketingAffiliateCommission } from '@/models/kolsList.model';
import { getSocialChannelError } from '../helpers';

export const slice = createSlice({
  name: KOLS_LIST_SLICE_NAME,
  initialState,
  reducers: {
    resetKolsListState() {
      return { ...initialState };
    },
    resetErrors(state) {
      state.influencerDataError = null;
    },
    setKolFilters(state: KOLsListState, action) {
      state.kolFilters = { ...state.kolFilters, ...action.payload };
      state.kolsListData = initialState.kolsListData;
    },
    resetKolList(state) {
      state.kolsListData = initialState.kolsListData;
      state.kolFilters = initialState.kolFilters;
    },
    updateKolDetails(state, action) {
      state.kolDetails = { ...state.kolDetails, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKOLsList.fulfilled, (state: KOLsListState, action) => {
        state.kolsListData = {
          items: (action.meta.arg.startId
            ? [...(state.kolsListData?.items ?? []), ...action.payload.items]
            : [...action.payload.items]) as IKOL[],
          hasMore: action.payload.hasMore,
        };
        state.isLoading = false;
      })
      .addCase(getKOLDetails.fulfilled, (state: KOLsListState, action) => {
        state.kolDetails = action.payload.data;
        state.isLoading = false;
      })
      .addCase(
        getKOLSocialChannels.fulfilled,
        (state: KOLsListState, action) => {
          const withErrors = action.payload?.data?.socialChannel?.some(
            (channel) => channel?.error,
          );

          if (withErrors) {
            const errorsFromPayload =
              action.payload?.data?.socialChannel?.reduce((acc, channel) => {
                return omitBy(
                  {
                    ...acc,
                    [channel.name]:
                      getSocialChannelError(channel.error) || null,
                  },
                  (item) => !item,
                );
              }, {});

            state.influencerDataError = {
              ...state.influencerDataError,
              ...errorsFromPayload,
            };
          } else {
            state.kolDetails = action.payload.data;
            state.influencerDataError = null;
          }
          state.isLoading = false;
        },
      )
      .addCase(
        getKOLAffiliateData.fulfilled,
        (state: KOLsListState, action) => {
          state.affiliateData = {
            items: (action.meta.arg.startId
              ? [...(state.affiliateData?.items ?? []), ...action.payload.items]
              : [...action.payload.items]) as MarketingAffiliateCommission[],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addCase(getInvitedUsers.fulfilled, (state: KOLsListState, action) => {
        state.invitedUsers = {
          items: action.meta.arg.startId
            ? [...(state.invitedUsers?.items ?? []), ...action.payload.items]
            : [...action.payload.items],
          hasMore: action.payload.hasMore,
        };
        state.isLoading = false;
      })
      .addCase(updateKOL.rejected, (state: KOLsListState) => {
        state.isLoading = false;
      })
      .addMatcher(
        isFulfilled(blockKOL, updateKOL),
        (state: KOLsListState, action) => {
          state.kolDetails = action.payload;
          state.isLoading = false;

          const withErrors = action.payload?.socialChannel?.some(
            (channel) => channel?.error,
          );

          if (withErrors) {
            const errorsFromPayload = action.payload?.socialChannel?.reduce(
              (acc, channel) => {
                return omitBy(
                  {
                    ...acc,
                    [channel.name]:
                      getSocialChannelError(channel.error) || null,
                  },
                  (item) => !item,
                );
              },
              {},
            );

            state.influencerDataError = {
              ...state.influencerDataError,
              ...errorsFromPayload,
            };
          }
        },
      )
      .addMatcher(
        isPending(
          getKOLDetails,
          getKOLsList,
          getInvitedUsers,
          updateKOL,
          getKOLSocialChannels,
        ),
        (state: KOLsListState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(
          getKOLDetails,
          getKOLsList,
          getInvitedUsers,
          getKOLSocialChannels,
          updateKOL,
        ),
        (state: KOLsListState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export const {
  resetKolsListState,
  resetKolList,
  resetErrors,
  setKolFilters,
  updateKolDetails,
} = slice.actions;
export default slice.reducer;
