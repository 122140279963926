// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledChannelsDetails } from './SocialChannelsGuidelinesStyles';

// Models
import { SocialChannels } from '@/models/socialChannel.model';

// Helpers
import { channelsInstructions } from '../../constants';

interface ISocialChannelsGuidelines {
  socialChannelName: SocialChannels;
}

const SocialChannelsGuidelines = ({
  socialChannelName,
}: ISocialChannelsGuidelines) => {
  const { t } = useTranslation(['campaigns']);

  const socialsPredefinedGuidelines = channelsInstructions[socialChannelName];

  return (
    <StyledChannelsDetails>
      <h6>{t('campaign_details_predefined_guidelines_title')}:</h6>
      <ul>
        <li>{t(socialsPredefinedGuidelines?.firstInfo)}</li>
        <li>{t(socialsPredefinedGuidelines?.secondInfo)}</li>
        <li>{t(socialsPredefinedGuidelines?.thirdInfo)}</li>
        <li>{t(socialsPredefinedGuidelines?.fourthInfo)}</li>
      </ul>
    </StyledChannelsDetails>
  );
};

export default SocialChannelsGuidelines;
