import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { FUNDING_ROUND_SLICE_NAME, FundingRoundState } from './models';

export const fundingRoundSliceSelector = (
  state: RootState,
): FundingRoundState => state[FUNDING_ROUND_SLICE_NAME];

export const fundingRoundIsLoading = createSelector(
  fundingRoundSliceSelector,
  (state: FundingRoundState) => state.isLoading,
);

export const selectFundingRoundParticipations = createSelector(
  fundingRoundSliceSelector,
  (state: FundingRoundState) => state.fundingRoundParticipations,
);

export const selectFundingRoundDetails = createSelector(
  fundingRoundSliceSelector,
  (state: FundingRoundState) => state.fundingRound,
);
