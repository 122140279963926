// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledWrapper,
  DetailsBlock,
  DescriptionWrap,
} from './ProjectDetailsTabStyles';
import { StyledTitle, StyledSubTitle } from '../Styled/Styled';
import SocialsBlock from './SocialsBlock';

// Models
import { ICampaign } from '@/models/campaigns.model';

interface IProjectDetailsTab {
  data: ICampaign | null;
}

const ProjectDetailsTab = ({ data }: IProjectDetailsTab) => {
  const { t } = useTranslation(['campaigns']);

  return (
    <StyledWrapper>
      <StyledTitle>{data?.projectTitle}</StyledTitle>

      <SocialsBlock data={data} />

      <DetailsBlock>
        <StyledSubTitle>
          {`${t('campaign_details_about')} ${data?.projectTitle}`}
        </StyledSubTitle>
        <DescriptionWrap>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.projectFullDescription
                ? String(data?.projectFullDescription)
                : '',
            }}
          />
        </DescriptionWrap>
      </DetailsBlock>
    </StyledWrapper>
  );
};

export default ProjectDetailsTab;
