// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  FilterWrapper,
  StyledChevronIcon,
  StyledSelect,
} from './ReportsFiltersStyles';

// Hooks | Helpers
import { useAppDispatch, useAppState } from '@/hooks';
import { setReportsFilters } from '../../feature/slice';
import { reportsSliceSelector } from '../../feature/selectors';
import { getFundingFilterOptions } from '../../helpers/getOptions';

const FundingReportsFilters = () => {
  const { t } = useTranslation(['reports']);
  const dispatch = useAppDispatch();
  const { reportsFilters, fundingFiltersOptions } =
    useAppState(reportsSliceSelector);

  const projectOptions = getFundingFilterOptions(
    fundingFiltersOptions?.projects ?? [],
  );
  const fundingRoundsOptions = getFundingFilterOptions(
    fundingFiltersOptions?.fundingRounds ?? [],
  );

  const handleProjectFilterChange = (value: unknown) => {
    dispatch(setReportsFilters({ projectId: value }));
  };

  const handleFundingRoundFilterChange = (value: unknown) => {
    dispatch(setReportsFilters({ fundingRoundId: value }));
  };

  return (
    <FilterWrapper>
      <StyledSelect
        options={projectOptions}
        allowClear
        showSearch
        value={reportsFilters?.projectId}
        onChange={handleProjectFilterChange}
        placeholder={t('reports_filters_project')}
        suffixIcon={<StyledChevronIcon />}
      />
      <StyledSelect
        options={fundingRoundsOptions}
        allowClear
        showSearch
        value={reportsFilters?.fundingRoundId}
        onChange={handleFundingRoundFilterChange}
        placeholder={t('reports_filters_funding_round')}
        suffixIcon={<StyledChevronIcon />}
      />
    </FilterWrapper>
  );
};

export default FundingReportsFilters;
