// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  ProjectCardWrapper,
  StyledInfoWrapper,
  StyledTitle,
  StyledText,
} from './CampaignCardStyles';
import { Link } from '@/ui';
import { ArrowRightIcon } from '@/icons';
import { CountBadge } from '@/components';

// Models
import { ICampaign } from '@/models/campaigns.model';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Helpers
import { numberWithCommas, getDateString } from '@/utils';
import { ROUTES_PATHS } from '@/router';
import { CampaignTabKeys } from '../../constants';

interface ICampaignCard {
  campaign: ICampaign;
}

const CampaignCard = ({ campaign }: ICampaignCard) => {
  const { t } = useTranslation(['campaigns']);

  return (
    <ProjectCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaigns_campaign_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{campaign?.title}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaigns_project_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{campaign?.projectTitle}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaigns_marketing_start_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{getDateString(campaign?.marketingStartDate)}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaigns_marketing_end_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{getDateString(campaign?.marketingEndDate)}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaigns_campaign_budget_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>${numberWithCommas(campaign?.marketingBudget)}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('campaigns_status_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{t(`campaign_status_${campaign?.status}`)}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <StyledText>
          <Link
            to={`${ROUTES_PATHS.CAMPAIGNS}/${encodeURIComponent(
              campaign?.id ?? '',
            )}/${CampaignTabKeys.CAMPAIGN}`}
          >
            <ArrowRightIcon />
          </Link>
          <CountBadge count={campaign.unreadRequestsCount ?? 0} />
        </StyledText>
      </StyledInfoWrapper>
    </ProjectCardWrapper>
  );
};

export default CampaignCard;
