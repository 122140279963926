import styled from 'styled-components';
import { BlockLayout, Button, Link } from '@/ui';
import { COLORS, pickThemeFontStyles, DEVICES } from '@/theme';
import { RoundArrowsIcon } from '@/icons';
import { PayoutStatuses } from '@/models/payout.model';

export const PayoutCardWrapper = styled(BlockLayout)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  position: relative;
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  width: 100%;
  flex-direction: column;
  min-width: 0;
  gap: 0.5rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    :last-child {
      align-items: center;
    }

    :nth-last-child(2) {
      align-items: center;
    }
  }
`;

export const StyledLink = styled(Link)`
  width: 100%;
`;

export const StyledRoundArrowsIcon = styled(RoundArrowsIcon)`
  margin-left: 0.5rem;
`;

export const StyledFileButton = styled(Button)`
  &&& {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: ${COLORS.SOLITUDE_GRAY};
    color: ${COLORS.SOLITUDE_DARK};
    ${pickThemeFontStyles('10', '16', 'BOLD')};
    span {
      word-break: break-all;
    }
`;

export const StyledFileIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    transform: scale(0.4);
  }
`;

export const StyledButton = styled(Button)<{ $isRequest?: boolean }>`
  display: flex;
  margin-top: 1rem;
  &&& {
    width: 100%;
    height: 1.5rem;
    background: ${COLORS.SOLITUDE_GRAY};
    ${pickThemeFontStyles('12', '16', 'SEMI_BOLD')};
    align-items: center;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-top: 0rem;
  }
`;

export const StyledTransferButton = styled(Button)`
  display: flex;
  margin-top: 1rem;
  &&& {
    width: 100%;
    height: 1.5rem;
    ${pickThemeFontStyles('12', '16', 'SEMI_BOLD')};
    align-items: center;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    margin-top: 0rem;
  }
`;

export const StyledIconWrapper = styled.div`
  svg {
    transform: scale(0.6);
  }
`;

export const StyledBadge = styled.div<{ $status?: string | null }>`
  display: flex;
  width: 100%;
  margin-top: 1rem;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  padding: 0.5rem;
  color: ${COLORS.CORN_FLOWER_BLUE};
  ${pickThemeFontStyles('12', '16', 'BOLD')};
  ${({ $status }) => {
    switch ($status) {
      case PayoutStatuses.SUCCESS:
        return `background: ${COLORS.GREEN_BUBBLES};`;
      case PayoutStatuses.PENDING:
        return `background: ${COLORS.CHABLIS_PEACH};`;
      default:
        return `background: ${COLORS.MISTY_ROSE};`;
    }
  }}
  @media screen and ${DEVICES.LAPTOP_S} {
    margin-top: 0rem;
  }
`;

export const StyledChannelsWrap = styled.div`
  display: flex;
`;

export const StyledFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;

  svg {
    width: 1.5rem;
  }
`;

export const StyledPopupWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  span {
    ${pickThemeFontStyles('14', '22', 'BOLD')};
  }
`;

export const StyledSubmitButton = styled(Button)`
  display: flex;
  margin-top: 1rem;
  &&& {
    width: 100%;
    ${pickThemeFontStyles('12', '16', 'SEMI_BOLD')};
    align-items: center;
  }
`;
