// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledFormWrapper,
  FormTextWrapper,
  StyledTitle,
  FormFieldsWrapper,
  StyledText,
  StyledSubTitle,
  StyledRow,
} from './ContractInfoStyles';

// Models
import { IProject } from '@/models/projects.model';

// Helpers
import { getDateString } from '@/utils';

interface IContractInfoProjects {
  userData?: IProject | null;
}

const ContractInfoProjects = ({ userData }: IContractInfoProjects) => {
  const { t } = useTranslation(['profile']);
  if (!userData) return null;

  return (
    <StyledFormWrapper>
      <FormTextWrapper>
        <StyledTitle>{t('profile_contract_information')}</StyledTitle>
      </FormTextWrapper>
      <FormFieldsWrapper>
        {userData?.companyInfo?.fullName && (
          <StyledRow>
            <StyledSubTitle>
              {t('profile_contract_fullName_label')}
            </StyledSubTitle>
            <StyledText>{userData?.companyInfo?.fullName}</StyledText>
          </StyledRow>
        )}
        {userData?.companyInfo?.companyName && (
          <StyledRow>
            <StyledSubTitle>
              {t('profile_contract_company_name_label')}
            </StyledSubTitle>
            <StyledText>{userData?.companyInfo?.companyName}</StyledText>
          </StyledRow>
        )}

        <StyledRow>
          <StyledSubTitle>{t('profile_contract_email_label')}</StyledSubTitle>
          <StyledText>{userData?.email}</StyledText>
        </StyledRow>

        <StyledRow>
          <StyledSubTitle>{t('profile_contract_country_label')}</StyledSubTitle>
          <StyledText>{userData?.companyInfo?.country}</StyledText>
        </StyledRow>

        <StyledRow>
          <StyledSubTitle>{t('profile_contract_city_label')}</StyledSubTitle>
          <StyledText>{userData?.companyInfo?.city}</StyledText>
        </StyledRow>

        <StyledRow>
          <StyledSubTitle>{t('profile_contract_address_label')}</StyledSubTitle>
          <StyledText>{userData?.companyInfo?.address}</StyledText>
        </StyledRow>

        <StyledRow>
          <StyledSubTitle>
            {t('profile_contract_registration_number')}
          </StyledSubTitle>
          <StyledText>{userData?.companyInfo?.registrationNumber}</StyledText>
        </StyledRow>

        <StyledRow>
          <StyledSubTitle>
            {t('profile_contract_date_of_incorporation')}
          </StyledSubTitle>
          <StyledText>
            {getDateString(userData?.companyInfo?.dateOfIncorporation ?? '')}
          </StyledText>
        </StyledRow>
      </FormFieldsWrapper>
    </StyledFormWrapper>
  );
};

export default ContractInfoProjects;
