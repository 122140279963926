import { SocialChannels } from '@/models/socialChannel.model';

export const channelsToSkip = new Set([
  SocialChannels.REDDIT,
  SocialChannels.TIKTOK,
  SocialChannels.TELEGRAM,
  SocialChannels.TIKTOK,
]);

export enum ProjectInformationFieldsNames {
  SOCIAL_CHANNEL = 'socialChannel',
}

export enum ContactInfoFieldNames {
  MAIN_CONTACT = 'mainContact',
  CONTACT = 'contact',
  SOURCE = 'source',
  EMAIL = 'email',
}

export enum CreditFieldsNames {
  TITLE = 'title',
  AMOUNT = 'amount',
}
