import { IApiError } from '@/models/apiError.model';
import {
  IDeliveryStatistics,
  IMarketingDelivery,
  IMarketingDeliveryInfo,
} from '@/models/marketingDelivery.model';

export const MARKETING_DELIVERY_SLICE_NAME = 'marketing-delivery';

export interface MarketingDeliveryState {
  isLoading: boolean;
  kolIdFilter: string;
  deliveryStatistics: IDeliveryStatistics | null;
  marketingDelivery: {
    items: IMarketingDelivery[];
    hasMore: boolean;
  };
  marketingDeliveryInfo: {
    items: IMarketingDeliveryInfo[];
    hasMore: boolean;
  };
  currentPostData: IMarketingDeliveryInfo | null;
  error: IApiError | null;
}

export const initialState: MarketingDeliveryState = {
  marketingDelivery: {
    items: [],
    hasMore: true,
  },
  marketingDeliveryInfo: {
    items: [],
    hasMore: true,
  },
  deliveryStatistics: null,
  kolIdFilter: '',
  currentPostData: null,
  isLoading: false,
  error: null,
};
