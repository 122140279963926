import {
  ITopBarSettingsDTO,
  ITopBar,
  ITopBarBase,
} from '@/models/settings.model';
import {
  TopBarsSettingsFieldsNames,
  TTopBarsSettingsValues,
} from '../helpers/types';

class TopBarSettingsDTO implements ITopBarSettingsDTO {
  NFT: ITopBarBase | undefined;
  MARKETING: ITopBar | undefined;
  FUNDING_ROUND: ITopBar | undefined;

  constructor(values: TTopBarsSettingsValues) {
    this.NFT = values[TopBarsSettingsFieldsNames.NFT];
    this.MARKETING = values[TopBarsSettingsFieldsNames.MARKETING];
    this.FUNDING_ROUND = values[TopBarsSettingsFieldsNames.FUNDING_ROUND];
  }
}

export default TopBarSettingsDTO;
