// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  ReportCardWrapper,
  StyledInfoWrapper,
  StyledTitle,
  StyledText,
  StyledCurrency,
} from './CampaignReportCardStyles';
import { ArrowRightIcon } from '@/icons';
import { Link } from '@/ui';

// Modules
import { currenciesConfig, networksConfig } from '@/modules/Web3';
import { MobileOnly } from '@/modules/Adaptive';

// Models
import { WalletNetworksEnum } from '@/models/web3.model';
import { ICampaign } from '@/models/campaigns.model';

// Utils | Helpers
import { findByName, numberWithCommas } from '@/utils';
import { ROUTES_PATHS } from '@/router';
import { IReportsTabsNames } from '../../constants';

interface ICampaignReportCard {
  report: ICampaign;
  isListView?: boolean;
}

const CampaignReportCard = ({ report, isListView }: ICampaignReportCard) => {
  const { t } = useTranslation(['reports']);
  return (
    <ReportCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('reports_list_project_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{report.projectTitle}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('reports_list_campaign_title')}</StyledTitle>
        </MobileOnly>
        <StyledText>{report.title}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('reports_list_budget')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          $ {numberWithCommas(Number(report.marketingBudget) ?? 0)}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('reports_list_budget_booked')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {numberWithCommas(report?.usedBudget?.total ?? 0)} $
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('reports_list_total_payout')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {numberWithCommas(report?.campaignResults?.totalPayout ?? 0)} $
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('reports_list_deliveries')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {report?.campaignResults?.totalDeliveredPosts}/
          {report?.campaignResults?.totalBookedPosts}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('reports_list_network_title')}</StyledTitle>
        </MobileOnly>

        <StyledCurrency>
          <img
            src={
              findByName(currenciesConfig, report?.payoutCurrency ?? '')
                ?.image ?? ''
            }
            width={24}
            height={24}
          />
          <img
            src={
              networksConfig[report?.payoutNetwork as WalletNetworksEnum]
                ?.image || ''
            }
            width={24}
            height={24}
          />
        </StyledCurrency>
      </StyledInfoWrapper>
      {isListView && (
        <StyledInfoWrapper>
          <StyledText>
            <Link
              to={`${ROUTES_PATHS.REPORTS}/${
                IReportsTabsNames.CAMPAIGN
              }/${encodeURIComponent(report.id ?? '')}`}
            >
              <ArrowRightIcon />
            </Link>
          </StyledText>
        </StyledInfoWrapper>
      )}
    </ReportCardWrapper>
  );
};

export default CampaignReportCard;
