/* eslint-disable @typescript-eslint/no-explicit-any */

import { capitalizeString } from './dataFormat';

export const isBelongToEnum = <T>(
  value: string,
  enumObject: { [key: string]: T },
) => Object.values(enumObject).includes(value as unknown as T);

type LowercaseKeys<T> = {
  [K in keyof T as Lowercase<string & K>]: T[K];
};

export function formatObjectKeysCase<T extends Record<string, any>>(
  obj: T,
  method: 'toLowerCase' | 'toUpperCase' | 'capitalize' = 'toLowerCase',
): LowercaseKeys<T> {
  return Object.keys(obj).reduce((accumulator, key) => {
    const formattedKey =
      method === 'capitalize' ? capitalizeString(key) : key[method]();
    accumulator[formattedKey as Lowercase<string & keyof T>] = obj[key];
    return accumulator;
  }, {} as LowercaseKeys<T>);
}

export const isUndefined = (value: string | undefined) => {
  return !value || value === 'undefined';
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
export const noop = (...args: any[]): any => {};
