import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout, Button } from '@/ui';
import { Form, FormItem, Modal } from '@/components';

export const StyledForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  padding-bottom: 2rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    padding-bottom: 0.5rem;
  }
`;

export const StyledInfluencerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const StyledCommunityWrapper = styled(BlockLayout)`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  width: 100%;
`;

export const StyledInfoWrapper = styled(BlockLayout)`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.5rem;
  padding: 1rem;
`;

export const StyledTitle = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  ${pickThemeFontStyles('12', '22', 'BOLD')};
  color: ${COLORS.CORN_FLOWER_BLUE};
  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '22', 'BOLD')};
  }

  :first-child {
    justify-content: flex-start;
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  height: 2rem !important;
`;

export const StyledFormItem = styled(FormItem)`
  width: 100%;
  flex-direction: column;
`;

export const StyledGetButton = styled(Button)`
  display: flex;
  &&& {
    max-width: 100%;
    height: 2rem;
    background: ${COLORS.SOLITUDE_GRAY};
    color: ${COLORS.DARK_BLUE};
    ${pickThemeFontStyles('14', '12', 'SEMI_BOLD')};
    align-items: center;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &&& {
      width: 9.5rem;
    }
  }
`;

export const StyledTitles = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
`;

export const StyledModal = styled(Modal)`
  &&& {
    .ant-modal-content {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  justify-content: space-between;
`;
