// Globals
import React, { ReactNode, useMemo } from 'react';

// Store
import { useAppState } from '@/hooks';

// Components
import { TabsWrapper, SectionTabWrapper } from './TabsStyles';
import SectionTab from '../SectionTab/SectionTab';

// Modules
import { selectAuthIsLoading } from '@/modules/auth';

export interface ISectionConfig {
  label: string;
  name: string;
  key: string;
  icon?: ReactNode;
}

interface IProps {
  sections: ISectionConfig[];
  activeTab?: string;
  onChange: (key: string) => void;
  count?: number;
  withCount?: boolean;
}

const Tabs = ({ sections, activeTab, onChange, count, withCount }: IProps) => {
  const isAuthLoading = useAppState(selectAuthIsLoading);

  const tabsItems = useMemo(
    () =>
      sections.map((section, i) => {
        return (
          <SectionTab
            key={section.label}
            section={section}
            isActive={activeTab === section.key}
            onClick={onChange}
            count={count}
            withCount={withCount && i === 0}
          />
        );
      }),
    [sections, activeTab, count],
  );

  if (isAuthLoading || !tabsItems.length) {
    return null;
  }

  return (
    <TabsWrapper>
      <SectionTabWrapper>{tabsItems}</SectionTabWrapper>
    </TabsWrapper>
  );
};

export default React.memo(Tabs);
