import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import {
  uploadFile,
  uploadCampaignFile,
  uploadFundingRoundFile,
} from './actionCreators';
import { UPLOADERS_SLICE_NAME, initialState, UploadersState } from './models';

export const slice = createSlice({
  name: UPLOADERS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isFulfilled(uploadFile, uploadCampaignFile, uploadFundingRoundFile),
        (state: UploadersState) => {
          state.isLoading = false;
          state.error = null;
        },
      )
      .addMatcher(
        isPending(uploadFile, uploadCampaignFile, uploadFundingRoundFile),
        (state: UploadersState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(uploadFile, uploadCampaignFile, uploadFundingRoundFile),
        (state: UploadersState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
        },
      );
  },
});

export default slice.reducer;
