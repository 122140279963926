import React, { ReactNode } from 'react';
import {
  SignIn,
  Documents,
  DocumentDetails,
  DocumentCreate,
  KolsList,
  KolsDetails,
  PartnersList,
  PartnerDetails,
  RequestsList,
  Settings,
  Dashboard,
  CampaignsList,
  ProjectsList,
  ProjectDetails,
  CampaignDetails,
  SocialChannelData,
  ProofOfDeliveryDetails,
  PayoutList,
  PayoutDetailsList,
  AdminsList,
  AdminDetails,
  FundingRoundList,
  FundingRoundDetails,
  Reports,
  ReportDetails,
  RefundDetails,
} from '@/pages';
import RequestsDetails from '../pages/request-details';
import CompleteCampaignsForTestPurposes from '../pages/__just-for-temporary-test-of-marketing-affiliate';

export enum ROUTES_PATHS {
  HOME = '/',
  KOL_LIST = '/kol-list',
  SOCIAL_CHANNEL = '/social-channel-data',
  HUNTERS_LIST = '/hunters-list',
  PROJECTS_LIST = '/projects-list',
  CAMPAIGNS = '/campaigns',
  FUNDING_ROUNDS = '/funding-rounds',
  REPORTS = '/reports',
  REPORT_DETAILS = '/reports/:section/:id/',
  SIGN_IN = '/sign-in',
  DASHBOARD = '/dashboard',
  ADVISORS = '/advisors',
  SETTINGS = '/settings',
  REQUESTS = '/requests',
  AFFILIATE_SYSTEM = '/affiliate-system',
  PAYOUT = '/payout',
  REFUND = '/refund',
  REFUND_DETAILS = '/refund/:id',
  PAYOUT_DETAILS = '/payout/:section/:id',
  DOCUMENTS = '/documents',
  DOCUMENT_DETAILS = '/documents/:id',
  ADMINS_LIST = '/admins',
  ADMIN_DETAILS = '/admins/:id',
  DOCUMENT_CREATE = '/documents/create',
  KOL_DETAILS = '/kol-list/:id/:section',
  HUNTERS_DETAILS = '/hunters-list/:id/:section',
  PROJECT_DETAILS = '/projects-list/:id/:section',
  PROJECT_DELIVERY_INFO = '/projects-list/:id/:section/:campaignId',
  CAMPAIGN_DETAILS = '/campaigns/:id/:section',
  FUNDING_ROUND_DETAILS = '/funding-rounds/:id',
  REQUESTS_DETAILS = '/requests/:type/:id',
  // TODO: Delete this after Marketing affiliate tests
  COMPLETE_CAMPAIGNS_FOR_TEST_PURPOSES = '/complete-campaigns-for-test-purposes',
}

export enum PublicRoutes {
  SIGN_IN = '/sign-in',
  PRIVACY = '/privacy',
}

export interface IRoute {
  isPrivate: boolean;
  title: string;
  path: ROUTES_PATHS;
  component: ReactNode;
  icon?: JSX.Element;
}

export const routesConfig: IRoute[] = [
  {
    isPrivate: false,
    title: 'Sign In',
    path: ROUTES_PATHS.SIGN_IN,
    component: <SignIn />,
  },
  {
    isPrivate: true,
    title: 'KOLs List',
    path: ROUTES_PATHS.KOL_LIST,
    component: <KolsList />,
  },
  {
    isPrivate: true,
    title: 'Social Channel Data',
    path: ROUTES_PATHS.SOCIAL_CHANNEL,
    component: <SocialChannelData />,
  },
  {
    isPrivate: true,
    title: 'KOLs Details',
    path: ROUTES_PATHS.KOL_DETAILS,
    component: <KolsDetails />,
  },
  {
    isPrivate: true,
    title: 'Partners List',
    path: ROUTES_PATHS.HUNTERS_LIST,
    component: <PartnersList />,
  },
  {
    isPrivate: true,
    title: 'Partners Details',
    path: ROUTES_PATHS.HUNTERS_DETAILS,
    component: <PartnerDetails />,
  },
  {
    isPrivate: true,
    title: 'Campaigns List',
    path: ROUTES_PATHS.CAMPAIGNS,
    component: <CampaignsList />,
  },
  {
    isPrivate: true,
    title: 'Funding Round List',
    path: ROUTES_PATHS.FUNDING_ROUNDS,
    component: <FundingRoundList />,
  },
  {
    isPrivate: true,
    title: 'Campaigns Details',
    path: ROUTES_PATHS.CAMPAIGN_DETAILS,
    component: <CampaignDetails />,
  },
  {
    isPrivate: true,
    title: 'Funding Round Details',
    path: ROUTES_PATHS.FUNDING_ROUND_DETAILS,
    component: <FundingRoundDetails />,
  },
  {
    isPrivate: true,
    title: 'Requests',
    path: ROUTES_PATHS.REQUESTS,
    component: <RequestsList />,
  },
  {
    isPrivate: true,
    title: 'Reports',
    path: ROUTES_PATHS.REPORTS,
    component: <Reports />,
  },
  {
    isPrivate: true,
    title: 'Report Details',
    path: ROUTES_PATHS.REPORT_DETAILS,
    component: <ReportDetails />,
  },
  {
    isPrivate: true,
    title: 'Requests Details',
    path: ROUTES_PATHS.REQUESTS_DETAILS,
    component: <RequestsDetails />,
  },
  {
    isPrivate: true,
    title: 'Settings',
    path: ROUTES_PATHS.SETTINGS,
    component: <Settings />,
  },
  {
    isPrivate: true,
    title: 'Dashboard',
    path: ROUTES_PATHS.DASHBOARD,
    component: <Dashboard />,
  },
  {
    isPrivate: true,
    title: 'Projects List',
    path: ROUTES_PATHS.PROJECTS_LIST,
    component: <ProjectsList />,
  },
  {
    isPrivate: true,
    title: 'Projects Details',
    path: ROUTES_PATHS.PROJECT_DETAILS,
    component: <ProjectDetails />,
  },
  {
    isPrivate: true,
    title: 'Projects Campaign Info',
    path: ROUTES_PATHS.PROJECT_DELIVERY_INFO,
    component: <ProofOfDeliveryDetails />,
  },
  {
    isPrivate: true,
    title: 'Admin`s Details',
    path: ROUTES_PATHS.ADMIN_DETAILS,
    component: <AdminDetails />,
  },
  {
    isPrivate: true,
    title: 'Admins List',
    path: ROUTES_PATHS.ADMINS_LIST,
    component: <AdminsList />,
  },
  {
    isPrivate: true,
    title: 'Payout',
    path: ROUTES_PATHS.PAYOUT,
    component: <PayoutList />,
  },
  {
    isPrivate: true,
    title: 'Payout Details',
    path: ROUTES_PATHS.PAYOUT_DETAILS,
    component: <PayoutDetailsList />,
  },
  {
    isPrivate: true,
    title: 'Payout Details',
    path: ROUTES_PATHS.REFUND_DETAILS,
    component: <RefundDetails />,
  },
  {
    isPrivate: true,
    title: 'Documents',
    path: ROUTES_PATHS.DOCUMENTS,
    component: <Documents />,
  },
  {
    isPrivate: true,
    title: 'Documents - Create New',
    path: ROUTES_PATHS.DOCUMENT_CREATE,
    component: <DocumentCreate />,
  },
  {
    isPrivate: true,
    title: 'Document Details',
    path: ROUTES_PATHS.DOCUMENT_DETAILS,
    component: <DocumentDetails />,
  },
  {
    // TODO: Delete this after Marketing affiliate tests
    isPrivate: true,
    title: 'Complete Campaigns For Test Purposes',
    path: ROUTES_PATHS.COMPLETE_CAMPAIGNS_FOR_TEST_PURPOSES,
    component: <CompleteCampaignsForTestPurposes />,
  },
];
