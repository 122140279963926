/* eslint-disable @typescript-eslint/no-explicit-any */
// Globals
import React from 'react';

// Components
import { StyledInfo, StyledActionSubTitle } from './LogCardStyles';

// Utils
import { capitalizeString } from '@/utils';

type TLogsDiffs = {
  data: any;
};

const LogsDiffs = ({ data }: TLogsDiffs) => {
  const logsDiffs = Object.entries(data).map(([key, value]) => {
    switch (true) {
      case Array.isArray(value):
        return (
          <StyledInfo key={key}>
            <StyledActionSubTitle>
              {capitalizeString(key)}:{' '}
            </StyledActionSubTitle>
            <span>
              {(value as any[]).map((item, idx) => {
                return (
                  <StyledInfo key={item.name}>
                    <StyledActionSubTitle>
                      {capitalizeString(item.name ?? idx.toString())}:{' '}
                    </StyledActionSubTitle>
                    <span>
                      <LogsDiffs data={item} />
                    </span>
                  </StyledInfo>
                );
              })}
            </span>
          </StyledInfo>
        );

      case typeof value === 'object':
        return (
          <StyledInfo key={key}>
            <StyledActionSubTitle>
              {capitalizeString(key)}:{' '}
            </StyledActionSubTitle>
            <span>
              <LogsDiffs data={value} />
            </span>
          </StyledInfo>
        );

      case typeof value === 'string' || typeof value === 'number':
        return (
          <StyledInfo key={key}>
            <StyledActionSubTitle>{key}: </StyledActionSubTitle>
            <span>{value as string}</span>
          </StyledInfo>
        );

      case typeof value === 'boolean':
        return (
          <StyledInfo key={key}>
            <StyledActionSubTitle>{key}: </StyledActionSubTitle>
            <span>{(value as boolean).toString()}</span>
          </StyledInfo>
        );
    }
  });

  return <>{logsDiffs}</>;
};

export default LogsDiffs;
