import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import {
  IUsersStatistics,
  IUsersStatisticsItem,
} from '@/models/dashboard.model';
import { IUsersChartItem } from '@/modules/Dashboard/helpers/types';
import { UsersStatisticTypes } from '@/modules/Dashboard';

const formatUsersStatistics = (
  items: IUsersStatisticsItem[],
  type: UsersStatisticTypes,
  t: TFunction,
): IUsersChartItem[] => {
  const result: IUsersChartItem[] = [];

  items.forEach((obj) => {
    if (obj[type]) {
      Object.keys(obj[type]).forEach((key) => {
        const formattedItem: IUsersChartItem = {
          month: dayjs(obj?.date).subtract(1, 'day').format('MMM'),
          value: obj[type]?.[key as keyof IUsersStatistics] as number,
          label: t(
            `dashboard_${
              type === UsersStatisticTypes.CURRENT ? key : key + 'Total'
            }`,
          ),
          id: t(
            `dashboard_${
              type === UsersStatisticTypes.CURRENT ? key : key + 'Total'
            }`,
          ),
        };
        result.push(formattedItem);
      });
    }
  });

  return result;
};

export default formatUsersStatistics;
