// GLobals
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Components
import { LegalAgreements } from '../../components';

// Model
import {
  LegalAgreementRoles,
  UserActionLogTypes,
  ILegalAgreementResponse,
} from '@/models/sharedProfile.model';

// Helpers
import { getUserId } from '@/utils';
import { useAppDispatch, useAppState } from '@/hooks';
import { getLegalAgreements } from '../../feature/actionCreators';
import { resetLegalAgreement } from '../../feature/slice';
import { profileSliceSelector } from '../../feature/selectors';

interface ILegalAgreementsContainer {
  role: LegalAgreementRoles;
}

const LegalAgreementsContainer = ({ role }: ILegalAgreementsContainer) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { isLoading, legalAgreements } = useAppState(profileSliceSelector);

  useEffect(() => {
    dispatch(
      getLegalAgreements({
        id: getUserId(id ?? ''),
        role: role,
        types: [
          UserActionLogTypes.AFFILIATE_JOINED,
          UserActionLogTypes.SIGNED_UP,
        ],
      }),
    );
  }, [role]);

  useEffect(
    () => (): void => {
      dispatch(resetLegalAgreement());
    },
    [dispatch],
  );

  return (
    <LegalAgreements
      data={legalAgreements as ILegalAgreementResponse[]}
      role={role}
      isLoading={isLoading}
    />
  );
};

export default LegalAgreementsContainer;
