import omit from 'lodash.omit';
import { ApiResponse, httpDelete, httpGet, httpPost } from './httpClient';
import {
  IGetMarketingDeliveryResponse,
  IMarketingDeliveryDetailsDTO,
  IGetMarketingDeliveryDetailsResponse,
  IDeliveryStatisticsDTO,
  IDeliveryStatistics,
  IUpdateCampaignPostDTO,
  IMarketingDeliveryInfo,
  IRemoveCampaignPostDTO,
  MarketingDeliveryEndpoints,
} from '@/models/marketingDelivery.model';

class MarketingDeliveryAPI {
  static getMarketingDeliveryData(
    id: string,
  ): ApiResponse<IGetMarketingDeliveryResponse> {
    return httpGet<IGetMarketingDeliveryResponse>(
      `${MarketingDeliveryEndpoints.GET_PROJECTS}/${encodeURI(
        id,
      )}/marketing-delivery`,
    );
  }

  static getProofOfDeliveryDataDetails(
    data: IMarketingDeliveryDetailsDTO,
  ): ApiResponse<IGetMarketingDeliveryDetailsResponse> {
    return httpGet<IGetMarketingDeliveryDetailsResponse>(
      `${MarketingDeliveryEndpoints.GET_PROJECTS}/${encodeURI(
        data.id ?? '',
      )}/marketing-delivery/${encodeURI(data.campaignId ?? '')}`,
      { ...omit(data, ['campaignId', 'id']) },
    );
  }

  static getDeliveryStatistics(
    data: IDeliveryStatisticsDTO,
  ): ApiResponse<IDeliveryStatistics> {
    return httpGet<IDeliveryStatistics>(
      `${MarketingDeliveryEndpoints.GET_PROJECTS}/${encodeURI(
        data.id ?? '',
      )}/marketing-delivery/${encodeURI(data?.campaignId ?? '')}/statistics`,
    );
  }

  static addCampaignPost(data: IUpdateCampaignPostDTO) {
    return httpPost<IUpdateCampaignPostDTO, IMarketingDeliveryInfo>(
      `${MarketingDeliveryEndpoints.ROOT_CAMPAIGNS}/${encodeURI(
        data?.id ?? '',
      )}/add-post`,
      omit(data, 'id'),
    );
  }

  static updateCampaignPost(data: IUpdateCampaignPostDTO) {
    return httpPost<IUpdateCampaignPostDTO, IMarketingDeliveryInfo>(
      `${MarketingDeliveryEndpoints.ROOT_CAMPAIGNS}/${encodeURI(
        data?.campaignId ?? '',
      )}/update-post`,
      omit(data, 'campaignId'),
    );
  }

  static deleteCampaignPost(data: IRemoveCampaignPostDTO) {
    return httpDelete(
      `${MarketingDeliveryEndpoints.ROOT_CAMPAIGNS}/${encodeURI(
        data?.campaignId ?? '',
      )}/delete-post`,
      omit(data, 'idCampaign'),
    );
  }
}

export default MarketingDeliveryAPI;
