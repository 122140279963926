// Globals
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledHeaderRow,
  StyledSearchWrap,
  StyledBlockLayout,
  StyledList,
  StyledButton,
  StyledBlockTitle,
} from './ProjectsListStyles';
import ProjectItem from '../ProjectItem/ProjectsItem';

// Models
import { IProject } from '@/models/projects.model';

// Helpers | Hooks
import { ROUTES_PATHS } from '@/router';

interface IDashboard {
  isLoading: boolean;
  projectsList: IProject[] | null;
}

const ProjectsList = ({ projectsList }: IDashboard) => {
  const { t } = useTranslation(['dashboard']);
  const navigate = useNavigate();
  const mappedProjects = useMemo(() => {
    return projectsList?.map((project) => (
      <ProjectItem project={project} key={project.id} />
    ));
  }, [projectsList]);

  const goToProjects = () => navigate(ROUTES_PATHS.PROJECTS_LIST);

  return (
    <StyledBlockLayout>
      <StyledHeaderRow>
        <StyledBlockTitle>{t('dashboard_project_overview')}</StyledBlockTitle>
        <StyledSearchWrap>
          <StyledButton onClick={goToProjects}>
            {t('dashboard_all_projects')}
          </StyledButton>
        </StyledSearchWrap>
      </StyledHeaderRow>
      <StyledList>{mappedProjects}</StyledList>
    </StyledBlockLayout>
  );
};

export default ProjectsList;
