// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { convertModelToFormData } from '@/utils';
import { IGetUploadUrlResponse } from '@/models/uploaders.model';

const uploadToCloudfront = async (
  presignedUrlData: IGetUploadUrlResponse,
  value: File,
) => {
  // Important order of form data fields
  const converted = convertModelToFormData({
    'Content-Type': value?.type,
    ...presignedUrlData.fields,
    file: value,
  });

  const requestOptions = {
    method: 'POST',
    body: converted,
    redirect: 'follow',
  };

  // Send files to S3 (without response)
  await fetch(presignedUrlData?.url, requestOptions).catch((error) =>
    console.error('error', error),
  );

  return presignedUrlData?.cloudfrontUrl;
};

export default uploadToCloudfront;
