import {
  DocumentTypes,
  IClickWrapTemplateData,
  ICreateClickWrapTemplateDTO,
  DocumentsEndpoints,
} from '@/models/documents.model';
import { ApiResponse, httpGet, httpPost } from './httpClient';

class DocumentsAPI {
  static getClickWrapTemplates(): ApiResponse<IClickWrapTemplateData[]> {
    return httpGet<IClickWrapTemplateData[]>(
      DocumentsEndpoints.GET_CLICKWRAP_TEMPLATES,
    );
  }

  static getClickWrapTemplate(
    type: DocumentTypes,
  ): ApiResponse<IClickWrapTemplateData> {
    return httpGet<IClickWrapTemplateData>(
      `${DocumentsEndpoints.GET_CLICKWRAP_TEMPLATES}/${type}`,
    );
  }

  static createClickWrapTemplate(
    data: ICreateClickWrapTemplateDTO,
  ): ApiResponse<IClickWrapTemplateData> {
    const { templateType, htmlContent } = data;
    return httpPost(
      `${DocumentsEndpoints.GET_CLICKWRAP_TEMPLATES}/${templateType}`,
      { htmlContent },
    );
  }
}

export default DocumentsAPI;
