// Globals
import React, { ReactNode, useCallback, useMemo, useState } from 'react';

// Helpers
import { StepsContext } from '../../context/StepsContext';
import { fundingRoundSteps } from '../../constants';
import { TLinkedStep, FundingSteps } from '../../types';

interface IMobileDrawerContextProvider {
  children: ReactNode;
}

const StepsContextProvider = ({ children }: IMobileDrawerContextProvider) => {
  const [currentStep, setCurrentStep] = useState<TLinkedStep>(
    fundingRoundSteps[FundingSteps.ELEVATOR_PITCH],
  );

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const goPrev = useCallback(() => {
    setCurrentStep((prevState) => {
      if (!prevState.prev) {
        return prevState;
      }

      return fundingRoundSteps[prevState.prev];
    });

    scrollToTop();
  }, [scrollToTop]);

  const goNext = useCallback(() => {
    setCurrentStep((prevState) => {
      if (!prevState.next) {
        return prevState;
      }

      return fundingRoundSteps[prevState.next];
    });

    scrollToTop();
  }, [scrollToTop]);

  const setCurrenStep = useCallback(
    (value: number) => {
      setCurrentStep(fundingRoundSteps[(value + 1) as FundingSteps]);

      scrollToTop();
    },
    [scrollToTop],
  );

  const value = useMemo(
    () => ({
      currentStep,
      goPrev,
      goNext,
      setCurrenStep,
    }),
    [currentStep, goPrev, goNext, setCurrenStep],
  );

  return (
    <StepsContext.Provider value={value}>{children}</StepsContext.Provider>
  );
};

export default StepsContextProvider;
