import ReactQuill, { ReactQuillProps, UnprivilegedEditor } from 'react-quill';
import { DeltaStatic, Sources } from 'quill';
import 'react-quill/dist/quill.snow.css';
import { modules, formats } from './config';

const TextEditor = ({ onChange, ...props }: ReactQuillProps) => {
  const handleChange = (
    value: string,
    delta: DeltaStatic,
    source: Sources,
    editor: UnprivilegedEditor,
  ) => {
    // Prevent default api reset :
    if (source === 'user') {
      onChange && onChange(value, delta, source, editor);
    }
  };

  return (
    <ReactQuill
      {...props}
      theme="snow"
      onChange={handleChange}
      onBlur={() => {}}
      modules={modules}
      formats={formats}
    />
  );
};

export default TextEditor;
