import { useTranslation } from 'react-i18next';
import {
  useGetEmailRule,
  useLengthValidationRule,
  useGetPhoneRule,
  useGetRequiredRule,
} from '@/hooks';
import { GeneralInfoFieldsNames } from '../helpers';

const useGetFormValidationRules = () => {
  const { t } = useTranslation(['common']);

  const required = useGetRequiredRule({ t });
  const textFieldLength = useLengthValidationRule({ t, min: 3, max: 255 });
  const phone = useGetPhoneRule({ t });
  const email = useGetEmailRule({ t });

  return {
    [GeneralInfoFieldsNames.FIRST_NAME]: [required, textFieldLength],
    [GeneralInfoFieldsNames.LAST_NAME]: [required, textFieldLength],
    [GeneralInfoFieldsNames.ADDRESS]: [required],
    [GeneralInfoFieldsNames.FORM_OF_ADDRESS]: [required],
    [GeneralInfoFieldsNames.PHONE]: [phone],
    [GeneralInfoFieldsNames.EMAIL]: [required, email],
  };
};

export default useGetFormValidationRules;
