import { createSelector } from '@reduxjs/toolkit';
import { UploadersState, UPLOADERS_SLICE_NAME } from './models';
import { RootState } from '../../../store';

export const socialChannelSelector = (state: RootState): UploadersState =>
  state[UPLOADERS_SLICE_NAME];

export const fileLoadingSelector = createSelector(
  socialChannelSelector,
  (state: UploadersState) => state.isLoading,
);
