// Globals
import { useCallback } from 'react';

// Modules
import {
  exportAllPayouts,
  exportPayoutsByCampaignId,
  exportFundingRoundsPayouts,
  exportFundingRoundsRefunds,
  exportFundingRefundById,
  selectDataExportsIsLoading,
} from '@/modules/DataExports';

// Helpers | Hooks
import { PayoutTabKeys } from '../constants';
import { useAppDispatch, useAppState } from '@/hooks';

type TGetExportPayoutAction = {
  activeTab: PayoutTabKeys;
  campaignId?: string;
  fundingId?: string;
};

export const useExportPayoutsData = ({
  activeTab,
  campaignId,
  fundingId,
}: TGetExportPayoutAction) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppState(selectDataExportsIsLoading);

  const handleExport = useCallback(() => {
    switch (activeTab) {
      case PayoutTabKeys.CAMPAIGN:
        if (campaignId) {
          return dispatch(exportPayoutsByCampaignId(campaignId ?? ''));
        }
        return dispatch(exportAllPayouts());
      case PayoutTabKeys.FUNDING_ROUND:
        return dispatch(exportFundingRoundsPayouts());
      case PayoutTabKeys.FUNDING_ROUND_REFUND:
        if (fundingId) {
          return dispatch(exportFundingRefundById(fundingId ?? ''));
        }
        return dispatch(exportFundingRoundsRefunds());
    }
  }, [activeTab, campaignId, fundingId]);

  return { isLoading, handleExport };
};
