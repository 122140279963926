import { Collapse } from 'antd';
import styled from 'styled-components';
import { COLORS, SHADOWS } from '@/theme';
import { DownIcon } from '@/icons';

export const StyledCollapse = styled(Collapse)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;

  &&& .ant-collapse-header[aria-expanded='true'] {
    padding-bottom: 1.5rem;
  }

  &&&.ant-collapse {
    border: 0;
  }
`;

export const StyledPanel = styled(Collapse.Panel)`
  &&& {
    border-radius: 1rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${COLORS.WHITE};
    box-shadow: ${SHADOWS.MEDIUM};
    border: 0;

    .ant-collapse-header {
      padding: 0;
      align-items: center;
    }
  }
`;

export const StyledExpandIcon = styled(DownIcon)<{ $isActive?: boolean }>`
  transition: transform 0.24s;
  transform: rotate(${({ $isActive }) => ($isActive ? '-180deg' : '0deg')});
`;
