// Global
import React from 'react';

// Components
import { Spinner } from '@/ui';
import { RefundRequestDetails, StyledPageWrapper } from '../../components';

// Models
import { IRequestDetails } from '@/models/requests.model';
import { AdminActionsEnum } from '@/models/adminsList.model';
import { LogEntities } from '@/models/logs.model';

// Modules
import { LogContainer } from '@/modules/Logs';
import { usePermissions, usePermissionsRedirect } from '@/modules/Permissions';

interface IRefundRequestContainer {
  request: IRequestDetails | null;
  isLoading: boolean;
}

const RefundRequestContainer = ({
  request,
  isLoading,
}: IRefundRequestContainer) => {
  const [permissionToEdit] = usePermissions(
    AdminActionsEnum.WRITE_REFUND_REQUEST,
  );

  usePermissionsRedirect(AdminActionsEnum.READ_REFUND_REQUEST);
  return (
    <StyledPageWrapper>
      <RefundRequestDetails
        request={request}
        isLoading={isLoading}
        isEditable={permissionToEdit}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <LogContainer entity={LogEntities.REQUESTS} id={request?.id} />
      )}
    </StyledPageWrapper>
  );
};

export default RefundRequestContainer;
