import styled from 'styled-components';
import { LayoutFooter, Menu } from '@/ui';
import { COLORS, pickThemeFontSize } from '@/theme';

export const StyledFooter = styled(LayoutFooter)`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  &.ant-layout-footer {
    background: ${COLORS.DARK_BLUE};
    padding: 0.25rem 1rem 0.35rem 1rem;
  }
`;

export const StyledMobileSideBarMenu = styled(Menu)`
  &&&.ant-menu.ant-menu-vertical {
    width: 100%;
    background: ${COLORS.DARK_BLUE};
    display: flex;
    justify-content: space-between;

    &&& .ant-menu-item {
      display: flex;
      width: 4rem;
      padding: 0;
      align-items: center;

      svg {
        font-size: ${pickThemeFontSize('16')};
        min-width: 4rem;
      }

      &&& .ant-menu-item-icon {
        justify-content: center;
      }

      &&& .ant-menu-title-content {
        display: none;
      }
    }

    &&& .ant-menu-item {
      &&&:has(.activeLink) {
        path {
          fill: ${COLORS.WHITE};
        }

        color: ${COLORS.WHITE};
        background: ${COLORS.DARK_BLUE};
      }
    }

    &&& .ant-menu-submenu {
      width: 4rem;
      svg {
        font-size: ${pickThemeFontSize('16')};
        min-width: 2rem;
        path {
          fill: ${COLORS.ORANGE_PRIMARY};
        }
      }
      &&& .ant-menu-submenu-title {
        width: 4rem;
        &&& .ant-menu-title-content {
          display: none !important;
        }
      }
    }

    &&&.ant-menu-vertical .ant-menu-submenu-title {
      padding-inline: 0rem;
    }

    &&& .ant-menu-submenu-arrow {
      display: none !important;
    }

    &&& li {
      background-color: inherit;
    }
  }
`;
