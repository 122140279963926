// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  CardWrapper,
  StyledTitles,
  StyledColumnCardTitle,
  StyledText,
} from './NFTFundingBucketHistoryStyles';

// Models
import { INftCreditsBucketHistoryItem } from '@/models/settings.model';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Helpers
import { getDateString, numberWithCommas } from '@/utils';
import { DATE_FORMATS } from '@/constants';

interface INFTFundingBucketHistoryCard {
  data: INftCreditsBucketHistoryItem;
}

const NFTFundingBucketHistoryCard = ({
  data,
}: INFTFundingBucketHistoryCard) => {
  const { t } = useTranslation(['settings']);
  const createdAt = getDateString(
    data?.created ?? '',
    DATE_FORMATS.DATE_WITH_DOTS,
  );

  return (
    <CardWrapper>
      <StyledTitles>
        <MobileOnly>
          <StyledColumnCardTitle>
            {t('settings_bucket_history_title')}
          </StyledColumnCardTitle>
        </MobileOnly>
        <StyledText>{data?.title}</StyledText>
      </StyledTitles>

      <StyledTitles>
        <MobileOnly>
          <StyledColumnCardTitle>
            {t('settings_bucket_history_date')}
          </StyledColumnCardTitle>
        </MobileOnly>
        <StyledText>{createdAt}</StyledText>
      </StyledTitles>

      <StyledTitles>
        <MobileOnly>
          <StyledColumnCardTitle>
            {t('settings_bucket_history_used_amount')}
          </StyledColumnCardTitle>
        </MobileOnly>
        <StyledText>$ {numberWithCommas(data?.usedAmount ?? 0)}</StyledText>
      </StyledTitles>

      <StyledTitles>
        <MobileOnly>
          <StyledColumnCardTitle>
            {t('settings_bucket_history_used_by')}
          </StyledColumnCardTitle>
        </MobileOnly>
        <StyledText>{data?.usedBy}</StyledText>
      </StyledTitles>
    </CardWrapper>
  );
};

export default NFTFundingBucketHistoryCard;
