import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { DASHBOARD_SLICE_NAME, DashboardState } from './models';

export const dashboardSliceSelector = (state: RootState): DashboardState =>
  state[DASHBOARD_SLICE_NAME];

export const selectDashboardIsLoading = createSelector(
  dashboardSliceSelector,
  (state: DashboardState) => state.isLoading,
);

export const selectDashboardProjectsList = createSelector(
  dashboardSliceSelector,
  (state: DashboardState) => state.projectsListData?.items,
);
