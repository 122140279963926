// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledTitle,
  StyledBlockWrapper,
  StyledFormItem,
  Input,
  AffiliateInput,
  SettingsWrapper,
} from '../Styles';

// Helpers | Hooks
import { SettingsFieldsNames } from '../../helpers';
import { useGetFormValidationRules } from '../../hooks';

interface IGeneralSettings {
  isEditActive: boolean;
}

const GeneralSettings = ({ isEditActive }: IGeneralSettings) => {
  const { t } = useTranslation(['settings']);

  const validationRules = useGetFormValidationRules();

  return (
    <StyledBlockWrapper>
      <StyledTitle>{t('settings_general_title')}</StyledTitle>

      <SettingsWrapper>
        <StyledFormItem
          name={SettingsFieldsNames.FIRST_STAGE_DAYS}
          rules={validationRules[SettingsFieldsNames.FIRST_STAGE_DAYS]}
          label={t('settings_first_stage_days')}
        >
          <Input disabled={!isEditActive} type="number" />
        </StyledFormItem>
        <StyledFormItem
          name={SettingsFieldsNames.INVESTMENT_TARGET_BIAS_PERCENT}
          rules={
            validationRules[SettingsFieldsNames.INVESTMENT_TARGET_BIAS_PERCENT]
          }
          label={t('settings_bias_percent')}
        >
          <AffiliateInput disabled={!isEditActive} suffix={'%'} type="number" />
        </StyledFormItem>
        <StyledFormItem
          name={SettingsFieldsNames.DAYS_FOR_LABEL_NEW}
          rules={validationRules[SettingsFieldsNames.DAYS_FOR_LABEL_NEW]}
          label={t('settings_days_for_label_new')}
        >
          <Input disabled={!isEditActive} type="number" />
        </StyledFormItem>
        <StyledFormItem
          name={SettingsFieldsNames.HOURS_FOR_CONTRACT_SIGNING}
          rules={
            validationRules[SettingsFieldsNames.HOURS_FOR_CONTRACT_SIGNING]
          }
          label={t('settings_hours_for_contract_signing')}
        >
          <Input disabled={!isEditActive} type="number" />
        </StyledFormItem>
        <StyledFormItem
          name={SettingsFieldsNames.DAYS_FOR_MONEY_TRANSFERRING}
          rules={
            validationRules[SettingsFieldsNames.DAYS_FOR_MONEY_TRANSFERRING]
          }
          label={t('settings_days_for_money_transferring')}
        >
          <Input disabled={!isEditActive} type="number" />
        </StyledFormItem>
        <StyledFormItem
          name={
            SettingsFieldsNames.DAYS_FOR_MONEY_TRANSFERRING_BEFORE_MARKETING_START
          }
          rules={
            validationRules[
              SettingsFieldsNames
                .DAYS_FOR_MONEY_TRANSFERRING_BEFORE_MARKETING_START
            ]
          }
          label={t(
            'settings_days_for_money_transferring_before_marketing_start',
          )}
        >
          <Input disabled={!isEditActive} type="number" />
        </StyledFormItem>
        <StyledFormItem
          name={SettingsFieldsNames.CAMPAIGN_COMMISSION}
          rules={validationRules[SettingsFieldsNames.CAMPAIGN_COMMISSION]}
          label={t('settings_campaign_commission')}
        >
          <Input disabled={!isEditActive} type="number" />
        </StyledFormItem>
        <StyledFormItem
          name={SettingsFieldsNames.CAMPAIGN_SIGN_UP_PERIOD_OFFSET}
          rules={
            validationRules[SettingsFieldsNames.CAMPAIGN_SIGN_UP_PERIOD_OFFSET]
          }
          label={t('settings_participation_threshold')}
        >
          <Input disabled={!isEditActive} type="number" />
        </StyledFormItem>
      </SettingsWrapper>
    </StyledBlockWrapper>
  );
};

export default GeneralSettings;
