// Globals
import React, { useMemo, useRef } from 'react';

// Components
import { useTranslation } from 'react-i18next';
import CampaignReportCard from '../CampaignReportCard/CampaignReportCard';
import { Spinner, StyledSpinnerWrap } from '@/ui';
import {
  ReportsListWrapper,
  StyledTitles,
  StyledInfoTitles,
} from './CampaignReportsListStyles';
import CampaignReportsFilters from '../CamapaignReportsFilters/CampaignReportsFilters';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';

// Models
import { ICampaign } from '@/models/campaigns.model';

// Hooks | Helpers
import { useInfiniteScroll } from '@/hooks';

interface IFundingReportsList {
  reports: ICampaign[] | null;
  isLoading: boolean;
  hasMore: boolean;
  getNextReports: () => void;
}

const CampaignReportsList = ({
  reports,
  getNextReports,
  isLoading,
  hasMore,
}: IFundingReportsList) => {
  const loaderRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(['reports']);

  const mappedRequestsList = useMemo(() => {
    return reports?.map((report) => {
      return <CampaignReportCard report={report} key={report.id} isListView />;
    });
  }, [reports]);

  useInfiniteScroll({
    callback: getNextReports,
    ref: loaderRef,
    isLoading,
    hasMore,
  });

  return (
    <ReportsListWrapper>
      <CampaignReportsFilters />
      <DesktopOnly>
        <StyledInfoTitles>
          <StyledTitles>{t('reports_list_project_title')}</StyledTitles>
          <StyledTitles>{t('reports_list_campaign_title')}</StyledTitles>
          <StyledTitles>{t('reports_list_budget')}</StyledTitles>
          <StyledTitles>{t('reports_list_budget_booked')}</StyledTitles>
          <StyledTitles>{t('reports_list_total_payout')}</StyledTitles>
          <StyledTitles>{t('reports_list_deliveries')}</StyledTitles>
          <StyledTitles>{t('reports_list_network_title')}</StyledTitles>
          <StyledTitles />
        </StyledInfoTitles>
      </DesktopOnly>
      {mappedRequestsList}
      {isLoading && (
        <StyledSpinnerWrap>
          <Spinner />
        </StyledSpinnerWrap>
      )}
      <div ref={loaderRef} />
    </ReportsListWrapper>
  );
};

export default CampaignReportsList;
