// Globals
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Store
import { manualConfirmFundingRoundPayment } from '../../feature/actionCreators';
import { fundingRoundIsLoading } from '../../feature/selectors';

// Components
import {
  StyledTextArea,
  StyledWrapper,
  StyledActionButton,
  StyledTextAreaWrapper,
} from './ManualConfirmPaymentModalStyles';
import { Modal, ModalSize } from '@/components';
import { Title, Text } from '@/ui';

// Modules
import { getLogs } from '@/modules/Logs';

// Models
import {
  FundingRoundParticipationTransactionStatus,
  IFundingRoundParticipation,
} from '@/models/fundingRound.model';
import { LogEntities } from '@/models/logs.model';

// Helpers | Hooks
import { useAppDispatch, useAppState } from '@/hooks';
import { getUserId } from '@/utils';

interface IManualConfirmPaymentModalContainer {
  isOpened: boolean;
  handleClose: () => void;
  transactionToConfirm?: IFundingRoundParticipation['transaction'];
  fundingRoundId?: string;
}

const ManualConfirmPaymentModalContainer = ({
  isOpened,
  handleClose,
  transactionToConfirm,
  fundingRoundId,
}: IManualConfirmPaymentModalContainer) => {
  const { t } = useTranslation(['fundingRound']);
  const [description, setDescription] = useState('');
  const [validation, setValidation] = useState(false);
  const dispatch = useAppDispatch();

  const isLoading = useAppState(fundingRoundIsLoading);

  const onDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDescription(event.target.value);
    event.target.value !== '' && setValidation(false);
  };

  const handleApprove = useCallback(async () => {
    if (!transactionToConfirm) {
      return;
    }

    await dispatch(
      manualConfirmFundingRoundPayment({
        transactionPK: transactionToConfirm.id,
        transactionSK: transactionToConfirm.sk,
        status: FundingRoundParticipationTransactionStatus.SUCCESS,
        reason: description,
      }),
    ).unwrap();

    dispatch(
      getLogs({
        entity: LogEntities.FUNDING_ROUNDS,
        id: getUserId(fundingRoundId ?? ''),
      }),
    );
    handleClose();
  }, [transactionToConfirm, fundingRoundId, description]);

  const handleReject = async () => {
    if (!transactionToConfirm) {
      return;
    }

    if (description === '') {
      setValidation(true);
      return;
    }

    await dispatch(
      manualConfirmFundingRoundPayment({
        transactionPK: transactionToConfirm.id,
        transactionSK: transactionToConfirm.sk,
        status: FundingRoundParticipationTransactionStatus.FAILED,
        reason: description,
      }),
    ).unwrap();
    handleClose();
  };

  return (
    <Modal visible={isOpened} onCancel={handleClose} size={ModalSize.LARGE}>
      <Title $type="h3">
        {t('funding_participation_confirm_payment_modal_title')}
      </Title>

      <div>{t('funding_participation_confirm_payment_modal_description')}</div>

      <StyledTextAreaWrapper>
        {validation && (
          <Text>
            {t(
              'funding_participation_confirm_payment_modal_validation_message',
            )}
          </Text>
        )}
        <StyledTextArea
          $valid={validation}
          rows={4}
          value={description}
          onChange={onDescriptionChange}
        />
        <StyledWrapper>
          <StyledActionButton
            type="primary"
            onClick={handleApprove}
            loading={isLoading}
          >
            {t('funding_participation_confirm_payment_modal_accept')}
          </StyledActionButton>

          <StyledActionButton
            danger
            type="primary"
            onClick={handleReject}
            loading={isLoading}
          >
            {t('funding_participation_confirm_payment_modal_reject')}
          </StyledActionButton>
        </StyledWrapper>
      </StyledTextAreaWrapper>
    </Modal>
  );
};

export default ManualConfirmPaymentModalContainer;
