import styled from 'styled-components';
import { COLORS, DEVICES } from '@/theme';
import { Layout } from '@/ui';

export const StyledPageWrapper = styled.div`
  background: ${COLORS.WHITE};
  min-height: 100vh;
  align-items: center;

  @media screen and ${DEVICES.LAPTOP_S} {
    padding-top: 4.125rem;
    background: initial;
    min-height: 85vh;
    display: flex;
    align-items: flex-start;
  }
`;

export const StyledFormWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: ${COLORS.LIGHTEST_GRAY};
  padding: 1rem;

  &&& input {
    background: ${COLORS.WHITE};
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    max-width: 32.25rem;
    height: 100%;
    padding: 2rem 2.31rem;
    margin-left: 6.875rem;
    box-shadow: 0 0 4px rgba(151, 151, 151, 0.32);
    border-radius: 0.5rem;
  }
`;

export const StyledLayout = styled(Layout)`
  &.ant-layout {
    min-height: 100vh;
    background: ${COLORS.WHITE};
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &.ant-layout {
      background: url('/images/registration.png') no-repeat center center fixed;
      background-size: cover;
    }
  }
`;
