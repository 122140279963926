import {
  FacebookSVGIcon,
  XTwitterIcon,
  InstagramSVGIcon,
  YoutubeSVGIcon,
  RedditSVGIcon,
  TiktokSVGIcon,
  TelegramSVGIcon,
  LinkedinSVGIcon,
} from '@/icons';
import { SocialChannels } from '@/models/socialChannel.model';
import { FormOfAddress } from '@/models/sharedProfile.model';

export const FormOfAddressConfig = [
  {
    value: FormOfAddress.MR,
    label: 'Mr',
  },
  {
    value: FormOfAddress.MRS,
    label: 'Mrs',
  },
  {
    value: FormOfAddress.UNDEFINED,
    label: 'Undefined',
  },
];

export const socialChannelOptions = [
  {
    value: SocialChannels.TWITTER,
    label: SocialChannels.TWITTER,
    icon: <XTwitterIcon />,
  },
  {
    value: SocialChannels.YOUTUBE,
    label: SocialChannels.YOUTUBE,
    icon: <YoutubeSVGIcon />,
  },
  {
    value: SocialChannels.FACEBOOK,
    label: SocialChannels.FACEBOOK,
    icon: <FacebookSVGIcon />,
  },
  {
    value: SocialChannels.INSTAGRAM,
    label: SocialChannels.INSTAGRAM,
    icon: <InstagramSVGIcon />,
  },
];

export const kolSocialChannelOptions = [
  ...socialChannelOptions,
  {
    value: SocialChannels.TIKTOK,
    label: SocialChannels.TIKTOK,
    icon: <TiktokSVGIcon />,
  },
  {
    value: SocialChannels.REDDIT,
    label: SocialChannels.REDDIT,
    icon: <RedditSVGIcon />,
  },
];

export const projectSocialChannelOptions = [
  ...socialChannelOptions,
  {
    value: SocialChannels.TELEGRAM,
    label: SocialChannels.TELEGRAM,
    icon: <TelegramSVGIcon />,
  },
  {
    value: SocialChannels.LINKEDIN,
    label: SocialChannels.LINKEDIN,
    icon: <LinkedinSVGIcon />,
  },
];

export enum CampaignTabsNames {
  PROJECT_DETAILS = 'project-details',
  CAMPAIGN_INFORMATION = 'campaign-information',
}

export const campaignDetailsTabsConfig = [
  {
    label: 'campaign_information',
    key: CampaignTabsNames.CAMPAIGN_INFORMATION,
  },
  {
    label: 'campaign_project_details',
    key: CampaignTabsNames.PROJECT_DETAILS,
  },
];

export const channelsInstructions = {
  [SocialChannels.FACEBOOK]: {
    firstInfo: 'campaign_facebook_info_first',
    secondInfo: 'campaign_facebook_info_second',
    thirdInfo: 'campaign_facebook_info_third',
    fourthInfo: 'campaign_facebook_info_fourth',
  },
  [SocialChannels.TWITTER]: {
    firstInfo: 'campaign_twitter_info_first',
    secondInfo: 'campaign_twitter_info_second',
    thirdInfo: 'campaign_twitter_info_third',
    fourthInfo: 'campaign_twitter_info_fourth',
  },
  [SocialChannels.YOUTUBE]: {
    firstInfo: 'campaign_youtube_info_first',
    secondInfo: 'campaign_youtube_info_second',
    thirdInfo: 'campaign_youtube_info_third',
    fourthInfo: 'campaign_youtube_info_fourth',
  },
  [SocialChannels.INSTAGRAM]: {
    firstInfo: 'campaign_instagram_info_first',
    secondInfo: 'campaign_instagram_info_second',
    thirdInfo: 'campaign_instagram_info_third',
    fourthInfo: 'campaign_instagram_info_fourth',
  },
  // TODO: update with correct guidelines
  [SocialChannels.REDDIT]: {
    firstInfo: 'campaign_instagram_info_first',
    secondInfo: 'campaign_instagram_info_second',
    thirdInfo: 'campaign_instagram_info_third',
    fourthInfo: 'campaign_instagram_info_fourth',
  },
  [SocialChannels.TIKTOK]: {
    firstInfo: 'campaign_instagram_info_first',
    secondInfo: 'campaign_instagram_info_second',
    thirdInfo: 'campaign_instagram_info_third',
    fourthInfo: 'campaign_instagram_info_fourth',
  },
  [SocialChannels.TELEGRAM]: {
    firstInfo: 'campaign_instagram_info_first',
    secondInfo: 'campaign_instagram_info_second',
    thirdInfo: 'campaign_instagram_info_third',
    fourthInfo: 'campaign_instagram_info_fourth',
  },
  [SocialChannels.LINKEDIN]: {
    firstInfo: 'campaign_instagram_info_first',
    secondInfo: 'campaign_instagram_info_second',
    thirdInfo: 'campaign_instagram_info_third',
    fourthInfo: 'campaign_instagram_info_fourth',
  },
};
