import { FormInstance } from 'antd';
import i18n from 'i18next';
import { GeneralInfoFieldsNames } from '@/modules/KolsList/helpers';
import { IFundingRoundFields } from '@/modules/FundingRounds/types';
import { verifyCryptoWalletAddress } from '@/modules/DataLists';
import { AppDispatch } from '../../store';
import { getUserId } from './dataFormat';
import { IVerifyCryptoWalletDTO } from '@/modules/DataLists/feature/models';

const validateWalletFields = async (
  form: FormInstance,
  dispatch: AppDispatch,
  userId?: string,
) => {
  const t = i18n?.t;
  const values = form.getFieldsValue();
  const dto = {
    userId: getUserId(userId ?? ''),
    address:
      values[GeneralInfoFieldsNames.WALLET_ADDRESS] ??
      values[IFundingRoundFields.WALLET_ADDRESS],
    currency: values[GeneralInfoFieldsNames.WALLET_CURRENCY],
    network: values[GeneralInfoFieldsNames.WALLET_NETWORK],
  } as IVerifyCryptoWalletDTO;

  const { isValid } = await dispatch(verifyCryptoWalletAddress(dto)).unwrap();

  if (t) {
    form.setFields([
      {
        name: GeneralInfoFieldsNames.WALLET_ADDRESS,
        errors: isValid
          ? undefined
          : [t('validation_wallet_error', { ns: 'common' })],
        value: values[GeneralInfoFieldsNames.WALLET_ADDRESS],
      },
      {
        name: GeneralInfoFieldsNames.WALLET_CURRENCY,
        errors: isValid ? undefined : [''],
        value: values[GeneralInfoFieldsNames.WALLET_CURRENCY],
      },
      {
        name: GeneralInfoFieldsNames.WALLET_NETWORK,
        errors: isValid ? undefined : [''],
        value: values[GeneralInfoFieldsNames.WALLET_NETWORK],
      },
    ]);
  }

  return isValid;
};

export default validateWalletFields;
