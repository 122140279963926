import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { BlockLayout } from '@/ui';

export const StyledHeader = styled.div`
  display: flex;
  width: 92%;
  height: 1.5rem;
  padding: 0.5rem 1.5rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background: ${COLORS.SOLITUDE_GRAY};
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledBlock = styled(BlockLayout)<{
  $isRow?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ $isRow }) => ($isRow ? 'row' : 'column')};
  align-items: flex-start;
  color: ${COLORS.CORN_FLOWER_BLUE};
  gap: 1rem;
  padding: 1.5rem;
  width: auto;
  position: relative;

  @media screen and ${DEVICES.LAPTOP_S} {
    justify-content: normal;
  }
`;

export const StyledSubTitle = styled.div`
  ${pickThemeFontStyles('18', '22', 'BOLD')};
  align-self: flex-start;
  color: ${COLORS.BLACK};
  word-break: break-all;
`;

export const StyledUl = styled.ul`
  &&& {
    padding-left: 1rem;
    li::marker {
      color: ${COLORS.YELLOW_SEA};
    }
  }
  ${pickThemeFontStyles('14', '22', 'NORMAL')};
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  margin-top: 2rem;
`;

export const StyledChannelsDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  ${pickThemeFontStyles('14', '24', 'SEMI_NORMAL')};

  & ul {
    margin: 0;
    padding: 0 0 0 1rem;
  }

  & h6 {
    margin: 0;
    ${pickThemeFontStyles('14', '24', 'BOLD')};
  }
`;

export const StyledHashTag = styled.span`
  ${pickThemeFontStyles('14', '22', 'NORMAL')};
  color: ${COLORS.YELLOW_SEA};
`;

export const StyledTabsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${COLORS.CORN_FLOWER_BLUE};
  gap: 1rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    justify-content: normal;
  }
`;
