import { useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { PublicRoutes, ROUTES_PATHS } from 'src/router/config';
import { useAppState } from '@/hooks';
import { isBelongToEnum } from '@/utils';
import { selectAuthIsLoading, selectIsAuthorized } from '@/modules/auth';

interface IUseUnauthorizedRedirect {
  pathname?: ROUTES_PATHS;
  query?: Record<string, string>;
}

const useUnauthorizedRedirect = ({
  pathname = ROUTES_PATHS.SIGN_IN,
}: IUseUnauthorizedRedirect) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthorized = useAppState(selectIsAuthorized);
  const isLoading = useAppState(selectAuthIsLoading);
  const isPublicRoute = isBelongToEnum(location.pathname, PublicRoutes);
  const isRouteExist = Object.values(ROUTES_PATHS).some((route) =>
    matchPath(route, location.pathname),
  );

  useEffect(() => {
    if (isAuthorized || isLoading) return;
    if (!isPublicRoute && isRouteExist) {
      navigate(ROUTES_PATHS.SIGN_IN);
    }
  }, [isAuthorized, isLoading, isPublicRoute, isRouteExist, pathname]);
};

export default useUnauthorizedRedirect;
