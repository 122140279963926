// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledInfo,
  StyledSubTitle,
  StyledText,
  StyledDetails,
  StyledWrapper,
} from './CompanyInfoDetailsBlockStyles';
import { Link } from '@/ui';
import KYBStatus from './KYBStatus';
import { StyledFieldsRow } from '../CompanyInfo/CompanyInfoStyles';
import { FormItem, NestedFormItem } from '@/components';

// Modules
import { FileUpload } from '@/modules/Uploaders';

// Utils
import {
  renderUsername,
  getDateString,
  getUserLink,
  buildSynapsLink,
} from '@/utils';

// Models
import { IProject } from '@/models/projects.model';
import { VerificationTypes } from '@/models/sharedProfile.model';

// Helpers
import { isDev } from 'src/config/env';
import { SocialChannels } from '@/models/socialChannel.model';

interface ICompanyInfoDetailsBlock {
  projectDetails: IProject | null;
}

const CompanyInfoDetailsBlock = ({
  projectDetails,
}: ICompanyInfoDetailsBlock) => {
  const { t } = useTranslation(['projectsList']);
  const { firstName, lastName, formAddress } =
    (projectDetails as IProject) ?? {};
  const goToInvitedByDetails = getUserLink(
    projectDetails?.invitedBy?.role ?? '',
    projectDetails?.invitedBy?.id ?? '',
  );

  const synapsSessionId = projectDetails?.KYB?.sessionId;
  const synapsLink = synapsSessionId
    ? buildSynapsLink(
        synapsSessionId,
        isDev ? VerificationTypes.KYB_DEV : VerificationTypes.KYB,
      )
    : '#';

  return (
    <StyledWrapper>
      <StyledInfo>
        <StyledDetails>
          <StyledSubTitle>
            {t('projects_general_info_name_title')}
          </StyledSubTitle>
          <StyledText>
            {renderUsername({
              firstName,
              lastName,
              formAddress,
            })}
          </StyledText>
        </StyledDetails>
        <StyledDetails>
          <StyledSubTitle>
            {t('projects_general_info_KYB_title')}
          </StyledSubTitle>
          <KYBStatus status={projectDetails?.KYB?.state} />
        </StyledDetails>
        <StyledDetails>
          <StyledSubTitle>{t('projects_list_synaps_title')}</StyledSubTitle>
          {synapsSessionId ? (
            <Link target="_blank" to={synapsLink}>
              {t('projects_list_go_synaps_title')}
            </Link>
          ) : (
            '-'
          )}
        </StyledDetails>
        <StyledDetails>
          <StyledSubTitle>
            {t('projects_general_info_registration_date_title')}
          </StyledSubTitle>
          {projectDetails?.created && (
            <StyledText>{getDateString(projectDetails?.created)}</StyledText>
          )}
        </StyledDetails>
      </StyledInfo>

      <StyledInfo>
        <StyledDetails>
          <StyledSubTitle>
            {t('projects_general_info_contact_source')}
          </StyledSubTitle>
          <StyledText>{projectDetails?.mainContact?.source}</StyledText>
        </StyledDetails>
        <StyledDetails>
          <StyledSubTitle>{t('projects_general_info_contact')}</StyledSubTitle>
          <StyledText>{projectDetails?.mainContact?.contact}</StyledText>
        </StyledDetails>
        <StyledDetails>
          <StyledSubTitle>
            {t('projects_general_info_company_type_label')}
          </StyledSubTitle>
          <StyledText>{projectDetails?.companyInfo?.companyType}</StyledText>
        </StyledDetails>
      </StyledInfo>

      <StyledInfo>
        <StyledDetails>
          <StyledSubTitle>
            {t('projects_general_info_email_title')}
          </StyledSubTitle>
          <StyledText>{projectDetails?.email}</StyledText>
        </StyledDetails>
        <StyledDetails>
          <StyledSubTitle>
            {t('projects_general_youtube_available_title')}
          </StyledSubTitle>
          <StyledText>
            {projectDetails?.allowedSocialChannels?.includes(
              SocialChannels.YOUTUBE,
            )
              ? t('projects_general_available_title')
              : t('projects_general_not_available_title')}
          </StyledText>
        </StyledDetails>
        <StyledDetails>
          <StyledSubTitle>
            {t('projects_verification_level_label')}
          </StyledSubTitle>
          <StyledText>{t(projectDetails?.verificationLevel ?? '')}</StyledText>
        </StyledDetails>
        {projectDetails?.invitedBy && (
          <StyledDetails>
            <StyledSubTitle>
              {t('projects_general_info_invited_by')}
            </StyledSubTitle>
            <Link
              to={goToInvitedByDetails ?? ''}
              target="_blank"
            >{`${projectDetails?.invitedBy?.firstName} ${projectDetails?.invitedBy?.lastName}`}</Link>
          </StyledDetails>
        )}

        <StyledFieldsRow>
          <FormItem
            label={t(
              'projects_general_info_certificate_of_incorporation_label',
            )}
          >
            <FileUpload
              disabled={true}
              value={projectDetails?.certificateOfIncorporation}
            />
          </FormItem>
        </StyledFieldsRow>
        <StyledFieldsRow>
          <NestedFormItem
            label={t('projects_general_info_beneficial_ownership_label')}
          >
            <FileUpload
              disabled={true}
              value={projectDetails?.beneficialOwnershipDeclaration}
            />
          </NestedFormItem>
        </StyledFieldsRow>
      </StyledInfo>
    </StyledWrapper>
  );
};

export default CompanyInfoDetailsBlock;
