import { IFormItemProps } from '../components/FormItem/FormItem';
export { getStatuses } from './getStatuses';

export type TFormValidatorRule = NonNullable<IFormItemProps['rules']>[number];

export type TChartDataItem = {
  amount?: number | string;
  label: string;
  value?: number;
  id: string;
};

export interface IBaseItem {
  value: string;
  label: string;
}

export type TTabItem = {
  label: string | JSX.Element;
  value: string;
};
