// Globals
import React, { ReactNode } from 'react';
import { TooltipProps } from 'antd';
import { FlattenSimpleInterpolation } from 'styled-components';

// Components
import { Tooltip } from '@/ui';
import {
  StyledTitle,
  StyledWrapper,
  StyledText,
  StyledInfoBlueIcon,
} from './InfoStyles';

// Modules
import { useIsMobile } from '@/modules/Adaptive';

type TInfoProps = TooltipProps & {
  text?: string | ReactNode;
  title?: string | ReactNode;
  placement?: string;
  wrapperStyles?: FlattenSimpleInterpolation;
  width?: string;
  arrow?: boolean;
};

const Info = ({
  title,
  text,
  arrow = true,
  placement = 'right',
  width = '320px',
}: TInfoProps): JSX.Element => {
  const { isMobile } = useIsMobile();

  return (
    <Tooltip
      overlayInnerStyle={{ width: isMobile ? '270px' : width }}
      getPopupContainer={(triggerNode) => triggerNode}
      arrow={arrow}
      title={
        <StyledWrapper>
          <StyledTitle>{title}</StyledTitle>
          <StyledText>{text}</StyledText>
        </StyledWrapper>
      }
      placement={placement}
    >
      <StyledInfoBlueIcon />
    </Tooltip>
  );
};

export default Info;
