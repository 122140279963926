import { useEffect } from 'react';
import { getUserId } from '@/utils';
import { getRequestCounter } from '@/modules/Requests/feature/actionCreators';
import { RequestersRoles } from '@/models/requests.model';
import { useAppDispatch, useAppState } from '@/hooks';
import { selectCurrentRequestCount } from '../feature/selectors';

const useGetRequestCount = (role?: string, id?: string) => {
  const dispatch = useAppDispatch();
  const count = useAppState(selectCurrentRequestCount);

  useEffect(() => {
    id &&
      dispatch(
        getRequestCounter({
          role: role as RequestersRoles,
          id: getUserId(id ?? ''),
        }),
      );
  }, [id]);

  return count;
};

export default useGetRequestCount;
