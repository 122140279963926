// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledButton } from './FundingRoundRefundCardStyles';
import {
  PayoutCardWrapper,
  StyledInfoWrapper,
  StyledTitle,
  StyledText,
  StyledArrowRightIcon,
  StyledLink,
  StyledBadge,
} from '../Styled/index';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Models
import { IFundingPayoutListItem } from '@/models/payout.model';
import { RequestStatuses } from '@/models/requests.model';

// Helpers
import { getDateString, numberWithCommas } from '@/utils';
import { ROUTES_PATHS } from '@/router';

interface IFundingRoundCard {
  data: IFundingPayoutListItem;
}

const FundingRoundRefundCard = ({ data }: IFundingRoundCard) => {
  const { t } = useTranslation(['payout', 'campaigns']);

  return (
    <PayoutCardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_refund_funding_round')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.title}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_refund_end_date')}</StyledTitle>
        </MobileOnly>
        <StyledText>{getDateString(data?.endDate ?? '')}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_refund_funding_target')}</StyledTitle>
        </MobileOnly>
        <StyledText>{data?.projectTitle}</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_refund_raised')}</StyledTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.raisedAmount ?? 0)} $</StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('payout_funding_refund_total_payout')}</StyledTitle>
        </MobileOnly>
        <StyledText>{numberWithCommas(data?.totalPayout ?? 0)} $</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        {data?.payoutRequest?.status && (
          <StyledBadge
            $status={data?.payoutRequest?.status as unknown as RequestStatuses}
          >
            {t(`campaign_status_${data?.payoutRequest?.status}`, {
              ns: 'campaigns',
            })}
          </StyledBadge>
        )}
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <StyledLink
          to={`${ROUTES_PATHS.REFUND}/${encodeURIComponent(data?.id ?? '')}`}
        >
          <StyledButton type="ghost">
            {t('payout_go_to_refund_button')}
            <StyledArrowRightIcon />
          </StyledButton>
        </StyledLink>
      </StyledInfoWrapper>
    </PayoutCardWrapper>
  );
};

export default FundingRoundRefundCard;
