// Globals
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'antd/lib/form/Form';

// Components
import { FormItem, FormList, IFormInstance } from '@/components';
import {
  FormFields,
  FormFieldsDevider,
  FormFieldsWrapper,
  StyledAddButton,
  StyledDetails,
  StyledIconWrapper,
  StyledSocialChannels,
  StyledText,
  StyledURIError,
} from './SocialChannelsSelectBlockStyles';
import { StyledInput, StyledSelect } from '@/ui';
import { DeleteCircleIcon } from '@/icons';
import ChanelLink from '../ChanelLink/ChanelLink';

// Models
import { ISocialChannel, IProject } from '@/models/projects.model';
import { IKOL } from '@/models/kolsList.model';
import { SocialChannels } from '@/models/socialChannel.model';

// Helpers
import {
  channelsToSkip,
  domainValidation,
  ProjectInformationFieldsNames,
} from '../../helpers';

// Modules
import { socialChannelsList } from '@/modules/KolsList/constants/socialChannels';
import { numbersOnlyRegex } from '@/constants';
import { getSocialChannelOptions } from '@/modules/SharedProfile/helpers/getOptions';
import { UserRoles } from '@/models/admin.model';
import { sortSocialChannels } from '@/utils';

interface IChosenOption {
  name: string;
  link: string;
}

interface IDomainsErrors {
  [index: number]: boolean | undefined;
}

interface ISocialChannelsSelect {
  formInstance: IFormInstance;
  data: IKOL | IProject | null;
  disabled?: boolean;
  isEditActive?: boolean;
  isKOL?: boolean;
  role?: UserRoles;
  requiredSocials?: Array<SocialChannels>;
}

const SocialChannelsSelectBlock = ({
  disabled,
  isEditActive,
  formInstance,
  requiredSocials,
  data,
  role,
}: ISocialChannelsSelect) => {
  const isKOL = role === UserRoles.KOL;
  const { t } = useTranslation(['projectsList', 'common']);
  const placeholder = t('common_placeholder_input', { ns: 'common' });
  const socialChannel = useWatch(ProjectInformationFieldsNames.SOCIAL_CHANNEL, {
    form: formInstance,
    preserve: true,
  });
  const channelOptions = getSocialChannelOptions(t, role);
  const [selectOptions, setSelectOptions] = useState(channelOptions);

  const [chosenOptions, setChosenOptions] = useState<IChosenOption[]>([]);
  const [socialChannelIndex, setSocialChannelIndex] = useState(0);
  const [domainsErrors, setDomainsErrors] = useState<IDomainsErrors>({});

  useEffect(() => {
    const mappedSocialChannelsValues = socialChannel?.filter(
      (item: ISocialChannel) => {
        return { name: item.name, link: item.link };
      },
    );
    setChosenOptions(mappedSocialChannelsValues);

    socialChannel?.forEach((value: ISocialChannel, index: number) => {
      if (socialChannelIndex === index) {
        value.link &&
          !channelsToSkip.has(value.name as SocialChannels) &&
          setDomainsErrors({
            ...domainsErrors,
            [index]: !domainValidation(
              value.name ?? '',
              value.link.toLowerCase(),
            ),
          });
      }
    });
  }, [socialChannel, socialChannelIndex]);

  useEffect(() => {
    const error = Object.values(domainsErrors).some(
      (errors) => errors === true,
    );
    formInstance.setFields([
      {
        name: ProjectInformationFieldsNames.SOCIAL_CHANNEL,
        errors: error ? ['error'] : undefined,
      },
    ]);
  }, [domainsErrors]);

  useEffect(() => {
    const updatedSocialChannels = channelOptions.filter(
      ({ value }) => !chosenOptions?.some((network) => network.name === value),
    );

    setSelectOptions(updatedSocialChannels);
  }, [chosenOptions]);

  useEffect(() => {
    formInstance.setFieldsValue({ socialChannel: data?.socialChannel });
  }, [formInstance, data?.socialChannel]);

  const mappedChannels = useMemo(() => {
    return sortSocialChannels(socialChannelsList).map((id) => {
      const channelData =
        data?.socialChannel &&
        ((data?.socialChannel as ISocialChannel[]).find(
          (channel: ISocialChannel) => channel.name === id,
        ) as ISocialChannel);

      return (
        <ChanelLink
          id={id}
          key={id}
          link={channelData?.link}
          isDisabled={!channelData}
          isExternal
        />
      );
    });
  }, [data?.socialChannel]);

  return !isEditActive ? (
    <StyledDetails>
      <StyledText>
        {t('projects_information_project_social_media_title')}
      </StyledText>
      <StyledSocialChannels>{mappedChannels}</StyledSocialChannels>
    </StyledDetails>
  ) : (
    <FormList
      name={ProjectInformationFieldsNames.SOCIAL_CHANNEL}
      initialValue={socialChannel || [{}]}
    >
      {(fields, { add, remove }) => (
        <FormFieldsWrapper>
          {fields.map((field) => {
            const socialName = formInstance.getFieldValue([
              'socialChannel',
              field.name,
              'name',
            ]);

            const isRequired = requiredSocials?.includes(socialName);

            return (
              <FormFields key={field.name}>
                <FormFieldsDevider>
                  <FormItem
                    name={[field.name, 'name']}
                    label={t('projects_information_social_channels_name')}
                  >
                    <StyledSelect
                      disabled={disabled}
                      options={selectOptions}
                      placeholder={placeholder}
                    />
                  </FormItem>
                  {isKOL && (
                    <FormItem
                      name={[field.name, 'expectedPrice']}
                      label={t(
                        'projects_information_social_channels_expected_price',
                      )}
                      rules={[
                        {
                          pattern: numbersOnlyRegex,
                          message: t('validation_numbers_only', {
                            ns: 'common',
                          }),
                        },
                      ]}
                    >
                      <StyledInput disabled={disabled} />
                    </FormItem>
                  )}
                </FormFieldsDevider>
                <FormFieldsDevider>
                  <FormItem
                    name={[field.name, 'link']}
                    label={t('projects_information_social_channels_link')}
                  >
                    <StyledInput
                      disabled={disabled}
                      onChange={() => setSocialChannelIndex(field.name)}
                    />
                  </FormItem>
                  {domainsErrors[field.name] && (
                    <StyledURIError>
                      {t(
                        'projects_information_social_channels_incorrect_domain',
                      )}
                    </StyledURIError>
                  )}
                </FormFieldsDevider>
                {!disabled && !isRequired && fields.length > 1 && (
                  <StyledIconWrapper onClick={() => remove(field.name)}>
                    <DeleteCircleIcon />
                  </StyledIconWrapper>
                )}
              </FormFields>
            );
          })}

          {fields.length < channelOptions.length && (
            <StyledAddButton
              type="primary"
              onClick={disabled ? () => {} : add}
              disabled={disabled}
            >
              {t('projects_information_social_channels_add')}
            </StyledAddButton>
          )}
        </FormFieldsWrapper>
      )}
    </FormList>
  );
};

export default SocialChannelsSelectBlock;
