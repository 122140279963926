// Globals
import React from 'react';

// Components
import { StyledSearchWrapper } from './SearchInfoInputStyles';
import { SearchInput } from '@/components';

interface ISearchInfoInput {
  handleSearch: (value: string) => void;
}

const SearchInfoInput = ({ handleSearch }: ISearchInfoInput) => {
  return (
    <StyledSearchWrapper>
      <SearchInput handleChange={handleSearch} />
    </StyledSearchWrapper>
  );
};

export default SearchInfoInput;
