import { IApiError } from '@/models/apiError.model';
import {
  FundingRoundParticipantsReportResponse,
  ICampaignReportsFilterOptionsResponse,
  ReportAffiliateResponse,
  IFundingReport,
  IFundingRoundFilterOptionsResponse,
  IGetCampaignReportsListDTO,
  IGetFundingReportsListDTO,
  TFundingRoundsReportGeneralDetailData,
  CampaignParticipantsReportResponse,
} from '@/models/reports.model';
import { ICampaign } from '@/models/campaigns.model';

export const REPORTS_SLICE_NAME = 'reports';

export interface ReportsState {
  fundingReports: {
    items: IFundingReport[] | null;
    hasMore: boolean;
  };
  campaignReports: {
    items: ICampaign[] | null;
    hasMore: boolean;
  };
  campaignDetails: ICampaign | null;
  reportsFilters: IGetFundingReportsListDTO;
  campaignReportsFilters: IGetCampaignReportsListDTO;
  fundingFiltersOptions: IFundingRoundFilterOptionsResponse | null;
  campaignFiltersOptions: ICampaignReportsFilterOptionsResponse | null;
  fundingReportDetails: TFundingRoundsReportGeneralDetailData | null;
  reportAffiliateList: ReportAffiliateResponse | null;
  isLoading: boolean;
  fundingReportParticipants: FundingRoundParticipantsReportResponse | null;
  campaignReportParticipants: CampaignParticipantsReportResponse | null;
  error: IApiError | null;
}

export const initialState: ReportsState = {
  fundingReports: {
    items: [],
    hasMore: true,
  },
  campaignReports: {
    items: [],
    hasMore: true,
  },
  campaignDetails: null,
  fundingReportDetails: null,
  fundingFiltersOptions: null,
  campaignFiltersOptions: null,
  reportsFilters: {
    projectId: undefined,
    fundingRoundId: undefined,
  },
  campaignReportsFilters: {
    projectId: undefined,
    campaignId: undefined,
  },
  reportAffiliateList: null,
  fundingReportParticipants: null,
  campaignReportParticipants: null,
  isLoading: false,
  error: null,
};
