import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';
import { Select, Slider } from '@/ui';
import { Modal } from '@/components';

export const StyledModal = styled(Modal)`
  &&& .ant-modal-content {
    padding-top: 3rem;
  }
`;

export const StyledPageWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
`;

export const SliderWrapper = styled.div<{ isSmaller?: boolean }>`
  position: relative;
  margin-bottom: 1rem;
  width: ${({ isSmaller }) => (isSmaller ? '60%' : '100%')};
`;

export const StyledSlider = styled(Slider)`
  width: 100%;
  position: absolute;
  height: 0.625rem;
  padding: 0rem;
  margin: 0rem;

  &&&.ant-slider .ant-slider-track {
    border-radius: 1rem;
    height: 0.625rem;
    background: linear-gradient(
      89.76deg,
      ${COLORS.BRIGHT_ORANGE} 2.3%,
      ${COLORS.HIGHLIGHTER_ORANGE} 97.85%
    );
  }

  &&&.ant-slider .ant-slider-rail {
    height: 0.625rem;
    border: 1px solid ${COLORS.SOLITUDE_GRAY};
    border-radius: 1rem;
    background-color: ${COLORS.WHITE};
  }

  &&&.ant-slider .ant-slider-handle::after {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    width: 24px;
  }

  &&&.ant-slider .ant-slider-handle {
    display: none;
  }
`;

export const StyledRangePercent = styled.div<{ width?: number }>`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  height: 0.625rem;
  position: absolute;
  justify-content: flex-end;
  color: ${COLORS.CORN_FLOWER_BLUE};
  ${pickThemeFontStyles('10', '20', 'SEMI_BOLD')};
  align-self: flex-start;
  ${({ width }) => {
    if ((width ?? 0) < 5) {
      return `
        width: 100%;
        justify-content: flex-start;
      `;
    }
    return `width: ${width + '%'};`;
  }};
`;

export const StyledChannels = styled.div<{ $isActive?: boolean }>`
  display: flex;
  transform: scale(0.8);
  margin-top: -0.3rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid
    ${({ $isActive }) => ($isActive ? COLORS.GREEN_MINT : COLORS.LIGHTEST_GRAY)};
  background: ${({ $isActive }) =>
    $isActive ? COLORS.GREEN_LIGHT : COLORS.WHITE_SMOKE};
`;

export const StyledSelect = styled(Select)`
  &.ant-select {
    &-single {
      & .ant-select-selector {
        background: ${COLORS.LIGHTEST_GRAY};
        height: 2.875rem;
        border: 0;
        & .ant-select-selection-placeholder {
          ${pickThemeFontStyles('14', '14', 'SEMI_NORMAL')};
          margin: auto;
        }
        & .ant-select-selection-item {
          width: 1rem;
          ${pickThemeFontStyles('14', '16', 'SEMI_NORMAL')};
          margin: auto;
        }
        & .ant-select-selection-search {
          input {
            height: 100%;
          }
        }
      }
    }
  }
`;

export const HorizontalDivider = styled.div`
  height: 1px;
  background: ${COLORS.SOLITUDE_GRAY};
  width: 100%;
  margin: 0.625rem 0;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin: 1rem 0rem 5rem 0rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    justify-content: flex-end;
  }
`;

export const FormTextWrapper = styled.div`
  width: 100%;
`;

export const StyledTitle = styled.h2`
  ${pickThemeFontStyles('18', '22', 'BOLD')};
  color: ${COLORS.GREY};
`;

export const StyledDescription = styled.p`
  ${pickThemeFontStyles('14', '22', 'NORMAL')};
  color: ${COLORS.GREY};
  margin: 1rem 0 0 0;
`;
