import styled from 'styled-components';
import { COLORS } from '@/theme';

export const StyledSearchWrapper = styled.div`
  display: flex;
  gap: 1rem;
  min-width: 10rem;

  svg {
    path {
      fill: ${COLORS.TUNDORA};
    }
  }
`;
