import { AccountStatuses } from '@/constants';

export const isProjectDisabled = (status?: string) =>
  status === AccountStatuses.CANCELED || status === AccountStatuses.ARCHIVED;

export const isEmailDisabled = (status?: string) =>
  status === AccountStatuses.NOT_VERIFIED ||
  status === AccountStatuses.IN_VERIFICATION ||
  status === AccountStatuses.PENDING;

export const isTGEDateDisabled = (
  status?: string,
  isTGEConfirmed?: boolean,
) => {
  switch (status) {
    case AccountStatuses.PAUSED:
    case AccountStatuses.VERIFIED:
      return isTGEConfirmed ? true : false;
    case AccountStatuses.PENDING:
    case AccountStatuses.NOT_VERIFIED:
    case AccountStatuses.IN_VERIFICATION:
      return false;
    default:
      return true;
  }
};
