// React
import React, { useMemo } from 'react';
import dayjs from 'dayjs';

// Components
import {
  StyledContentWrapper,
  StyledContentplanWrapper,
  StyledWrapper,
} from './ContentDistributionStyles';
import HeaderBlocks from '../HeaderBlocks/HeaderBlocks';
import ContentDistributionTable from '../ContentDistributionTable/ContentDistributionTable';

// Models
import { ICampaign, ICampaignSocialChannel } from '@/models/campaigns.model';
import { IAverageSocialDataItem } from '@/models/dataLists.model';
import {
  AverageSocialDataTypes,
  IAverageSocialData,
} from '@/models/settings.model';

// Helpers | Hooks
import { getDateString } from '@/utils';
import { getAveragePostsAmount } from '../../helpers/contentplan';
import { DATE_FORMATS } from '@/constants';
import { weeksRounder } from '../../helpers';

interface IContentDistribution {
  campaign?: ICampaign;
  handleSubmit?: () => void;
  averageSocialData: IAverageSocialData | null;
  socialChannels: ICampaignSocialChannel[];
}

const ContentDistribution = ({
  campaign,
  socialChannels,
  averageSocialData,
}: IContentDistribution) => {
  const marketingStartDate = getDateString(
    campaign?.marketingStartDate ?? '',
    DATE_FORMATS.DATE_WITH_DOTS,
  );
  const marketingEndDate = getDateString(
    campaign?.marketingEndDate ?? '',
    DATE_FORMATS.DATE_WITH_DOTS,
  );
  const weeksAmount = weeksRounder(
    dayjs(campaign?.marketingEndDate).diff(
      dayjs(campaign?.marketingStartDate),
      'week',
      true,
    ),
  );

  const totalAverageDelivery = useMemo(
    () =>
      getAveragePostsAmount(
        socialChannels,
        averageSocialData?.[
          AverageSocialDataTypes.POST_PRICES
        ] as unknown as IAverageSocialDataItem,
        campaign?.marketingBudget ?? 0,
      ),
    [averageSocialData?.POST_PRICES, campaign?.marketingBudget, socialChannels],
  );

  const weeklyAverageDelivery = useMemo(
    () =>
      Object.keys(totalAverageDelivery).reduce((result, key) => {
        result[key] = totalAverageDelivery[key] / weeksAmount || 0;
        return result;
      }, {} as IAverageSocialDataItem),
    [totalAverageDelivery, weeksAmount],
  );

  const totalImpressions = useMemo(
    () =>
      Object.keys(totalAverageDelivery).reduce((result, key) => {
        const channelAverageImpressions =
          Number(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            averageSocialData?.[AverageSocialDataTypes.IMPRESSION][
              key
            ] as unknown as IAverageSocialDataItem,
          ) || 0;
        result[key] =
          Math.floor(totalAverageDelivery[key]) *
            Math.floor(channelAverageImpressions) || 0;
        return result;
      }, {} as IAverageSocialDataItem),
    [averageSocialData?.IMPRESSION, totalAverageDelivery],
  );

  return (
    <StyledWrapper>
      <HeaderBlocks
        marketingStartDate={marketingStartDate}
        marketingEndDate={marketingEndDate}
      />
      <StyledContentWrapper>
        <StyledContentplanWrapper>
          <ContentDistributionTable
            totalAverageDelivery={totalAverageDelivery}
            weeklyAverageDelivery={weeklyAverageDelivery}
            totalImpressions={totalImpressions}
          />
        </StyledContentplanWrapper>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

export default ContentDistribution;
