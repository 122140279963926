// Globals
import React from 'react';

// Components
import StepsContextProvider from '../StepsContextProvider/StepsContextProvider';
import FundingRoundDetailsContainer from '../FundingRoundDetailsContainer/FundingRoundDetailsContainer';

const FundingDetailsRoot = () => {
  return (
    <StepsContextProvider>
      <FundingRoundDetailsContainer />
    </StepsContextProvider>
  );
};

export default FundingDetailsRoot;
