import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import {
  SHARED_PROFILE_SLICE_NAME,
  SharedProfileState,
  initialState,
} from './models';
import { showApiErrors } from '@/utils';
import {
  getLegalAgreements,
  getWithdrawalHistory,
  postCreditBonus,
  getSeedHunterCreditList,
  getFundingRoundAffiliateList,
  forceBlockUser,
  forceUnblockUser,
} from './actionCreators';
import {
  ICreditHistory,
  IFundingRoundAffiliate,
} from '@/models/sharedProfile.model';

export const slice = createSlice({
  name: SHARED_PROFILE_SLICE_NAME,
  initialState,
  reducers: {
    resetLegalAgreement(state) {
      state.legalAgreements = initialState.legalAgreements;
    },
    resetSeedHunterCreditsList(state) {
      state.creditHistory = initialState.creditHistory;
    },
    resetAffiliateFindingList(state) {
      state.fundingRoundAffiliateList = initialState.fundingRoundAffiliateList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getLegalAgreements.fulfilled,
        (state: SharedProfileState, action) => {
          state.legalAgreements = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        getSeedHunterCreditList.fulfilled,
        (state: SharedProfileState, action) => {
          state.creditHistory = {
            items: (action.meta.arg.startId
              ? [...(state.creditHistory?.items ?? []), ...action.payload.items]
              : [...action.payload.items]) as ICreditHistory[],
            hasMore: action.payload.hasMore,
            totalBalance: action.payload.totalBalance,
          };
          state.isLoading = false;
        },
      )
      .addCase(
        getFundingRoundAffiliateList.fulfilled,
        (state: SharedProfileState, action) => {
          state.fundingRoundAffiliateList = {
            items: (action.meta.arg.startSk
              ? [...(state.creditHistory?.items ?? []), ...action.payload.items]
              : [...action.payload.items]) as IFundingRoundAffiliate[],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addCase(
        getWithdrawalHistory.fulfilled,
        (state: SharedProfileState, action) => {
          state.withdrawalHistory = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isPending(
          getLegalAgreements,
          getWithdrawalHistory,
          postCreditBonus,
          getSeedHunterCreditList,
          getFundingRoundAffiliateList,
          forceBlockUser,
          forceUnblockUser,
        ),
        (state: SharedProfileState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(
          getLegalAgreements,
          getWithdrawalHistory,
          postCreditBonus,
          getSeedHunterCreditList,
          getFundingRoundAffiliateList,
          forceBlockUser,
          forceUnblockUser,
        ),
        (state: SharedProfileState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export const {
  resetLegalAgreement,
  resetSeedHunterCreditsList,
  resetAffiliateFindingList,
} = slice.actions;
export default slice.reducer;
