// Globals
import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { StyledItem } from './CampaignResultsStyles';

// Modules
import { DesktopOnly } from '@/modules/Adaptive';
import { socialChannelsConfig } from '@/modules/KolsList';

interface ISocialChannelsTitles {
  channel: string;
}

const SocialChannelsTitles = ({ channel }: ISocialChannelsTitles) => {
  const { t } = useTranslation(['common']);

  return (
    <StyledItem $isCentered>
      {socialChannelsConfig[channel]?.icon}
      <DesktopOnly>
        <>{t(channel, { ns: 'common' })}</>
      </DesktopOnly>
    </StyledItem>
  );
};

export default SocialChannelsTitles;
