import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useTabs = (
  defaultActiveTab?: string,
  getUrl?: (key: string) => string,
  local = false,
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initialTab = defaultActiveTab || params.get('tab');

  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    if (activeTab && !local) {
      const newParams = new URLSearchParams(location.search);
      newParams.set('tab', activeTab);

      navigate(
        {
          search: newParams.toString(),
        },
        { replace: true },
      );
    }
  }, [activeTab, location.search, navigate]);

  const onChange = (key: string) => {
    setActiveTab(key);
    if (getUrl) {
      navigate(getUrl(key));
    }
  };

  return { activeTab, onChange };
};

export default useTabs;
