// Global
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component
import {
  StyledInfoWrapper,
  CardWrapper,
  StyledText,
  StyledTitle,
} from '../Styles/Styles';
import { Tooltip } from '@/ui';
import { ContactBar } from '@/components';

// Modules
import { MobileOnly } from '@/modules/Adaptive';

// Models
import { FundingRoundParticipantsReportItem } from '@/models/reports.model';

// Helpers
import { getDateString, numberWithCommas } from '@/utils';
import { DATE_FORMATS } from '@/constants';

interface IFundingParticipationCard {
  participation: FundingRoundParticipantsReportItem;
}

const FundingParticipationCard = ({
  participation,
}: IFundingParticipationCard) => {
  const { t } = useTranslation(['reports']);
  const date = getDateString(
    participation?.acceptedAt ?? '',
    DATE_FORMATS.DATE_WITH_DOTS,
  );

  return (
    <CardWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>
            {t('report_details_participation_participant')}
          </StyledTitle>
        </MobileOnly>
        <StyledText>{`${participation?.participantFirstName} ${participation?.participantLastName}`}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('report_details_participation_date')}</StyledTitle>
        </MobileOnly>
        <StyledText>{date}</StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('report_details_participation_contact')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          <ContactBar
            data={{
              mainContact: participation.participantMainContact,
              email: participation.participantEmail,
            }}
          />
        </StyledText>
      </StyledInfoWrapper>

      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('report_details_participation_amount')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          $ {numberWithCommas(participation?.totalParticipationAmount ?? 0)}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>
            {t('report_details_participation_credits_used')}
          </StyledTitle>
        </MobileOnly>
        <StyledText>
          $ {numberWithCommas(participation?.amountFromBonusCredit ?? 0)}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('report_details_participant_tokens')}</StyledTitle>
        </MobileOnly>
        <StyledText>
          {numberWithCommas(
            (participation?.totalParticipationAmount ?? 0) /
              (participation?.pricePerToken ?? 0),
          )}
        </StyledText>
      </StyledInfoWrapper>
      <StyledInfoWrapper>
        <MobileOnly>
          <StyledTitle>{t('report_details_participant_wallet')}</StyledTitle>
        </MobileOnly>
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode}
          title={participation?.walletAddressForReceivingTokens}
          placement={'top'}
        >
          <StyledText>
            {participation?.walletAddressForReceivingTokens}
          </StyledText>
        </Tooltip>
      </StyledInfoWrapper>
    </CardWrapper>
  );
};

export default FundingParticipationCard;
