// Globals
import React from 'react';

// Modules
import { CampaignDetails } from '@/modules/SharedProfile';

// Models
import { ICampaign } from '@/models/campaigns.model';

// Components
import { ModalSize } from '@/components';
import { StyledModal } from '../Styled';

interface ICampaignDetailsModal {
  isOpened: boolean;
  handleClose: () => void;
  campaign?: ICampaign;
}

const CampaignDetailsModal = ({
  isOpened,
  handleClose,
  campaign,
}: ICampaignDetailsModal) => {
  return (
    <StyledModal
      visible={isOpened}
      onCancel={handleClose}
      size={ModalSize.HUGE}
    >
      <CampaignDetails campaign={campaign as ICampaign} />
    </StyledModal>
  );
};

export default CampaignDetailsModal;
