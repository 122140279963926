// Globals
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import {
  StyledBadge,
  StyledButton,
  StyledInfoWrapper,
  StyledRoundArrowsIcon,
  StyledTransferButton,
} from './FundingRefundDetailsCardStyles';
import PayoutTransferModal from './PayoutTransferModal';
import { StyledText } from '../Styled';

// Models
import { PayoutStatuses } from '@/models/payout.model';
import { FundingRoundParticipantsReportItem } from '@/models/reports.model';
import { WalletNetworksEnum } from '@/models/web3.model';

// Helpers
import { useAppDispatch, useModal } from '@/hooks';
import { refundFundingRoundTransaction } from '../../feature/actionCreators';

interface IPayoutActionsBadges {
  data: FundingRoundParticipantsReportItem;
}

const RefundTransactionActions = ({ data }: IPayoutActionsBadges) => {
  const { t } = useTranslation(['payout']);
  const { isOpened, openModal, closeModal } = useModal();
  const dispatch = useAppDispatch();

  const startTransaction = useCallback(() => {
    data?.participationId &&
      data?.participationSk &&
      dispatch(
        refundFundingRoundTransaction({
          participationId: data?.participationId,
          participationSk: data?.participationSk,
        }),
      );
  }, [data]);

  if (data?.refundTransaction?.status) {
    return (
      <StyledInfoWrapper>
        <StyledBadge $status={data?.refundTransaction?.status}>
          <StyledText>
            {t(`payout_${data?.refundTransaction?.status}_title`)}
          </StyledText>
        </StyledBadge>
        {data?.refundTransaction?.status === PayoutStatuses.REJECTED && (
          <StyledButton type="ghost" onClick={startTransaction}>
            {t('payout_try_again_button')}
            <StyledRoundArrowsIcon />
          </StyledButton>
        )}
      </StyledInfoWrapper>
    );
  }

  return (
    <StyledInfoWrapper>
      <StyledTransferButton type="primary" onClick={openModal}>
        {t('payout_start_transaction_button')}
      </StyledTransferButton>

      <PayoutTransferModal
        isOpened={isOpened}
        handleClose={closeModal}
        isEthereum={data.network === WalletNetworksEnum.ETHEREUM}
        startTransaction={startTransaction}
        walletAddress={data?.walletAddressForReceivingTokens}
      />
    </StyledInfoWrapper>
  );
};

export default RefundTransactionActions;
