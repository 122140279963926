import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import { LOGS_SLICE_NAME, LogsState, initialState } from './models';
import { getLogs, getSettingsLogs } from './actionCreators';
import { showApiErrors } from '@/utils';
import { ILogs } from '@/models/logs.model';

export const slice = createSlice({
  name: LOGS_SLICE_NAME,
  initialState,
  reducers: {
    resetLogsList(state) {
      state.logs = initialState.logs;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isFulfilled(getLogs, getSettingsLogs),
        (state: LogsState, action) => {
          state.logs = {
            items: (action.meta.arg.startSk
              ? [...(state.logs?.items ?? []), ...action.payload.items]
              : [...action.payload.items]) as ILogs[],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addMatcher(isPending(getLogs, getSettingsLogs), (state: LogsState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(
        isRejected(getLogs, getSettingsLogs),
        (state: LogsState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export const { resetLogsList } = slice.actions;

export default slice.reducer;
