import { useTranslation } from 'react-i18next';
import { useGetRequiredRule, useGetUrlRule } from '@/hooks';
import { AddCampaignPostsFieldsNames } from '../types';

const useGetCampaignPostValidationRules = () => {
  const { t } = useTranslation(['common']);

  const required = useGetRequiredRule({ t });
  const url = useGetUrlRule({ t });

  return {
    [AddCampaignPostsFieldsNames.KOL_ID]: [required],
    [AddCampaignPostsFieldsNames.DATE]: [required],
    [AddCampaignPostsFieldsNames.SOCIAL_CHANNEL]: [required],
    [AddCampaignPostsFieldsNames.CHANNEL_LINK]: [required, url],
  };
};

export default useGetCampaignPostValidationRules;
