import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Modal, ModalSize } from '@/components';
import { Title, Text } from '@/ui';
import {
  StyledTextArea,
  StyledTextAreaWrapper,
  StyledButton,
} from './StatusChangeBlockStyled';

interface IChangeStatusModal {
  isOpened: boolean;
  handleClose: () => void;
  handleRequest: (description: string, status?: string) => Promise<void>;
  withStatus: boolean;
  selectedStatus: string;
}

const ChangeStatusModal = ({
  isOpened,
  handleClose,
  handleRequest,
  withStatus,
  selectedStatus,
}: IChangeStatusModal) => {
  const { t } = useTranslation(['common']);
  const [description, setDescription] = useState('');
  const [isValid, setIsValid] = useState(true);

  const onDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDescription(event.target.value);
    event.target.value !== '' && setIsValid(true);
  };

  const handleBlockUser = async () => {
    if (description === '') {
      setIsValid(false);
    } else {
      withStatus
        ? await handleRequest(description, selectedStatus)
        : await handleRequest(description);
      setDescription('');
      handleClose();
    }
  };

  return (
    <Modal visible={isOpened} onCancel={handleClose} size={ModalSize.LARGE}>
      <Title $type="h3">{t(`status_${selectedStatus}_text`)}</Title>
      <StyledTextAreaWrapper>
        {!isValid && <Text>{t(`status_${selectedStatus}_title`)}</Text>}
        <StyledTextArea
          $valid={isValid}
          rows={4}
          value={description}
          onChange={onDescriptionChange}
        />
        <StyledButton type="primary" onClick={handleBlockUser}>
          {t(`status_${selectedStatus}`)}
        </StyledButton>
      </StyledTextAreaWrapper>
    </Modal>
  );
};

export default ChangeStatusModal;
