import { ApiResponse, httpGet, httpPost } from './httpClient';
import {
  FundingRoundPayoutItemsResponse,
  IFundingPayoutItem,
  IFundingPayoutListItem,
  IFundingRoundRequestDTO,
  IPayoutDetails,
  IPayoutDetailsListDTO,
  IPayoutDetailsListResponse,
  IPayoutFundingRoundTransactionDTO,
  IPayoutList,
  IPayoutTransactionDTO,
  PayoutEndpoints,
} from '@/models/payout.model';
import {
  FundingRoundParticipantsReportItem,
  FundingRoundParticipantsReportResponse,
  FundingRoundParticipationRefundTransactionRequestDTO,
} from '@/models/reports.model';

class PayoutAPI {
  static getPayoutList(): ApiResponse<IPayoutList[]> {
    return httpGet<IPayoutList[]>(PayoutEndpoints.GET_PAYOUT_LIST);
  }

  static getFundingPayoutList(): ApiResponse<IFundingPayoutListItem[]> {
    return httpGet<IFundingPayoutListItem[]>(
      PayoutEndpoints.GET_FUNDING_PAYOUT_LIST,
    );
  }

  static getFundingRefundList(): ApiResponse<IFundingPayoutListItem[]> {
    return httpGet<IFundingPayoutListItem[]>(
      PayoutEndpoints.GET_FUNDING_REFUND_LIST,
    );
  }

  static getPayoutDetailsList(
    data: IPayoutDetailsListDTO,
  ): ApiResponse<IPayoutDetailsListResponse> {
    return httpGet<IPayoutDetailsListResponse>(
      `${PayoutEndpoints.GET_PAYOUT_LIST}/${encodeURI(data?.campaignId ?? '')}`,
      {
        ...data,
      },
    );
  }

  static getFundingPayoutDetailsList(
    data: IFundingRoundRequestDTO,
  ): ApiResponse<FundingRoundPayoutItemsResponse> {
    return httpGet<FundingRoundPayoutItemsResponse>(
      `${PayoutEndpoints.GET_FUNDING_PAYOUT_LIST}/${encodeURI(
        data?.fundingRoundId ?? '',
      )}`,
    );
  }

  static getFundingRefundDetails(
    data: IFundingRoundRequestDTO,
  ): ApiResponse<FundingRoundParticipantsReportResponse> {
    return httpGet<FundingRoundParticipantsReportResponse>(
      `${PayoutEndpoints.GET_FUNDING_REFUND_DETAILS}/${encodeURI(
        data?.fundingRoundId ?? '',
      )}`,
    );
  }

  static payoutTransaction(data: IPayoutTransactionDTO) {
    return httpPost<IPayoutTransactionDTO, IPayoutDetails>(
      PayoutEndpoints.POST_PAYOUT_TRANSACTIONS,
      data,
    );
  }

  static payoutFundingRoundTransaction(
    data: IPayoutFundingRoundTransactionDTO,
  ) {
    return httpPost<IPayoutFundingRoundTransactionDTO, IFundingPayoutItem>(
      PayoutEndpoints.POST_PAYOUT_FUNDING_TRANSACTIONS,
      data,
    );
  }

  static refundFundingRoundTransaction(
    data: FundingRoundParticipationRefundTransactionRequestDTO,
  ) {
    return httpPost<
      FundingRoundParticipationRefundTransactionRequestDTO,
      FundingRoundParticipantsReportItem
    >(PayoutEndpoints.POST_REFUND_FUNDING_TRANSACTIONS, data);
  }

  static sendPayoutRequest(campaignId: string) {
    return httpPost<IPayoutTransactionDTO, IPayoutDetails>(
      `${PayoutEndpoints.GET_PAYOUT_LIST}/${encodeURI(
        campaignId ?? '',
      )}/request`,
    );
  }

  static sendFundingPayoutRequest(fundingRoundId: string) {
    return httpPost<IFundingRoundRequestDTO, void>(
      `${PayoutEndpoints.GET_FUNDING_PAYOUT_LIST}/${encodeURI(
        fundingRoundId ?? '',
      )}/request`,
    );
  }
}

export default PayoutAPI;
