import styled from 'styled-components';
import { DEVICES, pickThemeFontStyles } from '@/theme';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  > div {
    height: 100%;
  }

  &&& .g2-tooltip-list-item {
    display: flex;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    gap: 3rem;
    align-items: start;
  }
`;

export const StyledChartWrapper = styled.div`
  width: 9rem;
  height: 9rem;

  @media screen and ${DEVICES.LAPTOP_S} {
    width: 12.5rem;
    height: 12.5rem;
  }
`;

export const StyledLegendWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.25rem;
  flex: 1;
`;

export const StyledLegendRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.75rem;
`;

export const StyledTitle = styled.div<{ $color: string }>`
  ${pickThemeFontStyles('12', '18')};
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &::before {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: ${({ $color }) => $color};
    flex-shrink: 0;
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    ${pickThemeFontStyles('14', '16')};

    &::before {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
`;
