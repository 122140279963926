import styled from 'styled-components';
import { COLORS, DEVICES, pickThemeFontStyles } from '@/theme';

export const StyledFormWrapper = styled.div`
  align-items: flex-start;
  position: relative;

  @media screen and ${DEVICES.LAPTOP_S} {
    min-height: 8rem;
  }
`;

export const FormTextWrapper = styled.div`
  width: 100%;
`;

export const StyledTitle = styled.h2`
  ${pickThemeFontStyles('18', '22', 'BOLD')};
  color: ${COLORS.GREY};
  margin: 0;
`;

export const FormFieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1rem;

  & .ant-form-item {
    margin-bottom: 0rem;
  }
`;

export const FormFields = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;

  :first-child {
    margin-top: 0;
  }

  div {
    flex: 1;
  }
  @media screen and ${DEVICES.LAPTOP_S} {
    flex-direction: row;
    gap: 1.5rem;
  }
`;

export const StyledContactTabLabel = styled.div`
  display: flex;
  gap: 1rem;

  svg {
    height: 1rem;
    width: 1rem;
  }
`;
