const phoneReplaceRegex = /(?!^\+)[^\d\r\n]+/g;

export const normalizePhone = (phone = '') => {
  const cleared = phone.replace(phoneReplaceRegex, '');

  if (cleared && !cleared.startsWith('+')) {
    return `+${cleared}`;
  }
  return cleared;
};

export const normalizeNumber = (value = '') => value.replace(/\D/g, '');

export const getOrigin = (link: string) => {
  const cleared = (link ?? '').replace(/http:\/\/|https:\/\//, '');
  return cleared.split('/')[0];
};

const protocolRegex = /^http:\/\/|^https:\/\/|^ftp:\/\//;

export const normalizeExternalLink = (link: string) => {
  if (protocolRegex.test(link ?? '')) {
    return link;
  }

  return `https://${(link ?? '').replace(/^\//, '')}`;
};

export const replaceSpecialCharacters = (text: string) => {
  const characterMap: { [key: string]: string } = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
    ñ: 'n',
    ü: 'u',
    ç: 'c',
  };

  return text.replace(/[áéíóúñüç()–-]/g, (matched) => characterMap[matched]);
};
