import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import { REPORTS_SLICE_NAME, ReportsState, initialState } from './models';
import {
  getCampaignReportOptions,
  getCampaignReportsList,
  getFundingReportsList,
  getFundingRoundReportAffiliate,
  getCampaignAffiliate,
  getFundingRoundReportDetails,
  getCampaignReportDetails,
  getFundingRoundReportOptions,
  getFundingRoundReportParticipants,
  getCampaignReportParticipants,
} from './actionCreators';
import { showApiErrors } from '@/utils';

export const slice = createSlice({
  name: REPORTS_SLICE_NAME,
  initialState,
  reducers: {
    setReportsFilters(state: ReportsState, action) {
      state.reportsFilters = action.payload;
      state.fundingReports = initialState.fundingReports;
    },
    setCampaignReportsFilters(state: ReportsState, action) {
      state.campaignReportsFilters = action.payload;
      state.campaignReports = initialState.campaignReports;
    },
    resetRequestListState() {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFundingReportsList.fulfilled,
      (state: ReportsState, action) => {
        state.fundingReports = {
          items: action.meta.arg.startId
            ? [...(state.fundingReports?.items ?? []), ...action.payload.items]
            : [...action.payload.items],
          hasMore: action.payload.hasMore,
        };
        state.isLoading = false;
      },
    );
    builder
      .addCase(
        getCampaignReportsList.fulfilled,
        (state: ReportsState, action) => {
          state.campaignReports = {
            items: action.meta.arg.startId
              ? [
                  ...(state.campaignReports?.items ?? []),
                  ...action.payload.items,
                ]
              : [...action.payload.items],
            hasMore: action.payload.hasMore,
          };
          state.isLoading = false;
        },
      )
      .addCase(
        getFundingRoundReportParticipants.fulfilled,
        (state: ReportsState, action) => {
          state.fundingReportParticipants = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        getCampaignReportParticipants.fulfilled,
        (state: ReportsState, action) => {
          state.campaignReportParticipants = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        getFundingRoundReportDetails.fulfilled,
        (state: ReportsState, action) => {
          state.fundingReportDetails = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        getCampaignReportDetails.fulfilled,
        (state: ReportsState, action) => {
          state.campaignDetails = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        getFundingRoundReportOptions.fulfilled,
        (state: ReportsState, action) => {
          state.fundingFiltersOptions = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        getCampaignReportOptions.fulfilled,
        (state: ReportsState, action) => {
          state.campaignFiltersOptions = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isFulfilled(getFundingRoundReportAffiliate, getCampaignAffiliate),
        (state: ReportsState, action) => {
          state.reportAffiliateList = action.payload;
          state.isLoading = false;
        },
      )
      .addMatcher(
        isPending(
          getFundingReportsList,
          getFundingRoundReportParticipants,
          getFundingRoundReportDetails,
          getCampaignReportDetails,
          getFundingRoundReportOptions,
          getFundingRoundReportAffiliate,
          getCampaignReportOptions,
          getCampaignReportsList,
          getCampaignAffiliate,
        ),
        (state: ReportsState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(
          getFundingReportsList,
          getFundingRoundReportParticipants,
          getFundingRoundReportDetails,
          getCampaignReportDetails,
          getFundingRoundReportOptions,
          getFundingRoundReportAffiliate,
          getCampaignReportsList,
          getCampaignReportOptions,
          getCampaignAffiliate,
        ),
        (state: ReportsState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;

          showApiErrors(error);
        },
      );
  },
});

export const {
  resetRequestListState,
  setReportsFilters,
  setCampaignReportsFilters,
} = slice.actions;
export default slice.reducer;
