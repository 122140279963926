import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { PAYOUT_SLICE_NAME, PayoutState } from './models';

export const payoutSliceSelector = (state: RootState): PayoutState =>
  state[PAYOUT_SLICE_NAME];

export const selectPayoutIsLoading = createSelector(
  payoutSliceSelector,
  (state: PayoutState) => state.isLoading,
);

export const selectPayoutListDetails = createSelector(
  payoutSliceSelector,
  (state: PayoutState) => state.payoutList,
);
