import styled from 'styled-components';
import { Link } from '@/ui';

export const StyledLink = styled(Link)`
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  &:first-child {
    svg {
      transform: scale(0.7);
    }
  }
`;
